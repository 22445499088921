import React from "react"
import { Grid, Typography } from "@material-ui/core";
import {getClassName} from "../helpers/"

export default function FlightInfo(props){

    return (
        <Grid item md={8} xs={12} style={{padding:"20px"}}>
            <Grid container style={{ padding:"20px", backgroundColor:"#CD171A"}}>
                <Grid item md={12} xs={12}>
                    <Grid container style={{border:"1pt solid #FFF"}}>
                        <Grid item md={12} xs={12} style={{paddingTop:"20px", paddingBottom:"20px", borderBottom:"1pt solid #FFF"}}>
                            <Grid container >
                                <Grid item md={3} xs={6} style={{display:"flex", justifyContent:"flex-end"}}>
                                    <Typography style={{color:"#FFF"}}  className={props.classes.text}>
                                        Ordenado por:
                                    </Typography>
                                </Grid>
                                <Grid item md={3} xs={6} style={{display:"flex", justifyContent:"flex-start", paddingLeft:"10px"}}>
                                    <img src="./img/logo-footer.png" alt="" className={props.classes.imgAvior} />
                                </Grid>
                                <Grid item md={3} xs={6} style={{display:"flex", justifyContent:"flex-end"}}>
                                    <Typography style={{color:"#FFF"}}  className={props.classes.text}>
                                        Vuelo:
                                    </Typography>
                                </Grid>
                                <Grid item md={3} xs={6} style={{display:"flex", justifyContent:"flex-start", paddingLeft:"10px"}}>
                                    <Typography style={{color:"#FFF"}}  className={props.classes.text}>
                                        {props.segment.flightNumber}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item md={12} xs={12} style={{paddingTop:"20px", paddingBottom:"20px"}}>
                            <Grid container >
                                <Grid item md={3} xs={6} style={{display:"flex", justifyContent:"flex-end"}}>
                                    <Typography style={{color:"#FFF"}}  className={props.classes.text}>
                                        Cabina:
                                    </Typography>
                                </Grid>
                                <Grid item md={3} xs={6} style={{display:"flex", justifyContent:"flex-start", paddingLeft:"10px"}}>
                                    <Typography style={{color:"#FFF"}}  className={props.classes.text}>
                                        {getClassName(props.segment.classCode).cabinName}
                                    </Typography>
                                </Grid>
                                <Grid item md={3} xs={6} style={{display:"flex", justifyContent:"flex-end"}}>
                                    <Typography style={{color:"#FFF"}}  className={props.classes.text}>
                                        Clase:
                                    </Typography>
                                </Grid>
                                <Grid item md={3} xs={6} style={{display:"flex", justifyContent:"flex-start", paddingLeft:"10px"}}>
                                    <Typography style={{color:"#FFF"}}  className={props.classes.text}>
                                        {getClassName(props.segment.classCode).familyName}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>           
    )

}