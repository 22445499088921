import React, { useState, useEffect } from "react";
import { TextField, Button, Grid } from "@material-ui/core";
import { Spinner, Toast } from "../components";
import { dolarPriceServices } from "../services";
import { useHistory } from "react-router-dom";
import { FormValidator } from "../helpers"
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles(theme => ({
    paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      margin: theme.spacing(1),
      width: 100,
      height: 100,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
  }));

export default function DolarPriceConfig(props){
    const classes = useStyles();
    let history = useHistory();
    const [price, setPrice] = useState("")
    const [loading, setLoading] = useState(false)
    const [openToast, setOpenToast] = useState(false);
    const [toastMessage, setToastMessage] = useState("");
    const [errors, setErrors] = useState({});
    const validator = new FormValidator([
        {
            field: "price",
            method: "isEmpty",
            validWhen: false,
            message: "Debe ingresar un monto"
        },
        {
            field: "price",
            method: "isNumeric",
            validWhen:true,
            message: "Debe ingresar un monto valido"
        }
    ])
    const getPrice = async () => {
        setLoading(true)
        const {status, response} = await dolarPriceServices.getPrice()
        setLoading(false)
        if (status === 200) {
            setPrice(response.price);
        }
    };
    useEffect(() => {
        getPrice();
    }, []);
    const updatePrice = async ()=>{
        const validation = validator.validate({
            price
        });
        setErrors(validation);
        if (validation.isValid) {
            setLoading(true)
            const {status} = await dolarPriceServices.updatePrice({
                price
            });
            setLoading(false)
            if (status === 200) {
                setOpenToast(true)
                setToastMessage("Precio actualizado")
            }else if (status === 401) {
                localStorage.clear();
                history.push(`/loginadminavior`);
            }
        }
    }
    return (
        <Grid container justify="center" direction="row" spacing={2}>
            <Spinner loading={loading}/>
            <Toast open={openToast} close={()=>setOpenToast(false)} message={toastMessage}/>
            <div className={classes.paper}>
                <form className={classes.form}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="Tasa"
                        name="price"
                        autoFocus
                        value={price}
                        error={errors.price ? errors.price.isInvalid : false}
                        helperText={errors.price ? errors.price.message : ""}
                        onChange={(e)=>setPrice(e.target.value)}
                    />
                    <Button fullWidth color="primary" variant="contained" onClick={updatePrice}>Guardar</Button>
                </form>
            </div>
        </Grid>
    )
}