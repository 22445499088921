import React, { useState } from "react";
import { StepsPaymentPromotion } from "../components";
import { makeStyles } from "@material-ui/core/styles";
import { Container } from "@material-ui/core";
import {
  CustomerData,
  PromotionPaymentMethodSelect,
  CreditCardPromo,
  PaymentPromoZelle,
  PaymentPromoBAPM
} from ".";
export default function PaymentCuponAirStep() {
  const [step, setStep] = useState(0);

  const useStyles = makeStyles((theme) => ({
    paper: {
      width: "100%",
    },
    container: {
      paddingTop: theme.spacing(12),
      paddingBottom: theme.spacing(4),
    },
    bottom: {
      paddingBottom: theme.spacing(4),
    },
    [theme.breakpoints.down("md")]: {
      top: {
        paddingBottom: theme.spacing(4),
      },
    },
    [theme.breakpoints.up("md")]: {
      top: {
        paddingBottom: theme.spacing(4),
      },
    },
  }));
  const classes = useStyles();
  const [method, setMethod] = useState("zelle");
  const [passenger, setPassenger] = useState(null);
  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <CustomerData
            passenger={passenger}
            sendData={(data) => {
              //console.log(data)
              setPassenger(data);
              window.scrollTo(0, 0);
              setStep(1);
            }}
          />
        );
        case 1:
          return (
            <PromotionPaymentMethodSelect
              sendData={(method) => {
                //console.log(method)
                setMethod(method);
                window.scrollTo(0, 0);
                setStep(2);
                
              }}
              goBack={() => {
                window.scrollTo(0, 0);
                setStep(0);
              }}
            />
          );
        case 2:
          if(method ==="zelle"|| method==="luka_zelle") return (
            <PaymentPromoZelle
            passenger={passenger}
            goBack={() => {
              setStep(1);
              window.scrollTo(0, 0);
            }}
            />
          )
          if(method === "bapm" || method === "BSS" || method === "luka_pm") return (
            <PaymentPromoBAPM
            passenger={passenger}
            goBack={() => {
              setStep(1);
              window.scrollTo(0, 0);
            }}
            />
          )
          else return (
            <CreditCardPromo
            passenger={passenger}
            goBack={() => {
              setStep(1);
              window.scrollTo(0, 0);
            }}
            />
          );
      default:
        throw new Error("Unknown step");
    }
  }

  function getActiveStep(step) {
    return step;
  }
  return (
    <div className={classes.paper}>
      <div className={classes.top}>
        <StepsPaymentPromotion active={getActiveStep(step)} />
      </div>

      <Container maxWidth="lg" className={classes.bottom}>
        <div style={{ minHeight: "55vh" }}>{getStepContent(step)}</div>
      </Container>
    </div>
  );
}
