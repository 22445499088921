import React, { useState, useEffect } from "react";
import {
  PassengerForm,
  ContactForm,
  Toast,
  ButtonSpinner,
  Title,
  TermsAndConditions,
} from "../components";
import { Grid, Button, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { getIsNationalRoute } from "../helpers";
import LoginAviorPlus from "./login-aviorplus";
//import { reservationServices } from "../services";
import ReactModal from "react-modal";
import { X } from "lucide-react";

export default function PassengersData(props) {
  const [toast, setToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [contForms, setContForms] = useState(0);
  const [termns, setTermns] = useState(false);
  //const [adult, setAdult] = useState(false);

  const [showLogin, setShowLogin] = useState(false);

  const classes = useStyles();
  let structurePassengers = [];
  const [passengers, setPassengers] = useState([]);
  const [contactInfo, setContactInfo] = useState({});
  const adults = props.countAdults;
  const childs = props.countChildrens;
  const infants = props.countBabies;
  const [minAdultDate, setMinAdultDate] = useState("");
  const [maxAdultDate, setMaxAdultDate] = useState("");
  const [minChildDate, setMinChildDate] = useState("");
  const [maxChildDate, setMaxChildDate] = useState("");
  const [minInfantDate, setMinInfantDate] = useState("");
  const [maxInfantDate, setMaxInfantDate] = useState("");
  const [isNationalRoute, setIsNationalRoute] = useState(false);
  let i = 1;

  for (let j = 1; j <= adults; i++, j++) {
    structurePassengers.push({
      id: i.toString(),
      name: "Pasajero",
      passengerType: "ADT",
    });
  }

  for (let j = 1; j <= childs; i++, j++) {
    structurePassengers.push({
      id: i.toString(),
      name: "Pasajero",
      passengerType: "CNN",
    });
  }

  for (let j = 1; j <= infants; i++, j++) {
    structurePassengers.push({
      id: i.toString(),
      name: "Pasajero",
      passengerType: "INF",
    });
  }

  useEffect(() => {
    setForms(props.passengers);
  }, [props.passengers]);

  useEffect(() => {
    getDates();
  }, []);
  useEffect(() => {
    setIsNationalRoute(
      getIsNationalRoute(
        props.searchParams.origin,
        props.searchParams.destination
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getDates() {
    let date = new Date();
    date.setMonth(date.getMonth() - 12 * 2);
    date.setDate(date.getDate() + 1);
    await setMinInfantDate(
      new Date(
        date.getFullYear() + "/" + (date.getMonth() + 1) + "/" + date.getDate()
      )
    );

    date = new Date();
    await setMaxInfantDate(
      new Date(
        date.getFullYear() + "/" + (date.getMonth() + 1) + "/" + date.getDate()
      )
    );

    date = new Date();
    date.setMonth(date.getMonth() - 12 * 2);
    await setMaxChildDate(
      new Date(
        date.getFullYear() + "/" + (date.getMonth() + 1) + "/" + date.getDate()
      )
    );

    date = new Date();
    date.setMonth(date.getMonth() - 12 * 12);
    await setMinChildDate(
      new Date(
        date.getFullYear() + "/" + (date.getMonth() + 1) + "/" + date.getDate()
      )
    );

    date = new Date();
    await setMinAdultDate(new Date("1920/01/02"));

    date = new Date();
    date.setMonth(date.getMonth() - 12 * 12);
    date.setDate(date.getDate() - 1);
    await setMaxAdultDate(
      new Date(
        date.getFullYear() + "/" + (date.getMonth() + 1) + "/" + date.getDate()
      )
    );
  }

  async function setForms(passengers) {
    if (passengers.length !== 0) {
      setContForms(passengers.length + 1);
      setPassengers(passengers);
      let principalPassenger = passengers.find((p) => p.id === "1");
      let info;
      if (principalPassenger) {
        info = {
          name: principalPassenger.nameContact,
          lastName: principalPassenger.lastNameContact,
          phone: principalPassenger.phoneContact,
          email: principalPassenger.emailContact,
        };
        setContactInfo({ ...info });
      }
    }
  }

  const getMaxDate = (type) => {
    if (type === "ADT") return maxAdultDate;
    else if (type === "CNN") return maxChildDate;
    else if (type === "INF") return maxInfantDate;
  };

  const getMinDate = (type) => {
    if (type === "ADT") return minAdultDate;
    else if (type === "CNN") return minChildDate;
    else if (type === "INF") return minInfantDate;
  };

  const addPassenger = async (passenger) => {
    await setContForms(contForms + 1);
    let newArray = passengers.filter((p) => p.id !== passenger.id);
    newArray.push(passenger);
    await setPassengers(newArray);
  };

  const deletePassenger = async (passenger) => {
    //console.log("Ptd",passenger )
    let arrayAux = passengers.filter(
      (p) => p.document.documentID !== passenger.document.documentID
    );
    await setPassengers(arrayAux);
    await setContForms(contForms - 1);
  };

  const findPhoneContact = async (phone) => {
    return passengers.find(
      (p) =>
        p.type === "ADT" &&
        p.phone.code === phone.code &&
        p.phone.number === phone.number
    )
      ? true
      : false;
  };

  const findEmailContact = (email) => {
    return passengers.find((p) => p.email === email) ? true : false;
  };

  const findCustomer = async (customer) => {
    return passengers.find(
      (p) => p.membershipID !== "" && p.membershipID === customer
    )
      ? true
      : false;
  };
  const findPhonePassengers = async (phone) => {
    return contactInfo.phone &&
      contactInfo.phone.code === phone.code &&
      contactInfo.phone.number === phone.number
      ? true
      : false;
  };
  const findPassenger = async (documentID) => {
    let found = passengers.find((p) => p.document.documentID === documentID);
    if (found) return true;
    return false;
  };
  const addContactInfo = async (info) => {
    setContForms(contForms + 1);
    setContactInfo({ ...info });
  };

  const deleteContactInfo = async (info) => {
    await setContForms(contForms - 1);
    setContactInfo({});
  };

  const isAdult = async (isAdult) => {
    //console.log(isAdult);
    //await setAdult(isAdult);
  };
  const acceptTermns = async (acceptTermns) => {
    await setTermns(acceptTermns);
  };
  const validateForms = async () => {
    if (structurePassengers.length + 1 !== contForms) {
      setToast(true);
      setToastMessage("Debe guardar todos los formularios");
    } else if (!termns) {
      setToast(true);
      setToastMessage("Debe aceptar los terminos y condiciones");
    } else {
      for (let passenger of passengers) {
        if (passenger.id === "1") {
          passenger.nameContact = contactInfo.name;
          passenger.lastNameContact = contactInfo.lastName;
          passenger.phoneContact = contactInfo.phone;
          passenger.emailContact = contactInfo.email;
        }
      }
      props.sendData({ passengersInfo: passengers, contactData: contactInfo });
    }
  };

  return (
    <div
      className={classes.divContainer}
      style={{ margin: "0px 0px 10px 5px" }}
    >
      {!props.userData && (
        <Grid item md={3} xs={12} style={{ marginBottom: 10 }}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={() => {
              setShowLogin(!showLogin);
            }}
            id="buttonLogin"
            style={{
              textTransform: "none",
            }}
          >
            Iniciar sesión
          </Button>
        </Grid>
      )}

      {!props.userData && (
        <ReactModal
          isOpen={showLogin}
          onRequestClose={() => setShowLogin(!showLogin)}
          style={modalStyles}
          closeTimeoutMS={200}
        >
          <Grid container justify="space-between" style={{ marginBottom: 15 }}>
            <Typography color="secondary" align="center">
              INICIAR SESIÓN
            </Typography>

            <X
              cursor="pointer"
              onClick={() => {
                setShowLogin(!showLogin);
              }}
            />
          </Grid>

          <LoginAviorPlus
            setUserData={props.setUserData}
            close={() => {
              setShowLogin(!showLogin);
            }}
          />
        </ReactModal>
      )}

      <Typography variant="h5" style={{ color: "#AEAEAE", fontWeight: "bold" }}>
        Paso 1
      </Typography>

      <Title
        h2={"INFORMACIÓN DEL"}
        strong={"PASAJERO"}
        p={
          "Ingresa los datos al igual como aparecen registrados en el pasaporte o documento de identificación de los pasajeros."
        }
      />

      {structurePassengers.map((passenger) => (
        <PassengerForm
          key={passenger.id}
          index={passenger.id}
          type={passenger.passengerType}
          addPassenger={addPassenger}
          deletePassenger={deletePassenger}
          findPassenger={findPassenger}
          classes={classes}
          maxDate={getMaxDate(passenger.passengerType)}
          minDate={getMinDate(passenger.passengerType)}
          passengersCount={structurePassengers.length}
          passengers={props.passengers}
          findPhonePassengers={findPhonePassengers}
          findCustomer={findCustomer}
          searchParams={props.searchParams}
          isNationalRoute={isNationalRoute}
          setTourCodeType={(type) => props.setTourCodeType(type)}
          userData={props.userData}
        />
      ))}

      <Typography variant="h5" style={{ color: "#AEAEAE", fontWeight: "bold" }}>
        Paso 2
      </Typography>

      <Title
        h2={"INFORMACIÓN DEL "}
        strong={"CONTACTO DE EMERGENCIA"}
        p={
          "La información provista debe ser diferente a la de los pasajeros registrados."
        }
      />

      <ContactForm
        classes={classes}
        addContactInfo={addContactInfo}
        deleteContactInfo={deleteContactInfo}
        passengers={props.passengers}
        findPhoneContact={findPhoneContact}
        findEmailContact={findEmailContact}
        userData={props.userData}
      />

      <TermsAndConditions isAdult={isAdult} acceptTermns={acceptTermns} />

      <Grid container justify="space-between">
        <div style={{ width: "158px", height: "41px" }}>
          <ButtonSpinner
            action={() => {
              props.goBack();
            }}
            fullWidth
            text="Atras"
            color={"primary"}
            style={{ height: "100%" }}
            labelClassname={classes.aceptButtonLabel}
          />
        </div>
        <div style={{ width: "158px", height: "41px" }}>
          <ButtonSpinner
            action={validateForms}
            fullWidth
            text="Continuar"
            color={"primary"}
            style={{ height: "100%" }}
            labelClassname={classes.aceptButtonLabel}
          />
        </div>
      </Grid>

      <Toast
        message={toastMessage}
        open={toast}
        close={() => {
          setToast(false);
        }}
      />
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  /*Screens styles*/
  titleRow: {
    display: "flex",
    borderWidth: 3,
    borderColor: "black",
    marginBottom: 50,
  },
  title: {
    color: "gray",
    margin: 0,
  },
  titleColor: {
    color: "black",
  },
  h2: {
    color: "#777777",
    fontWeight: "unset",
    fontSize: "1.2rem",
  },
  checkText: {
    fontSize: "1.17rem",
    color: "#555555",
    marginTop: 0,
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  textPrimary: {
    color: "#CD171A",
  },

  /*Components Shared styles*/
  divContainer: {
    //backgroundColor: "#F4F4F4",
    //boxShadow: theme.shadows[5],
    borderRadius: 5,
    padding: 10,
  },
  container: {
    marginBottom: 20,
    backgroundColor: "#fff",
    border: "1px solid #ccc",
    borderRadius: 10,
  },
  headerFormContainerLeft: {
    border: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    height: "100%",
  },
  headerFormContainerRight: {
    border: "none",
    height: "100%",
  },
  aceptButtonLabel: {
    color: "#ffffff",
    //fontSize: "1.17rem",
    display: "flex",
    alignItems: "center",
  },
  floatingLabelFocusStyle: {
    color: "#CD171A",
    fontSize: "1.1rem",
    width: "max-content",
  },
  inputDiv: {
    backgroundColor: "#fff",
    borderWidth: "1pt",
    borderStyle: "solid",
    borderColor: "#cccccc",
    padding: "2px 5px",
    borderRadius: "5px",
    "& .MuiFormControl-root": {
      width: "100%",
    },
    "& .MuiInputBase-input": {
      padding: "2px 10px 2px 0px",
    },
  },
  parent: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  inputLabel: {
    fontSize: "0.8rem",
    paddingBottom: 0,
    color: "#555555!important",
    height: "20px",
  },
  underline: {
    "&::before": {
      display: "none",
    },
    "&::after": {
      display: "none",
    },
  },
  button: {
    height: 50,
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
  },
  divWord: {
    width: "100%",
    justifyContent: "flex-start",
    height: "100%",
    alignItems: "center",
    display: "flex",
  },
  divIcon: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    height: "100%",
  },
  p: {
    fontSize: "1.3rem",
    color: "#848484",
  },
  p1: {
    fontSize: "0.8rem",
  },
  [theme.breakpoints.up("xs")]: {
    allFieldsText: {
      color: "#555555",
    },
    Grid: {
      display: "flex",
      justifyContent: "space-between",
    },
    gridContainer: {
      display: "flex",
      justifyContent: "center",
      padding: "10px",
    },
    checked1: {
      display: "flex",
    },
    checked2: {
      display: "flex",
    },
  },
  [theme.breakpoints.down("md")]: {
    buttonLabel: {
      fontSize: "0.9rem",
    },
    floatingLabelFocusStyle: {
      fontSize: "0.9rem",
    },
    inputLabel: {
      fontSize: "0.8rem",
    },
    aceptButtonLabel: {
      fontSize: "0.9rem",
    },
  },
  /*Medias querys*/
  [theme.breakpoints.up("md")]: {
    Grid: {
      display: "flex",
      justifyContent: "space-between",
    },
    gridContainer: {
      display: "flex",
      justifyContent: "center",
      padding: "0px 10px",
    },
    allFieldsText: {
      display: "flex",
      justifyContent: "flex-end",
      height: "100%",
      alignItems: "flex-end",
      color: "#555555",
      fontSize: "1rem",
    },
    buttonLabel: {
      fontSize: "1.25rem",
    },
    checkeds: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    checked1: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderRightWidth: "1px",
      borderRightStyle: "solid",
      borderRightColor: "#cccccc",
      paddingRight: "10px",
    },
    checked2: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      paddingLeft: "10px",
    },
  },
  /* Accordion */
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const modalStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  content: {
    top: "50%",
    left: "50%",
    bottom: "auto",
    //bottom: "-40%",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    maxWidth: 450,
    //maxHeight: 280,
    padding: 15,
    border: "none",
    borderRadius: 0,
  },
};
