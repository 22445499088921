import React, { useState, useEffect } from "react";
import { Box, Typography, Grid, Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  Footer,
  ModalPopUp,
  PurchaseDetailsInstallments,
  Spinner,
  Toast,
} from "../components";
import {
  aviorPlusServices,
  gatewaysServices,
  installmentsServices,
} from "../services";
import PaymentBAPM from "./payment-bapm";
import PaymentStripe from "./stripe-payment-booking";
import PaymentLukaPay from "./luka-pay-payment-booking";
import PaymentBTPMP2P from "./payment-btpm-p2p";

const useStyles = makeStyles((theme) => ({
  header: {
    padding: "10px 20px",
    marginBottom: 20,
    borderBottom: "1px solid #C8C8C8",
  },
  card: {
    borderRadius: 10,
    boxShadow: theme.shadows[2],
  },
  span1: {
    fontWeight: "bold",
    fontSize: "1.21rem",
  },
  p: {
    fontSize: "0.9rem",
  },
  p3: {
    fontSize: "1.15rem",
    lineHeight: "1.5",
    color: "#848484",
  },
  grid: {
    padding: "15px 15px 40px 15px",
  },
  buttons: {
    display: "flex",
    justifyContent: "center",
    marginTop: "15px",
  },
  box: {
    backgroundColor: "#EAEAEA",
  },
  box1: {
    backgroundColor: "#CD171A",
    border: "1px solid #CD171A",
    color: "#FFF",
  },

  [theme.breakpoints.down("sm")]: {
    header: {
      padding: "10px 5px",
      justifyContent: "flex-end",
    },
    span1: {
      fontSize: "0.8rem",
    },
    p3: {
      fontSize: "0.8rem",
    },
    imgContainer: {
      display: "flex",
      alignItems: "center",
    },
    img: {
      width: "100%",
    },
  },
}));

export default function PayInstallments(props) {
  const classes = useStyles();

  const params = new URLSearchParams(window.location.search);
  const installment_id = params.get("installment_id");

  ///limpiar url
  //const url = new URL(window.location);
  //url.search = "";
  //window.history.replaceState({}, "", url);

  const [methodSelect, setMethodSelect] = useState(null);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [methodsView, setMethodsView] = useState([]);
  const [installmentInfo, setInstallmentInfo] = useState(null);
  const [installmentsError, setInstallmentsError] = useState(null);
  const [userData, setUserData] = useState(null);
  //const [paymentStatus, setPaymentStatus] = useState(null);

  const [toast, setToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [modalToHome, setModalToHome] = useState(false);

  const [loading, setLoading] = useState(false);

  const installmentsMethods = [
    //"bapm",
    "stripe",
    "luka_zelle",
    "luka_banesco",
    "btp2p",
  ];

  const getUserInfo = async ({ user_id }) => {
    const { response, status } = await aviorPlusServices.userInfo({
      user_id,
    });

    if (status === 200) {
      setUserData(response?.users[0]);
    } else {
      const messageError = response?.error?.message
        ? response.error.message
        : "Error al buscar usuario.";

      setInstallmentsError(messageError);
    }
  };

  const getGateways = async () => {
    const { response } = await gatewaysServices.getGateways("booking");

    setPaymentMethods(response);
  };

  const getInstallment = async ({ id }) => {
    if (!id) {
      setInstallmentsError("ID de la reserva no encontrado");
      return;
    }

    setLoading(true);

    const { response, status } =
      await installmentsServices.getInstallmentsPrice({
        id,
      });

    if (status !== 200) {
      if (response?.error?.message) {
        setInstallmentsError(response?.error.message);
      } else {
        setInstallmentsError("Error al consultar las cuota");
      }
    }

    if (status === 200) {
      setInstallmentInfo(response);
      await getUserInfo({ user_id: response.user });

      if (response.status === "approved") {
        setInstallmentsError("La cuota ya fue pagada correctamente");
      } else if (response.status === "in_process") {
        setToast(true);
        setToastMessage(
          "Esta cuota ya tiene un pago registrado. Espere por su aprobación o comuníquese con nuestro equipo."
        );
      } else {
        setInstallmentsError(null);
      }
    }

    setLoading(false);
  };

  useEffect(() => {
    getGateways();
  }, []);

  useEffect(() => {
    getInstallment({ id: installment_id });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let methods = [];

    if (userData) {
      methods = paymentMethods.filter((item) =>
        installmentsMethods.includes(item.value)
      );
    } else {
      setMethodsView([]);
    }

    setMethodSelect(null);
    setMethodsView(methods);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentMethods, userData]);

  return (
    <Container
      //maxWidth="lg"
      maxWidth={false}
      className={classes.bottom}
      style={{ padding: 0 }}
    >
      <Spinner loading={loading} />

      <Grid container className={classes.header}>
        <Grid item md={2} xs={4}>
          <img
            src="./img/logoWhite.png"
            alt=""
            style={{ width: "100%", cursor: "pointer" }}
            onClick={() => setModalToHome(true)}
          />
        </Grid>
      </Grid>

      <Grid container style={{ minHeight: "calc(100vh - 120px)" }}>
        <Grid item md={9} xs={12}>
          {installmentsError && (
            <Box mb={2} textAlign="center">
              <Typography className={classes.span1}>
                {installmentsError}
              </Typography>
            </Box>
          )}

          {!installmentsError && (
            <Box mb={2}>
              <Typography align="center" className={classes.span1}>
                SELECCIONA TU MÉTODO DE PAGO
              </Typography>
            </Box>
          )}

          {userData && !installmentsError && (
            <Box
              display="flex"
              flexWrap="wrap"
              justifyContent="center"
              margin="auto"
              maxWidth={800}
            >
              {methodsView.map((method, index) => (
                <Box
                  key={index}
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  m={1}
                  padding={1}
                  width={180}
                  border={`2px solid ${
                    methodSelect === method.value ? "#CD171A" : "#fff"
                  }`}
                  className={classes.card}
                  style={{ cursor: "pointer" }}
                  onClick={(e) => {
                    setMethodSelect(method.value);
                  }}
                >
                  <Box mb={1}>
                    <img
                      src={method.img}
                      alt=""
                      style={{ maxHeight: 40, maxWidth: 160 }}
                    />
                  </Box>

                  <Box className={classes.p} textAlign="center">
                    {method.name}
                  </Box>
                </Box>
              ))}
            </Box>
          )}

          <Box my={2}>
            {methodSelect === "bapm" && (
              <PaymentBAPM
                installments={true}
                userData={userData}
                installmentId={installment_id}
                installmentInfo={installmentInfo}
              />
            )}

            {methodSelect === "stripe" && (
              <PaymentStripe
                name={
                  userData
                    ? `${userData.first_name} ${userData.last_name}`
                    : null
                }
                email={userData ? userData.email : null}
                user={userData ? userData.user_id : null}
                installments={true}
                installmentId={installment_id}
                installmentInfo={installmentInfo}
              />
            )}

            {(methodSelect === "luka_zelle" ||
              methodSelect === "luka_banesco") && (
              <PaymentLukaPay
                method={methodSelect}
                email={userData ? userData.email : null}
                user={userData ? userData.user_id : null}
                installments={true}
                installmentId={installment_id}
                installmentInfo={installmentInfo}
              />
            )}

            {methodSelect === "btp2p" && (
              <PaymentBTPMP2P
                method={methodSelect}
                email={userData ? userData.email : null}
                user={userData ? userData.user_id : null}
                installments={true}
                installmentId={installment_id}
                installmentInfo={installmentInfo}
              />
            )}
          </Box>
        </Grid>

        <Grid item md={3} xs={12} style={{ padding: "0px 10px" }}>
          <PurchaseDetailsInstallments installmentInfo={installmentInfo} />
        </Grid>
      </Grid>

      <Footer />

      <Toast
        message={toastMessage}
        open={toast}
        close={() => {
          setToast(false);
        }}
      />

      <ModalPopUp
        open={modalToHome}
        close={() => setModalToHome(false)}
        modalToHome={true}
      />
    </Container>
  );
}
