import Modal from "@material-ui/core/Modal";
import React, { useState } from "react";
import { Grid, Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

export default function ModalTourCodeError(props) {
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);
  function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
  }
  return (
    <Modal open={true} onClose={()=>props.close()} disableBackdropClick={true}>
      <Grid
        container
        justify="center"
        style={modalStyle}
        className={classes.paper}
      >
        <Grid item md={12} xs={12} style={{ backgroundColor: "#FFF" }}>
          <Typography className={classes.h2} align="center">
            <strong>¡ATENCION!</strong>
          </Typography>
          <br />
          <Typography className={classes.modalText}>
            {props.message}
          </Typography>
          <br />
          <br />
          <Grid
            container
            justify="center"
            alignItems="center"
            direction="column"
          >
            <div style={{ width: "100%" }}>
              <Button
                variant="contained"
                fullWidth
                color={"primary"}
                onClick={()=>props.close()}
              >
                <label className={classes.aceptButtonLabel}>
                  {"Cerrar"}
                </label>
              </Button>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 400,
    height: 200,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #FFF",
    boxShadow: theme.shadows[5],
    padding: "20px",
    borderRadius: "20px",
    outline: "none",
  },
  paper2: {
    position: "absolute",
    width: 400,
    height: 200,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #FFF",
    boxShadow: theme.shadows[5],
    padding: "20px",
    borderRadius: "20px",
    outline: "none",
  },
  closeButton: {
    position: "absolute",
    zIndex: 10,
    top: "-4%",
  },
  aceptButtonLabel: {
    color: "#ffffff",
    fontSize: "0.8rem",
  },
  h2: {
    color: "#777777",
    fontWeight: "unset",
    fontSize: "1rem",
  },
  modalText: {
    color: "#575756",
    fontWeight: "bold",
    fontSize: "0.8rem",
  },
  p: {
    fontSize: "1.3rem",
    color: "#848484",
  },
  highlightedP: {
    fontSize: "1.5rem",
    color: "#af0061",
  },
  [theme.breakpoints.down("xs")]: {
    closeButton: {
      left: "90%",
    },
  },
  [theme.breakpoints.up("xs")]: {
    closeButton: {
      left: "92.5%",
    },
  },
  [theme.breakpoints.down("sm")]: {
    h2: {
      fontSize: "1.1rem",
    },
    p: {
      fontSize: "1rem",
    },
    highlightedP: {
      fontSize: "1.2rem",
    },
    paper: {
      width: 340,
      height: 210,
    },
  },
}));
