import urls from "../urls";

const login = async ({ username, password }) => {
  const request = await fetch(urls.loginAP, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ username, password }),
  });

  const response = await request.json();

  return {
    status: request.status,
    response,
  };
};

const userInfo = async ({ user_id }) => {
  const request = await fetch(urls.userInfo, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ user_id }),
  });

  const response = await request.json();

  return {
    status: request.status,
    response,
  };
};

const getDolarToMiles = async () => {
  const request = await fetch(urls.dolarToMiles, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Basic " + btoa("avior:fazQMUyq"),
    },
  });
  const response = await request.json();
  return {
    status: request.status,
    response,
  };
};

const getReservationPrice = async ({ pnr }) => {
  const request = await fetch(`${urls.milesPrice}?pnr=${pnr}`);
  const response = await request.json();
  return {
    status: request.status,
    response,
  };
};

const addRedemptionAvior = async ({ token, pnr }) => {
  const request = await fetch(urls.addRedemption, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ pnr }),
  });
  const response = await request.json();
  return {
    status: request.status,
    response,
  };
};

const getUserAddresses = async ({ token }) => {
  const request = await fetch(urls.addresses, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const response = await request.json();
  return {
    status: request.status,
    response,
  };
};

const updateAVP = async ({ email, is_aviorplus }) => {
  const request = await fetch(urls.updateAVP, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email, is_aviorplus }),
  });

  const response = await request.json();

  return {
    status: request.status,
    response,
  };
};

const aviorPlusServices = Object.freeze({
  login,
  userInfo,
  getDolarToMiles,
  getReservationPrice,
  addRedemptionAvior,
  getUserAddresses,
  updateAVP,
});

export default aviorPlusServices;
