import { days, months } from "./get-day-mounth";

// date format yyyy-MM-ddThh:mm
export default function getFullDateName(date) {
  const newDate = new Date(date);

  return `${days[newDate.getDay()].fullName}, ${newDate.getDate()} de ${
    months[newDate.getMonth()].fullName
  } ${newDate.getUTCFullYear()}`;
}
