import React, { useState, useEffect } from "react"
import { 
    Grid,
    RadioGroup, 
    FormControlLabel, 
    Radio, 
    Button,
    Typography,
    FormGroup,
    Checkbox
} from "@material-ui/core"
import { Spinner, Toast } from "../components";
import { gatewaysServices } from "../services";
import { useHistory } from "react-router-dom";
export default function PaymentsGatewaysConfig(){
    const [loading, setLoading] = useState(false)
    let history = useHistory();
    const [paymentMethods, setPaymentMethods] = useState([])
    const [linkValue, setLinkValue] = useState("")
    const [bookingValue, setBookingValue] = useState("");
    const [toast, setToast] = useState(false);
    const [toastMessage, setToastMessage] = useState("");
    const savePaymentsGateways = async ()=>{
        setLoading(true)
        const { status } = await gatewaysServices.putGateways(paymentMethods);
        if(status===200){
            getGateways();
            setToastMessage("Pasarelas actualizadas");
            setToast(true);
        }else if (status === 401) {
            localStorage.clear();
            history.push(`/loginadminavior`);
        }
        setLoading(false);
    }
    const getGateways = async ()=>{
        setLoading(true)
        const { response, status } = await gatewaysServices.getGateways();
        if(status===200){
            const toFindLink = response.find(method=>method.type === 'link' && method.status === true)
            if(toFindLink){
                setLinkValue(toFindLink.value)
            }
            const toFindBooking = response.find(method=>method.type === 'booking' && method.status === true && method.isBookingCC === true)
            if(toFindBooking){
                setBookingValue(toFindBooking.value)
            }
            setPaymentMethods(response)
        }
        setLoading(false)
    }
    useEffect(() => {
        getGateways();
    }, []);

    const changeMethodLink = async (e)=>{
        const index = paymentMethods.findIndex(method=>method.type === 'link' && method.value === e.target.value)
        setLinkValue(e.target.value)
        const methodAux = [...paymentMethods];
        //let indexAux;
        methodAux[index].status = true;
        for(let i = 0; i<methodAux.length; i++){
            if(methodAux[i].type === 'link'&&methodAux[i].value!==e.target.value){
                methodAux[i].status = false
            }
        }
        //if(e.target.value==="tuOfertazo"){
        //    indexAux = paymentMethods.findIndex(method=>method.type === 'link' && method.value === "creditCard")
        //    methodAux[index].status = true;
        //    methodAux[indexAux].status = false;
        //}else{
        //    indexAux = paymentMethods.findIndex(method=>method.type === 'link' && method.value === "tuOfertazo")
        //    methodAux[index].status = true;
        //    methodAux[indexAux].status = false;
        //}
        setPaymentMethods([...methodAux])
    }

    const changeMethodBooking = async (e) => {
        const index = paymentMethods.findIndex(method=>method.type === 'booking' && method.value === e.target.value)
        setBookingValue(e.target.value)
        const methodAux = [...paymentMethods];
        methodAux[index].status = true;
        for(let i = 0; i<methodAux.length; i++){
            if(methodAux[i].type === 'booking'&&methodAux[i].value!==e.target.value&&methodAux[i].isBookingCC){
                methodAux[i].status = false
            }
        }
        //if(e.target.value==="nodus"){
        //    indexAux = paymentMethods.findIndex(method=>method.type === 'booking' && method.value === "TDC")
        //    methodAux[index].status = true;
        //    methodAux[indexAux].status = false;
        //}else{
        //    indexAux = paymentMethods.findIndex(method=>method.type === 'booking' && method.value === "nodus")
        //    methodAux[index].status = true;
        //    methodAux[indexAux].status = false;
        //}
        setPaymentMethods([...methodAux])
    }

    const changeBooking = async (e) => {
        const index = paymentMethods.findIndex(method=>method.type === 'booking' && method.value === e.target.value)
        const methodAux = [...paymentMethods];
        methodAux[index].status = e.target.checked;
        setPaymentMethods([...methodAux])
    }
    return(
        <Grid container justify="center" direction="row" spacing={2}>
            <Toast
                message={toastMessage}
                open={toast}
                close={() => {
                setToast(false);
                }}
            />
            <Spinner loading={loading}/>
            <Grid item xs={12}>
                <Grid container justify="space-between">
                    <Button color="primary" variant="contained" onClick={savePaymentsGateways} style={{height: "35px"}}>Guardar</Button>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h5">Link de pago</Typography>
                <Grid container spacing={1} style={{marginTop:"10px"}}>
                    <RadioGroup onChange={changeMethodLink} value={linkValue}>
                        {paymentMethods.filter(method=>method.type === 'link').map((method, index)=>(
                            <FormControlLabel key={index} value={method.value} control={<Radio />} label={`${method.name} ${method.description}`} />
                        ))}  
                    </RadioGroup>
                    
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h5">Booking</Typography>
                <Grid container spacing={1} style={{marginTop:"10px"}}>
                    <Grid item xs={12}>
                        <RadioGroup onChange={changeMethodBooking} value={bookingValue}>
                        {paymentMethods.filter(method=>method.type === 'booking' && method.isBookingCC === true).map((method, index)=>(
                            <FormControlLabel key={index} value={method.value} control={<Radio />} label={`${method.name} ${method.description}`} />
                        ))}
                        </RadioGroup>
                    </Grid>
                    <Grid item xs={12}>
                        <FormGroup>
                            {paymentMethods.filter(method=>method.type === 'booking' && !method.isBookingCC).map((method, index)=>(
                                <FormControlLabel
                                key={index}
                                onChange={changeBooking}
                                value={method.value}
                                control={
                                <Checkbox checked={method.status} />
                                }
                                label={`${method.name} ${method.description}`}
                            />
                            ))}
                        </FormGroup>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
} 