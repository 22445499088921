import urls from "../urls";

const getPrice = async () => {
    const request = await fetch(urls.dolarPrice,{
        headers: {
          "Content-Type": "application/json",
          "Authorization":'Basic '+ btoa('avior:fazQMUyq'),
        },
      });
      const response = await request.json();
      return {
        status: request.status,
        response
    };
};

const updatePrice = async (body) => {
    const token = localStorage.getItem("token");
    const request = await fetch(urls.dolarPrice,{
        method:"PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        },
        body:JSON.stringify(body)
    });
    const response = await request.json();
    return {
      status: request.status,
      response
    };
};

const dolarPriceServices = Object.freeze({
    getPrice,
    updatePrice
});

export default dolarPriceServices;