import React, { useEffect, useState } from "react";
import { Button, Grid, makeStyles } from "@material-ui/core";
import { Spinner, Title } from "../components";
import { reservationServices, anciliariesServices } from "../services";
import { OriginDestinationSeatSelection } from "../components/SeatSelection";
import { getAncillaryGroup } from "../helpers";
const useStyles = makeStyles((theme) => ({
  grid: {
    marginTop: "15px",
  },
  details: {
    cursor: "pointer",
    textDecoration: "underline",
    fontSize: "0.8rem",
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "15px",
  },
  [theme.breakpoints.down("md")]: {
    font: {
      //background: "#891C56",
      fontSize: "0.9rem",
    },
  },
}));

export default function SelectSeat({
  goNext,
  pnr,
  lastname,
  originFlight,
  returnFlight,
  selectedSeats,
  setSelectedSeats,
}) {
  const classes = useStyles();
  let [loading, setLoading] = useState(true);
  const [activePassenger, setActivePassenger] = useState(null);
  const [segments, setSegments] = useState([]);
  const [segmentIndex, setSegmentIndex] = useState(-1);
  const [seatMap, setSeatMap] = useState(null);
  const [checkInPassengers, setCheckInPassengers] = useState([]);
  const [rphObject, setRphObject] = useState({});
  const [seatSsrs, setSeatSsrs] = useState([]);
  useEffect(() => {
    (async () => {
      setLoading(true);
      const { resp, status } = await reservationServices.getReservation({
        pnr,
        lastName: lastname,
      });
      setLoading(false);
      if (status === 200) {
        setSegments(resp.segments);
      }
    })();
  }, [pnr, lastname]);
  useEffect(() => {
    const generateRphObject = (originFlight, returnFlight) => {
      const rphObject = {};
      let rphCounter = 1;

      // Procesar segmentos de originFlight
      originFlight.segments.forEach((segment) => {
        rphObject[rphCounter] = {
          segment: {
            cabinType: originFlight.fare.classCode,
            flightNumber: segment.flightNumber,
            departureInfo: {
              dateTime: segment.departureInfo.dateTime,
              locationCode: segment.departureInfo.locationCode,
              cityName: segment.departureInfo.cityName,
              airPortNam: segment.departureInfo.airPortName,
            },
            arrivalInfo: {
              dateTime: segment.arrivalInfo.dateTime,
              locationCode: segment.arrivalInfo.locationCode,
              cityName: segment.arrivalInfo.cityName,
              airPortNam: segment.arrivalInfo.airPortName,
            },
          },
          type: "going",
        };
        rphCounter++;
      });
      if (returnFlight) {
        returnFlight.segments.forEach((segment) => {
          rphObject[rphCounter] = {
            segment: {
              cabinType: returnFlight.fare.classCode,
              flightNumber: segment.flightNumber,
              departureInfo: {
                dateTime: segment.departureInfo.dateTime,
                locationCode: segment.departureInfo.locationCode,
                cityName: segment.departureInfo.cityName,
                airPortNam: segment.departureInfo.airPortName,
              },
              arrivalInfo: {
                dateTime: segment.arrivalInfo.dateTime,
                locationCode: segment.arrivalInfo.locationCode,
                cityName: segment.arrivalInfo.cityName,
                airPortNam: segment.arrivalInfo.airPortName,
              },
            },
            type: "goBack",
          };
          rphCounter++;
        });
      }
      return rphObject;
    };
    const newRphObject = generateRphObject(originFlight, returnFlight);
    setRphObject(newRphObject);
  }, [originFlight, returnFlight]);
  const buildGroupAncillaries = async (ancillariesResult, flightRPH) => {
    const auxAncillaries = {
      going: {
        SEAT_SELECTION: {
          group: "SEAT_SELECTION",
          name: "SELECCIÓN DE ASIENTO",
          ancillaries: [],
        },
      },
    };
    ancillariesResult.going.forEach((ancillary) => {
      const type = getAncillaryGroup(ancillary.code);
      if (type === "SEAT_SELECTION") {
        const index = auxAncillaries.going[type].ancillaries.findIndex(
          (elem) => elem.code === ancillary.code
        );
        if (index === -1) {
          auxAncillaries.going[type].ancillaries.push({ ...ancillary });
        }
      }
    });
    const ancillariesArray = {
      going: [],
    };
    Object.entries(auxAncillaries.going).forEach(([key, value]) =>
      ancillariesArray.going.push({ key, ...value })
    );
    setSeatSsrs(
      ancillariesArray.going
        .find((ancillary) => ancillary.key === "SEAT_SELECTION")
        .ancillaries.map((ele) => {
          return {
            ...ele,
            segmentRPH: `${parseInt(flightRPH)}`,
          };
        })
    );
  };
  const getAncillaries = async (segment, flightRPH) => {
    const { response, status } = await anciliariesServices.getAncilliaries({
      going: [segment],
      goBack: [],
    });
    if (status === 200) {
      buildGroupAncillaries(response, flightRPH);
    }
  };
  const getSeatMap = async (flightNumber, index) => {
    setLoading(true);
    const { segment } = rphObject[parseInt(segments[index].flightRPH)];
    await getAncillaries(segment, segments[index].flightRPH);
    const { resp, status } = await reservationServices.getSeatAvailability(
      pnr,
      lastname,
      flightNumber
    );
    if (status === 200) {
      setSeatMap(resp);
    }
    setLoading(false);
  };
  const splitSeat = (seat) => {
    const match = seat.match(/(\d+)([A-Z])/);
    if (match) {
      return {
        row: match[1],
        column: match[2],
      };
    } else {
      return null;
    }
  };
  const addSeat = (ancillary, type) => {
    console.log({ ancillary });
    const seatsAux = [...selectedSeats[type]];
    console.log({ seatsAux });
    const existingPassengerIndex = seatsAux.findIndex(
      (p) =>
        p.segmentRPH === ancillary.segmentRPH &&
        p.travelerRPH === ancillary.travelerRPH
    );
    if (existingPassengerIndex === -1) {
      seatsAux.push(ancillary);
    } else {
      seatsAux[existingPassengerIndex] = ancillary;
    }
    setSelectedSeats({
      ...selectedSeats,
      [type]: seatsAux,
    });
  };
  const removeSeat = (ancillary, type) => {
    const seatsAux = [...selectedSeats[type]];
    const index = seatsAux.findIndex(
      (elem) =>
        elem.segmentRPH === ancillary.segmentRPH &&
        elem.travelerRPH === ancillary.travelerRPH
    );
    seatsAux.splice(index, 1);
    setSelectedSeats({
      ...selectedSeats,
      [type]: seatsAux,
    });
  };
  const updateSeat = (flightRPH, passengerId, newSeat, type, ssrInfo) => {
    let typeAncillary = rphObject[parseInt(flightRPH)].type;
    setCheckInPassengers((prev) => {
      const existingPassengerIndex = prev.findIndex(
        (p) =>
          p.travelerRefNumberRPHList === parseInt(flightRPH) &&
          p.travelerRefNumberRPHList === parseInt(passengerId)
      );
      if (type === "select") {
        if (existingPassengerIndex !== -1) {
          const updatedCheckInPassengers = [...prev];
          updatedCheckInPassengers[existingPassengerIndex].seatNumber = newSeat;
          if (ssrInfo.total) {
            updatedCheckInPassengers[existingPassengerIndex].SSRCode =
              ssrInfo.code;
          } else {
            delete updatedCheckInPassengers[existingPassengerIndex].SSRCode;
          }

          return updatedCheckInPassengers;
        } else {
          const passengerData = {
            travelerRefNumberRPHList: parseInt(passengerId),
            flightRefNumberRPHLists: parseInt(flightRPH),
            seatNumber: newSeat,
            SSRCode: ssrInfo.code,
          };
          if (ssrInfo.total) {
            passengerData.SSRCode = ssrInfo.code;
          }
          return [...prev, passengerData];
        }
      } else if (type === "deselect") {
        if (existingPassengerIndex !== -1) {
          const updatedCheckInPassengers = [...prev];
          updatedCheckInPassengers.splice(existingPassengerIndex, 1);
          return updatedCheckInPassengers;
        }
        return prev;
      }
    });
    if (type === "select") {
      addSeat(
        {
          segmentRPH: flightRPH,
          travelerRPH: passengerId,
          ...ssrInfo,
        },
        typeAncillary
      );
    } else {
      removeSeat(
        {
          segmentRPH: flightRPH,
          travelerRPH: passengerId,
          ...ssrInfo,
        },
        typeAncillary
      );
    }
    let seat = newSeat;
    const segmentPassengers = segments[segmentIndex].passengers;
    if (type === "deselect") {
      const passenger = segmentPassengers.find(
        (elem) => elem.id === passengerId
      );
      if (passenger.seat.seatNumber === newSeat) seat = null;
      else return;
    }

    setSegments((prevSegments) =>
      prevSegments.map((segment) =>
        segment.flightRPH === flightRPH
          ? {
              ...segment,
              passengers: segment.passengers.map((passenger) =>
                passenger.id === passengerId
                  ? {
                      ...passenger,
                      checkedInComplete: seat ? true : false,

                      seat: seat
                        ? {
                            ...passenger.seat,
                            seatNumber: seat,
                            ...splitSeat(seat),
                          }
                        : { seatNumber: null, column: null, row: null },
                    }
                  : passenger
              ),
            }
          : segment
      )
    );
    //if (seat) {
    //  setActivePassenger(null)
    //}
  };
  async function modifyBooking() {
    setLoading(true);
    const { status } = await reservationServices.selectSeats(
      pnr,
      checkInPassengers
    );
    setLoading(false);
    if (status === 200) {
      goNext();
    }
  }
  return (
    <>
      <Spinner loading={loading} />
      <Grid
        container
        justify="center"
        direction="row"
        spacing={1}
        className={classes.grid}
        style={{ margin: "10px 20px" }}
      >
        <Title
          h2={"SELECCIONE SU"}
          strong={"ASIENTO"}
          p={"Opcionalmente puede elegir en que asiento se ubicará"}
        />
        <Grid item xs={10}>
          {segments.map((segment, index) => (
            <OriginDestinationSeatSelection
              pnr={pnr}
              index={index}
              key={index}
              segment={segment}
              activeSegment={segmentIndex === index}
              activePassenger={activePassenger}
              setPassenger={(passenger) => setActivePassenger(passenger)}
              setSegment={(segment) => {
                setSegmentIndex(index);
                getSeatMap(segment.flightNumber, index);
              }}
              seatMap={seatMap}
              seatSsrs={seatSsrs}
              updateSeat={updateSeat}
            />
          ))}
        </Grid>
        <Grid item xs={11} style={{ margin: "10px 0px" }}>
          <Grid container justify="flex-end" direction="row">
            <Grid item md={2} xs={4}>
              <Button variant="contained" color="secondary" onClick={goNext}>
                Omitir
              </Button>
            </Grid>
            <Grid item md={2} xs={4}>
              <Button
                disabled={!checkInPassengers.length}
                variant="contained"
                color="primary"
                onClick={modifyBooking}
              >
                Reservar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
