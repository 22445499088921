import { FormValidator, } from './'
function filterAlpha(value){
    return  value.replace(/[^a-zA-ZÑñ]/g, "");
}
function filterNumber(value){
    return value.replace(/[^0-9]/g, "");
}
function filterAmount(value){
    return value.replace(/[^0-9.]/g, "");
}
function onBlurEmail( email ){
    //console.log(email)
    const validatorEmail = new FormValidator([
        {
          field: "email",
          method: "isEmail",
          validWhen: true,
          message: "Email invalido"
        }
    ])
    let validation=validatorEmail.validate({email});
    return validation.email.message;
}
function filterDocument(value){
    return value.replace(/[^0-9a-zA-Z]/g, "");
}
export {
    filterAlpha,
    filterNumber,
    onBlurEmail,
    filterAmount,
    filterDocument
}