import React from "react";

import { Button, Grid, Typography } from "@material-ui/core";

export default function PassengerMobile({passenger, isActivePassenger, selectPassenger}) {
  return (
    <Grid
      container
      spacing={2}
      justify="center"
      alignItems="center"
      direction="row"
      style={{
        paddingTop: "10px",
        paddingBottom: "10px",
        borderBottom: "1px solid #E0E0E0",
      }}
    >
      <Grid item xs={6}>
        <Typography color="secondary">
          {passenger.name} {passenger.lastName}
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography align="left" color="secondary">
          Asiento{" "}
          {passenger?.seat?.seatNumber
            ? passenger.seat.seatNumber
            : "S/A"}
        </Typography>
      </Grid>
      <Grid item xs={8}>
        <Grid container justify="flex-end" alignItems="center" direction="row">
          {isActivePassenger ? (
            <Button disabled>Seleccionando</Button>
          ) : passenger.checkedIn ? (
            <Button disabled>Completado</Button>
          ) : (
            <Button
              color="primary"
              variant="contained"
              disableElevation
              onClick={() => {
                selectPassenger();
              }}
            >
              {!passenger.checkedInComplete
                ? "Iniciar Selección"
                : "Modificar Selección"}
            </Button>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}