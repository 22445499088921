
import Modal from '@material-ui/core/Modal';
import React, {useState} from "react";
import { Grid, Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    paper: {
      position: 'absolute',
      width: 400,
      height:400,
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #FFF',
      boxShadow: theme.shadows[5],
      padding:"20px",
      borderRadius:"20px",
      outline: 'none'
    },
    paper2:{
      position: 'absolute',
      width: 400,
      height:200,
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #FFF',
      boxShadow: theme.shadows[5],
      padding:"20px",
      borderRadius:"20px",
      outline: 'none'
    },
    aceptButtonLabel: {
        color: "#ffffff",
        fontSize: "0.8rem",
        
    },
    h2:{
        color:"#777777",
        fontWeight: "unset",
        fontSize: "1rem",
    },
    modalText:{
        color:"#575756",
        fontWeight: "bold",
        fontSize: "0.8rem",
    },
    p:{
        fontSize:"1.3rem",
        color:"#848484",
    },
    highlightedP:{
        fontSize:"1.5rem",
        color:"#af0061",
    },
    [theme.breakpoints.down('sm')]:{ 
        h2:{
        fontSize: "1.1rem",
        },
        p:{
        fontSize:"1rem"
        },
        highlightedP:{
        fontSize:"1.2rem"
        },
        paper:{
          width: 340,
          height:410,
        }
    },
    
  }));
export default function ModalToHome(props){
    const classes=useStyles();
    const [modalStyle] = useState(getModalStyle);

    const goToHome=()=>{
        window.location.replace(`https://www.avior.com.ve/`);
    }

    function getModalStyle() {
        const top = 50;
        const left = 50;
        
        return {
            top: `${top}%`,
            left: `${left}%`,
            transform: `translate(-${top}%, -${left}%)`,
        };
    }
    return(
        props.modalToHome?
        <Modal  disableBackdropClick={true} onClose={props.close} open={props.open}>
            <Grid container justify="center" style={modalStyle} className={classes.paper2} >
                <Grid item md={12} xs={12} style={{backgroundColor:"#FFF", height:"25%",display:"flex", justifyContent:"center", alignItems:"flex-start"}}>
                <Typography className={classes.h2} align="center" >¡ATENCION!</Typography>                          
                </Grid>
                <Grid item md={12} xs={12} style={{backgroundColor:"#FFF", height:"50%", display:"flex", justifyContent:"center", alignItems:"center"}}>
                <Typography className={classes.modalText} align="center">¿Desea regresar al home?</Typography><br/>
                </Grid>
                <Grid item md={12} xs={12} style={{backgroundColor:"#FFF", height:"25%", alignItems:"flex-end"}}>
                <Grid container justify="space-between" >
                    <Grid item md={3} xs={3}>
                    <Typography style={{color:"#575756", fontWeight:"bold", cursor:"pointer"}} align="center" onClick={props.close} >
                        Cancelar
                    </Typography>
                    </Grid>
                    <Grid item md={3} xs={3}>
                    <Typography style={{color:"#CE2827", fontWeight:"bold", cursor:"pointer"}} align="center" onClick={goToHome}>
                        Aceptar
                    </Typography><br/>                
                    </Grid>
                </Grid>  
                </Grid>
            </Grid>
        </Modal>
        :
        <Modal open={props.open} onClose={props.close} disableBackdropClick={true}>  
            <Grid container justify="center" style={modalStyle} className={classes.paper} >
            <Grid item md={12} xs={12} style={{backgroundColor:"#FFF"}}>
                <Typography className={classes.h2} align="center" >¡SUSPENDISTE TU <strong>BUSQUEDA!</strong></Typography>
                <br/>
                <Typography className={classes.modalText} >
                    Puedes recuperar la selección de vuelos y nosotros comprobaremos automáticamente la disponibilidad y las tarifas 
                    disponibles. Puedes realizar nuevamente el proceso de búsqueda.
                </Typography >
                <br/>
                <Typography className={classes.modalText}>Te invitamos a ingresar de nuevo los datos y empezar una nueva consulta.</Typography>
                <Grid container justify="flex-end" alignItems="center" direction="column" style={{height:"200px", paddingBottom:"20px"}}>
                    <div style={{width:"100%", height:"60px", marginBottom:"20px"}}>
                        <Button variant="contained" fullWidth color={"primary"} style={{ height:"100%"}}
                            onClick={props.modalCheckFlight}
                        >
                            <label className={classes.aceptButtonLabel}>{"Carga la selección de vuelos anterior y comprueba disponibilidad y tarifas."}</label>
                        </Button>     
                    </div> 
                    <div style={{width:"100%", height:"41px"}}>
                        <Button variant="contained" fullWidth color={"primary"} style={{ height:"100%"}}
                            onClick={props.modalInit}
                        >
                            <label className={classes.aceptButtonLabel}>{"Iniciar."}</label>
                        </Button>     
                    </div>
                </Grid>
                </Grid>
            </Grid>        
        </Modal>
    )
}