import React, { useState } from "react";
import {
  StepsPaymentFromPnr,
  Footer,
  //Alert,
  //Spinner,
  //ModalPopUp,
} from "../components";
import { makeStyles } from "@material-ui/core/styles";
import { Container } from "@material-ui/core";
//import { useLocation } from "react-router-dom";
//import IdleTimer from "react-idle-timer";
import {
  //PaymentPaypal,
  //PaymentCreditCardPaypal,
  PaymentFromPNR,
  PaymentZelle,
  CreditCardForm,
  PaymentBss,
  //PaymentBookingCreditCardBancaAmiga
  PaymentBookingCreditCardTuOfertazo,
  PaymentLukaPay
} from ".";
export default function PaymentStepFromPnr() {
  const [step, setStep] = useState(0);

  const useStyles = makeStyles((theme) => ({
    paper: {
      //display: "flex",
      //flexDirection: "column",
      //alignItems: "center",
      width: "100%",
    },
    container: {
      paddingTop: theme.spacing(12),
      paddingBottom: theme.spacing(4),
    },
    bottom: {
      paddingBottom: theme.spacing(4),
    },
    [theme.breakpoints.down("md")]: {
      top: {
        paddingBottom: theme.spacing(4),
      },
    },
    [theme.breakpoints.up("md")]: {
      top: {
        paddingBottom: theme.spacing(4),
      },
    },
  }));
  const classes = useStyles();
  //const [open, setOpen] = useState(false);
  //const [openAlert, setOpenAlert] = useState(false);
  const [method, setMethod] = useState("");
  const [pnr, setPnr] = useState("");
  const [currency, setCurrency] = useState("");
  const [reservation, setReservation] = useState({});
  //const [showModal, setShowModal] = useState(false);
  //const [isTimedOut, setIsTimeOut] = useState(false);
  //const timeout=5000
  //const timeout = 5000 * 60;
  //
  //const [modalToHome, setModalToHome] = useState(false);
  //const [loading, setLoading] = useState(false);

  const [selectedAncillaries] = useState({
    "going":[],
    "goBack":[]
  })
  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <PaymentFromPNR
            sendData={(data) => {
              setMethod(data.method);
              setPnr(data.pnr);
              setCurrency(data.currency);
              setStep(1);
              setReservation(data.reservation);
              window.scrollTo(0, 0);
            }}
          />
        );
      case 1:
        if (method === "TDC") {
          return (
            <CreditCardForm
              pnr={pnr}
              currency={currency}
              originFlight={{
                fare: {
                  totalAmount: reservation.amount,
                },
              }}
              ancillaries={selectedAncillaries}
              goBack={() => {
                setStep(0);
                window.scrollTo(0, 0);
              }}
            />
          );
        } else if(method === "nodus"){
          return (
            <PaymentBookingCreditCardTuOfertazo
              pnr={pnr}
              currency={currency}
              originFlight={{
                fare: {
                  totalAmount: reservation.amount,
                },
              }}
              ancillaries={selectedAncillaries}
              //returnFlight={returnFlight}
              goBack={() => {
                setStep(0);
                window.scrollTo(0, 0);
              }}
            />
          )
        }
        else if(method === "BSS"){
          return (
            <PaymentBss
              pnr={pnr}
              currency={currency}
              originFlight={{
                fare: {
                  totalAmount: reservation.amount,
                },
              }}
              ancillaries={selectedAncillaries}
              //returnFlight={returnFlight}
              goBack={() => {
                setStep(0);
                window.scrollTo(0, 0);
              }}
            />
          )
        }else if(method === "luka_tdc"||method === "luka_zelle"||method === "luka_pm"){
            return (
              <PaymentLukaPay
              method={method}
              pnr={pnr}
              />
            )
        }else
          return (
            <PaymentZelle
              pnr={pnr}
              currency={currency}
              originFlight={{
                fare: {
                  totalAmount: reservation.amount,
                },
              }}

              ancillaries={selectedAncillaries}
              //returnFlight={returnFlight}
              goBack={() => {
                setStep(0);
                window.scrollTo(0, 0);
              }}
            />
          );
      default:
        throw new Error("Unknown step");
    }
  }

  function getActiveStep(step) {
    return step;
  }
  return (
    <div className={classes.paper}>
      <div className={classes.top}>
        <StepsPaymentFromPnr active={getActiveStep(step)} />
      </div>

      <Container maxWidth="lg" className={classes.bottom}>
        <div style={{ minHeight: "60vh" }}>{getStepContent(step)}</div>
      </Container>
      <Footer />
    </div>
  );
}
