import React, { useState, useEffect } from "react";
import { Grid, MenuItem, TextField, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { FormValidator, FormatCurrency, filterDate } from "../helpers";
import {
  ButtonSpinner,
  Toast,
  Spinner,
  StaticDatePicker,
  ModalLukaPay,
} from "../components";
import {
  paymentServices,
  dolarPriceServices,
  installmentsServices,
} from "../services";
import { ExpandMore } from "@material-ui/icons";
import { useHistory } from "react-router-dom";

const banks = [
  { value: "0156", name: "100% BANCO" },
  { value: "0196", name: "ABN AMRO BANK" },
  { value: "0172", name: "BANCAMIGA BANCO MICROFINANCIERO" },
  { value: "0171", name: "BANCO ACTIVO BANCO COMERCIAL" },
  { value: "0166", name: "BANCO AGRICOLA" },
  { value: "0175", name: "BANCO BICENTENARIO" },
  { value: "0128", name: "BANCO CARONI BANCO UNIVERSAL" },
  { value: "0164", name: "BANCO DE DESARROLLO DEL MICROEMPRESARIO" },
  { value: "0102", name: "BANCO DE VENEZUELA" },
  { value: "0114", name: "BANCO DEL CARIBE" },
  { value: "0149", name: "BANCO DEL PUEBLO SOBERANO" },
  { value: "0163", name: "BANCO DEL TESORO" },
  { value: "0176", name: "BANCO ESPIRITO SANTO" },
  { value: "0115", name: "BANCO EXTERIOR" },
  { value: "0003", name: "BANCO INDUSTRIAL DE VENEZUELA" },
  { value: "0173", name: "BANCO INTERNACIONAL DE DESARROLLO" },
  { value: "0105", name: "BANCO MERCANTIL" },
  { value: "0191", name: "BANCO NACIONAL DE CREDITO" },
  { value: "0116", name: "BANCO OCCIDENTAL DE DESCUENTO" },
  { value: "0138", name: "BANCO PLAZA" },
  { value: "0108", name: "BANCO PROVINCIAL BBVA" },
  { value: "0104", name: "BANCO VENEZOLANO DE CREDITO" },
  { value: "0168", name: "BANCRECER BANCO DE DESARROLLO" },
  { value: "0134", name: "BANESCO BANCO UNIVERSAL" },
  { value: "0177", name: "BANFANB" },
  { value: "0146", name: "BANGENTE" },
  { value: "0174", name: "BANPLUS BANCO COMERCIAL" },
  { value: "0190", name: "CITIBANK" },
  { value: "0121", name: "CORP BANCA" },
  { value: "0157", name: "DELSUR BANCO UNIVERSAL" },
  { value: "0151", name: "FONDO COMUN" },
  { value: "0601", name: "INSTITUTO MUNICIPAL DE CRÉDITO POPULAR" },
  { value: "0169", name: "MIBANCO BANCO DE DESARROLLO" },
  { value: "0137", name: "SOFITASA" },
];

const phoneCodes = [
  { value: "58414", name: "0414" },
  { value: "58424", name: "0424" },
  { value: "58416", name: "0416" },
  { value: "58426", name: "0426" },
  { value: "58412", name: "0412" },
];

const useStyles = makeStyles((theme) => ({
  inputGrid: {
    marginBottom: "15px",
  },
  Grid: {
    display: "flex",
    justifyContent: "center",
  },
  floatingLabelFocusStyle: {
    color: "#CD171A",
    fontSize: "15pt",
    width: "max-content",
  },
  inputLabel: {
    fontSize: "14pt",
    paddingBottom: 0,
    color: "#555555!important",
    height: "20px",
  },
  underline: {
    "&::before": {
      display: "none",
    },
    "&::after": {
      display: "none",
    },
  },
  inputDiv: {
    borderWidth: "1pt",
    borderStyle: "solid",
    borderColor: "#cccccc",
    padding: "5px 10px",
    borderRadius: "5px",
    "& .MuiFormControl-root": {
      width: "100%",
    },
  },
  inputCodeDiv: {
    borderWidth: "1pt",
    borderStyle: "solid",
    borderColor: "#cccccc",
    padding: "5px 10px",
    borderRadius: "5px",
    "& .MuiFormControl-root": {
      width: "100%",
    },
  },
  expandIcon: {
    color: "#FA7268",
  },
  [theme.breakpoints.down("md")]: {
    floatingLabelFocusStyle: {
      fontSize: "0.9rem",
    },
    inputLabel: {
      fontSize: "0.8rem",
    },
  },
}));

export default function PaymentBAPM(props) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [toast, setToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  const [email, setEmail] = useState("");
  const [ref, setRef] = useState("");
  const [emailError, setEmailError] = useState("");
  const [refError, setRefError] = useState("");
  const [bank, setBank] = useState("");
  const [bankError, setBankError] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [phoneCode, setPhoneCode] = useState("");
  const [toastLuka, setToastLuka] = useState(true);
  const [totalAmount, setTotalAmount] = useState("0.00");
  const [date, setDate] = useState(new Date());
  let history = useHistory();

  const validator = new FormValidator([
    {
      field: "email",
      method: "isEmpty",
      validWhen: false,
      message: "Debe ingresar su email",
    },
    {
      field: "email",
      method: "isEmail",
      validWhen: true,
      message: "Ingrese un correo electrónico valido",
    },
    {
      field: "ref",
      method: "isEmpty",
      validWhen: false,
      message: "Ingrese la referencia bancaria",
    },
    {
      field: "bank",
      method: "isEmpty",
      validWhen: false,
      message: "Seleccione la entidad bancaria",
    },
    {
      field: "phone",
      method: "isEmpty",
      validWhen: false,
      message: "Ingrese el número de teléfono ",
    },
    {
      field: "phoneCode",
      method: "isEmpty",
      validWhen: false,
      message: "Ingrese el código",
    },
  ]);

  const getAncillariesAmount = () => {
    return parseFloat(
      props.ancillaries.going.reduce(function (a, b) {
        return parseFloat(a) + parseFloat(b.total);
      }, 0) +
        props.ancillaries.goBack.reduce(function (a, b) {
          return parseFloat(a) + parseFloat(b.total);
        }, 0)
    ).toFixed(2);
  };

  const getAmountSeats = () => {
    return parseFloat(
      props.selectedSeats.going.reduce(function (a, b) {
        return parseFloat(a) + parseFloat(b.total);
      }, 0) +
        props.selectedSeats.goBack.reduce(function (a, b) {
          return parseFloat(a) + parseFloat(b.total);
        }, 0)
    ).toFixed(2);
  };

  const getInstallments = async ({ pnr }) => {
    const { response, status } =
      await installmentsServices.getInstallmentsPreview({ pnr });

    setLoading(false);

    if (status !== 200) {
      setToast(true);
      setToastMessage(response.error.message);
    }

    return response;
  };

  const getPrice = async () => {
    setLoading(true);

    const { status, response } = await dolarPriceServices.getPrice();

    setLoading(false);

    if (status === 200) {
      const totalAncillaries = getAncillariesAmount();
      const totalSeats = getAmountSeats();
      const faresAmount =
        parseFloat(
          props.originFlight ? props.originFlight.fare.totalAmount : 0
        ) +
        parseFloat(
          props.returnFlight ? props.returnFlight.fare.totalAmount : 0
        );
      setTotalAmount(
        parseFloat(
          parseFloat(
            parseFloat(faresAmount) +
              parseFloat(totalAncillaries) +
              parseFloat(totalSeats)
          ) * parseFloat(response.price)
        ).toFixed(2)
      );
    }
  };

  const getPriceInstallment = async () => {
    setLoading(true);

    const { status, response } = await dolarPriceServices.getPrice();

    if (status === 200) {
      const installmentsRes = await getInstallments({ pnr: props.pnr });

      if (!installmentsRes?.error) {
        setTotalAmount(
          parseFloat(
            parseFloat(installmentsRes[0].amount) * parseFloat(response.price)
          ).toFixed(2)
        );
      }
    }

    setLoading(false);
  };

  const getPriceAmount = async ({ amount }) => {
    setLoading(true);

    const { status, response } = await dolarPriceServices.getPrice();

    if (status === 200) {
      setTotalAmount(
        parseFloat(parseFloat(amount) * parseFloat(response.price)).toFixed(2)
      );
    }

    setLoading(false);
  };

  const validateForms = async () => {
    const validation = validator.validate({
      email,
      ref,
      bank,
      phone,
      phoneCode,
    });
    setEmailError(validation.email.message);
    setRefError(validation.ref.message);
    setBankError(validation.bank.message);
    setPhoneError(
      validation.phone.message !== ""
        ? validation.phone.message
        : validation.phoneCode.message
    );
    if (validation.isValid) {
      let user_id;

      if (props.userData) {
        user_id = props.userData.user_info
          ? props.userData.user_info?.user_id
          : props.userData?.user_id;
      }

      const request = {
        pnr: props.pnr,
        email,
        user: user_id ? user_id : null,
        installment: props.installmentId ? props.installmentId : null,
        paymentInfo: {
          ref,
          bank,
          phone: `${phoneCode}${phone}`,
          date: filterDate(date),
        },
      };

      setLoading(true);

      const responseData = props.installments
        ? await paymentServices.paymentInstallmentsBAPM(request)
        : await paymentServices.paymentBAPM(request);

      const { response, status } = responseData;

      setLoading(false);

      if (status === 200) {
        redirectThank({ response, status: response.status });
      } else if (status === 400) {
        setToast(true);
        setToastMessage(response.error.message);
      }
    }
  };

  const redirectThank = ({ response, status }) => {
    let urlThank;

    if (status === "approved") {
      urlThank = "/thankyoupage";
    } else {
      urlThank = "/thankyoupagependding";
    }

    const params = {
      ...response,
      passengers: response.passengers?.length
        ? response.passengers
        : response?.reservationdetails.passengers,
      reservationDetails: response?.reservationdetails,
      installments: props.installments ? true : false,
      //
      originFlight: props.originFlight,
      returnFlight: props.returnFlight,
    };

    history.push(urlThank, {
      ...params,
    });
  };

  useEffect(() => {
    if (props.installments) {
      if (props.installmentInfo) {
        getPriceAmount({ amount: props.installmentInfo?.amount });
      } else {
        getPriceInstallment();
      }
    } else {
      getPrice();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Grid
        container
        justify="center"
        direction="row"
        alignItems="center"
        spacing={1}
      >
        <Grid item md={12} xs={12} className={classes.Grid}>
          <Grid item md={5} xs={12} className={classes.inputGrid}>
            <Typography>
              <strong>Pago móvil:</strong>
            </Typography>
            <Typography>
              <strong>Titular:</strong> Avior Airlines C.A.
            </Typography>
            <Typography>
              <strong>Banco:</strong> 0172 BANCAMIGA
            </Typography>
            <Typography>
              <strong>Rif:</strong> J-302097843
            </Typography>
            <Typography>
              <strong>Teléfono:</strong> 0424-8925387
            </Typography>
          </Grid>
        </Grid>
        <Grid item md={12} xs={12} className={classes.Grid}>
          <Typography variant="h5">
            Total a Pagar VES Bs{" "}
            {FormatCurrency(`${parseFloat(totalAmount).toFixed(2)}`)}
          </Typography>
        </Grid>
        <Grid item md={12} xs={12} className={classes.Grid}>
          <Grid item md={5} xs={12} className={classes.inputGrid}>
            <Typography>
              <strong>Detalles del pago móvil.</strong>
            </Typography>
          </Grid>
        </Grid>
        <Grid item md={12} xs={12} className={classes.Grid}>
          <Grid item md={5} xs={12} className={classes.inputGrid}>
            <div className={classes.inputDiv}>
              <TextField
                value={email}
                label="Email"
                name="email"
                id="email"
                fullWidth
                helperText={emailError}
                error={emailError !== ""}
                InputLabelProps={{
                  shrink: true,
                  className: classes.floatingLabelFocusStyle,
                  htmlFor: "email",
                }}
                InputProps={{
                  classes: {
                    input: classes.inputLabel,
                    underline: classes.underline,
                  },
                }}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </Grid>
        </Grid>
        <Grid item md={12} xs={12} className={classes.Grid}>
          <Grid item md={2} xs={5} className={classes.inputGrid}>
            <div className={classes.inputCodeDiv}>
              <TextField
                value={phoneCode}
                label="Cod"
                name="code"
                id="code"
                fullWidth
                select
                InputLabelProps={{
                  shrink: true,
                  className: classes.floatingLabelFocusStyle,
                  htmlFor: "phone",
                }}
                InputProps={{
                  classes: {
                    input: classes.inputLabel,
                    underline: classes.underline,
                  },
                }}
                SelectProps={{
                  IconComponent: () => (
                    <ExpandMore className={classes.expandIcon} />
                  ),
                }}
                onChange={(e) => setPhoneCode(e.target.value)}
              >
                {phoneCodes.map((code, index) => (
                  <MenuItem key={index} value={code.value}>
                    {code.name}
                  </MenuItem>
                ))}
              </TextField>
            </div>
          </Grid>
          <Grid item md={3} xs={7} className={classes.inputGrid}>
            <div className={classes.inputDiv}>
              <TextField
                value={phone}
                label="Número de teléfono "
                name="phone"
                id="phone"
                fullWidth
                helperText={phoneError}
                error={phoneError !== ""}
                InputLabelProps={{
                  shrink: true,
                  className: classes.floatingLabelFocusStyle,
                  htmlFor: "phone",
                }}
                InputProps={{
                  classes: {
                    input: classes.inputLabel,
                    underline: classes.underline,
                  },
                }}
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>
          </Grid>
        </Grid>
        <Grid item md={12} xs={12} className={classes.Grid}>
          <Grid item md={5} xs={12} className={classes.inputGrid}>
            <div className={classes.inputDiv}>
              <TextField
                value={bank}
                label="Entidad bancaria"
                name="bank"
                fullWidth
                helperText={bankError}
                select
                error={bankError !== ""}
                InputLabelProps={{
                  shrink: true,
                  className: classes.floatingLabelFocusStyle,
                  htmlFor: "bank",
                }}
                InputProps={{
                  classes: {
                    input: classes.inputLabel,
                    underline: classes.underline,
                  },
                }}
                onChange={(e) => setBank(e.target.value)}
                SelectProps={{
                  IconComponent: () => (
                    <ExpandMore className={classes.expandIcon} />
                  ),
                }}
              >
                {banks.map((bank, index) => (
                  <MenuItem key={index} value={bank.value}>
                    {bank.name}
                  </MenuItem>
                ))}
              </TextField>
            </div>
          </Grid>
        </Grid>
        <Grid item md={12} xs={12} className={classes.Grid}>
          <Grid item md={5} xs={12} className={classes.inputGrid}>
            <div className={classes.inputDiv}>
              <TextField
                value={ref}
                label="Referencia Bancaria"
                name="ref"
                fullWidth
                helperText={refError}
                error={refError !== ""}
                InputLabelProps={{
                  shrink: true,
                  className: classes.floatingLabelFocusStyle,
                  htmlFor: "ref",
                }}
                InputProps={{
                  classes: {
                    input: classes.inputLabel,
                    underline: classes.underline,
                  },
                }}
                onChange={(e) => setRef(e.target.value)}
              />
              <span
                style={{
                  color: "rgba(0, 0, 0, 0.54)",
                  fontSize: "0.75rem",
                }}
              >
                6 últimos dígitos
              </span>
            </div>
          </Grid>
        </Grid>
        <Grid item md={12} xs={12} className={classes.Grid}>
          <Grid item md={5} xs={12} className={classes.inputGrid}>
            <div className={classes.inputDiv}>
              <StaticDatePicker
                value={date}
                label="Fecha"
                toolbar={true}
                disableFuture
                inputVariant={"standard"}
                //helperText={selectBirthDayError || selectBirthDayTypeError}
                //error={
                //  selectBirthDayError !== "" ||
                //  selectBirthDayTypeError !== ""
                //}
                InputLabelProps={{
                  shrink: true,
                  className: classes.floatingLabelFocusStyle,
                  htmlFor: `${"birthDay"}${props.index}`,
                }}
                InputProps={{
                  classes: {
                    input: classes.inputLabel,
                    underline: classes.underline,
                  },
                }}
                onChange={(dateSelect) => {
                  setDate(dateSelect);
                }}
              />
            </div>
          </Grid>
        </Grid>
        <Grid item md={12} xs={12} className={classes.Grid}>
          <Grid item md={5} xs={12} className={classes.inputGrid}>
            <Grid container justify="space-between">
              <div style={{ width: "158px", height: "41px" }}>
                {props.goBack ? (
                  <ButtonSpinner
                    action={() => {
                      props.goBack();
                    }}
                    fullWidth
                    text="Atras"
                  />
                ) : null}
              </div>
              <div style={{ width: "158px", height: "41px" }}>
                <ButtonSpinner
                  action={validateForms}
                  fullWidth
                  text="Continuar"
                />
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <ModalLukaPay open={toastLuka} close={() => setToastLuka(false)} />
      <Toast
        message={toastMessage}
        open={toast}
        close={() => {
          setToast(false);
        }}
      />
      <Spinner loading={loading} />
    </div>
  );
}
