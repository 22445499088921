import React, { useState } from "react";
import { Grid, TextField, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { FormValidator } from "../../helpers/";
import { ButtonSpinner, Toast, Spinner } from "../../components/";
import { paymentServices } from "../../services";
import { useHistory } from "react-router-dom";
import {
  formatCreditCardNumber,
  filterFullName,
  formatExpirationDate,
  formatCVC,
} from "../../helpers/credit-card-filters";
import Cards from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";
import { dateValid, FormatCurrency } from "../../helpers";
const useStyles = makeStyles((theme) => ({
  inputGrid: {
    marginBottom: "15px",
  },
  Grid: {
    display: "flex",
    justifyContent: "center",
  },
  floatingLabelFocusStyle: {
    color: "#CD171A",
    fontSize: "15pt",
    width: "max-content",
  },
  inputLabel: {
    fontSize: "14pt",
    paddingBottom: 0,
    color: "#555555!important",
    height: "20px",
  },
  underline: {
    "&::before": {
      display: "none",
    },
    "&::after": {
      display: "none",
    },
  },
  inputDiv: {
    borderWidth: "1pt",
    borderStyle: "solid",
    borderColor: "#cccccc",
    padding: "10px",
    borderRadius: "5px",
    "& .MuiFormControl-root": {
      width: "100%",
    },
  },
  expandIcon: {
    color: "#FA7268",
  },
  [theme.breakpoints.down("md")]: {
    floatingLabelFocusStyle: {
      fontSize: "0.9rem",
    },
    inputLabel: {
      fontSize: "0.8rem",
    },
  },
}));
export default function CreditCardForm(props) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [toast, setToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [fullName, setFullName] = useState("");
  const [date, setDate] = useState("");
  const [cvc, setCvc] = useState("");
  const [focused, setFocused] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [errors, setErrors] = useState("");
  const [email, setEmail] = useState("");
  let history = useHistory();
  const validator = new FormValidator([
    {
      field: "cardNumber",
      method: "isEmpty",
      validWhen: false,
      message: "Debe ingresar su número de tarjeta",
    },
    {
      field: "email",
      method: "isEmpty",
      validWhen: false,
      message: "Debe ingresar email",
    },
    {
      field: "email",
      method: "isEmail",
      validWhen: true,
      message: "Ingrese un correo electrónico valido",
    },
    //{
    //  field: "cardNumber",
    //  method: "isCreditCard",
    //  validWhen: false,
    //  message: "Ingrese un número de tarjeta valido",
    //},
    {
      field: "date",
      method: "isEmpty",
      validWhen: false,
      message: "Ingrese la fecha de vencimiento de su tarjeta de crédito.",
    },
    {
      field: "date",
      method: dateValid,
      validWhen: true,
      message: "La fecha de vencimiento no es valida",
    },
    {
      field: "cvc",
      method: "isEmpty",
      validWhen: false,
      message: "Ingrese el CVC de su tarjeta de crédito.",
    },
    {
      field: "cvc",
      method: "isLength",
      validWhen: true,
      args: [{ min: 3, max: 4 }],
      message: "El CVC no es valido",
    },
    {
      field: "fullName",
      method: "isEmpty",
      validWhen: false,
      message: "Ingrese su nombre.",
    },
    {
      field: "postalCode",
      method: "isEmpty",
      validWhen: false,
      message: "Ingrese el código postal.",
    },
  ]);

  const onFocus = (e) => {
    setFocused(e.target.name);
  };
  const paymentCreditCard = async () => {
    const validation = validator.validate({
      email,
      cardNumber: cardNumber.replace(/\D+/g, ""),
      date,
      cvc,
      fullName,
      postalCode,
    });
    setErrors(validation);
    if (validation.isValid) {
      const dateFormat = date.split("/");
      const body = {
        passenger: props.passenger,
        payment:{
            ano: dateFormat[1],
            mes: dateFormat[0],
            cvc,
            direccion_postal: postalCode,
            email,
            numero: cardNumber.replace(/\D+/g, ""),
            descripcion:"Pago cupon air",
            nombre: fullName,
        }
      };
      setLoading(true);
      const { response, status } = await paymentServices.payCreditCardPromo(body);
      setLoading(false);
      //console.log(response)
      if (status === 200) {
        if(response.payment.status==="rejected"){
          setToastMessage(response.payment.respuesta_data);
          setToast(true);
        }else{
          history.push(`/thankyoupagepromo`, {status:"approved"})
        }
      } else {
        setToastMessage(response.error.message);
        setToast(true);
      }
    }
  };
  return (
    <div>
      <Grid
        container
        justify="center"
        direction="row"
        alignItems="center"
        spacing={1}
      >
        <Grid item md={12} xs={12} className={classes.Grid}>
          <Grid item md={5} xs={12}>
            <Cards
              cvc={cvc}
              expiry={date}
              focused={focused}
              name={fullName}
              number={cardNumber}
            />
          </Grid>
        </Grid>
        <Grid item md={12} xs={12} className={classes.Grid}>
          <Grid item md={5} xs={12} className={classes.inputGrid}>
            <div className={classes.inputDiv}>
              <TextField
                value={email}
                label="Correo electrónico"
                name="email"
                id="email"
                fullWidth
                error={errors.email ? errors.email.isInvalid : false}
                helperText={errors.email ? errors.email.message : ""}
                InputLabelProps={{
                  shrink: true,
                  className: classes.floatingLabelFocusStyle,
                  htmlFor: "email",
                }}
                InputProps={{
                  classes: {
                    input: classes.inputLabel,
                    underline: classes.underline,
                  },
                }}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </Grid>
        </Grid>
        <Grid item md={12} xs={12} className={classes.Grid}>
          <Grid item md={5} xs={12} className={classes.inputGrid}>
            <div className={classes.inputDiv}>
              <TextField
                value={cardNumber}
                label="Número de tarjeta"
                name="number"
                id="number"
                fullWidth
                error={errors.cardNumber ? errors.cardNumber.isInvalid : false}
                helperText={errors.cardNumber ? errors.cardNumber.message : ""}
                InputLabelProps={{
                  shrink: true,
                  className: classes.floatingLabelFocusStyle,
                  htmlFor: "number",
                }}
                InputProps={{
                  classes: {
                    input: classes.inputLabel,
                    underline: classes.underline,
                  },
                }}
                onFocus={onFocus}
                onChange={(e) =>
                  setCardNumber(formatCreditCardNumber(e.target.value))
                }
                onSelect={onFocus}
              />
            </div>
          </Grid>
        </Grid>
        <Grid item md={12} xs={12} className={classes.Grid}>
          <Grid item md={5} xs={12} className={classes.inputGrid}>
            <div className={classes.inputDiv}>
              <TextField
                value={fullName}
                label="Nombre y apellido"
                name="name"
                id="name"
                fullWidth
                error={errors.fullName ? errors.fullName.isInvalid : false}
                helperText={errors.fullName ? errors.fullName.message : ""}
                InputLabelProps={{
                  shrink: true,
                  className: classes.floatingLabelFocusStyle,
                  htmlFor: "name",
                }}
                InputProps={{
                  classes: {
                    input: classes.inputLabel,
                    underline: classes.underline,
                  },
                }}
                onChange={(e) => setFullName(filterFullName(e.target.value))}
                onSelect={onFocus}
              />
            </div>
          </Grid>
        </Grid>
        <Grid item md={12} xs={12} className={classes.Grid}>
          <Grid item md={5} xs={12} className={classes.inputGrid}>
            <div className={classes.inputDiv}>
              <TextField
                value={date}
                placeholder="MM/YY"
                label="Fecha de vencimiento"
                name="expiry"
                id="expiry"
                fullWidth
                error={errors.date ? errors.date.isInvalid : false}
                helperText={errors.date ? errors.date.message : ""}
                InputLabelProps={{
                  shrink: true,
                  className: classes.floatingLabelFocusStyle,
                  htmlFor: "expiry",
                }}
                InputProps={{
                  classes: {
                    input: classes.inputLabel,
                    underline: classes.underline,
                  },
                }}
                onChange={(e) => setDate(formatExpirationDate(e.target.value))}
                onSelect={onFocus}
              />
            </div>
          </Grid>
        </Grid>
        <Grid item md={12} xs={12} className={classes.Grid}>
          <Grid item md={5} xs={12} className={classes.inputGrid}>
            <div className={classes.inputDiv}>
              <TextField
                value={cvc}
                label="CVC"
                name="cvc"
                id="cvc"
                fullWidth
                error={errors.cvc ? errors.cvc.isInvalid : false}
                helperText={errors.cvc ? errors.cvc.message : ""}
                InputLabelProps={{
                  shrink: true,
                  className: classes.floatingLabelFocusStyle,
                  htmlFor: "cvc",
                }}
                InputProps={{
                  classes: {
                    input: classes.inputLabel,
                    underline: classes.underline,
                  },
                }}
                onChange={(e) => setCvc(formatCVC(e.target.value, cardNumber))}
                onBlur={() => setFocused("")}
                onSelect={onFocus}
              />
            </div>
          </Grid>
        </Grid>
        <Grid item md={12} xs={12} className={classes.Grid}>
          <Grid item md={5} xs={12} className={classes.inputGrid}>
            <div className={classes.inputDiv}>
              <TextField
                value={postalCode}
                label="Código postal"
                name="postalCode"
                id="postalCode"
                fullWidth
                error={errors.postalCode ? errors.postalCode.isInvalid : false}
                helperText={errors.postalCode ? errors.postalCode.message : ""}
                InputLabelProps={{
                  shrink: true,
                  className: classes.floatingLabelFocusStyle,
                  htmlFor: "postalCode",
                }}
                InputProps={{
                  classes: {
                    input: classes.inputLabel,
                    underline: classes.underline,
                  },
                }}
                onChange={(e) => setPostalCode(e.target.value)}
              />
            </div>
          </Grid>
        </Grid>
        <Grid item md={12} xs={12} className={classes.Grid}>
          <Typography variant="h5">
            Total a Pagar USD $
            {FormatCurrency("100.00")}
          </Typography>
        </Grid>
        <Grid item md={12} xs={12} className={classes.Grid}>
          <Grid item md={5} xs={12} className={classes.inputGrid}>
            <Grid container justify="space-between">
              <div style={{ width: "158px", height: "41px" }}>
                <ButtonSpinner
                  action={() => {
                    props.goBack();
                  }}
                  fullWidth
                  text="Atras"
                />
              </div>
              <div style={{ width: "158px", height: "41px" }}>
                <ButtonSpinner
                  action={paymentCreditCard}
                  fullWidth
                  text="Pagar"
                />
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Toast
        message={toastMessage}
        open={toast}
        close={() => {
          setToast(false);
        }}
      />
      <Spinner loading={loading} />
    </div>
  );
}
