import React from "react";
import { Grid, MenuItem, TextField } from "@material-ui/core/";
import { makeStyles } from "@material-ui/core/styles";
import { ExpandMore } from "@material-ui/icons";
import { styled } from "@material-ui/core/styles";
import { Select } from ".";
const MyExpandExpandMore = styled(ExpandMore)({
  color: "#FA7268",
  "&.MuiSelect-iconOpen": {
    transform: "none",
  },
});
const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "70%",
  },
  input: {
    "&:before": {
      borderBottom: "1px solid #CD171A",
    },
    "&:after": {
      borderBottom: "2px solid #CD171A",
    },
    "&:focus": {
      borderBottom: "2px solid #CD171A",
    },
    color: "#575756",
    fontSize: "0.8rem",
  },
  label: {
    color: "#575756",
    width: "35%",
    fontSize: "0.8rem",
    fontWeight: "400",
    lineHeight: "1.5",
  },
  container: {
    display: "inline-flex",
    alignItems: "center",
    verticalAlign: "middle",
    width: "100%",
  },
  floatingLabelFocusStyle: {
    color: "#CD171A",
    fontSize: "15pt",
  },
  inputLabel: {
    fontSize: "14pt",
    paddingBottom: 0,
    color: "#555555!important",
  },
  underline: {
    "&::before": {
      display: "none",
    },
    "&::after": {
      display: "none",
    },
  },
  selectLabel: {
    color: "#CD171A",
    fontSize: "11.5pt",
  },
  inputDiv: {
    borderWidth: "1pt",
    borderStyle: "solid",
    borderColor: "#cccccc",
    padding: "10px",
    borderRadius: "5px",
    "& .MuiFormControl-root": {
      width: "100%",
    },
  },
  [theme.breakpoints.down("md")]: {
    floatingLabelFocusStyle: {
      fontSize: "13pt",
    },
  },
}));
export default function Currency(props) {
  const classes = useStyles();
  const currencies = [
    {
      name: "Dolares",
      code: "USD",
    },
    //{
    //  name: "Pesos",
    //  code: "COP",
    //},
    //{
    //  name:"Florines",
    //  code:"AWG"
    //},
    //{
    //  name: "Bolivares",
    //  code: "VES",
    //},
  ];
  function changeCurrencyType(type) {
    props.setCurrency(type);
  }
  return (
    <Grid container justify="center" direction="row" alignItems="center">
      <Grid item xs={12}>
        {props.isHeader ? (
          <div>
            <div className={classes.container}>
              <span className={classes.label}>Moneda</span>
              <Select
                variant="standard"
                classes={classes.input}
                value={props.currency}
                onChange={(e) => {
                  changeCurrencyType(e.target.value);
                }}
              >
                {currencies.map((currency, index) => (
                  <MenuItem key={index} value={currency.code}>
                    {currency.name} {currency.code}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </div>
        ) : (
          <div className={classes.inputDiv}>
            <TextField
              value={props.currency}
              label="Moneda"
              select
              id="currency"
              InputLabelProps={{
                shrink: true,
                className: classes.floatingLabelFocusStyle,
                htmlFor: "currency",
              }}
              InputProps={{
                /*disabled:fields,*/ classes: {
                  input: classes.inputLabel,
                  underline: classes.underline,
                },
              }}
              SelectProps={{ IconComponent: MyExpandExpandMore }}
              onChange={(e) => {
                changeCurrencyType(e.target.value);
              }}
            >
              {currencies.map((currency, index) => (
                <MenuItem key={index} value={currency.code}>
                  {currency.name} {currency.code}
                </MenuItem>
              ))}
            </TextField>
          </div>
        )}
      </Grid>
    </Grid>
  );
}

/*
import React from "react";
import { ButtonGroup, Button } from "@material-ui/core/";

export default function Currency(props){
    function changeCurrencyType(type){
        props.setCurrency(type)
    }
    return(
        <ButtonGroup fullWidth>
            <Button style={{color: (props.currency==="VES") ?"#FFF":"#575756", fontWeight:"bold"}} size="small" 
                color={ (props.currency==="VES") ? "primary":null} 
                variant={ (props.currency==="VES")? "contained":null}  
                onClick={()=>changeCurrencyType("VES")}
            >
                VES
            </Button>
            <Button style={{color:(props.currency!=="VES")?"#FFF":"#575756", fontWeight:"bold"}} 
                color={(props.currency!=="VES")?"primary":null}
                size="small" 
                variant={(props.currency!=="VES")?"contained":null} 
                onClick={()=>changeCurrencyType("USD")}
            >
                USD
            </Button>
        </ButtonGroup>
    );
}
*/
