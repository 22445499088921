import React, { useState, useEffect } from "react";
import { Grid, Box, Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Spinner } from ".";
import { gatewaysServices, installmentsServices } from "../services";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import moment from "moment";
import ModalLogin from "./modal-login";

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: 10,
    boxShadow: theme.shadows[2],
  },
  p: {
    fontSize: "0.9rem",
  },
  grid: {
    padding: "15px 15px 40px 15px",
  },
  buttons: {
    display: "flex",
    justifyContent: "center",
    marginTop: "15px",
  },
  check: {
    width: "95%",
    display: "flex",
    flexDirection: "column",
  },
  check2: {
    width: "95%",
    display: "flex",
    flexDirection: "column",
  },
  imgContainer: {
    display: "block",
    minHeight: "60px",
  },
  imgCash: {
    width: 40,
  },
  imgFlex: {
    width: 90,
  },
  imgBss: {
    width: "90px",
  },
  imgBssBanesco: {
    width: "120px",
  },
  formControl: {
    display: "block",
  },
  [theme.breakpoints.down("md")]: {
    imgContainer: {
      display: "flex",
      alignItems: "center",
    },
    img: {
      width: "100%",
    },
    imgPaypal: {
      width: "75%",
    },
    formControl: {
      display: "flex",
      justifyContent: "flex-start",
    },
    check: {
      flexDirection: "row",
    },
    check2: {
      flexDirection: "row",
    },
    imgBss: {
      width: "70px",
    },
  },
  [theme.breakpoints.up("md")]: {
    check2: {
      width: "100%",
      borderLeft: "1pt solid #cccccc",
    },
    imgPaypal: {
      width: "25%",
    },
    imgBss: {
      width: "70px",
    },
  },
}));

export default function PaymentMethodsNew(props) {
  const classes = useStyles();

  const [typeSelect, setTypeSelect] = useState(null);
  const [methodSelect, setMethodSelect] = useState(null);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [methodsView, setMethodsView] = useState([]);
  const [installmentsPreview, setInstallmentsPreview] = useState([]);
  const [installmentsError, setInstallmentsError] = useState(null);

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(true);
  const [showLogin, setShowLogin] = useState(false);

  const typeOptions = [
    {
      name: "cash",
      text: "De contado",
      img: "./img/payment-cash.png",
    },
    {
      name: "installments",
      text: "",
      img: "./img/flexi-pagos.png",
    },
  ];

  const installmentsMethods = [
    //"bapm",
    "stripe",
    "luka_zelle",
    "luka_banesco",
    "btp2p",
  ];

  const PaymentType = ({ name, text, img }) => {
    let styleImg;

    if (name === "installments") {
      styleImg = classes.imgFlex;
    } else {
      styleImg = classes.imgCash;
    }

    return (
      <Box
        key={name}
        display="flex"
        alignItems="center"
        justifyContent="center"
        width={300}
        height={100}
        m={2}
        border={`2px solid ${typeSelect === name ? "#CD171A" : "#fff"}`}
        className={classes.card}
        style={{ cursor: "pointer", fontSize: "1rem" }}
        onClick={() => {
          setTypeSelect(name);
          props.selectMethod(null);
          setMethodSelect(null);
        }}
      >
        <img src={img} className={styleImg} alt="" style={{ margin: 10 }} />{" "}
        {text}
      </Box>
    );
  };

  const getGateways = async () => {
    setLoading(true);
    const { response } = await gatewaysServices.getGateways("booking");

    /* response.push({
      type: "booking",
      name: "Millas",
      img: "./img/aviorplus.png",
      value: "miles",
      style: "check",
      status: true,
      description: "Avior plus",
    });
   */

    setPaymentMethods(response);
    setLoading(false);
  };

  const getInstallments = async ({ pnr }) => {
    setLoading(true);
    const { response, status } =
      await installmentsServices.getInstallmentsPreview({ pnr });

    if (status !== 200) {
      if (response?.error?.message) {
        setInstallmentsError(response?.error.message);
      } else {
        setInstallmentsError("Error al consultar las cuotas");
      }

      setInstallmentsPreview([]);
    } else {
      setInstallmentsError(null);
      setInstallmentsPreview(response);
    }

    setLoading(false);
  };

  useEffect(() => {
    getGateways();
  }, []);

  useEffect(() => {
    let methods = [];

    if (typeSelect === "cash") {
      methods = paymentMethods;
      props.setInstallments(false);
    } else if (typeSelect === "installments" && props.userData) {
      methods = paymentMethods.filter((item) =>
        installmentsMethods.includes(item.value)
      );

      // BAPM ESTATICO
      /* methods.push({
        type: "booking",
        name: "Pago movil",
        img: "./img/bss.png",
        value: "bapm",
        style: "check",
        status: true,
        description: "Pago movil Bancamiga",
      }); */

      props.setInstallments(true);

      getInstallments({ pnr: props.pnr });
    } else {
      setMethodsView([]);
    }

    setMethodSelect(null);
    setMethodsView(methods);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeSelect, paymentMethods, props.userData]);

  return (
    <Box my={2}>
      <Spinner loading={loading} />
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        className={props.classes.box}
        padding={1}
      >
        <Typography align="center" className={props.classes.span1}>
          SELECCIONA TU MÉTODO DE PAGO
        </Typography>
        <Box style={{ width: 50 }}>
          <Button
            variant="contained"
            className={props.classes.button}
            fullWidth={true}
            style={{
              backgroundColor: "transparent",
              boxShadow: "none",
              padding: 0,
              minWidth: "100%",
              height: "100%",
              borderRadius: 10,
            }}
            onClick={() => setOpen(!open)}
          >
            {!open ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
          </Button>
        </Box>
      </Box>

      {open && (
        <Box>
          <Box display="flex" justifyContent="center" flexWrap="wrap" my={2}>
            {typeOptions.map((type) => (
              <PaymentType
                key={type.name}
                name={type.name}
                text={type.text}
                img={type.img}
              />
            ))}
          </Box>

          {/* INICIAR SESION */}
          {!props.userData && typeSelect === "installments" ? (
            <Box mb={2} textAlign="center" m="auto" maxWidth={350}>
              <Typography
                color="secondary"
                align="center"
                className={classes.p}
              >
                Inicia sesión con tu cuenta para continuar
              </Typography>

              <Box my={1}>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  className={classes.p}
                  onClick={() => {
                    setShowLogin(!showLogin);
                  }}
                >
                  Iniciar sesión
                </Button>
              </Box>

              <Typography
                color="secondary"
                align="center"
                className={classes.p}
              >
                O
              </Typography>

              <Box my={1}>
                <Button
                  variant="contained"
                  fullWidth
                  className={classes.p}
                  onClick={() => {
                    window.open("https://aviorair.com/register", "_blank");
                  }}
                >
                  Registrarte
                </Button>
              </Box>
            </Box>
          ) : null}

          {!props.userData && showLogin && (
            <ModalLogin
              showLogin={showLogin}
              setShowLogin={setShowLogin}
              setUserData={props.setUserData}
            />
          )}
          {/* END INICIAR SESION */}

          {/* INSTALLMENTS ERRORS */}
          {installmentsError && typeSelect === "installments" && (
            <Box mb={2} textAlign="center">
              <Typography className={props.classes.span1}>
                {installmentsError}
              </Typography>
            </Box>
          )}

          {/* INTALLMENTS */}
          {props.userData &&
            typeSelect === "installments" &&
            !installmentsError && (
              <Box mb={2} maxWidth={632} mx="auto">
                <Box
                  border="1px solid #CCC"
                  className={props.classes.box}
                  style={{ padding: "5px 10px" }}
                >
                  <Grid container>
                    <Grid item md={4} xs={4}>
                      <Typography className={props.classes.span1}>
                        Descripción
                      </Typography>
                    </Grid>
                    <Grid item md={4} xs={4}>
                      <Typography className={props.classes.span1}>
                        Fecha
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      md={4}
                      xs={4}
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <Typography className={props.classes.span1}>
                        Monto
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>

                <Box>
                  {installmentsPreview.map((item, index) => {
                    return (
                      <Box
                        key={index}
                        border="1px solid #ccc"
                        className={index === 0 ? props.classes.box1 : ""}
                        style={{
                          padding: "5px 10px",
                        }}
                      >
                        <Grid container>
                          <Grid item md={4} xs={4}>
                            {index === 0 ? (
                              <Typography
                                className={
                                  index === 0
                                    ? props.classes.span1
                                    : props.classes.p3
                                }
                              >
                                PAGO INICIAL
                              </Typography>
                            ) : (
                              <Typography
                                className={
                                  index === 0
                                    ? props.classes.span1
                                    : props.classes.p3
                                }
                                style={{
                                  width: "max-content",
                                  color: "#000",
                                  backgroundColor: "#EBEBEB",
                                  border: "1px solid #EBEBEB",
                                  borderRadius: 5,
                                  padding: "0px 20px",
                                }}
                              >
                                Cuota {index}
                              </Typography>
                            )}
                          </Grid>
                          <Grid item md={4} xs={4}>
                            <Typography
                              className={
                                index === 0
                                  ? props.classes.span1
                                  : props.classes.p3
                              }
                            >
                              {moment(item.expireAt).format("DD/MM/YYYY")}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            md={4}
                            xs={4}
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <Typography
                              className={
                                index === 0
                                  ? props.classes.span1
                                  : props.classes.p3
                              }
                            >
                              $ {item.amount}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Box>
                    );
                  })}
                </Box>
              </Box>
            )}

          {/* PAYMENT METHODS */}
          {(typeSelect === "cash" ||
            (props.userData &&
              typeSelect === "installments" &&
              !installmentsError)) && (
            <Box display="flex" flexWrap="wrap" justifyContent="center">
              {methodsView.map((method, index) => (
                <Box
                  key={index}
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  m={1}
                  padding={1}
                  width={180}
                  border={`2px solid ${
                    methodSelect === method.value ? "#CD171A" : "#fff"
                  }`}
                  className={classes.card}
                  style={{ cursor: "pointer" }}
                  onClick={(e) => {
                    props.selectMethod(method.value);
                    setMethodSelect(method.value);
                  }}
                >
                  <Box mb={1}>
                    <img
                      src={method.img}
                      alt=""
                      style={{ maxHeight: 40, maxWidth: 160 }}
                    />
                  </Box>

                  <Box className={classes.p} textAlign="center">
                    {method.name}
                  </Box>
                </Box>
              ))}
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
}
