import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import TransfersRow from "./TransfersRow"

import React from "react";

const columnsAviorGo = [
  {
    id: "date",
    label: "Fecha",
    minWidth: 120,
  },
  {
    id: "referenceNumber",
    label: "Referencia",
    minWidth: 100,
  },
  {
    id: "full-name",
    label: "Nombre y apellido",
    minWidth: 170,
  },
  {
    id: "email",
    label: "Email",
    minWidth: 170,
  },
  {
    id: "phone",
    label: "Teléfono",
    minWidth: 170,
  },
  {
    id: "amount",
    label: "Monto",
    minWidth: 90,
  },
  {
    id: "status",
    label: "Estado",
    minWidth: 90,
  },
  {
    id: "admin",
    label: "Administrador",
    minWidth: 90,
  },
  {
    id: "update",
    label: "Actualizacion",
    minWidth: 110
  },
  {
    id: "acciones",
    label: "",
    minWidth: 270,
  }
];

const columnsBs = [
  {
    label: "",
    id: "open",
    minWidth: 50,
  },
  {
    id: "pnr",
    label: "PNR",
    minWidth: 50,
  },
  {
    id: "referenceNumber",
    label: "Referencia",
    minWidth: 100,
  },
  {
    id: "transferDate",
    label: "Fecha",
    minWidth: 150,
  },
  {
    id: "bankName",
    label: "Banco emisor",
    minWidth: 150,
  },
  {
    id: "bankNameRecive",
    label: "Banco receptor",
    minWidth: 150,
  },
  {
    id: "full-name",
    label: "Nombre y apellido",
    minWidth: 170,
  },
  {
    id: "email",
    label: "Email",
    minWidth: 170,
  },
  {
    id: "origin",
    label: "Origen",
    minWidth: 90,
  },
  {
    id: "amount",
    label: "Monto",
    minWidth: 90,
  },
  {
    id: "status",
    label: "Estado",
    minWidth: 90,
  },
  {
    id: "admin",
    label: "Administrador",
    minWidth: 90,
  },
  {
    id: "acciones",
    label: "Actualizacion",
    minWidth: 110,
  },
  {
    id: "acciones",
    label: "",
    minWidth: 270,
  }
];

const columnsDollar = [
  {
    label: "",
    id: "open",
    minWidth: 50,
  },
  {
    id: "pnr",
    label: "PNR",
    minWidth: 50,
  },
  {
    id: "referenceNumber",
    label: "Referencia",
    minWidth: 100,
  },
  {
    id: "transferDate",
    label: "Fecha",
    minWidth: 150,
  },
  {
    id: "full-name",
    label: "Nombre y apellido",
    minWidth: 170,
  },
  {
    id: "email",
    label: "Email",
    minWidth: 170,
  },
  {
    id: "origin",
    label: "Origen",
    minWidth: 90,
  },
  {
    id: "amount",
    label: "Monto",
    minWidth: 90,
  },
  {
    id: "status",
    label: "Estado",
    minWidth: 90,
  },
  {
    id: "admin",
    label: "Administrador",
    minWidth: 90,
  },
  {
    id: "update",
    label: "Actualizacion",
    minWidth: 110
  },
  {
    id: "acciones",
    label: "",
    minWidth: 270,
  }
];

const columnsByType = {
  zelle: columnsDollar,
  stripe: columnsDollar,
  bs: columnsBs,
  zelleCuponAir: columnsAviorGo,
  bsCuponAir: columnsAviorGo
}


export function TransfersTable({
  transfers,
  transactionType,
  aproveTranfer,
  cancelTranfer
}) {

  console.log("trns", transfers)
  const columns = columnsByType[transactionType]

  return <Table stickyHeader aria-label="sticky table">
    <TableHead>
      <TableRow>
        {columns.map(column => <TableCell key={column.id} align={column.align} style={{
          minWidth: column.minWidth
        }}>
          {column.label || " "}
        </TableCell>)}
      </TableRow>
    </TableHead>
    <TableBody>
      {transfers.length && transfers[0]._id
        ? transfers.map((transfer, index) => {
          return <TransfersRow transactionType={transactionType} transfer={transfer} aproveTranfer={aproveTranfer} cancelTranfer={cancelTranfer} key={index} />;
        })
        :
        <TableRow>
          <TableCell colSpan={columns.length}>
            No hay resultados
          </TableCell>
        </TableRow>
      }
    </TableBody>
  </Table>;
}
