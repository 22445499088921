import React, { useEffect, useState } from 'react';
import Seat from './Seat';

export default function SeatSelection ({ seatMap, updateSeat, activePassenger, seatSsrs, flightRph, passengers }) {
  const [seatNumbers, setSeatNumbers] = useState([])
  const cabinCode = passengers[0].cabinType
  const cabin = seatMap.cabins.find((cabin) => cabin.cabinClass.code === cabinCode);
  const sortedCabin = {
    ...cabin,
    seats: cabin.seats.sort((a, b) => parseInt(a.row) - parseInt(b.row))
  };

  useEffect(() => {
    setSeatNumbers(passengers.map(passenger=>passenger.seat.seatNumber))
  }, [passengers])
  const handleSelect = (seatNumber, ssrInfo) => {
    if (activePassenger) {
      updateSeat(flightRph, activePassenger.id, seatNumber, "select", ssrInfo)
    }
  };

  const handleDeselect = (seatNumber) => {
    if (activePassenger) {
      updateSeat(flightRph, activePassenger.id, seatNumber, "deselect")
    }
  }

  const cabinSeats = sortedCabin.seats || [];
  const columns = sortedCabin.columns || [];
  return (
    <>
      <div className="seat-selection" style={{ display: "flex", flexDirection: "row" }}>
        {columns.map((column, index) => {
          const desiredSeats = cabinSeats.filter(seat => seat.column === column.position);
          return (
            <div style={{ display: "flex" }} key={column.position + "-wrapper"}>
              <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }} className="row-container" key={column.position}>
                {desiredSeats.map((seat, index) => {
                  let occupied = seat.occupied
                  let isActive
                  if (seatNumbers.includes(seat.seatNumber)) {
                    //occupied = true
                    isActive = true
                  } else {
                    isActive = false
                  }
                  return (
                    <Seat
                      seat={seat}
                      isActive = {isActive}
                      handleSelect={handleSelect}
                      handleDeselect={handleDeselect}
                      seatNumber={seat.seatNumber}
                      isOccupied={occupied}
                      column={seat.column}
                      ssr = {seatSsrs.find(ssr => ssr.code === seat.ssrCode)}
                      price={seatSsrs.find(ssr => ssr.code === seat.ssrCode)?.total ?? "GRATIS"}
                      showPrice={!!seat.characteristics.find(char => char.code === "CH")}
                      key={`${seat.seatNumber}-seat-${index}`}
                      activePassenger={activePassenger}
                    />
                  )
                }
                )}
              </div>
              {column.codeType === "A" && columns[index - 1].codeType !== "A" ? <div style={{ width: "60px" }}></div> : null}
            </div>
          )
        })
        }
      </div>
    </>
  );
};
