import React, { useState, useEffect } from "react";
import { Alert, Spinner } from "../components/";
import { paymentServices } from "../services";
import { useHistory, useParams } from "react-router-dom";

export default function VerifyPaymentBookingBancaAmiga(){
    let history = useHistory();
    const [loading, setLoading] = useState(false);
    const [toast, setToast] = useState(false);
    const [toastMessage, setToastMessage] = useState("");
    //const [pnr, setPnr] = useState("");
    const { orderRef } = useParams();
    
    const verifyPayment = async (orderRef)=>{
        setLoading(true);
        const { response, status } = await paymentServices.verifyOrderBancaAmigaBooking(orderRef)
        setLoading(false);
        if (status === 200) {
            history.push(`/thankyoupage`, {
                state: { ...response, reservationDetails:response.reservationdetails },
            });
        }else{
            setToastMessage(response.error.message);
            //setPnr(response.error.pnr)
            setToast(true);
        }
    }

    useEffect(() => {
        verifyPayment(orderRef);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            <Alert
                message={toastMessage}
                open={toast}
                buttonMessage="Intentar de nuevo."
                close={() => {
                setToast(false);
                history.push(`/`);
                }}
            />
            <Spinner loading={loading} />
        </div>
    )
}