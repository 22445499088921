/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import {
  CssBaseline,
  TextField,
  Typography,
  Container,
} from "@material-ui/core/";
import { makeStyles } from "@material-ui/core/styles";
import { ButtonSpinner, Toast, Spinner } from "../components";
import { invitationServices } from "../services"
const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    width: 100,
    height: 100,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SendEmailForInvitationBK2() {
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [toast, setToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const login = async () => {
    setLoading(true)
    const { resp, status } = await invitationServices.generateInvitationBK2(email)
    setLoading(false)
    setToast(true);
    
    if(status===200){
      setEmail("")
      setToastMessage(resp.message);
    }else{
      setToastMessage(resp.error.message);
    }
  };
  return (
    <Container component="main" maxWidth="xs">
      <Toast
        message={toastMessage}
        open={toast}
        close={() => {
          setToast(false);
        }}
      />
      <Spinner loading={loading} />
      <CssBaseline />
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
            BK2
        </Typography>
        <form className={classes.form}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="Correo electrónico"
            name="pnr"
            autoFocus
            value={email}
            onChange={(e) => {
                    setEmail(e.target.value.toLowerCase())
            }}
          />
          <ButtonSpinner
            fullWidth
            action={login}
            text="Enviar"
          />
        </form>
      </div>
    </Container>
  );
}
