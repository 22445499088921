import urls from "../urls";
import { base } from "../urls";

const payWithCash = async (pay) => {
  const peticion = await fetch(urls.payCash, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Basic " + btoa("avior:fazQMUyq"),
    },
    body: JSON.stringify(pay),
  });
  const resp = await peticion.json();
  return {
    status: peticion.status,
    resp,
  };
};

const payTransfer = async (pay) => {
  const peticion = await fetch(urls.transfer, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Basic " + btoa("avior:fazQMUyq"),
    },
    body: JSON.stringify(pay),
  });
  const resp = await peticion.json();
  return {
    status: peticion.status,
    resp,
  };
};
const payTransferBss = async (pay) => {
  const peticion = await fetch(urls.transferBss, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Basic " + btoa("avior:fazQMUyq"),
    },
    body: JSON.stringify(pay),
  });
  const resp = await peticion.json();
  return {
    status: peticion.status,
    resp,
  };
};
const payCreditCard = async (pay) => {
  const peticion = await fetch(urls.bancaAmiga, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Basic " + btoa("avior:fazQMUyq"),
    },
    body: JSON.stringify(pay),
  });
  const response = await peticion.json();
  return {
    status: peticion.status,
    response,
  };
};

const paymentOrderBancaAmigaBooking = async (pay) => {
  const peticion = await fetch(urls.paymentOrderBancaAmigaBooking, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Basic " + btoa("avior:fazQMUyq"),
    },
    body: JSON.stringify(pay),
  });
  const response = await peticion.json();
  return {
    status: peticion.status,
    response,
  };
};

const verifyOrderBancaAmigaBooking = async (orderRef) => {
  const peticion = await fetch(urls.verifyOrderBancaAmigaBooking, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Basic " + btoa("avior:fazQMUyq"),
    },
    body: JSON.stringify({ orderRef }),
  });
  const response = await peticion.json();
  return {
    status: peticion.status,
    response,
  };
};

const paymentBookingBancaAmigaTDCBss = async ({ ...paymentIfo }) => {
  const peticion = await fetch(urls.bancaAmigaTDCBss, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Basic " + btoa("avior:fazQMUyq"),
    },
    body: JSON.stringify({ ...paymentIfo }),
  });
  const response = await peticion.json();
  return {
    status: peticion.status,
    response,
  };
};
const paymentOrderBancaAmigaMiscellaneous = async (pay) => {
  const peticion = await fetch(urls.paymentOrderBancaAmigaMiscellaneous, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Basic " + btoa("avior:fazQMUyq"),
    },
    body: JSON.stringify(pay),
  });
  const response = await peticion.json();
  return {
    status: peticion.status,
    response,
  };
};

const veryfyOrderBancaAmigaMiscellaneous = async (orderRef) => {
  const peticion = await fetch(urls.veryfyOrderBancaAmigaMiscellaneous, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Basic " + btoa("avior:fazQMUyq"),
    },
    body: JSON.stringify({ orderRef }),
  });
  const response = await peticion.json();
  return {
    status: peticion.status,
    response,
  };
};

const payAnciliariesCreditCard = async (pay) => {
  const peticion = await fetch(urls.bancaAmigaAnciliaries, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Basic " + btoa("avior:fazQMUyq"),
    },
    body: JSON.stringify(pay),
  });
  const response = await peticion.json();
  return {
    status: peticion.status,
    response,
  };
};
const payWithPse = async (pay) => {
  //    console.log("json",pay)
  const peticion = await fetch(urls.payPse, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Basic " + btoa("avior:fazQMUyq"),
    },
    body: JSON.stringify(pay),
  });
  const resp = await peticion.json();
  return {
    status: peticion.status,
    resp,
  };
};

const payCreditCardPromo = async (payment) => {
  const request = await fetch(urls.paymentPromoCreditCard, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Basic " + btoa("avior:fazQMUyq"),
    },
    body: JSON.stringify(payment),
  });
  const response = await request.json();
  return {
    status: request.status,
    response,
  };
};

const payPromoZelle = async (payment) => {
  const request = await fetch(
    `${urls.paymentPromoCreditCard}?paymentType=zelle`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Basic " + btoa("avior:fazQMUyq"),
      },
      body: JSON.stringify(payment),
    }
  );
  const response = await request.json();
  return {
    status: request.status,
    response,
  };
};
const paymentPromoPMBA = async (payment) => {
  const request = await fetch(
    `${urls.paymentPromoCreditCard}?paymentType=bapm`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Basic " + btoa("avior:fazQMUyq"),
      },
      body: JSON.stringify(payment),
    }
  );
  const response = await request.json();
  return {
    status: request.status,
    response,
  };
};
const payAnciliariesCreditCardTuOfertazo = async (pay) => {
  const peticion = await fetch(urls.payAnciliariesCreditCardTuOfertazo, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Basic " + btoa("avior:fazQMUyq"),
    },
    body: JSON.stringify(pay),
  });
  const response = await peticion.json();
  return {
    status: peticion.status,
    response,
  };
};

const paymentBookingCreditCardTuOfertazo = async (payment) => {
  const peticion = await fetch(urls.paymentBookingCreditCardTuOfertazo, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Basic " + btoa("avior:fazQMUyq"),
    },
    body: JSON.stringify(payment),
  });
  const response = await peticion.json();
  return {
    status: peticion.status,
    response,
  };
};

const paymentBAPM = async (payment) => {
  const peticion = await fetch(urls.paymentBookingBAPM, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Basic " + btoa("avior:fazQMUyq"),
    },
    body: JSON.stringify(payment),
  });
  const response = await peticion.json();
  return {
    status: peticion.status,
    response,
  };
};

const paymentInstallmentsBAPM = async (payment) => {
  const peticion = await fetch(urls.paymentInstallmentsBAPM, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Basic " + btoa("avior:fazQMUyq"),
    },
    body: JSON.stringify(payment),
  });
  const response = await peticion.json();
  return {
    status: peticion.status,
    response,
  };
};

const paymentBTPM = async (payment) => {
  const peticion = await fetch(urls.paymentBookingBTPM, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Basic " + btoa("avior:fazQMUyq"),
    },
    body: JSON.stringify(payment),
  });
  const response = await peticion.json();
  return {
    status: peticion.status,
    response,
  };
};

const paymentInstallmentsBTPMP2P = async (payment) => {
  const peticion = await fetch(urls.paymentInstallmentsBTPM, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Basic " + btoa("avior:fazQMUyq"),
    },
    body: JSON.stringify(payment),
  });
  const response = await peticion.json();
  return {
    status: peticion.status,
    response,
  };
};

const paymentBTPMP2P = async (payment) => {
  const peticion = await fetch(`${base}/emit-tickets-btpmp2p`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Basic " + btoa("avior:fazQMUyq"),
    },
    body: JSON.stringify(payment),
  });
  const response = await peticion.json();
  return {
    status: peticion.status,
    response,
  };
};

const paymentServices = Object.freeze({
  payWithCash,
  payWithPse,
  payTransfer,
  payCreditCard,
  payAnciliariesCreditCard,
  payCreditCardPromo,
  payPromoZelle,
  payAnciliariesCreditCardTuOfertazo,
  payTransferBss,
  paymentOrderBancaAmigaBooking,
  verifyOrderBancaAmigaBooking,
  paymentOrderBancaAmigaMiscellaneous,
  veryfyOrderBancaAmigaMiscellaneous,
  paymentBookingCreditCardTuOfertazo,
  paymentBAPM,
  paymentPromoPMBA,
  paymentBTPM,
  paymentBookingBancaAmigaTDCBss,
  paymentInstallmentsBAPM,
  paymentBTPMP2P,
  paymentInstallmentsBTPMP2P,
});

export default paymentServices;
