import React from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  Button,
} from "@material-ui/core/";
import { useHistory } from "react-router-dom";
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Footer } from "../components"
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  banner: {
    minHeight: "120vh",
    backgroundImage: `url(./img/AVIORGO_landing.jpg)`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  appBar: {
    background: "#fff",
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  infoSection: {
    background: "#fff",
    paddingTop: "40px",
    paddingBottom: "20px",
  },
  termSection: {
    background: "#ffffff",
    paddingTop: "40px",
  },
  tyc: {
    fontSize: "1.5rem"
  },
  button: {
    border: "1pt solid #CCCCCC",
    fontSize: "1.3em",
    padding: "5px 20px",
    borderRadius: "10px",
    textTransform: 'none'
  },
  li: {
    border: "2px solid #CD171A",
    padding: "10px",
    borderRadius: "10px",
    marginBottom: "10px",
    color: "#CD171A"
  },
  footer: {
    background: "#cd171a",
    paddingTop: "10px",
    paddingBottom: "10px",
  },
  [theme.breakpoints.down("md")]: {
    h4:{
      fontSize:"1.5rem"
    },
    font:{
      fontSize:"1rem"
    },
    tyc: {
      fontSize: "0.8rem"
    },
    liFont: {
      fontSize: "0.8rem"
    },
    button:{
      fontSize: "0.8rem"
    }
  }
}));
export default function LandingCuponAir() {
  const classes = useStyles();
  let history = useHistory();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  function List(props) {
    return (
      <Grid container justify="center" className={classes.ul}>
        <Grid item xs={12}  className={classes.li}>
          <Typography className={classes.liFont}>
          Costo de CupónAir: $100 o su valor al cambio en Bolívares.
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.li}>
          <Typography className={classes.liFont}>
          Pase de descuento se genera y se envía por correo electrónico..
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.li}>
          <Typography className={classes.liFont}>
          Métodos de pago aplicables: tarjeta de crédito internacional, pago móvil, trasferencias bancarias nacionales, cash, zelle.
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.li}>
          <Typography className={classes.liFont}>
          Cupón aplicable, sin límite, por 25% de descuento en cada compra de boletos en todas nuestras rutas activas (nacionales o internacional), en cualquiera de nuestras tarifas.
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.li}>
          <Typography className={classes.liFont}>
          Beneficios de tarifas se aplican de acuerdo a la adquirida. 
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.li}>
          <Typography className={classes.liFont}>
          Válido para comprar hasta el 01 de diciembre de 2023.
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.li}>
          <Typography className={classes.liFont}>
          Válido para volar hasta el 15 de diciembre de 2023.
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.li}>
          <Typography className={classes.liFont}>
          Código generado es intransferible y único por pasajero.
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.li}>
          <Typography className={classes.liFont}>
          Válido para canjear únicamente por nuestra página web.
          </Typography>
        </Grid>
      </Grid>
    );
  }
  return (
    <div className={classes.root}>
      <main className={classes.content}>
         {!matches?<Grid container justify="center">
           <img src="./img/cupon-air.png" alt="avior go" style={{width:"100%"}}/> 
           </Grid>:<Grid container justify="center">
           <img src="./img/cupon-air-mobile.png" alt="avior go" style={{width:"100%"}}/> 
           </Grid>}
        <Grid
          container
          maxWidth="lg"
          justify="center"
          className={classes.termSection}
        >
          <Grid item xs={12}>
            <Grid container justify="center">
              <Grid item md={9}>
                <Typography align="center" color="primary" className={classes.tyc}>
                  <strong style={{background:"#CD171A", color: "#ffff", padding:"10px", borderRadius:"10px" }}>TÉRMINOS Y CONDICIONES</strong>
                </Typography>
              </Grid>
              <Grid item md={4} xs={11} style={{ marginTop: 60 }}>
                <List />
              </Grid>
            </Grid>
          </Grid>
          <Grid container style={{ marginTop: 60 }} justify="center">
            <Grid
              item
              xs={12}
              style={{
                backgroundColor: "#fff",
                padding: "10px",
                display: "flex",
                justifyContent: "center"
              }}
            >
              <div style={{ border: "1px solid rgb(205, 23, 26)" , padding: "10px", borderRadius: "10px"}}>
                <Button
                variant="contained"
                color={"primary"}
                className={classes.button}
                onClick={()=>history.push("/cupon-air")}
              >
                ¡Compra tu CupónAir hoy!
              </Button>
              </div>
              
            </Grid>
            <Grid item xs={12} style={{marginBottom:"40px"}}>
              <Typography align="center" style={{color:"#A4A4A4"}}> 
                No pierdas esta oportunidad
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Footer />
      </main>
    </div>
  );
}
