import React from "react";
import { InputAdornment, Grid } from "@material-ui/core/";
import { StaticDatePicker as DatePicker } from ".";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "70%",
  },
  adornment: {
    width: "22px",
  },
  input: {
    "&:before": {
      borderBottom: "1px solid #CD171A",
    },
    "&:after": {
      borderBottom: "2px solid #CD171A",
    },
    "&:focus": {
      borderBottom: "2px solid #CD171A",
    },
    color: "#575756",
    fontSize:"0.8rem"
  },
  label: {
    color: "#575756",
    width: "45%",
    fontSize: "0.8rem",
    fontWeight: "400",
    lineHeight: "1.5",
  },
  container: {
    display: "inline-flex",
    alignItems: "center",
    verticalAlign: "middle",
    width: "100%",
  },
}));

export default function DateRange(props) {
  const classes = useStyles();
  const [minDate, setMinDate] = React.useState(new Date())
  return (
    <Grid container justify="center" direction="row"  alignItems="center">
        <Grid item xs={11}>
        <div>
          <div className={classes.container}>
            <span className={classes.label}>Ida</span>
          <DatePicker
            autoOk={ props.autoOk? props.autoOk: true }
            disablePast
            inputVariant="standard"
            value={props.dateDeparture}
            onChange={(date) => {
              setMinDate(date)
              props.setDateDeparture(date)
            }}
            inputProps={classes.input}
            disableDays={props.disableDaysDeparture}
            error={props.dateDepartureError}
            helperText={props.dateDepartureHelperText}
            adornment={
              <InputAdornment position="start">
                <img
                  src="./img/icono-calendario.png"
                  alt=""
                  className={classes.adornment}
                />
              </InputAdornment>
            }
          />
          </div>
          </div>
        </Grid>
        <Grid item xs={11}>
        <div>
          <div className={classes.container}>
            <span className={classes.label} style={{color:!props.isRoundTrip?"#c2c2c2":"#575756"}}>Regreso</span>
          <DatePicker
            autoOk={ props.autoOk? props.autoOk: true }
            disablePast
            inputVariant="standard"
            value={props.dateArrival}
            onChange={(date) => props.setDateArrival(date)}
            inputProps={classes.input}
            disableDays={props.disableDaysArrival}
            error={props.dateArrivalError}
            helperText={props.dateArrivalHelperText}
            disabled={!props.isRoundTrip}
            minDate={minDate}
            adornment={
              <InputAdornment position="start">
                <img
                  src="./img/icono-calendario.png"
                  alt=""
                  className={classes.adornment}
                />
              </InputAdornment>
            }
          />
          </div>
          </div>
        </Grid>
    </Grid>
  );
}
