import React from "react";
import {
  Drawer,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  AppBar,
  Toolbar,
  Box,
  Container,
  Grid
} from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import MenuIcon from "@material-ui/icons/Menu";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import ListIcon from '@material-ui/icons/List';
import clsx from "clsx";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Copyright } from ".";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import { Landmark, Wallet } from "lucide-react";
const drawerWidth = 300;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },

  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
}));
const DashBoardDrawer = (props) => {
  let history = useHistory();
  const useStyles = makeStyles((theme) => ({
    toolbarIcon: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: "0 8px",
      ...theme.mixins.toolbar,
    },
    drawerPaper: {
      position: "relative",
      whiteSpace: "nowrap",
      width: props.drawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerPaperClose: {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    },
  }));
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  return (
    <Drawer
      variant={matches ? "permanent" : "temporary"}
      classes={{
        paper: matches
          ? clsx(classes.drawerPaper, !props.open && classes.drawerPaperClose)
          : classes.drawerPaper,
      }}
      ModalProps={{
        keepMounted: matches ? false : true, // Better open performance on mobile.
      }}
      open={props.open}
    >
      <div className={classes.toolbarIcon}>
        <IconButton onClick={props.handleDrawerClose}>
          <ChevronLeftIcon />
        </IconButton>
      </div>
      <Divider />
      <List>
        <div>
          <ListItem button component={Link} to="/dHJhbnNmZXJlbmNpYXM=transferencias">
            <ListItemIcon>
              <Landmark size={24} />
            </ListItemIcon>
            <ListItemText primary="Pagos" />
          </ListItem>
          <ListItem button component={Link} to="/saldos">
            <ListItemIcon>
              <Wallet size={24} />
            </ListItemIcon>
            <ListItemText primary="Saldos" />
          </ListItem>
          <ListItem button component={Link} to="/admindolar">
            <ListItemIcon>
              <AttachMoneyIcon />
            </ListItemIcon>
            <ListItemText primary="Tasa" />
          </ListItem>
          {/* <ListItem button component={Link} to="/dHJhbnNmZXJlbmNpYXM=transferencias-promo">
            <ListItemIcon>
              <ListIcon />
            </ListItemIcon>
            <ListItemText primary="Pagos Zelle Cupon Air" />
          </ListItem>
          <ListItem button component={Link} to="/dHJhbnNmZXJlbmNpYXM=transferencias-promo-bss">
            <ListItemIcon>
              <ListIcon />
            </ListItemIcon>
            <ListItemText primary="Pagos Bolivares Cupon Air" />
          </ListItem> */}
          <ListItem button onClick={() => {
            localStorage.removeItem('token')
            history.push('/loginadminavior')
          }}>
            <ListItemIcon>
              <ListIcon />
            </ListItemIcon>
            <ListItemText primary="Salir" />
          </ListItem>
        </div>
      </List>
      <Divider />
      {/*<List>{secondaryListItems}</List>*/}
    </Drawer>
  );
};
const DashBoardAppBar = (props) => {
  const useStyles = makeStyles((theme) => ({
    appBar: {
      background: "#fff",
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: props.drawerWidth,
      width: `calc(100% - ${props.drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    toolbar: {
      paddingRight: 24, // keep right padding when drawer closed
    },
    menuButtonHidden: {
      display: "none",
    },
    menuButton: {
      marginRight: 36,
    },
    title: {
      flexGrow: 1,
    },
  }));
  const classes = useStyles();
  return (
    <AppBar
      position="absolute"
      className={clsx(classes.appBar, props.open && classes.appBarShift)}
    >
      <Toolbar className={classes.toolbar}>
        <IconButton
          edge="start"
          aria-label="open drawer"
          onClick={props.handleDrawerOpen}
          className={clsx(
            classes.menuButton,
            props.open && classes.menuButtonHidden
          )}
        >
          <MenuIcon />
        </IconButton>
        <img src="./img/logoWhite.png" alt="" style={{ width: "200px" }} />
      </Toolbar>
    </AppBar>
  );
};
export default function DashboardAdminTransfers({ children }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <DashBoardAppBar
        drawerWidth={drawerWidth}
        open={open}
        handleDrawerOpen={handleDrawerOpen}
      />
      <DashBoardDrawer
        drawerWidth={drawerWidth}
        open={open}
        handleDrawerClose={handleDrawerClose}
      />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={3}>
            <Grid item sm={12}>
              {children}
            </Grid>
          </Grid>
          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
      </main>
    </div>
  );
}
