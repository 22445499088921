import React, { useState, useEffect } from "react";
import { 
    Grid, 
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    IconButton,
    Fab,
    Modal,
    TextField,
    Button
} from "@material-ui/core";
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { destinationServices } from "../services"
import { Spinner, Toast } from "../components"
import { makeStyles } from "@material-ui/core/styles";
import { FormValidator } from "../helpers"
import { useHistory } from "react-router-dom";
const useStyles = makeStyles((theme) =>({
    paper: {
        position: 'absolute',
        width: 400,
        height:500,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #FFF',
        boxShadow: theme.shadows[5],
        padding:"20px",
        borderRadius:"20px",
        outline: 'none'
      },
      modal: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
}));
const columns = [
    {
        id: "locationCode",
        label: "Codigo",
        minWidth: 50,
    },
    {
        id: "cityName",
        label: "Ciudad",
        minWidth: 50,
    },
    {
        id: "airPortName",
        label: "Aeropuerto",
        minWidth: 50,
    },
    {
        id: "countryName",
        label: "Pais",
        minWidth: 50,
    }
]

const ModalAirport = (props) =>{
    const [modalStyle] = useState(getModalStyle);
    const [locationCode, setLocationCode] = useState("")
    const [cityName, setCityName] = useState("")
    const [airPortName, setAirPortName] = useState("")
    const [airportCode, setAirportCode] = useState("")
    const [countryName, setCountryName] = useState("")
    const [countryCode, setCountryCode] = useState("")
    const [errors, setErrors] = useState({});
    const classes = useStyles();
    const validator = new FormValidator([
        {
            field: "locationCode",
            method: "isEmpty",
            validWhen: false,
            message: "Debe ingresar el código"
        },
        {
            field: "cityName",
            method: "isEmpty",
            validWhen: false,
            message: "Debe ingresar la ciudad"
        },
        {
            field: "airPortName",
            method: "isEmpty",
            validWhen: false,
            message: "Debe ingresar el nombre del aeropuerto"
        },
        {
            field: "airportCode",
            method: "isEmpty",
            validWhen: false,
            message: "Debe ingresar el código del aeropuerto"
        },
        {
            field: "countryName",
            method: "isEmpty",
            validWhen: false,
            message: "Debe el nombre del pais"
        },
        {
            field: "countryCode",
            method: "isEmpty",
            validWhen: false,
            message: "Debe ingresar el código del pais"
        }
    ])
    function getModalStyle() {
        const top = 50;
        const left = 50;
        
        return {
            top: `${top}%`,
            left: `${left}%`,
            transform: `translate(-${top}%, -${left}%)`,
        };
    }
    useEffect(() => {
        if(props.isUpdating){
            setLocationCode(props.airport.locationCode)
            setCityName(props.airport.cityName)
            setAirPortName(props.airport.airPortName)
            setAirportCode(props.airport.airportCode)
            setCountryName(props.airport.countryName)
            setCountryCode(props.airport.countryCode)
        }else{
            setLocationCode("")
            setCityName("")
            setAirPortName("")
            setAirportCode("")
            setCountryName("")
            setCountryCode("")
        }
    }, [props.isUpdating, props.airport]);
    
    const addAirport =()=>{
        const validation = validator.validate({
            locationCode,
            cityName,
            airPortName,
            airportCode,
            countryName,
            countryCode
        });
        setErrors(validation);
        if (validation.isValid) {
            const airport = {
                locationCode,
                cityName,
                airPortName,
                airportCode,
                countryName,
                countryCode
            }
            if(props.isUpdating){
                props.updateAirport(airport)
            }else{
                props.addAirport(airport)
            }
            props.close()
        }
    }
    return (
        <Modal className={classes.modal} open={props.open} onClose={props.close} disableBackdropClick={true}>
            <Grid container style={modalStyle} className={classes.paper} spacing={2}>
                <Grid item xs={12}>
                    <Grid container justify="flex-end">
                        <HighlightOffIcon color="primary" onClick={props.close}/>
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        label="Código"
                        variant="outlined"
                        value={locationCode}
                        fullWidth
                        error={errors.locationCode ? errors.locationCode.isInvalid : false}
                        helperText={errors.locationCode ? errors.locationCode.message : ""}
                        onChange={(e)=> setLocationCode(e.target.value)}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        label="Ciudad"
                        variant="outlined"
                        value={cityName}
                        fullWidth
                        error={errors.cityName ? errors.cityName.isInvalid : false}
                        helperText={errors.cityName ? errors.cityName.message : ""}
                        onChange={(e)=>{setCityName(e.target.value)}}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        label="Aeropuerto"
                        value={airPortName}
                        error={errors.airPortName ? errors.airPortName.isInvalid : false}
                        helperText={errors.airPortName ? errors.airPortName.message : ""}
                        onChange={(e)=>setAirPortName(e.target.value)}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        label="Código"
                        value={airportCode}
                        error={errors.airportCode ? errors.airportCode.isInvalid : false}
                        helperText={errors.airportCode ? errors.airportCode.message : ""}
                        onChange={(e)=>setAirportCode(e.target.value)}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        label="Pais"
                        value={countryName}
                        error={errors.countryName ? errors.countryName.isInvalid : false}
                        helperText={errors.countryName ? errors.countryName.message : ""}
                        onChange={(e)=>setCountryName(e.target.value)}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        label="Código del pais"
                        value={countryCode}
                        error={errors.countryCode ? errors.countryCode.isInvalid : false}
                        helperText={errors.countryCode ? errors.countryCode.message : ""}
                        onChange={(e)=>setCountryCode(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Grid container justify="center">
                        <Button color="primary" variant="contained" onClick={addAirport}>Aceptar</Button>
                    </Grid>
                </Grid>
            </Grid>
        </Modal>
    )
}

export default function AirportsConfig(){
    const [airPorts, setAirPorts] = useState([])
    let history = useHistory();
    const [loading, setLoading] = useState(false)
    const [openModal, setOpenModal] = useState(false)
    const [isUpdating, setIsUpdating] = useState(false)
    const [airport, setAirport] = useState({})
    const [index, setIndex] = useState(null)
    const [openToast, setOpenToast] = useState(false);
    const [toastMessage, setToastMessage] = useState("");
    const getAirports = async () => {
        setLoading(true)
        const {status, resp} = await destinationServices.getAirports();
        setLoading(false)
        if (status === 200) {
          setAirPorts(resp.airports);
        }
    };
    useEffect(() => {
        getAirports();
    }, []);
    
    const addAirport = (airport) =>{
        const airportsAux = [...airPorts];
        airportsAux.push(airport)
        setAirPorts([...airportsAux])
        setOpenToast(true)
        setToastMessage("Aeropuerto agregado con exito")
    }

    const updateAirport = (airport)=>{
        const airportsAux = [...airPorts];
        airportsAux[index] = airport;
        setAirPorts([...airportsAux])
        setOpenToast(true)
        setToastMessage("Aeropuerto actualizado con exito")
    }
    const deleteAirport = (index)=>{
        const airportsAux = [...airPorts];
        airportsAux.splice(index,1)
        setAirPorts([...airportsAux])
        setOpenToast(true)
        setToastMessage("Aeropuerto eliminado con exito")
    }
    const saveAirports = async()=>{
        const body = {
            airports: airPorts
        }
        setLoading(true)
        const {status} = await destinationServices.putAirports(body);
        setLoading(false)
        if (status === 200) {
            setOpenToast(true)
            setToastMessage("Aeropuertos actualizados")
            getAirports()
        }else if (status === 401) {
            localStorage.clear();
            history.push(`/loginadminavior`);
        }
    }
    return (
        <Grid container justify="center" direction="row" spacing={2}>
            <Spinner loading={loading}/>
            <Grid item xs={12}>
                <Grid container justify="space-between">
                    <Button color="primary" variant="contained" onClick={saveAirports} style={{height: "35px"}}>Guardar</Button>
                    <Fab color="primary" aria-label="add" onClick={()=>setOpenModal(true)}>
                        <AddIcon />
                    </Fab>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <TableContainer>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                key={column.id}
                                align={column.align}
                                style={{ minWidth: column.minWidth }}
                                >
                                {column.label}
                                </TableCell>
                            ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {airPorts.map((airport, index) => (
                            <TableRow key={index}>
                                <TableCell component="th" scope="row">
                                    <Typography color="primary">{airport.locationCode}</Typography>
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    <Typography color="primary">{airport.cityName}</Typography>
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    <Typography>{airport.airPortName}{" "}{airport.airportCode}</Typography>
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    <Typography>{airport.countryName}{" "} {airport.countryCode}</Typography>
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    <IconButton  onClick={()=>{
                                            setIndex(index)
                                            setIsUpdating(true);
                                            setAirport(airport)
                                            setOpenModal(true)
                                        }}>
                                        <EditIcon/>
                                    </IconButton>
                                    <IconButton onClick={()=>deleteAirport(index)}>
                                        <DeleteIcon/>
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            <ModalAirport isUpdating={isUpdating} airport={airport} open={openModal} addAirport={addAirport} updateAirport={updateAirport} close={()=>{
                setOpenModal(false)
                setIsUpdating(false);
                setAirport({})
                }}/>
            <Toast open={openToast} close={()=>setOpenToast(false)} message={toastMessage}/>
        </Grid>
    )
}