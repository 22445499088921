import { base } from "../urls";

const getOrders = async ({
  page,
  limit,
  type,
  status,
  query,
  starDate,
  endDate,
}) => {
  const token = localStorage.getItem("token");

  // Crear un objeto URLSearchParams para construir los query params dinámicamente
  const queryParams = new URLSearchParams();

  // Agregar los parámetros solo si tienen un valor definido
  if (page) queryParams.append("page", page);
  if (limit) queryParams.append("limit", limit);
  if (type) queryParams.append("type", type);
  if (status) queryParams.append("status", status);
  if (query) queryParams.append("query", query);
  if (starDate) queryParams.append("starDate", starDate);
  if (endDate) queryParams.append("endDate", endDate);

  // Construir la URL base con los query params
  const url = `${base}/ordersv2?${queryParams.toString()}`;

  const peticion = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  let resp;
  if (peticion.status === 401) {
    resp = {};
  } else {
    resp = await peticion.json();
  }

  return {
    status: peticion.status,
    resp,
  };
};

const rejectOrder = async (orderId) => {
  const token = localStorage.getItem("token");
  const url = `${base}/ordersv2/reject`;
  const peticion = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      order: orderId,
    }),
  });
  let resp = await peticion.json();
  return {
    status: peticion.status,
    resp,
  };
};
const approveOrder = async (orderId) => {
  const token = localStorage.getItem("token");
  const url = `${base}/ordersv2/approve`;

  const peticion = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      order: orderId,
    }),
  });

  let resp = await peticion.json();
  return {
    status: peticion.status,
    resp,
  };
};
const ordersServices = Object.freeze({
  getOrders,
  rejectOrder,
  approveOrder,
});

export default ordersServices;
