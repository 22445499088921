const ref = {
  home: "https://www.avior.com.ve",
  transportContract: "https://www.avior.com.ve/legal",
  personalData: "https://www.avior.com.ve/legal",
  dangerousBaggage: "https://www.avior.com.ve/articulosprohibidos",
  dutiesAndRights: "https://www.avior.com.ve/legal",
  taxes: "https://www.avior.com.ve/legal",
  refounds: "https://www.gcaair.com/refounds",
  termsAndConditions: "https://www.avior.com.ve",
  termsAviorPlus: "https://aviorplus.com/terms-conditions",
};

export { ref };
