import React from "react";
import { Grid, Typography, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
const useStyles = makeStyles((theme) => ({
    box:{
        height: "200px", 
        border: "1px solid #cccccc",
        borderRadius:"3px",
        padding:"10px"
    },
    font:{
        fontSize:"1rem"
    },
    [theme.breakpoints.down('sm')]:{ 
        font:{
            fontSize:"0.8rem"
        }
    }

}))
export default function Precautions() {
  const classes = useStyles();
  return (
    <Grid container justify="center" direction="row" style={{marginTop:"20px"}}>
      <Grid item xs={10}>
        <Grid
          container
          justify="center"
          direction="row"
          alignItems="center"
          spacing={4}
        >
          {/*<Grid item md={5} xs={12}>
            <Box className={classes.box} boxShadow={2}>
                <Typography className={classes.font} color="primary" style={{display:"flex", alignItems:"center"}}>
                    <ErrorOutlineIcon style={{fontSize:"20px"}} color="primary"/> {" "}<span style={{marginLeft:"5px"}}>Más flexibilidad para su viaje</span>
                </Typography>
                <Typography className={classes.font}>
                Estimados usuarios, debido a las restricciones de vuelos comerciales impuestas por el INAC por la pandemia, todos los itinerarios están sujetos a cambio. Sin embargo, no aplicará recargos por cambios de fechas de viaje, pues deseamos  brindarles la mayor flexibilidad posible. 
                </Typography>
            </Box>
  </Grid>*/}
          <Grid item md={8} xs={12}>
            <Box className={classes.box} boxShadow={2}>
                <Typography className={classes.font} color="primary" style={{display:"flex", alignItems:"center"}}>
                    <ErrorOutlineIcon style={{fontSize:"20px"}} color="primary"/> {" "}<span style={{marginLeft:"5px"}}>Atención</span>
                </Typography>
                <Typography className={classes.font}>
                Para más información sobre medidas y protocolos que estaremos aplicando para prevenir contagios, visite aquí nuestra sección del <a rel='noopener noreferrer'  target="_blank" href={"https://aviorair.com/covid19"} style={{ textDecoration:"none", color:"#000", fontWeight:"bold"}}>Covid-19</a>.
                </Typography>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
