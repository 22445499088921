import SeatSelection from "./SeatSelection";
import OriginDestinationSeatSelection from "./origin-destination"
import PassengersBlock from "./PassengersBlock"
import SeatMap from "./seat-map"
export default SeatSelection;

export {
    OriginDestinationSeatSelection,
    PassengersBlock,
    SeatMap
}