import React from 'react';

const getColor = (isActive, isOccupied) => {
  if (isActive) {
    return "white"
  } else if (isOccupied) {
    return "#777777"
  } else {
    return "#777777"
  }
}

const getBColor = (isActive, isOccupied) => {
  if (isActive) {
    return "#cd171a"
  } else if (isOccupied) {
    return "#B2B2B2"
  } else {
    return "transparent"
  }
}

export default function Seat({ handleSelect, handleDeselect, seatNumber, isOccupied, showPrice, price, isActive, ssr}) {
  const handleClick = () => {
    if (!isOccupied) {
      if (isActive) {
        handleDeselect(seatNumber)
      } else {
        handleSelect(seatNumber, {...ssr, total: showPrice? ssr.total : 0})
      }
    }
  }

  return (
    <div onClick={handleClick} style={{ width: "70px", height: "70px", color: getColor(isActive, isOccupied), backgroundColor: getBColor(isActive, isOccupied), border: "2px solid #777777", borderRadius: "5px", margin: "5px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", cursor: "pointer", userSelect: "none", transition: "all .3s ease-in-out" }} >
      <h1 style={{ fontSize: "1.3rem", margin: "0" }}>{seatNumber}</h1>
      <p style={{ margin: "0" }}>{showPrice ? `$${price}` : "GRATIS"}</p>
    </div>
  )
}
