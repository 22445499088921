import { useState } from 'react';

const useTransferFilters = () => {
  // State to store the filter values.
  const [filters, setFiltersState] = useState({
    startDate: "",
    endDate: "",
    filterStatus: "all",
    transactionType: "stripe",
  });

  // Function to handle setting the filter values.
  const setFilters = (updatedFilters) => {
    setFiltersState(prevFilters => ({
      ...prevFilters,
      ...updatedFilters
    }));
  };

  const resetFilters = () => (
    setFiltersState({
      startDate: "",
      endDate: "",
      filterStatus: "all",
      transactionType: "stripe",
    })
  );

  return { filters, setFilters, resetFilters };
};

export default useTransferFilters;
