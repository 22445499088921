import React from "react";
import { useLocation, useHistory } from "react-router-dom";
import { CostResume, FligthResume, PaymentStatus } from "../components";
export default function PaymentResume(){
    let location = useLocation();
    let history = useHistory();
    if (!location.state) {
      history.push(`/`);
    }
    //console.log(location.state)
    const params = location.state.params;
    //const reservation = location.state.reservation; 
    const payment = location.state.payment
    return (
        <div>
          <PaymentStatus status={payment.status}/>
          <FligthResume
            segments={params.originFlight.segments}
            searchParams={params.searchParams}
            totalTimeFlight={params.originFlight.totalTimeFlight}
          />
          {params.returnFlight ? (
            <FligthResume
              segments={params.returnFlight.segments}
              searchParams={params.searchParams}
              totalTimeFlight={params.returnFlight.totalTimeFlight}
            />
          ) : null}
          <div style={{ marginTop: "20px" }}>
            <CostResume
              originFlight={params.originFlight}
              returnFlight={params.returnFlight}
            />
          </div>
        </div>
      );
}