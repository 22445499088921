import urls from "../urls";

const getSegments = async (pnr) => {
    const peticion = await fetch(`${urls.boarding}/${pnr}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        }
    });
    let response;
    if(peticion.status===401){
        response={}
    }
    else{
        response = await peticion.json();  
    }
    return {
        status: peticion.status,
        response
    };
};


const resendEmail = async({pnr, email, segment}) => {
    const peticion = await fetch(`${urls.boarding}/${pnr}/${email}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body:JSON.stringify({...segment})
    });
    let response;
    if(peticion.status===401){
        response={}
    }
    else{
        response = await peticion.json();  
    }
    return {
        status: peticion.status,
        response
    };
}

const boardingPassServices = Object.freeze({
    getSegments,
    resendEmail
})

export default boardingPassServices;