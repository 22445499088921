const days=[
    {
        fullName: "Domingo",
        shortName: "Dom"
    },
    {
        fullName: "Lunes",
        shortName: "Lun"
    },
    {
        fullName: "Martes",
        shortName: "Mar"
    },
    {
        fullName: "Miercoles",
        shortName: "Mie"
    },
    {
        fullName: "Jueves",
        shortName: "Jue"
    },
    {
        fullName: "Viernes",
        shortName: "Vie"
    },
    {
        fullName: "Sabado",
        shortName: "Sab"
    },
    
]

const months = [
    {
        fullName: "Enero",
        shortName: "Ene"
    },
    {
        fullName: "Febrero",
        shortName: "Feb"
    },
    {
        fullName: "Marzo",
        shortName: "Mar"
    },
    {
        fullName: "Abril",
        shortName: "Abr"
    },
    {
        fullName: "Mayo",
        shortName: "May"
    },
    {
        fullName: "Junio",
        shortName: "Jun"
    },
    {
        fullName: "Julio",
        shortName: "Julio"
    },
    {
        fullName: "Agosto",
        shortName: "Ago"
    },
    {
        fullName: "Septiembre",
        shortName: "Sep"
    },
    {
        fullName: "Octubre",
        shortName: "Oct"
    },
    {
        fullName: "Noviembre",
        shortName: "Nov"
    },
    {
        fullName: "Diciembre",
        shortName: "Dic"
    },
]

export {
    days,
    months
}