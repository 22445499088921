import urls from "../urls";
const getAirports = async () => {
  const peticion = await fetch(urls.airports,{
    headers: {
      "Content-Type": "application/json",
      "Authorization":'Basic '+ btoa('avior:fazQMUyq'),
    },
  });
  const resp = await peticion.json();
  return {
    status: peticion.status,
    resp
  };
};
const getDestinations = async locationCode => {
  const peticion = await fetch(urls.destinations, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authorization":'Basic '+ btoa('avior:fazQMUyq'),
    },
    body: JSON.stringify({
      locationCode
    })
  });
  const resp = await peticion.json();
  return {
    status: peticion.status,
    resp
  };
};

const getItineraries = async () => {
  const request = await fetch(urls.itineraries,{
    headers: {
      "Content-Type": "application/json",
      "Authorization":'Basic '+ btoa('avior:fazQMUyq'),
    },
  });
  const response = await request.json();
  return {
    status: request.status,
    response
  };
}
const putItineraries = async(body)=>{
  const token = localStorage.getItem("token");
  const request = await fetch(urls.itineraries,{
    method:"PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    body:JSON.stringify(body)
  });
  const response = await request.json();
  return {
    status: request.status,
    response
  };
}
const putAirports = async (body) => {
  const token = localStorage.getItem("token");
  const peticion = await fetch(urls.airports,{
    method:"PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    body:JSON.stringify(body)
  });
  const resp = await peticion.json();
  return {
    status: peticion.status,
    resp
  };
};
const destinationServices = Object.freeze({
    getAirports,
    getDestinations,
    getItineraries,
    putItineraries,
    putAirports
});

export default destinationServices;