import React from "react";
import { ButtonGroup, Button } from "@material-ui/core/";

export default function FlightType(props){
    function changeFlightType(type){
        props.setFlightType(type)
    }
    return(
        <ButtonGroup fullWidth>
            <Button style={{color:props.isRoundTrip?"#575756":"#fff", fontWeight:"bold"}} size="small" color={!props.isRoundTrip?"primary":null} 
                variant={!props.isRoundTrip?"contained":null}  
                onClick={()=>changeFlightType(false)}
            >
                Solo ida
            </Button>
            <Button style={{color:!props.isRoundTrip?"#575756":"#fff", fontWeight:"bold"}} 
                size="small" color={props.isRoundTrip?"primary":null} 
                variant={props.isRoundTrip?"contained":null} 
                onClick={()=>changeFlightType(true)}
            >
                Ida y vuelta
            </Button>
        </ButtonGroup>
    );
}