import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { AppBar, Toolbar, Container } from "@material-ui/core/";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex"
  },
  appBar: {
    background: "#fff",
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    //overflow: "auto"
  },
  container: {
    //paddingTop: theme.spacing(4),
    //paddingBottom: theme.spacing(4)
  },
}));

export default function LayOut({ children }) {
  const classes = useStyles();

  function AppBarCustom(props) {
    return (
      <AppBar position="absolute" className={clsx(classes.appBar)}>
        <Container maxWidth="lg">
          <Toolbar>
            <img src="/img/logoWhite.png" alt="" style={{ width: "200px" }} />
          </Toolbar>
        </Container>
      </AppBar>
    );
  }
  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBarCustom />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
            {children}
        </Container>
      </main>
    </div>
  );
}
