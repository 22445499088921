import destinationServices from "./destinations-services";
import flightAvailabilityServices from "./flighta-availability-services";
import reservationServices from "./reservation-services";
import paymentServices from "./payment-services";
import transferServices from "./transfers-services";
import anciliariesServices from "./anciliaries-services";
import passengerServices from "./passenger-services";
import dolarPriceServices from "./dolar-price-services";
import fareConditionsServices from "./fare-conditions-services";
import invitationServices from "./invitation-services";
import gatewaysServices from "./gateway-services";
import boardingPassService from "./boarding-pass-services";
import btpmServices from "./btpm-services";
import installmentsServices from "./installments-services";
import aviorPlusServices from "./aviorplus-services";
import ordersServices from "./orders-services";

export {
  destinationServices,
  flightAvailabilityServices,
  reservationServices,
  paymentServices,
  transferServices,
  anciliariesServices,
  passengerServices,
  dolarPriceServices,
  fareConditionsServices,
  invitationServices,
  gatewaysServices,
  boardingPassService,
  btpmServices,
  installmentsServices,
  aviorPlusServices,
  ordersServices,
};
