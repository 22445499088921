import React, { useEffect, useState } from "react";
import { Button, Divider, Grid, Modal, Paper, Table, TableBody, TableCell, TableHead, TableRow, TextField, IconButton, Box, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { transferServices } from "../services";
import CloseIcon from '@material-ui/icons/Close';
import { DatePicker, LocalizationProvider } from '@material-ui/pickers';
import DateFnsUtils from "@material-ui/pickers/adapter/date-fns";
import esLocale from "date-fns/locale/es";
import { useDropzone } from "react-dropzone";
import urls from "../urls";

const styles = {
  saldoWrapper: {
    // border: "1px solid #444",
    marginTop: "20px",
    padding: "20px 40px",
    fontSize: "1rem",
    color: "#555",
    boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.1)',
    backgroundColor: '#fff',
    marginBottom: "20px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  title: {
    color: "#555",
    marginBottom: "5px"
  },
  tableCell: {
    fontWeight: 600,
    fontSize: "1rem"
  },
  positiveBalance: {
    color: "#459A30"
  },
  negativeBalance: {
    color: "#CD171A"
  },
  advanceButton: {
    height: "45px"
  },
  dropZone: {
    display: 'flex',
    flexDirection: 'column',
    padding: '2rem',
    maxHeight: '24rem',
    height: '24rem',
    position: 'relative',
    borderRadius: '6px',
    border: '2px dashed #a9a9a9',
    backgroundColor: '#f5f5f5',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#e9e9e9',
    },
  }
}

//const avances = [
//  // {
//  //   description: "Liquidación 1 ref09287",
//  //   amount: 50,
//  //   date: new Date('2023-09-15T12:00:00')
//  // },
//  // {
//  //   description: "Liquidación 2 ref09288",
//  //   amount: 100,
//  //   date: new Date('2023-09-16T12:00:00')
//  // },
//  // {
//  //   description: "Liquidación 3 ref09289",
//  //   amount: 50,
//  //   date: new Date('2023-09-17T12:00:00')
//  // },
//  // {
//  //   description: "Liquidación 4 ref09290",
//  //   amount: 50,
//  //   date: new Date('2023-09-18T12:00:00')
//  // }
//];

export default function Saldos() {
  const [advances, setAdvances] = useState([])
  //const [loading, setLoading] = useState(false)
  const [balance, setBalance] = useState(false)
  const [isAdvanceModalOpen, setAdvanceModalOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [advanceData, setAdvanceData] = useState({
    description: "",
    amount: null,
    date: new Date()
  });

  let history = useHistory();

  const [transactionImage, setTransactionImage] = useState()
  const [picture, setPicture] = useState()

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/png': ['.png'],
      'image/jpg': ['.jpg'],
      'image/jpeg': ['.jpeg'],
      'application/pdf': ['.pdf'],
    },
    onDrop: (acceptedFiles) => {
      debugger
      const imgUrl = URL.createObjectURL(acceptedFiles[0]);
      setTransactionImage(acceptedFiles[0]);
      setPicture(imgUrl);
      //     const img = new Image();
      //   img.src = imgUrl;
      //   console.log({ nh: img.naturalHeight, nw: img.naturalWidth, imgUrl });
    },
  });

  useEffect(() => {
    const getTransfers = async () => {
      //setLoading(true);
      const peticion = await transferServices.getTotalBalance()
      //setLoading(false);
      const resp = await peticion.resp;
      if (peticion.status === 200) {
        setBalance(resp.totalBalance);
      } else if (peticion.status === 401) {
        localStorage.clear();
        history.push(`/loginadminavior`);
      }
    };
    getTransfers()
  }, [history])

  useEffect(() => {
    const fetchAdvancements = async () => {
      try {
        const response = await fetch(urls.advancements);
        if (response.ok) {
          const data = await response.json();
          setAdvances(data);
        } else {
          console.error("Error fetching advancements:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching advancements:", error);
      }
    }
    fetchAdvancements();
  }, []);


  const totalAvances = advances.reduce((sum, avance) => sum + Number(avance.amount), 0);
  const adjustedBalance = (balance - totalAvances).toFixed(2);

  const handleAdvanceChange = (e) => {
    const { name, value } = e.target;
    setAdvanceData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleAdvanceSubmit = async () => {
    //setLoading(true);  // Indicate the start of async operation

    try {
      // Post new advancement to the server
      const response = await fetch(urls.advancements, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(advanceData)
      });

      if (response.ok) {
        const data = await response.json();
        setAdvances(prevAvances => [...prevAvances, data]);
        setAdvanceModalOpen(false);
      } else {
        console.error("Error adding advancement:", response.statusText);
      }
    } catch (error) {
      console.error("Error adding advancement:", error);
    } finally {
      //setLoading(false);  // Indicate the end of async operation
    }
  };

  return (
    <>
      <Modal
        open={isAdvanceModalOpen}
        onClose={() => setAdvanceModalOpen(false)}

      >
        <div style={{ padding: '30px', backgroundColor: '#fff', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '50%', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', borderRadius: "5px" }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <h2 style={{ margin: 0, marginBottom: "10px" }}>Reportar nuevo avance</h2>
            <IconButton style={{ padding: 0 }} onClick={() => setAdvanceModalOpen(false)}>
              <CloseIcon />
            </IconButton>
          </div>
          <div>
            <TextField
              fullWidth
              label="Descripción"
              name="description"
              value={advanceData.description}
              onChange={handleAdvanceChange}
            />
          </div>
          <div style={{ marginTop: '20px' }}>
            <TextField
              fullWidth
              label="Monto"
              type="number"
              name="amount"
              value={advanceData.amount}
              onChange={handleAdvanceChange}
            />
          </div>
          <div style={{ marginTop: '20px' }}>
            <LocalizationProvider dateAdapter={DateFnsUtils} locale={esLocale}>
              <DatePicker
                cancelText="Cancelar"
                displayStaticWrapperAs={"desktop"}
                animateYearScrolling
                autoOk={true}
                format={"dd-MM-yyyy"}
                value={selectedDate}
                onChange={(date) => setSelectedDate(date)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Fecha"
                    variant="standard"
                  />
                )}
              />
            </LocalizationProvider>
          </div>
          <Grid container style={{ marginBottom: '1rem' }}>
            <Grid item xs={12} style={{ position: 'relative' }}>
              <Box {...getRootProps()} className={styles.dropZone}>
                <input {...getInputProps()} />
                {picture ? (
                  <>
                    {transactionImage?.type?.includes('pdf') ? (
                      <div
                        style={{
                          margin: '0 auto',
                          height: '100%',
                          borderRadius: '6px',
                        }}>
                        PDF
                      </div>
                    ) : (
                      <image
                        alt={transactionImage.name}
                        style={{
                          position: 'absolute',
                          display: 'inline-block',
                          margin: '0 auto',
                          width: 'fit-content',
                          height: '100%',
                          objectFit: 'contain',
                          borderRadius: '6px',
                        }}
                        width={250}
                        height={250}
                        // fill
                        src={picture}
                      />
                    )}

                    <Typography align="center" style={{ color: 'gray' }}>
                      {transactionImage.name}
                    </Typography>
                  </>
                ) : (
                  <Typography align="center" style={{ color: 'gray' }}>
                    Drop Image
                  </Typography>
                )}
              </Box>
              {/* <FormHelperText error={Boolean(errors.transactionImg)}>
                {errors.transactionImg && errors.transactionImg}
              </FormHelperText> */}
            </Grid>
          </Grid>
          <div style={{ marginTop: '20px' }}>
            <Button onClick={handleAdvanceSubmit} variant="contained" color="primary">
              Submit
            </Button>
          </div>
        </div>
      </Modal>
      <Grid container direction="column" spacing={2}>
        <Grid item style={styles.saldoWrapper}>
          <h2>Saldo: <span style={adjustedBalance < 0 ? styles.negativeBalance : styles.positiveBalance}>${adjustedBalance}</span></h2>
          <Button
            variant="contained"
            color="primary"
            style={styles.advanceButton}
            onClick={() => setAdvanceModalOpen(true)}
          >
            Crear Transacción
          </Button>
        </Grid>
        <Divider />
        <h1 style={styles.title}>Avances</h1>
        <Grid item style={{ padding: "0px" }}>
          <Paper elevation={2}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={styles.tableCell}>Descripción</TableCell>
                  <TableCell style={styles.tableCell} align="left">Monto</TableCell>
                  <TableCell style={styles.tableCell} align="right">Fecha</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {advances.map((avance, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      {avance.description}
                    </TableCell>
                    <TableCell align="left">$ {avance.amount}</TableCell>
                    <TableCell align="right">{avance.date?.toLocaleDateString() ?? "N/A"}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}