import getIsNationalRoute from "./get-is-national-route"

export default function validateRoute(segments){
    if(segments.length===1) {
        return getIsNationalRoute(segments[0].departure, segments[0].arrival)
    } else if(segments.length===2) {
        if(segments[0].arrival===segments[1].departure) {
           return getIsNationalRoute(segments[1].departure, segments[1].arrival) 
        }else {
            return getIsNationalRoute(segments[0].departure, segments[0].arrival)
        }
    } else if(segments.length===4) {
        return getIsNationalRoute(segments[1].departure, segments[1].arrival) 
    }
}