export default function faresColors(fare) {
  if (fare === "LIGHT") {
    return "#FFF"
  } else if (fare === "MEDIUM") {
    return "#575756"
  } else if (fare === "FLEX") {
    return "#eca61c"
  } else if (fare === "ULTRA") {
    return "#CD171A"
  } else {
    return "#000"
  }
}
