import ButtonSpinner from "./buttonsSpinner";
import Toast from "./toast";
import Copyright from "./copyright";
import Select from "./select";
import DatePicker from "./date-picker";
import LayOut from "./lay-out";
import SearchFlightsForm from "./search-flights-form";
import PassengersSelect from "./passengers-select";
import FlightType from "./flight-type";
import PassengerForm from "./passenger-data-form";
import Title from "./title";
import SearchResume from "./search-resume";
import DatesReBooking from "./dates-re-booking";
import Fares from "./fares";
import Flight from "./flight";
import OriginDestination from "./origin-destination";
import OriginDestinationPointToPoint from "./origin-destination-point-to-point";
import ModalSegmentsInfo from "./modal-segments-info";
import SearchResults from "./search-results";
import SelectedFlightsResumen from "./selected-flights-resume";
import Spinner from "./spinner";
import ModalFareInfo from "./modal-fare-info";
import CostResume from "./cost-resume";
import FligthResume from "./flights-resume";
import Pnr from "./pnr";
import PaymentMethods from "./payment-methods";
import PaymentMethodsNew from "./payment-methods-new";
import ModalPaymentCreditCard from "./modal-payment-credit-card";
import FareTerms from "./fare-terms";
import PaymentStatus from "./payment-status";
import SearchFlightsHeader from "./search-flights-header";
import DateRange from "./date-range";
import DestinationSelects from "./destination-selects";
import PurchaseDetailsOld from "./purchase-details-old";
import PurchaseDetails from "./purchase-details";
import PurchaseDetailsInstallments from "./purchase-details-installments";
import StaticDatePicker from "./static-date-picker";
import PassengersSelectStatic from "./passengers-select-static";
import Steps from "./stepper";
import ContactForm from "./contact-data-form";
import TermsAndConditions from "./terms-and-conditions";
import CallCenter from "./call-center";
import Footer from "./footer";
import AirFaresResume from "./air-fares-resume";
import TaxesAndSurcharges from "./taxes-and-surcharges";
import Alert from "./alert";
import IframeBookingForm from "./iframe-form-booking";
import FormStep1 from "./form-step-1";
import FaresRoundTrip from "./round-trip/fares-round-trip";
import Currency from "./currency";
import TravelInfo from "./travel-info";
import FlightInfo from "./flight-info";
import PassengerDetailsInfo from "./passenger-details-info";
import ModalPopUp from "./modal-pop-up";
import ModalPaymentError from "./modal-payment-error";
import StepsPaymentFromPnr from "./steps-payment-from-pnr";
import DateRangeForm from "./date-range-form";
import DateRangeHeader from "./date-range-header";
import Precautions from "./precuations";
import DashboardAdmin from "./lay-out-admin";
import DashboardAdminTransfers from "./lay-ouyt-admin-transfers";
import CardInput from "./card-input";
import PCRSurcharge from "./pcr-surcharge";
import AncillariesPassengerList from "./ancillaries-passenger-list";
import StepsPaymentPromotion from "./step-payment-promotion";
import CustomerDataForm from "./customer-data-form";
import ModalTourCodeError from "./modal-tour-code-error";
import ModalReservation from "./modal-reservation";
import { PriorityAssistant } from "./ancillaries-components";
import DashboardConfig from "./lay-out-config";
import AncillariesAmount from "./ancillaries-amount";
import AncillariesSelectedDetails from "./ancillaries-selected-details";
import SearchBar from "./search-bar";
import DashboardGateways from "./lay-out-gateways";
import DashboardBoardingPass from "./layout-boarding";
import ModalTravelAssist from "./popup-travel-assist";
import ModalLukaPay from "./popup-lukapay";
import SeatsAmount from "./seats-amount";
import TermsAviorPlus from "./terms-aviorplus";
import PassengersSummary from "./passengers-summary";
import TimerClock from "./timer-clock";

export {
  ButtonSpinner,
  Toast,
  Copyright,
  Select,
  DatePicker,
  LayOut,
  SearchFlightsForm,
  PassengersSelect,
  FlightType,
  PassengerForm,
  Title,
  SearchResume,
  DatesReBooking,
  Fares,
  Flight,
  OriginDestination,
  OriginDestinationPointToPoint,
  ModalSegmentsInfo,
  SearchResults,
  SelectedFlightsResumen,
  Spinner,
  ModalFareInfo,
  CostResume,
  FligthResume,
  Pnr,
  PaymentMethods,
  PaymentMethodsNew,
  ModalPaymentCreditCard,
  FareTerms,
  PaymentStatus,
  SearchFlightsHeader,
  DateRange,
  DestinationSelects,
  PurchaseDetailsOld,
  PurchaseDetails,
  PurchaseDetailsInstallments,
  StaticDatePicker,
  PassengersSelectStatic,
  Steps,
  ContactForm,
  TermsAndConditions,
  CallCenter,
  Footer,
  AirFaresResume,
  TaxesAndSurcharges,
  Alert,
  IframeBookingForm,
  FormStep1,
  FaresRoundTrip,
  Currency,
  TravelInfo,
  FlightInfo,
  PassengerDetailsInfo,
  ModalPopUp,
  ModalPaymentError,
  StepsPaymentFromPnr,
  DateRangeForm,
  DateRangeHeader,
  Precautions,
  DashboardAdmin,
  DashboardAdminTransfers,
  CardInput,
  PCRSurcharge,
  AncillariesPassengerList,
  StepsPaymentPromotion,
  CustomerDataForm,
  ModalTourCodeError,
  ModalReservation,
  PriorityAssistant,
  DashboardConfig,
  AncillariesAmount,
  AncillariesSelectedDetails,
  SearchBar,
  DashboardGateways,
  DashboardBoardingPass,
  ModalTravelAssist,
  ModalLukaPay,
  SeatsAmount,
  TermsAviorPlus,
  PassengersSummary,
  TimerClock,
};
