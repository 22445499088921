import React, { useState, useEffect } from "react";
import { Grid, Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  getFullHour,
  getDateName,
  getEqp,
  formatHoursMinutes
} from "../../helpers";
import { PassengersBlock, SeatMap } from "../../components/SeatSelection"
const useStyles = makeStyles((theme) => ({
  [theme.breakpoints.down("sm")]: {
    title:{
      fontSize:"0.9rem !important"
    },
    airport:{
      fontSize:"0.7rem !important"
    }
  }
}));
export default function OriginDestinationSeatSelection(props) {
  useEffect(() => {
    if (props.seatMap && props.activeSegment && props.activePassenger) {
      setActiveSeatMap(true)
    }
}, [props.seatMap, props.activeSegment, props.activePassenger]);
  const [activeSeatMap, setActiveSeatMap] = useState(true)
  const classes = useStyles();
  return (
    <Grid
      container
      alignItems="flex-start"
      justify="center"
      direction="row"
      spacing={1}
      style={{
        borderBottom: "2px solid #E0E0E0",
        paddingBottom: "10px",
        paddingTop: "10px",
      }}
    >
      <Grid item xs={12}>
      <Typography
          display="block"
          color="textSecondary"
          className={classes.title}
        >
          Segmento {props.index + 1}
        </Typography>
      </Grid>
      <Grid item md={3} xs={5}>
        <Typography
          display="block"
          variant="h5"
          color="primary"
          className={classes.title}
        >
          <strong>{props.segment.departureInfo.cityName}</strong>
        </Typography>
        <Typography
          display="block"
          variant="caption"
          color="textSecondary"
          className={classes.airport}
        >
          {props.segment.departureInfo.airportName}(
          {props.segment.departureInfo.locationCode})
        </Typography>
        <Typography
          display="block"
          variant="h6"
          color="textSecondary"
          className={classes.title}
        >
          <strong>{getFullHour(props.segment.departureInfo.dateTime)}</strong>
        </Typography>
      </Grid>
      <Grid item md={1} xs={2} style={{padding:0}}>
        <Grid container alignItems="center" justify="center">
          <Grid item xs={12}>
            <img src="./img/iconoplane.png" alt="" style={{ width: "50px" }} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={3} xs={5}>
        <Typography
          display="block"
          variant="h5"
          color="primary"
          className={classes.title}
        >
          <strong>{props.segment.arrivalInfo.cityName}</strong>
        </Typography>
        <Typography
          display="block"
          variant="caption"
          color="textSecondary"
          className={classes.airport}
        >
          {props.segment.arrivalInfo.airportName}(
          {props.segment.arrivalInfo.locationCode})
        </Typography>
        <Typography
          display="block"
          variant="h6"
          color="textSecondary"
          className={classes.title}
        >
          <strong>{getFullHour(props.segment.arrivalInfo.dateTime)}</strong>
        </Typography>
      </Grid>
      <Grid item md={5} xs={12}>
        <Grid container alignItems="center" justify="space-between">
          <Grid item md={4} xs={5}>
            <Grid container alignItems="center" justify="center">
              <Grid item xs={12}>
                <Typography
                  display="block"
                  variant="caption"
                  color="textSecondary"
                  className={classes.text}
                >
                  Fecha
                </Typography>
                <Typography
                  display="block"
                  variant="caption"
                  color="primary"
                  className={classes.text}
                >
                  <strong>
                    {getDateName(props.segment.departureInfo.dateTime)}
                  </strong>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  display="block"
                  variant="caption"
                  color="textSecondary"
                  className={classes.text}
                >
                  Duración
                </Typography>
                <Typography
                  display="block"
                  variant="caption"
                  color="primary"
                  className={classes.text}
                >
                  <strong>
                    {formatHoursMinutes(props.segment.timeFlight)}
                  </strong>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={4} xs={5}>
            <Grid container alignItems="center" justify="center">
              <Grid item xs={12}>
                <Typography
                  display="block"
                  variant="caption"
                  color="textSecondary"
                  className={classes.text}
                >
                  Avión
                </Typography>
                <Typography
                  display="block"
                  variant="caption"
                  color="primary"
                  className={classes.text}
                >
                  <strong>
                    {getEqp(props.segment.equipmentInformation.code)}
                  </strong>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  display="block"
                  variant="caption"
                  color="textSecondary"
                  className={classes.text}
                >
                  Localizador
                </Typography>
                <Typography
                  display="block"
                  variant="caption"
                  color="primary"
                  className={classes.text}
                >
                  <strong>{props.pnr}</strong>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={4} xs={12}>
            <Grid container alignItems="center" justify="flex-end">
              <Grid item md={12} xs={4}>
                <Button
                  disabled={
                    props.activeSegment
                  }
                  color="primary"
                  variant="contained"
                  fullWidth
                  disableElevation
                  onClick={() =>props.setSegment(props.segment)}
                >
                  Seleccionar puestos
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {props.activeSegment &&<Grid item xs={12}>
        <Grid container alignItems="center" justify="center">
          <PassengersBlock
            passengers = {props.segment.passengers}
            activePassenger = {props.activePassenger}
            setPassenger = {(passenger)=>props.setPassenger(passenger)}
          />
        </Grid>
      </Grid>}
      {props.seatMap && props.activeSegment && activeSeatMap &&
      <Grid item xs={12}>
        <Grid container alignItems="center" justify="center">
          <SeatMap
            seatMap = {props.seatMap}
            updateSeat = {props.updateSeat}
            activePassenger = {props.activePassenger}
            seatSsrs = {props.seatSsrs}
            flightRPH = {props.segment.flightRPH}
            passengers = {props.segment.passengers}
            hiddenMap= {()=>{setActiveSeatMap(false); props.setPassenger(null)} }
          />
        </Grid>
      </Grid>}
    </Grid>
  );
}
