import React from "react";
import { Grid, Card, CardContent, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  grid: {
    marginTop: "15px",
  },
}));
export default function PaymentStatus(props) {
  const classes = useStyles();
  return (
    <Grid
      container
      justify="center"
      direction="row"
      spacing={1}
      className={classes.grid}
    >
      <Grid item md={6} xs={12}>
        <Card>
          <CardContent>
            <Grid
              container
              justify="center"
              direction="row"
              spacing={1}
              className={classes.grid}
            >
              <Grid item xs={10}>
                <Typography
                  display="block"
                  variant="caption"
                  color="textSecondary"
                  align="center"
                  style={{ fontSize: "1em" }}
                >
                  Estado de pago:{" "}
                  {props.status === "approved" ? "APROBADO" : "EN PROCESO"}
                </Typography>
                <Typography
                  variant="caption"
                  align="center"
                  style={{ fontSize: "1em" }}
                >
                  {props.status === "approved"
                    ? "Pago aprobado con éxito. Un correo con los detalles de la compra le será enviado."
                    : "Su pago está en proceso. En cuanto este listo le enviaremos un correo con los detalles de la compra"}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
