import React, { useState, useEffect } from "react";
import { Grid, MenuItem, TextField, Typography } from "@material-ui/core";
import countries from "../helpers/countries.json";
import { FormValidator } from "../helpers/";
import { Button } from "@material-ui/core/";
import { ExpandMore } from "@material-ui/icons";
import { styled } from "@material-ui/core/styles";
import {
  filterAlpha,
  filterNumber,
  onBlurEmail,
} from "../helpers/filterValues";
import {
  Save,
  //ToggleLeft,
  //ToggleRight
} from "lucide-react";
//import { aviorPlusServices } from "../services";

const MyExpandExpandMore = styled(ExpandMore)({
  color: "#FA7268",
  "&.MuiSelect-iconOpen": {
    transform: "none",
  },
});

export default function PassengerForm(props) {
  const validator = new FormValidator([
    {
      field: "name",
      method: "isEmpty",
      validWhen: false,
      message: "Debe ingresar nombre del pasajero",
    },
    {
      field: "lastName",
      method: "isEmpty",
      validWhen: false,
      message: "Debe ingresar apellido del pasajero",
    },
    {
      field: "email",
      method: "isEmpty",
      validWhen: false,
      message: "Debe ingresar email",
    },
    {
      field: "email",
      method: "isEmail",
      validWhen: true,
      message: "Ingrese un correo electrónico valido",
    },
    {
      field: "phoneNumber",
      method: "isEmpty",
      validWhen: false,
      message: "Ingrese numero de telefono",
    },
  ]);

  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneCode, setPhoneCode] = useState("+58");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [contactInfo, setContactInfo] = useState({
    name: "",
    lastName: "",
    phone: {
      code: "",
      number: "",
    },
    email: "",
  });
  const [fields, setFields] = useState(false);

  const [nameError, setNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [phoneNumberError2, setPhoneNumberError2] = useState("");
  const [emailError, setEmailError] = useState("");
  const [emailError2, setEmailError2] = useState("");

  /* const [checkUser, setCheckUser] = useState(false); */

  /* const handleChange = () => {
    if (checkUser) {
      setCheckUser(false);
      setFields(false);
      setName("");
      setLastName("");
      setPhoneCode("");
      setPhoneNumber("");
      setEmail("");
    } else {
      setCheckUser(true);
      setName(props.userData?.user_info.first_name);
      setLastName(props.userData?.user_info.last_name);
      setPhoneCode(`+${props.userData?.user_info.code_phone}`);
      setPhoneNumber(props.userData?.user_info.phone);
      setEmail(props.userData?.user_info.email);
    }
  }; */

  useEffect(() => {
    async function setContactInfo() {
      if (props.passengers.length !== 0) {
        for (let passenger of props.passengers) {
          if (passenger.id === "1") {
            setName(passenger.nameContact);
            setLastName(passenger.lastNameContact);
            setPhoneCode(passenger.phoneContact.code);
            setPhoneNumber(passenger.phoneContact.number);
            setEmail(passenger.emailContact);
            setFields(true);
          }
        }
      }
      //else console.log("no hay")
    }
    setContactInfo();
  }, [props.passengers]);

  const validateData = async () => {
    let validation;

    if (!fields) {
      validation = validator.validate({
        name,
        lastName,
        email,
        phoneNumber,
      });
      setNameError(validation.name.message);
      setLastNameError(validation.lastName.message);
      setEmailError(validation.email.message);
      setPhoneNumberError(validation.phoneNumber.message);

      const numberInvalid = await props.findPhoneContact({
        code: phoneCode,
        number: phoneNumber,
      });

      const emailInvalid = props.findEmailContact(email);

      if (numberInvalid) {
        setPhoneNumberError2("Debe ser distinto al de los pasajeros");
      } else {
        setPhoneNumberError2("");
      }

      if (emailInvalid) {
        setEmailError2("Debe ser distinto al de los pasajeros");
      } else {
        setEmailError2("");
      }

      //console.log(edad)
      if (
        validation.isValid &&
        phoneNumberError2.length === 0 &&
        !numberInvalid &&
        !emailInvalid
      ) {
        setFields(true);
        let contactInfoAux = {
          name: name,
          lastName: lastName,
          phone: {
            code: phoneCode,
            number: phoneNumber,
          },
          email: email,
        };
        setContactInfo(contactInfoAux);
        props.addContactInfo(contactInfoAux);
      }
    } else {
      setFields(!fields);
      props.deleteContactInfo(contactInfo);
    }
  };

  const onBlurPhone = async () => {
    await setPhoneNumberError2(
      (await props.findPhoneContact({ code: phoneCode, number: phoneNumber }))
        ? "Debe ser distinto al de los pasajeros"
        : ""
    );
  };

  return (
    <div>
      <Grid container direction="column">
        <Grid item md={12} xs={12} style={{ marginBottom: "10px" }}>
          <Grid container direction="row" justify="space-between">
            <Typography color="secondary">
              Aplica para todos los pasajeros de la reserva.
            </Typography>
            <Typography color="secondary">
              (*) Todos los campos son obligatorios
            </Typography>
          </Grid>
        </Grid>

        <Grid
          container
          direction="row"
          alignItems="center"
          justify="flex-end"
          style={{ padding: 0 }}
        >
          {/* {props.userData && (
            <Typography>
              <Grid
                component="label"
                container
                alignItems="center"
                style={{ height: 50 }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  {!checkUser ? (
                    <ToggleLeft onClick={handleChange} />
                  ) : (
                    <ToggleRight onClick={handleChange} color="#177ddc" />
                  )}
                </div>
                <p style={{ fontSize: "0.9rem", margin: "0px 5px" }}>
                  Usar mis datos
                </p>
              </Grid>
            </Typography>
          )} */}

          {fields ? (
            <Grid item style={{ borderRightWidth: 0, marginBottom: 5 }}>
              <Button
                variant="contained"
                className={props.classes.button}
                style={{
                  backgroundColor: "transparent",
                  boxShadow: "none",
                  padding: 0,
                }}
                onClick={validateData}
              >
                <div className={props.classes.divIcon}>
                  <img src="./img/icono-editar.png" alt="edit-icon" />
                </div>
              </Button>
            </Grid>
          ) : null}
        </Grid>

        <Grid item xs={12} md={12}>
          <Grid container className={props.classes.Grid} spacing={2}>
            <Grid item md={5} xs={12}>
              <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                  <div className={props.classes.inputDiv}>
                    <TextField
                      id="firstName"
                      name="firstName"
                      value={name}
                      label="Nombre (s) *"
                      fullWidth
                      onChange={(e) => setName(filterAlpha(e.target.value))}
                      helperText={nameError}
                      error={nameError !== ""}
                      InputLabelProps={{
                        shrink: true,
                        className: props.classes.floatingLabelFocusStyle,
                        htmlFor: "firstName",
                      }}
                      InputProps={{
                        classes: {
                          input: props.classes.inputLabel,
                          underline: props.classes.underline,
                        },
                        disabled: fields,
                      }}
                    />
                  </div>
                </Grid>

                <Grid item md={6} xs={12}>
                  <div className={props.classes.inputDiv}>
                    <TextField
                      name="lastname"
                      id="lastname"
                      value={lastName}
                      label="Apellido (s) *"
                      fullWidth
                      onChange={(e) => setLastName(filterAlpha(e.target.value))}
                      helperText={lastNameError}
                      error={lastNameError !== ""}
                      InputLabelProps={{
                        shrink: true,
                        className: props.classes.floatingLabelFocusStyle,
                        htmlFor: "lastname",
                      }}
                      InputProps={{
                        classes: {
                          input: props.classes.inputLabel,
                          underline: props.classes.underline,
                        },
                        disabled: fields,
                      }}
                    />
                  </div>
                </Grid>
              </Grid>
            </Grid>

            <Grid item md={4} xs={12}>
              <Grid container spacing={2}>
                <Grid item md={5} xs={5}>
                  <div className={props.classes.inputDiv}>
                    <TextField
                      value={phoneCode}
                      select
                      id="country"
                      onChange={(e) => setPhoneCode(e.target.value)}
                      label={"Pais *"}
                      InputLabelProps={{
                        shrink: true,
                        className: props.classes.floatingLabelFocusStyle,
                        htmlFor: "country",
                      }}
                      InputProps={{
                        classes: {
                          input: props.classes.inputLabel,
                          underline: props.classes.underline,
                        },
                        disabled: fields,
                      }}
                      SelectProps={{
                        IconComponent: MyExpandExpandMore,
                      }}
                    >
                      {countries.map((countrie) => (
                        <MenuItem
                          key={countrie.code}
                          value={countrie.phone_code}
                          disabled={fields}
                        >
                          {`${countrie.phone_code} (${countrie.name})`}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                </Grid>
                <Grid item md={7} xs={7}>
                  <div className={props.classes.inputDiv}>
                    <TextField
                      name="number"
                      id="number"
                      label="Telefono *"
                      value={phoneNumber}
                      fullWidth
                      helperText={phoneNumberError || phoneNumberError2}
                      error={
                        phoneNumberError !== "" || phoneNumberError2 !== ""
                      }
                      onBlur={onBlurPhone}
                      onChange={(e) =>
                        setPhoneNumber(filterNumber(e.target.value))
                      }
                      InputLabelProps={{
                        shrink: true,
                        className: props.classes.floatingLabelFocusStyle,
                        htmlFor: "number",
                      }}
                      InputProps={{
                        classes: {
                          input: props.classes.inputLabel,
                          underline: props.classes.underline,
                        },
                        disabled: fields,
                      }}
                    />
                  </div>
                </Grid>
              </Grid>
            </Grid>

            <Grid item md={3} xs={12}>
              <div className={props.classes.inputDiv}>
                <TextField
                  name="email"
                  id="email"
                  value={email}
                  label="Email *"
                  fullWidth
                  onChange={(e) => setEmail(e.target.value)}
                  //helperText={emailError}
                  //error={emailError !== ""}
                  ///
                  helperText={emailError || emailError2}
                  error={emailError !== "" || emailError2 !== ""}
                  onBlur={() => {
                    setEmailError(onBlurEmail(email));
                  }}
                  InputLabelProps={{
                    shrink: true,
                    className: props.classes.floatingLabelFocusStyle,
                    htmlFor: "email",
                  }}
                  InputProps={{
                    classes: {
                      input: props.classes.inputLabel,
                      underline: props.classes.underline,
                    },
                    disabled: fields,
                  }}
                />
              </div>
            </Grid>

            <Grid item md={6} xs={12}></Grid>
          </Grid>
        </Grid>
        {!fields ? (
          <Grid
            container
            justify="flex-end"
            spacing={2}
            style={{ marginBottom: "5px" }}
          >
            <Grid item md={10} xs={false}></Grid>
            <Grid item md={2} xs={12}>
              <Button
                variant="contained"
                fullWidth
                color={"primary"}
                style={{ height: "100%", textTransform: "none" }}
                onClick={validateData}
              >
                <div className={props.classes.aceptButtonLabel}>
                  <Save /> <p style={{ margin: "0px 10px" }}>Guardar</p>
                </div>
              </Button>
            </Grid>
          </Grid>
        ) : null}
      </Grid>
    </div>
  );
}
