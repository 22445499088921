/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Avatar, CssBaseline, TextField, Box, Typography, Container } from '@material-ui/core/';
import { makeStyles } from '@material-ui/core/styles';
import urls from "../urls";
import { useHistory } from "react-router-dom";
import {ButtonSpinner, Toast, Copyright } from "../components/";
const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    width: 100,
    height: 100,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function Login() {
  const classes = useStyles();
  const [email, setEmail]= useState('');
  const [password, setPassword]= useState('');
  const [loading, setLoading] = useState(false)
  const [toast,setToast] = useState(false)
  const [toastMessage,setToastMessage] = useState('')
  let history = useHistory();
    const login = async()=>{
        setLoading(true)
        const peticion = await fetch(urls.login,{
            headers:{
              'Content-Type': 'application/json',
            },
            method:'POST',
            body:JSON.stringify({
              "userName":email,
              "password":password
            })
              
            
        })
        setLoading(false)
        const resp = await peticion.json()
        //console.log(resp)
        if(peticion.status===200){
            localStorage.setItem('token',resp.token)
            if(resp.error){
              setToast(true)
              setToastMessage(resp.error.message)
            }else if(resp.secondaryRole==="TRANSFER"){
              history.push('/dHJhbnNmZXJlbmNpYXM=transferencias')
            }else if(resp.secondaryRole==="CONFIG"){
              history.push('/adminitinerario')
            }else if(resp.secondaryRole==="GATEWAY"){
              history.push('/paymentsgatewaysconfig')
            }else if(resp.secondaryRole==="BOARDING"){
              history.push('/boarding-pass')
            }else{
              history.push('/openlink')
            }
        }else{
            setToast(true)
            setToastMessage(resp.error.message)
        }
    }
  return (
    <Container component="main" maxWidth="xs">
      <Toast message={toastMessage} open={toast} close={()=>{setToast(false);}}/>
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar src="./img/avior-01.png" alt="" className={classes.avatar}/>
        <Typography component="h1" variant="h5">
          Inicio de sesión 
        </Typography>
        <form className={classes.form}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="User"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={(e)=>setEmail(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e)=>setPassword(e.target.value)}
          />
          <ButtonSpinner 
            fullWidth
            action={login}
            loading={loading}
            text="Login"/>
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}