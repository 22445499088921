import React from "react";
import { Stepper, Step, StepLabel, Typography, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  [theme.breakpoints.down("md")]: {
    stepIcon: {
      fontSize: "1rem",
      color: "#CD171A",
    },
    step: {
      paddingLeft: "4px",
      paddingRight: "4px",
      flex: 1,
      position: "relative",
      borderLeft: "none !important",
    },
    stepper: {
      //background: "#EAEAEA",
      padding: "30px 5px",
      alignItems: "flex-start",
      display: "flex",
      flexDirection: "row",
      overflowY: "auto",
    },
    stepLabel: {
      flexDirection: "column",
      display: "flex",
      alignItems: "center",
      textAlign: "center",
    },

    label: {
      fontSize: "0.7rem",
      fontWeight: 500,
      margin: 0,
    },
  },

  [theme.breakpoints.up("md")]: {
    stepIcon: {
      fontSize: "2rem",
      color: "#CD171A",
    },
    step: {
      justifyContent: "center",
      borderRight: "1px solid #C8C8C8",
      flex: 1,
      position: "relative",
    },
    stepper: {
      padding: "5px",
      //background: "#EAEAEA",
      alignItems: "flex-start",
      display: "flex",
      justifyContent: "center",
      width: "90%",
    },
    stepLabel: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },

    label: {
      fontSize: "0.8rem",
      fontWeight: 500,
      margin: 0,
    },
  },
  labelContainer: {
    width: "auto !important",
  },
  active: {
    color: "#CD171A !important",
    fontWeight: "bold !important",
  },
}));
function getSteps() {
  return [
    "BUSCAR",
    "SELECCIONAR VUELO",
    "REGISTRAR DATOS",
    "ADICIONALES",
    "COMPRAR",
  ];
}
export default function Steps(props) {
  const classes = useStyles();
  return (
    <Grid
      container
      justify="center"
      direction="row"
      //style={{ background: "#EAEAEA" }}
    >
      <Stepper
        activeStep={props.active}
        connector={null}
        className={classes.stepper}
        style={{ backgroundColor: "unset" }}
      >
        {getSteps().map((label, index) => (
          <Step
            className={classes.step}
            style={{
              borderLeft: index === 0 ? "1px solid #C8C8C8" : null,
            }}
            key={index}
          >
            <StepLabel
              classes={{
                root: classes.stepLabel,
                labelContainer: classes.labelContainer,
                active: classes.active,
              }}
              icon={
                <Typography color="primary" className={classes.stepIcon}>
                  {index + 1}
                </Typography>
              }
            >
              <p className={classes.label}>{label}</p>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Grid>
  );
}
