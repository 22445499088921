import React from "react";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles({
  paper: {
    position: "absolute",
    maxWidth: "900px",
    width:"90%",
    height:"100vh",
    backgroundColor: "#fff",
    border: "1px solid #000",
    top:"5%",
    overflowY:"scroll",
    
  },
  modal:{
      display:"flex",
      justifyContent:"center",
      alignItems:"center",
      
    
      
  },
});
export default function ModalFareInfo(props) {
  const classes = useStyles();
  function getSrc(){
    const name = props.fareName;
    if(name==="GCA PROM-ON")
        return "./img/PROMO.png"
    else if(name==="GCA GO")
        return "./img/GO.png"
    else if(name==="GCA VALUE")
        return "./img/VALUE.png"
    else if(name==="GCA GO PLUS")
        return "./img/GOPLUS.png"
    else 
        return "./img/CLASSICPLUS.png"
  }
  return (
    <Modal className={classes.modal} open={props.open} onClose={() => props.close()}>
      <div className={classes.paper}>
          <img src={getSrc()} alt="" style={{width:"100%"}}/>
      </div>
    </Modal>
  );
}
