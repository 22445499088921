import React from "react";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import LatoRegularPro from "./fonts/Lato-Regular.ttf"

const LatoRegular = {
  fontFamily: 'Lato-Regular, sans-serif',
  fontStyle: 'normal',
  src: `
          local('Lato-Regular'),
          url(${LatoRegularPro}) format('truetype')
        `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',

}
const theme = createMuiTheme({
  palette: {
    primary: {
      //light: "rgba(103, 58, 183, 1)",
      main: "#CD171A",
      //dark: "rgba(81, 45, 168, 1)",
      contrastText: "rgba(255, 255, 255, 1)"
    },
    secondary: {
      //light: "#000",
      main: "#575756",
      //dark: "#000",
      contrastText: "rgba(255, 255, 255, 1)"
    }
  },
  typography: {
    useNextVariants: true,
    fontFamily: 'Lato-Regular, sans-serif',
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [LatoRegular],
      },
    },
  },
});

export default function AppTheme({ children }) {
  return (
    <MuiThemeProvider theme={theme}>
      {children}
    </MuiThemeProvider>
  );
}
