import urls from "../urls";

const lowFares = async(
  isRoundTrip,
  origin,
  destination,
  departureDate,
  returnDate,
  countAdults,
  countChildrens,
  countBabies,
  currency,
  tourCode
)=>{
  const peticion = await fetch(
    `${urls.lowFares}/${origin}/${destination}?currency=${currency}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization":'Basic '+ btoa('avior:fazQMUyq'),
      },
      body: JSON.stringify({
        isRoundTrip: isRoundTrip,
        departureDate: departureDate,
        returnDate: returnDate,
        passengerType: [
          {
            type: "ADT",
            quantity: countAdults
          },
          {
            type: "CHD",
            quantity: countChildrens
          },
          {
            type: "INF",
            quantity: countBabies
          }
        ],
        tourCode:tourCode
      })
    }
  );
  const resp = await peticion.json();
  return {
    status: peticion.status,
    resp
  };
}

const flightAvailability = async (
  origin,
  destination,
  isRoundTrip,
  dateDeparture,
  dateArrival,
  countAdults,
  countChildrens,
  countBabies,
  currency,
  tourCode
) => {
  const peticion = await fetch(
    `${isRoundTrip?urls.availabilityV2:urls.availability}/${origin}/${destination}?currency=${currency}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization":'Basic '+ btoa('avior:fazQMUyq'),
      },
      body: JSON.stringify({
        isRoundTrip: isRoundTrip,
        departureDate: dateDeparture,
        returnDate: dateArrival,
        passengerType: [
          {
            type: "ADT",
            quantity: countAdults
          },
          {
            type: "CHD",
            quantity: countChildrens
          },
          {
            type: "INF",
            quantity: countBabies
          }
        ],
        tourCode:tourCode
      })
    }
  );
  const resp = await peticion.json();
  return {
    status: peticion.status,
    resp: isRoundTrip? resp.results : resp,
    terms: resp.terms
  };
};

const flightAvailabilityServices = Object.freeze({
    flightAvailability,
    lowFares
});

export default flightAvailabilityServices;
