import React, { useState } from "react";
import { Grid, TextField, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { FormValidator } from "../helpers";
import { ButtonSpinner, Alert, Spinner } from "../components";
import { paymentServices } from "../services";
import { FormatCurrency } from "../helpers";

const useStyles = makeStyles((theme) => ({
    inputGrid: {
      marginBottom: "15px",
    },
    Grid: {
      display: "flex",
      justifyContent: "center",
    },
    floatingLabelFocusStyle: {
      color: "#CD171A",
      fontSize: "15pt",
      width: "max-content",
    },
    inputLabel: {
      fontSize: "14pt",
      paddingBottom: 0,
      color: "#555555!important",
      height: "20px",
    },
    underline: {
      "&::before": {
        display: "none",
      },
      "&::after": {
        display: "none",
      },
    },
    inputDiv: {
      borderWidth: "1pt",
      borderStyle: "solid",
      borderColor: "#cccccc",
      padding: "10px",
      borderRadius: "5px",
      "& .MuiFormControl-root": {
        width: "100%",
      },
    },
    expandIcon: {
      color: "#FA7268",
    },
    [theme.breakpoints.down("md")]: {
      floatingLabelFocusStyle: {
        fontSize: "0.9rem",
      },
      inputLabel: {
        fontSize: "0.8rem",
      },
    },
}));

export default function PaymentBookingCreditCardBancaAmiga(props) {
  const classes = useStyles();
  const [toast, setToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [dni, setDni] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState("");
  const [loading, setLoading]=useState(false)
  const [url, setUrl] = useState(null);
  const totalAmount = 
    parseFloat(props.originFlight ? props.originFlight.fare.totalAmount : 0) +
    parseFloat(props.returnFlight ? props.returnFlight.fare.totalAmount : 0);
  const getAmountAmount = () => {
    return parseFloat(
        props.ancillaries.going.reduce(function (a, b) {
        return parseFloat(a) + parseFloat(b.total);
      }, 0) +
      props.ancillaries.goBack.reduce(function (a, b) {
          return parseFloat(a) + parseFloat(b.total);
        }, 0)
    ).toFixed(2);
  };
  const getAmountSeats = () =>{
    return parseFloat(
            props.selectedSeats.going.reduce(function (a, b) { return parseFloat(a) + parseFloat(b.total); }, 0) + 
           props.selectedSeats.goBack.reduce(function (a, b) { return parseFloat(a) + parseFloat(b.total); }, 0)
        ).toFixed(2)
  }
  const totalSeats = getAmountSeats()
  const totalAncillaries = getAmountAmount();
  const validator = new FormValidator([
    {
      field: "dni",
      method: "isEmpty",
      validWhen: false,
      message: "Debe ingresar su número de documento",
    },
    {
      field: "name",
      method: "isEmpty",
      validWhen: false,
      message: "Debe ingresar su nombre y apellido",
    },
    {
      field: "email",
      method: "isEmpty",
      validWhen: false,
      message: "Debe ingresar email",
    },
    {
      field: "email",
      method: "isEmail",
      validWhen: true,
      message: "Ingrese un correo electrónico valido",
    }
  ])
  const paymentCreditCard = async () => {
    const validation = validator.validate({
      email,
      name,
      dni
    });
    setErrors(validation);
    if (validation.isValid) {
      const body = {
        email,
        pnr: props.pnr,
        name,
        dni
      };
      setLoading(true);
      const { response, status } = await paymentServices.paymentOrderBancaAmigaBooking(body)
      setLoading(false);
      if (status === 200) {
        setUrl(response.orderURL);
        setToastMessage("Presione el boton continuar para dirigirse al portal de pago");
        setToast(true);
      } else {
        setToastMessage(response.error.message);
        setToast(true);
      }
    }
  };
  return (
    <div>
      <Grid
        container
        justify="center"
        direction="row"
        alignItems="center"
        spacing={1}
      >
        <Grid item md={12} xs={12} className={classes.Grid}>
          <Grid item md={5} xs={12} className={classes.inputGrid}>
            <div className={classes.inputDiv}>
              <TextField
                value={email}
                label="Correo electrónico"
                name="email"
                id="email"
                fullWidth
                error={errors.email ? errors.email.isInvalid : false}
                helperText={errors.email ? errors.email.message : ""}
                InputLabelProps={{
                  shrink: true,
                  className: classes.floatingLabelFocusStyle,
                  htmlFor: "email",
                }}
                InputProps={{
                  classes: {
                    input: classes.inputLabel,
                    underline: classes.underline,
                  },
                }}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </Grid>
        </Grid>
        <Grid item md={12} xs={12} className={classes.Grid}>
          <Grid item md={5} xs={12} className={classes.inputGrid}>
            <div className={classes.inputDiv}>
              <TextField
                value={name}
                label="Nombre y apellido"
                name="name"
                id="name"
                fullWidth
                error={errors.name ? errors.name.isInvalid : false}
                helperText={errors.name ? errors.name.message : ""}
                InputLabelProps={{
                  shrink: true,
                  className: classes.floatingLabelFocusStyle,
                  htmlFor: "name",
                }}
                InputProps={{
                  classes: {
                    input: classes.inputLabel,
                    underline: classes.underline,
                  },
                }}
                onChange={(e) =>
                  setName(e.target.value)
                }
              />
            </div>
          </Grid>
        </Grid>
        <Grid item md={12} xs={12} className={classes.Grid}>
          <Grid item md={5} xs={12} className={classes.inputGrid}>
            <div className={classes.inputDiv}>
              <TextField
                value={dni}
                label="Número de documento"
                name="dni"
                id="dni"
                fullWidth
                error={errors.dni ? errors.dni.isInvalid : false}
                helperText={errors.dni ? errors.dni.message : ""}
                InputLabelProps={{
                  shrink: true,
                  className: classes.floatingLabelFocusStyle,
                  htmlFor: "dni",
                }}
                InputProps={{
                  classes: {
                    input: classes.inputLabel,
                    underline: classes.underline,
                  },
                }}
                onChange={(e) => setDni(e.target.value)}
              />
            </div>
          </Grid>
        </Grid>
        <Grid item md={12} xs={12} className={classes.Grid}>
          <Typography variant="h5">
            Total a Pagar USD $
            {FormatCurrency(`${parseFloat(parseFloat(totalAmount)+parseFloat(totalAncillaries)+parseFloat(totalSeats)).toFixed(2)}`)}
          </Typography>
        </Grid>
        <Grid item md={12} xs={12} className={classes.Grid}>
          <Grid item md={5} xs={12} className={classes.inputGrid}>
            <Grid container justify="space-between">
              <div style={{ width: "158px", height: "41px" }}>
                <ButtonSpinner
                  action={() => {
                    props.goBack();
                  }}
                  fullWidth
                  text="Atras"
                />
              </div>
              <div style={{ width: "158px", height: "41px" }}>
                <ButtonSpinner
                  action={paymentCreditCard}
                  fullWidth
                  text="Procesar Pago"
                />
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Alert
        message={toastMessage}
        open={toast}
        buttonMessage="Continuar"
        close={() => {
          setToast(false);
          if(url){
            window.location.replace(url);
          }
          
        }}
      />
      <Spinner loading={loading} />
    </div>
  );
}