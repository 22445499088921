import React, { useState, useEffect } from "react";
import { Alert, Spinner } from "../components/";
import { paymentServices } from "../services";
import { useHistory, useParams } from "react-router-dom";

export default function VerifyPaymentAncillariesBancaAmiga(){
    let history = useHistory();
    const [loading, setLoading] = useState(false);
    const [toast, setToast] = useState(false);
    const [toastMessage, setToastMessage] = useState("");
    const [data, setData] = useState("");
    const { orderRef } = useParams();
    
    const verifyPayment = async (orderRef)=>{
        setLoading(true);
        const { response, status } = await paymentServices.veryfyOrderBancaAmigaMiscellaneous(orderRef)
        setLoading(false);
        if (status === 200) {
            if(response.order.payment.Status==="rejected"){
                setData(response.order)
              setToastMessage("Pago rechazado");
              setToast(true);
            }else{
              history.push(`/thankyoupageopenlink`, {
                state: { ...response },
              });
            }
        } else {
            setToastMessage(response.error.message);
            setToast(true);
        }
    }

    useEffect(() => {
        verifyPayment(orderRef);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            <Alert
                message={toastMessage}
                open={toast}
                buttonMessage="Intentar de nuevo."
                close={() => {
                setToast(false);
                history.push(`/payment-miscelaneous?payload=${Buffer.from(JSON.stringify(data)).toString('base64')}`);
                }}
            />
            <Spinner loading={loading} />
        </div>
    )
}