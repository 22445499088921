import { TransfersTable } from "./../components/TransfersTable";
import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Container,
  TablePagination,
  TableContainer,
  Paper,
  Grid,
  Select,
  MenuItem,
  Button,
  TextField,
  FormControl,
  InputLabel,
  InputAdornment,
} from "@material-ui/core";
import { transferServices, ordersServices } from "../services";
import { Spinner, Toast, SearchBar } from "../components";
import { useHistory } from "react-router-dom";

import { DateRangePicker, LocalizationProvider } from "@material-ui/pickers";
import DateFnsUtils from "@material-ui/pickers/adapter/date-fns";
import esLocale from "date-fns/locale/es";
import moment from "moment";
import useTransferFilters from "../hooks/useTransferFilters";
import { Download, CalendarDays, ListRestart } from "lucide-react";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  container: {
    marginTop: theme.spacing(8),
  },
}));

const transactionTypes = [
  { value: "zelle", label: "Zelle" },
  { value: "stripe", label: "Back9" },
  { value: "bs", label: "Bolívares" },
];
//const transfersGetByType = {
//  zelle: (params) => transferServices.getTransfersByType({ ...params, type: "zelle" }),
//  stripe: (params) => transferServices.getTransfersByType({ ...params, type: "stripe" }),
//  bs: (params) => transferServices.getTransfersBss(params),
//  bsCuponAir: (params) => transferServices.getTransfersAviorGoBss(params),
//  zelleCuponAir: (params) => transferServices.getTransfersAviorGo(params),
//};

const transfersExportByType = {
  zelle: (params) =>
    transferServices.exportTransfersByType({ ...params, type: "zelle" }),
  stripe: (params) =>
    transferServices.exportTransfersByType({ ...params, type: "stripe" }),
  bs: (params) => transferServices.exportTransfersBss(params),
  bsCuponAir: null,
  zelleCuponAir: null,
};

//const getTransferQueryByType = {
//  zelle: (params) => transferServices.getTransferQuery({ ...params, type: "zelle" }),
//  stripe: (params) => transferServices.getTransferQuery({ ...params, type: "stripe" }),
//  bs: (params) => transferServices.getTransferQueryBss(params),
//  bsCuponAir: (params) => transferServices.getTransfersAviorGoBss(params),
//  zelleCuponAir: (params) => transferServices.getTransfersAviorGo(params),
//}
const statusTypes = [
  { value: "all", label: "Todos" },
  { value: "pending", label: "Pendiente" },
  { value: "approved", label: "Aprobado" },
  { value: "rejected", label: "Rechazado" },
];
const fmtDate = (date) => moment(date).format("DD-MM-YYYY");

export default function Transfers() {
  const classes = useStyles();
  let history = useHistory();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [loading, setLoading] = useState(false);
  const [transfers, setTransfers] = useState([]);
  const [count, setCount] = useState(0);
  const [toast, setToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [query, setQuery] = useState("");
  const [isQuery, setIsQuery] = useState("");

  const { filters, setFilters, resetFilters } = useTransferFilters();

  const handleResetFilters = () => {
    resetFilters();
    setTempDates([null, null]);
  };

  // Destructure filter values for easy access.
  const { startDate, endDate, filterStatus, transactionType } = filters;

  // Wrapper functions defined within the component
  const setStartDate = (date) => setFilters({ startDate: date });
  const setEndDate = (date) => setFilters({ endDate: date });
  const setFilterStatus = (status) => setFilters({ filterStatus: status });
  const setTransactionType = (type) => setFilters({ transactionType: type });
  const [tempDates, setTempDates] = useState([null, null]);
  const tempDatesRef = useRef([null, null]);

  const handlePickerClose = (dates) => {
    // When the picker closes, commit the temporary dates to the main state
    setStartDate(moment(tempDatesRef.current[0]).format("YYYY-MM-DD"));
    setEndDate(moment(tempDatesRef.current[1]).format("YYYY-MM-DD"));
  };

  const handleChange = (event) => {
    setTransactionType(event.target.value);
  };

  useEffect(() => {
    console.log(startDate, endDate);
    getTransfersByType({
      page: page + 1,
      limit: rowsPerPage,
      status: filterStatus,
      startDate,
      endDate,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, startDate, endDate, transactionType, filterStatus]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (isQuery) {
      getTransfersByType({ page: newPage + 1, limit: rowsPerPage, query });
    } else {
      getTransfersByType({ page: newPage + 1, limit: rowsPerPage });
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
    if (isQuery) {
      getTransfersByType({ page: 1, limit: event.target.value, query });
    } else {
      getTransfersByType({ page: 1, limit: event.target.value });
    }
  };
  const getTypeMethod = {
    stripe: "STRIPE",
    bs: "BAPM",
    zelle: "zelle",
  };
  const getTransfersByType = async ({
    page,
    limit,
    status,
    startDate,
    endDate,
    query,
  }) => {
    setLoading(true);

    const peticion = await ordersServices.getOrders({
      page,
      limit,
      query,
      starDate: startDate,
      endDate,
      type: getTypeMethod[transactionType],
      status: status === "all" ? "" : status,
    });
    //await transfersGetByType[transactionType]({ page, limit, status: filterStatus, startDate, endDate });
    setLoading(false);
    const resp = await peticion.resp;
    if (peticion.status === 200) {
      setTransfers(resp.docs);
      setPage(resp.page ? resp.page - 1 : 0);
      setCount(resp.totalDocs);
    } else if (peticion.status === 401) {
      localStorage.clear();
      history.push(`/loginadminavior`);
    }
  };
  const aproveTranfer = async (_id) => {
    setLoading(true);
    const peticion = await ordersServices.approveOrder(_id);
    const resp = await peticion.resp;
    setLoading(false);
    if (peticion.status === 200) {
      setToastMessage("Pago aprobado");
      setToast(true);
    } else if (peticion.status === 400) {
      setToastMessage(resp.error.message);
      setToast(true);
    } else if (peticion.status === 401) {
      localStorage.clear();
      history.push(`/loginadminavior`);
    }
  };
  const cancelTranfer = async (_id) => {
    setLoading(true);
    const peticion = await ordersServices.rejectOrder(_id);
    setLoading(false);
    const resp = await peticion.resp;
    if (peticion.status === 200) {
      setToastMessage("Pago rechazado");
      setToast(true);
    } else if (peticion.status === 400) {
      setToastMessage(resp.error.message);
      setToast(true);
    } else if (peticion.status === 401) {
      localStorage.clear();
      history.push(`/loginadminavior`);
    }
  };

  const exportTransfers = async () => {
    try {
      setLoading(true);
      const response =
        transfersExportByType[transactionType] &&
        (await transfersExportByType[transactionType]({ startDate, endDate }));
      setLoading(false);
      var blob = new Blob([response], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      var link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download =
        startDate && endDate
          ? `tranferencias-${
              transactionType === "stripe" ? "Back9" : transactionType
            }_desde-${fmtDate(startDate)}-hasta-${fmtDate(endDate)}.xlsx`
          : `tranferencias-${transactionType}.xlsx`;
      link.click();
      window.URL.revokeObjectURL(link.href);
    } catch (error) {
      alert("Ha ocurrido un error.");
    }
  };

  const querySearchBar = async (newQuery) => {
    setQuery(newQuery);
    setPage(0);
    setIsQuery(true);
    getTransfersByType({ page: 1, limit: rowsPerPage, query: newQuery });
  };

  const clearSearchBar = async () => {
    setPage(0);
    setIsQuery(false);
    getTransfersByType({ page: 1, limit: rowsPerPage });
  };

  return (
    <Container component="main">
      <Grid container justify="space-between" style={{ marginTop: "20px" }}>
        <Grid item>
          <SearchBar
            label="PNR, referencia, nombre o monto"
            search={querySearchBar}
            clear={clearSearchBar}
          />
        </Grid>
        <FormControl style={{ width: 150 }}>
          <InputLabel id="demo-simple-select-label">Forma de pago</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={transactionType}
            onChange={handleChange}
          >
            {transactionTypes.map((tType) => (
              <MenuItem value={tType.value} key={tType.value}>
                {tType.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl style={{ width: 150 }}>
          <InputLabel id="demo-simple-select-label">Estado</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={filterStatus}
            onChange={(e) => setFilterStatus(e.target.value)}
          >
            {statusTypes.map((tType) => (
              <MenuItem value={tType.value} key={tType.value}>
                {tType.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <LocalizationProvider dateAdapter={DateFnsUtils} locale={esLocale}>
          <DateRangePicker
            key={`${startDate}-${endDate}`}
            cancelText="Cancelar"
            displayStaticWrapperAs={"desktop"}
            animateYearScrolling
            calendars={2}
            // calendars={matches ? 2 : 1}
            showToolbar={false}
            autoOk={true}
            format={"dd-MM-yyyy"}
            value={tempDates}
            onChange={(dates) => {
              setTempDates(dates);
              tempDatesRef.current = dates;
            }}
            onClose={(dates) => handlePickerClose(dates)}
            renderInput={(startProps, endProps) => (
              <React.Fragment>
                <Grid container spacing={2} direction="row" alignItems="center">
                  <Grid item xs={6}>
                    <div className={classes.inputDiv}>
                      <TextField
                        ref={startProps.inputRef}
                        {...startProps.inputProps}
                        label="Rango de fechas"
                        value={
                          tempDates[0]
                            ? fmtDate(tempDates[0]) +
                              " / " +
                              (tempDates[1]
                                ? fmtDate(tempDates[1])
                                : "DD/MM/YYYY")
                            : undefined
                        }
                        variant="standard"
                        onChange={() => {}}
                        InputLabelProps={{
                          shrink: true,
                          // className: classes.floatingLabelFocusStyle,
                          htmlFor: "dateDeparture",
                        }}
                        //inputProps={classes.input}
                        InputProps={{
                          style: { width: "190px" },
                          endAdornment: (
                            <InputAdornment position="start">
                              <CalendarDays color="#555555" />
                            </InputAdornment>
                          ),
                        }}
                        // error={props.dateDepartureError}
                        // helperText={props.dateDepartureHelperText}
                        placeholder=""
                      />
                    </div>
                  </Grid>
                </Grid>
              </React.Fragment>
            )}
          />
        </LocalizationProvider>
        <Button
          style={{ minWidth: "50px" }}
          onClick={() => handleResetFilters()}
          variant="contained"
          color="primary"
        >
          <ListRestart />
        </Button>
        <Button
          style={{ minWidth: "50px" }}
          onClick={exportTransfers}
          variant="contained"
          color="primary"
        >
          <Download />
        </Button>
      </Grid>
      <div className={classes.container}>
        <Paper className={classes.root}>
          <TableContainer className={classes.containerTable}>
            <TransfersTable
              transactionType={transactionType}
              transfers={transfers}
              aproveTranfer={aproveTranfer}
              cancelTranfer={cancelTranfer}
            />
          </TableContainer>
          <TablePagination
            labelRowsPerPage="Elementos por pagina"
            rowsPerPageOptions={[10, 25, 50, 100]}
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      </div>
      <Toast
        message={toastMessage}
        open={toast}
        close={() => {
          getTransfersByType(page + 1, rowsPerPage);
          setToast(false);
        }}
      />
      <Spinner loading={loading} />
    </Container>
  );
}
