import { Grid, Typography } from "@material-ui/core";
import React from "react";

const PassengersSummary = (props) => {
  const passengerTypes = {
    ADT: "Adulto(a)",
    //CHD: "Niño",
    CNN: "Niño(a)",
    INF: "Infante",
  };

  return (
    <Grid container style={{ marginBottom: 20 }}>
      <Grid item xs={12} className={props.classes.box}>
        <Grid
          container
          justify="space-between"
          className={props.classes.padding2}
        >
          <Grid item xs={3}>
            <Typography className={props.classes.p3} color="secondary">
              Nombre
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography className={props.classes.p3} color="secondary">
              Apellido
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography className={props.classes.p3} color="secondary">
              Tipo
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography className={props.classes.p3} color="secondary">
              Documento
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className={props.classes.padding2}>
        {props.passengers.map((passenger, index) => (
          <Grid key={index} container justify="space-between">
            <Grid item xs={3}>
              <Typography className={props.classes.p3}>
                {passenger.name}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography className={props.classes.p3}>
                {passenger.lastName}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography className={props.classes.p3}>
                {passengerTypes[passenger.type]
                  ? passengerTypes[passenger.type]
                  : ""}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography className={props.classes.p3}>
                {passenger?.document?.documentID
                  ? `${passenger?.document?.docIssueCountry} ${passenger.document.documentID}`
                  : "N/A"}
              </Typography>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default PassengersSummary;
