import React, { useState } from "react";
import { Grid, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { FormValidator } from "../helpers/";
import { aviorPlusServices } from "../services";
import { ButtonSpinner, Toast, Spinner } from "../components/";

const LoginAviorPlus = (props) => {
  const classes = useStyles();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  //const [userData, setUserData] = useState(null);

  const [loading, setLoading] = useState(false);
  const [toast, setToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  const validator = new FormValidator([
    {
      field: "email",
      method: "isEmpty",
      validWhen: false,
      message: "Debe ingresar su email",
    },
    {
      field: "email",
      method: "isEmail",
      validWhen: true,
      message: "Ingrese un correo electrónico valido",
    },
    {
      field: "password",
      method: "isEmpty",
      validWhen: false,
      message: "Debe ingresar su password",
    },
  ]);

  const validateForms = async (e) => {
    e.preventDefault();

    const validation = validator.validate({
      email,
      password,
    });

    setEmailError(validation.email.message);
    setPasswordError(validation.password.message);

    if (validation.isValid) {
      setLoading(true);
      const { response, status } = await aviorPlusServices.login({
        username: email,
        password,
      });
      setLoading(false);

      if (status === 200) {
        if (response?.error) {
          setToast(true);
          setToastMessage(response.error.message);
        } else {
          props.setUserData(response);
        }
      } else if (status === 400) {
        setToast(true);
        setToastMessage(response.error.message);
      }
    }
  };

  return (
    <form onSubmit={validateForms}>
      <Grid container justify="center" alignItems="center" spacing={1}>
        <Grid item md={12} xs={12}>
          <Grid item md={12} xs={12}>
            <div className={classes.inputDiv}>
              <TextField
                value={email}
                label="Email"
                name="email"
                id="email"
                fullWidth
                helperText={emailError}
                InputLabelProps={{
                  shrink: true,
                  className: classes.floatingLabelFocusStyle,
                  htmlFor: "email",
                }}
                InputProps={{
                  classes: {
                    input: classes.inputLabel,
                    underline: classes.underline,
                  },
                }}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </Grid>
        </Grid>

        <Grid item md={12} xs={12}>
          <Grid item md={12} xs={12}>
            <div className={classes.inputDiv}>
              <TextField
                value={password}
                label="Password"
                name="password"
                id="password"
                type="password"
                fullWidth
                helperText={passwordError}
                InputLabelProps={{
                  shrink: true,
                  className: classes.floatingLabelFocusStyle,
                  htmlFor: "password",
                }}
                InputProps={{
                  classes: {
                    input: classes.inputLabel,
                    underline: classes.underline,
                  },
                }}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
          </Grid>
        </Grid>

        <Grid item md={12} xs={12}>
          <Grid item md={12} xs={12}>
            <Grid container justify="space-between" style={{ marginTop: 8 }}>
              <Grid item md={4} xs={4}>
                <ButtonSpinner
                  action={() => {
                    if (props.goBack) props.goBack();
                    else props.close();
                  }}
                  fullWidth
                  text={props.goBack ? "Atras" : "Cerrar"}
                />
              </Grid>

              <Grid item md={4} xs={4}>
                <ButtonSpinner type="submit" fullWidth text="Continuar" />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Toast
          message={toastMessage}
          open={toast}
          close={() => {
            setToast(false);
          }}
        />
        <Spinner loading={loading} />
      </Grid>
    </form>
  );
};

const useStyles = makeStyles((theme) => ({
  inputGrid: {
    marginBottom: "15px",
  },
  Grid: {
    display: "flex",
    justifyContent: "center",
  },
  floatingLabelFocusStyle: {
    color: "#CD171A",
    fontSize: "1.1rem",
    width: "max-content",
  },
  inputLabel: {
    fontSize: "0.9rem",
    paddingBottom: 0,
    color: "#555555!important",
    height: "20px",
  },
  underline: {
    "&::before": {
      display: "none",
    },
    "&::after": {
      display: "none",
    },
  },
  inputDiv: {
    borderWidth: "1pt",
    borderStyle: "solid",
    borderColor: "#cccccc",
    padding: "2px 5px",
    borderRadius: "5px",
    "& .MuiFormControl-root": {
      width: "100%",
    },
  },
  padding: {
    padding: "5px 10px",
  },
  box: {
    borderBottom: "solid 1px #cccccc",
    backgroundColor: "#EAEAEA",
    marginBottom: 5,
  },
  box2: {
    borderBottom: "solid 1px #CD171A",
    backgroundColor: "#CD171A",
    color: "#fff",
    marginBottom: 5,
  },
  span1: {
    fontWeight: "bold",
    fontSize: "1.21rem",
  },
  p3: {
    fontSize: "1.15rem",
    lineHeight: "1.5",
    color: "#848484",
  },
}));

export default LoginAviorPlus;
