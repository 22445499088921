import React, { useState, useEffect } from "react";
import {
  Spinner,
  Toast,
  //PriorityAssistant
} from "../components";
import { anciliariesServices, reservationServices } from "../services";
import {
  Grid,
  Checkbox,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  IconButton,
  Popover,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { FormatCurrency, getAncillaryGroup, sortAncillaries } from "../helpers";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ErrorIcon from "@material-ui/icons/Error";
const useStyles = makeStyles((theme) => ({
  fontBody: {
    color: "#555555",
    [theme.breakpoints.down("md")]: {
      fontSize: "0.9rem",
    },
  },
  fontPrice: {},
  fontTitle: {
    color: "#555555",
    fontWeight: 700,
  },
  icon: {
    width: "20px",
    marginRight: "20px",
    height: "30px",
  },
  [theme.breakpoints.down("md")]: {
    font: {
      fontSize: "1rem",
    },
  },
  [theme.breakpoints.down("md")]: {
    fontTitle: {
      fontWeight: 500,
      fontSize: "0.9rem",
    },
  },
}));
const PassengerAncillary = (props) => {
  const [checked, setChecked] = useState(false);
  const handleChange = (event) => {
    setChecked(event.target.checked);
    if (event.target.checked) {
      props.add();
    } else {
      props.remove();
    }
  };
  return (
    <Grid container justify="flex-start" direction="row">
      <Grid item md={1} xs={2}>
        <Checkbox color="primary" checked={checked} onChange={handleChange} />
      </Grid>
      <Grid item xs={10}>
        <Grid alignItems="center" container style={{ height: "100%" }}>
          <Typography color="primary">
            {props.name} {props.lastName}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};
const Ancillary = (props) => {
  const classes = useStyles();
  const [checked, setChecked] = useState(false);
  const [selected, setSelected] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const handleChange = (event) => {
    setChecked(event.target.checked);
    if (event.target.checked) {
      props.add("1");
    } else {
      props.remove("1");
    }
  };
  const addAncillariy = (ancillary) => {
    const selectedAux = [...selected];
    selectedAux.push(ancillary);
    setSelected([...selectedAux]);
  };
  const removeAncillariy = () => {
    const selectedAux = [...selected];
    selectedAux.splice(0, 1);
    setSelected([...selectedAux]);
  };
  const getAmount = () => {
    return parseFloat(
      selected.reduce(function (a, b) {
        return parseFloat(a) + parseFloat(b.price);
      }, 0)
    ).toFixed(2);
  };
  return (
    <>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <div style={{ padding: "20px", width: "350px" }}>
          <Typography
            color="primary"
            style={{ fontWeight: "bold" }}
            dangerouslySetInnerHTML={{ __html: props.description }}
          />
        </div>
      </Popover>
      {props.isSinglePassenger ? (
        <Grid container justify="flex-start" direction="row">
          <Grid item md={1} xs={2}>
            <Checkbox
              color="primary"
              checked={checked}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={8}>
            <Grid alignItems="center" container style={{ height: "100%" }}>
              <Typography className={classes.fontBody}>{props.name}</Typography>
              {props.description !== "" && (
                <div style={{ marginLeft: "10px" }}>
                  {" "}
                  <IconButton onClick={handleClick}>
                    <ErrorIcon />
                  </IconButton>
                </div>
              )}
            </Grid>
          </Grid>
          <Grid item xs={2}>
            <Grid alignItems="center" container style={{ height: "100%" }}>
              <Typography color="primary">
                ${FormatCurrency(`${parseFloat(props.total).toFixed(2)}`)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid
          container
          justify="flex-start"
          direction="row"
          style={{ marginBottom: "10px" }}
        >
          <Grid item xs={12}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Grid container justify="flex-start" direction="row">
                  <Grid item xs={10} md={9}>
                    <Grid
                      alignItems="center"
                      container
                      style={{ height: "100%" }}
                    >
                      <Typography className={classes.fontBody}>
                        {props.name}
                      </Typography>
                      {props.description !== "" && (
                        <div style={{ marginLeft: "10px" }}>
                          {" "}
                          <IconButton onClick={handleClick}>
                            <ErrorIcon />
                          </IconButton>
                        </div>
                      )}
                    </Grid>
                  </Grid>
                  <Grid item xs={2}>
                    <Grid
                      justify="flex-end"
                      alignItems="center"
                      container
                      style={{ height: "100%" }}
                    >
                      <Typography color="primary" display="inline">
                        $
                        {FormatCurrency(
                          `${parseFloat(props.total).toFixed(2)}`
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container>
                  <Grid container>
                    {props.passengers.map((passenger, index) => (
                      <PassengerAncillary
                        {...passenger}
                        key={index}
                        add={() => {
                          addAncillariy({ price: props.total });
                          props.add(passenger.id);
                        }}
                        remove={() => {
                          removeAncillariy();
                          props.remove(passenger.id);
                        }}
                      />
                    ))}
                  </Grid>
                  {selected.length > 0 && (
                    <Grid container justify="flex-start" direction="row">
                      <Grid item xs={11}>
                        <Grid
                          justify="flex-end"
                          alignItems="center"
                          container
                          style={{ height: "100%" }}
                        >
                          <Typography
                            color="primary"
                            display="inline"
                            style={{ marginRight: "10px" }}
                          >
                            Total
                          </Typography>
                          <Typography color="primary" display="inline">
                            ${getAmount()}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      )}
    </>
  );
};

const AncillaryAccordion = (props) => {
  const classes = useStyles();
  const [selected, setSelected] = useState([]);
  const getAmount = () => {
    return parseFloat(
      selected.reduce(function (a, b) {
        return parseFloat(a) + parseFloat(b.total);
      }, 0)
    ).toFixed(2);
  };
  const addAncillary = (ancillary) => {
    const selectedAux = [...selected];
    selectedAux.push(ancillary);
    setSelected([...selectedAux]);
  };
  const removeAncillary = (ancillary) => {
    const selectedAux = [...selected];
    const index = selectedAux.findIndex((elem) => elem.code === ancillary.code);
    selectedAux.splice(index, 1);
    setSelected([...selectedAux]);
  };
  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Grid container justify="flex-start" direction="row">
          <Grid item xs={10} md={9}>
            <Grid
              justify="flex-start"
              alignItems="center"
              container
              style={{ height: "100%" }}
            >
              <img
                src={`./img/${props.ancillary.key}.svg`}
                alt=""
                className={classes.icon}
              />
              <Typography className={classes.fontTitle}>
                {props.ancillary.name}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={2}>
            <Grid
              justify="flex-end"
              alignItems="center"
              container
              style={{ height: "100%" }}
            >
              <Typography color="primary" display="inline">
                ${FormatCurrency(`${parseFloat(getAmount()).toFixed(2)}`)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container>
          {sortAncillaries(
            props.ancillary.group,
            props.ancillary.ancillaries
          ).map((ancillary, index) => (
            <Ancillary
              isSinglePassenger={props.passengers.length < 2}
              {...ancillary}
              passengers={props.passengers}
              key={index}
              add={(travelerRPH) => {
                addAncillary(ancillary);
                props.addAncillary(travelerRPH, ancillary);
              }}
              remove={(travelerRPH) => {
                removeAncillary(ancillary);
                props.removeAncillary(travelerRPH, ancillary);
              }}
              //addAncillariyToPassenger={(index)=>addAncillariyToPassenger(index,ancillary,"going")}
              //removeAncillariyToPassenger={(index)=>removeAncillariyToPassenger(index,ancillary,"going")}
            />
          ))}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};
export default function AncillariesStep(props) {
  const classes = useStyles();
  const [ancillaries, setAncillaries] = useState({
    going: [],
    goBack: [],
  });
  const [toast, setToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const totalFare = parseFloat(
    parseFloat(props.originFlight ? props.originFlight.fare.totalAmount : 0) +
      parseFloat(props.returnFlight ? props.returnFlight.fare.totalAmount : 0)
  ).toFixed(2);
  useEffect(() => {
    props.setSelectedAncillaries({
      going: [],
      goBack: [],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    getAncillaries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const buildGroupAncillaries = async (ancillariesResult) => {
    const auxAncillaries = {
      going: {
        BAGGAGES: {
          group: "BAGGAGES",
          name: "MALETAS",
          ancillaries: [],
        },
        PETS: {
          group: "PETS",
          name: "MASCOTAS",
          ancillaries: [],
        },
        SPORTS_ARTICLES: {
          group: "SPORTS_ARTICLES",
          name: "ARTICULOS DEPORTIVOS",
          ancillaries: [],
        },
        UNACCOMPANIED_MINOR: {
          group: "UNACCOMPANIED_MINOR",
          name: "MENOR SIN ACOMPAÑANTE",
          ancillaries: [],
        },
        OTHERS: {
          group: "OTHERS",
          name: "EXTRAS",
          ancillaries: [],
        },
        SEAT_SELECTION: {
          group: "SEAT_SELECTION",
          name: "SELECCIÓN DE ASIENTO",
          ancillaries: [],
        },
      },
      goBack: {
        BAGGAGES: {
          group: "BAGGAGES",
          name: "MALETAS",
          ancillaries: [],
        },
        PETS: {
          group: "PETS",
          name: "MASCOTAS",
          ancillaries: [],
        },
        SPORTS_ARTICLES: {
          group: "SPORTS_ARTICLES",
          name: "ARTICULOS DEPORTIVOS",
          ancillaries: [],
        },
        UNACCOMPANIED_MINOR: {
          group: "UNACCOMPANIED_MINOR",
          name: "MENOR SIN ACOMPAÑANTE",
          ancillaries: [],
        },
        OTHERS: {
          group: "OTHERS",
          name: "EXTRAS",
          ancillaries: [],
        },
        SEAT_SELECTION: {
          group: "SEAT_SELECTION",
          name: "SELECCIÓN DE ASIENTO",
          ancillaries: [],
        },
      },
    };
    ancillariesResult.going.forEach((ancillary) => {
      const type = getAncillaryGroup(ancillary.code);
      const index = auxAncillaries.going[type].ancillaries.findIndex(
        (elem) => elem.code === ancillary.code
      );
      if (index === -1) {
        auxAncillaries.going[type].ancillaries.push({ ...ancillary });
      }
    });
    if (ancillariesResult.goBack.length > 0) {
      ancillariesResult.goBack.forEach((ancillary) => {
        const type = getAncillaryGroup(ancillary.code);
        const index = auxAncillaries.goBack[type].ancillaries.findIndex(
          (elem) => elem.code === ancillary.code
        );
        if (index === -1) {
          auxAncillaries.goBack[type].ancillaries.push({ ...ancillary });
        }
      });
    }
    const ancillariesArray = {
      going: [],
      goBack: [],
    };
    Object.entries(auxAncillaries.going).forEach(([key, value]) =>
      ancillariesArray.going.push({ key, ...value })
    );
    Object.entries(auxAncillaries.goBack).forEach(([key, value]) =>
      ancillariesArray.goBack.push({ key, ...value })
    );

    setAncillaries({
      going: ancillariesArray.going.filter(
        (ancillary) => ancillary.key !== "SEAT_SELECTION"
      ),
      goBack: ancillariesArray.goBack.filter(
        (ancillary) => ancillary.key !== "SEAT_SELECTION"
      ),
    });
  };
  const getAncillaries = async () => {
    let going = [
      {
        cabinType: props.originFlight.fare.classCode,
        flightNumber: props.originFlight.segments[0].flightNumber,
        departureInfo: {
          dateTime: props.originFlight.segments[0].departureInfo.dateTime,
          locationCode:
            props.originFlight.segments[0].departureInfo.locationCode,
          cityName: props.originFlight.segments[0].departureInfo.cityName,
          airPortNam: props.originFlight.segments[0].departureInfo.airPortName,
        },
        arrivalInfo: {
          dateTime:
            props.originFlight.segments[props.originFlight.segments.length - 1]
              .arrivalInfo.dateTime,
          locationCode:
            props.originFlight.segments[props.originFlight.segments.length - 1]
              .arrivalInfo.locationCode,
          cityName:
            props.originFlight.segments[props.originFlight.segments.length - 1]
              .arrivalInfo.cityName,
          airPortNam:
            props.originFlight.segments[props.originFlight.segments.length - 1]
              .arrivalInfo.airPortName,
        },
      },
    ];
    let segments = { going: going };
    if (props.isRoundTrip) {
      let goBack = [
        {
          cabinType: props.returnFlight.fare.classCode,
          flightNumber: props.returnFlight.segments[0].flightNumber,
          departureInfo: {
            dateTime: props.returnFlight.segments[0].departureInfo.dateTime,
            locationCode:
              props.returnFlight.segments[0].departureInfo.locationCode,
            cityName: props.returnFlight.segments[0].departureInfo.cityName,
            airPortNam:
              props.returnFlight.segments[0].departureInfo.airPortName,
          },
          arrivalInfo: {
            dateTime:
              props.returnFlight.segments[
                props.returnFlight.segments.length - 1
              ].arrivalInfo.dateTime,
            locationCode:
              props.returnFlight.segments[
                props.returnFlight.segments.length - 1
              ].arrivalInfo.locationCode,
            cityName:
              props.returnFlight.segments[
                props.returnFlight.segments.length - 1
              ].arrivalInfo.cityName,
            airPortName:
              props.returnFlight.segments[
                props.returnFlight.segments.length - 1
              ].arrivalInfo.airPortName,
          },
        },
      ];
      segments.goBack = goBack;
    } else {
      segments.goBack = [];
    }
    setLoading(true);
    const { response, status } = await anciliariesServices.getAncilliaries(
      segments
    );
    setLoading(false);
    if (status === 200) {
      buildGroupAncillaries(response);
    }
  };
  const addAncillary = (ancillary, type) => {
    const ancillariesAux = [...props.selectedAncillaries[type]];
    ancillariesAux.push(ancillary);
    props.setSelectedAncillaries({
      ...props.selectedAncillaries,
      [type]: ancillariesAux,
    });
  };
  const removeAncillary = (ancillary, type) => {
    const ancillariesAux = [...props.selectedAncillaries[type]];
    const index = ancillariesAux.findIndex(
      (elem) =>
        elem.code === ancillary.code &&
        elem.travelerRPH === ancillary.travelerRPH
    );
    ancillariesAux.splice(index, 1);
    props.setSelectedAncillaries({
      ...props.selectedAncillaries,
      [type]: ancillariesAux,
    });
  };
  const getAmount = () => {
    return parseFloat(
      props.selectedAncillaries.going.reduce(function (a, b) {
        return parseFloat(a) + parseFloat(b.total);
      }, 0) +
        props.selectedAncillaries.goBack.reduce(function (a, b) {
          return parseFloat(a) + parseFloat(b.total);
        }, 0)
    ).toFixed(2);
  };
  const handleSubmit = async () => {
    let passengers = props.passengers;
    for (let passenger of passengers) delete passenger.id;

    let going = props.originFlight.segments.map((segment) => ({
      cabinType: props.originFlight.fare.classCode,
      flightNumber: segment.flightNumber,
      departureInfo: {
        dateTime: segment.departureInfo.dateTime,
        locationCode: segment.departureInfo.locationCode,
        cityName: segment.departureInfo.cityName,
        airPortNam: segment.departureInfo.airPortName,
      },
      arrivalInfo: {
        dateTime: segment.arrivalInfo.dateTime,
        locationCode: segment.arrivalInfo.locationCode,
        cityName: segment.arrivalInfo.cityName,
        airPortName: segment.arrivalInfo.airPortName,
      },
    }));

    let segments = { going: going, passengers: passengers };
    if (props.isRoundTrip) {
      let goBack = props.returnFlight.segments.map((segment) => ({
        cabinType: props.returnFlight.fare.classCode,
        flightNumber: segment.flightNumber,
        departureInfo: {
          dateTime: segment.departureInfo.dateTime,
          locationCode: segment.departureInfo.locationCode,
          cityName: segment.departureInfo.cityName,
          airPortNam: segment.departureInfo.airPortName,
        },
        arrivalInfo: {
          dateTime: segment.arrivalInfo.dateTime,
          locationCode: segment.arrivalInfo.locationCode,
          cityName: segment.arrivalInfo.cityName,
          airPortName: segment.arrivalInfo.airPortName,
        },
      }));
      segments.goBack = goBack;
    } else {
      segments.goBack = [];
    }

    let ancillaries = {
      going: [],
      goBack: [],
    };
    ancillaries.going.forEach((ancillary) => {
      for (let i = 0; i < segments.going.length; i++) {
        ancillaries.going.push({
          ...ancillary,
          segmentRPH: `${i + 1}`,
        });
      }
    });
    const segmentRPH = segments.goBack.length;
    ancillaries.goBack.forEach((ancillary) => {
      for (let i = 0; i < segments.goBack.length; i++) {
        ancillaries.goBack.push({
          ...ancillary,
          segmentRPH: `${segmentRPH + i + 1}`,
        });
      }
    });

    const contact = props.contactInfo;

    setLoading(true);
    const peticion = await reservationServices.createReservation(
      segments,
      props.currency,
      props.searchParams.tourCode && props.searchParams.tourCode !== ""
        ? props.searchParams.tourCode
        : "",
      false,
      props.selectedAncillaries,
      contact
    );
    setLoading(false);
    const resp = await peticion.resp;
    if (peticion.status === 200) {
      props.sendData(resp.pnr);
    } else {
      setToast(true);
      setToastMessage(resp.error.message);
    }
  };
  return (
    <div style={{ padding: "0px 10px" }}>
      <Toast
        message={toastMessage}
        open={toast}
        close={() => {
          setToast(false);
        }}
      />
      <Spinner loading={loading} />
      <Grid container justify="center" direction="row" spacing={2}>
        <Grid item xs={12}>
          <Typography className={classes.fontTitle}>
            ADICIONALES VUELO DE IDA
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {ancillaries.going.map((ancillary, index) => (
            <AncillaryAccordion
              ancillary={ancillary}
              key={index}
              addAncillary={(travelerRPH, elem) =>
                addAncillary({ ...elem, travelerRPH }, "going")
              }
              removeAncillary={(travelerRPH, elem) =>
                removeAncillary({ ...elem, travelerRPH }, "going")
              }
              {...props}
            />
          ))}
        </Grid>
        {props.isRoundTrip && (
          <>
            <Grid item xs={12} style={{ marginTop: "20px" }}>
              <Typography className={classes.fontTitle}>
                ADICIONALES VUELO DE VUELTA
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {ancillaries.goBack.map((ancillary, index) => (
                <AncillaryAccordion
                  ancillary={ancillary}
                  key={index}
                  addAncillary={(travelerRPH, elem) =>
                    addAncillary({ ...elem, travelerRPH }, "goBack")
                  }
                  removeAncillary={(travelerRPH, elem) =>
                    removeAncillary({ ...elem, travelerRPH }, "goBack")
                  }
                  {...props}
                />
              ))}
            </Grid>
          </>
        )}
      </Grid>
      <Grid
        container
        justify="flex-end"
        direction="row"
        style={{ margin: "20px 0px" }}
      >
        <Grid item md={8} xs={12} className={classes.xsMb}>
          <Grid container>
            {!matches && (
              <Grid item md={2}>
                <Grid alignItems="center" container style={{ height: "100%" }}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={props.goBack}
                  >
                    Atras
                  </Button>
                </Grid>
              </Grid>
            )}
            <Grid item md={5} xs={12}>
              <Grid alignItems="center" container style={{ height: "100%" }}>
                <Typography
                  className={classes.font}
                  variant="h6"
                  display="inline"
                  color="textSecondary"
                  style={{ marginRight: "10px" }}
                >
                  Total tarifa{" "}
                </Typography>
                <Typography
                  className={classes.font}
                  variant="h6"
                  display="inline"
                  color="primary"
                >
                  ${FormatCurrency(`${totalFare}`)}
                </Typography>
              </Grid>
            </Grid>
            <Grid item md={5} xs={12}>
              <Grid alignItems="center" container style={{ height: "100%" }}>
                <Typography
                  className={classes.font}
                  variant="h6"
                  display="inline"
                  color="textSecondary"
                  style={{ marginRight: "10px" }}
                >
                  Total adicionales{" "}
                </Typography>
                <Typography
                  className={classes.font}
                  variant="h6"
                  display="inline"
                  color="primary"
                >
                  ${FormatCurrency(`${parseFloat(getAmount()).toFixed(2)}`)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={4} xs={12}>
          <Grid container>
            <Grid item md={8} xs={12}>
              <Grid alignItems="center" container style={{ height: "100%" }}>
                <Typography
                  className={classes.font}
                  variant="h6"
                  display="inline"
                  color="textSecondary"
                  style={{ marginRight: "10px" }}
                >
                  Monto Total{" "}
                </Typography>
                <Typography
                  className={classes.font}
                  variant="h6"
                  display="inline"
                  color="primary"
                >
                  $
                  {FormatCurrency(
                    `${parseFloat(
                      parseFloat(totalFare) + parseFloat(getAmount())
                    ).toFixed(2)}`
                  )}
                </Typography>
              </Grid>
            </Grid>
            {!matches && (
              <Grid item md={4} xs={12}>
                <Grid alignItems="center" container style={{ height: "100%" }}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                  >
                    Siguiente
                  </Button>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
        {matches && (
          <Grid item xs={12} style={{ margin: "20px 0px" }}>
            <Grid container justify="space-between">
              <Grid item xs={6}>
                <Grid justify="flex-start" container>
                  <Button variant="contained" color="primary">
                    Atras
                  </Button>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid justify="flex-end" container>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                  >
                    Siguiente
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </div>
  );
}
