import React, { useState, useEffect } from "react";
import { Grid, Checkbox, FormControlLabel } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Title, Spinner } from ".";
//import { paymentMethods } from "../helpers"
import { gatewaysServices } from "../services";
//import { reservationServices } from "../services"
const useStyles = makeStyles((theme) => ({
  grid: {
    padding: "15px 15px 40px 15px",
  },
  buttons: {
    display: "flex",
    justifyContent: "center",
    marginTop: "15px",
  },
  check: {
    width: "95%",
    display: "flex",
    flexDirection: "column",
  },
  check2: {
    width: "95%",
    display: "flex",
    flexDirection: "column",
  },
  imgContainer: {
    display: "block",
    minHeight: "60px",
  },
  imgBss: {
    width: "90px",
  },
  imgBssBanesco: {
    width: "120px",
  },
  formControl: {
    display: "block",
  },
  [theme.breakpoints.down("md")]: {
    imgContainer: {
      display: "flex",
      alignItems: "center",
    },
    img: {
      width: "100%",
    },
    imgPaypal: {
      width: "75%",
    },
    formControl: {
      display: "flex",
      justifyContent: "flex-start",
    },
    check: {
      flexDirection: "row",
    },
    check2: {
      flexDirection: "row",
    },
    imgBss: {
      width: "70px",
    },
  },
  [theme.breakpoints.up("md")]: {
    check2: {
      width: "100%",
      borderLeft: "1pt solid #cccccc",
    },
    imgPaypal: {
      width: "25%",
    },
    imgBss: {
      width: "70px",
    },
  },
}));

export default function PaymentMethods(props) {
  const classes = useStyles();
  const [methodSelect, setMethodSelect] = useState(null);
  const [loading, setLoading] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [columns, setColumns] = useState(4);
  const getImage = (value) => {
    if (value === "zelle" || value === "luka_zelle") {
      return classes.imgPaypal;
    } else if (
      value === "BSS" ||
      value === "luka_pm" ||
      value === "bapm" ||
      value === "btpm"
    ) {
      return classes.imgBss;
    } else if (value === "luka_banesco") {
      return classes.imgBssBanesco;
    } else {
      return classes.img;
    }
  };
  const getGateways = async () => {
    setLoading(true);
    const { response } = await gatewaysServices.getGateways("booking");
    if (response.length === 4) {
      setColumns(3);
    }

    setPaymentMethods(response);
    setLoading(false);
  };
  useEffect(() => {
    getGateways();
  }, []);
  return (
    <Grid
      container
      justify="flex-start"
      direction="row"
      className={classes.grid}
    >
      <Spinner loading={loading} />
      <Title h2={"SELECCIONA TU "} strong={"MEDIO DE PAGO:"} />
      <div style={{ marginTop: "10px", width: "100%" }}></div>
      <Grid container>
        {paymentMethods.map((method, index) => (
          <Grid item md={columns} xs={12} key={index}>
            <Grid
              container
              direction="column"
              className={classes[method.style]}
            >
              <Grid item md={12} xs={8} className={classes.formControl}>
                <FormControlLabel
                  value={""}
                  name={method.value}
                  control={<Checkbox color="primary" />}
                  checked={methodSelect === method.value}
                  onChange={(e) => {
                    props.selectMethod(method.value);
                    setMethodSelect(method.value);
                  }}
                  label={
                    <Grid
                      container
                      justify="center"
                      direction="row"
                      //style={{ height: "120px" }}
                    >
                      <Grid item xs={12}>
                        <p className={props.classes.p3} style={{ margin: 0 }}>
                          {method.name}
                        </p>
                      </Grid>
                    </Grid>
                  }
                  labelPlacement="end"
                  style={{ display: "flex", justifyContent: "center" }}
                />
              </Grid>

              <Grid item md={12} xs={4} className={classes.imgContainer}>
                <Grid
                  container
                  justify="center"
                  direction="row"
                  alignItems="center"
                >
                  <img
                    src={method.img}
                    alt=""
                    className={getImage(method.value)}
                    style={{ maxWidth: 160 }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
}
