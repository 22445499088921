import React, { useState } from "react";
import {
  getTime,
  formatHoursMinutes,
  flightStops,
  getFullDateName,
  //getEqp
} from "../helpers";
import { Typography, Grid, Popover, Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ErrorIcon from "@material-ui/icons/Error";

const useStyles = makeStyles((theme) => ({
  font: {
    fontSize: "1.2em",
    lineHeight: "1.1",
    fontWeight: "bold",
  },
  line: {
    height: "1px",
    background: "#CD171A",
  },
  text: {
    fontSize: "0.8rem",
  },
  padding: {
    paddingTop: "5px",
    paddingBottom: "5px",
  },
  textSecondary: {
    fontSize: "0.9rem",
  },
}));
export default function OriginDestination(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <Grid container alignItems="center" justify="center" direction="row">
      <Grid item xs={12}>
        <Grid
          container
          alignItems="center"
          justify="space-between"
          direction="row"
        >
          <Grid item xs={2}>
            <Typography
              display="block"
              align="center"
              variant="caption"
              color="textSecondary"
              className={classes.text}
            >
              Vuelo
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography
              display="block"
              align="left"
              variant="caption"
              color="textSecondary"
              className={classes.text}
            >
              Hora de Salida
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography
              display="block"
              variant="caption"
              color="textSecondary"
              className={classes.text}
            >
              Duración
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography
              display="block"
              align="center"
              variant="caption"
              color="textSecondary"
              className={classes.text}
            >
              Hora de Llegada
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid
          container
          alignItems="center"
          justify="center"
          direction="row"
          style={{
            border: "1px solid #E6E6E6",
            paddingBottom: "5px",
            paddingTop: "5px",
          }}
        >
          <Grid item xs={2} style={{ borderRight: "1px solid #E6E6E6" }}>
            <Typography
              display="block"
              align="center"
              variant="caption"
              color="textSecondary"
              className={classes.text}
              style={{
                cursor: "pointer",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={handleClick}
            >
              {props.segments[0].flightNumber} <ErrorIcon />
            </Typography>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "center",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <div style={{ padding: "20px", width: "350px" }}>
                {props.segments.map((segment, index) => (
                  <div style={{ width: "100%" }} key={index}>
                    {segment.timeConection !== "00:00" ? (
                      <>
                        <Grid
                          container
                          alignItems="center"
                          justify="center"
                          direction="row"
                          className={classes.padding}
                        >
                          <Grid item xs={3}>
                            <Typography
                              color="primary"
                              style={{ fontWeight: "bold" }}
                            >
                              Conexión
                            </Typography>
                          </Grid>
                          <Grid item xs={9}>
                            <Typography
                              display="block"
                              align="left"
                              variant="caption"
                              color="textSecondary"
                              className={classes.textSecondary}
                            >
                              {formatHoursMinutes(segment.timeConection, true)}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Divider style={{ width: "100%" }} />
                      </>
                    ) : null}
                    {props.segments.length > 1 ? (
                      <>
                        <Grid
                          container
                          alignItems="center"
                          justify="center"
                          direction="row"
                          className={classes.padding}
                        >
                          <Grid item xs={12}>
                            <Typography
                              color="primary"
                              style={{ fontWeight: "bold" }}
                            >
                              Segmento {index + 1}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Divider style={{ width: "100%" }} />
                      </>
                    ) : null}
                    <Grid
                      container
                      alignItems="center"
                      justify="center"
                      direction="row"
                      className={classes.padding}
                    >
                      <Grid item xs={3}>
                        <Typography
                          color="primary"
                          style={{ fontWeight: "bold" }}
                        >
                          Vuelo
                        </Typography>
                      </Grid>
                      <Grid item xs={9}>
                        <Typography
                          display="block"
                          align="left"
                          variant="caption"
                          color="textSecondary"
                          className={classes.textSecondary}
                        >
                          {segment.flightNumber}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Divider style={{ width: "100%" }} />
                    <Grid
                      container
                      alignItems="center"
                      justify="center"
                      direction="row"
                      className={classes.padding}
                    >
                      <Grid item xs={3}>
                        <Typography
                          color="primary"
                          style={{ fontWeight: "bold" }}
                        >
                          Origen
                        </Typography>
                      </Grid>
                      <Grid item xs={9}>
                        <Typography
                          display="block"
                          align="left"
                          variant="caption"
                          color="textSecondary"
                          className={classes.textSecondary}
                        >
                          {segment.departureInfo.cityName}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Divider style={{ width: "100%" }} />
                    <Grid
                      container
                      alignItems="center"
                      justify="center"
                      direction="row"
                      className={classes.padding}
                    >
                      <Grid item xs={3}>
                        <Typography
                          color="primary"
                          style={{ fontWeight: "bold" }}
                        >
                          Destino
                        </Typography>
                      </Grid>
                      <Grid item xs={9}>
                        <Typography
                          display="block"
                          align="left"
                          variant="caption"
                          color="textSecondary"
                          className={classes.textSecondary}
                        >
                          {segment.arrivalInfo.cityName}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Divider style={{ width: "100%" }} />
                    <Grid
                      container
                      alignItems="center"
                      justify="center"
                      direction="row"
                      className={classes.padding}
                    >
                      <Grid item xs={3}>
                        <Typography
                          color="primary"
                          style={{ fontWeight: "bold" }}
                        >
                          Salida
                        </Typography>
                      </Grid>
                      <Grid item xs={9}>
                        <Typography
                          display="block"
                          align="left"
                          variant="caption"
                          color="textSecondary"
                          className={classes.textSecondary}
                        >
                          {getTime(segment.departureInfo.dateTime)}{" "}
                          {getFullDateName(segment.departureInfo.dateTime)}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Divider style={{ width: "100%" }} />
                    <Grid
                      container
                      alignItems="center"
                      justify="center"
                      direction="row"
                      className={classes.padding}
                    >
                      <Grid item xs={3}>
                        <Typography
                          color="primary"
                          style={{ fontWeight: "bold" }}
                        >
                          Llegada
                        </Typography>
                      </Grid>
                      <Grid item xs={9}>
                        <Typography
                          display="block"
                          align="left"
                          variant="caption"
                          color="textSecondary"
                          className={classes.textSecondary}
                        >
                          {getTime(segment.arrivalInfo.dateTime)}{" "}
                          {getFullDateName(segment.arrivalInfo.dateTime)}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Divider style={{ width: "100%" }} />
                    {/*<Grid
                      container
                      alignItems="center"
                      justify="center"
                      direction="row"
                      className={classes.padding}
                    >
                      <Grid item xs={3}>
                        <Typography
                          color="primary"
                          style={{ fontWeight: "bold" }}
                        >
                          Avión
                        </Typography>
                      </Grid>
                      <Grid item xs={9}>
                        <Typography
                          display="block"
                          align="left"
                          variant="caption"
                          color="textSecondary"
                          className={classes.textSecondary}
                        >
                          {getEqp(segment.eqp)}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Divider style={{ width: "100%" }} />*/}
                    <Grid
                      container
                      alignItems="center"
                      justify="center"
                      direction="row"
                      className={classes.padding}
                    >
                      <Grid item xs={3}>
                        <Typography
                          color="primary"
                          style={{ fontWeight: "bold" }}
                        >
                          Duración
                        </Typography>
                      </Grid>
                      <Grid item xs={9}>
                        <Typography
                          display="block"
                          align="left"
                          variant="caption"
                          color="textSecondary"
                          className={classes.textSecondary}
                        >
                          {formatHoursMinutes(segment.timeFlight, true)}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Divider
                      style={{
                        width: "100%",
                        background: "rgba(0, 0, 0, 0.5)",
                      }}
                    />
                  </div>
                ))}
              </div>
            </Popover>
          </Grid>
          <Grid item xs={3}>
            <Typography
              display="block"
              align="center"
              variant="caption"
              color="textSecondary"
              className={classes.font}
            >
              {getTime(props.segments[0].departureInfo.dateTime)}
            </Typography>
            <Typography
              display="block"
              align="center"
              variant="caption"
              color="primary"
              className={classes.font}
            >
              {props.segments[0].departureInfo.locationCode}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <div className={classes.line}></div>
          </Grid>
          <Grid item xs={2}>
            <Typography
              display="block"
              align="center"
              variant="caption"
              color="textSecondary"
              className={classes.font}
            >
              {flightStops(props.segments)}
            </Typography>
            <Typography
              display="block"
              align="center"
              variant="caption"
              color="primary"
              className={classes.font}
            >
              {formatHoursMinutes(props.totalTimeFlight)}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Grid
              container
              alignItems="center"
              justify="center"
              direction="row"
            >
              <div className={classes.line} style={{ width: "75%" }}></div>
            </Grid>
          </Grid>
          <Grid item xs={3}>
            <Typography
              display="block"
              align="center"
              variant="caption"
              color="textSecondary"
              className={classes.font}
            >
              {getTime(
                props.segments[props.segments.length - 1].arrivalInfo.dateTime
              )}
            </Typography>
            <Typography
              display="block"
              align="center"
              variant="caption"
              color="primary"
              className={classes.font}
            >
              {
                props.segments[props.segments.length - 1].arrivalInfo
                  .locationCode
              }
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
