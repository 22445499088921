import React, { useState, useEffect } from "react";
import { Typography, Popover, Grid, Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  getShortDateName,
  getTime,
  passengersCode,
  sumArrayObjects,
  FormatCurrency,
  findTaxAmount,
} from "../helpers";
const useStyles = makeStyles((theme) => ({
  details: {
    cursor: "pointer",
    textDecoration: "underline",
    fontSize: "0.8rem",
  },
  paymentMethod: {
    fontSize: "0.9rem",
  },
  color: {
    background: "#CD171A",
    padding: "10px 20px",
  },
  colorGray: {
    background: "#EAEAEA",
    padding: "10px 20px",
  },
  padding: {
    padding: "10px 20px",
  },
  paddingTopBottom: {
    paddingTop: "5px",
    paddingBottom: "5px",
  },
  paypalImage: {
    width: "65%",
  },
  [theme.breakpoints.down("md")]: {
    popover: {
      width: "350px",
    },
    fontMain: {
      fontSize: "0.9rem !important",
      fontWeight: "bold",
    },
    paymentImage: {
      width: "80%",
    },
    root: {
      marginTop: "10px",
    },
    totalAmount: {
      fontSize: "0.9rem",
      lineHeight: "1",
      color: "#000",
    },
    cost: {
      fontSize: "0.8rem",
    },
    details: {
      color: "#CE2827",
    },
  },
  [theme.breakpoints.up("md")]: {
    popover: {
      width: "500px",
    },
    paymentImage: {
      width: "50%",
    },
    root: {
      marginTop: "10px",
      marginBottom: "10px",
    },
    totalAmount: {
      marginBottom: "15px",
      lineHeight: "1",
      fontSize: "1.5rem",
      color: "#fff",
    },
    fontMain: {
      fontWeight: "bold",
      fontSize: "1rem !important",
    },
    details: {
      color: "#fff",
    },
  },
  textSecondary: {
    fontSize: "0.85rem",
  },
}));

function SegmentDetils(props) {
  const classes = useStyles();
  return (
    <Grid
      container
      justify="center"
      direction="row"
      className={classes.padding}
    >
      <Grid item xs={5}>
        <Typography className={classes.fontMain} color="textSecondary">
          {props.label}:{" "}
          {getShortDateName(props.segments[0].departureInfo.dateTime, true)}
        </Typography>
        <Typography
          className={classes.fontMain}
          color="primary"
          display="inline"
        >
          {props.segments[0].departureInfo.cityName}{" "}
        </Typography>{" "}
        <Typography
          className={classes.fontMain}
          color="textSecondary"
          display="inline"
        >
          ({props.segments[0].departureInfo.locationCode})
        </Typography>
        <Typography className={classes.fontMain} color="textSecondary">
          {getTime(props.segments[0].departureInfo.dateTime)}
        </Typography>
        <Typography
          className={classes.textSecondary}
          color="textSecondary"
          variant="caption"
        >
          {props.searchParams.countAdults} Adulto(s){" "}
        </Typography>
        {props.searchParams.countChildrens ? (
          <Typography
            className={classes.textSecondary}
            color="textSecondary"
            variant="caption"
          >
            {props.searchParams.countChildrens} Niño(s){" "}
          </Typography>
        ) : null}
        {props.searchParams.countBabies ? (
          <Typography
            className={classes.textSecondary}
            color="textSecondary"
            variant="caption"
          >
            {props.searchParams.countBabies} Infantes(s)
          </Typography>
        ) : null}
      </Grid>
      <Grid
        item
        xs={2}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div>
          <img
            src="./img/flecha-fechasflexibles2.png"
            alt=""
            style={{ width: "50%" }}
          />
        </div>
      </Grid>
      <Grid item xs={5}>
        <Typography className={classes.fontMain} color="primary">
          Tarifa{" "}
          {props.fare.className === "BUSINESS"
            ? "EJECUTIVA"
            : props.fare.className}
        </Typography>
        <Typography
          className={classes.fontMain}
          color="primary"
          display="inline"
        >
          {props.segments[props.segments.length - 1].arrivalInfo.cityName}{" "}
        </Typography>{" "}
        <Typography
          className={classes.fontMain}
          color="textSecondary"
          display="inline"
        >
          ({props.segments[props.segments.length - 1].arrivalInfo.locationCode})
        </Typography>
        <Typography className={classes.fontMain} color="textSecondary">
          {getTime(
            props.segments[props.segments.length - 1].arrivalInfo.dateTime
          )}
        </Typography>
        <Typography color="textSecondary"> </Typography>
      </Grid>
    </Grid>
  );
}
function PaymentsOptions() {
  const classes = useStyles();
  return (
    <Grid
      container
      justify="flex-start"
      direction="row"
      alignItems="center"
      className={classes.padding}
    >
      <Typography className={classes.fontMain} color="primary">
        METODOS DE PAGO
      </Typography>
      <Grid container justify="flex-start" direction="row" alignItems="center">
        <Grid item xs={3}>
          <img
            src="./img/logo-visa.png"
            alt=""
            className={classes.paymentImage}
          />
        </Grid>
        <Grid item xs={3}>
          <img
            src="./img/logo-mastercard.png"
            alt=""
            className={classes.paymentImage}
          />
        </Grid>
        <Grid item xs={3}>
          <img src="./img/zelle.png" alt="" className={classes.paypalImage} />
        </Grid>
      </Grid>
      <Grid container justify="flex-start" direction="row" alignItems="center">
        <Grid item xs={3}>
          <img src="./img/bss.png" alt="" className={classes.paypalImage} />
        </Grid>
        <Grid item xs={3}>
          <img src="./img/banesco.png" alt="" className={classes.paypalImage} />
        </Grid>
      </Grid>
    </Grid>
  );
}
function PassengersCost(props) {
  const classes = useStyles();
  const faresPerTypePassenger = props.originFlight
    ? props.originFlight.fare.faresPerTypePassenger
    : props.returnFlight.fare.faresPerTypePassenger;
  return faresPerTypePassenger.map((fare, index) => {
    const sumTaxes = parseFloat(
      parseFloat(
        props.isRoundTrip
          ? parseFloat(
              props.returnFlight
                ? sumArrayObjects(
                    props.returnFlight.fare.faresPerTypePassenger[index].taxes,
                    "amount"
                  ) *
                    parseInt(
                      props.returnFlight.fare.faresPerTypePassenger[index]
                        .quantity
                    )
                : "0"
            )
          : parseFloat(
              props.originFlight
                ? sumArrayObjects(
                    props.originFlight.fare.faresPerTypePassenger[index].taxes,
                    "amount"
                  ) *
                    parseInt(
                      props.originFlight.fare.faresPerTypePassenger[index]
                        .quantity
                    )
                : "0"
            )
        //+
        //parseFloat(props.returnFlight?sumArrayObjects(props.returnFlight.fare.faresPerTypePassenger[index].taxes,"amount")*parseInt(props.returnFlight.fare.faresPerTypePassenger[index].quantity):"0")
      ).toFixed(2) -
        findTaxAmount(
          props.isRoundTrip
            ? props.returnFlight
              ? props.returnFlight.fare.faresPerTypePassenger[index].taxes
              : []
            : props.originFlight
            ? props.originFlight.fare.faresPerTypePassenger[index].taxes
            : [],
          "6P"
        )
    ).toFixed(2);
    const baseFare = parseFloat(
      //parseFloat(props.originFlight?props.originFlight.fare.faresPerTypePassenger[index].baseFare:"0")
      //+
      props.isRoundTrip
        ? parseFloat(
            props.returnFlight
              ? props.returnFlight.fare.faresPerTypePassenger[index].baseFare
              : "0"
          )
        : parseFloat(
            props.originFlight
              ? props.originFlight.fare.faresPerTypePassenger[index].baseFare
              : "0"
          )
    ).toFixed(2);
    return (
      <Grid
        key={index}
        container
        justify="center"
        direction="row"
        alignItems="center"
        className={classes.colorGray}
      >
        <Grid
          container
          justify="space-between"
          direction="row"
          alignItems="center"
          className={classes.paddingTopBottom}
        >
          <Grid item xs={5} md={4}>
            <Typography className={classes.fontMain} color="primary">
              PASAJEROS:
            </Typography>
          </Grid>
          <Grid item xs={5} md={4}>
            <Typography
              className={classes.textSecondary}
              color="textSecondary"
              variant="caption"
            >
              {fare.quantity} {passengersCode(fare.code)}
            </Typography>
          </Grid>
        </Grid>
        <Divider style={{ width: "100%" }} />
        <Grid
          container
          justify="space-between"
          direction="row"
          alignItems="center"
          className={classes.paddingTopBottom}
        >
          <Grid item xs={5} md={4}>
            <Typography
              className={classes.fontMain}
              display="block"
              color="primary"
            >
              TARIFA:
            </Typography>
            <Typography
              className={classes.textSecondary}
              color="textSecondary"
              variant="caption"
            >
              Por pasajero
            </Typography>
          </Grid>
          <Grid item xs={5} md={4}>
            <Grid container direction="row">
              <Grid item xs={4} md={4}>
                <Typography
                  className={classes.fontMain}
                  color="primary"
                >{` ${props.searchParams.currency} `}</Typography>
              </Grid>
              <Grid
                item
                xs={8}
                md={8}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Typography className={classes.fontMain} color="primary">
                  {FormatCurrency(`${baseFare}`)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Divider style={{ width: "100%" }} />
        <Grid
          container
          justify="space-between"
          direction="row"
          alignItems="center"
          className={classes.paddingTopBottom}
        >
          <Grid item md={4} xs={5}>
            <Typography
              className={classes.fontMain}
              display="block"
              color="primary"
            >
              IMPUESTOS:
            </Typography>
          </Grid>
          <Grid item xs={5} md={4}>
            <Grid container direction="row">
              <Grid item xs={4} md={4}>
                <Typography
                  className={classes.fontMain}
                  color="primary"
                >{` ${props.searchParams.currency} `}</Typography>
              </Grid>
              <Grid
                item
                xs={8}
                md={8}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Typography className={classes.fontMain} color="primary">
                  {FormatCurrency(`${sumTaxes}`)}{" "}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {findTaxAmount(
          props.isRoundTrip
            ? props.returnFlight
              ? props.returnFlight.fare.faresPerTypePassenger[index].taxes
              : []
            : props.originFlight
            ? props.originFlight.fare.faresPerTypePassenger[index].taxes
            : [],
          "6P"
        ) !== 0 && (
          <>
            <Divider style={{ width: "100%" }} />
            <Grid
              container
              justify="space-between"
              direction="row"
              alignItems="center"
              className={classes.paddingTopBottom}
            >
              <Grid item md={4} xs={5}>
                <Typography
                  className={classes.fontMain}
                  display="block"
                  color="primary"
                >
                  Prueba PCR:
                </Typography>
              </Grid>
              <Grid item xs={5} md={4}>
                <Grid container direction="row">
                  <Grid item xs={4} md={4}>
                    <Typography
                      className={classes.fontMain}
                      color="primary"
                    >{` ${props.searchParams.currency} `}</Typography>
                  </Grid>
                  <Grid
                    item
                    xs={8}
                    md={8}
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <Typography className={classes.fontMain} color="primary">
                      {FormatCurrency(
                        `${parseFloat(
                          findTaxAmount(
                            props.isRoundTrip
                              ? props.returnFlight
                                ? props.returnFlight.fare.faresPerTypePassenger[
                                    index
                                  ].taxes
                                : []
                              : props.originFlight
                              ? props.originFlight.fare.faresPerTypePassenger[
                                  index
                                ].taxes
                              : [],
                            "6P"
                          )
                        ).toFixed(2)}`
                      )}{" "}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    );
  });
}
function AncillariesDatails(props) {
  const classes = useStyles();
  return (
    <Grid
      container
      justify="center"
      direction="row"
      alignItems="center"
      className={classes.colorGray}
    >
      <Grid
        container
        justify="space-between"
        direction="row"
        alignItems="center"
        className={classes.paddingTopBottom}
      >
        <Grid item xs={5} md={4}>
          <Typography className={classes.fontMain} color="primary">
            ADICIONALES:
          </Typography>
        </Grid>
        <Grid item xs={5} md={4}>
          <Grid container direction="row">
            <Grid item xs={4} md={4}>
              <Typography
                className={classes.fontMain}
                color="primary"
              >{` ${props.searchParams.currency} `}</Typography>
            </Grid>
            <Grid
              item
              xs={8}
              md={8}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Typography className={classes.fontMain} color="primary">
                {props.totalAncillaries}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
export default function PurchaseDetailsOld(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(ref.current);
  };

  const handleClose = () => {
    setAnchorEl(null);
    props.close();
  };
  const totalAmount = parseFloat(
    parseFloat(props.originFlight ? props.originFlight.fare.totalAmount : 0) +
      parseFloat(props.returnFlight ? props.returnFlight.fare.totalAmount : 0)
  ).toFixed(2);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const ref = React.createRef();
  const getAmountAmount = () => {
    return parseFloat(
      props.selectedAncillaries.going.reduce(function (a, b) {
        return parseFloat(a) + parseFloat(b.total);
      }, 0) +
        props.selectedAncillaries.goBack.reduce(function (a, b) {
          return parseFloat(a) + parseFloat(b.total);
        }, 0)
    ).toFixed(2);
  };
  const totalAncillaries = getAmountAmount();
  const getAmountSeats = () => {
    return parseFloat(
      props.selectedSeats.going.reduce(function (a, b) {
        return parseFloat(a) + parseFloat(b.total);
      }, 0) +
        props.selectedSeats.goBack.reduce(function (a, b) {
          return parseFloat(a) + parseFloat(b.total);
        }, 0)
    ).toFixed(2);
  };
  const totalSeats = getAmountSeats();
  useEffect(() => {
    if (props.open) {
      setAnchorEl(ref.current);
    }
  }, [props.open, ref]);

  return (
    <div>
      <Grid
        container
        justify="center"
        direction="row"
        alignItems="center"
        className={classes.root}
      >
        {props.step === 0 ? null : (
          <Grid item xs={10} sm={6} md={10}>
            <Typography variant="h6" className={classes.totalAmount}>
              Total:{" "}
              {props.step === 0
                ? null
                : `${
                    props.searchParams.currency
                      ? props.searchParams.currency
                      : ""
                  }`}{" "}
              {FormatCurrency(
                `${parseFloat(
                  parseFloat(totalAmount) +
                    parseFloat(totalAncillaries) +
                    parseFloat(totalSeats)
                ).toFixed(2)}`
              )}
            </Typography>
            <Typography
              ref={ref}
              className={classes.details}
              onClick={handleClick}
              style={{ display: "flex", alignItems: "center" }}
            >
              Ver detalles <ExpandMoreIcon />
            </Typography>
          </Grid>
        )}
      </Grid>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <div className={classes.popover}>
          <Grid
            container
            justify="flex-start"
            direction="row"
            alignItems="center"
            className={classes.padding}
          >
            <Typography className={classes.fontMain} color="primary">
              DETALLES DE TU COMPRA
            </Typography>
          </Grid>

          {props.originFlight ? (
            <SegmentDetils
              {...props.originFlight}
              searchParams={props.searchParams}
              label={"IDA"}
            />
          ) : null}

          {props.returnFlight ? (
            <>
              <Divider />
              <SegmentDetils
                {...props.returnFlight}
                searchParams={props.searchParams}
                label={"VUELTA"}
              />
            </>
          ) : null}
          {(!props.searchParams.isRoundTrip && props.originFlight) ||
          (props.searchParams.isRoundTrip && props.returnFlight) ? (
            <PassengersCost
              searchParams={props.searchParams}
              isRoundTrip={props.searchParams.isRoundTrip}
              originFlight={props.originFlight}
              returnFlight={props.returnFlight}
            />
          ) : null}
          {(props.selectedAncillaries.going.length > 0 ||
            props.selectedAncillaries.goBack.length > 0) && (
            <AncillariesDatails
              totalAncillaries={totalAncillaries}
              searchParams={props.searchParams}
            />
          )}
          {(props.selectedSeats.going.length > 0 ||
            props.selectedSeats.goBack.length > 0) && (
            <Grid
              container
              justify="center"
              direction="row"
              alignItems="center"
              className={classes.colorGray}
            >
              <Grid
                container
                justify="space-between"
                direction="row"
                alignItems="center"
                className={classes.paddingTopBottom}
              >
                <Grid item xs={5} md={4}>
                  <Typography className={classes.fontMain} color="primary">
                    ASIENTOS:
                  </Typography>
                </Grid>
                <Grid item xs={5} md={4}>
                  <Grid container direction="row">
                    <Grid item xs={4} md={4}>
                      <Typography
                        className={classes.fontMain}
                        color="primary"
                      >{` ${props.searchParams.currency} `}</Typography>
                    </Grid>
                    <Grid
                      item
                      xs={8}
                      md={8}
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <Typography className={classes.fontMain} color="primary">
                        {FormatCurrency(
                          `${parseFloat(parseFloat(totalSeats)).toFixed(2)}`
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}

          <Grid
            container
            justify="space-between"
            direction="row"
            alignItems="center"
            className={classes.color}
          >
            <Grid item md={4} xs={5}>
              <Typography
                className={classes.fontMain}
                style={{ color: "#FFF" }}
              >
                TOTAL A PAGAR:
              </Typography>
            </Grid>
            <Grid item xs={5} md={4}>
              <Grid container direction="row">
                <Grid item xs={4} md={4}>
                  <Typography
                    className={classes.fontMain}
                    style={{ color: "#FFF" }}
                  >
                    {props.step === 0 ? null : `${props.searchParams.currency}`}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={8}
                  md={8}
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <Typography
                    className={classes.fontMain}
                    style={{ color: "#FFF" }}
                  >
                    {" "}
                    {FormatCurrency(
                      `${parseFloat(
                        parseFloat(totalAmount) +
                          parseFloat(totalAncillaries) +
                          parseFloat(totalSeats)
                      ).toFixed(2)}`
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <PaymentsOptions />
        </div>
      </Popover>
    </div>
  );
}
