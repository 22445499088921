import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Container,
  TableRow,
  TablePagination,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  Paper,
  Button,
  IconButton,
  Collapse,
  Box,
  Typography,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid
} from "@material-ui/core";
import { transferServices } from "../services";
import { Spinner, Toast, SearchBar } from "../components";
import {
  //getDate, 
  getFullHour,
  getStatus
} from "../helpers";
import { useHistory } from "react-router-dom";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
const columns = [
  {
    label: "",
    id: "open",
    minWidth: 50,
  },
  {
    id: "pnr",
    label: "PNR",
    minWidth: 50,
  },
  {
    id: "referenceNumber",
    label: "Referencia",
    minWidth: 100,
  },
  {
    id: "transferDate",
    label: "Fecha",
    minWidth: 150,
  },
  {
    id: "bankName",
    label: "Banco emisor",
    minWidth: 150,
  },
  {
    id: "bankNameRecive",
    label: "Banco receptor",
    minWidth: 150,
  },
  {
    id: "full-name",
    label: "Nombre y apellido",
    minWidth: 170,
  },
  {
    id: "email",
    label: "Email",
    minWidth: 170,
  },
  {
    id: "origin",
    label: "Origen",
    minWidth: 90,
  },
  {
    id: "amount",
    label: "Monto",
    minWidth: 90,
  },
  {
    id: "status",
    label: "Estado",
    minWidth: 90,
  },
  {
    id: "admin",
    label: "Administrador",
    minWidth: 90,
  },
  {
    id: "acciones",
    label: "Actualizacion",
    minWidth: 110,
  },
  {
    id: "acciones",
    label: "",
    minWidth: 270,
  }
];

const passengers = [
  {
    id: "full-name",
    label: "Nombre y apellido",
    minWidth: 170,
  },
  {
    id: "email",
    label: "Email",
    minWidth: 100,
  },
];
const segments = [
  {
    id: "route",
    label: "Ruta",
    minWidth: 80,
  },
  {
    id: "departureDate",
    label: "Salida",
    minWidth: 100,
  },
  {
    id: "arrivalDate",
    label: "Llegada",
    minWidth: 100,
  },
];
const banks = [
  { value: "0156", name: "100% BANCO" },
  { value: "0196", name: "ABN AMRO BANK" },
  { value: "0172", name: "BANCAMIGA" },
  { value: "0171", name: "BANCO ACTIVO BANCO COMERCIAL" },
  { value: "0166", name: "BANCO AGRICOLA" },
  { value: "0175", name: "BANCO BICENTENARIO" },
  { value: "0128", name: "BANCO CARONI" },
  { value: "0164", name: "BANCO DE DESARROLLO DEL MICROEMPRESARIO" },
  { value: "0102", name: "BANCO DE VENEZUELA" },
  { value: "0114", name: "BANCO DEL CARIBE" },
  { value: "0149", name: "BANCO DEL PUEBLO SOBERANO" },
  { value: "0163", name: "BANCO DEL TESORO" },
  { value: "0176", name: "BANCO ESPIRITO SANTO" },
  { value: "0115", name: "BANCO EXTERIOR" },
  { value: "0003", name: "BANCO INDUSTRIAL DE VENEZUELA" },
  { value: "0173", name: "BANCO INTERNACIONAL DE DESARROLLO" },
  { value: "0105", name: "MERCANTIL" },
  { value: "0191", name: "BANCO NACIONAL DE CREDITO" },
  { value: "0116", name: "BANCO OCCIDENTAL DE DESCUENTO" },
  { value: "0138", name: "BANCO PLAZA" },
  { value: "0108", name: "BANCO PROVINCIAL BBVA" },
  { value: "0104", name: "BANCO VENEZOLANO DE CREDITO" },
  { value: "0168", name: "BANCRECER" },
  { value: "0134", name: "BANESCO" },
  { value: "0177", name: "BANFANB" },
  { value: "0146", name: "BANGENTE" },
  { value: "0174", name: "BANPLUS" },
  { value: "0190", name: "CITIBANK" },
  { value: "0121", name: "CORP BANCA" },
  { value: "0157", name: "DELSUR" },
  { value: "0151", name: "FONDO COMUN" },
  { value: "0601", name: "INSTITUTO MUNICIPAL DE CRÉDITO POPULAR" },
  { value: "0169", name: "MIBANCO" },
  { value: "0137", name: "SOFITASA" },
  { value: "Banco Mercantil", name: "MERCANTIL" },
  { value: "Banco Banesco ", name: "BANESCO" }
]
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  containerTable: {
    maxHeight: 440,
  },
  container: {
    marginTop: theme.spacing(5),
  },
}));

function getDate(date) {
  const newDate = date.split("T")[0];
  const dateFormat = newDate.split("-")
  return `${dateFormat[2]}-${dateFormat[1]}-${dateFormat[0]}`
}
function getBank(bankName) {
  const bank = banks.find(elem => elem.value === bankName)
  if (bank) {
    return bank.name
  } else return bankName
}
function Row(props) {
  const [open, setOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogCancel, setOpenDialogCancel] = useState(false);
  const [pnr, setPnr] = useState("");
  const [transfer, setTransfer] = useState({});
  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };
  return (
    <React.Fragment>
      <Dialog
        open={openDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Aprobar pago.</DialogTitle>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button
            onClick={() => {
              handleClose();
              props.aproveTranfer(pnr, transfer.email, transfer._id);
            }}
            color="primary"
            autoFocus
          >
            Aprobar
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openDialogCancel}
        onClose={() => setOpenDialogCancel(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Rachazar pago.</DialogTitle>
        <DialogActions>
          <Button onClick={() => setOpenDialogCancel(false)} color="primary">
            Cancelar
          </Button>
          <Button
            onClick={() => {
              setOpenDialogCancel(false)
              props.cancelTranfer(transfer._id);
            }}
            color="primary"
            autoFocus
          >
            Rechazar
          </Button>
        </DialogActions>
      </Dialog>
      <TableRow hover role="checkbox" tabIndex={-1}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{props.transfer.pnr}</TableCell>
        <TableCell>{props.transfer.referenceNumber}</TableCell>
        <TableCell>{props.transfer.createdAt.split("T")[0]}</TableCell>
        <TableCell><Typography noWrap>{getBank(props.transfer.bankName)}</Typography></TableCell>
        <TableCell><Typography noWrap>{props.transfer.BancoOrig ? "Banca amiga" : props.transfer.bankName}</Typography></TableCell>
        <TableCell>{props.transfer.name} {props.transfer.lastName} </TableCell>
        <TableCell>{props.transfer.email}</TableCell>
        <TableCell>{props.transfer?.reservationInfo?.origin || props.transfer.reservationInfo.platform}</TableCell>
        <TableCell>{props.transfer.amount}</TableCell>
        <TableCell>{getStatus(props.transfer.status)}</TableCell>
        <TableCell>{props.transfer.status !== "pending" ? props.transfer.admin ? `${props.transfer.admin?.firstName} ${props.transfer.admin?.lastName}` : "Automatico" : "-"}</TableCell>
        <TableCell>{props.transfer.status !== "pending" ? props.transfer.updatedAt.split("T")[0] : "-"}</TableCell>
        <TableCell>
          {props.transfer.status === "pending" && (
            <Grid container justify="center">
              <Grid item xs={6}>
                <Button
                  onClick={() => {
                    handleClickOpen();
                    setOpen(false);
                    setPnr(props.transfer.pnr);
                    setTransfer(props.transfer);
                  }}
                  variant="contained"
                  color="primary"
                >
                  Aprobar
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  onClick={() => {
                    setOpenDialogCancel(true)
                    setOpen(false);
                    //setPnr(props.transfer.pnr);
                    setTransfer(props.transfer);
                  }}
                  variant="contained"
                  color="primary"
                >
                  Rechazar
                </Button>
              </Grid>
            </Grid>
          )}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          {props.transfer.reservationInfo ? (
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={1}>
                <Typography variant="h6" gutterBottom component="div">
                  Pasajeros
                </Typography>
                <Table aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      {passengers.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{ minWidth: column.minWidth }}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {props.transfer.reservationInfo.passengers.map(
                      (pasenger, index) => (
                        <TableRow key={index}>
                          <TableCell component="th" scope="row">
                            {pasenger.firstName} {pasenger.lastName}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {pasenger.email}
                          </TableCell>
                        </TableRow>
                      )
                    )}
                  </TableBody>
                </Table>
              </Box>
              <Box margin={1}>
                <Typography variant="h6" gutterBottom component="div">
                  Itinerario
                </Typography>
                <Table aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      {segments.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{ minWidth: column.minWidth }}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {props.transfer.reservationInfo.segments.going.map(
                      (segment, index) => (
                        <TableRow key={index}>
                          <TableCell component="th" scope="row">
                            {segment.departure}/{segment.arrival}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {getDate(segment.depatureTime, true)}{" "}
                            {getFullHour(segment.depatureTime)}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {getDate(segment.arrivalTime, true)}{" "}
                            {getFullHour(segment.arrivalTime)}
                          </TableCell>
                        </TableRow>
                      )
                    )}
                    {props.transfer.reservationInfo.segments.goBack.map(
                      (segment, index) => (
                        <TableRow key={index}>
                          <TableCell component="th" scope="row">
                            {segment.departure}/{segment.arrival}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {getDate(segment.depatureTime, true)}{" "}
                            {getFullHour(segment.depatureTime)}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {getDate(segment.arrivalTime, true)}{" "}
                            {getFullHour(segment.arrivalTime)}
                          </TableCell>
                        </TableRow>
                      )
                    )}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          ) : null}
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
export default function TransfersBss() {
  const classes = useStyles();
  let history = useHistory();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [transfers, setTransfers] = useState([]);
  const [count, setCount] = useState(0);
  const [toast, setToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [query, setQuery] = useState("");
  const [isQuery, setIsQuery] = useState("");
  useEffect(() => {
    getTransfers(page + 1, rowsPerPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage]);
  const clearSearchBar = async () => {
    setPage(0)
    setIsQuery(false)
    getTransfers(1, rowsPerPage);
  }
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (isQuery) {
      transfersQuery(newPage + 1, rowsPerPage, query)
    } else {
      getTransfers(newPage + 1, rowsPerPage);
    }

  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
    if (isQuery) {
      transfersQuery(1, event.target.value, query)
    } else {
      getTransfers(1, event.target.value);
    }

  };

  const getTransfers = async (page, limit) => {
    setLoading(true);
    const peticion = await transferServices.getTransfersBss({
      page: page,
      limit: limit,
    });
    setLoading(false);
    const resp = await peticion.resp;
    if (peticion.status === 200) {
      setTransfers(resp.transfers);
      setPage(resp.page ? resp.page - 1 : 0);
      setCount(resp.total);
    } else if (peticion.status === 401) {
      localStorage.clear();
      history.push(`/loginadminavior`);
    }
  };

  const transfersQuery = async (page, limit, query) => {
    setLoading(true);
    const peticion = await transferServices.getTransferQueryBss({
      page,
      limit,
      query
    });
    setLoading(false);
    const resp = await peticion.resp;
    if (peticion.status === 200) {
      setTransfers(resp.transfers);
      setPage(resp.page ? resp.page - 1 : 0);
      setCount(resp.total);
    } else if (peticion.status === 401) {
      localStorage.clear();
      history.push(`/loginadminavior`);
    }
  };

  const aproveTranfer = async (pnr, email, _id) => {
    setLoading(true);
    const peticion = await transferServices.aproveTransferBss({
      pnr: pnr,
      emissionType: "miscellaneous",
      miscellaneousCode: "PM",
      email: email,
    });
    const resp = await peticion.resp;
    if (peticion.status === 200) {
      const peticionUpdate = await transferServices.updateTransferBss(
        _id,
        "approved"
      );
      setLoading(false);
      if (peticionUpdate.status === 200) {
        setToastMessage("Pago aprobado");
        setToast(true);
      } else if (peticionUpdate.status === 400) {
        setToastMessage(resp.error.message);
        setToast(true);
      } else if (peticionUpdate.status === 401) {
        localStorage.clear();
        history.push(`/loginadminavior`);
      }
    } else if (peticion.status === 400) {
      setLoading(false);
      setToastMessage(resp.error.message);
      setToast(true);
    } else if (peticion.status === 401) {
      setLoading(false);
      localStorage.clear();
      history.push(`/loginadminavior`);
    }
  };
  const cancelTranfer = async (_id) => {
    setLoading(true);
    const peticion = await transferServices.updateTransferBss(
      _id,
      "rejected"
    );
    setLoading(false);
    const resp = await peticion.resp;
    if (peticion.status === 200) {
      setToastMessage("Pago rechazado");
      setToast(true);
    } else if (peticion.status === 400) {
      setToastMessage(resp.error.message);
      setToast(true);
    } else if (peticion.status === 401) {
      localStorage.clear();
      history.push(`/loginadminavior`);
    }
  };
  const querySearchBar = async (newQuery) => {
    setQuery(newQuery)
    setPage(0)
    setIsQuery(true)
    transfersQuery(1, rowsPerPage, newQuery)
  }
  const exportTransfers = async () => {
    try {
      setLoading(true);
      const response = await transferServices.exportTransfersBss();
      setLoading(false);
      var blob = new Blob([response], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      var link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "tranferencias-bss.xlsx";
      link.click();
      window.URL.revokeObjectURL(link.href);
    } catch (error) {
      alert("Ha ocurrido un error.");
    }
  };
  return (
    <Container component="main">
      <Grid container justify="center">
        <SearchBar label="PNR, referencia, nombre o monto" search={querySearchBar} clear={clearSearchBar} />
      </Grid>
      <Grid container justify="flex-end">
        <Button onClick={exportTransfers} variant="contained" color="primary">
          Exportar
        </Button>
      </Grid>
      <div className={classes.container}>
        <Paper className={classes.root}>
          <TableContainer className={classes.containerTable}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {transfers.map((transfer, index) => {
                  return (
                    <Row
                      transfer={transfer}
                      aproveTranfer={aproveTranfer}
                      cancelTranfer={cancelTranfer}
                      key={index}
                    />
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            labelRowsPerPage="Elemetos por pagina"
            rowsPerPageOptions={[10, 25, 50, 100]}
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      </div>
      <Toast
        message={toastMessage}
        open={toast}
        close={() => {
          getTransfers(page + 1, rowsPerPage);
          setToast(false);
        }}
      />
      <Spinner loading={loading} />
    </Container>
  );
}
