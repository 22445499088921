import React, { useState } from "react";
import {
  StaticDatePicker,
  LocalizationProvider,
  //DateRangePicker,
} from "@material-ui/pickers";
import esLocale from "date-fns/locale/es";
import DateFnsUtils from "@material-ui/pickers/adapter/date-fns";
import { Popover, TextField, InputAdornment, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Moment from "moment";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%",
  },
  adornment: {
    width: "22px",
  },
  input: {
    "&:before": {
      borderBottom: "1px solid #CD171A",
    },
    "&:after": {
      borderBottom: "2px solid #CD171A",
    },
    "&:focus": {
      borderBottom: "2px solid #CD171A",
    },
    color: "#575756",
    fontSize: "0.8rem",
  },
  underline: {
    "&::before": {
      borderBottom: "1px solid #CD171A",
    },
    "&::after": {
      borderBottom: "1px solid #CD171A",
    },
  },
  label: {
    color: "#575756",
    width: "48%",
    fontSize: "0.8rem",
    fontWeight: "400",
    lineHeight: "1.5",
  },
  container: {
    display: "inline-flex",
    alignItems: "center",
    verticalAlign: "middle",
    width: "100%",
  },
  [theme.breakpoints.down("md")]: {
    label: {
      width: "25% !important",
    },
  },
}));
export default function DateRangeHeader(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElB, setAnchorElB] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickB = (event) => {
    setAnchorElB(event.currentTarget);
  };

  const handleCloseB = () => {
    setAnchorElB(null);
  };

  const open = Boolean(anchorEl);
  const openB = Boolean(anchorElB);

  const id = open ? "simple-popover" : undefined;
  function disableDaysOfTheWeekends(date, days) {
    return days.includes(date.getDay());
  }
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  return (
    <div style={{ width: "100%" }}>
      <LocalizationProvider dateAdapter={DateFnsUtils} locale={esLocale}>
        {props.isRoundTrip ? (
          <>
            {/* <DateRangePicker
              cancelText="Cancelar"
              displayStaticWrapperAs={"desktop"}
              animateYearScrolling
              calendars={matches ? 2 : 1}
              disablePast={props.disablePast}
              disableFuture={props.disableFuture}
              minDate={new Date()}
              showToolbar={false}
              autoOk={true}
              format={props.format ? props.format : "dd-MM-yyyy"}
              shouldDisableDate={(date) =>
                disableDaysOfTheWeekends(
                  date,
                  props.disableDays ? props.disableDays : []
                )
              }
              value={[props.dateDeparture, props.dateArrival]}
              onChange={
                props.onChange
                  ? (dates) => {
                      props.onChange(dates);
                    }
                  : () => {
                      handleClose();
                    }
              }
              renderInput={(startProps, endProps) => (
                <React.Fragment>
                  <Grid
                    container
                    justify="center"
                    direction="row"
                    alignItems="center"
                  >
                    <Grid item xs={11}>
                      <div>
                        <div className={classes.container}>
                          <span className={classes.label}>Ida</span>
                          <TextField
                            ref={startProps.inputRef}
                            value={
                              props.dateDeparture !== null &&
                              props.dateDeparture !== ""
                                ? Moment(props.dateDeparture).format(
                                    "DD-MM-YYYY"
                                  )
                                : ""
                            }
                            variant="standard"
                            onChange={() => {}}
                            //inputProps={classes.input}
                            error={props.dateDepartureError}
                            helperText={props.dateDepartureHelperText}
                            className={classes.formControl}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <img
                                    src="./img/icono-calendario.png"
                                    alt=""
                                    className={classes.adornment}
                                  />
                                </InputAdornment>
                              ),

                              classes: {
                                underline: classes.underline,
                              },
                            }}
                            {...startProps.inputProps}
                            placeholder=""
                            onClick={(e) => {
                              props.selectDaparture();
                            }}
                          />
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={11}>
                      <div>
                        <div className={classes.container}>
                          <span
                            className={classes.label}
                            style={{
                              color: !props.isRoundTrip ? "#c2c2c2" : "#575756",
                            }}
                          >
                            Regreso
                          </span>
                          <TextField
                            ref={endProps.inputRef}
                            value={
                              props.dateArrival !== null &&
                              props.dateArrival !== ""
                                ? Moment(props.dateArrival).format("DD-MM-YYYY")
                                : ""
                            }
                            variant="standard"
                            onChange={() => {}}
                            //inputProps={classes.input}
                            className={classes.formControl}
                            error={props.dateArrivalError}
                            helperText={props.dateArrivalHelperText}
                            disabled={!props.isRoundTrip}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <img
                                    src="./img/icono-calendario.png"
                                    alt=""
                                    className={classes.adornment}
                                  />
                                </InputAdornment>
                              ),
                              classes: {
                                underline: classes.underline,
                              },
                            }}
                            {...endProps.inputProps}
                            placeholder=""
                            onClick={(e) => {
                              props.selectArrival();
                            }}
                          />
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </React.Fragment>
              )}
            /> */}

            <Grid
              container
              justify="center"
              direction="row"
              alignItems="center"
            >
              <Grid item xs={11}>
                <div>
                  <div className={classes.container}>
                    <span className={classes.label}>Ida</span>
                    <TextField
                      value={
                        props.dateDeparture !== null &&
                        props.dateDeparture !== ""
                          ? Moment(props.dateDeparture).format("DD-MM-YYYY")
                          : ""
                      }
                      onClick={(e) => {
                        handleClick(e);
                      }}
                      variant="standard"
                      onChange={() => {}}
                      //inputProps={classes.input}
                      className={classes.formControl}
                      error={props.dateDepartureError}
                      helperText={props.dateDepartureHelperText}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <img
                              src="./img/icono-calendario.png"
                              alt=""
                              className={classes.adornment}
                            />
                          </InputAdornment>
                        ),

                        classes: { underline: classes.underline },
                      }}
                    />
                  </div>
                </div>
              </Grid>
              <Grid item xs={11}>
                <div>
                  <div className={classes.container}>
                    <span
                      className={classes.label}
                      style={{
                        color: !props.isRoundTrip ? "#c2c2c2" : "#575756",
                      }}
                    >
                      Regreso
                    </span>
                    <TextField
                      value={
                        props.dateArrival !== null && props.dateArrival !== ""
                          ? Moment(props.dateArrival).format("DD-MM-YYYY")
                          : ""
                      }
                      onClick={(e) => {
                        if (!props.disabled) handleClickB(e);
                      }}
                      variant="standard"
                      onChange={() => {}}
                      //inputProps={classes.input}
                      className={classes.formControl}
                      error={props.dateArrivalError}
                      helperText={props.dateArrivalHelperText}
                      disabled={!props.isRoundTrip}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <img
                              src="./img/icono-calendario.png"
                              alt=""
                              className={classes.adornment}
                            />
                          </InputAdornment>
                        ),
                        classes: { underline: classes.underline },
                      }}
                    />
                  </div>
                </div>
              </Grid>
            </Grid>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <div style={{ width: "320px" }}>
                <StaticDatePicker
                  displayStaticWrapperAs="desktop"
                  animateYearScrolling
                  disablePast={props.disablePast}
                  disableFuture={props.disableFuture}
                  minDate={new Date()}
                  disableToolbar={!props.toolbar}
                  autoOk={true}
                  variant="static"
                  format={props.format ? props.format : "dd-MM-yyyy"}
                  shouldDisableDate={(date) =>
                    disableDaysOfTheWeekends(
                      date,
                      props.disableDays ? props.disableDays : []
                    )
                  }
                  value={props.dateDeparture}
                  onChange={
                    props.onChange
                      ? (date) => {
                          props.onChange([date, null]);
                          handleClose();
                        }
                      : () => {
                          handleClose();
                        }
                  }
                />
              </div>
            </Popover>
            <Popover
              id={id}
              open={openB}
              anchorEl={anchorElB}
              onClose={handleCloseB}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <div style={{ width: "320px" }}>
                <StaticDatePicker
                  displayStaticWrapperAs="desktop"
                  animateYearScrolling
                  calendars={matches ? 2 : 1}
                  disablePast={props.disablePast}
                  disableFuture={props.disableFuture}
                  //minDate={new Date()}
                  minDate={
                    props.dateDeparture ? props.dateDeparture : new Date()
                  }
                  disableToolbar={!props.toolbar}
                  autoOk={true}
                  variant="static"
                  format={props.format ? props.format : "dd-MM-yyyy"}
                  shouldDisableDate={(date) =>
                    disableDaysOfTheWeekends(
                      date,
                      props.disableDays ? props.disableDays : []
                    )
                  }
                  value={
                    props.dateArrival ? props.dateArrival : props.dateDeparture
                  }
                  onChange={
                    props.onChange
                      ? (date) => {
                          props.onChange([props.dateDeparture, date]);
                          handleCloseB();
                        }
                      : null
                  }
                />
              </div>
            </Popover>
          </>
        ) : (
          <div style={{ width: "100%" }}>
            <Grid
              container
              justify="center"
              direction="row"
              alignItems="center"
            >
              <Grid item xs={11}>
                <div>
                  <div className={classes.container}>
                    <span className={classes.label}>Ida</span>
                    <TextField
                      value={
                        props.dateDeparture !== null &&
                        props.dateDeparture !== ""
                          ? Moment(props.dateDeparture).format("DD-MM-YYYY")
                          : ""
                      }
                      onClick={(e) => {
                        handleClick(e);
                      }}
                      variant="standard"
                      onChange={() => {}}
                      //inputProps={classes.input}
                      className={classes.formControl}
                      error={props.dateDepartureError}
                      helperText={props.dateDepartureHelperText}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <img
                              src="./img/icono-calendario.png"
                              alt=""
                              className={classes.adornment}
                            />
                          </InputAdornment>
                        ),

                        classes: { underline: classes.underline },
                      }}
                    />
                  </div>
                </div>
              </Grid>
              <Grid item xs={11}>
                <div>
                  <div className={classes.container}>
                    <span
                      className={classes.label}
                      style={{
                        color: !props.isRoundTrip ? "#c2c2c2" : "#575756",
                      }}
                    >
                      Regreso
                    </span>
                    <TextField
                      value={
                        props.dateArrival !== null && props.dateArrival !== ""
                          ? Moment(props.dateArrival).format("DD-MM-YYYY")
                          : ""
                      }
                      onClick={(e) => {
                        if (!props.disabled) handleClick(e);
                      }}
                      variant="standard"
                      onChange={() => {}}
                      //inputProps={classes.input}
                      className={classes.formControl}
                      error={props.dateArrivalError}
                      helperText={props.dateArrivalHelperText}
                      disabled={!props.isRoundTrip}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <img
                              src="./img/icono-calendario.png"
                              alt=""
                              className={classes.adornment}
                            />
                          </InputAdornment>
                        ),
                        classes: { underline: classes.underline },
                      }}
                    />
                  </div>
                </div>
              </Grid>
            </Grid>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <div style={{ width: "320px" }}>
                <StaticDatePicker
                  displayStaticWrapperAs="desktop"
                  animateYearScrolling
                  disablePast={props.disablePast}
                  disableFuture={props.disableFuture}
                  minDate={new Date()}
                  disableToolbar={!props.toolbar}
                  autoOk={true}
                  variant="static"
                  format={props.format ? props.format : "dd-MM-yyyy"}
                  shouldDisableDate={(date) =>
                    disableDaysOfTheWeekends(
                      date,
                      props.disableDays ? props.disableDays : []
                    )
                  }
                  value={props.dateDeparture}
                  onChange={
                    props.onChange
                      ? (date) => {
                          props.onChange([date, null]);
                          handleClose();
                        }
                      : () => {
                          handleClose();
                        }
                  }
                />
              </div>
            </Popover>
          </div>
        )}
      </LocalizationProvider>
    </div>
  );
}

/*export default function Pruebas() {
    const [disableDays, setDisableDays] = useState([1,3])
    const [isRoundTrip, setIsRoundTrip] = useState(true);
    const [dateDeparture, setDateDeparture] = useState(null);
    const [dateArrival, setDateArrival] = useState(null);
    const [selectDateDepartureError, setSelectDateDepartureError] = useState("");
    const [selectDateArrivalError, setSelectDateArrivalError] = useState("");
  return (
    <Grid container justify="center" direction="row" alignItems="center">
      <Grid item xs={3}>
        <DateRangeHeader 
          disableDays={disableDays}
          dateDeparture={dateDeparture}
          dateArrival={dateArrival}
          isRoundTrip={isRoundTrip}
          dateDepartureError={selectDateDepartureError !== ""}
          dateDepartureHelperText={selectDateDepartureError}
          dateArrivalError={selectDateArrivalError !== ""}
          dateArrivalHelperText={selectDateArrivalError}
          onChange={(dates)=> {
              if(dates[0]){
                setDateDeparture(dates[0]);
                setDisableDays([4,5])
              }
              if(dates[1]){
                setDateArrival(dates[1]);
                setDisableDays([1,3])
              }
          }}
          />
      </Grid>
    </Grid>
  );
}*/
