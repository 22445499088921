import React from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  AppBar,
  Toolbar,
  Container,
  Typography,
  Divider,
  Button,
} from "@material-ui/core/";
import CssBaseline from "@material-ui/core/CssBaseline";
import clsx from "clsx";
import { useHistory } from "react-router-dom";
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  banner: {
    minHeight: "120vh",
    backgroundImage: `url(./img/AVIORGO_landing.jpg)`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  appBar: {
    background: "#fff",
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  infoSection: {
    background: "#fff",
    paddingTop: "40px",
    paddingBottom: "20px",
  },
  termSection: {
    background: "#f7f7f7",
    paddingTop: "40px",
  },
  button: {
    border: "1pt solid #CCCCCC",
    fontSize: "1.5em",
    fontWeight: "bold",
    position: "absolute",
    top: "-25%",
    padding: "5px 20px",
    borderRadius: "10px",
  },
  ul: {
    color: "#515151",

    "& li::marker": {
      color: "#cd171a",
      fontWeight: "bold",
      display: "inline-block",
      width: "2em",
      marginLeft: "-1em",
      fontSize: "1.3em",
    },
  },
  footer: {
    background: "#cd171a",
    paddingTop: "10px",
    paddingBottom: "10px",
  },
  [theme.breakpoints.down("md")]: {
    h4:{
      fontSize:"1.5rem"
    },
    font:{
      fontSize:"1rem"
    }
  }
}));
export default function LandingAviorGo() {
  const classes = useStyles();
  let history = useHistory();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  function AppBarCustom(props) {
    return (
      <AppBar position="absolute" className={clsx(classes.appBar)}>
        <Container maxWidth="lg">
          <Toolbar>
            <img src="./img/logoWhite.png" alt="" style={{ width: "200px" }} />
          </Toolbar>
        </Container>
      </AppBar>
    );
  }
  function List(props) {
    return (
      <ul className={classes.ul}>
        <li>
          <Typography style={{ color: "#6f6f6f" }}>
            Aplicable para viajes en Tarifa Light, ocho (8) oneway o cuatro (4)
            roundtrip. El valor tiene derecho a usar 8 trayectos (origen y
            destino).
          </Typography>
        </li>
        <li>
          <Typography style={{ color: "#6f6f6f" }}>
            Beneficios de equipaje de la tarifa Light: 1 equipaje de mano de 8
            Kg y selección de asientos.
          </Typography>
        </li>
        {/*<li>
          <Typography style={{ color: "#6f6f6f" }}>
            Válido para compras únicamente desde el 17 de mayo hasta el 14 de junio del
            2021 como última fecha de compra.
          </Typography>
        </li>*/}
        {/*<li>
          <Typography style={{ color: "#6f6f6f" }}>
            Válido para su uso desde la fecha de adquisición del producto hasta
            el 31 de abril del 2022.
          </Typography>
        </li>*/}
        <li>
          <Typography style={{ color: "#6f6f6f" }}>
            La tarifa incluye impuestos y tasas aeroportuarias. No incluye
            prueba PCR del laboratorio Casalab.
          </Typography>
        </li>
        <li>
          <Typography style={{ color: "#6f6f6f" }}>
            Para comprar únicamente a través de la página web aviorair.com. No
            disponible para compras por otros canales ni agencias de viajes.
          </Typography>
        </li>
        <li>
          <Typography style={{ color: "#6f6f6f" }}>
            No se permite cambio de nombre, la promoción es personal e
            intransferible. El titular del Avior Go es la única persona que
            puede utilizarlo.
          </Typography>
        </li>
        <li>
          <Typography style={{ color: "#6f6f6f" }}>
            Tarifa para viajar en cabina turista, el ascenso a clase Business, se deberá pagar la diferencia tarifaria y estará sujeto a
            disponibilidad
          </Typography>
        </li>
        <li>
          <Typography style={{ color: "#6f6f6f" }}>
          No se permiten cambios de fechas ni reembolsos. Consultar las p
olíticas de los beneficios de la tarifa Light
          </Typography>
        </li>
        <li>
          <Typography style={{ color: "#6f6f6f" }}>
          No se permitirán hacer ascensos a otras familias de tarifas.
          </Typography>
        </li>
      </ul>
    );
  }
  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBarCustom />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
         {!matches&&<Grid container justify="center">
           <img src="./img/AVIORGO_landing.jpg" alt="avior go" style={{width:"100%"}}/> 
           </Grid>}
        <Grid
          container
          maxWidth="lg"
          justify="center"
          className={classes.infoSection}
        >
          <Grid item xs={12}>
            <Grid container justify="center">
              <Grid item md={6}>
                <Typography align="center" variant="h4" color="primary" className={classes.h4}>
                  <strong>APLICA PARA LAS RUTAS:</strong>
                </Typography>
                <Typography align="center" variant="h4" color="primary" className={classes.h4}>
                  <strong>SANTO DOMINGO Y CANCÚN</strong>
                </Typography>
                <div style={{ marginTop: "10px", marginBottom: "20px" }}>
                  <Divider style={{ height: "3px", background: "#d6011f" }} />
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container justify="center">
              <Grid item md={6}>
                <Grid container justify="center">
                  <Grid item xs={11} md={12}>
                    <Grid
                      container
                      justify="center"
                      style={{ marginTop: "10px" }}
                    >
                      <Grid item md={3}>
                        <Typography variant="h6" color="primary">
                          <strong>CABINA:</strong>
                        </Typography>
                      </Grid>
                      <Grid item md={7}>
                        <Typography variant="h6" style={{ color: "#9f9f9f" }}>
                          TURÍSTICA
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      justify="center"
                      style={{ marginTop: "10px" }}
                    >
                      <Grid item md={3}>
                        <Typography variant="h6" color="primary">
                          <strong>TARIFA:</strong>
                        </Typography>
                      </Grid>
                      <Grid item md={7}>
                        <Typography variant="h6" style={{ color: "#9f9f9f" }}>
                          LIGHT
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      justify="center"
                      style={{ marginTop: "10px" }}
                    >
                      <Grid item md={3}>
                        <Typography variant="h6" color="primary">
                          <strong>PRECIO:</strong>
                        </Typography>
                      </Grid>
                      <Grid item md={7}>
                        <Typography variant="h6" style={{ color: "#9f9f9f" }}>
                          USD $999.99 POR PRODUCTO
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      justify="center"
                      style={{ marginTop: "10px" }}
                    >
                      <Grid item md={3}>
                        <Typography variant="h6" color="primary">
                          <strong>INCLUYE:</strong>
                        </Typography>
                      </Grid>
                      <Grid item md={7}>
                        <Typography variant="h6" style={{ color: "#9f9f9f" }}>
                          8 TRAYECTOS POR COMPRA
                        </Typography>
                        <Typography variant="h6" style={{ color: "#9f9f9f" }}>
                          IDA O VUELTA
                        </Typography>
                        <Typography variant="h6" style={{ color: "#9f9f9f" }}>
                          INCLUYE IMPUESTOS Y TASAS AEROPORTUARIAS
                        </Typography>
                        <Typography variant="h6" style={{ color: "#9f9f9f" }}>
                          NO INCLUYE PRUEBA PCR
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      justify="center"
                      style={{ marginTop: "10px" }}
                    >
                      <Grid item md={3}>
                        <Typography variant="h6" color="primary">
                          <strong>EQUIPAJE:</strong>
                        </Typography>
                      </Grid>
                      <Grid item md={7}>
                        <Typography variant="h6" style={{ color: "#9f9f9f" }}>
                          APLICA POLÍTICA DE EQUIPAJE PARA TARIFA LIGHT,
                          EQUIPAJE EN BODEGA SERA FACTURADO Y ASUMIDO POR EL
                          PASAJERO
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          maxWidth="lg"
          justify="center"
          className={classes.termSection}
        >
          <Grid item xs={12}>
            <Grid container justify="center">
              <Grid item md={9}>
                <Typography align="center" variant="h4" color="primary"  className={classes.h4}>
                  <strong>TÉRMINOS Y CONDICIONES DE LA CAMPAÑA AVIOR GO</strong>
                </Typography>
              </Grid>
              <Grid item md={9} xs={11}>
                <List />
              </Grid>
            </Grid>
          </Grid>
          <Grid container style={{ marginTop: 60 }} justify="center">
            <Grid
              item
              xs={12}
              style={{
                backgroundColor: "#fff",
                padding: "50px",
                display: "flex",
                justifyContent: "center",
                position: "relative",
              }}
            >
              <Button
                variant="contained"
                color={"primary"}
                className={classes.button}
                onClick={()=>history.push("/avior-go")}
              >
                Acepto
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          maxWidth="lg"
          justify="center"
          className={classes.footer}
        >
          <Grid item xs={10}>
            <Typography variant="h6" align="center" style={{ color: "#fff" }} className={classes.font}>
              Más información, te invitamos a consultar la política de equipaje
              en aviorair.com o en el aplicación móvil Avior Air Si tienes
              problemas para realizar tu compra contáctenos llamando al Call
              center en 0501 AVIOR 00
            </Typography>
          </Grid>
        </Grid>
      </main>
    </div>
  );
}
