import React, { useState } from "react";
import {
    Button,
    Grid,
    TextField,
    Typography,
    Checkbox,
    Divider
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ErrorIcon from "@material-ui/icons/Error";
import { differenceYears } from "../../helpers"
const useStyles = makeStyles((theme) => ({
    formControl: {
      width: "70%",
    },
    adornment: {
      width: "22px",
    },
    font:{
        color: "#555555",
    },
    input: {
      "&:before": {
        borderBottom: "1px solid #CD171A",
      },
      "&:after": {
        borderBottom: "2px solid #CD171A",
      },
      "&:focus": {
        borderBottom: "2px solid #CD171A",
      },
      color: "#575756",
      fontSize: "0.8rem",
    },
    label: {
      marginRight: "10px",
      width: "200px",
    },
    container: {
      display: "inline-flex",
      alignItems: "center",
      verticalAlign: "middle",
      width: "100%",
    },
    icon: {
      fill: "#FA7268",
    },
    inputContainer: {
      width: "55px",
    },
}));

const PassengerPriority = (props) =>{
    const [checked, setChecked] = useState(false);
    const handleChange = (event) => {
        setChecked(event.target.checked);
       // event.target.checked?props.add():props.remove()
        
    };
    return (
        <Grid container justify="flex-start" direction="row">
            <Grid item md={1} xs={2}>
                <Checkbox color="primary" checked={checked} onChange={handleChange}/>
            </Grid>
            <Grid item xs={10}>
                <Grid alignItems="center" container style={{height:"100%"}}>
                    <Typography color="primary">{props.name} {props.lastName}</Typography>
                </Grid>
            </Grid>
        </Grid>
    )
}
export default function PriorityAssistant(props) {
    const classes = useStyles();
    const [days, setDays] = useState("");
    return (
        <Grid
        container
        justify="center"
        direction="row"
        alignItems="center"
        spacing={2}
        >
            <Grid item xs={12}>
                <Grid container spacing={2} justify="space-between">
                    <Grid item md={4} xs={12}>
                        <Typography className={classes.font}>Priority Assitant</Typography>
                    </Grid>
                    <Grid item md={5} xs={12}>
                        <Typography
                        color="primary"
                        style={{ display: "flex"}}
                        >
                        <ErrorIcon
                          style={{ fontSize: "20px" }}
                          color="primary"
                        />{" "}
                        <span style={{ marginLeft: "5px" }}>
                          Valido para pasajeros menores de 75 años{" "}
                        </span>
                      </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                {props.passengers.filter(passenger=>differenceYears(passenger.birthDate)<75).map((passenger,index)=>(
                    <PassengerPriority key={index} {...passenger}/>
                ))}
            </Grid>
            {!props.isRoundTrip&&
              <Grid item xs={12}>
                <div>
                  <div className={classes.container}>
                    <Typography
                      className={classes.label}
                      color="textSecondary"
                    >
                      Cantidad de dias
                    </Typography>
                    <Grid container direction="row" alignItems="center">
                      <div className={classes.inputContainer}>
                        <TextField
                          size="small"
                          variant="outlined"
                          placeholder="00"
                          value={days}
                          onChange={(e) => setDays(e.target.value)}
                        />
                      </div>
                    </Grid>
                  </div>
                  </div>
              </Grid>
            }
            <Grid item xs={12}>
                <Divider/>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2} justify="space-between">
                <Grid item xs={3}>
                  <Typography className={classes.fontFooter} variant="h6" display="inline" color="textSecondary" style={{marginRight:"10px"}}>Total{" "}</Typography> <Typography className={classes.fontFooter} variant="h6" display="inline" color="primary">0.00$</Typography>
                </Grid>
                <Grid item xs={3}>
                    <Button variant="contained" color="primary">
                      ACEPTAR
                    </Button>
                </Grid>
              </Grid>
            </Grid>
        </Grid>
    )
}