import React, { useState, useEffect } from "react";
import { Grid, Typography, Toolbar } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  Footer,
  TravelInfo,
  PassengerDetailsInfo,
  ModalPopUp,
  ModalTravelAssist,
} from "../components/";
import { useLocation, useHistory } from "react-router-dom";
import {
  FormatCurrency,
  //validateRoute
} from "../helpers";
const useStyles = makeStyles((theme) => ({
  divPassengers: {
    width: "",
  },
  [theme.breakpoints.down("sm")]: {
    container: {
      padding: "20px",
    },
    text: {
      fontSize: "0.8rem",
    },
    containerBox: {
      borderBottom: "1pt solid #000",
    },
    imgAvior: {
      width: "90%",
    },
    taxes: {
      flexDirection: "column",
    },
    header: {
      justifyContent: "center",
    },
  },
  [theme.breakpoints.up("sm")]: {
    imgAvior: {
      width: "60%",
    },
    header: {
      justifyContent: "flex-end",
    },
  },
}));
export default function ThankYouPage(props) {
  let location = useLocation();
  let history = useHistory();

  const locationState = location?.state?.state
    ? location.state.state
    : location.state
    ? location.state
    : {};

  const [params] = useState(locationState);
  const [ancillaries, setAncillaries] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const totalAmount =
    parseFloat(params.reservation.amount) +
    parseFloat(params.reservationDetails.itineraryPricing.totalAncillaries);
  const installments = params.installments ? params.installments : false;

  const passengers = params?.passengers?.length
    ? params.passengers
    : params?.reservationDetails?.passengers; // verifica si el arreglo de pasajeros viene en la respuesta

  params.passengers = passengers;

  const [modalToHome, setModalToHome] = useState(false);

  if (!location.state) history.push(`/`);

  const classes = useStyles();

  const buildAncillaries = (reservationAnciallaries = [], segments) => {
    const reservationAnciallariesAux = [];
    reservationAnciallaries.forEach((elem) => {
      elem.itemOffer.forEach((item) => {
        reservationAnciallariesAux.push(item);
      });
    });
    const groupAncillariesAux = reservationAnciallariesAux.reduce(function (
      obj,
      item
    ) {
      obj[item.segmentRPH] = obj[item.segmentRPH] || [];
      obj[item.segmentRPH].push({ ...item });
      return obj;
    },
    {});
    const groups = Object.keys(groupAncillariesAux).map(function (key) {
      return {
        segment: `${segments[key - 1].departure}-${segments[key - 1].arrival}`,
        ancillaries: groupAncillariesAux[key],
      };
    });
    setAncillaries([...groups]);
  };
  useEffect(() => {
    window.fbq("track", "Purchase", { currency: "USD", value: totalAmount });
  }, [totalAmount]);
  useEffect(() => {
    buildAncillaries(
      params.reservation.ancillaries,
      params.reservation.segments
    );
    //if (!validateRoute(params.reservation.segments)) setOpenModal(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    const s = document.createElement("script");
    s.async = true;
    s.innerHTML = `
      gtag('event', 'conversion', {
        'send_to': 'AW-699849219/a6yYCIbkqoACEIO0280C',
        'transaction_id': '${params.reservation.pnr}',
        'value': "${parseFloat(totalAmount).toFixed(2)}", 
        'currency': 'USD' 
      });
    `;
    document.head.appendChild(s);
    return () => {
      document.head.removeChild(s);
    };
  }, [params.reservation.pnr, totalAmount]);
  useEffect(() => {
    const s = document.createElement("script");
    s.async = true;
    s.innerHTML = `
    var dataLayer  = window.dataLayer || [];
    dataLayer.push({ ecommerce: null });
    dataLayer.push({
      'event': 'purchase',
      'ecommerce': {
        'purchase': {
          'actionField': {
            'id': "${params.reservation.pnr} ${
      params.reservationDetails.payment.paymentType
    }",
            'affiliation': 'Avior',
            'value': "${parseFloat(totalAmount).toFixed(2)}",
            'currency': 'USD',
            'tax':'0',
            'shipping': '0'
          },    
            'products': [{
              'name':"Reserva",
              'id': "${params.reservation.pnr}",
              'price': "${parseFloat(totalAmount).toFixed(2)}",
              'brand': 'Avior',
              'category': 'Reserva',
              'variant': 'Gray',
              'quantity': 1,
              'coupon': '' 
            }]
      }
    }});
    `;
    document.head.appendChild(s);
    return () => {
      document.body.removeChild(s);
    };
  }, [
    params.reservation.amount,
    params.reservation.pnr,
    params.reservationDetails.payment.paymentType,
    totalAmount,
  ]);
  return !location.state ? (
    <></>
  ) : (
    <div>
      <Grid container justify="center" className={classes.container}>
        <Grid item md={12} xs={12}>
          <Toolbar>
            <Grid container className={classes.header}>
              <Grid item xs={5} md={2}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img
                    src="./img/logoWhite.png"
                    alt=""
                    style={{ width: "100%", cursor: "pointer" }}
                    onClick={() => setModalToHome(true)}
                  />
                </div>
              </Grid>
            </Grid>
          </Toolbar>
        </Grid>
        <Grid item md={8} xs={12}>
          <Grid container justify="flex-start">
            <Grid item md={10} xs={12}>
              <Grid container justify="flex-start">
                <Grid item md={12} style={{ marginBottom: "20px" }}>
                  <Typography>
                    Estado de <strong>compra:</strong>{" "}
                    <span style={{ color: "#CE2827" }}>APROBADO</span>
                  </Typography>
                </Grid>
                <Grid item md={6} xs={12} style={{ marginBottom: "20px" }}>
                  <Grid container>
                    <Grid item md={6} xs={6}>
                      <Typography
                        style={{ fontWeight: "bold" }}
                        className={classes.text}
                      >
                        Codigo de reserva:
                      </Typography>
                    </Grid>

                    <Grid
                      item
                      md={6}
                      xs={6}
                      style={{ borderBottom: "2pt dotted #000" }}
                    >
                      <Typography className={classes.text}>
                        {params.reservation.pnr}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={6} xs={12} style={{ marginBottom: "20px" }}>
                  <Grid container justify="flex-end">
                    <Grid item md={10}>
                      {installments ? (
                        <Typography className={classes.text}>
                          Gracias por su compra. Ha adquirido su boleto por
                          cuotas. Para obtener más información ingrese a su
                          perfil o comuniquese con nuestro call center. Consulta
                          Avior tu amigo de viaje - Servicios adicionales.
                        </Typography>
                      ) : (
                        <Typography className={classes.text}>
                          Para mas informacion puedes consultar el estado de tu
                          compra a través de nuestra herramienta "Estado de
                          reserva" o comunicate con nuestro call center.
                          Consulta Avior tu amigo de viaje - Servicios
                          adicionales.
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={12} xs={12} style={{ marginBottom: "20px" }}>
                  <Typography className={classes.text}>
                    DETALLES <strong>DE LOS PASAJEROS</strong>
                  </Typography>
                </Grid>
                {params.passengers.map((passenger, index) => (
                  <PassengerDetailsInfo
                    classes={classes}
                    key={index}
                    passenger={passenger}
                    email={params.reservationDetails.passengers[index].email}
                    installments={installments}
                  />
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {params.reservation.segments.map((segment, index) => (
          <TravelInfo
            classes={classes}
            segment={segment}
            key={index}
            index={index + 1}
          />
        ))}

        <Grid item md={8} xs={12} style={{ marginBottom: "20px" }}>
          <Grid container justify="flex-start">
            <Grid
              item
              md={12}
              style={{
                marginBottom: "10px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography className={classes.text}>
                BOLETO <strong>Y PAGO DEL VUELO:</strong>
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item md={8} xs={12} style={{ marginBottom: "20px" }}>
          <Grid container justify="flex-start">
            <Grid item md={2} xs={12}>
              <Grid container justify="space-between">
                <Grid
                  item
                  md={12}
                  style={{
                    padding: "5px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Typography className={classes.text}>Viajeros</Typography>
                </Grid>
                <Grid
                  item
                  md={12}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Typography className={classes.text}>
                    {params.passengers.length}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={2} xs={12}>
              <Grid container justify="space-between">
                <Grid
                  item
                  md={12}
                  style={{
                    padding: "5px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Typography className={classes.text}>
                    Tarifa + sobrecargo
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={12}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography className={classes.text}>
                    {`${params.reservation.currCode} ${FormatCurrency(
                      `${parseFloat(
                        parseFloat(
                          params.reservationDetails.itineraryPricing
                            .amountBeforeTax
                        )
                      ).toFixed(2)}`
                    )}`}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={2} xs={12}>
              <Grid container justify="space-between">
                <Grid
                  item
                  md={12}
                  style={{
                    padding: "5px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Typography className={classes.text}>Adicionales</Typography>
                </Grid>
                <Grid
                  item
                  md={12}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography className={classes.text}>
                    {`${params.reservation.currCode} ${FormatCurrency(
                      `${parseFloat(
                        parseFloat(
                          params.reservationDetails.itineraryPricing
                            .totalAncillaries
                        )
                      ).toFixed(2)}`
                    )}`}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={2} xs={12}>
              <Grid container justify="space-between">
                <Grid
                  item
                  md={12}
                  style={{
                    padding: "5px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Typography className={classes.text}>
                    Tasas + impuestos
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={12}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography className={classes.text}>
                    {`${params.reservation.currCode} ${FormatCurrency(
                      `${parseFloat(
                        parseFloat(
                          params.reservationDetails.itineraryPricing
                            .amountAfterTax
                        ) -
                          parseFloat(
                            params.reservationDetails.itineraryPricing
                              .amountBeforeTax
                          )
                      ).toFixed(2)}`
                    )}`}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={4} xs={12}>
              <Grid container justify="space-between">
                <Grid
                  item
                  md={12}
                  style={{
                    padding: "5px",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Typography className={classes.text}>Total</Typography>
                </Grid>
                <Grid
                  item
                  md={12}
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  <Typography color={"primary"} className={classes.text}>
                    {` ${params.reservation.currCode} ${FormatCurrency(
                      `${parseFloat(parseFloat(totalAmount)).toFixed(2)}`
                    )}`}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          md={8}
          xs={12}
          style={{
            marginBottom: "20px",
            padding: "40px 10px",
            backgroundColor: "#CCCCCC",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Typography style={{ fontWeight: "bold" }} className={classes.text}>
            {`Total para todos los pasajeros ${
              params.reservation.currCode
            } ${FormatCurrency(`${parseFloat(totalAmount).toFixed(2)}`)}`}
          </Typography>
        </Grid>
        <Grid item md={8} xs={12} style={{ marginBottom: "20px" }}>
          <Grid container justify="flex-start">
            <Grid
              item
              md={12}
              style={{
                marginBottom: "10px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography className={classes.text}>
                IMPUESTOS <strong>Y SOBRECARGO POR COMBUSTIBLE:</strong>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={8} xs={12} style={{ marginBottom: "20px" }}>
          <Grid container justify="flex-start" className={classes.taxes}>
            <Grid
              item
              md={12}
              style={{
                marginBottom: "10px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography
                style={{ fontWeight: "bold" }}
                className={classes.text}
              >
                Desglose de impuestos
              </Typography>
            </Grid>
            {params.reservationDetails.itineraryPricing.taxes.map(
              (tax, index) => (
                <Grid
                  item
                  md={12}
                  style={{
                    marginBottom: "10px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                  key={index}
                >
                  <div style={{ width: "80%" }}>
                    <Typography
                      style={{ fontWeight: "bold" }}
                      className={classes.text}
                    >
                      {tax.taxCode}
                    </Typography>
                  </div>

                  <div
                    style={{
                      width: "20%",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      style={{ fontWeight: "bold" }}
                      className={classes.text}
                    >
                      {params.reservation.currCode}
                    </Typography>
                    <Typography
                      style={{ fontWeight: "bold" }}
                      className={classes.text}
                    >
                      {FormatCurrency(`${parseFloat(tax.amount).toFixed(2)}`)}
                    </Typography>
                  </div>
                </Grid>
              )
            )}
          </Grid>
        </Grid>
        {ancillaries.length > 0 && (
          <Grid item md={8} xs={12} style={{ marginBottom: "20px" }}>
            <Grid container justify="flex-start" className={classes.taxes}>
              <Grid
                item
                md={12}
                style={{
                  marginBottom: "10px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography
                  style={{ fontWeight: "bold" }}
                  className={classes.text}
                >
                  Adicionales
                </Typography>
              </Grid>
            </Grid>
            {ancillaries.map((ancillary, index) => (
              <Grid container key={index}>
                <Grid item xs={12}>
                  <Typography
                    style={{ fontWeight: "bold" }}
                    className={classes.text}
                    color="primary"
                  >
                    {ancillary.segment}
                  </Typography>
                </Grid>
                <Grid container>
                  {ancillary.ancillaries.map((elem, key) => (
                    <Grid container justify="space-between" key={key}>
                      <Grid item xs={6}>
                        <Typography
                          style={{ fontWeight: "bold" }}
                          className={classes.text}
                        >
                          {elem.description}
                        </Typography>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            ))}
          </Grid>
        )}
        <Grid item md={8} xs={12} style={{ marginBottom: "20px" }}>
          <Grid container justify="flex-start">
            <Grid
              item
              md={12}
              style={{
                marginBottom: "10px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                style={{ fontWeight: "bold" }}
                className={classes.text}
              >
                PARA TENER EN CUENTA
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={8} xs={12} style={{ marginBottom: "20px" }}>
          <Grid
            container
            style={{ backgroundColor: "#CCCCCC", padding: "20px" }}
          >
            <ul>
              <li>
                <Typography className={classes.text}>
                  Te recomendamos llegar (4) horas antes de la hora programada
                  del vuelo sim importar por que medio realizaste el Check-in.
                </Typography>
              </li>
              <li>
                <Typography className={classes.text}>
                  Si presentas una condicion de discapacidad y requieres
                  asistencia adicional o alguno de nuestros otros servicios que
                  te ofrece AVIOR
                </Typography>
              </li>
            </ul>
          </Grid>
        </Grid>
      </Grid>
      <ModalTravelAssist open={openModal} close={() => setOpenModal(false)} />
      <ModalPopUp
        open={modalToHome}
        close={() => setModalToHome(false)}
        modalToHome={true}
      />
      <Footer />
    </div>
  );
}
