import React from 'react';
import { Typography, Link }from '@material-ui/core/';
export default function Copyright() {
    return (
      <Typography variant="body2" color="textSecondary" align="center">
        {'Copyright © '}
        <Link  target="_blank" color="inherit" href="https://back9.com.ve">
          Back9 solutions LLC. 
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
  }
  