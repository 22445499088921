import React, { useState, useEffect } from "react";
import { Grid, Typography, Toolbar } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  Footer,
  TravelInfo,
  PassengerDetailsInfo,
  ModalPopUp,
  ModalTravelAssist,
} from "../components";
import { useLocation, useHistory } from "react-router-dom";
import {
  FormatCurrency,
  //validateRoute
} from "../helpers";

const useStyles = makeStyles((theme) => ({
  divPassengers: {
    width: "",
  },
  [theme.breakpoints.down("sm")]: {
    container: {
      padding: "20px",
    },
    text: {
      fontSize: "0.8rem",
    },
    containerBox: {
      borderBottom: "1pt solid #000",
    },
    imgAvior: {
      width: "90%",
    },
    taxes: {
      flexDirection: "column",
    },
    header: {
      justifyContent: "center",
    },
  },
  [theme.breakpoints.up("sm")]: {
    imgAvior: {
      width: "60%",
    },
    header: {
      justifyContent: "flex-end",
    },
  },
}));

export default function ThankYouPage(props) {
  const classes = useStyles();

  let location = useLocation();
  let history = useHistory();
  if (!location.state) history.push(`/`);

  const [params] = useState(location.state ? location.state.state : {});

  const ancillaries = params?.ancillaries;
  const totalAmount = params?.reservation?.price.totalAmount;
  const pnr = params?.reservation?.pnr;
  const paymentType = "miles";

  const [openModal, setOpenModal] = useState(false);
  const [modalToHome, setModalToHome] = useState(false);

  useEffect(() => {
    window.fbq("track", "Purchase", { currency: "USD", value: totalAmount });
  }, [totalAmount]);

  useEffect(() => {
    const s = document.createElement("script");
    s.async = true;
    s.innerHTML = `
      gtag('event', 'conversion', {
        'send_to': 'AW-699849219/a6yYCIbkqoACEIO0280C',
        'transaction_id': '${pnr}',
        'value': "${parseFloat(totalAmount).toFixed(2)}", 
        'currency': 'USD' 
      });
    `;
    document.head.appendChild(s);
    return () => {
      document.head.removeChild(s);
    };
  }, [pnr, totalAmount]);

  useEffect(() => {
    const s = document.createElement("script");
    s.async = true;
    s.innerHTML = `
    var dataLayer  = window.dataLayer || [];
    dataLayer.push({ ecommerce: null });
    dataLayer.push({
      'event': 'purchase',
      'ecommerce': {
        'purchase': {
          'actionField': {
            'id': "${pnr} ${paymentType}",
            'affiliation': 'Avior',
            'value': "${parseFloat(totalAmount).toFixed(2)}",
            'currency': 'USD',
            'tax':'0',
            'shipping': '0'
          },    
            'products': [{
              'name':"Reserva",
              'id': "${pnr}",
              'price': "${parseFloat(totalAmount).toFixed(2)}",
              'brand': 'Avior',
              'category': 'Reserva',
              'variant': 'Gray',
              'quantity': 1,
              'coupon': '' 
            }]
      }
    }});
    `;
    document.head.appendChild(s);
    return () => {
      document.body.removeChild(s);
    };
  }, [pnr, paymentType, totalAmount]);

  return !location.state ? (
    <></>
  ) : (
    <div>
      <Grid container justify="center" className={classes.container}>
        <Grid item md={12} xs={12}>
          <Toolbar>
            <Grid container className={classes.header}>
              <Grid item xs={5} md={2}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img
                    src="./img/logoWhite.png"
                    alt=""
                    style={{ width: "100%", cursor: "pointer" }}
                    onClick={() => setModalToHome(true)}
                  />
                </div>
              </Grid>
            </Grid>
          </Toolbar>
        </Grid>
        <Grid item md={8} xs={12}>
          <Grid container justify="flex-start">
            <Grid item md={10} xs={12}>
              <Grid container justify="flex-start">
                <Grid item md={12} style={{ marginBottom: "20px" }}>
                  <Typography>
                    Estado de <strong>compra:</strong>{" "}
                    <span style={{ color: "#CE2827" }}>APROBADO</span>
                  </Typography>
                </Grid>
                <Grid item md={6} xs={12} style={{ marginBottom: "20px" }}>
                  <Grid container>
                    <Grid item md={6} xs={6}>
                      <Typography
                        style={{ fontWeight: "bold" }}
                        className={classes.text}
                      >
                        Codigo de reserva:
                      </Typography>
                    </Grid>

                    <Grid
                      item
                      md={6}
                      xs={6}
                      style={{ borderBottom: "2pt dotted #000" }}
                    >
                      <Typography className={classes.text}>
                        {params.reservation.pnr}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={6} xs={12} style={{ marginBottom: "20px" }}>
                  <Grid container justify="flex-end">
                    <Grid item md={10}>
                      <Typography className={classes.text}>
                        Para mas informacion puedes consultar el estado de tu
                        compra a través de nuestra herramienta "Estado de
                        reserva" o comunicate con nuestro call center. Consulta
                        Avior tu amigo de viaje - Servicios adicionales.
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={12} xs={12} style={{ marginBottom: "20px" }}>
                  <Typography className={classes.text}>
                    DETALLES <strong>DE LOS PASAJEROS</strong>
                  </Typography>
                </Grid>

                {params.reservation.passengers.map((passenger, index) => {
                  const ticketNumber =
                    params.reservation.tickets[index].ticketNumber;

                  passenger.ticketNumber = ticketNumber;

                  return (
                    <PassengerDetailsInfo
                      classes={classes}
                      key={index}
                      passenger={passenger}
                      email={passenger.email}
                    />
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {params.reservation.segments.going.map((segment, index) => (
          <TravelInfo
            classes={classes}
            segment={segment}
            key={index}
            index={index + 1}
          />
        ))}

        {params.reservation.segments.goBack.length
          ? params.reservation.segments.goBack.map((segment, index) => {
              const track =
                params.reservation.segments.going.length + index + 1;

              return (
                <TravelInfo
                  classes={classes}
                  segment={segment}
                  key={index}
                  index={track}
                />
              );
            })
          : null}

        <Grid item md={8} xs={12} style={{ marginBottom: "20px" }}>
          <Grid container justify="flex-start">
            <Grid
              item
              md={12}
              style={{
                marginBottom: "10px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography className={classes.text}>
                BOLETO <strong>Y PAGO DEL VUELO:</strong>
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item md={8} xs={12} style={{ marginBottom: "20px" }}>
          <Grid container justify="flex-start">
            <Grid item md={2} xs={12}>
              <Grid container justify="space-between">
                <Grid
                  item
                  md={12}
                  style={{
                    padding: "5px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Typography className={classes.text}>Viajeros</Typography>
                </Grid>
                <Grid
                  item
                  md={12}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Typography className={classes.text}>
                    {params.reservation.passengers.length}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={2} xs={12}>
              <Grid container justify="space-between">
                <Grid
                  item
                  md={12}
                  style={{
                    padding: "5px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Typography className={classes.text}>
                    Tarifa + sobrecargo
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={12}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography className={classes.text}>
                    {`${params.reservation.currency} ${FormatCurrency(
                      `${parseFloat(
                        parseFloat(params.reservation.price.baseFare)
                      ).toFixed(2)}`
                    )}`}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={2} xs={12}>
              <Grid container justify="space-between">
                <Grid
                  item
                  md={12}
                  style={{
                    padding: "5px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Typography className={classes.text}>Adicionales</Typography>
                </Grid>
                <Grid
                  item
                  md={12}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography className={classes.text}>
                    {`${params.reservation.currency} ${FormatCurrency(
                      `${parseFloat(
                        parseFloat(params.reservation.price.totalAncillaries)
                      ).toFixed(2)}`
                    )}`}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={2} xs={12}>
              <Grid container justify="space-between">
                <Grid
                  item
                  md={12}
                  style={{
                    padding: "5px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Typography className={classes.text}>
                    Tasas + impuestos
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={12}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography className={classes.text}>
                    {`${params.reservation.currency} ${FormatCurrency(
                      `${parseFloat(
                        params.reservation.price.taxesAmount
                      ).toFixed(2)}`
                    )}`}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={4} xs={12}>
              <Grid container justify="space-between">
                <Grid
                  item
                  md={12}
                  style={{
                    padding: "5px",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Typography className={classes.text}>Total</Typography>
                </Grid>
                <Grid
                  item
                  md={12}
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  <Typography color={"primary"} className={classes.text}>
                    {` ${params.reservation.currency} ${FormatCurrency(
                      `${parseFloat(parseFloat(totalAmount)).toFixed(2)}`
                    )}`}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          md={8}
          xs={12}
          style={{
            marginBottom: "20px",
            padding: "40px 10px",
            backgroundColor: "#CCCCCC",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Typography style={{ fontWeight: "bold" }} className={classes.text}>
            {`Total para todos los pasajeros ${
              params.reservation.currency
            } ${FormatCurrency(`${parseFloat(totalAmount).toFixed(2)}`)}`}
          </Typography>
        </Grid>

        <Grid item md={8} xs={12} style={{ marginBottom: "20px" }}>
          <Grid container justify="flex-start">
            <Grid
              item
              md={12}
              style={{
                marginBottom: "10px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography className={classes.text}>
                IMPUESTOS <strong>Y SOBRECARGO POR COMBUSTIBLE:</strong>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={8} xs={12} style={{ marginBottom: "20px" }}>
          <Grid container justify="flex-start" className={classes.taxes}>
            <Grid
              item
              md={12}
              style={{
                marginBottom: "10px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography
                style={{ fontWeight: "bold" }}
                className={classes.text}
              >
                Desglose de impuestos
              </Typography>
            </Grid>
            {params.reservation.taxes.map((tax, index) => (
              <Grid
                item
                md={12}
                style={{
                  marginBottom: "10px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
                key={index}
              >
                <div style={{ width: "80%" }}>
                  <Typography
                    style={{ fontWeight: "bold" }}
                    className={classes.text}
                  >
                    {tax.code}
                  </Typography>
                </div>

                <div
                  style={{
                    width: "20%",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    style={{ fontWeight: "bold" }}
                    className={classes.text}
                  >
                    {params.reservation.currency}
                  </Typography>
                  <Typography
                    style={{ fontWeight: "bold" }}
                    className={classes.text}
                  >
                    {FormatCurrency(`${parseFloat(tax.amount).toFixed(2)}`)}
                  </Typography>
                </div>
              </Grid>
            ))}
          </Grid>
        </Grid>

        {(ancillaries.going.length > 0 || ancillaries.goBack.length > 0) && (
          <Grid item md={8} xs={12} style={{ marginBottom: "20px" }}>
            <Grid container justify="flex-start" className={classes.taxes}>
              <Grid
                item
                md={12}
                style={{
                  marginBottom: "10px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography
                  style={{ fontWeight: "bold" }}
                  className={classes.text}
                >
                  Adicionales
                </Typography>
              </Grid>
            </Grid>

            {ancillaries.going.length > 0 && (
              <Grid container key={1}>
                <Grid item xs={12}>
                  <Typography
                    style={{ fontWeight: "bold" }}
                    className={classes.text}
                    color="primary"
                  >
                    {params.reservation.segments.going[0].departure}-
                    {
                      params.reservation.segments.going[
                        params.reservation.segments.going.length - 1
                      ].arrival
                    }
                  </Typography>
                </Grid>

                {ancillaries.going.map((ancillary, key) => (
                  <Grid container>
                    <Grid container justify="space-between" key={key}>
                      <Grid item xs={6}>
                        <Typography
                          style={{ fontWeight: "bold" }}
                          className={classes.text}
                        >
                          {ancillary.name}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            )}

            {ancillaries.goBack.length > 0 && (
              <Grid container key={1}>
                <Grid item xs={12}>
                  <Typography
                    style={{ fontWeight: "bold" }}
                    className={classes.text}
                    color="primary"
                  >
                    {
                      params.reservation.segments.going[
                        params.reservation.segments.going.length - 1
                      ].arrival
                    }
                    -{params.reservation.segments.going[0].departure}
                  </Typography>
                </Grid>

                {ancillaries.goBack.map((ancillary, key) => (
                  <Grid container>
                    <Grid container justify="space-between" key={key}>
                      <Grid item xs={6}>
                        <Typography
                          style={{ fontWeight: "bold" }}
                          className={classes.text}
                        >
                          {ancillary.name}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            )}
          </Grid>
        )}

        <Grid item md={8} xs={12} style={{ marginBottom: "20px" }}>
          <Grid container justify="flex-start">
            <Grid
              item
              md={12}
              style={{
                marginBottom: "10px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                style={{ fontWeight: "bold" }}
                className={classes.text}
              >
                PARA TENER EN CUENTA
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={8} xs={12} style={{ marginBottom: "20px" }}>
          <Grid
            container
            style={{ backgroundColor: "#CCCCCC", padding: "20px" }}
          >
            <ul>
              <li>
                <Typography className={classes.text}>
                  Te recomendamos llegar (4) horas antes de la hora programada
                  del vuelo sim importar por que medio realizaste el Check-in.
                </Typography>
              </li>
              <li>
                <Typography className={classes.text}>
                  Si presentas una condicion de discapacidad y requieres
                  asistencia adicional o alguno de nuestros otros servicios que
                  te ofrece AVIOR
                </Typography>
              </li>
            </ul>
          </Grid>
        </Grid>
      </Grid>
      <ModalTravelAssist open={openModal} close={() => setOpenModal(false)} />
      <ModalPopUp
        open={modalToHome}
        close={() => setModalToHome(false)}
        modalToHome={true}
      />
      <Footer />
    </div>
  );
}
