import React, { useState } from "react";
import {
  Grid,
  GridList,
  GridListTile,
  Typography,
  Button,
  Radio,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  faresColors,
  FormatCurrency,
  getFareClassName,
  findTaxAmount,
} from "../../helpers";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    overflow: "hidden",
  },
  gridList: {
    flexWrap: "nowrap",
    transform: "translateZ(0)",
  },
  gridListTile: {
    height: "none",
    width: "120px",
    marginRight: "2px",
  },
  fare: {
    color: "rgb(133, 133, 141)",
    //borderRadius:"5px",
    cursor: "pointer",
    padding: "6px",
  },
  fareContainer: {},
  fareFamilyName: {
    fontWeight: "bold",
    fontSize: "1.1rem",
    textAlign: "center",
    fontStyle: "oblique",
  },
}));
export default function FaresRoundTrip(props) {
  const classes = useStyles();
  const fares = [];
  const LIGHT = props.fares.find((fare) => fare.fareInfo.className === "LIGHT");
  const FLEX = props.fares.find((fare) => fare.fareInfo.className === "FLEX");
  const MEDIUM = props.fares.find(
    (fare) => fare.fareInfo.className === "MEDIUM"
  );
  const ULTRA = props.fares.find((fare) => fare.fareInfo.className === "ULTRA");
  const BUSINESS = props.fares.find(
    (fare) => fare.fareInfo.className === "BUSINESS"
  );
  if (LIGHT) {
    fares.push(LIGHT);
  } else {
    fares.push({
      fareInfo: {
        originDestRPH: "1",
        className: "LIGHT",
        classCode: "M",
        cabin: "ECONOMY",
        posting: "0",
      },
    });
  }
  if (FLEX) {
    fares.push(FLEX);
  } else {
    fares.push({
      fareInfo: {
        originDestRPH: "1",
        className: "FLEX",
        classCode: "E",
        cabin: "ECONOMY",
        posting: "0",
      },
    });
  }
  if (MEDIUM) {
    fares.push(MEDIUM);
  } else {
    fares.push({
      fareInfo: {
        originDestRPH: "1",
        className: "MEDIUM",
        classCode: "M",
        cabin: "ECONOMY",
        posting: "0",
      },
    });
  }
  if (ULTRA) {
    fares.push(ULTRA);
  } else {
    fares.push({
      fareInfo: {
        originDestRPH: "1",
        className: "ULTRA",
        classCode: "M",
        cabin: "ECONOMY",
        posting: "0",
      },
    });
  }
  if (BUSINESS) {
    fares.push(BUSINESS);
  } else {
    fares.push({
      fareInfo: {
        originDestRPH: "1",
        className: "BUSINESS",
        classCode: "M",
        cabin: "BUSINESS",
        posting: "0",
      },
    });
  }
  const [seledtedIndex, setSeledtedIndex] = useState(null);
  const [selectRadio, setSelectRadio] = useState(null);
  return (
    <Grid container justify="center" direction="row" className={classes.grid}>
      <Grid item xs={12}>
        <div className={classes.root}>
          <GridList className={classes.gridList}>
            {fares.map((fare, index) => {
              return (
                <GridListTile
                  key={index}
                  style={{
                    height: null,
                    width: "115px",
                    paddingLeft: 0,
                    paddingRight: 0,
                    marginLeft:
                      fare.fareInfo.className === "BUSINESS" ? "5px" : "0",
                  }}
                >
                  <div style={{ height: "20px", color: "#777777" }}>
                    <Typography
                      variant="h6"
                      style={{ fontSize: "0.8em", fontStyle: "oblique" }}
                      align="center"
                    >
                      {`${getFareClassName(fare.fareInfo.className)} ${
                        fare.fareInfo.className === "FLEX"
                          ? `(Recomendada)`
                          : ""
                      }`}
                    </Typography>
                  </div>

                  <div
                    style={{
                      width: "100%",
                      height: "4px",
                      background: faresColors(fare.fareInfo.className),
                      border: `1pt solid ${
                        faresColors(fare.fareInfo.className) === "#FFF"
                          ? "#575756"
                          : null
                      }`,
                      marginBottom: "5px",
                    }}
                  ></div>

                  <Grid container justify="center" direction="row">
                    <Grid
                      item
                      xs={12}
                      style={{ paddingLeft: "2px", paddingRight: "2px" }}
                    >
                      <Button
                        disabled={!props.selectable}
                        className={classes.fare}
                        style={{
                          width: "100%",
                          height: "55px",
                          borderRadius: "0",
                          color:
                            faresColors(fare.fareInfo.className) === "#FFF"
                              ? "#575756"
                              : seledtedIndex === index && props.isSelected
                              ? "#fff"
                              : "rgb(133, 133, 141)",
                          background:
                            fare.fareInfo.posting !== "0"
                              ? seledtedIndex === index && props.isSelected
                                ? faresColors(fare.fareInfo.className)
                                : "#FAFAFA"
                              : "#6d6e71",
                          border: `${
                            fare.className === "FLEX" ? "3pt" : "1pt"
                          } solid ${
                            faresColors(fare.fareInfo.className) === "#FFF"
                              ? "#575756"
                              : faresColors(fare.fareInfo.className)
                          }`,
                        }}
                        onClick={() => {
                          if (fare.fareInfo.posting !== "0") {
                            //props.selectFare(fare);
                            setSeledtedIndex(index);
                            setSelectRadio(index);
                            props.selectFare(fare);
                          }
                        }}
                      >
                        <Grid container justify="center" direction="row">
                          <Grid item xs={12}></Grid>
                          <Grid item xs={12}>
                            {fare.fareInfo.posting !== "0" ? (
                              <>
                                <Typography
                                  variant="h6"
                                  align="center"
                                  style={{ fontSize: "1em" }}
                                >
                                  {`${props.currency}`}{" "}
                                  {FormatCurrency(
                                    `${parseFloat(
                                      fare.fareConstruction.componentAmount -
                                        findTaxAmount(fare.taxes, "6P") / 2
                                    ).toFixed(2)}`
                                  )}
                                </Typography>
                                <Radio
                                  onClick={() => {
                                    setSelectRadio(index);
                                    props.selectFare(fare);
                                    //setSeledtedIndex(index);
                                  }}
                                  checked={
                                    selectRadio === index && props.isSelected
                                  }
                                  size="small"
                                  style={{
                                    padding: "4px",
                                    color:
                                      faresColors(fare.fareInfo.className) ===
                                      "#FFF"
                                        ? "#575756"
                                        : selectRadio === index &&
                                          props.isSelected
                                        ? "#fff"
                                        : "rgba(0, 0, 0, 0.54)",
                                  }}
                                />
                              </>
                            ) : (
                              <Typography
                                variant="h6"
                                align="center"
                                style={{ fontSize: "1em" }}
                              >
                                Agotado
                              </Typography>
                            )}
                          </Grid>
                        </Grid>
                      </Button>
                    </Grid>
                  </Grid>
                </GridListTile>
              );
            })}
          </GridList>
        </div>
      </Grid>
    </Grid>
  );
}
