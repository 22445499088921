import urls from "../urls";

const getProducts = async () => {
    const peticion = await fetch(urls.products, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        }
    });
    let resp;
    if(peticion.status===401){
        resp={}
    }
    else{
        resp = await peticion.json();  
    }
    return {
        status: peticion.status,
        resp
    };
};

const createOrder = async ({...orderRequest})=>{
    const token = localStorage.getItem("token");
    const peticion = await fetch(urls.createOrder, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        },
        body:JSON.stringify(orderRequest)
    });
    let resp;
    if(peticion.status===401){
        resp={}
    }
    else{
        resp = await peticion.json();  
    }
    return {
        status: peticion.status,
        resp
    };
}
const getOrders = async ({page, limit})=>{
    const token = localStorage.getItem("token");
    const peticion = await fetch(`${urls.getOrders}?limit=${limit}&page=${page}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        }
    });
    let resp;
    if(peticion.status===401){
        resp = {};
    }else{
        resp= await peticion.json()
    }
    return {
        status: peticion.status,
        resp
    };
}
const getAncilliaries = async(segments)=>{
    const body = {
      segments
    }
    const request = await fetch(urls.ancillaries,{
        method:"POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization":'Basic '+ btoa('avior:fazQMUyq'),
        },
        body: JSON.stringify(body)
    });
    const response = await request.json();
    return {
      status: request.status,
      response
    };
}
const anciliariesServices = Object.freeze({
    getProducts,
    createOrder,
    getOrders,
    getAncilliaries
})

export default anciliariesServices;