//avior test
//const base = "http://api.avior.com.ve:3000/api/v1";
//avior produccion
const base = "https://api.avior.com.ve/api/v1";
//avior local
//const base = 'http://localhost:8000/api/v1'
//const baseProd = "http://api.avior.com.ve/api/v1";

//aviorplus produccion
const baseAP = "https://api.aviorplus.com/api/v1";
//aviorplus local
//const baseAP = "http://localhost:3420/api/v1";

const urls = {
  airports: `${base}/airports`,
  destinations: `${base}/destinations`,
  availability: `${base}/availability`,
  createReservation: `${base}/booking`,
  paymentCreditCard: `${base}/view/paymentweb`,
  payCash: `${base}/paycashorder`,
  payPse: `${base}/paybanktransferorder`,
  booking: `${base}/booking`,
  lowFares: `${base}/lowfare/dates`,
  availabilityV2: `${base}/availability-v2`,
  paymentPaypalButton: `${base}/view/paypalbutton`,
  paymentPaypalCreditCard: `${base}/view/paypalcreditcard`,
  generatePaymentLink: `${base}/paypal-link`,
  getPrice: `${base}/price`,
  paypal: `${base}/view/paypalweb-`,
  transfer: `${base}/transfer`,
  transferBss: `${base}/transfer-bss`,
  transfersGetTotalBalance: `${base}/transfers/get-total-balance`,
  login: `${base}/users/login`,
  getTransfers: `${base}/transfers`,
  getTransfersBss: `${base}/transfers-bss`,
  aproveTransfer: `${base}/ticket/transfers`,
  aproveTransferBss: `${base}/ticket/transfers-bss`,
  products: `${base}/products`,
  paymentAnciliaries: `${base}/view/paypal-miscellaneous`,
  createOrder: `${base}/order`,
  getOrders: `${base}/orders/list`,
  updateTransfer: `${base}/transfer`,
  updateTransferBss: `${base}/transfer-bss`,
  bancaAmiga: `${base}/banca-amiga`,
  bancaAmigaAnciliaries: `${base}/order/pay-banca-amiga`,
  paymentPromoCreditCard: `${base}/pay-promotion-code`,
  passengerPromoCode: `${base}/passenger-code`,
  ancillaries: `${base}/ancillaries`,
  payAnciliariesCreditCardTuOfertazo: `${base}/order/pay-tu-ofertazo`,
  itineraries: `${base}/itineraries`,
  dolarPrice: `${base}/dolarprice`,
  paymentOrderBancaAmigaBooking: `${base}/banca-amiga-order-v2`,
  verifyOrderBancaAmigaBooking: `${base}/banca-amiga-verify-order-v2`,
  paymentOrderBancaAmigaMiscellaneous: `${base}/order/payment-order-banca-amiga`,
  veryfyOrderBancaAmigaMiscellaneous: `${base}/order/verify-order-banca-amiga`,
  queryTransfers: `${base}/transfers-query`,
  queryTransfersBss: `${base}/transfers-bss-query`,
  exportTransfers: `${base}/transfers-usd/export`,
  exportTransfersBss: `${base}/transfers-bss/export`,
  fareFamilies: `${base}/fareconditions`,
  paymentBookingCreditCardTuOfertazo: `${base}/booking/tu-ofertazo`,
  invitationCodeFitGames: `${base}/invitation-code-fit-games`,
  gateways: `${base}/payment-gateways`,
  boarding: `${base}/boarding-pass`,
  invitationCodeSusnet: `${base}/invitation-code-sunset`,
  invitationCCSFC: `${base}/invitation-ccs-fc`,
  invitationCodeCCSFC: `${base}/invitation-code-ccs-fc`,
  lukaPayBooking: `${base}/view/luka-pay`,
  lukaPayLink: `${base}/view/luka-pay-link`,
  paymentBookingBAPM: `${base}/emit-tickets-bapm`,
  paymentBookingBTPM: `${base}/emit-tickets-btpm`,
  invitationFVG: `${base}/invitation-fvg`,
  invitationCodeFVG: `${base}/invitation-code-fvg`,
  invitationBK2: `${base}/invitation-bk2`,
  invitationCodeBK2: `${base}/invitation-code-bk2`,
  invitationCodeBPlaza: `${base}/invitation-code-banco-plaza`,
  invitationSVCOTMED: `${base}/invitation-svcotmed`,
  invitationCodeSVCOTMED: `${base}/invitation-code-svcotmed`,
  stripePayBooking: `${base}/view/stripe`,
  invitationFITGMAES: `${base}/invitation-fit-games`,
  advancements: `${base}/advancements`,
  stripeLink: `${base}/view/stripe-link`,
  getReservation: `${base}/booking`,
  seatMap: `${base}/seatmap`,
  seatAvailability: `${base}/seatavail`,
  modifyBooking: `${base}/modifybooking`,
  bancaAmigaTDCBss: `${base}/banca-amiga-bss-v2`,
  installmentsPreview: `${base}/installments/preview`,
  installmentsPrice: `${base}/installment/price`,
  paymentInstallmentsBAPM: `${base}/installments/bapm`,
  paymentInstallmentsStripe: `${base}/view/stripe-installment`,
  paymentInstallmentsLukapay: `${base}/view/luka-pay-installment`,
  paymentInstallmentsBTPM: `${base}/installments/btpm`,
  loginAP: `${baseAP}/users/login`,
  userInfo: `${baseAP}/public-passenger`,
  dolarToMiles: `${baseAP}/dolar-to-miles`,
  milesPrice: `${baseAP}/redemptions/reservation-price`,
  addRedemption: `${baseAP}/redemptions/add-avior`,
  addresses: `${baseAP}/users/addresses`,
  updateAVP: `${baseAP}/users/update/aviorplus`,
};

export default urls;
export { base };
