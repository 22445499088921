import React from "react";
import { Grid, Button, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { FormatCurrency, findTaxAmount } from "../helpers";
//import { useHistory } from "react-router-dom";
//import { CostResume } from ".";
const useStyles = makeStyles((theme) => ({
  grid: {
    marginTop: "15px",
  },
  details: {
    cursor: "pointer",
    textDecoration: "underline",
    fontSize: "0.8rem",
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "15px",
  },
  [theme.breakpoints.down("md")]: {
    font: {
      //background: "#891C56",
      fontSize: "0.9rem",
    },
  },
}));
export default function SelectedFlightsResumen(props) {
  const classes = useStyles();
  //let history = useHistory();
  function sendData() {
    const segments = [];
    const originCabinType = props.originFlight.fare.classCode;
    props.originFlight.segments.forEach((segment) => {
      segments.push({
        cabinType: originCabinType,
        flightNumber: segment.flightNumber,
        departureInfo: segment.departureInfo,
        arrivalInfo: segment.arrivalInfo,
      });
    });
    if (props.isRoundTrip) {
      const returnCabinType = props.returnFlight.fare.classCode;
      props.returnFlight.segments.forEach((segment) => {
        segments.push({
          cabinType: returnCabinType,
          flightNumber: segment.flightNumber,
          departureInfo: segment.departureInfo,
          arrivalInfo: segment.arrivalInfo,
        });
      });
    }
    const searchParams = props.searchParams;
    const data = {
      countAdults: searchParams.countAdults,
      countChildrens: searchParams.countChildrens,
      countBabies: searchParams.countBabies,
      segments,
      params: {
        isRoundTrip: props.isRoundTrip,
        originFlight: props.originFlight,
        returnFlight: props.returnFlight,
        searchParams: props.searchParams,
      },
    };
    //history.push(`/passengers`, data);
    props.sendData(data);
  }
  const totalAmount =
    parseFloat(props.originFlight ? props.originFlight.fare.totalAmount : 0) +
    parseFloat(props.returnFlight ? props.returnFlight.fare.totalAmount : 0);
  return (
    <Grid
      container
      justify="center"
      direction="row"
      spacing={1}
      className={classes.grid}
      style={{ margin: "15px 0px" }}
    >
      {/*<CostResume originFlight={props.originFlight} returnFlight={props.returnFlight}/>*/}
      <Grid item xs={11}>
        {props.originFlight &&
          findTaxAmount(props.originFlight.fare.taxes, "6P") !== 0 && (
            <Grid container justify="flex-end" direction="row">
              <Grid item xs={12} md={5}>
                <Typography
                  className={classes.font}
                  variant="h6"
                  display="inline"
                  color="textSecondary"
                >
                  Prueba PCR:{" "}
                </Typography>
                <Typography
                  className={classes.font}
                  variant="h6"
                  display="inline"
                  color="primary"
                >
                  {`${props.searchParams.currency}`}{" "}
                  {FormatCurrency(
                    `${parseFloat(
                      findTaxAmount(props.originFlight.fare.taxes, "6P")
                    ).toFixed(2)}`
                  )}
                </Typography>
              </Grid>
            </Grid>
          )}
        <Grid container justify="flex-end" direction="row">
          <Grid item xs={8} md={3}>
            <Typography
              className={classes.font}
              variant="h6"
              display="inline"
              color="textSecondary"
            >
              Costo Total:{" "}
            </Typography>
            <Typography
              className={classes.font}
              variant="h6"
              display="inline"
              color="primary"
            >
              {`${props.searchParams.currency}`}{" "}
              {FormatCurrency(`${parseFloat(totalAmount).toFixed(2)}`)}
            </Typography>

            {/* <Typography
              color="textSecondary"
              className={classes.details}
              onClick={() => props.open()}
              display="block"
            >
              Ver detalles <img src="./img/icono-detalle.png" alt="" />
            </Typography> */}
          </Grid>
          <Grid item md={2} xs={4}>
            <Button
              disabled={
                !(
                  !props.isRoundTrip ||
                  (props.isRoundTrip &&
                    props.originFlight &&
                    props.returnFlight)
                )
              }
              variant="contained"
              color="primary"
              onClick={sendData}
              id="buttonReservar"
            >
              Reservar
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
