import moment from "moment";

export default function getDatesBeforeAfter(date, notIncludeDays) {
  const dates = [];
  let daysAdded = 0;
  let index = 1;
  while (true) {
    let dateAdd = moment(date);
    dateAdd.subtract(index++, "day");
    if (
      moment()
        .subtract(1, "day")
        .isSameOrAfter(dateAdd)
    ) {
      dates.push({
        value: dateAdd.format("YYYY-MM-DD"),
        disabled: true
      });
    } else if (
      notIncludeDays.find(day => day === dateAdd.day()) === undefined
    ) {
      dates.push({
        value: dateAdd.format("YYYY-MM-DD"),
        disabled: false
      });
    } else {
      dates.push({
        value: dateAdd.format("YYYY-MM-DD"),
        disabled: true
      });
    }
    daysAdded++;
    if (daysAdded >= 3) {
      break;
    }
  }
  dates.push({
    value: date,
    disabled: false
  });
  daysAdded = 0;
  index = 1;
  while (true) {
    let dateAdd = moment(date);
    dateAdd.add(index++, "day");
    if (notIncludeDays.find(day => day === dateAdd.day()) === undefined) {
      dates.push({
        value: dateAdd.format("YYYY-MM-DD"),
        disabled: false
      });
    } else {
      dates.push({
        value: dateAdd.format("YYYY-MM-DD"),
        disabled: true
      });
    }
    daysAdded++;
    if (daysAdded >= 3) {
      break;
    }
  }
  dates.sort(compare);
  return dates;
}

function compare(a,b){
    if(a.value<b.value){
        return -1;
    }else if(a.value>b.value){
        return 1;
    }
    return 0
}
/*import moment from "moment";

export default function getDatesBeforeAfter(date, notIncludeDays){
    const dates=[]
    dates.push(date)
    let addDaysAfter=3;
    let daysAdded = 0
    let index = 1;
    while( true ){
        let dateAdd = moment(date);
        dateAdd.subtract(index++,'day');
        if(moment().subtract(1,'day').isSameOrAfter(dateAdd)){
            break;
        }
        if(notIncludeDays.find(day=>day===dateAdd.day())===undefined){
            addDaysAfter--
            daysAdded++
            dates.push(dateAdd.format("YYYY-MM-DD"))
        }
        if(daysAdded>=3){
            break;
        }
    }
    daysAdded = 0
    index = 1;
    while( true ){
        let dateAdd = moment(date);
        dateAdd.add(index++,'day');
        if(notIncludeDays.find(day=>day===dateAdd.day())===undefined){
            daysAdded++
            dates.push(dateAdd.format("YYYY-MM-DD"))
        }
        if(daysAdded>=3+addDaysAfter){
            break;
        }
    }
    dates.sort();
    return dates;
}*/
