import React, { useState, useEffect } from "react";
import {
  CustomerDataForm,
  Title
} from "../components";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
    /*Screens styles*/
    titleRow: {
      display: "flex",
      borderWidth: 3,
      borderColor: "black",
      marginBottom: 50,
    },
    title: {
      color: "gray",
      margin: 0,
    },
    titleColor: {
      color: "black",
    },
  
    checkText: {
      fontSize: "14pt",
      color: "#555555",
      marginTop: 0,
    },
    buttonContainer: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
    },
  
    /*Components Shared styles*/
    container: {
      marginBottom: 20,
    },
    headerFormContainerLeft: {
      borderWidth: "1pt",
      borderStyle: "solid",
      borderColor: "#cccccc",
      borderRadius: "unset",
      borderRightWidth: 0,
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      height: "100%",
    },
    headerFormContainerRight: {
      borderWidth: "1pt",
      borderStyle: "solid",
      borderColor: "#cccccc",
      borderRadius: "unset",
      borderLeftWidth: 0,
      height: "100%",
    },
  
    buttonLabel: {
      margin: 0,
      color: "#cd171a",
      fontSize: "15pt",
    },
    aceptButtonLabel: {
      color: "#ffffff",
      fontSize: "14pt",
      fontWeight: "bold",
    },
    floatingLabelFocusStyle: {
      color: "#cd171a",
      fontSize: "15pt",
      width: "max-content",
    },
    inputDiv: {
      borderWidth: "1pt",
      borderStyle: "solid",
      borderColor: "#cccccc",
      padding: "10px",
      borderRadius: "5px",
      "& .MuiFormControl-root": {
        width: "100%",
      },
    },
    parent: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    inputLabel: {
      fontSize: "14pt",
      paddingBottom: 0,
      color: "#555555!important",
      height: "20px",
    },
    underline: {
      "&::before": {
        display: "none",
      },
      "&::after": {
        display: "none",
      },
    },
    button: {
      height: 50,
      justifyContent: "center",
      display: "flex",
      alignItems: "center",
    },
    divWord: {
      width: "100%",
      justifyContent: "flex-start",
      height: "100%",
      alignItems: "center",
      display: "flex",
    },
    divIcon: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      height: "100%",
    },
    p: {
      fontSize: "16pt",
      color: "#848484",
      lineHeight: "16pt",
    },
    [theme.breakpoints.up("xs")]: {
      inputGrid: {
        marginRight: 0,
        marginBottom: "15px",
      },
      allFieldsText: {
        color: "#555555"
      },
      Grid: {
        display: "flex",
        justifyContent: "space-between",
      },
      gridContainer: {
        display: "flex",
        justifyContent: "center",
        borderWidth: "1pt",
        borderStyle: "solid",
        borderRadius: "unset",
        borderTopWidth: 0,
        borderColor: "#cccccc",
        padding: "20px",
      },
      checked1: {
        display: "flex",
      },
      checked2: {
        display: "flex",
      },
      
    },
    [theme.breakpoints.down("md")]: {
      buttonLabel:{
        fontSize:"0.9rem"
      },
      floatingLabelFocusStyle:{
        fontSize:"0.9rem"
      },
      inputLabel:{
        fontSize:"0.8rem"
      },
      aceptButtonLabel:{
        fontSize:"0.9rem"
      },
      
    },
    /*Medias querys*/
    [theme.breakpoints.up("md")]: {
      inputGrid: {
        marginBottom: "15px",
      },
      Grid: {
        display: "flex",
        justifyContent: "space-between",
      },
      gridContainer: {
        display: "flex",
        justifyContent: "center",
        paddingLeft: 20,
        paddingRight: 20,
      },
      allFieldsText: {
        display: "flex",
        justifyContent: "flex-end",
        height: "100%",
        alignItems: "flex-end",
        color: "#555555",
        fontSize: "12pt"
      },
      buttonLabel: {
        fontSize: "15pt",
      },
      checkeds: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
      checked1: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRightWidth: "1px",
        borderRightStyle: "solid",
        borderRightColor: "#cccccc",
        paddingRight: "10px",
      },
      checked2: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        paddingLeft: "10px",
      },
    },
  }));
  
export default function CustomerData(props) {
  const [minAdultDate, setMinAdultDate] = useState("");
  const [maxAdultDate, setMaxAdultDate] = useState("");
  //const [adult, setAdult] = useState(false);

  const classes = useStyles();
  let structurePassengers = [];
  structurePassengers.push({
    id: "1",
    name: "Pasajero",
    passengerType: "ADT",
  });
  useEffect(()=>{
    setMinAdultDate(new Date("1920/01/02"));
    const date = new Date();
    date.setMonth(date.getMonth() - 12 * 12);
    setMaxAdultDate(
      new Date(
        date.getFullYear() +
          "/" +
          (date.getMonth() + 1) +
          "/" +
          date.getDate()
      )
    );
  },[])
  return (
    <div>
      <Title
        h2={"INFORMACIÓN DEL"}
        strong={"PASAJERO"}
        p={
          "Ingresa los datos al igual como aparecen registrados en el pasaporte o documento de identificación de los pasajeros."
        }
      />
      {structurePassengers.map((passenger) => (
        <CustomerDataForm
          key={passenger.id}
          index={passenger.id}
          type={passenger.passengerType}
          addPassenger={(data)=>props.sendData(data)}
          passenger={props.passenger}
          classes={classes}
          maxDate={maxAdultDate}
          minDate={minAdultDate}
          passengersCount={structurePassengers.length}
        />
      ))}

      {/*<Grid container justify="flex-end">
        <div style={{ width: "158px", height: "41px" }}>
          <ButtonSpinner
            //action={validateForms}
            fullWidth
            text="Continuar"
            style={{ backgroundColor: "#cd0063", height: "100%" }}
            labelClassname={classes.aceptButtonLabel}
          />
        </div>
      </Grid>*/}
    </div>
  );
}
