import React, { useEffect, useState } from "react";
import {
  SearchFlightsHeader,
  Steps,
  Footer,
  Alert,
  FormStep1,
  Spinner,
  ModalPopUp,
  PurchaseDetails,
  TimerClock,
} from "../components";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Grid } from "@material-ui/core";
import { useLocation } from "react-router-dom";
import IdleTimer from "react-idle-timer";
import {
  SearchFlightResult,
  PassengersData,
  PurchaseSummary,
  //PaymentCreditCard,
  SearchFlightResultRoundTrip,
  PaymentZelle,
  CreditCardForm,
  AncillariesStep,
  SelectSeat,
  PaymentBss,
  PaymentBookingCreditCardTuOfertazo,
  PaymentBookingCreditCardBancaAmiga,
  PaymentLukaPay,
  PaymentBAPM,
  PaymentStripe,
  PaymentBTPM,
  PaymentBookingCreditCardBancaAmigaBss,
  PaymentBTPMP2P,
} from ".";
import PaymentMiles from "./payment-miles";
import ModalTimeout from "../components/modal-timeout";

export default function SearchFlightsSteps() {
  let location = useLocation();
  const [step, setStep] = useState(location.state ? 1 : 0);
  //const [step, setStep] = useState(1);
  const useStyles = makeStyles((theme) => ({
    paper: {
      //display: "flex",
      //flexDirection: "column",
      //alignItems: "center",
      width: "100%",
    },
    container: {
      paddingTop: theme.spacing(12),
      paddingBottom: theme.spacing(4),
    },
    bottom: {
      paddingBottom: theme.spacing(4),
    },
    [theme.breakpoints.down("md")]: {
      top: {
        //paddingTop: step === 0 ? theme.spacing(8) : theme.spacing(10),
        paddingBottom: theme.spacing(2),
      },
    },
    [theme.breakpoints.up("md")]: {
      top: {
        //paddingTop: theme.spacing(17),
        //paddingTop: step === 0 ? theme.spacing(9) : theme.spacing(19),
        paddingBottom: theme.spacing(2),
      },
    },
  }));

  const classes = useStyles();
  const [originRoute, setOriginRoute] = useState({});
  const [returnRoute, setReturnRoute] = useState({});
  const [searchParams, setSearchParams] = useState({});
  const [resultsFlights, setResultsFlights] = useState({});
  const [resultsAvailability, setResultsAvailability] = useState(false);
  const [notResult, setNotResult] = useState(false);
  const [originFlight, setOriginFlight] = useState(null);
  const [returnFlight, setReturnFlight] = useState(null);
  const [open, setOpen] = useState(false);
  const [selectFlightsData, setSelectFlightsData] = useState({});
  const [openAlert, setOpenAlert] = useState(false);
  const [passengers, setPassengers] = useState([]);
  const [method, setMethod] = useState(null);
  const [pnr, setPnr] = useState("");
  const [terms, setTerms] = useState([]);
  const [userData, setUserData] = useState(null);
  const [installments, setInstallments] = useState(false);

  const timeout = 5000 * 60;
  const [showModal, setShowModal] = useState(false);
  const [isTimedOut, setIsTimeOut] = useState(false);

  const timeLimit = 15 * 60; // 15 minutes
  const [timeLeft, setTimeLeft] = useState(timeLimit);
  const [showModalTimeout, setShowModalTimeout] = useState(false);

  const [modalToHome, setModalToHome] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedAncillaries, setSelectedAncillaries] = useState({
    going: [],
    goBack: [],
  });
  const [selectedSeats, setSelectedSeats] = useState({
    going: [],
    goBack: [],
  });
  const [tourCodeType, setTourCodeType] = useState(null);

  const [contactInfo, setContactInfo] = useState(null);

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <Grid container justify="center">
            <Grid item md={6} xs={12}>
              <FormStep1
                originFlight={originFlight}
                returnFlight={returnFlight}
                searchParams={searchParams}
                setTerms={(terms) => setTerms(terms)}
                setResultsAvailability={(resp) => setResultsAvailability(resp)}
                setSearchParams={(resp) => setSearchParams(resp)}
                setOriginRoute={(resp) => setOriginRoute(resp)}
                setReturnRoute={(resp) => setReturnRoute(resp)}
                setResultsFlights={(resp) => setResultsFlights(resp)}
                setNotResult={(resp) => setNotResult(resp)}
                setStep={() => {
                  setStep(1);
                }}
              />
            </Grid>
          </Grid>
        );
      case 1:
        return searchParams.isRoundTrip ? (
          <SearchFlightResultRoundTrip
            resultsAvailability={resultsAvailability}
            originRoute={originRoute}
            terms={terms}
            resultsFlights={resultsFlights}
            setOriginFlight={(resp) => {
              setOriginFlight(resp);
            }}
            searchParams={searchParams}
            setResultsAvailability={(resp) => setResultsAvailability(resp)}
            returnRoute={returnRoute}
            setReturnFlight={(resp) => {
              setReturnFlight(resp);
            }}
            notResult={notResult}
            open={() => setOpen(true)}
            originFlight={originFlight}
            returnFlight={returnFlight}
            sendData={(data) => {
              setPassengers([]);
              setSelectFlightsData(data);
              window.scrollTo(0, 0);
              setStep(2);
            }}
            resetOrigin={() => setOriginFlight(null)}
            resetReturn={() => setReturnFlight(null)}
          />
        ) : (
          <SearchFlightResult
            resultsAvailability={resultsAvailability}
            originRoute={originRoute}
            terms={terms}
            resultsFlights={resultsFlights}
            setOriginFlight={(resp) => setOriginFlight(resp)}
            searchParams={searchParams}
            setResultsAvailability={(resp) => setResultsAvailability(resp)}
            returnRoute={returnRoute}
            setReturnFlight={(resp) => setReturnFlight(resp)}
            notResult={notResult}
            open={() => setOpen(true)}
            originFlight={originFlight}
            returnFlight={returnFlight}
            sendData={(data) => {
              setPassengers([]);
              setSelectFlightsData(data);
              window.scrollTo(0, 0);
              setStep(2);
            }}
            resetOrigin={() => setOriginFlight(null)}
            resetReturn={() => setReturnFlight(null)}
          />
        );
      case 2:
        return (
          <PassengersData
            countAdults={selectFlightsData.countAdults}
            countChildrens={selectFlightsData.countChildrens}
            countBabies={selectFlightsData.countBabies}
            searchParams={searchParams}
            passengers={passengers}
            userData={userData}
            setUserData={setUserData}
            goBack={() => {
              setStep(1);
              window.scrollTo(0, 0);
            }}
            setTourCodeType={(type) => {
              setTourCodeType(type);
            }}
            sendData={async ({ passengersInfo, contactData }) => {
              setPassengers(passengersInfo);
              setContactInfo(contactData);
              setStep(3);
              window.scrollTo(0, 0);
            }}
          />
        );
      case 3:
        return (
          <AncillariesStep
            originFlight={originFlight}
            returnFlight={returnFlight}
            isRoundTrip={selectFlightsData.params.isRoundTrip}
            passengers={passengers}
            contactInfo={contactInfo}
            selectedAncillaries={selectedAncillaries}
            setSelectedAncillaries={(data) => {
              setSelectedAncillaries(data);
            }}
            goBack={() => {
              setStep(2);
              window.scrollTo(0, 0);
            }}
            sendData={(pnr) => {
              setPnr(pnr);
              setStep(4);
            }}
            searchParams={searchParams}
            currency={searchParams.currency}
          />
        );
      case 4:
        return (
          <SelectSeat
            goNext={() => {
              setStep(5);
            }}
            pnr={pnr}
            lastname={passengers[0].lastName}
            selectedSeats={selectedSeats}
            setSelectedSeats={setSelectedSeats}
            selectedAncillaries={selectedAncillaries}
            setSelectedAncillaries={setSelectedAncillaries}
            originFlight={originFlight}
            returnFlight={returnFlight}
          />
        );
      case 5:
        return (
          <PurchaseSummary
            isRoundTrip={selectFlightsData.params.isRoundTrip}
            originFlight={originFlight}
            returnFlight={returnFlight}
            currency={searchParams.currency}
            passengers={passengers}
            pnr={pnr}
            searchParams={searchParams}
            segments={selectFlightsData.segments}
            userData={userData}
            setUserData={setUserData}
            setInstallments={setInstallments}
            goBack={() => {
              setStep(4);
              window.scrollTo(0, 0);
            }}
            tourCodeType={tourCodeType}
            ancillaries={selectedAncillaries}
            selectedSeats={selectedSeats}
            contactInfo={contactInfo}
            sendData={(data) => {
              setMethod(data.method);
              setStep(6);
              window.scrollTo(0, 0);
            }}
          />
        );
      case 6:
        if (method === "TDC") {
          return (
            <CreditCardForm
              pnr={pnr}
              currency={searchParams.currency}
              originFlight={originFlight}
              returnFlight={returnFlight}
              ancillaries={selectedAncillaries}
              selectedSeats={selectedSeats}
              goBack={() => {
                setStep(5);
                window.scrollTo(0, 0);
              }}
            />
          );
        } else if (method === "ba_tdc_bss") {
          return (
            <PaymentBookingCreditCardBancaAmigaBss
              pnr={pnr}
              currency={searchParams.currency}
              originFlight={originFlight}
              returnFlight={returnFlight}
              ancillaries={selectedAncillaries}
              selectedSeats={selectedSeats}
              goBack={() => {
                setStep(5);
                window.scrollTo(0, 0);
              }}
            />
          );
        } else if (method === "ba_tdc") {
          return (
            <PaymentBookingCreditCardBancaAmiga
              pnr={pnr}
              currency={searchParams.currency}
              originFlight={originFlight}
              returnFlight={returnFlight}
              ancillaries={selectedAncillaries}
              selectedSeats={selectedSeats}
              goBack={() => {
                setStep(5);
                window.scrollTo(0, 0);
              }}
            />
          );
        } else if (method === "nodus") {
          return (
            <PaymentBookingCreditCardTuOfertazo
              pnr={pnr}
              currency={searchParams.currency}
              originFlight={originFlight}
              returnFlight={returnFlight}
              ancillaries={selectedAncillaries}
              selectedSeats={selectedSeats}
              goBack={() => {
                setStep(5);
                window.scrollTo(0, 0);
              }}
            />
          );
        } else if (method === "BSS") {
          return (
            <PaymentBss
              pnr={pnr}
              currency={searchParams.currency}
              originFlight={originFlight}
              returnFlight={returnFlight}
              ancillaries={selectedAncillaries}
              selectedSeats={selectedSeats}
              goBack={() => {
                setStep(5);
                window.scrollTo(0, 0);
              }}
            />
          );
        } else if (
          method === "luka_tdc" ||
          method === "luka_zelle" ||
          method === "luka_pm" ||
          method === "luka_banesco"
        ) {
          return (
            <PaymentLukaPay
              method={method}
              pnr={pnr}
              email={userData ? userData.user_info.email : passengers[0]?.email}
              user={userData ? userData.user_info.user_id : null}
              installments={installments}
              currency={searchParams.currency}
              originFlight={originFlight}
              returnFlight={returnFlight}
              ancillaries={selectedAncillaries}
              selectedSeats={selectedSeats}
              passengers={passengers}
              isRoundTrip={selectFlightsData.params.isRoundTrip}
              segments={selectFlightsData.segments}
              goBack={() => {
                setStep(5);
                window.scrollTo(0, 0);
              }}
            />
          );
        } else if (method === "bapm")
          return (
            <PaymentBAPM
              pnr={pnr}
              currency={searchParams.currency}
              originFlight={originFlight}
              returnFlight={returnFlight}
              ancillaries={selectedAncillaries}
              selectedSeats={selectedSeats}
              userData={userData}
              installments={installments}
              passengers={passengers}
              isRoundTrip={selectFlightsData.params.isRoundTrip}
              segments={selectFlightsData.segments}
              goBack={() => {
                setStep(5);
                window.scrollTo(0, 0);
              }}
            />
          );
        else if (method === "btpm")
          return (
            <PaymentBTPM
              pnr={pnr}
              currency={searchParams.currency}
              originFlight={originFlight}
              returnFlight={returnFlight}
              ancillaries={selectedAncillaries}
              selectedSeats={selectedSeats}
              //userData={userData}
              //installments={installments}
              goBack={() => {
                setStep(5);
                window.scrollTo(0, 0);
              }}
            />
          );
        else if (method === "btp2p")
          return (
            <PaymentBTPMP2P
              pnr={pnr}
              currency={searchParams.currency}
              originFlight={originFlight}
              returnFlight={returnFlight}
              ancillaries={selectedAncillaries}
              selectedSeats={selectedSeats}
              userData={userData}
              installments={installments}
              goBack={() => {
                setStep(5);
                window.scrollTo(0, 0);
              }}
            />
          );
        else if (method === "stripe")
          return (
            <PaymentStripe
              pnr={pnr}
              name={
                userData
                  ? `${userData.user_info.first_name} ${userData.user_info.last_name}`
                  : `${passengers[0]?.name} ${passengers[0]?.lastName}`
              }
              email={userData ? userData.user_info.email : passengers[0]?.email}
              user={userData ? userData.user_info.user_id : null}
              installments={installments}
              currency={searchParams.currency}
              originFlight={originFlight}
              returnFlight={returnFlight}
              ancillaries={selectedAncillaries}
              selectedSeats={selectedSeats}
              passengers={passengers}
              isRoundTrip={selectFlightsData.params.isRoundTrip}
              segments={selectFlightsData.segments}
              goBack={() => {
                setStep(5);
                window.scrollTo(0, 0);
              }}
            />
          );
        else if (method === "miles")
          return (
            <PaymentMiles
              userData={userData}
              setUserData={setUserData}
              pnr={pnr}
              ancillaries={selectedAncillaries}
              goBack={() => {
                setStep(5);
                window.scrollTo(0, 0);
              }}
            />
          );
        else
          return (
            <PaymentZelle
              pnr={pnr}
              currency={searchParams.currency}
              originFlight={originFlight}
              returnFlight={returnFlight}
              ancillaries={selectedAncillaries}
              selectedSeats={selectedSeats}
              goBack={() => {
                setStep(5);
                window.scrollTo(0, 0);
              }}
            />
          );

      default:
        throw new Error("Unknown step");
    }
  }

  function getActiveStep(step) {
    return step;
  }

  function reset() {
    setPassengers([]);
    setOriginFlight(null);
    setReturnFlight(null);
    setMethod("");
    setPnr("");
  }

  const _onAction = (e) => {
    setIsTimeOut(false);
  };

  const _onActive = (e) => {
    setIsTimeOut(false);
  };

  const _onIdle = (e) => {
    if (isTimedOut) {
    } else {
      setShowModal(true);
      setModalToHome(false);
      setIsTimeOut(true);
    }
  };

  const modalCheckFlight = () => {
    let obj = {
      origin: searchParams.origin,
      destination: searchParams.destination,
      departure_date: searchParams.selectDateDeparture,
      return_date: searchParams.selectDateArrival,
      isRoundTrip: searchParams.isRoundTrip,
      currency: searchParams.currency,
      passengerType: [
        {
          type: "ADT",
          quantity: searchParams.countAdults,
        },
        {
          type: "CHD",
          quantity: searchParams.countChildrens,
        },
        {
          type: "INF",
          quantity: searchParams.countBabies,
        },
      ],
    };
    let encoded = btoa(JSON.stringify(obj));
    window.location.replace(
      `https://booking.aviorair.com/availability?payload=${encoded}`
    );
    //window.location.replace(`http://localhost:3000/availability?payload=${encoded}`);
    setShowModal(false);
  };

  const modalInit = () => {
    reset();
    setStep(0);
    setShowModal(false);
  };

  useEffect(() => {
    if (timeLeft === 0) {
      setShowModalTimeout(true);
    }
  }, [timeLeft]);

  useEffect(() => {
    if (step === 5) {
      setTimeLeft(timeLimit);
    }
  }, [step, timeLimit]);

  return (
    <div className={classes.paper}>
      <Alert open={openAlert} close={() => setOpenAlert(false)} />
      {step !== 0 ? (
        <IdleTimer
          //ref={ref => { this.idleTimer = ref }}
          element={document}
          onActive={_onActive}
          onIdle={_onIdle}
          onAction={_onAction}
          debounce={250}
          timeout={timeout}
        />
      ) : null}
      {
        <SearchFlightsHeader
          originFlight={originFlight}
          returnFlight={returnFlight}
          searchParams={searchParams}
          step={step}
          setModalToHome={(isOpen) => setModalToHome(isOpen)}
          setLoading={(load) => setLoading(load)}
          setTerms={(terms) => setTerms(terms)}
          notResult={notResult}
          setResultsAvailability={(resp) => setResultsAvailability(resp)}
          setSearchParams={(resp) => setSearchParams(resp)}
          setOriginRoute={(resp) => setOriginRoute(resp)}
          setReturnRoute={(resp) => setReturnRoute(resp)}
          setResultsFlights={(resp) => setResultsFlights(resp)}
          setNotResult={(resp) => setNotResult(resp)}
          setStep={() => {
            setStep(1);
            reset();
          }}
          open={open}
          close={() => setOpen(false)}
          reset={() => reset()}
          selectedAncillaries={selectedAncillaries}
          setSelectedAncillaries={setSelectedAncillaries}
          selectedSeats={selectedSeats}
          setSelectedSeats={setSelectedSeats}
        />
      }

      <Container
        maxWidth={false}
        className={classes.bottom}
        style={{ padding: 0 }}
      >
        <Grid container>
          <Grid item md={step >= 1 && step <= 5 ? 9 : 12} xs={12}>
            <div className={classes.top}>
              <Steps active={getActiveStep(step)} />
            </div>

            {step === 6 && (
              <TimerClock timeLeft={timeLeft} setTimeLeft={setTimeLeft} />
            )}

            <div style={{ minHeight: "calc(100vh - 120px)" }}>
              {getStepContent(step)}
            </div>
          </Grid>

          {step >= 1 && step <= 5 ? (
            <Grid item md={3} xs={12} style={{ padding: "5px 10px" }}>
              <PurchaseDetails
                originFlight={originFlight}
                returnFlight={returnFlight}
                searchParams={searchParams}
                step={step}
                open={open}
                close={() => setOpen(false)}
                reset={() => reset()}
                selectedAncillaries={selectedAncillaries}
                selectedSeats={selectedSeats}
              />
            </Grid>
          ) : null}
        </Grid>

        {/*<Precautions/>*/}
      </Container>
      <Footer />
      <ModalPopUp
        open={showModal}
        close={() => setShowModal(false)}
        modalToHome={false}
        modalCheckFlight={modalCheckFlight}
        modalInit={modalInit}
      />
      <ModalPopUp
        open={modalToHome}
        close={() => setModalToHome(false)}
        modalToHome={true}
      />
      <ModalTimeout
        open={showModalTimeout}
        close={() => setShowModalTimeout(false)}
        modalCheckFlight={modalCheckFlight}
        modalInit={modalInit}
      />
      <Spinner loading={loading} />
    </div>
  );
}
