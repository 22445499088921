import React, { useState } from "react";
import {
  //FligthResume,
  ButtonSpinner,
  Title,
  //PaymentMethods,
  PaymentMethodsNew,
  Toast,
  Spinner,
} from "../components";
import {
  Container,
  Grid,
  Typography,
  //Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { reservationServices } from "../services";
//import {
//findTaxAmount,
//  FormatCurrency,
//} from "../helpers";
import {
  //AirFaresResume,
  //TaxesAndSurcharges,
  //PCRSurcharge,
  ModalReservation,
  //AncillariesAmount,
  //AncillariesSelectedDetails,
  //SeatsAmount,
  PassengersSummary,
} from "../components";
import { useHistory } from "react-router-dom";

export default function PurchaseSummary(props) {
  const classes = useStyles();

  let history = useHistory();

  const [loading, setLoading] = useState(false);
  const [methodSelect, setMethodSelect] = useState(null);
  const [toast, setToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const totalAmount =
    parseFloat(props.originFlight ? props.originFlight.fare.totalAmount : 0) +
    parseFloat(props.returnFlight ? props.returnFlight.fare.totalAmount : 0);

  const isZero = totalAmount === 0;

  const selectMethod = async (method) => {
    setMethodSelect(method);
  };

  /* const getAmountAmount = () => {
    return parseFloat(
      props.ancillaries.going.reduce(function (a, b) {
        return parseFloat(a) + parseFloat(b.total);
      }, 0) +
        props.ancillaries.goBack.reduce(function (a, b) {
          return parseFloat(a) + parseFloat(b.total);
        }, 0)
    ).toFixed(2);
  };

  const totalAncillaries = getAmountAmount();

  const getAmountSeats = () => {
    return parseFloat(
      props.selectedSeats.going.reduce(function (a, b) {
        return parseFloat(a) + parseFloat(b.total);
      }, 0) +
        props.selectedSeats.goBack.reduce(function (a, b) {
          return parseFloat(a) + parseFloat(b.total);
        }, 0)
    ).toFixed(2);
  };

  const totalSeats = getAmountSeats(); */

  const issueTickets = async () => {
    setOpenModal(false);
    let passengers = props.passengers;
    for (let passenger of passengers) delete passenger.id;
    //console.log("originFlight", props.originFlight)
    //console.log("returnFlight", props.returnFlight)

    let going = props.originFlight.segments.map((segment) => ({
      cabinType: props.originFlight.fare.classCode,
      flightNumber: segment.flightNumber,
      departureInfo: {
        dateTime: segment.departureInfo.dateTime,
        locationCode: segment.departureInfo.locationCode,
        cityName: segment.departureInfo.cityName,
        airPortNam: segment.departureInfo.airPortName,
      },
      arrivalInfo: {
        dateTime: segment.arrivalInfo.dateTime,
        locationCode: segment.arrivalInfo.locationCode,
        cityName: segment.arrivalInfo.cityName,
        airPortName: segment.arrivalInfo.airPortName,
      },
    }));

    let segments = { going: going, passengers: passengers };
    if (props.isRoundTrip) {
      let goBack = props.returnFlight.segments.map((segment) => ({
        cabinType: props.returnFlight.fare.classCode,
        flightNumber: segment.flightNumber,
        departureInfo: {
          dateTime: segment.departureInfo.dateTime,
          locationCode: segment.departureInfo.locationCode,
          cityName: segment.departureInfo.cityName,
          airPortNam: segment.departureInfo.airPortName,
        },
        arrivalInfo: {
          dateTime: segment.arrivalInfo.dateTime,
          locationCode: segment.arrivalInfo.locationCode,
          cityName: segment.arrivalInfo.cityName,
          airPortName: segment.arrivalInfo.airPortName,
        },
      }));
      segments.goBack = goBack;
    } else {
      segments.goBack = [];
    }
    let ancillaries = {
      going: [],
      goBack: [],
    };
    props.ancillaries.going.forEach((ancillary) => {
      for (let i = 0; i < segments.going.length; i++) {
        ancillaries.going.push({
          ...ancillary,
          segmentRPH: `${i + 1}`,
        });
      }
    });
    const segmentRPH = segments.goBack.length;
    props.ancillaries.goBack.forEach((ancillary) => {
      for (let i = 0; i < segments.goBack.length; i++) {
        ancillaries.goBack.push({
          ...ancillary,
          segmentRPH: `${segmentRPH + i + 1}`,
        });
      }
    });

    setLoading(true);
    const peticion = await reservationServices.createReservation(
      segments,
      props.currency,
      props.searchParams.tourCode && props.searchParams.tourCode !== ""
        ? props.searchParams.tourCode
        : "",
      true,
      ancillaries
    );
    setLoading(false);
    const resp = await peticion.resp;
    if (peticion.status === 200) {
      history.push(`/thankyoupage`, {
        state: { ...resp, reservationDetails: resp.reservationdetails },
      });
    } else {
      setToast(true);
      setToastMessage(resp.error.message);
      //console.log(resp)
    }
  };

  return (
    <Container component="main" maxWidth="lg">
      <Toast
        message={toastMessage}
        open={toast}
        close={() => {
          setToast(false);
        }}
      />
      <Grid container direction="row" spacing={5}>
        <Grid item md={12} xs={12}>
          <Title
            h2={"RESUMEN DE "}
            strong={"COMPRA"}
            p={"A continuación, encontrarás el detalle de tu selección."}
          />

          <Grid item md={12} xs={12} style={{ marginBottom: 10 }}>
            <Typography color="textSecondary" className={classes.span1}>
              LOCALIZADOR: {props.pnr}
            </Typography>
          </Grid>

          <PassengersSummary classes={classes} passengers={props.passengers} />

          {/* <Grid container justify="center" direction="row" alignItems="center">
            <Grid item md={12} xs={12}>
              <AirFaresResume
                fare={
                  !props.isRoundTrip
                    ? props.originFlight.fare
                    : props.returnFlight.fare
                }
                classes={classes}
                isRoundTrip={props.isRoundTrip}
                currency={props.currency}
              />
            </Grid>

            <Grid item md={12} xs={12}>
              <TaxesAndSurcharges
                flight={
                  !props.isRoundTrip ? props.originFlight : props.returnFlight
                }
                fare={
                  !props.isRoundTrip
                    ? props.originFlight.fare
                    : props.returnFlight.fare
                }
                classes={classes}
                isRoundTrip={props.isRoundTrip}
                currency={props.currency}
              />
            </Grid>

            {!props.isRoundTrip
              ? findTaxAmount(props.originFlight.fare.taxes, "6P") !== 0 && (
                  <Grid item xs={12}>
                    <PCRSurcharge
                      fare={props.originFlight.fare}
                      classes={classes}
                      isRoundTrip={props.isRoundTrip}
                      currency={props.currency}
                    />
                  </Grid>
                )
              : findTaxAmount(props.returnFlight.fare.taxes, "6P") !== 0 && (
                  <Grid item xs={12}>
                    <PCRSurcharge
                      fare={props.returnFlight.fare}
                      classes={classes}
                      isRoundTrip={props.isRoundTrip}
                      currency={props.currency}
                    />
                  </Grid>
                )}

            {(props.ancillaries.going.length > 0 ||
              props.ancillaries.goBack.length > 0) && (
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={12}>
                    <AncillariesSelectedDetails
                      {...props}
                      classes={classes}
                      totalAncillaries={totalAncillaries}
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}

            {(props.selectedSeats.going.length > 0 ||
              props.selectedSeats.goBack.length > 0) && (
              <Grid item xs={12}>
                <SeatsAmount
                  totalSeats={totalSeats}
                  classes={classes}
                  currency={props.currency}
                />
              </Grid>
            )}
          </Grid> */}

          {/* {!isZero && (
            <Grid
              item
              xs={12}
              className={classes.box}
              style={{
                backgroundColor: "#CD171A",
                color: "#fff",
                marginTop: 20,
              }}
            >
              <Grid
                container
                justify="space-between"
                style={{ padding: "5px 10px" }}
              >
                <Grid item xs={6}>
                  <Typography className={classes.span1}>
                    TOTAL A PAGAR:
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Grid container justify="flex-end">
                    <Grid item md={2} xs={2}>
                      <Typography
                        className={classes.span1}
                      >{`${props.currency}`}</Typography>
                    </Grid>
                    <Grid
                      item
                      md={4}
                      xs={4}
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <Typography className={classes.span1}>
                        {FormatCurrency(
                          `${parseFloat(
                            parseFloat(totalAmount) +
                              parseFloat(totalAncillaries) +
                              parseFloat(totalSeats)
                          ).toFixed(2)}`
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )} */}
        </Grid>

        {/* <Grid item md={6} xs={12}>
          <Grid item md={12} xs={12} style={{ marginBottom: "20px" }}>
            <FligthResume
              currency={props.currency}
              flight={props.originFlight}
              direction={"foward"}
              classes={classes}
              isRoundTrip={props.isRoundTrip}
            />
          </Grid>

          {props.isRoundTrip ? (
            <Grid item md={12} xs={12} style={{ marginBottom: "20px" }}>
              <FligthResume
                currency={props.currency}
                flight={props.returnFlight}
                classes={classes}
                isRoundTrip={props.isRoundTrip}
              />
            </Grid>
          ) : null}
        </Grid> */}
      </Grid>

      {!isZero && (
        <PaymentMethodsNew
          classes={classes}
          currency={props.currency}
          pnr={props.pnr}
          selectMethod={selectMethod}
          setInstallments={props.setInstallments}
          userData={props.userData}
          setUserData={(data) => {
            props.setUserData(data);
          }}
        />
      )}

      <Spinner loading={loading} />

      <Grid
        container
        justify={props.pnr ? "flex-end" : "space-between"}
        style={{ marginBottom: 20 }}
      >
        {!props.pnr ? (
          <div style={{ width: "158px", height: "41px" }}>
            <ButtonSpinner
              action={() => {
                props.goBack();
              }}
              fullWidth
              text="Atras"
              color={"primary"}
              style={{ height: "100%" }}
              labelClassname={classes.aceptButtonLabel}
            />
          </div>
        ) : null}
        <div style={{ width: "158px", height: "41px" }}>
          {!isZero ? (
            <ButtonSpinner
              action={() => {
                if (methodSelect) {
                  props.sendData({ method: methodSelect });
                } else {
                  setToast(true);
                  setToastMessage(
                    "Debe seleccionar el método de pago para continuar."
                  );
                }
              }}
              fullWidth
              text="Pagar"
              id="buttonPagar"
              color={"primary"}
              style={{ height: "100%" }}
              labelClassname={classes.payButtonLabel}
            />
          ) : (
            <ButtonSpinner
              action={() => setOpenModal(true)}
              fullWidth
              text="Reservar"
              id="buttonPagar"
              color={"primary"}
              style={{ height: "100%" }}
              labelClassname={classes.payButtonLabel}
            />
          )}
        </div>
      </Grid>

      {openModal && (
        <ModalReservation
          close={() => setOpenModal(false)}
          accept={issueTickets}
        />
      )}
    </Container>
  );
}

const useStyles = makeStyles((theme) => ({
  /*Screens styles*/
  container: {
    width: "100%",
    border: "1pt solid #E5E5E5",
  },
  reserveButtonLabel: {
    color: "#891c56",
    fontSize: "14pt",
    lineHeight: "1.5",
    fontWeight: "bold",
  },
  payButtonLabel: {
    color: "#ffffff",
    fontSize: "14pt",
    lineHeight: "1.5",
    fontWeight: "bold",
  },
  aceptButtonLabel: {
    color: "#ffffff",
    fontSize: "14pt",
    fontWeight: "bold",
  },
  p: {
    fontWeight: "bold",
    color: "#ffffff",
    fontSize: "20pt",
  },
  /*Components Shared styles*/
  p1: {
    fontWeight: "bold",
    fontSize: "15pt",
    color: "#848484",
  },
  span1: {
    fontWeight: "bold",
    fontSize: "1.2rem",
  },
  p2: {
    fontSize: "12pt",
    color: "#848484",
  },
  span2: {
    color: "#848484",
  },
  p3: {
    fontSize: "1.15rem",
    lineHeight: "1.5",
    color: "#848484",
  },
  padding: {
    padding: "10px",
  },
  padding2: {
    padding: "5px 20px",
  },
  box: {
    backgroundColor: "#EAEAEA",
  },
  box1: {
    backgroundColor: "#CD171A",
    border: "1px solid #CD171A",
    color: "#FFF",
  },
  banner: {
    display: "none",
  },
  fondoBanner: {
    width: "100%",
  },
  [theme.breakpoints.down("sm")]: {
    container: {
      width: "100%",
    },
    fondoBanner: {
      display: "none",
    },
    banner: {
      display: "block",
      width: "100%",
    },

    payButtonLabel: {
      fontSize: "0.9rem",
    },
    reserveButtonLabel: {
      fontSize: "0.9rem",
    },
    aceptButtonLabel: {
      fontSize: "0.9rem",
    },
    p: {
      fontSize: "0.9rem",
    },
    p1: {
      fontSize: "0.8rem",
    },
    p2: {
      fontSize: "0.8rem",
    },
    p3: {
      fontSize: "0.8rem",
    },
    span1: {
      fontSize: "0.8rem",
    },
    span2: {
      fontSize: "0.8rem",
    },
    arrow: {
      fontSize: "0.9rem",
    },
  },
}));
