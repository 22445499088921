import React from "react";
import { Grid, InputAdornment, MenuItem } from "@material-ui/core";
import { Select } from ".";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "70%",
  },
  adornment: {
    width: "22px",
  },
  input: {
    "&:before": {
      borderBottom: "1px solid #CD171A",
    },
    "&:after": {
      borderBottom: "2px solid #CD171A",
    },
    "&:focus": {
      borderBottom: "2px solid #CD171A",
    },
    color: "#575756",
    fontSize: "0.8rem",
  },
  label: {
    color: "#575756",
    width: "25%",
    fontSize: "0.8rem",
    fontWeight: "400",
    lineHeight: "1.5",
  },
  container: {
    display: "inline-flex",
    alignItems: "center",
    verticalAlign: "middle",
    width: "100%",
  },
  icon: {
    fill: "#FA7268",
  },
}));
export default function DestinationSelects(props) {
  const classes = useStyles();

  return (
    <Grid container justify="center" direction="row" alignItems="center">
      <Grid item xs={11}>
        <div>
          <div className={classes.container}>
            <span className={classes.label}>Desde</span>
            <Select
              variant="standard"
              adornment={
                <InputAdornment position="start">
                  <img
                    src="./img/icono-desde.png"
                    alt=""
                    className={classes.adornment}
                  />
                </InputAdornment>
              }
              classes={classes.input}
              inputProps={{
                classes: {
                  icon: classes.icon,

                  //input:classes.input
                },
              }}
              helperText={props.originError}
              error={props.originError !== ""}
              value={props.origin}
              onChange={(e) => {
                props.changeAirPort(e.target.value);
              }}
            >
              {props.airports.map((airport, index) => (
                <MenuItem key={index} value={airport.locationCode}>
                  {/*{airport.locationCode}*/}
                  {airport.cityName}
                  {/*{" "}{airport.airportName}*/}
                </MenuItem>
              ))}
            </Select>
          </div>
        </div>
        <div>
          <div className={classes.container}>
            <span className={classes.label}>Hacia</span>
            <Select
              variant="standard"
              adornment={
                <InputAdornment position="start">
                  <img
                    src="./img/icono-hacia.png"
                    alt=""
                    className={classes.adornment}
                  />
                </InputAdornment>
              }
              classes={classes.input}
              inputProps={{
                classes: {
                  icon: classes.icon,
                },
              }}
              helperText={props.destinationError}
              error={props.destinationError !== ""}
              value={props.destination}
              onChange={(e) => {
                props.changeDestination(e.target.value);
              }}
            >
              {props.destinationsDepartures.map((destination, index) => (
                <MenuItem key={index} value={destination.arrival}>
                  {/*{destination.arrival}*/}
                  {destination.arrivalCityName}
                  {/*{" "}{destination.arrivalAirportName}*/}
                </MenuItem>
              ))}
            </Select>
          </div>
        </div>
      </Grid>
    </Grid>
  );
}
