import React from "react";
import {
  Grid,
  Container,
  Typography,
  MenuItem,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useState } from "react";
import { anciliariesServices } from "../services";
import { useEffect } from "react";
import { DeleteOutline } from "@material-ui/icons";
const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 20,
  },
}));
const columns = [
  {
    id: "ancillarie",
    label: "Ancillarie",
    minWidth: 200,
  },
  {
    id: "quantity",
    label: "Cantidad",
    minWidth: 50,
  },
  {
    id: "precio",
    label: "Precio",
    minWidth: 50,
  },
  {
    id: "totalPrecio",
    label: "Precio total",
    minWidth: 50,
  },
];
const quantities = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
const RowProduct = (props) => {
  return (
    <TableRow>
      <TableCell>
        <Typography noWrap>{props.ancillarie.name}</Typography>
      </TableCell>
      <TableCell>
        <TextField
          select
          fullWidth
          variant="outlined"
          label="Cantidad"
          value={props.ancillarie.quantity}
          onChange={(e) => {
            props.addChangeQuantity(e.target.value);
          }}
        >
          {quantities.map((quantity, index) => (
            <MenuItem key={index} value={quantity}>
              {quantity}
            </MenuItem>
          ))}
        </TextField>
      </TableCell>
      <TableCell>
        <Typography noWrap>${props.ancillarie.price}</Typography>
      </TableCell>
      <TableCell>
        <Typography noWrap>
          ${props.ancillarie.price * props.ancillarie.quantity}
        </Typography>
      </TableCell>
      <TableCell>
        <IconButton onClick={props.deleteAncillarie}>
            <DeleteOutline/>
        </IconButton>
      </TableCell>
    </TableRow>
  );
};
export default function AnciliariesGenerate() {
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [descripcion, setDescripcion] = useState("");
  const [products, setProducts] = useState([]);
  const [selectProducts, setSelectProducts] = useState([]);
  const [productSelected, setProductSelected] = useState("");
  const [amount, setAmount] = useState("0.00");
  const getAnciliaries = async () => {
    const { resp, status } = await anciliariesServices.getProducts();
    if (status === 200) {
      setProducts(resp.products);
    }
  };
  useEffect(() => {
    getAnciliaries();
  }, []);
  const sumAmount = (items) => {
    return items.reduce(function (a, b) {
      return a + b.price * b.quantity;
    }, 0);
  };
  const addAncillarie = async (_id) => {
    const ancillarie = products.find((product) => product._id === _id);
    if (ancillarie) {
      const ancillariesAux = [...selectProducts];
      const anciliarieFound = ancillariesAux.findIndex(
        (elem) => elem._id === ancillarie._id
      );
      if (anciliarieFound !== -1) {
        ancillariesAux[anciliarieFound].quantity =
          ancillariesAux[anciliarieFound].quantity + 1;
      } else {
        ancillarie.quantity = 1;
        ancillariesAux.push(ancillarie);
      }
      setSelectProducts(ancillariesAux);
      setAmount(sumAmount(ancillariesAux));
    }
  };
  const changeQuantity = (index, quantity) => {
    const ancillariesAux = [...selectProducts];
    ancillariesAux[index].quantity = quantity;
    setSelectProducts(ancillariesAux);
    setAmount(sumAmount(ancillariesAux));
  };
  const deleteAncillarie = (index)=>{
    const ancillariesAux = [...selectProducts];
    ancillariesAux.splice(index, 1);
    setSelectProducts(ancillariesAux);
    setAmount(sumAmount(ancillariesAux));
  }
  return (
    <Container component="main" className={classes.root}>
      <Grid container justify="center" spacing={2}>
        <Typography variant="h4">Anciliaries</Typography>
        <Grid item xs={12}>
          <Grid container justify="center">
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Email"
                variant="outlined"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container justify="center">
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Descripcion"
                variant="outlined"
                value={descripcion}
                onChange={(e) => setDescripcion(e.target.value)}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container justify="center">
            <Grid item xs={6}>
              <TextField
                select
                fullWidth
                variant="outlined"
                label="Selecciona el anciliarie"
                value={productSelected}
                onChange={(e) => {
                  setProductSelected(e.target.value);
                  addAncillarie(e.target.value);
                }}
              >
                {products.map((product, index) => (
                  <MenuItem key={index} value={product._id}>
                    {product.name} ${product.price}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} style={{ marginTop: "20px" }}>
        <Grid container justify="flex-end">
          <Grid item xs={3}>
            <Typography>Total a pagar: ${amount}</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} style={{ marginTop: "20px" }}>
        <TableContainer className={classes.containerTable}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {selectProducts.map((ancillarie, index) => (
                <RowProduct
                  ancillarie={ancillarie}
                  key={index}
                  addChangeQuantity={(quantity) => {
                    changeQuantity(index, quantity);
                  }}
                  deleteAncillarie={()=>deleteAncillarie(index)}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Container>
  );
}
