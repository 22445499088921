import React, { useState, useEffect } from "react";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  FaresRoundTrip,
  OriginDestination,
  ModalSegmentsInfo,
  FareTerms,
} from "../";
import { getFullDateName } from "../../helpers/";
//import { useEffect } from "react";
const useStyles = makeStyles((theme) => ({
  grid: {
    marginTop: "15px",
  },
  stops: {
    marginTop: "10px",
    color: "#af0061",
    cursor: "pointer",
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
}));
function FlightType(props) {
  return (
    <div style={{ height: "100%" }}>
      <Typography
        color="textSecondary"
        style={{ fontSize: "1.25rem", fontWeight: "bold" }}
      >
        {props.typeFlight === "origin" ? (
          <>
            IDA <img src="../img/icono-ida.png" alt="" />{" "}
          </>
        ) : (
          <>
            REGRESO <img src="./img/icono-regreso.png" alt="" />{" "}
          </>
        )}
        {getFullDateName(props.departure).toUpperCase()}
      </Typography>
    </div>
  );
}
export default function FlightRoundTrip(props) {
  const classes = useStyles();
  const [openModal, setModalOpen] = useState(false);
  const [openFareTerms, setOpenFareTerms] = useState(false);
  const [fareName, setFareName] = useState(
    props.flight.fares[0].fareInfo.className
  );
  //const [fare, setFare] = useState(null);
  //useEffect(()=>{
  //  filterTerms(props.searchParams, props.terms, props.flight);
  //}, [props.searchParams, props.terms, props.flight])
  //
  //async function filterTerms(searchParams, terms, flight ){
  //  if(searchParams.isRoundTrip)
  //  {
  //    const aux=[];
  //    for(let fare of flight.fares)
  //    {
  //      for(let term of terms)
  //      {
  //        if(term.name===fare.fareInfo.className)
  //        {
  //          aux.push(term)
  //        }
  //      }
  //      setTerms(aux);
  //    }
  //  }
  //}
  useEffect(() => {
    setOpenFareTerms(false);
  }, [props.openFareTerms]);
  return (
    <Grid
      container
      justify="center"
      direction="row"
      alignItems="flex-end"
      spacing={1}
      className={classes.grid}
      style={{ margin: "15px auto", maxWidth: 1140 }}
    >
      {/* <Grid item md={1} xs={12}>

      </Grid> */}
      <Grid item md={5} xs={12} style={{ padding: "0px 15px" }}>
        <FlightType
          typeFlight={props.typeFlight}
          departure={props.flight.segments[0].departureInfo.dateTime}
        />
        <OriginDestination
          totalTimeFlight={props.flight.totalTimeFlight}
          segments={props.flight.segments}
        />
      </Grid>
      <Grid item md={7} xs={12}>
        <FaresRoundTrip
          currency={props.searchParams.currency}
          fares={props.flight.fares}
          selectable={props.selectable}
          isSelected={props.isSelected}
          selectFare={(fare) => {
            setFareName(fare.fareInfo.className);
            setOpenFareTerms(true);
            props.selectFlight({
              segments: props.flight.segments,
              fare: fare,
              flightId: props.flight.flightId,
              //totalTimeFlight: props.flight.totalTimeFlight,
            });
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <FareTerms
          fareName={fareName}
          fares={props.flight.fares}
          isRoundTrip={props.searchParams.isRoundTrip}
          terms={props.terms}
          setVisible={() => setOpenFareTerms(!openFareTerms)}
          openFareTerms={openFareTerms}
        />
      </Grid>
      {/*
      {fare ? (
        <Grid item md={11} xs={12}>
          <Grid container justify="flex-end" direction="row">
          <Grid item xs={2}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => {
                
                setFare(null)
              }}
            >
              Seleccionar
            </Button>
          </Grid>
          </Grid>
        </Grid>
            ) : null}*/}

      <ModalSegmentsInfo
        open={openModal}
        close={() => setModalOpen(false)}
        segments={props.flight.segments}
      />
    </Grid>
  );
}
