import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Button } from "@material-ui/core/";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

export default function ButtonSpinner(props) {
  const classes = useStyles();
  return (
    <Button
      variant={props.variant ? props.variant : "contained"}
      color={props.color ? props.color : "primary"}
      style={props.style}
      fullWidth={props.fullWidth}
      className={classes.button}
      disabled={props.loading || props.disabled}
      onClick={props.action}
      type={props.type}
    >
      {props.loading && (
        <CircularProgress size={24} className={classes.buttonProgress} />
      )}

      <div className={props.labelClassname}>{props.text}</div>
    </Button>
  );
}
