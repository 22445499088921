import Modal from "@material-ui/core/Modal";
import React, { useState } from "react";
import { Grid, Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CancelIcon from '@material-ui/icons/Cancel';
export default function ModalTourCodeError(props) {
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);
  function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
  }
  return (
    <Modal open={props.open} onClose={()=>props.close()} disableBackdropClick={true}>
      <Grid
        container
        justify="center"
        style={modalStyle}
        className={classes.paper}
      >
        <Grid item md={12} xs={12} style={{ backgroundColor: "#FFF" }}>
          <Grid
            container
            justify="flex-end"
          >
            <CancelIcon color="primary" style={{cursor:"pointer"}} onClick={()=>props.close()}/>
          </Grid>
          <Grid
            container
            justify="center"
            alignItems="center"
            direction="column"
          >
            <img src="./img/logoWhite.png" alt="" style={{width:"180px"}}/>
          </Grid>
          <br />
          <Typography align="center" className={classes.modalText}>
            {props.message}
          </Typography>
          <br />
          <br />
          <Grid
            container
            justify="center"
            alignItems="center"
            direction="column"
          >
              <Button
                variant="contained"
                color={"primary"}
                onClick={()=>props.close()}
              >
                <label className={classes.aceptButtonLabel}>
                  {"Aceptar"}
                </label>
              </Button>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 400,
    height: 230,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #FFF",
    boxShadow: theme.shadows[5],
    padding: "20px",
    borderRadius: "20px",
    outline: "none",
  },
  paper2: {
    position: "absolute",
    width: 400,
    height: 220,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #FFF",
    boxShadow: theme.shadows[5],
    padding: "20px",
    borderRadius: "20px",
    outline: "none",
  },
  closeButton: {
    position: "absolute",
    zIndex: 10,
    top: "-4%",
  },
  aceptButtonLabel: {
    color: "#ffffff",
    fontSize: "0.8rem",
  },
  h2: {
    color: "#777777",
    fontWeight: "unset",
    fontSize: "1rem",
  },
  modalText: {
    color: "#575756",
    fontWeight: "bold",
    fontSize: "0.8rem",
  },
  p: {
    fontSize: "1.3rem",
    color: "#848484",
  },
  highlightedP: {
    fontSize: "1.5rem",
    color: "#af0061",
  },
  [theme.breakpoints.down("xs")]: {
    closeButton: {
      left: "90%",
    },
  },
  [theme.breakpoints.up("xs")]: {
    closeButton: {
      left: "92.5%",
    },
  },
  [theme.breakpoints.down("sm")]: {
    h2: {
      fontSize: "1.1rem",
    },
    p: {
      fontSize: "1rem",
    },
    highlightedP: {
      fontSize: "1.2rem",
    },
    paper: {
      width: 340,
      height: 210,
    },
  },
}));

/*import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(theme => ({
  close: {
    padding: theme.spacing(0.5),
  },
}));

export default function Toast(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);

  //React.useEffect(() => {
  //    setOpen(props.open); 
  //}, [props.open])

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    props.close()
  };

  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        message={<span id="message-id">{props.message}</span>}
        action={[
          <IconButton
            key="close"
            aria-label="close"
            color="inherit"
            className={classes.close}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>,
        ]}
      />
    </div>
  );
}*/