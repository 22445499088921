import React from "react";
import { Stepper, Step, StepLabel, Typography, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  [theme.breakpoints.down("md")]: {
    stepIcon: {
      fontSize: "1rem",
    },
    step: {
      paddingLeft: "4px",
      paddingRight: "4px",
      flex: 1,
      position: "relative",
      borderLeft: "none !important",
    },
    stepper: {
      background: "#EAEAEA",
      padding: "24px 5px",
      alignItems: "flex-start",
      display: "flex",
      flexDirection: "row",
    },
    stepLabel: {
      flexDirection: "column",
      display: "flex",
      alignItems: "center",
      textAlign: "center",
    },

    label: {
      fontSize: "0.9rem",
      fontWeight: 500
    },
  },

  [theme.breakpoints.up("md")]: {
    stepIcon: {
      fontSize: "2.3rem",
    },
    step: {
      justifyContent: "center",
      borderRight: "1px solid #C8C8C8",
      flex: 1,
      position: "relative",
    },
    stepper: {
      padding: "24px",
      background: "#EAEAEA",
      alignItems: "flex-start",
      display: "flex",
      justifyContent: "center",
      width: "80%",
    },
    stepLabel: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },

    label: {
      fontSize: "1rem",
      fontWeight: 500
    },
  },
  labelContainer: {
    width: "auto !important",
  },
  active: {
    color: "#CD0063 !important",
    fontWeight: "bold !important"
  },
}));
function getSteps() {
  return ["REGISTRAR DATOS","METODO DE PAGO", "PAGAR"];
}
export default function StepsPaymentPromotion(props) {
  const classes = useStyles();
  return (
    <Grid
      container
      justify="center"
      direction="row"
      style={{ background: "#EAEAEA" }}
    >
      <Stepper
        activeStep={props.active}
        connector={null}
        className={classes.stepper}
      >
        {getSteps().map((label, index) => (
          <Step
            className={classes.step}
            style={{ borderLeft: index === 0 ? "1px solid #C8C8C8" : null }}
            key={index}
          >
            <StepLabel
              classes={{
                root: classes.stepLabel,
                labelContainer: classes.labelContainer,
                active: classes.active,
              }}
              icon={
                <Typography color="primary" className={classes.stepIcon}>
                  {index + 1}
                </Typography>
              }
            >
              <p className={classes.label}>{label}</p>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Grid>
  );
}
