export default function getFareClassName(className){
    if(className === "LIGHT"){
        return "Light"
    }
    else if(className === "FLEX"){
        return "Flex"
    }
    else if(className === "MEDIUM"){
        return "Medium"
    }else if(className === "ULTRA"){
        return "Ultra"
    }else{
        return "Ejecutiva"
    }
}