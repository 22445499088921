import urls from "../urls";

const getFareFamiliesConditions = async (isInternational) =>{
    const token = localStorage.getItem("token");
    const request = await fetch(`${urls.fareFamilies}?isInternational=${isInternational}`,{
      method:"GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      }
    });
    const response = await request.json();
    return {
      status: request.status,
      response
    };
}

const updateConditions = async ({id, body})=>{
  const token = localStorage.getItem("token");
  const request = await fetch(`${urls.fareFamilies}/${id}`,{
    method:"PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify(body)
  });
  const response = await request.json();
  return {
    status: request.status,
    response
  };
}

const fareConditionsServices = Object.freeze({
    getFareFamiliesConditions,
    updateConditions
});

export default fareConditionsServices;