import React, { useEffect, useState } from 'react';
import { Box, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, makeStyles, useMediaQuery, useTheme } from "@material-ui/core";
import PassengerMobile from './PassengerMobile';


const useStyles = makeStyles((theme) => ({
  planeBackgroundEconomy: {
    backgroundImage: "url(./img/plane-economy.png)",
    backgroundSize: "cover",
    backgroundPositionY: "center",
  },
  planeBackgroundBusiness: {
    backgroundImage: "url(./img/plane-business.png)",
    backgroundSize: "cover",
    backgroundPositionY: "center",
  },
  containerEconomy:{
    overflowY:"auto",
    height:"500px",
    margin: 0,
    padding: 0,
    listStyle: "none",
    '&::-webkit-scrollbar': {
      width: '0.3rem'
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,.3)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,.3)'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: "#CD171A",
      outline: "1px solid #fff",
      borderRadius: "20px",
      overflowX: "hidden"
    }
  },
  [theme.breakpoints.down("sm")]: {
    planeBackgroundBusiness: {
      backgroundImage: "url(./img/plane-business.png)",
      backgroundSize: "180% 120%",
      backgroundPositionY: "center",
      backgroundPositionX: "center",
    },
    planeBackgroundEconomy: {
      backgroundImage: "url(./img/plane-economy.png)",
      backgroundSize: "190% 130%",
      backgroundPositionY: "center",
      backgroundPositionX: "center",
    },
  },
}));

const columns = [
  {
    id: "full-name",
    label: "Nombre y apellido",
    minWidth: 170,
  },
  {
    id: "seat",
    label: "Asiento",
    minWidth: 170,
  },
  {
    id: "acciones",
    label: "",
    minWidth: 170,
  },
];

export default function PassengersBlock(props) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const classes = useStyles();
  const [activePassenger, setActivePassenger] = useState(null)
  useEffect(() => {
    if (!props.activePassenger) {
      setActivePassenger(null)
    }
  }, [props.activePassenger])
  if (!matches) {
    return (
      props.passengers.map((passenger, index) => (
        <PassengerMobile
          passenger={passenger}
          key={index}
          activePassenger = {props.activePassenger}
          isActivePassenger={activePassenger === index}
          selectPassenger={() => {
            setActivePassenger(index);
            props.setPassenger(passenger);
          }}
        />
      ))
    )
  }

  return (
    <Box margin={1}>
      <TableContainer className={classes.containerTable}>
        <Table aria-label="purchases">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  <Typography align="left" color="secondary">
                    {column.label}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.passengers.map((passenger, index) => (
              <TableRow key={index}>
                <TableCell component="th" scope="row">
                  <Typography align="left" color="secondary">
                    {passenger.name} {passenger.lastName}
                  </Typography>
                </TableCell>
                <TableCell component="th" scope="row">
                  <Typography align="left" color="secondary">
                    {passenger?.seat?.seatNumber
                      ? passenger.seat.seatNumber
                      : "S/A"}
                  </Typography>
                </TableCell>
                <TableCell component="th" scope="row">
                  {activePassenger === index ? (
                    <Button disabled>Seleccionando asiento</Button>
                  ) : (
                    <Button
                      color="primary"
                      variant="contained"
                      disableElevation
                      onClick={() => {
                        setActivePassenger(index);
                        props.setPassenger(passenger);
                      }}
                    >
                      {!passenger.checkedInComplete
                        ? "Iniciar Selección"
                        : "Modificar Selección"}
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}