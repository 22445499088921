import React, { useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
export default function Availability(props) {
  let query = useQuery();
  let history = useHistory();
  useEffect(() => {
    const payload = query.get("payload")
    const data = JSON.parse(atob(payload))
    history.push(`/`,data)
  })
  return <>

  </>;
}
