import React, { useState } from "react";
import {
  StaticDatePicker as MaterialDatePicker,
  LocalizationProvider,
} from "@material-ui/pickers";
import DateFnsUtils from '@material-ui/pickers/adapter/date-fns';
import { Popover, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Moment from "moment";
const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%",
    
  },
  underline: {
    "&::before": {
      display: "none",
    },
    "&::after": {
      display: "none",
    },
  inputLabel: {
    fontSize: "14pt",
    paddingBottom: 0,
    color: "#555555!important",
},
  },
}));
export default function StaticDatePicker(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  function disableDaysOfTheWeekends(date, days) {
    return days.includes(date.getDay());
  }
  return (
    <div style={{ width: "100%" }}>
      <TextField
        className={classes.formControl}
        variant={props.inputVariant ? props.inputVariant : "outlined"}
        label={props.label}
        error={props.error}
        
        onClick={(e)=>{
          if(!props.disabled)
            handleClick(e)
        }}
        onChange={() => {}}
        disabled={props.disabled}
        value={
          props.value !== null && props.value !== ""
            ? Moment(props.value).format("DD-MM-YYYY")
            : ""
        }
        helperText={props.helperText}
        inputProps={props.inputProps}
        InputLabelProps={props.InputLabelProps}
        InputProps={{
            startAdornment:props.adornment,
            ...props.InputProps
        }}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <div style={{ width: "320px" }}>
          <LocalizationProvider dateAdapter={DateFnsUtils}>
            <MaterialDatePicker
              displayStaticWrapperAs="desktop"
              animateYearScrolling
              disablePast={props.disablePast}
              disableFuture={props.disableFuture}
              minDate={props.minDate}
              maxDate={props.maxDate}
              disableToolbar={!props.toolbar}
              autoOk={true}
              variant="static"
              format={props.format ? props.format : "dd-MM-yyyy"}
              shouldDisableDate={(date) =>
                disableDaysOfTheWeekends(
                  date,
                  props.disableDays ? props.disableDays : []
                )
              }
              value={props.value?props.value:props.maxDate}
              onChange={props.onChange ? (date)=>{props.onChange(date);handleClose()} : () => {handleClose()}}
            />
          </LocalizationProvider>
        </div>
      </Popover>
    </div>
  );
}
