import React from "react";
import FaresRoundTrip from "../components/round-trip/fares-round-trip";

export default function Vuelos() {
    const response = {
        "segments": [
            {
                "originDestRPH": "1",
                "flightNumber": "1500",
                "EQP": "737",
                "stops": "0",
                "timeFlight": "01:30",
                "departureInfo": {
                    "departureDate": "2020-10-01T12:15",
                    "locationCode": "CLO",
                    "cityName": "Cali",
                    "airPortName": "Aeropuerto Internacional Alfonso Bonilla Aragón"
                },
                "arrivalInfo": {
                    "arrivalDate": "2020-10-01T13:45",
                    "locationCode": "CTG",
                    "cityName": "Cali",
                    "airPortName": "Aeropuerto Internacional Alfonso Bonilla Aragón"
                }
            },
            {
                "originDestRPH": "1001",
                "flightNumber": "1503",
                "EQP": "737",
                "stops": "0",
                "timeFlight": "01:40",
                "departureInfo": {
                    "departureDate": "2020-10-25T15:00",
                    "locationCode": "CTG",
                    "cityName": "Cartagena",
                    "airPortName": "Aeropuerto Internacional Rafael Núñez"
                },
                "arrivalInfo": {
                    "arrivalDate": "2020-10-25T16:40",
                    "locationCode": "CLO",
                    "cityName": "Cartagena",
                    "airPortName": "Aeropuerto Internacional Rafael Núñez"
                }
            }
        ],
        "rbds": [
            {
                "fareInfo": [
                    {
                        "originDestRPH": "1",
                        "className": "MAXI",
                        "classCode": "K",
                        "cabin": "ECONOMY"
                    },
                    {
                        "originDestRPH": "1001",
                        "className": "MINI",
                        "classCode": "V",
                        "cabin": "ECONOMY"
                    }
                ],
                "fareConstruction": [
                    {
                        "originDestRPH": "1",
                        "componentAmount": "168000.00"
                    },
                    {
                        "originDestRPH": "1001",
                        "componentAmount": "112000.00"
                    }
                ],
                "baseFare": "280000.00",
                "totalTax": "53500.00",
                "totalFees": "0.00",
                "totalFare": "333500.00",
                "currCode": "COP",
                "faresPerPassenger": [
                    {
                        "code": "ADT",
                        "quantity": "1",
                        "baseFare": "280000.00",
                        "totalFare": "333500.00",
                        "taxes": [
                            {
                                "code": "CO",
                                "amount": "39500.00"
                            },
                            {
                                "code": "YS",
                                "amount": "14000.00"
                            }
                        ]
                    }
                ]
            },
            {
                "fareInfo": [
                    {
                        "originDestRPH": "1",
                        "className": "MAXI",
                        "classCode": "K",
                        "cabin": "ECONOMY"
                    },
                    {
                        "originDestRPH": "1001",
                        "className": "MAXI",
                        "classCode": "K",
                        "cabin": "ECONOMY"
                    }
                ],
                "fareConstruction": [
                    {
                        "originDestRPH": "1",
                        "componentAmount": "168000.00"
                    },
                    {
                        "originDestRPH": "1001",
                        "componentAmount": "168000.00"
                    }
                ],
                "baseFare": "336000.00",
                "totalTax": "56300.00",
                "totalFees": "0.00",
                "totalFare": "392300.00",
                "currCode": "COP",
                "faresPerPassenger": [
                    {
                        "code": "ADT",
                        "quantity": "1",
                        "baseFare": "336000.00",
                        "totalFare": "392300.00",
                        "taxes": [
                            {
                                "code": "CO",
                                "amount": "39500.00"
                            },
                            {
                                "code": "YS",
                                "amount": "16800.00"
                            }
                        ]
                    }
                ]
            },
            {
                "fareInfo": [
                    {
                        "originDestRPH": "1",
                        "className": "SUPER",
                        "classCode": "W",
                        "cabin": "ECONOMY"
                    },
                    {
                        "originDestRPH": "1001",
                        "className": "MINI",
                        "classCode": "V",
                        "cabin": "ECONOMY"
                    }
                ],
                "fareConstruction": [
                    {
                        "originDestRPH": "1",
                        "componentAmount": "264700.00"
                    },
                    {
                        "originDestRPH": "1001",
                        "componentAmount": "112000.00"
                    }
                ],
                "baseFare": "376700.00",
                "totalTax": "58340.00",
                "totalFees": "0.00",
                "totalFare": "435040.00",
                "currCode": "COP",
                "faresPerPassenger": [
                    {
                        "code": "ADT",
                        "quantity": "1",
                        "baseFare": "376700.00",
                        "totalFare": "435040.00",
                        "taxes": [
                            {
                                "code": "CO",
                                "amount": "39500.00"
                            },
                            {
                                "code": "YS",
                                "amount": "18840.00"
                            }
                        ]
                    }
                ]
            },
            {
                "fareInfo": [
                    {
                        "originDestRPH": "1",
                        "className": "SUPER",
                        "classCode": "W",
                        "cabin": "ECONOMY"
                    },
                    {
                        "originDestRPH": "1001",
                        "className": "MAXI",
                        "classCode": "K",
                        "cabin": "ECONOMY"
                    }
                ],
                "fareConstruction": [
                    {
                        "originDestRPH": "1",
                        "componentAmount": "264700.00"
                    },
                    {
                        "originDestRPH": "1001",
                        "componentAmount": "168000.00"
                    }
                ],
                "baseFare": "432700.00",
                "totalTax": "61140.00",
                "totalFees": "0.00",
                "totalFare": "493840.00",
                "currCode": "COP",
                "faresPerPassenger": [
                    {
                        "code": "ADT",
                        "quantity": "1",
                        "baseFare": "432700.00",
                        "totalFare": "493840.00",
                        "taxes": [
                            {
                                "code": "CO",
                                "amount": "39500.00"
                            },
                            {
                                "code": "YS",
                                "amount": "21640.00"
                            }
                        ]
                    }
                ]
            },
            {
                "fareInfo": [
                    {
                        "originDestRPH": "1",
                        "className": "MEGA",
                        "classCode": "J",
                        "cabin": "BUSINESS"
                    },
                    {
                        "originDestRPH": "1001",
                        "className": "MINI",
                        "classCode": "V",
                        "cabin": "ECONOMY"
                    }
                ],
                "fareConstruction": [
                    {
                        "originDestRPH": "1",
                        "componentAmount": "369500.00"
                    },
                    {
                        "originDestRPH": "1001",
                        "componentAmount": "112000.00"
                    }
                ],
                "baseFare": "481500.00",
                "totalTax": "63580.00",
                "totalFees": "0.00",
                "totalFare": "545080.00",
                "currCode": "COP",
                "faresPerPassenger": [
                    {
                        "code": "ADT",
                        "quantity": "1",
                        "baseFare": "481500.00",
                        "totalFare": "545080.00",
                        "taxes": [
                            {
                                "code": "CO",
                                "amount": "39500.00"
                            },
                            {
                                "code": "YS",
                                "amount": "24080.00"
                            }
                        ]
                    }
                ]
            },
            {
                "fareInfo": [
                    {
                        "originDestRPH": "1",
                        "className": "MAXI",
                        "classCode": "K",
                        "cabin": "ECONOMY"
                    },
                    {
                        "originDestRPH": "1001",
                        "className": "MEGA",
                        "classCode": "J",
                        "cabin": "BUSINESS"
                    }
                ],
                "fareConstruction": [
                    {
                        "originDestRPH": "1",
                        "componentAmount": "168000.00"
                    },
                    {
                        "originDestRPH": "1001",
                        "componentAmount": "369500.00"
                    }
                ],
                "baseFare": "537500.00",
                "totalTax": "66380.00",
                "totalFees": "0.00",
                "totalFare": "603880.00",
                "currCode": "COP",
                "faresPerPassenger": [
                    {
                        "code": "ADT",
                        "quantity": "1",
                        "baseFare": "537500.00",
                        "totalFare": "603880.00",
                        "taxes": [
                            {
                                "code": "CO",
                                "amount": "39500.00"
                            },
                            {
                                "code": "YS",
                                "amount": "26880.00"
                            }
                        ]
                    }
                ]
            },
            {
                "fareInfo": [
                    {
                        "originDestRPH": "1",
                        "className": "MEGA",
                        "classCode": "J",
                        "cabin": "BUSINESS"
                    },
                    {
                        "originDestRPH": "1001",
                        "className": "MAXI",
                        "classCode": "K",
                        "cabin": "ECONOMY"
                    }
                ],
                "fareConstruction": [
                    {
                        "originDestRPH": "1",
                        "componentAmount": "369500.00"
                    },
                    {
                        "originDestRPH": "1001",
                        "componentAmount": "168000.00"
                    }
                ],
                "baseFare": "537500.00",
                "totalTax": "66380.00",
                "totalFees": "0.00",
                "totalFare": "603880.00",
                "currCode": "COP",
                "faresPerPassenger": [
                    {
                        "code": "ADT",
                        "quantity": "1",
                        "baseFare": "537500.00",
                        "totalFare": "603880.00",
                        "taxes": [
                            {
                                "code": "CO",
                                "amount": "39500.00"
                            },
                            {
                                "code": "YS",
                                "amount": "26880.00"
                            }
                        ]
                    }
                ]
            },
            {
                "fareInfo": [
                    {
                        "originDestRPH": "1",
                        "className": "SUPER",
                        "classCode": "W",
                        "cabin": "ECONOMY"
                    },
                    {
                        "originDestRPH": "1001",
                        "className": "MEGA",
                        "classCode": "J",
                        "cabin": "BUSINESS"
                    }
                ],
                "fareConstruction": [
                    {
                        "originDestRPH": "1",
                        "componentAmount": "264700.00"
                    },
                    {
                        "originDestRPH": "1001",
                        "componentAmount": "369500.00"
                    }
                ],
                "baseFare": "634200.00",
                "totalTax": "71210.00",
                "totalFees": "0.00",
                "totalFare": "705410.00",
                "currCode": "COP",
                "faresPerPassenger": [
                    {
                        "code": "ADT",
                        "quantity": "1",
                        "baseFare": "634200.00",
                        "totalFare": "705410.00",
                        "taxes": [
                            {
                                "code": "CO",
                                "amount": "39500.00"
                            },
                            {
                                "code": "YS",
                                "amount": "31710.00"
                            }
                        ]
                    }
                ]
            },
            {
                "fareInfo": [
                    {
                        "originDestRPH": "1",
                        "className": "MEGA",
                        "classCode": "J",
                        "cabin": "BUSINESS"
                    },
                    {
                        "originDestRPH": "1001",
                        "className": "MEGA",
                        "classCode": "J",
                        "cabin": "BUSINESS"
                    }
                ],
                "fareConstruction": [
                    {
                        "originDestRPH": "1",
                        "componentAmount": "369500.00"
                    },
                    {
                        "originDestRPH": "1001",
                        "componentAmount": "369500.00"
                    }
                ],
                "baseFare": "739000.00",
                "totalTax": "76450.00",
                "totalFees": "0.00",
                "totalFare": "815450.00",
                "currCode": "COP",
                "faresPerPassenger": [
                    {
                        "code": "ADT",
                        "quantity": "1",
                        "baseFare": "739000.00",
                        "totalFare": "815450.00",
                        "taxes": [
                            {
                                "code": "CO",
                                "amount": "39500.00"
                            },
                            {
                                "code": "YS",
                                "amount": "36950.00"
                            }
                        ]
                    }
                ]
            }
        ]
    }
  const faresDepartureExisting = [];
  const fareArrivalExisting = [];
  response.rbds.forEach((rbd) => {
    let existDeparture = faresDepartureExisting.find(
      (element) => element.fareInfo.className === rbd.fareInfo[0].className
    );
    if (!existDeparture) {
      const code = "1";
      const fareInfo = rbd.fareInfo.find(
        (element) => element.originDestRPH === code
      );
      const fareConstruction = rbd.fareConstruction.find(
        (element) => element.originDestRPH === code
      );
      faresDepartureExisting.push({ ...rbd, fareInfo, fareConstruction });
    }

    let existArrival = fareArrivalExisting.find(
      (element) =>
        element.fareInfo.className ===
        rbd.fareInfo[rbd.fareInfo.length - 1].className
    );
    if (!existArrival) {
      const code = "1001";
      const fareInfo = rbd.fareInfo.find(
        (element) => element.originDestRPH === code
      );
      const fareConstruction = rbd.fareConstruction.find(
        (element) => element.originDestRPH === code
      );
      fareArrivalExisting.push({ ...rbd, fareInfo, fareConstruction });
    }
  });
  return (
      <>
  <FaresRoundTrip selectable={true} fares={faresDepartureExisting} selectFare={(fare)=>console.log(fare)} />
  <FaresRoundTrip fares={fareArrivalExisting} selectFare={(fare)=>console.log(fare)}/>
  </>
  );
}
