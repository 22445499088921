import urls from "../urls";

const generateInvitationCodeFitGames = async (body) => {
  const peticion = await fetch(urls.invitationCodeFitGames,{
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authorization":'Basic '+ btoa('avior:fazQMUyq'),
    },
    body: JSON.stringify({passenger:body})
  });
  const resp = await peticion.json();
  return {
    status: peticion.status,
    resp
  };
};
const generateInvitationCodeSunset = async (body) => {
  const peticion = await fetch(urls.invitationCodeSusnet,{
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authorization":'Basic '+ btoa('avior:fazQMUyq'),
    },
    body: JSON.stringify({passenger:body})
  });
  const resp = await peticion.json();
  return {
    status: peticion.status,
    resp
  };
};

const generateInvitationCodeCCSFC = async (body) => {
  const peticion = await fetch(urls.invitationCodeCCSFC,{
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authorization":'Basic '+ btoa('avior:fazQMUyq'),
    },
    body: JSON.stringify({passenger:body})
  });
  const resp = await peticion.json();
  return {
    status: peticion.status,
    resp
  };
};

const generateInvitationCCSFC = async (email) => {
  const peticion = await fetch(urls.invitationCCSFC,{
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authorization":'Basic '+ btoa('avior:fazQMUyq'),
    },
    body: JSON.stringify({email})
  });
  const resp = await peticion.json();
  return {
    status: peticion.status,
    resp
  };
};
const generateInvitationFVG = async (email) => {
  const peticion = await fetch(urls.invitationFVG,{
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authorization":'Basic '+ btoa('avior:fazQMUyq'),
    },
    body: JSON.stringify({email})
  });
  const resp = await peticion.json();
  return {
    status: peticion.status,
    resp
  };
};
const generateInvitationCodeFVG = async (body) => {
  const peticion = await fetch(urls.invitationCodeFVG,{
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authorization":'Basic '+ btoa('avior:fazQMUyq'),
    },
    body: JSON.stringify({passenger:body})
  });
  const resp = await peticion.json();
  return {
    status: peticion.status,
    resp
  };
};
const generateInvitationBK2 = async (email) => {
  const peticion = await fetch(urls.invitationBK2,{
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authorization":'Basic '+ btoa('avior:fazQMUyq'),
    },
    body: JSON.stringify({email})
  });
  const resp = await peticion.json();
  return {
    status: peticion.status,
    resp
  };
};
const generateInvitationCodeBK2 = async (body) =>{
  const peticion = await fetch(urls.invitationCodeBK2,{
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authorization":'Basic '+ btoa('avior:fazQMUyq'),
    },
    body: JSON.stringify({passenger:body})
  });
  const resp = await peticion.json();
  return {
    status: peticion.status,
    resp
  };
}
const generateInvitationCodeBPlaza = async (body) =>{
  const peticion = await fetch(urls.invitationCodeBPlaza,{
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authorization":'Basic '+ btoa('avior:fazQMUyq'),
    },
    body: JSON.stringify({passenger:body})
  });
  const resp = await peticion.json();
  return {
    status: peticion.status,
    resp
  };
}
const generateInvitationSVCOTMED = async (email) => {
  const peticion = await fetch(urls.invitationSVCOTMED,{
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authorization":'Basic '+ btoa('avior:fazQMUyq'),
    },
    body: JSON.stringify({email})
  });
  const resp = await peticion.json();
  return {
    status: peticion.status,
    resp
  };
};
const generateInvitationCodeSVCOTMED = async (body) =>{
  const peticion = await fetch(urls.invitationCodeSVCOTMED,{
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authorization":'Basic '+ btoa('avior:fazQMUyq'),
    },
    body: JSON.stringify({passenger:body})
  });
  const resp = await peticion.json();
  return {
    status: peticion.status,
    resp
  };
}
const generateInvitationFITGMAES = async (email) => {
  const peticion = await fetch(urls.invitationFITGMAES,{
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authorization":'Basic '+ btoa('avior:fazQMUyq'),
    },
    body: JSON.stringify({email})
  });
  const resp = await peticion.json();
  return {
    status: peticion.status,
    resp
  };
};
const invitationServices = Object.freeze({
    generateInvitationCodeFitGames,
    generateInvitationCodeSunset,
    generateInvitationCCSFC,
    generateInvitationCodeCCSFC,
    generateInvitationFVG,
    generateInvitationCodeFVG,
    generateInvitationCodeBK2,
    generateInvitationBK2,
    generateInvitationCodeBPlaza,
    generateInvitationSVCOTMED,
    generateInvitationCodeSVCOTMED,
    generateInvitationFITGMAES
});

export default invitationServices;