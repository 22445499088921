import React, { useEffect, useState } from "react";
import { Box, Grid, TextField, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import urls from "../urls";
import {
  Spinner,
  ModalPaymentError,
  ButtonSpinner,
  Toast,
} from "../components";
import { makeStyles } from "@material-ui/core/styles";
import { FormValidator } from "../helpers";
const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    width: 100,
    height: 100,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));
export default function PaymentStripe(props) {
  const [loading, setLoading] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [emailError, setEmailError] = useState("");
  const [openIframe, setOpenIframe] = useState(false);
  const [countAttempts, setCountAttempts] = useState(0);
  const [, setToast] = useState(false);

  let urlFrame;

  if (props.installments) {
    urlFrame = props.installmentInfo
      ? `${urls.paymentInstallmentsStripe}?i=${props.installmentId}&email=${email}&name=${name}&user=${props.user}`
      : `${urls.paymentInstallmentsStripe}?pnr=${props.pnr}&email=${email}&name=${name}&user=${props.user}`;
  } else {
    urlFrame = `${urls.stripePayBooking}?pnr=${props.pnr}&email=${email}&name=${name}`;
  }

  const classes = useStyles();
  const validator = new FormValidator([
    {
      field: "email",
      method: "isEmpty",
      validWhen: false,
      message: "Debe ingresar email",
    },
    {
      field: "email",
      method: "isEmail",
      validWhen: true,
      message: "Ingrese un correo electrónico valido",
    },
  ]);
  let history = useHistory();

  const redirectThank = ({ response, status }) => {
    let urlThank;

    if (status === "approved") {
      urlThank = "/thankyoupage";
    } else {
      urlThank = "/thankyoupagependding";
    }

    const params = {
      ...response,
      passengers: response.passengers?.length
        ? response.passengers
        : response?.reservationdetails.passengers,
      reservationDetails: response?.reservationdetails,
      installments: props.installments ? true : false,
      originFlight: props.originFlight,
      returnFlight: props.returnFlight,
    };

    history.push(urlThank, {
      ...params,
    });
  };

  useEffect(() => {
    window.addEventListener("message", getMessage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (props.email) {
      setOpenIframe(true);
      setEmail(props.email);
      setName(props.name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (countAttempts >= 3) {
      setToast(true);
    }
  }, [countAttempts]);
  function getMessage(e) {
    const data = e.data;
    if (data.type === "loading") {
      setLoading(data.response.loading);
    } else if (data.type === "response") {
      console.log("data: ", data);

      if (data.status === 200) {
        if (data.response.paymenStatus === "pending") {
          redirectThank({ response: data.response, status: "pending" });
        } else {
          redirectThank({ response: data.response, status: "approved" });
        }
      } else {
        setToastMessage(data.response.error.message);
        setOpenModal(true);
      }
    } else if (data.type === "back") {
      props.goBack();
    }
  }
  const validateEmail = () => {
    const validation = validator.validate({
      email,
    });
    setEmailError(validation.email.message);
    if (validation.isValid) {
      setOpenIframe(true);
    }
  };

  return (
    <Grid
      container
      justify="center"
      direction="row"
      alignItems="center"
      spacing={1}
    >
      {!openIframe ? (
        <div className={classes.paper}>
          <Typography component="h1" variant="h5">
            Ingresa tu correo electrónico
          </Typography>
          <form className={classes.form}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Correo electrónico"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              helperText={emailError}
              error={emailError !== ""}
              onChange={(e) => setEmail(e.target.value)}
            />
            <ButtonSpinner fullWidth action={validateEmail} text="Aceptar" />
          </form>
        </div>
      ) : (
        <Grid item md={10} xs={12}>
          {!openModal ? (
            <Box>
              <iframe
                style={{ border: 0 }}
                //src={`${urls.stripePayBooking}?pnr=${props.pnr}&email=${email}&name=${name}`}
                src={urlFrame}
                title="Payment Luka Pay"
                width="100%"
                height="700px"
              />

              {props.goBack && !props.installmentInfo ? (
                <Box
                  display={"flex"}
                  justifyContent={"center"}
                  marginBottom={"50px"}
                >
                  <ButtonSpinner
                    action={() => {
                      props.goBack();
                    }}
                    style={{ width: 500, fontSize: 16, fontWeight: 600 }}
                    text="Atras"
                  />
                </Box>
              ) : null}
            </Box>
          ) : null}
        </Grid>
      )}
      <Spinner loading={loading} />
      <ModalPaymentError
        message={toastMessage}
        open={openModal}
        close={() => {
          setOpenModal(false);
          setCountAttempts(countAttempts + 1);
        }}
      />
      <Toast
        message={
          "Reserva cancelada motivo: muchos intentos al pagar la reserva"
        }
        open={countAttempts >= 3}
        close={() => {
          setToast(false);
          window.location.replace(`https://aviorair.com/`);
        }}
      />
    </Grid>
  );
}
