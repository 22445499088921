import React, { useState, useEffect } from "react";
import {
  ButtonSpinner,
  Title,
  PaymentMethods,
  //Toast,
  Spinner,
} from "../components";
import { Grid, Typography, Modal, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  FormatCurrency,
  getClassName,
  getCityName,
  getFullDateName,
  getTime,
} from "../helpers";
import urls from "../urls";
import { useLocation } from "react-router-dom";
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
export default function PaymentFromPNR(props) {
  let query = useQuery();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [methodSelect, setMethodSelect] = useState("");
  const [reservation, setReservation] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [reservationError, setReservationError] = useState("");
  const [ancillaries, setAncillaries] = useState([])
  useEffect(() => {
    const pnr = query.get("pnr");
    getPrice(pnr);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const selectMethod = async (method) => {
    await setMethodSelect(method);
  };
  const buildAncillaries=(reservationAnciallaries, segments)=>{
    const reservationAnciallariesAux = []
    reservationAnciallaries.forEach(elem=>{
      elem.itemOffer.forEach(item=>{
        reservationAnciallariesAux.push(item)
      })
    })
    const groupAncillariesAux = reservationAnciallariesAux.reduce(function (obj, item) {
      obj[item.segmentRPH] = obj[item.segmentRPH] || [];
      obj[item.segmentRPH].push({...item});
      return obj;
    }, {});
    const groups = Object.keys(groupAncillariesAux).map(function (key) {
        
        return {segment: `${segments[key-1].departure}-${segments[key-1].arrival}`, ancillaries: groupAncillariesAux[key]};
    });
    setAncillaries([...groups])
  }
  const getPrice = async (pnr) => {
    setLoading(true);
    const peticion = await fetch(`${urls.getPrice}/${pnr}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Basic " + btoa("avior:fazQMUyq"),
      },
    });
    const resp = await peticion.json();
    setLoading(false);
    if (peticion.status === 200) {
      setReservation(resp);
      buildAncillaries(resp.ancillaries, resp.segments)
    } else {
      setOpenModal(true);
      setReservationError(resp.error.message);
    }
  };
  const onSubmitPay= async ()=>{
    props.sendData({method:methodSelect, pnr:reservation.pnr, currency:reservation.currCode, reservation});
  }
  function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
  }
  const goToHome=()=>{
    window.location.replace(`https://www.avior.com.ve/`);
  }
  return (
    <div>
      {reservation.pnr ? (
        <Title h2={"LOCALIZADOR: "} strong={reservation.pnr} />
      ) : null}
      <Title
        h2={"RESUMEN DE "}
        strong={"COMPRA"}
        p={"A continuación, encontrarás el detalle de tu selección."}
      />
      <Grid container justify="flex-start" direction="row" spacing={2}>
        <Grid item md={12} xs={12} style={{ marginBottom: "20px" }}>
          <Grid
            container
            justify="center"
            direction="row"
            alignItems="center"
            style={{ border: "1pt solid #CCCCCC" }}
          >
            <Grid item md={12} xs={12}>
              <Grid
                container
                justify="flex-start"
                direction="row"
                alignItems="center"
              >
                {reservation.segments
                  ? reservation.segments.map((segment, index) => {
                      const segmentClass = getClassName(segment.classCode);
                      const departureCityName = getCityName(segment.departure);
                      const arrivalCityName = getCityName(segment.arrival);
                      return (
                        <Grid key={index} item md={6} xs={12}>
                          <Grid container className={classes.padding}>
                            <Grid item xs={6}>
                              <Typography
                                className={classes.span1}
                                color="primary"
                              >
                                Tarifa {segmentClass.cabinName}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            md={12}
                            xs={12}
                            className={classes.padding}
                          >
                            <Grid container>
                              <Grid item xs={12}>
                                <Grid container>
                                  <Grid item xs={12}>
                                    <Typography
                                      display="inline"
                                      className={classes.span1}
                                      color="primary"
                                    >
                                      {departureCityName.cityName}{" "}
                                    </Typography>
                                    <Typography
                                      display="inline"
                                      className={classes.span2}
                                    >
                                      ({departureCityName.code})
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <Typography className={classes.p3}>
                                      {departureCityName.airPortName}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <Typography
                                      className={classes.p3}
                                      style={{ marginTop: "10px" }}
                                    >
                                      {getFullDateName(segment.depatureTime)}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <Typography className={classes.p3}>
                                      Salida: {getTime(segment.depatureTime)}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item xs={6}>
                                <Grid container>
                                  <Grid item xs={12}>
                                    <Typography
                                      display="inline"
                                      className={classes.span1}
                                      color="primary"
                                    >
                                      {arrivalCityName.cityName}{" "}
                                    </Typography>
                                    <Typography
                                      display="inline"
                                      className={classes.span2}
                                    >
                                      ({arrivalCityName.code})
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <Typography className={classes.p3}>
                                      {arrivalCityName.airPortName}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <Typography
                                      className={classes.p3}
                                      style={{ marginTop: "10px" }}
                                    >
                                      {getFullDateName(segment.arrivalTime)}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <Typography className={classes.p3}>
                                      Llegada: {getTime(segment.arrivalTime)}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      );
                    })
                  : null}
              </Grid>
            </Grid>
            <Grid item md={12} xs={12}>
              <Grid item xs={12} className={classes.box}>
                <Grid item xs={12} className={classes.box}>
                  <Grid
                    container
                    justify="space-between"
                    className={classes.padding}
                  >
                    <Grid item xs={6}>
                      <Typography className={classes.span1} color="primary">
                        TARIFAS AÉREAS:
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Grid container justify="flex-end">
                        <Grid item md={2} xs={2}>
                          <Typography className={classes.p3}>
                            {reservation.currCode ? reservation.currCode : ""}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          md={4}
                          xs={4}
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <Typography className={classes.p3}>
                            {FormatCurrency(
                              `${parseFloat(
                                reservation.amountBeforeTax
                                  ? reservation.amountBeforeTax
                                  : "0.00"
                              ).toFixed(2)}`
                            )}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  container
                  className={classes.padding}
                  justify="space-between"
                >
                  <Grid item xs={6}>
                    <Typography className={classes.span1} color="primary">
                      IMPUESTOS Y TASAS:
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid container justify="flex-end">
                      <Grid item md={2} xs={2}>
                        <Typography className={classes.p3}>
                          {reservation.currCode ? reservation.currCode : ""}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        md={4}
                        xs={4}
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <Typography className={classes.p3}>
                          {FormatCurrency(
                            `${parseFloat(
                              reservation.amount ? reservation.amount-reservation.amountBeforeTax-reservation.totalAncillaries : "0.00"
                            ).toFixed(2)}`
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} className={classes.padding}>
                {reservation.taxes
                  ? reservation.taxes.map((taxe, index) => (
                      <Grid container justify="space-between" key={index}>
                        <Grid item xs={6}>
                          <Typography className={classes.p3}>
                            {taxe.code}
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Grid container justify="flex-end">
                            <Grid item md={2} xs={2}>
                              <Typography
                                className={classes.p3}
                              >{`${reservation.currCode}`}</Typography>
                            </Grid>
                            <Grid
                              item
                              md={4}
                              xs={4}
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <Typography className={classes.p3}>
                                {FormatCurrency(
                                  `${parseFloat(taxe.amount).toFixed(2)}`
                                )}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    ))
                  : null}
              </Grid>
              {reservation?.ancillaries?.length>0&&
              <Grid item md={12} xs={12}>
                <Grid className={classes.box}>
                  <Grid
                    container
                    className={classes.padding}
                    justify="space-between"
                  >
                    <Grid item xs={6}>
                      <Typography className={classes.span1} color="primary">
                        ADICIONALES:
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Grid container justify="flex-end">
                        <Grid item xs={2}>
                          <Typography className={classes.p3}>{reservation.currCode}</Typography>
                        </Grid>
                        <Grid item xs={4} style={{ display: "flex", justifyContent: "flex-end"}}>
                            <Typography className={classes.p3}>
                              {FormatCurrency(
                                `${parseFloat(reservation.totalAncillaries).toFixed(2)}`
                              )}
                            </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} className={classes.padding}>
                {ancillaries.map((ancillary, index)=>(
                    <Grid container key={index}>
                        <Grid item xs={12} >
                            <Typography className={classes.span1} color="primary">{ancillary.segment}</Typography >
                        </Grid>
                        <Grid container>
                            {ancillary.ancillaries.map((elem, key)=>(
                                <Grid container justify="space-between" key={key}>
                                    <Grid item xs={6}>
                                        <Typography  className={classes.p3}>{elem.description}</Typography>
                                    </Grid>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                ))}
                </Grid>
              </Grid>}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        style={{ border: "1pt solid #cccccc", borderBottom: "unset" }}
      >
        <PaymentMethods
        currency={reservation.currCode} 
        selectMethod={selectMethod} 
        classes={classes} />
      </Grid>
      <Grid
        container
        style={{
          padding: "15px",
          backgroundColor: "#CD171A",
          marginBottom: "20px",
        }}
      >
        <Grid
          item
          md={12}
          xs={12}
          style={{ display: "flex", alignItems: "center" }}
        >
          <p className={classes.p} style={{ margin: 0 }}>
            VALOR TOTAL DE TU RESERVA:{" "}
            {reservation.currCode ? reservation.currCode : ""}{" "}
            {FormatCurrency(
              `${parseFloat(
                reservation.amount ? reservation.amount : "0."
              ).toFixed(2)}`
            )}
          </p>
        </Grid>
      </Grid>

      <Spinner loading={loading} />
      <Grid container justify="flex-end">
        <div style={{ width: "158px", height: "41px" }}>
          <ButtonSpinner
            id="buttonPagar"
            action={onSubmitPay}
            fullWidth
            text="PAGAR"
            color={"primary"}
            style={{ height: "100%" }}
            labelClassname={classes.payButtonLabel}
          />
        </div>
      </Grid>
      <Modal disableBackdropClick={true} onClose={() => {}} open={openModal}>
        <Grid
          container
          justify="center"
          style={getModalStyle()}
          className={classes.paper2}
        >
          <Grid
            item
            md={12}
            xs={12}
            style={{
              backgroundColor: "#FFF",
              height: "25%",
            }}
          >
            <Typography className={classes.h2} align="center" display="block">
              ¡ATENCION!
            </Typography>
            <br/>
            <Typography
              align="center"
              display="block"
              className={classes.modalText}
            >
              {reservationError}
            </Typography>
            <br/>
            <br/>
            <Button variant="contained" fullWidth color={"primary"}
                            onClick={goToHome}
                        >
                            <label>{"Volver al inicio."}</label>
                        </Button>  
          </Grid>
        </Grid>
      </Modal>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  /*Screens styles*/
  paper2: {
    position: "absolute",
    width: 400,
    height: 200,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #FFF",
    boxShadow: theme.shadows[5],
    padding: "20px",
    borderRadius: "20px",
    outline: "none",
  },
  container: {
    width: "100%",
    border: "1pt solid #cccccc",
  },
  reserveButtonLabel: {
    color: "#891c56",
    fontSize: "14pt",
    lineHeight: "1.5",
    fontWeight: "bold",
  },
  payButtonLabel: {
    color: "#ffffff",
    fontSize: "14pt",
    lineHeight: "1.5",
    fontWeight: "bold",
  },
  aceptButtonLabel: {
    color: "#ffffff",
    fontSize: "14pt",
    fontWeight: "bold",
  },
  p: {
    fontWeight: "bold",
    color: "#ffffff",
    fontSize: "20pt",
  },
  /*Components Shared styles*/
  p1: {
    fontWeight: "bold",
    fontSize: "15pt",
    color: "#848484",
  },
  span1: {
    fontWeight: "bold",
    fontSize: "1.21rem",
  },
  p2: {
    fontSize: "12pt",
    color: "#848484",
  },
  span2: {
    color: "#848484",
  },
  p3: {
    fontSize: "1.15rem",
    lineHeight: "1.5",
    color: "#848484",
  },
  padding: {
    padding: "10px",
  },
  box: {
    borderBottom: "solid 1px #cccccc",
    backgroundColor: "#EAEAEA",
  },
  banner: {
    display: "none",
  },
  fondoBanner: {
    width: "100%",
  },
  [theme.breakpoints.down("sm")]: {
    container: {
      width: "100%",
    },
    fondoBanner: {
      display: "none",
    },
    banner: {
      display: "block",
      width: "100%",
    },
    p: {
      fontSize: "0.9rem",
    },
    payButtonLabel: {
      fontSize: "0.9rem",
    },
    reserveButtonLabel: {
      fontSize: "0.9rem",
    },
    aceptButtonLabel: {
      fontSize: "0.9rem",
    },
    p1: {
      fontSize: "0.9rem",
    },
    p3: {
      fontSize: "0.8rem",
    },
    span1: {
      fontSize: "1rem",
    },
    arrow: {
      fontSize: "0.9rem",
    },
  },
}));
