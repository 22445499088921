import React, { useState } from "react";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import { ref } from "../helpers/links";

export default function TermsAviorPlus(props) {
  const classes = useStyles();

  const [termsA, setTermsA] = useState(false);
  const [termsB, setTermsB] = useState(false);

  return (
    <Grid container direction="row" className={classes.container}>
      <Grid
        item
        md={12}
        xs={12}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
        }}
      >
        <div>
          <Checkbox
            checked={termsA}
            onChange={(e) => {
              setTermsA(e.target.checked);
              props.acceptTermns({ termsA: e.target.checked, termsB });
            }}
            color={"primary"}
          />
        </div>
        <div style={{ width: "95%", paddingTop: "9px" }}>
          <Typography className={classes.checkText}>
            Acepto los{" "}
            <a
              rel="noopener noreferrer"
              target="_blank"
              href={ref.termsAviorPlus}
              style={{ color: "#555555" }}
            >
              <strong className={classes.links}>términos y condiciones</strong>
            </a>
            , y pago en millas de Avior plus.
          </Typography>
        </div>
      </Grid>

      <Grid
        item
        md={12}
        xs={12}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
        }}
      >
        <div>
          <Checkbox
            checked={termsB}
            onChange={(e) => {
              setTermsB(e.target.checked);
              props.acceptTermns({ termsA, termsB: e.target.checked });
            }}
            color={"primary"}
          />
        </div>
        <div style={{ width: "95%", paddingTop: "9px" }}>
          <Typography className={classes.checkText}>
            He leído y acepto las condiciones de{" "}
            <a
              rel="noopener noreferrer"
              target="_blank"
              href={ref.transportContract}
              style={{ color: "#555555" }}
            >
              <strong className={classes.links}>contrato de transporte</strong>
            </a>
            , y las restricciones sobre{" "}
            <a
              rel="noopener noreferrer"
              target="_blank"
              href={ref.dangerousBaggage}
              style={{ color: "#555555" }}
            >
              <strong className={classes.links}>mercancias peligrosas</strong>
            </a>
            , y los términos y condiciones de los servicios adicionales.
          </Typography>
        </div>
      </Grid>
    </Grid>
  );
}
const useStyles = makeStyles((theme) => ({
  checkText: {
    fontSize: "1.17rem",
    color: "#555555",
  },
  container: {
    backgroundColor: "#eaeaea",
    padding: "20px",
    marginBottom: "20px",
    marginTop: "20px",
  },
  links: {
    textDecoration: "underline",
  },
  [theme.breakpoints.down("md")]: {
    checkText: {
      fontSize: "0.8rem",
    },
  },
}));
