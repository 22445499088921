import React, { useEffect, useState } from "react";
import { Spinner } from "../components";
//import urls from "../urls";
//import { useHistory } from "react-router-dom";
export default function VerifyGBC() {
  const [loading, setLoading] = useState(false);
  //let history = useHistory();
  const getPrice = async (pnr) => {
    setLoading(true);
    /*const peticion = await fetch(`${urls.getPrice}/${pnr}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Basic " + btoa("avior:fazQMUyq"),
      },
    });*/
    /*const resp = await peticion.json();
    setLoading(false);
    if (peticion.status === 200) {
      const data= {
        state: {
          passengers: [
            {
              firstName: "CESAR",
              lastName: "OLIVERO",
              ticketNumber: "7420202002174",
            }
          ],
          reservation: {
            amount: "204.64",
            amountBeforeTax: "66",
            currCode: "USD",
            passengers: [
              {
                document: { type: "ID", id: "26875715" },
                firstName: "CESAR",
                id: "1",
                lastName: "OLIVERO",
                type: "ADT",
              }
            ],
            pnr: "HFRUDD",
            segments: [
              {
                arrival: "CCS",
                arrivalTime: "2021-02-26T18:00:00",
                classCode: "T",
                departure: "SDQ",
                depatureTime: "2021-02-26T16:30:00",
                flightNumber: "1215",
              }
            ],
            taxes: [
              { code: "AA", amount: "20" },
              { code: "C2", amount: "1.25" },
              { code: "DO", amount: "22.68" },
              { code: "EU", amount: "0.66" },
              { code: "TY", amount: "2.75" },
              { code: "UX", amount: "15" },
              { code: "VB", amount: "16.3" },
              { code: "YQ", amount: "60" }
            ],
          },
          reservationDetails: {
            itineraryPricing: {
              amountBeforeTax: "66",
              amountAfterTax: "204.64",
              taxes: [
                { code: "AA", amount: "20" },
                { code: "C2", amount: "1.25" },
                { code: "DO", amount: "22.68" },
                { code: "EU", amount: "0.66" },
                { code: "TY", amount: "2.75" },
                { code: "UX", amount: "15" },
                { code: "VB", amount: "16.3" },
                { code: "YQ", amount: "60" }
              ],
            },
            pnr: "HFRUDD",
            segments: [
              {
                arrival: "CCS",
                arrivalTime: "2021-02-26T18:00:00",
                classCode: "T",
                departure: "SDQ",
                depatureTime: "2021-02-26T16:30:00",
                flightNumber: "1215",
              }
            ],
            passengers: [
                {
                  document: { type: "ID", id: "26875715" },
                  firstName: "CESAR",
                  id: "1",
                  lastName: "OLIVERO",
                  type: "ADT",
                }
              ],
          },
        },
      };
      history.push(`/thankyoupage`, data);
    }*/
  };
  useEffect(() => {
    //const pnr = query.get("pnr");
    getPrice("OUHVKK");
  }, []);
  return <Spinner loading={loading} />;
}
