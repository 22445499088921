import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Container,
  TableRow,
  TablePagination,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  Paper,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import { transferServices } from "../services";
import { Spinner, Toast } from "../components";
import { useHistory } from "react-router-dom";
import { getStatus } from "../helpers"
const columns = [
  {
    id: "date",
    label: "Fecha",
    minWidth: 120,
  },
  {
    id: "referenceNumber",
    label: "Referencia",
    minWidth: 100,
  },
  {
    id: "full-name",
    label: "Nombre y apellido",
    minWidth: 170,
  },
  {
    id: "email",
    label: "Email",
    minWidth: 170,
  },
  {
    id: "phone",
    label: "Teléfono",
    minWidth: 170,
  },
  {
    id: "amount",
    label: "Monto",
    minWidth: 90,
  },
  {
    id: "status",
    label: "Estado",
    minWidth: 90,
  },
  {
    id: "admin",
    label: "Administrador",
    minWidth: 90,
  },
  {
    id: "update",
    label: "Actualizacion",
    minWidth: 110
  },
  {
    id: "acciones",
    label: "",
    minWidth: 270,
  }
];

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  containerTable: {
    maxHeight: 440,
  },
  container: {
    marginTop: theme.spacing(8),
  },
}));

function getDate(date){
  const newDate = date.split("T")[0];
  const dateFormat = newDate.split("-")
  return `${dateFormat[2]}-${dateFormat[1]}-${dateFormat[0]}`
}
function Row(props) {
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogCancel, setOpenDialogCancel] = useState(false);
  const [transfer, setTransfer] = useState({});
  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };
  return (
    <React.Fragment>
      <Dialog
        open={openDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Aprobar pago.</DialogTitle>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button
            onClick={() => {
              handleClose();
              props.aproveTranfer(transfer._id);
            }}
            color="primary"
            autoFocus
          >
            Aprobar
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openDialogCancel}
        onClose={()=>setOpenDialogCancel(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Rachazar pago.</DialogTitle>
        <DialogActions>
          <Button onClick={()=>setOpenDialogCancel(false)} color="primary">
            Cancelar
          </Button>
          <Button
            onClick={() => {
              setOpenDialogCancel(false)
              props.cancelTranfer(transfer._id);
            }}
            color="primary"
            autoFocus
          >
            Rechazar
          </Button>
        </DialogActions>
      </Dialog>
      <TableRow hover role="checkbox" tabIndex={-1}>
        <TableCell>{getDate(props.transfer.createdAt)}</TableCell>
        <TableCell>{props.transfer.referenceNumber}</TableCell>
        <TableCell>{props.transfer.name}</TableCell>
        <TableCell>{props.transfer.email}</TableCell>
        <TableCell>{props.transfer.phone}</TableCell>
        <TableCell>{props.transfer.amount}</TableCell>
        <TableCell>{getStatus(props.transfer.status)}</TableCell>
        <TableCell>{props.transfer.status !== "pending"?props.transfer.admin? `${props.transfer.admin?.firstName} ${props.transfer.admin?.lastName}`:"Automatico":"-"}</TableCell>
        <TableCell>{props.transfer.status !== "pending"?getDate(props.transfer.updatedAt.split("T")[0]) :"-"}</TableCell>
        <TableCell>
          {props.transfer.status === "pending" && (
            <Grid container justify="center">
              <Grid item xs={6}>
              <Button
                onClick={() => {
                  handleClickOpen();
                  setTransfer(props.transfer);
                }}
                variant="contained"
                color="primary"
              >
                Aprobar
              </Button>
              </Grid>
              <Grid item xs={6}>
              <Button
                onClick={() => {
                  setOpenDialogCancel(true)
                  //setPnr(props.transfer.pnr);
                  setTransfer(props.transfer);
                }}
                variant="contained"
                color="primary"
              >
                Rechazar
              </Button>
              </Grid>
            </Grid>
          )}
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
export default function TransfersAviorGO({isBss}) {
  const classes = useStyles();
  let history = useHistory();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [transfers, setTransfers] = useState([]);
  const [count, setCount] = useState(0);
  const [toast, setToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  useEffect(() => {
    getTransfers(page + 1, rowsPerPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, isBss]);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getTransfers(newPage + 1, rowsPerPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
    getTransfers(1, event.target.value);
  };

  const getTransfers = async (page, limit) => {
    setLoading(true);
    const peticion = isBss? await transferServices.getTransfersAviorGoBss({
      page: page,
      limit: limit
    }) : await transferServices.getTransfersAviorGo({
      page: page,
      limit: limit
    });
    setLoading(false);
    const resp = await peticion.resp;
    if (peticion.status === 200) {
      setTransfers(resp.transfers);
      setPage(resp.page ? resp.page - 1 : 0);
      setCount(resp.total);
    } else if (peticion.status === 401) {
      localStorage.clear();
      history.push(`/loginadminavior`);
    }
  };
  const aproveTranfer = async (_id) => {
    setLoading(true);
    const peticion= isBss? await transferServices.updateTransferBss(
      _id,
      "approved"
    ) : await transferServices.updateTransfer(
      _id,
      "approved"
    )
    const resp = await peticion.resp;
    setLoading(false);
    if (peticion.status === 200) {
      setToastMessage("Pago aprobado");
      setToast(true);
    } else if (peticion.status === 400) {
      setToastMessage(resp.error.message);
      setToast(true);
    } else if (peticion.status === 401) {
      localStorage.clear();
      history.push(`/loginadminavior`);
    }
  };
  const cancelTranfer = async (_id) => {
    setLoading(true);
    const peticion = isBss? await transferServices.updateTransferBss(
      _id,
      "rejected"
    ) : await transferServices.updateTransfer(
      _id,
      "rejected"
    )
    setLoading(false);
    const resp = await peticion.resp;
    if (peticion.status === 200) {
      setToastMessage("Pago rechazado");
      setToast(true);
    } else if (peticion.status === 400) {
      setToastMessage(resp.error.message);
      setToast(true);
    } else if (peticion.status === 401) {
      localStorage.clear();
      history.push(`/loginadminavior`);
    }
  };
  return (
    <Container component="main">
      <div className={classes.container}>
        <Paper className={classes.root}>
          <TableContainer className={classes.containerTable}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {transfers.map((transfer, index) => {
                  return (
                    <Row
                      transfer={transfer}
                      aproveTranfer={aproveTranfer}
                      cancelTranfer={cancelTranfer}
                      key={index}
                    />
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            labelRowsPerPage="Elemetos por pagina"
            rowsPerPageOptions={[10, 25, 50, 100]}
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      </div>
      <Toast
        message={toastMessage}
        open={toast}
        close={() => {
          getTransfers(page + 1, rowsPerPage);
          setToast(false);
        }}
      />
      <Spinner loading={loading} />
    </Container>
  );
}
