import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { getFullDateName } from "../helpers";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles(theme => ({
  grid: {
    marginTop: "15px"
  }
}));
export default function SearchResume(props) {
  const classes = useStyles();
  const align = "center";
  return (
    <Grid
      container
      justify="center"
      direction="row"
      spacing={1}
      className={classes.grid}
    >
      <Grid item md={6} xs={12}>
        <Grid container justify="center" direction="row" spacing={2}>
          <Grid item md={4} xs={5}>
            <Typography variant="h6" align={align} style={{ fontSize: "1em" }}>
              {props.route.departureCityName} ({props.route.origin})
            </Typography>
            <Typography
              display="block"
              variant="caption"
              color="textSecondary"
              align={align}
            >
              {props.dateDeparture?getFullDateName(props.dateDeparture).toUpperCase():""}
            </Typography>
          </Grid>
          <Grid item xs={2} style={{ paddingLeft: 0, paddingRight: 0 }}>
            <img
              src="./img/origin-destination.png"
              alt=""
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item md={4} xs={5}>
            <Typography variant="h6" align={align} style={{ fontSize: "1em" }}>
              {props.route.arrivalCityName} ({props.route.arrival})
            </Typography>
            <Typography
              display="block"
              variant="caption"
              color="textSecondary"
              align={align}
            >
              {props.dateArrival?getFullDateName(props.dateArrival).toUpperCase():""}
            </Typography>
          </Grid>
          <Grid item md={2} xs={10}>
            <Grid container direction="row" spacing={0}>
              {props.searchParams.countAdults > 0 ? (
                <Grid item md={12} xs={4}>
                  <Typography
                    display="block"
                    variant="caption"
                    color="textSecondary"
                  >
                    {props.searchParams.countAdults}{" "}
                    {props.searchParams.countAdults === 1
                      ? "ADULTO"
                      : "ADULTOS"}
                  </Typography>
                </Grid>
              ) : null}
              {props.searchParams.countChildrens > 0 ? (
                <Grid item md={12} xs={4}>
                  <Typography
                    display="block"
                    variant="caption"
                    color="textSecondary"
                  >
                    {props.searchParams.countChildrens}{" "}
                    {props.searchParams.countChildrens === 1 ? "NIÑO" : "NIÑOS"}
                  </Typography>
                </Grid>
              ) : null}
              {props.searchParams.countBabies > 0 ? (
                <Grid item md={12} xs={4}>
                  <Typography
                    display="block"
                    variant="caption"
                    color="textSecondary"
                  >
                    {props.searchParams.countBabies}{" "}
                    {props.searchParams.countBabies === 1
                      ? "INFANTE"
                      : "INFANTES"}
                  </Typography>
                </Grid>
              ) : null}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
