import React from "react";
import LoginAviorPlus from "../screens/login-aviorplus";
import { Grid, Typography } from "@material-ui/core";
import { X } from "lucide-react";
import ReactModal from "react-modal";

const modalStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  content: {
    top: "50%",
    left: "50%",
    bottom: "auto",
    //bottom: "-40%",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    maxWidth: 450,
    //maxHeight: 280,
    padding: 20,
    border: "none",
    borderRadius: 0,
  },
};

const ModalLogin = ({ showLogin, setShowLogin, setUserData }) => {
  return (
    <ReactModal
      isOpen={showLogin}
      onRequestClose={() => setShowLogin(!showLogin)}
      style={modalStyles}
      closeTimeoutMS={200}
    >
      <Grid container justify="space-between" style={{ marginBottom: 10 }}>
        <Typography color="secondary" align="center">
          INICIAR SESIÓN
        </Typography>

        <X
          cursor="pointer"
          onClick={() => {
            setShowLogin(!showLogin);
          }}
        />
      </Grid>

      <LoginAviorPlus
        setUserData={setUserData}
        close={() => {
          setShowLogin(!showLogin);
        }}
      />
    </ReactModal>
  );
};

export default ModalLogin;
