import React, { useState, useEffect } from "react";
import { Spinner, StaticDatePicker, Title } from "../components/index";
import {
  Box,
  Collapse,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import countries from "../helpers/countries.json";
import { filterDate, FormValidator } from "../helpers/";
import { Button } from "@material-ui/core/";
import {
  ChevronRight,
  //ExpandLess,
  ExpandMore,
  KeyboardArrowUp,
} from "@material-ui/icons";
import { styled } from "@material-ui/core/styles";
import //withStyles
"@material-ui/core/styles";
import {
  filterNumber,
  onBlurEmail,
  filterDocument,
} from "../helpers/filterValues";
import { aviorPlusServices, passengerServices } from "../services";
import { HelpCircle, Save, ToggleLeft, ToggleRight, User } from "lucide-react";

/* const MyExpandExpandLess = styled(ExpandLess)({
  color: "#FA7268",
  "&.MuiSelect-iconOpen": {
    transform: "none",
  },
}); */

const MyExpandExpandMore = styled(ExpandMore)({
  color: "#FA7268",
  "&.MuiSelect-iconOpen": {
    transform: "none",
  },
});

export default function PassengerForm(props) {
  const validatorPhone = new FormValidator([
    {
      field: "phoneNumber",
      method: "isEmpty",
      validWhen: false,
      message: "Ingrese numero de telefono",
    },
  ]);
  const validator = new FormValidator([
    {
      field: "name",
      method: "isEmpty",
      validWhen: false,
      message: "Debe ingresar nombre del pasajero",
    },
    {
      field: "lastName",
      method: "isEmpty",
      validWhen: false,
      message: "Debe ingresar apellido del pasajero",
    },
    {
      field: "selectBirthDay",
      method: "isEmpty",
      validWhen: false,
      message: "Seleccione la fecha de nacimiento",
    },
    {
      field: "gender",
      method: "isEmpty",
      validWhen: false,
      message: "Debe seleccionar un genero",
    },
    {
      field: "birthCountry",
      method: "isEmpty",
      validWhen: false,
      message: "Seleccione pais de nacimiento",
    },
    {
      field: "email",
      method: "isEmpty",
      validWhen: false,
      message: "Debe ingresar email",
    },
    {
      field: "email",
      method: "isEmail",
      validWhen: true,
      message: "Ingrese un correo electrónico valido",
    },
    {
      field: "docType",
      method: "isEmpty",
      validWhen: false,
      message: "Seleccione tipo de documento",
    },
    {
      field: "docNumber",
      method: "isEmpty",
      validWhen: false,
      message: "Ingrese numero de documento",
    },
    {
      field: "issueCountry",
      method: "isEmpty",
      validWhen: false,
      message: "Seleccione pais del documento",
    },
    {
      field: "selectDocExpirationDate",
      method: "isEmpty",
      validWhen: false,
      message: "Seleccione la fecha de vencimiento",
    },
  ]);

  let indexGender = 0;
  const genders1 = [
    { key: indexGender++, name: "Masculino", value: "M" },
    { key: indexGender++, name: "Femenino", value: "F" },
    { key: indexGender++, name: "Otro", value: "U" },
  ];
  let indexGender2 = 0;
  const genders2 = [
    { key: indexGender2++, name: "Infante masculino", value: "MI" },
    { key: indexGender2++, name: "Infante femenino", value: "FI" },
    { key: indexGender2++, name: "Otro", value: "U" },
  ];

  let indexPets = 0;
  const petsTypes = [
    {
      key: indexPets++,
      name: "Perro",
      value: "P",
    },
  ];

  let indexDocument = 0;
  const documentType = [
    { key: indexDocument++, name: "Pasaporte", value: "PP" },
    //{key:indexDocument++, name:"Doc de identidad", value:"ID"},
  ];

  const [documentTypeState, setDocumentTypeState] = useState(documentType);

  const [customerId, setCustomerId] = useState("");
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [gender, setGender] = useState("");

  const [birthDay, setBirthDay] = useState(null);
  const [selectBirthDay, setSelectBirthDay] = useState("");
  const [phoneCode, setPhoneCode] = useState("+58");
  const [phoneNumber, setPhoneNumber] = useState("");

  const [birthCountry, setBirthCountry] = useState("VE");
  const [email, setEmail] = useState("");

  const [docType, setDocType] = useState("PP");
  const [docNumber, setDocNumer] = useState("");
  const [issueCountry, setIssueCountry] = useState("VE");
  const [docExpirationDate, setDocExpirationDate] = useState(null);
  const [selectDocExpirationDate, setSelectDocExpirationDate] = useState("");

  const [passenger, setPassenger] = useState({
    birthCountry: "",
    birthDate: "",
    document: {
      docIssueCountry: "",
      documentID: "",
      documentType: "",
    },
    email: "",
    gender: "",
    lastName: "",
    name: "",
    type: "",
  });

  const [formVisible, setFormVisible] = useState(false);
  const [fields, setFields] = useState(false);

  const [nameError, setNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [genderError, setGenderError] = useState("");
  const [selectBirthDayError, setSelectBirthDayError] = useState("");
  const [selectBirthDayTypeError, setSelectBirthDayTypeError] = useState("");
  const [birthCountryError, setBirthCountryError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [phoneNumberError2, setPhoneNumberError2] = useState("");
  const [customerError, setCustomerError] = useState("");
  const [docTypeError, setDocTypeError] = useState("");
  const [docNumberError, setDocNumberError] = useState("");
  const [docNumberError2, setDocNumberError2] = useState("");
  const [issueCountryError, setIssueCountryError] = useState("");
  const [selectDocExpirationDateError, setSelectDocExpirationDateError] =
    useState("");

  const [pets, setPets] = useState(false);
  const [specialConditions, setSpecialConditions] = useState(false);
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState(props.type);

  const [checkUser, setCheckUser] = useState(false);
  const [userCountry, setUserCountry] = useState("");

  const handleChange = () => {
    if (checkUser) {
      setCheckUser(false);
      setFields(false);
      setName("");
      setLastName("");
      setGender("");
      setSelectBirthDay("");
      setBirthDay("");
      setBirthCountry("");
      setPhoneCode("");
      setPhoneNumber("");
      setCustomerId("");
      //setDocType("");
      setDocNumer("");
      setIssueCountry("");
      setEmail("");
    } else {
      setCheckUser(true);
      setFormVisible(true);

      const dateB = new Date(`${props.userData.user_info.birthdate}T00:00:00`);

      setName(props.userData.user_info.first_name);
      setLastName(props.userData.user_info.last_name);
      setGender(props.userData.user_info.sex);
      setSelectBirthDay(props.userData.user_info.birthdate);
      setBirthDay(dateB);
      setPhoneCode(`+${props.userData.user_info.code_phone}`);
      setPhoneNumber(props.userData.user_info.phone);
      setCustomerId(props.userData.user_info.customerid);
      //setDocType(props.userData.user_info.doc_type);
      setDocNumer(props.userData.user_info.doc_identity);
      setEmail(props.userData.user_info.email);

      if (userCountry?.code) {
        setBirthCountry(userCountry.code);
        setIssueCountry(userCountry.code);
      }
    }
  };

  const getAddresses = async () => {
    const { response: responseAddresses, status: statusAddresses } =
      await aviorPlusServices.getUserAddresses({
        token: props.userData.token,
      });

    if (statusAddresses === 200) {
      let addressesMap = responseAddresses.map((item, index) => {
        return {
          id: index + 1,
          description: item.description,
          country: item.country ? item.country : item.province?.country?.name,
          city: item.city,
        };
      });

      const countryFound = countries.find(
        (item) => item.name.toUpperCase() === addressesMap[0]?.country
      );

      setUserCountry(countryFound);
    }
  };

  useEffect(() => {
    if (props.userData) getAddresses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.userData]);

  const getUserPromoData = async (code) => {
    setLoading(true);
    const { status, resp } = await passengerServices.passengerPromoData(code);
    setLoading(false);
    if (status === 200) {
      if (!resp.multiPassengers) {
        const user = resp.passenger;
        setName(user.name.toUpperCase());
        setLastName(user.lastName.toUpperCase());
        setGender(user.gender);
        setBirthDay(new Date(user.birthDate.replace(/-/g, "/")));
        setSelectBirthDay(user.birthDate);
        setBirthCountry(user.birthCountry);
        setDocNumer(user.document.documentID);
        setDocType(user.document.documentType);
        setIssueCountry(user.document.docIssueCountry);
        setSelectDocExpirationDate(user.document.docExpirationDate);
        setDocExpirationDate(
          user.document.docExpirationDate
            ? new Date(user.document.docExpirationDate.replace(/-/g, "/"))
            : user.document.docExpirationDate
        );
        if (user.type === "ADT") {
          setPhoneCode(user.phone.code);
          setPhoneNumber(user.phone.number);
        }
        setType(user.type);
        setEmail(user.email);
        setFields(true);
        await setPassenger(user);
        props.addPassenger(user);
      }
      props.setTourCodeType(resp.type ? resp.type : 0);
    }
  };

  useEffect(() => {
    if (
      parseInt(props.index) === 1 &&
      props.searchParams.tourCode &&
      props.searchParams.tourCode !== ""
    ) {
      getUserPromoData(props.searchParams.tourCode);
    } else if (parseInt(props.index) === 1 && props.searchParams.user) {
      const user = props.searchParams.user;
      //docNumber: "25580278"
      //docType: "V"
      setName(user.name.toUpperCase());
      setLastName(user.lastName.toUpperCase());
      setGender(user.gender);
      setDocNumer(user.docNumber);
      setDocType(user.docType === "P" ? "PP" : "ID");
      setBirthDay(new Date(user.birthDay.replace(/-/g, "/")));
      setSelectBirthDay(user.birthDay);
      setPhoneCode(`+${user.phoneCode}`);
      setPhoneNumber(user.phoneNumber);
      setEmail(user.email);
      setCustomerId(user.customerId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.isNationalRoute) {
      documentType.push({
        key: indexDocument++,
        name: "Doc de identidad",
        value: "ID",
      });
      setDocumentTypeState(documentType);
      setDocType("ID");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isNationalRoute]);

  const validateData = async () => {
    setSelectBirthDayTypeError("");

    let validation;
    let validatePhoneNumber;
    let passengerAux;

    if (!fields) {
      validation = validator.validate({
        name,
        lastName,
        gender,
        selectBirthDay,
        birthCountry,
        docType,
        docNumber,
        issueCountry,
        email,
        selectDocExpirationDate,
      });
      setNameError(validation.name.message);
      setLastNameError(validation.lastName.message);
      setGenderError(validation.gender.message);
      setSelectBirthDayError(validation.selectBirthDay.message);
      setBirthCountryError(validation.birthCountry.message);
      setEmailError(validation.email.message);
      setDocTypeError(validation.docType.message);
      setDocNumberError(validation.docNumber.message);
      setIssueCountryError(validation.issueCountry.message);
      setSelectDocExpirationDateError(
        validation.selectDocExpirationDate.message
      );

      //console.log("validation", validation);
      //console.log(edad)
      if (props.type === "ADT") {
        validatePhoneNumber = validatorPhone.validate({ phoneNumber });
        setPhoneNumberError(validatePhoneNumber.phoneNumber.message);
        if (
          validatePhoneNumber.isValid &&
          validation.isValid &&
          docNumberError2.length === 0
        ) {
          setFields(true);
          passengerAux = {
            membershipID: customerId,
            name: name,
            lastName: lastName,
            gender: gender,
            birthDate: selectBirthDay,
            birthCountry: birthCountry,
            document: {
              docIssueCountry: issueCountry,
              documentID: docNumber,
              documentType: docType,
              docExpireDate: selectDocExpirationDate,
            },
            phone: {
              code: phoneCode,
              number: phoneNumber,
            },
            email: email,
            type: props.type,
            id: props.index,
          };
          await setPassenger(passengerAux);
          //console.log("aqui",passenger)
          await setFormVisible(!formVisible);
          props.addPassenger(passengerAux);
          window.scrollTo(0, 0);
        }
      } else if (validation.isValid && docNumberError2.length === 0) {
        setFields(true);
        passengerAux = {
          membershipID: customerId,
          name: name,
          lastName: lastName,
          gender: gender,
          birthDate: selectBirthDay,
          birthCountry: birthCountry,
          document: {
            docIssueCountry: issueCountry,
            documentID: docNumber,
            documentType: docType,
            docExpirationDate: selectDocExpirationDate,
          },
          email: email,
          type: props.type,
          id: props.index,
        };
        await setPassenger(passengerAux);
        //console.log("aqui",passenger)
        await setFormVisible(!formVisible);
        props.addPassenger(passengerAux);
        window.scrollTo(0, 0);
      }
    } else {
      if (formVisible === false) setFormVisible(true);
      setFields(!fields);
      props.deletePassenger(passenger);
    }
  };

  const docNumberExists = async () => {
    await setDocNumberError2(
      (await props.findPassenger(docNumber)) ? "Documento repetido" : ""
    );
  };

  const onBlurPhone = async () => {
    await setPhoneNumberError2(
      (await props.findPhonePassengers({
        code: phoneCode,
        number: phoneNumber,
      }))
        ? "Debe ser distinto al de informacion de contacto"
        : ""
    );
  };
  const onBlurCustomer = async () => {
    await setCustomerError(
      (await props.findCustomer(customerId)) ? "Customer repetido" : ""
    );
  };
  useEffect(() => {
    if (props.passengersCount === 1) setFormVisible(true);
    setPassengers(props.index, props.passengers, props.type);
  }, [props.index, props.passengers, props.passengersCount, props.type]);

  async function setPassengers(index, passengers, type) {
    //console.log(passengers)
    if (passengers) {
      for (let passenger of passengers) {
        if (passenger.id === index) {
          const docExpirationDate =
            passenger.document.docExpirationDate ||
            passenger.document.docExpireDate;
          if (type === "ADT") {
            setPhoneCode(passenger.phone.code);
            setPhoneNumber(passenger.phone.number);
          }
          setName(passenger.name);
          setLastName(passenger.lastName);
          setGender(passenger.gender);
          setBirthDay(passenger.birthDate.replace(/-/g, "/"));
          setSelectBirthDay(
            filterDate(new Date(passenger.birthDate.replace(/-/g, "/")))
          );
          setBirthCountry(passenger.birthCountry);
          setDocType(passenger.document.documentType);
          setDocNumer(passenger.document.documentID);
          setIssueCountry(passenger.document.docIssueCountry);
          setDocExpirationDate(docExpirationDate.replace(/-/g, "/"));
          setSelectDocExpirationDate(
            filterDate(new Date(docExpirationDate.replace(/-/g, "/")))
          );
          setEmail(passenger.email);
          setFields(true);
        }
      }
    }
  }

  let typePassenger =
    type === "ADT" ? "Adulto" : type === "CNN" ? "Niño" : "Infante";
  return (
    <div className={props.classes.container}>
      <Box
        display="flex"
        justifyContent="space-between"
        style={{ padding: "0px 10px", height: "70px" }}
      >
        <Box className={props.classes.headerFormContainerLeft}>
          <Box display="flex" alignItems="center" flexWrap="wrap">
            <Box display="flex" alignItems="center" mr={2}>
              <User color="#959595" />{" "}
              <Typography
                className={props.classes.buttonLabel}
                color={"primary"}
              >
                {name === "" && lastName === ""
                  ? typePassenger + ` pasajero ${props.index}`
                  : name + " " + lastName}
              </Typography>
            </Box>

            {props.userData && props.index === "1" && (
              <Box display="flex" alignItems="center">
                <Box display="flex" alignItems="center">
                  {!checkUser ? (
                    <ToggleLeft onClick={handleChange} cursor="pointer" />
                  ) : (
                    <ToggleRight
                      onClick={handleChange}
                      cursor="pointer"
                      color="#177ddc"
                    />
                  )}
                </Box>
                <Typography
                  className={props.classes.p1}
                  style={{
                    margin: "0px 5px",
                  }}
                >
                  Usar mis datos
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
        <Box display="flex">
          <Box
            className={props.classes.headerFormContainerRight}
            style={{ borderRightWidth: 0 }}
          >
            {fields
              ? !props.searchParams.tourCode && (
                  <Button
                    variant="contained"
                    className={props.classes.button}
                    fullWidth={true}
                    style={{
                      backgroundColor: "transparent",
                      boxShadow: "none",
                      padding: 0,
                      minWidth: "100%",
                      height: "100%",
                    }}
                    onClick={validateData}
                  >
                    <div className={props.classes.divIcon}>
                      <img src="./img/icono-editar.png" alt="edit-icon" />
                    </div>
                  </Button>
                )
              : null}
          </Box>
          <Box
            style={{ width: 50 }}
            className={props.classes.headerFormContainerRight}
          >
            <Button
              variant="contained"
              className={props.classes.button}
              fullWidth={true}
              style={{
                backgroundColor: "transparent",
                boxShadow: "none",
                padding: 0,
                minWidth: "100%",
                height: "100%",
                borderRadius: 10,
              }}
              onClick={() => setFormVisible(!formVisible)}
            >
              <div className={props.classes.divIcon}>
                {!formVisible ? <ChevronRight /> : <KeyboardArrowUp />}
              </div>
            </Button>
          </Box>
        </Box>
      </Box>

      <Collapse in={formVisible}>
        <Grid
          container
          direction="column"
          className={props.classes.gridContainer}
        >
          <Grid item xs={12} md={12}>
            <Grid
              container
              className={props.classes.Grid}
              style={{ marginBottom: "5px" }}
              spacing={2}
            >
              <Grid item md={3} xs={12}>
                <div className={props.classes.inputDiv}>
                  <TextField
                    name="firstName"
                    value={name}
                    label="Nombre (s) *"
                    fullWidth
                    id={`${"firstName"}${props.index}`}
                    helperText={nameError}
                    error={nameError !== ""}
                    InputLabelProps={{
                      shrink: true,
                      className: props.classes.floatingLabelFocusStyle,
                      htmlFor: `${"firstName"}${props.index}`,
                    }}
                    InputProps={{
                      disabled: fields,
                      classes: {
                        input: props.classes.inputLabel,
                        underline: props.classes.underline,
                      },
                    }}
                    onChange={(e) =>
                      setName(
                        e.target.value.toUpperCase().replace(/[^A-Z ]/g, "")
                      )
                    }
                  />
                </div>
              </Grid>

              <Grid item md={3} xs={12}>
                <div className={props.classes.inputDiv}>
                  <TextField
                    name="lastname"
                    value={lastName}
                    label="Apellido (s) *"
                    fullWidth
                    id={`${"lastname"}${props.index}`}
                    helperText={lastNameError}
                    error={lastNameError !== ""}
                    InputLabelProps={{
                      shrink: true,
                      className: props.classes.floatingLabelFocusStyle,
                      htmlFor: `${"lastname"}${props.index}`,
                    }}
                    InputProps={{
                      disabled: fields,
                      classes: {
                        input: props.classes.inputLabel,
                        underline: props.classes.underline,
                      },
                    }}
                    onChange={(e) =>
                      setLastName(
                        e.target.value.toUpperCase().replace(/[^A-Z ]/g, "")
                      )
                    }
                  />
                </div>
              </Grid>

              <Grid item md={3} xs={12}>
                <div className={props.classes.inputDiv}>
                  <TextField
                    value={birthCountry}
                    label="Nacionalidad *"
                    select
                    id={`${"birthCountry"}${props.index}`}
                    helperText={birthCountryError}
                    error={birthCountryError !== ""}
                    InputLabelProps={{
                      shrink: true,
                      className: props.classes.floatingLabelFocusStyle,
                      htmlFor: `${"birthCountry"}${props.index}`,
                    }}
                    InputProps={{
                      disabled: fields,
                      classes: {
                        input: props.classes.inputLabel,
                        underline: props.classes.underline,
                      },
                    }}
                    SelectProps={{ IconComponent: MyExpandExpandMore }}
                    onChange={(e) => setBirthCountry(e.target.value)}
                  >
                    {countries.map((countrie) => (
                      <MenuItem
                        key={countrie.code}
                        value={countrie.code}
                        disabled={fields}
                      >
                        {countrie.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
              </Grid>

              <Grid item md={3} xs={12}>
                <div className={props.classes.inputDiv}>
                  <StaticDatePicker
                    value={birthDay}
                    label="Fecha de nacimiento *"
                    toolbar={true}
                    disableFuture
                    disabled={fields}
                    minDate={props.minDate}
                    maxDate={props.maxDate}
                    inputVariant={"standard"}
                    id={`${"birthDay"}${props.index}`}
                    helperText={selectBirthDayError || selectBirthDayTypeError}
                    error={
                      selectBirthDayError !== "" ||
                      selectBirthDayTypeError !== ""
                    }
                    InputLabelProps={{
                      shrink: true,
                      className: props.classes.floatingLabelFocusStyle,
                      htmlFor: `${"birthDay"}${props.index}`,
                    }}
                    InputProps={{
                      disabled: fields,
                      classes: {
                        input: props.classes.inputLabel,
                        underline: props.classes.underline,
                      },
                    }}
                    onChange={(date) => {
                      setSelectBirthDay(filterDate(date));
                      setBirthDay(date);
                    }}
                  />
                </div>
              </Grid>

              <Grid item md={3} xs={12}>
                <div className={props.classes.inputDiv}>
                  <TextField
                    value={email}
                    name="email"
                    label="Email *"
                    fullWidth
                    id={`${"email"}${props.index}`}
                    helperText={emailError}
                    error={emailError !== ""}
                    InputLabelProps={{
                      shrink: true,
                      className: props.classes.floatingLabelFocusStyle,
                      htmlFor: `${"email"}${props.index}`,
                    }}
                    InputProps={{
                      disabled: fields,
                      classes: {
                        input: props.classes.inputLabel,
                        underline: props.classes.underline,
                      },
                    }}
                    onChange={(e) => setEmail(e.target.value)}
                    onBlur={() => {
                      setEmailError(onBlurEmail(email));
                    }}
                  />
                </div>
              </Grid>

              <Grid item md={2} xs={12}>
                <div className={props.classes.inputDiv}>
                  <TextField
                    value={gender}
                    label="Género *"
                    select
                    id={`${"gender"}${props.index}`}
                    helperText={genderError}
                    error={genderError !== ""}
                    InputLabelProps={{
                      shrink: true,
                      className: props.classes.floatingLabelFocusStyle,
                      htmlFor: `${"gender"}${props.index}`,
                    }}
                    InputProps={{
                      disabled: fields,
                      classes: {
                        input: props.classes.inputLabel,
                        underline: props.classes.underline,
                      },
                    }}
                    SelectProps={{ IconComponent: MyExpandExpandMore }}
                    onChange={(e) => setGender(e.target.value)}
                  >
                    {props.type === "ADT" || props.type === "CNN"
                      ? genders1.map((gender) => (
                          <MenuItem
                            key={gender.key}
                            value={gender.value}
                            disabled={fields}
                          >
                            {gender.name}
                          </MenuItem>
                        ))
                      : genders2.map((gender) => (
                          <MenuItem
                            key={gender.key}
                            value={gender.value}
                            disabled={fields}
                          >
                            {gender.name}
                          </MenuItem>
                        ))}
                  </TextField>
                </div>
              </Grid>

              {props.type === "ADT" ? (
                <Grid item md={5} xs={12}>
                  <Grid container spacing={2}>
                    <Grid item md={5} xs={4}>
                      <div className={props.classes.inputDiv}>
                        <TextField
                          value={phoneCode}
                          label={"Pais *"}
                          select
                          id={`${"phoneCode"}${props.index}`}
                          InputLabelProps={{
                            shrink: true,
                            className: props.classes.floatingLabelFocusStyle,
                            htmlFor: `${"phoneCode"}${props.index}`,
                          }}
                          InputProps={{
                            disabled: fields,
                            classes: {
                              input: props.classes.inputLabel,
                              underline: props.classes.underline,
                            },
                          }}
                          SelectProps={{ IconComponent: MyExpandExpandMore }}
                          onChange={(e) => setPhoneCode(e.target.value)}
                        >
                          {countries.map((countrie) => (
                            <MenuItem
                              key={countrie.code}
                              value={countrie.phone_code}
                              disabled={fields}
                            >
                              {`${countrie.phone_code} (${countrie.name})`}
                            </MenuItem>
                          ))}
                        </TextField>
                      </div>
                    </Grid>

                    <Grid item md={7} xs={8}>
                      <div className={props.classes.inputDiv}>
                        <TextField
                          value={phoneNumber}
                          label="Teléfono *"
                          name="number"
                          fullWidth
                          id={`${"phoneNumber"}${props.index}`}
                          helperText={phoneNumberError || phoneNumberError2}
                          error={
                            phoneNumberError !== "" || phoneNumberError2 !== ""
                          }
                          InputLabelProps={{
                            shrink: true,
                            className: props.classes.floatingLabelFocusStyle,
                            htmlFor: `${"phoneNumber"}${props.index}`,
                          }}
                          InputProps={{
                            disabled: fields,
                            classes: {
                              input: props.classes.inputLabel,
                              underline: props.classes.underline,
                            },
                          }}
                          onBlur={onBlurPhone}
                          onChange={(e) =>
                            setPhoneNumber(filterNumber(e.target.value))
                          }
                        />
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              ) : (
                <Grid item md={5} xs={false}></Grid>
              )}

              {props.type === "ADT" ? (
                <Grid item md={2} xs={12}>
                  <div className={props.classes.inputDiv}>
                    <TextField
                      value={customerId}
                      label="Customer ID"
                      name="customerId"
                      fullWidth
                      id={`${"customerId"}${props.index}`}
                      helperText={customerError}
                      error={customerError !== ""}
                      InputLabelProps={{
                        shrink: true,
                        className: props.classes.floatingLabelFocusStyle,
                        htmlFor: `${"customerId"}${props.index}`,
                      }}
                      InputProps={{
                        disabled: fields,
                        classes: {
                          input: props.classes.inputLabel,
                          underline: props.classes.underline,
                        },
                      }}
                      onBlur={onBlurCustomer}
                      onChange={(e) => setCustomerId(e.target.value)}
                    />
                  </div>
                </Grid>
              ) : (
                <Grid item md={2} xs={false}></Grid>
              )}
            </Grid>
          </Grid>

          <Grid item md={12} xs={12} style={{ marginBottom: "10px" }}>
            <Grid
              item
              md={12}
              xs={12}
              style={{ display: "flex", alignItems: "center" }}
            >
              <Typography
                className={props.classes.h2}
                style={{ marginRight: 5 }}
              >
                Documento del <strong>pasajero</strong>
              </Typography>{" "}
              <HelpCircle
                className={props.classes.textPrimary}
                width={16}
                cursor="pointer"
              />
            </Grid>

            <Typography style={{ fontSize: "1.1rem", color: "#848484" }}>
              La siguiente información será utilizada para informarte cualquier
              novedad, cambio o modificación que se presente antes de tu viaje.
            </Typography>
          </Grid>

          <Grid item md={12} xs={12} style={{ marginBottom: "10px" }}>
            <Grid
              container
              justify="flex-start"
              alignItems="center"
              direction="row"
              className={props.classes.Grid}
              spacing={2}
            >
              <Grid item md={3} xs={12}>
                <div className={props.classes.inputDiv}>
                  <TextField
                    value={issueCountry}
                    label="Pais del documento *"
                    select
                    id={`${"issueCountry"}${props.index}`}
                    helperText={issueCountryError}
                    error={issueCountryError !== ""}
                    InputLabelProps={{
                      shrink: true,
                      className: props.classes.floatingLabelFocusStyle,
                      htmlFor: `${"issueCountry"}${props.index}`,
                    }}
                    InputProps={{
                      disabled: fields,
                      classes: {
                        input: props.classes.inputLabel,
                        underline: props.classes.underline,
                      },
                    }}
                    SelectProps={{ IconComponent: MyExpandExpandMore }}
                    onChange={(e) => setIssueCountry(e.target.value)}
                  >
                    {countries.map((countrie) => (
                      <MenuItem
                        key={countrie.code}
                        value={countrie.code}
                        disabled={fields}
                      >
                        {countrie.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
              </Grid>

              <Grid item md={4} xs={12}>
                <Grid container spacing={2}>
                  <Grid item md={4} xs={4}>
                    <div className={props.classes.inputDiv}>
                      <TextField
                        value={docType}
                        label="Tipo *"
                        select
                        id={`${"docType"}${props.index}`}
                        helperText={docTypeError}
                        error={docTypeError !== ""}
                        InputLabelProps={{
                          shrink: true,
                          className: props.classes.floatingLabelFocusStyle,
                          htmlFor: `${"docType"}${props.index}`,
                        }}
                        InputProps={{
                          disabled: fields,
                          classes: {
                            input: props.classes.inputLabel,
                            underline: props.classes.underline,
                          },
                        }}
                        SelectProps={{ IconComponent: MyExpandExpandMore }}
                        onChange={(e) => setDocType(e.target.value)}
                      >
                        {documentTypeState.map((document) => (
                          <MenuItem
                            key={document.key}
                            value={document.value}
                            disabled={fields}
                          >
                            {document.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </div>
                  </Grid>

                  <Grid item md={8} xs={8}>
                    <div className={props.classes.inputDiv}>
                      <TextField
                        value={docNumber}
                        name="number"
                        label="Número de documento *"
                        fullWidth
                        id={`${"docNumber"}${props.index}`}
                        helperText={docNumberError || docNumberError2}
                        error={docNumberError !== "" || docNumberError2 !== ""}
                        InputLabelProps={{
                          shrink: true,
                          className: props.classes.floatingLabelFocusStyle,
                          htmlFor: `${"docNumber"}${props.index}`,
                        }}
                        InputProps={{
                          disabled: fields,
                          classes: {
                            input: props.classes.inputLabel,
                            underline: props.classes.underline,
                          },
                        }}
                        onChange={(e) =>
                          setDocNumer(filterDocument(e.target.value))
                        }
                        onBlur={docNumberExists}
                      />
                    </div>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item md={3} xs={12}>
                <div className={props.classes.inputDiv}>
                  <StaticDatePicker
                    value={docExpirationDate}
                    label="Vigencia de documento *"
                    inputVariant={"standard"}
                    id={`${"docExpirationDate"}${props.index}`}
                    disabled={fields}
                    toolbar={true}
                    disablePast
                    helperText={selectDocExpirationDateError}
                    error={selectDocExpirationDateError !== ""}
                    InputLabelProps={{
                      shrink: true,
                      className: props.classes.floatingLabelFocusStyle,
                      htmlFor: `${"docExpirationDate"}${props.index}`,
                    }}
                    InputProps={{
                      disabled: fields,
                      classes: {
                        input: props.classes.inputLabel,
                        underline: props.classes.underline,
                      },
                    }}
                    onChange={(date) => {
                      setSelectDocExpirationDate(filterDate(date));
                      setDocExpirationDate(date);
                    }}
                  />
                </div>
              </Grid>

              {!fields ? (
                <Grid item md={2} xs={12}>
                  <Button
                    variant="contained"
                    fullWidth
                    style={{
                      backgroundColor: "#CD171A",
                      textTransform: "none",
                    }}
                    onClick={validateData}
                  >
                    <div className={props.classes.aceptButtonLabel}>
                      <Save /> <p style={{ margin: "0px 10px" }}>Guardar</p>
                    </div>
                  </Button>
                </Grid>
              ) : (
                <Grid item md={2} xs={12}></Grid>
              )}

              {!true ? (
                <Grid item md={9} xs={12} className={props.classes.checkeds}>
                  <Grid item md={6} xs={12} className={props.classes.checked1}>
                    <div
                      style={{ width: "10%", height: "100%" }}
                      className={props.classes.parent}
                    >
                      <img src="./img/icono-necesidades.png" alt="needs-icon" />
                    </div>
                    <div
                      style={{ width: "80%", height: "100%" }}
                      className={props.classes.parent}
                    >
                      <p>Pasajeros en Condiciones especiales</p>
                    </div>
                    <div
                      style={{ width: "10%", height: "100%" }}
                      className={props.classes.parent}
                    >
                      <Checkbox
                        checked={specialConditions}
                        disabled={fields}
                        onChange={(e) => setSpecialConditions(e.target.checked)}
                        inputProps={{ color: "black" }}
                      />
                    </div>
                  </Grid>
                  <Grid item md={6} xs={12} className={props.classes.checked2}>
                    <div
                      style={{ width: "10%", height: "100%" }}
                      className={props.classes.parent}
                    >
                      <img src="./img/icono-mascota.png" alt="pets-icon" />
                    </div>
                    <div
                      style={{ width: "80%", height: "100%" }}
                      className={props.classes.parent}
                    >
                      <p>Necesitas viajar con tu mascota</p>
                    </div>
                    <div
                      style={{ width: "10%", height: "100%" }}
                      className={props.classes.parent}
                    >
                      <Checkbox
                        checked={pets}
                        disabled={fields}
                        onChange={(e) => setPets(e.target.checked)}
                        inputProps={{ color: "black" }}
                      />
                    </div>
                  </Grid>
                </Grid>
              ) : null}
            </Grid>
          </Grid>

          {!true ? (
            <Grid
              item
              md={12}
              xs={12}
              style={{
                borderTopWidth: "1px",
                borderTopColor: "#cccccc",
                borderTopStyle: "solid",
              }}
            >
              <Grid container>
                <Grid item md={6} xs={12}>
                  <Grid container>
                    <Grid item md={12} xs={12} style={{ display: "flex" }}>
                      <div
                        style={{ width: "10%", height: "100%" }}
                        className={props.classes.parent}
                      >
                        <img
                          src="./img/icono-mascota.png"
                          alt="pets-icon"
                          style={{ marginBlockStart: "0.83em" }}
                        />
                      </div>
                      <Title h2={"VIAJAS CON"} strong={"MASCOTA"} />
                    </Grid>
                    <Grid item md={12} xs={12} style={{ paddingRight: "15px" }}>
                      <Typography className={props.classes.p}>
                        Para reservar el cupo de su mascota a bordo, debes
                        cumplir con toda la documentación y peso exigido para
                        viajar en cabina y está sujeto a la disponibilidad del
                        cupo en al ruta y fecha en la que deseas realizar el
                        viaje.
                        <br />
                        <br />
                        Para mayor información de este servicio ingresa{" "}
                        <strong style={{ textDecoration: "underline" }}>
                          GCAPET
                        </strong>
                        . Para reservar el cupo es obligatorio llamar a nuestro
                        Call Center en los horarios de servicio establecidos.
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Grid
                    container
                    style={{ marginBlockStart: "1.5em" }}
                    spacing={2}
                  >
                    <Grid item md={6} xs={12}>
                      <div className={props.classes.inputDiv}>
                        <TextField
                          value={issueCountry}
                          label="TIPO DE MASCOTA *"
                          select
                          helperText={issueCountryError}
                          error={issueCountryError !== ""}
                          InputLabelProps={{
                            shrink: true,
                            className: props.classes.floatingLabelFocusStyle,
                          }}
                          InputProps={{
                            disabled: fields,
                            classes: {
                              input: props.classes.inputLabel,
                              underline: props.classes.underline,
                            },
                          }}
                          SelectProps={{
                            IconComponent: () => (
                              <ExpandMore
                                className={props.classes.expandIcon}
                              />
                            ),
                          }}
                          onChange={(e) => setIssueCountry(e.target.value)}
                        >
                          {petsTypes.map((pet) => (
                            <MenuItem
                              key={pet.code}
                              value={pet.code}
                              disabled={fields}
                            >
                              {pet.name}
                            </MenuItem>
                          ))}
                        </TextField>
                      </div>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <div className={props.classes.inputDiv}>
                        <TextField
                          value={issueCountry}
                          label="RAZA *"
                          select
                          helperText={issueCountryError}
                          error={issueCountryError !== ""}
                          InputLabelProps={{
                            shrink: true,
                            className: props.classes.floatingLabelFocusStyle,
                          }}
                          InputProps={{
                            disabled: fields,
                            classes: {
                              input: props.classes.inputLabel,
                              underline: props.classes.underline,
                            },
                          }}
                          SelectProps={{
                            IconComponent: () => (
                              <ExpandMore
                                className={props.classes.expandIcon}
                              />
                            ),
                          }}
                          onChange={(e) => setIssueCountry(e.target.value)}
                        >
                          {petsTypes.map((pet) => (
                            <MenuItem
                              key={pet.code}
                              value={pet.code}
                              disabled={fields}
                            >
                              {pet.name}
                            </MenuItem>
                          ))}
                        </TextField>
                      </div>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <div className={props.classes.inputDiv}>
                        <TextField
                          value={issueCountry}
                          label="EDAD *"
                          select
                          helperText={issueCountryError}
                          error={issueCountryError !== ""}
                          InputLabelProps={{
                            shrink: true,
                            className: props.classes.floatingLabelFocusStyle,
                          }}
                          InputProps={{
                            disabled: fields,
                            classes: {
                              input: props.classes.inputLabel,
                              underline: props.classes.underline,
                            },
                          }}
                          SelectProps={{
                            IconComponent: () => (
                              <ExpandMore
                                className={props.classes.expandIcon}
                              />
                            ),
                          }}
                          onChange={(e) => setIssueCountry(e.target.value)}
                        >
                          {petsTypes.map((pet) => (
                            <MenuItem
                              key={pet.code}
                              value={pet.code}
                              disabled={fields}
                            >
                              {pet.name}
                            </MenuItem>
                          ))}
                        </TextField>
                      </div>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <div className={props.classes.inputDiv}>
                        <TextField
                          value={issueCountry}
                          label="PESO CON MALETA (kg) *"
                          select
                          helperText={issueCountryError}
                          error={issueCountryError !== ""}
                          InputLabelProps={{
                            shrink: true,
                            className: props.classes.floatingLabelFocusStyle,
                          }}
                          InputProps={{
                            disabled: fields,
                            classes: {
                              input: props.classes.inputLabel,
                              underline: props.classes.underline,
                            },
                          }}
                          SelectProps={{
                            IconComponent: () => (
                              <ExpandMore
                                className={props.classes.expandIcon}
                              />
                            ),
                          }}
                          onChange={(e) => setIssueCountry(e.target.value)}
                        >
                          {petsTypes.map((pet) => (
                            <MenuItem
                              key={pet.code}
                              value={pet.code}
                              disabled={fields}
                            >
                              {pet.name}
                            </MenuItem>
                          ))}
                        </TextField>
                      </div>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <div className={props.classes.inputDiv}>
                        <StaticDatePicker
                          value={docExpirationDate}
                          label="FECHA ÚLTIMA VACUNA"
                          inputVariant={"standard"}
                          disabled={fields}
                          disablePast
                          toolbar={true}
                          helperText={selectDocExpirationDateError}
                          error={selectDocExpirationDateError !== ""}
                          InputLabelProps={{
                            shrink: true,
                            className: props.classes.floatingLabelFocusStyle,
                          }}
                          InputProps={{
                            disabled: fields,
                            classes: {
                              input: props.classes.inputLabel,
                              underline: props.classes.underline,
                            },
                          }}
                          onChange={(date) => {
                            setSelectDocExpirationDate(filterDate(date));
                            setDocExpirationDate(date);
                          }}
                        />
                      </div>
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <Typography
                        className={props.classes.allFieldsText}
                        style={{ padding: "8px" }}
                      >
                        * Todos los campos son obligatorios
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ) : null}
        </Grid>
      </Collapse>

      <Spinner loading={loading} />
    </div>
  );
}
