import urls from "../urls";

const passengerPromoData = async (code) => {
  const peticion = await fetch(`${urls.passengerPromoCode}?code=${code}`);
  const resp = await peticion.json();
  return {
    status: peticion.status,
    resp: resp
  };
};

const passengerServices = Object.freeze({
    passengerPromoData
});

export default passengerServices;
