import React from "react";
import { Grid } from "@material-ui/core";
export default function PaymentGBC(props) {
  //useEffect(() => {
  //  window.addEventListener("message", getMessage);
  //  // eslint-disable-next-line react-hooks/exhaustive-deps
  //}, []);
  return (
    <Grid
      container
      justify="center"
      direction="row"
      alignItems="center"
      spacing={1}
    >
      <Grid item md={5} xs={11}>
      <iframe
          style={{ border: 0 }}
          
          //src={`${urls.paymentPaypalButton}?pnr=${props.pnr}&button=paypal`}
          src="http://api.avior.com.ve:3000/api/v1/view/paypalbutton?pnr=4454545&button=paypal"
          title="Payment Credit Card"
          width="100%"
          height="550px"
          />
      </Grid>
    </Grid>
  );
}
