import Modal from "@material-ui/core/Modal";
import React, { useState } from "react";
import { Grid, Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 400,
    //height: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #FFF",
    boxShadow: theme.shadows[5],
    padding: "20px",
    borderRadius: "20px",
    outline: "none",
  },
  paper2: {
    position: "absolute",
    width: 400,
    height: 200,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #FFF",
    boxShadow: theme.shadows[5],
    padding: "20px",
    borderRadius: "20px",
    outline: "none",
  },
  aceptButtonLabel: {
    color: "#ffffff",
    fontSize: "0.8rem",
  },
  h2: {
    color: "#777777",
    fontWeight: "unset",
    fontSize: "1rem",
  },
  modalText: {
    color: "#575756",
    fontWeight: "bold",
    fontSize: "0.8rem",
  },
  p: {
    fontSize: "1.3rem",
    color: "#848484",
  },
  highlightedP: {
    fontSize: "1.5rem",
    color: "#af0061",
  },
  [theme.breakpoints.down("sm")]: {
    h2: {
      fontSize: "1.1rem",
    },
    p: {
      fontSize: "1rem",
    },
    highlightedP: {
      fontSize: "1.2rem",
    },
    paper: {
      width: 340,
      height: 410,
    },
  },
}));
export default function ModalTimeout(props) {
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);

  function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
  }

  return (
    <Modal open={props.open} onClose={props.close} disableBackdropClick={true}>
      <Grid
        container
        justify="center"
        style={modalStyle}
        className={classes.paper}
      >
        <Grid item md={12} xs={12} style={{ backgroundColor: "#FFF" }}>
          <Typography className={classes.h2} align="center">
            ¡SE ACABÓ SU TIEMPO DE <strong>RESERVA</strong>!
          </Typography>
          <br />
          <Typography className={classes.modalText}>
            Su tiempo para completar esta reserva ha terminado, usted no ejecutó
            el pago, debe empezar de nuevo.
          </Typography>
          <br />
          <Typography className={classes.modalText}>
            Le invitamos a ingresar de nuevo los datos y empezar una nueva
            consulta.
          </Typography>
          <Grid
            container
            justify="flex-end"
            alignItems="center"
            direction="column"
            style={{
              height: "140px",
            }}
          >
            <div
              style={{ width: "100%", height: "60px", marginBottom: "20px" }}
            >
              <Button
                variant="contained"
                fullWidth
                color={"primary"}
                style={{ height: "100%" }}
                onClick={props.modalCheckFlight}
              >
                <label className={classes.aceptButtonLabel}>
                  {
                    "Carga la selección de vuelos anterior y comprueba disponibilidad y tarifas."
                  }
                </label>
              </Button>
            </div>
            <div style={{ width: "100%", height: "41px" }}>
              <Button
                variant="contained"
                fullWidth
                color={"primary"}
                style={{ height: "100%" }}
                onClick={props.modalInit}
              >
                <label className={classes.aceptButtonLabel}>{"Iniciar."}</label>
              </Button>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
}
