import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  h2: {
    color: "#777777",
    fontWeight: "unset",
    fontSize: "1.2rem",
  },
  p: {
    fontSize: "1.1rem",
    color: "#848484",
  },
  highlightedP: {
    fontSize: "2.5rem",
    color: "#af0061",
  },
  [theme.breakpoints.down("sm")]: {
    h2: {
      fontSize: "1.1rem",
    },
    p: {
      fontSize: "1rem",
    },
    highlightedP: {
      fontSize: "1.2rem",
    },
  },
}));
export default function Title(props) {
  const classes = useStyles();
  return (
    <Grid
      container
      justify="flex-start"
      direction="column"
      style={{ marginBottom: "10px" }}
    >
      <Grid item md={12} xs={12}>
        {props.h2 ? (
          <Typography className={classes.h2}>
            {props.h2} <strong>{props.strong}</strong>
          </Typography>
        ) : null}
        {props.p ? (
          <Typography
            className={props.highlightedP ? classes.highlightedP : classes.p}
          >
            {props.p}
          </Typography>
        ) : null}
      </Grid>
    </Grid>
  );
}
