import urls from "../urls";
const getTransfers = async ({ ...transferRequest }) => {
    const token = localStorage.getItem("token");
    const peticion = await fetch(`${urls.getTransfers}?page=${transferRequest.page}&limit=${transferRequest.limit}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        }
    });
    const resp = await peticion.json();
    return {
        status: peticion.status,
        resp
    };
}

const getTransfersByType = async ({ page, limit, type, status, startDate, endDate }) => {
    const token = localStorage.getItem("token");
    const peticion = await fetch(`${urls.getTransfers}?type=${type}${status && status !== 'all' ? `&status=${status}` : ''}&page=${page}&limit=${limit}${startDate ? `&startDate=${startDate}` : ''}${endDate ? `&endDate=${endDate}` : ''}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        }
    });
    const resp = await peticion.json();
    return {
        status: peticion.status,
        resp
    };
}

const aproveTransfer = async ({ ...transferRequest }) => {
    const token = localStorage.getItem("token");
    const peticion = await fetch(urls.aproveTransfer, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(transferRequest)
    });
    const resp = await peticion.json();
    return {
        status: peticion.status,
        resp
    };
}
const updateTransfer = async (_id, status) => {
    const token = localStorage.getItem("token");
    const peticion = await fetch(`${urls.updateTransfer}/${_id}`, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ status })
    });
    const resp = await peticion.json();
    return {
        status: peticion.status,
        resp
    };
}
const getTransfersAviorGo = async ({ ...transferRequest }) => {
    const token = localStorage.getItem("token");
    const peticion = await fetch(`${urls.getTransfers}?page=${transferRequest.page}&limit=${transferRequest.limit}&tourCode=true`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        }
    });
    const resp = await peticion.json();
    return {
        status: peticion.status,
        resp
    };
}

const getTransfersBss = async ({ ...transferRequest }) => {
    const token = localStorage.getItem("token");
    const peticion = await fetch(`${urls.getTransfersBss}?page=${transferRequest.page}&limit=${transferRequest.limit}&startDate=${transferRequest.startDate || ""}&endDate=${transferRequest.endDate || ""}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        }
    });
    const resp = await peticion.json();
    return {
        status: peticion.status,
        resp
    };
}

const aproveTransferBss = async ({ ...transferRequest }) => {
    //console.log(JSON.stringify(transferRequest))
    const token = localStorage.getItem("token");
    const peticion = await fetch(urls.aproveTransferBss, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(transferRequest)
    });
    const resp = await peticion.json();
    return {
        status: peticion.status,
        resp
    };
}
const updateTransferBss = async (_id, status) => {
    const token = localStorage.getItem("token");
    const peticion = await fetch(`${urls.updateTransferBss}/${_id}`, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ status })
    });
    const resp = await peticion.json();
    return {
        status: peticion.status,
        resp
    };
}
const getTransfersAviorGoBss = async ({ ...transferRequest }) => {
    const token = localStorage.getItem("token");
    const peticion = await fetch(`${urls.getTransfersBss}?page=${transferRequest.page}&limit=${transferRequest.limit}&tourCode=true`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        }
    });
    const resp = await peticion.json();
    return {
        status: peticion.status,
        resp
    };
}

const getTransferQuery = async ({ ...transferRequest }) => {
    const token = localStorage.getItem("token");
    const peticion = await fetch(`${urls.queryTransfers}?page=${transferRequest.page}&limit=${transferRequest.limit}&query=${transferRequest.query}&type=${transferRequest.type}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        }
    });
    const resp = await peticion.json();
    return {
        status: peticion.status,
        resp
    };
}

const getTransferQueryBss = async ({ ...transferRequest }) => {
    const token = localStorage.getItem("token");
    const peticion = await fetch(`${urls.queryTransfersBss}?page=${transferRequest.page}&limit=${transferRequest.limit}&query=${transferRequest.query}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        }
    });
    const resp = await peticion.json();
    return {
        status: peticion.status,
        resp
    };
}

const exportTransfers = async (type) =>{
    const token = localStorage.getItem("token");
    const peticion = await fetch(`${urls.exportTransfers}?type=${type}`, {
        method: "GET",
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return await peticion.blob();
}

const exportTransfersBss = async ({ startDate, endDate }) => {
    const token = localStorage.getItem("token");
    const peticion = await fetch(`${urls.exportTransfersBss}?startDate=${startDate || ""}&endDate=${endDate || ""}`, {
        method: "GET",
        headers: {
            Authorization: `Bearer ${token} `
        }
    });
    return await peticion.blob();
}

const exportTransfersByType = async ({ type, startDate, endDate }) => {
    const token = localStorage.getItem("token");
    const peticion = await fetch(`${urls.exportTransfers}?type=${type}&startDate=${startDate || ""}&endDate=${endDate || ""}`, {
        method: "GET",
        headers: {
            Authorization: `Bearer ${token} `
        }
    });
    return await peticion.blob();
}

const getTotalBalance = async () => {
    const token = localStorage.getItem("token");
    const peticion = await fetch(`${urls.transfersGetTotalBalance}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token} `
        }
    });
    const resp = await peticion.json();
    return {
        status: peticion.status,
        resp
    };
}

const transferServices = Object.freeze({
    getTransfers,
    getTransfersByType,
    aproveTransfer,
    updateTransfer,
    getTransfersAviorGo,
    getTransfersBss,
    aproveTransferBss,
    updateTransferBss,
    getTransfersAviorGoBss,
    getTransferQuery,
    getTransferQueryBss,
    exportTransfers,
    exportTransfersBss,
    exportTransfersByType,
    getTotalBalance
});

export default transferServices;