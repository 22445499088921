import React, { useState, useEffect } from "react";
import { StaticDatePicker, Title } from "../components/index";
import { Grid, MenuItem, TextField } from "@material-ui/core";
import countries from "../helpers/countries.json";
import { filterDate, FormValidator, getPassengerType } from "../helpers/";
import { Button } from "@material-ui/core/";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { styled } from "@material-ui/core/styles";
import { filterNumber, onBlurEmail } from "../helpers/filterValues";

const MyExpandExpandLess = styled(ExpandLess)({
  color: "#FA7268",
  "&.MuiSelect-iconOpen": {
    transform: "none",
  },
});
const MyExpandExpandMore = styled(ExpandMore)({
  color: "#FA7268",
  "&.MuiSelect-iconOpen": {
    transform: "none",
  },
});
let indexGender = 0;
const genders1 = [
  { key: indexGender++, name: "Masculino", value: "M" },
  { key: indexGender++, name: "Femenino", value: "F" },
  { key: indexGender++, name: "Otro", value: "U" },
];
let indexDocument = 0;
const documentType = [
  { key: indexDocument++, name: "Pasaporte", value: "PP" },
  { key:indexDocument++, name:"Doc de identidad", value:"ID" }
];
export default function CustomerDataForm(props) {
  const validatorPhone = new FormValidator([
    {
      field: "phoneNumber",
      method: "isEmpty",
      validWhen: false,
      message: "Ingrese numero de telefono",
    },
  ]);

  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [gender, setGender] = useState("");

  const [birthDay, setBirthDay] = useState(null);
  const [selectBirthDay, setSelectBirthDay] = useState("");
  const [phoneCode, setPhoneCode] = useState("+58");
  const [phoneNumber, setPhoneNumber] = useState("");

  const [birthCountry, setBirthCountry] = useState("");
  const [email, setEmail] = useState("");

  const [docType, setDocType] = useState("");
  const [docNumber, setDocNumer] = useState("");
  const [issueCountry, setIssueCountry] = useState("");
  const [docExpirationDate, setDocExpirationDate] = useState(null);
  const [selectDocExpirationDate, setSelectDocExpirationDate] = useState("");

  const [passenger, setPassenger] = useState({
    birthCountry: "",
    birthDate: "",
    document: {
      docIssueCountry: "",
      documentID: "",
      documentType: "",
    },
    email: "",
    gender: "",
    lastName: "",
    name: "",
    type: "",
  });

  const [formVisible, setFormVisible] = useState(true);
  const [fields, setFields] = useState(false);

  const [nameError, setNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [genderError, setGenderError] = useState("");
  const [selectBirthDayError, setSelectBirthDayError] = useState("");
  const [selectBirthDayTypeError, setSelectBirthDayTypeError] = useState("");
  const [birthCountryError, setBirthCountryError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [docTypeError, setDocTypeError] = useState("");
  const [docNumberError, setDocNumberError] = useState("");
  const [issueCountryError, setIssueCountryError] = useState("");
  const [
    selectDocExpirationDateError,
    setSelectDocExpirationDateError,
  ] = useState("");
  const [disabledDocExpirationDate, setDisabledDocExpirationDate] = useState(
    false
  );
  const [type, setType] = useState("ADT")
  const validator = new FormValidator([
    {
      field: "name",
      method: "isEmpty",
      validWhen: false,
      message: "Debe ingresar nombre del pasajero",
    },
    {
      field: "lastName",
      method: "isEmpty",
      validWhen: false,
      message: "Debe ingresar apellido del pasajero",
    },
    {
      field: "selectBirthDay",
      method: "isEmpty",
      validWhen: false,
      message: "Seleccione la fecha de nacimiento",
    },
    {
      field: "gender",
      method: "isEmpty",
      validWhen: false,
      message: "Debe seleccionar un genero",
    },
    {
      field: "birthCountry",
      method: "isEmpty",
      validWhen: false,
      message: "Seleccione pais de nacimiento",
    },
    {
      field: "email",
      method: "isEmpty",
      validWhen: false,
      message: "Debe ingresar email",
    },
    {
      field: "email",
      method: "isEmail",
      validWhen: true,
      message: "Ingrese un correo electrónico valido",
    },
    {
      field: "docType",
      method: "isEmpty",
      validWhen: false,
      message: "Seleccione tipo de documento",
    },
    {
      field: "docNumber",
      method: "isEmpty",
      validWhen: false,
      message: "Ingrese numero de documento",
    },
    {
      field: "issueCountry",
      method: "isEmpty",
      validWhen: false,
      message: "Seleccione pais del documento",
    },
    {
      field: "selectDocExpirationDate",
      method: "isEmpty",
      validWhen: false,
      message: "Seleccione la fecha de vencimiento",
    },
  ]);
  useEffect(() => {
    if (parseInt(props.index) === 1 && props.passenger) {
      const user = props.passenger;
      setName(user.name.toUpperCase());
      setLastName(user.lastName.toUpperCase());
      setGender(user.gender);
      setBirthCountry(user.birthCountry);
      setDocNumer(user.document.documentID);
      setDocType(user.document.documentType);
      if (user.document.documentType === "ID") {
        setDisabledDocExpirationDate(true);
        setSelectDocExpirationDate("");
        setDocExpirationDate(null);
        setIssueCountry("");
      } else {
        setIssueCountry(user.document.docIssueCountry);
        setDocExpirationDate(user.document.docExpirationDate);
        setSelectDocExpirationDate(user.document.docExpirationDate);
      }
      setBirthDay(new Date(user.birthDate.replace(/-/g, "/")));
      setSelectBirthDay(user.birthDate);
      if(user.type==="ADT"){
        setPhoneCode(user.phone.code);
        setPhoneNumber(user.phone.number);
      }
      setEmail(user.email);
      setType(user.type)
    }
  }, [props.index, props.passenger]);
  const validateData = async () => {
    setSelectBirthDayTypeError("");
    let validation;
    let validatePhoneNumber;
    let passengerAux;
    if (!fields) {
      validation = validator.validate({
        name,
        lastName,
        gender,
        selectBirthDay,
        birthCountry,
        docType,
        docNumber,
        issueCountry,
        email,
        selectDocExpirationDate,
      });
      setNameError(validation.name.message);
      setLastNameError(validation.lastName.message);
      setGenderError(validation.gender.message);
      setSelectBirthDayError(validation.selectBirthDay.message);
      setBirthCountryError(validation.birthCountry.message);
      setEmailError(validation.email.message);
      setDocTypeError(validation.docType.message);
      setDocNumberError(validation.docNumber.message);
      setIssueCountryError(validation.issueCountry.message);
      setSelectDocExpirationDateError(
        validation.selectDocExpirationDate.message
      );
      if (validation.isValid) {
        setFields(true);
        if(type==="ADT"){
          validatePhoneNumber = validatorPhone.validate({ phoneNumber });
          setPhoneNumberError(validatePhoneNumber.phoneNumber.message);
          if(validatePhoneNumber.isValid){
            passengerAux = {
              name: name.trim(),
              lastName: lastName.trim(),
              gender: gender,
              birthDate: selectBirthDay,
              birthCountry: birthCountry,
              document: {
                docIssueCountry: issueCountry,
                documentID: docNumber,
                documentType: docType,
                docExpirationDate: selectDocExpirationDate,
              },
              phone: {
                code: phoneCode,
                number: phoneNumber,
              },
              email: email,
              type: getPassengerType(selectBirthDay),
              id: props.index,
            };
            setPassenger(passengerAux);
            setFormVisible(!formVisible);
            props.addPassenger(passengerAux);
          }
        }
        else{
          passengerAux = {
            name: name.trim(),
            lastName: lastName.trim(),
            gender: gender,
            birthDate: selectBirthDay,
            birthCountry: birthCountry,
            document: {
              docIssueCountry: issueCountry,
              documentID: docNumber,
              documentType: docType,
              docExpirationDate: selectDocExpirationDate,
            },
            email: email,
            type: getPassengerType(selectBirthDay),
            id: props.index,
          };
          setPassenger(passengerAux);
          setFormVisible(!formVisible);
          props.addPassenger(passengerAux);
        }
      }
    } else {
      if (formVisible === false) setFormVisible(true);
      setFields(!fields);
      props.deletePassenger(passenger);
    }
  };
  return (
    <div className={props.classes.container}>
      <Grid container direction="row" style={{ padding: 0, height: "50px" }}>
        <Grid
          item
          md={10}
          xs={10}
          className={props.classes.headerFormContainerLeft}
        >
          <Grid container direction="row" style={{ padding: 0 }}>
            <Grid item md={1} xs={1} className={props.classes.parent}>
              <img src="./img/icono-pasajero.png" alt="passenger-icon" />
            </Grid>
            <Grid item md={11} xs={11}>
              <div className={props.classes.divWord}>
                <p className={props.classes.buttonLabel}>
                  {name === "" && lastName === ""
                    ?" PASAJERO "
                    : name + " " + lastName}
                </p>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          md={1}
          xs={1}
          className={props.classes.headerFormContainerRight}
          style={{ borderRightWidth: 0 }}
        >
          {fields ? (
            <Button
              variant="contained"
              className={props.classes.button}
              fullWidth={true}
              style={{
                backgroundColor: "transparent",
                boxShadow: "none",
                padding: 0,
                minWidth: "100%",
              }}
              onClick={validateData}
            >
              <div className={props.classes.divIcon}>
                <img src="./img/icono-editar.png" alt="edit-icon" />
              </div>
            </Button>
          ) : null}
        </Grid>
        <Grid
          item
          md={1}
          xs={1}
          className={props.classes.headerFormContainerRight}
        >
          <Button
            variant="contained"
            className={props.classes.button}
            fullWidth={true}
            style={{
              backgroundColor: "transparent",
              boxShadow: "none",
              padding: 0,
              minWidth: "100%",
            }}
            onClick={() => setFormVisible(!formVisible)}
          >
            <div className={props.classes.divIcon}>
              {!formVisible ? <MyExpandExpandMore /> : <MyExpandExpandLess />}
            </div>
          </Button>
        </Grid>
      </Grid>
      {!formVisible ? null : (
        <Grid
          container
          direction="column"
          className={props.classes.gridContainer}
        >
          <Grid item xs={12} md={12}>
            <Grid container className={props.classes.Grid} spacing={2}>
              <Grid item md={3} xs={12} className={props.classes.inputGrid}>
                <div className={props.classes.inputDiv}>
                  <TextField
                    name="firstName"
                    value={name}
                    label="NOMBRE (S) *"
                    fullWidth
                    id={`${"firstName"}${props.index}`}
                    helperText={nameError}
                    error={nameError !== ""}
                    InputLabelProps={{
                      shrink: true,
                      className: props.classes.floatingLabelFocusStyle,
                      htmlFor: `${"firstName"}${props.index}`,
                    }}
                    InputProps={{
                      disabled: fields,
                      classes: {
                        input: props.classes.inputLabel,
                        underline: props.classes.underline,
                      },
                    }}
                    onChange={(e) =>
                      setName(
                        e.target.value.toUpperCase().replace(/[^A-Z ]/g, "")
                      )
                    }
                  />
                </div>
              </Grid>

              <Grid item md={3} xs={12} className={props.classes.inputGrid}>
                <div className={props.classes.inputDiv}>
                  <TextField
                    name="lastname"
                    value={lastName}
                    label="APELLIDOS (S) *"
                    fullWidth
                    id={`${"lastname"}${props.index}`}
                    helperText={lastNameError}
                    error={lastNameError !== ""}
                    InputLabelProps={{
                      shrink: true,
                      className: props.classes.floatingLabelFocusStyle,
                      htmlFor: `${"lastname"}${props.index}`,
                    }}
                    InputProps={{
                      disabled: fields,
                      classes: {
                        input: props.classes.inputLabel,
                        underline: props.classes.underline,
                      },
                    }}
                    onChange={(e) =>
                      setLastName(
                        e.target.value.toUpperCase().replace(/[^A-Z ]/g, "")
                      )
                    }
                  />
                </div>
              </Grid>

              <Grid item md={3} xs={12} className={props.classes.inputGrid}>
                <div className={props.classes.inputDiv}>
                  <TextField
                    value={gender}
                    label="GÉNERO *"
                    select
                    id={`${"gender"}${props.index}`}
                    helperText={genderError}
                    error={genderError !== ""}
                    InputLabelProps={{
                      shrink: true,
                      className: props.classes.floatingLabelFocusStyle,
                      htmlFor: `${"gender"}${props.index}`,
                    }}
                    InputProps={{
                      disabled: fields,
                      classes: {
                        input: props.classes.inputLabel,
                        underline: props.classes.underline,
                      },
                    }}
                    SelectProps={{ IconComponent: MyExpandExpandMore }}
                    onChange={(e) => setGender(e.target.value)}
                  >
                    {
                      //props.type==='ADT' || props.type==='CNN'?
                      genders1.map((gender) => (
                        <MenuItem
                          key={gender.key}
                          value={gender.value}
                          disabled={fields}
                        >
                          {gender.name}
                        </MenuItem>
                      ))
                      //:
                      //    genders2.map((gender) => (
                      //        <MenuItem key={gender.key} value={gender.value} disabled={fields}>
                      //            {gender.name}
                      //        </MenuItem>
                      //    ))
                    }
                  </TextField>
                </div>
              </Grid>
              <Grid item md={3} xs={12} className={props.classes.inputGrid}>
                <div className={props.classes.inputDiv}>
                  <StaticDatePicker
                    value={birthDay}
                    label="FECHA DE NACIMIENTO *"
                    toolbar={true}
                    disableFuture
                    disabled={fields}
                    minDate={props.minDate}
                    maxDate={props.maxDate}
                    inputVariant={"standard"}
                    id={`${"birthDay"}${props.index}`}
                    helperText={selectBirthDayError || selectBirthDayTypeError}
                    error={
                      selectBirthDayError !== "" ||
                      selectBirthDayTypeError !== ""
                    }
                    InputLabelProps={{
                      shrink: true,
                      className: props.classes.floatingLabelFocusStyle,
                      htmlFor: `${"birthDay"}${props.index}`,
                    }}
                    InputProps={{
                      disabled: fields,
                      classes: {
                        input: props.classes.inputLabel,
                        underline: props.classes.underline,
                      },
                    }}
                    onChange={(date) => {
                      setType(getPassengerType(date))
                      setSelectBirthDay(filterDate(date));
                      setBirthDay(date);
                    }}
                  />
                </div>
              </Grid>
              <Grid item md={3} xs={12} className={props.classes.inputGrid}>
                <div className={props.classes.inputDiv}>
                  <TextField
                    value={birthCountry}
                    label="NACIONALIDAD"
                    select
                    id={`${"birthCountry"}${props.index}`}
                    helperText={birthCountryError}
                    error={birthCountryError !== ""}
                    InputLabelProps={{
                      shrink: true,
                      className: props.classes.floatingLabelFocusStyle,
                      htmlFor: `${"birthCountry"}${props.index}`,
                    }}
                    InputProps={{
                      disabled: fields,
                      classes: {
                        input: props.classes.inputLabel,
                        underline: props.classes.underline,
                      },
                    }}
                    SelectProps={{ IconComponent: MyExpandExpandMore }}
                    onChange={(e) => setBirthCountry(e.target.value)}
                  >
                    {countries.map((countrie) => (
                      <MenuItem
                        key={countrie.code}
                        value={countrie.code}
                        disabled={fields}
                      >
                        {countrie.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
              </Grid>
              {type === "ADT" ? (
                <Grid item md={3} xs={12} className={props.classes.inputGrid}>
                  <div className={props.classes.inputDiv}>
                    <TextField
                      value={phoneCode}
                      label={"PAIS *"}
                      select
                      id={`${"phoneCode"}${props.index}`}
                      InputLabelProps={{
                        shrink: true,
                        className: props.classes.floatingLabelFocusStyle,
                        htmlFor: `${"phoneCode"}${props.index}`,
                      }}
                      InputProps={{
                        disabled: fields,
                        classes: {
                          input: props.classes.inputLabel,
                          underline: props.classes.underline,
                        },
                      }}
                      SelectProps={{ IconComponent: MyExpandExpandMore }}
                      onChange={(e) => setPhoneCode(e.target.value)}
                    >
                      {countries.map((countrie) => (
                        <MenuItem
                          key={countrie.code}
                          value={countrie.phone_code}
                          disabled={fields}
                        >
                          {countrie.name + " " + countrie.phone_code}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                </Grid>
              ) : null}
              {type === "ADT" ? (
                <Grid item md={3} xs={12} className={props.classes.inputGrid}>
                  <div className={props.classes.inputDiv}>
                    <TextField
                      value={phoneNumber}
                      label="TELEFONO *"
                      name="number"
                      fullWidth
                      id={`${"phoneNumber"}${props.index}`}
                      helperText={phoneNumberError}
                      error={phoneNumberError !== ""}
                      InputLabelProps={{
                        shrink: true,
                        className: props.classes.floatingLabelFocusStyle,
                        htmlFor: `${"phoneNumber"}${props.index}`,
                      }}
                      InputProps={{
                        disabled: fields,
                        classes: {
                          input: props.classes.inputLabel,
                          underline: props.classes.underline,
                        },
                      }}
                      //onBlur={onBlurPhone}
                      onChange={(e) =>
                        setPhoneNumber(filterNumber(e.target.value))
                      }
                    />
                  </div>
                </Grid>
              ) : null}
              {type === "ADT" ? (
                <Grid
                  item
                  md={3}
                  xs={12}
                  className={props.classes.inputGrid}
                ></Grid>
              ) : null}
            </Grid>
          </Grid>
          <Title
            h2={"DOCUMENTO"}
            strong={"PASAJERO"}
            p={
              "La siguiente información será utilizada para informarte cualquier novedad, cambio o modificación que se presente antes de tu viaje."
            }
          />
          <Grid item md={12} xs={12}>
            <Grid
              container
              justify="flex-start"
              direction="row"
              className={props.classes.Grid}
              spacing={2}
            >
              <Grid item md={3} xs={12} className={props.classes.inputGrid}>
                <div className={props.classes.inputDiv}>
                  <TextField
                    value={docType}
                    label="TIPO DE DOCUMENTO *"
                    select
                    id={`${"docType"}${props.index}`}
                    helperText={docTypeError}
                    error={docTypeError !== ""}
                    InputLabelProps={{
                      shrink: true,
                      className: props.classes.floatingLabelFocusStyle,
                      htmlFor: `${"docType"}${props.index}`,
                    }}
                    InputProps={{
                      disabled: fields,
                      classes: {
                        input: props.classes.inputLabel,
                        underline: props.classes.underline,
                      },
                    }}
                    SelectProps={{ IconComponent: MyExpandExpandMore }}
                    onChange={(e) => {
                      setDocType(e.target.value);
                      if (e.target.value === "ID") {
                        setIssueCountry("VE");
                      } else {
                        setDisabledDocExpirationDate(false);
                      }
                    }}
                  >
                    {documentType.map((document) => (
                      <MenuItem
                        key={document.key}
                        value={document.value}
                        disabled={fields}
                      >
                        {document.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
              </Grid>
              <Grid item md={3} xs={12} className={props.classes.inputGrid}>
                <div className={props.classes.inputDiv}>
                  <TextField
                    value={docNumber}
                    name="number"
                    label="NÚMERO DE DOCUMENTO *"
                    fullWidth
                    id={`${"docNumber"}${props.index}`}
                    helperText={docNumberError}
                    error={docNumberError !== ""}
                    InputLabelProps={{
                      shrink: true,
                      className: props.classes.floatingLabelFocusStyle,
                      htmlFor: `${"docNumber"}${props.index}`,
                    }}
                    InputProps={{
                      disabled: fields,
                      classes: {
                        input: props.classes.inputLabel,
                        underline: props.classes.underline,
                      },
                    }}
                    onChange={(e) => setDocNumer(filterNumber(e.target.value))}
                    //onBlur={docNumberExists}
                  />
                </div>
              </Grid>
              <Grid item md={3} xs={12} className={props.classes.inputGrid}>
                <div className={props.classes.inputDiv}>
                  <TextField
                    value={issueCountry}
                    label="PAÍS EMISOR *"
                    select
                    id={`${"issueCountry"}${props.index}`}
                    helperText={issueCountryError}
                    error={issueCountryError !== ""}
                    InputLabelProps={{
                      shrink: true,
                      className: props.classes.floatingLabelFocusStyle,
                      htmlFor: `${"issueCountry"}${props.index}`,
                    }}
                    InputProps={{
                      disabled: fields || disabledDocExpirationDate,
                      classes: {
                        input: props.classes.inputLabel,
                        underline: props.classes.underline,
                      },
                    }}
                    SelectProps={{ IconComponent: MyExpandExpandMore }}
                    onChange={(e) => setIssueCountry(e.target.value)}
                    disabled={fields || disabledDocExpirationDate}
                  >
                    {countries.map((countrie) => (
                      <MenuItem
                        key={countrie.code}
                        value={countrie.code}
                        disabled={fields}
                      >
                        {countrie.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
              </Grid>
              <Grid item md={3} xs={12} className={props.classes.inputGrid}>
                <div className={props.classes.inputDiv}>
                  <StaticDatePicker
                    value={docExpirationDate}
                    label="VIGENCIA DOCUMENTO"
                    inputVariant={"standard"}
                    id={`${"docExpirationDate"}${props.index}`}
                    disabled={fields || disabledDocExpirationDate}
                    toolbar={true}
                    disablePast
                    helperText={selectDocExpirationDateError}
                    error={selectDocExpirationDateError !== ""}
                    InputLabelProps={{
                      shrink: true,
                      className: props.classes.floatingLabelFocusStyle,
                      htmlFor: `${"docExpirationDate"}${props.index}`,
                    }}
                    InputProps={{
                      disabled: fields,
                      classes: {
                        input: props.classes.inputLabel,
                        underline: props.classes.underline,
                      },
                    }}
                    onChange={(date) => {
                      setSelectDocExpirationDate(filterDate(date));
                      setDocExpirationDate(date);
                    }}
                  />
                </div>
              </Grid>
              <Grid item md={3} xs={12} className={props.classes.inputGrid}>
                <div className={props.classes.inputDiv}>
                  <TextField
                    value={email}
                    name="email"
                    label="E-MAIL *"
                    fullWidth
                    id={`${"email"}${props.index}`}
                    helperText={emailError}
                    error={emailError !== ""}
                    InputLabelProps={{
                      shrink: true,
                      className: props.classes.floatingLabelFocusStyle,
                      htmlFor: `${"email"}${props.index}`,
                    }}
                    InputProps={{
                      disabled: fields,
                      classes: {
                        input: props.classes.inputLabel,
                        underline: props.classes.underline,
                      },
                    }}
                    onChange={(e) => setEmail(e.target.value)}
                    onBlur={() => {
                      setEmailError(onBlurEmail(email));
                    }}
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>
          {!fields ? (
            <Grid container justify="flex-end">
              <div style={{ width: "158px", height: "41px" }}>
                <Button
                  variant="contained"
                  color={"primary"}
                  fullWidth
                  style={{ height: "100%" }}
                  onClick={validateData}
                >
                  <label className={props.classes.aceptButtonLabel}>
                    {"Aceptar"}
                  </label>
                </Button>
              </div>
            </Grid>
          ) : null}
        </Grid>
      )}
    </div>
  );
}
