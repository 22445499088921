import React from "react";
import { Grid, Typography } from "@material-ui/core";

export default function PassengerDetailsInfo(props) {
  return (
    <>
      <Grid
        item
        md={6}
        xs={12}
        style={{ marginBottom: "20px", display: "flex" }}
      >
        <Grid container>
          <Grid item md={12} xs={12} style={{ marginBottom: "10px" }}>
            <Grid container>
              <Grid item md={6} xs={6}>
                <Typography
                  style={{ fontWeight: "bold" }}
                  className={props.classes.text}
                >
                  Nombre:
                </Typography>
              </Grid>
              <Grid
                item
                md={6}
                xs={6}
                style={{ borderBottom: "2pt dotted #000" }}
              >
                <Typography className={props.classes.text}>
                  {`${
                    props.passenger.firstName
                      ? props.passenger.firstName
                      : props.passenger.name
                  } ${props.passenger.lastName}`}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          {!props.installments && (
            <Grid item md={12} xs={12} style={{ marginBottom: "10px" }}>
              <Grid container>
                <Grid item md={6} xs={6}>
                  <Typography
                    style={{ fontWeight: "bold" }}
                    className={props.classes.text}
                  >
                    Número de tiquete:
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={6}
                  style={{ borderBottom: "2pt dotted #000" }}
                >
                  <Typography className={props.classes.text}>
                    {props.passenger?.ticketNumber
                      ? props.passenger.ticketNumber
                      : props.passenger?.ticket}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid
        item
        md={6}
        xs={12}
        style={{ marginBottom: "20px", display: "flex" }}
      >
        <Grid container justify="flex-end">
          <Grid item md={10} xs={12} style={{ marginBottom: "10px" }}>
            <Grid container>
              <Grid item md={6} xs={6}>
                <Typography
                  style={{ fontWeight: "bold" }}
                  className={props.classes.text}
                >
                  Correo:
                </Typography>
              </Grid>
              <Grid
                item
                md={6}
                xs={6}
                style={{ borderBottom: "2pt dotted #000" }}
              >
                <Typography className={props.classes.text}>
                  {props.email}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          {/*<Grid item md={10} xs={12} style={{marginBottom:"10px"}}>
                        <Grid container>
                            <Grid item md={6} xs={6}>
                                <Typography style={{fontWeight:"bold"}}  className={props.classes.text}>
                                    Telefono:
                                </Typography>
                            </Grid>
                            <Grid item md={6} xs={6} style={{borderBottom: "2pt dotted #000"}}>
                                <Typography  className={props.classes.text}>
                                    {props.user? props.user.nombre: "234567890"}
                                </Typography>
                            </Grid>
                        </Grid>
                        </Grid>*/}
        </Grid>
      </Grid>
    </>
  );
}
