import React, { useEffect } from "react";
import { Box, makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  span: {
    fontWeight: "bold",
    fontSize: "1.25rem",
  },
  [theme.breakpoints.down("sm")]: {
    span: {
      fontSize: "1rem",
    },
  },
}));

const TimerClock = ({ timeLeft, setTimeLeft }) => {
  const classes = useStyles();

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes}:${secs < 10 ? "0" : ""}${secs}`;
  };

  useEffect(() => {
    if (timeLeft <= 0) return;

    const timer = setInterval(() => {
      setTimeLeft((prev) => prev - 1);
    }, 1000);

    return () => clearInterval(timer);
    // eslint-disable-next-line
  }, [timeLeft]);

  return (
    <Box display="flex" justifyContent="center" marginBottom="20px">
      <Typography color="textSecondary" className={classes.span}>
        {formatTime(timeLeft)}
      </Typography>
    </Box>
  );
};

export default TimerClock;
