/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import {
  CssBaseline,
  TextField,
  Typography,
  Container,
} from "@material-ui/core/";
import { makeStyles } from "@material-ui/core/styles";
import urls from "../urls";
import { useHistory } from "react-router-dom";
import { ButtonSpinner, Toast, Copyright } from "../components/";
const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    width: 100,
    height: 100,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function PayementLinkGenerator() {
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [pnr, setPnr] = useState("");
  const [loading, setLoading] = useState(false);
  const [toast, setToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  let history = useHistory();
  const login = async () => {
    setLoading(true);
    const peticion = await fetch(
      `${urls.generatePaymentLink}/${pnr}?email=${email}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Basic " + btoa("avior:fazQMUyq"),
        },
      }
    );
    setLoading(false);
    const resp = await peticion.json();
    //console.log(resp)
    if (peticion.status === 200) {
      setToast(true);
      setToastMessage(resp.message);
      setEmail("");
      setPnr("");
    } else {
      setToast(true);
      setToastMessage(resp.error.message);
    }
  };
  return (
    <Container component="main" maxWidth="xs">
      <Toast
        message={toastMessage}
        open={toast}
        close={() => {
          setToast(false);
        }}
      />
      <CssBaseline />
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          Generar link de pago
        </Typography>
        <form className={classes.form}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="PNR"
            name="pnr"
            autoFocus
            value={pnr}
            onChange={(e) => setPnr(e.target.value.toUpperCase())}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value.toLowerCase())}
          />
          <ButtonSpinner
            fullWidth
            action={login}
            loading={loading}
            text="Enviar"
          />
        </form>
      </div>
    </Container>
  );
}
