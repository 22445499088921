import React, { useState, useEffect } from "react";
import { Grid, Typography, Toolbar } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  Footer,
  TravelInfo,
  PassengerDetailsInfo,
  ModalPopUp,
  ModalTravelAssist,
} from "../components/";
import { useLocation, useHistory } from "react-router-dom";
import { FormatCurrency, validateRoute } from "../helpers";
const useStyles = makeStyles((theme) => ({
  divPassengers: {
    width: "",
  },
  [theme.breakpoints.down("sm")]: {
    container: {
      padding: "20px",
    },
    text: {
      fontSize: "0.8rem",
    },
    containerBox: {
      borderBottom: "1pt solid #000",
    },
    imgAvior: {
      width: "90%",
    },
    taxes: {
      flexDirection: "column",
    },
    header: {
      justifyContent: "center",
    },
  },
  [theme.breakpoints.up("sm")]: {
    imgAvior: {
      width: "60%",
    },
    header: {
      justifyContent: "flex-end",
    },
  },
}));

export default function ThankYouPageInstallmentsPendding(props) {
  let location = useLocation();
  let history = useHistory();
  const classes = useStyles();

  const [params] = useState(location.state ? location.state.state : {});

  if (!location.state) history.push(`/`);

  const getAncillariesAmount = () => {
    const arrayAncillaries = params?.ancillaries;

    const priceGoing = arrayAncillaries?.going.length
      ? arrayAncillaries.going.reduce(function (a, b) {
          return parseFloat(a) + parseFloat(b.total);
        }, 0)
      : 0;

    const priceGoback = arrayAncillaries?.goBack.length
      ? arrayAncillaries.goBack.reduce(function (a, b) {
          return parseFloat(a) + parseFloat(b.total);
        }, 0)
      : 0;

    return parseFloat(priceGoing + priceGoback).toFixed(2);
  };

  const getAmountSeats = () => {
    const arraySeats = params?.selectedSeats;

    const priceGoing = arraySeats?.going.length
      ? arraySeats.going.reduce(function (a, b) {
          return parseFloat(a) + parseFloat(b.total);
        }, 0)
      : 0;

    const priceGoback = arraySeats?.goBack.length
      ? arraySeats.goBack.reduce(function (a, b) {
          return parseFloat(a) + parseFloat(b.total);
        }, 0)
      : 0;

    return parseFloat(priceGoing + priceGoback).toFixed(2);
  };

  const buildAncillaries = (reservationAnciallaries, segments) => {
    const reservationAnciallariesAux = [];
    reservationAnciallaries.forEach((elem) => {
      elem.itemOffer.forEach((item) => {
        reservationAnciallariesAux.push(item);
      });
    });
    const groupAncillariesAux = reservationAnciallariesAux.reduce(function (
      obj,
      item
    ) {
      obj[item.segmentRPH] = obj[item.segmentRPH] || [];
      obj[item.segmentRPH].push({ ...item });
      return obj;
    },
    {});
    const groups = Object.keys(groupAncillariesAux).map(function (key) {
      return {
        segment: `${segments[key - 1].departure}-${segments[key - 1].arrival}`,
        ancillaries: groupAncillariesAux[key],
      };
    });
    setAncillaries([...groups]);
  };

  const pnr = params?.pnr;
  const originFlight = params?.originFlight;
  const returnFlight = params?.returnFlight;
  const segments = params?.segments ? params.segments : [];
  const arrayAncillaries = params?.ancillaries ? params.ancillaries : [];
  const reservationDetails = params.reservationDetails
    ? params.reservationDetails
    : null;
  const passengers = params.passengers ? params.passengers : [];
  const currency = params.currency;

  const totalAncillaries = getAncillariesAmount();
  const totalSeats = getAmountSeats();
  const faresAmount =
    parseFloat(originFlight ? originFlight.fare.totalAmount : 0) +
    parseFloat(returnFlight ? returnFlight.fare.totalAmount : 0);

  const totalBaseFare =
    parseFloat(originFlight ? originFlight.fare.baseFare : 0) +
    parseFloat(returnFlight ? returnFlight.fare.baseFare : 0);
  const totalTaxes =
    parseFloat(originFlight ? originFlight.fare.totalTax : 0) +
    parseFloat(returnFlight ? returnFlight.fare.totalTax : 0);

  const flightTaxes = !params.isRoundTrip ? originFlight : returnFlight;

  const taxesSum = flightTaxes.fare.faresPerTypePassenger.reduce(
    (acc, item) => {
      item.taxes.forEach((tax) => {
        acc[tax.code] =
          (acc[tax.code] || 0) + parseFloat(tax.amount * item.quantity);
      });
      return acc;
    },
    {}
  );

  const taxesArray = Object.entries(taxesSum).map(([code, totalAmount]) => ({
    code,
    amount: totalAmount,
  }));

  const taxes = taxesArray ? taxesArray.filter((tax) => tax.code !== "6P") : [];

  const [ancillaries, setAncillaries] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const totalAmount = parseFloat(
    parseFloat(faresAmount) +
      parseFloat(totalAncillaries) +
      parseFloat(totalSeats)
  ).toFixed(2);

  useEffect(() => {
    if (arrayAncillaries.length) buildAncillaries(arrayAncillaries, segments);
    if (!validateRoute(segments)) setOpenModal(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [modalToHome, setModalToHome] = useState(false);

  useEffect(() => {
    window.fbq("track", "Purchase", { currency: "USD", value: totalAmount });
  }, [totalAmount]);

  useEffect(() => {
    const s = document.createElement("script");
    s.async = true;
    s.innerHTML = `
        var dataLayer  = window.dataLayer || [];
        dataLayer.push({ ecommerce: null });
        dataLayer.push({
          'event': 'purchase',
          'ecommerce': {
            'purchase': {
              'actionField': {
                'id': "${pnr} ${
      reservationDetails ? reservationDetails.payment.paymentType : null
    }",
                'affiliation': 'Avior',
                'value': "${parseFloat(totalAmount).toFixed(2)}",
                'currency': 'USD',
                'tax':'0',
                'shipping': '0'
              },    
                'products': [{
                  'name':"Reserva",
                  'id': "${pnr}",
                  'price': "${parseFloat(totalAmount).toFixed(2)}",
                  'brand': 'Avior',
                  'category': 'Reserva',
                  'variant': 'Gray',
                  'quantity': 1,
                  'coupon': '' 
                }]
          }
        }});
        `;
    document.head.appendChild(s);
    return () => {
      document.body.removeChild(s);
    };
  }, [pnr, reservationDetails, totalAmount]);

  return (
    <div>
      <Grid container justify="center" className={classes.container}>
        <Grid item md={12} xs={12}>
          <Toolbar>
            <Grid container className={classes.header}>
              <Grid item xs={5} md={2}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img
                    src="./img/logoWhite.png"
                    alt=""
                    style={{ width: "100%", cursor: "pointer" }}
                    onClick={() => setModalToHome(true)}
                  />
                </div>
              </Grid>
            </Grid>
          </Toolbar>
        </Grid>
        <Grid item md={8} xs={12}>
          <Grid container justify="flex-start">
            <Grid item md={10} xs={12}>
              <Grid container justify="flex-start">
                <Grid item md={12} style={{ marginBottom: "20px" }}>
                  <Typography>
                    Estado de <strong>compra:</strong>{" "}
                    <span style={{ color: "#CE2827" }}>PENDIENTE</span>
                  </Typography>
                </Grid>
                <Grid item md={6} xs={12} style={{ marginBottom: "20px" }}>
                  <Grid container>
                    <Grid item md={6} xs={6}>
                      <Typography
                        style={{ fontWeight: "bold" }}
                        className={classes.text}
                      >
                        Codigo de reserva:
                      </Typography>
                    </Grid>

                    <Grid
                      item
                      md={6}
                      xs={6}
                      style={{ borderBottom: "2pt dotted #000" }}
                    >
                      <Typography className={classes.text}>{pnr}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={6} xs={12} style={{ marginBottom: "20px" }}>
                  <Grid container justify="flex-end">
                    <Grid item md={10}>
                      <Typography className={classes.text}>
                        Estimado pasajero su compra se encuentra en estado de
                        verificación, al completarse recibirá un correo
                        electrónico con los detalles de su itinerario.
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={12} xs={12} style={{ marginBottom: "20px" }}>
                  <Typography className={classes.text}>
                    DETALLES <strong>DE LOS PASAJEROS</strong>
                  </Typography>
                </Grid>
                {passengers.map((passenger, index) => (
                  <PassengerDetailsInfo
                    classes={classes}
                    key={index}
                    passenger={passenger}
                    email={passengers[index].email}
                  />
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {segments.map((segment, index) => {
          const item = {
            flightNumber: segment.flightNumber,
            departure: segment.departureInfo.locationCode,
            arrival: segment.arrivalInfo.locationCode,
            depatureTime: segment.departureInfo.dateTime,
            arrivalTime: segment.arrivalInfo.dateTime,
            classCode: segment.cabinType,
          };

          return (
            <TravelInfo
              classes={classes}
              segment={item}
              key={index}
              index={index + 1}
            />
          );
        })}

        <Grid item md={8} xs={12} style={{ marginBottom: "20px" }}>
          <Grid container justify="flex-start">
            <Grid
              item
              md={12}
              style={{
                marginBottom: "10px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography className={classes.text}>
                BOLETO <strong>Y PAGO DEL VUELO:</strong>
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item md={8} xs={12} style={{ marginBottom: "20px" }}>
          <Grid container justify="flex-start">
            <Grid item md={2} xs={12}>
              <Grid container justify="space-between">
                <Grid
                  item
                  md={12}
                  style={{
                    padding: "5px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Typography className={classes.text}>Viajeros</Typography>
                </Grid>
                <Grid
                  item
                  md={12}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Typography className={classes.text}>
                    {passengers.length}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={2} xs={12}>
              <Grid container justify="space-between">
                <Grid
                  item
                  md={12}
                  style={{
                    padding: "5px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Typography className={classes.text}>
                    Tarifa + sobrecargo
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={12}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography className={classes.text}>
                    {`${currency} ${FormatCurrency(
                      `${parseFloat(totalBaseFare).toFixed(2)}`
                    )}`}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={2} xs={12}>
              <Grid container justify="space-between">
                <Grid
                  item
                  md={12}
                  style={{
                    padding: "5px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Typography className={classes.text}>Adicionales</Typography>
                </Grid>
                <Grid
                  item
                  md={12}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography className={classes.text}>
                    {`${currency} ${FormatCurrency(
                      `${parseFloat(totalAncillaries).toFixed(2)}`
                    )}`}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={2} xs={12}>
              <Grid container justify="space-between">
                <Grid
                  item
                  md={12}
                  style={{
                    padding: "5px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Typography className={classes.text}>
                    Tasas + impuestos
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={12}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography className={classes.text}>
                    {`${currency} ${FormatCurrency(
                      `${parseFloat(totalTaxes).toFixed(2)}`
                    )}`}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={4} xs={12}>
              <Grid container justify="space-between">
                <Grid
                  item
                  md={12}
                  style={{
                    padding: "5px",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Typography className={classes.text}>Total</Typography>
                </Grid>
                <Grid
                  item
                  md={12}
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  <Typography color={"primary"} className={classes.text}>
                    {` ${currency} ${FormatCurrency(
                      `${parseFloat(totalAmount).toFixed(2)}`
                    )}`}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          md={8}
          xs={12}
          style={{
            marginBottom: "20px",
            padding: "40px 10px",
            backgroundColor: "#CCCCCC",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Typography style={{ fontWeight: "bold" }} className={classes.text}>
            {`Total para todos los pasajeros ${currency} ${FormatCurrency(
              `${parseFloat(totalAmount).toFixed(2)}`
            )}`}
          </Typography>
        </Grid>
        <Grid item md={8} xs={12} style={{ marginBottom: "20px" }}>
          <Grid container justify="flex-start">
            <Grid
              item
              md={12}
              style={{
                marginBottom: "10px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography className={classes.text}>
                IMPUESTOS <strong>Y SOBRECARGO POR COMBUSTIBLE:</strong>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={8} xs={12} style={{ marginBottom: "20px" }}>
          <Grid container justify="flex-start" className={classes.taxes}>
            <Grid
              item
              md={12}
              style={{
                marginBottom: "10px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography
                style={{ fontWeight: "bold" }}
                className={classes.text}
              >
                Desglose de impuestos
              </Typography>
            </Grid>
            {taxes.map((tax, index) => (
              <Grid
                item
                md={12}
                style={{
                  marginBottom: "10px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
                key={index}
              >
                <div style={{ width: "80%" }}>
                  <Typography
                    style={{ fontWeight: "bold" }}
                    className={classes.text}
                  >
                    {tax.code}
                  </Typography>
                </div>

                <div
                  style={{
                    width: "20%",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    style={{ fontWeight: "bold" }}
                    className={classes.text}
                  >
                    {currency}
                  </Typography>
                  <Typography
                    style={{ fontWeight: "bold" }}
                    className={classes.text}
                  >
                    {FormatCurrency(`${parseFloat(tax.amount).toFixed(2)}`)}
                  </Typography>
                </div>
              </Grid>
            ))}
          </Grid>
        </Grid>
        {ancillaries.length > 0 && (
          <Grid item md={8} xs={12} style={{ marginBottom: "20px" }}>
            <Grid container justify="flex-start" className={classes.taxes}>
              <Grid
                item
                md={12}
                style={{
                  marginBottom: "10px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography
                  style={{ fontWeight: "bold" }}
                  className={classes.text}
                >
                  Adicionales
                </Typography>
              </Grid>
            </Grid>
            {ancillaries.map((ancillary, index) => (
              <Grid container key={index}>
                <Grid item xs={12}>
                  <Typography
                    style={{ fontWeight: "bold" }}
                    className={classes.text}
                    color="primary"
                  >
                    {ancillary.segment}
                  </Typography>
                </Grid>
                <Grid container>
                  {ancillary.ancillaries.map((elem, key) => (
                    <Grid container justify="space-between" key={key}>
                      <Grid item xs={6}>
                        <Typography
                          style={{ fontWeight: "bold" }}
                          className={classes.text}
                        >
                          {elem.description}
                        </Typography>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            ))}
          </Grid>
        )}
        <Grid item md={8} xs={12} style={{ marginBottom: "20px" }}>
          <Grid container justify="flex-start">
            <Grid
              item
              md={12}
              style={{
                marginBottom: "10px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                style={{ fontWeight: "bold" }}
                className={classes.text}
              >
                PARA TENER EN CUENTA
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={8} xs={12} style={{ marginBottom: "20px" }}>
          <Grid
            container
            style={{ backgroundColor: "#CCCCCC", padding: "20px" }}
          >
            <ul>
              <li>
                <Typography className={classes.text}>
                  Te recomendamos llegar (4) horas antes de la hora programada
                  del vuelo sim importar por que medio realizaste el Check-in.
                </Typography>
              </li>
              <li>
                <Typography className={classes.text}>
                  Si presentas una condicion de discapacidad y requieres
                  asistencia adicional o alguno de nuestros otros servicios que
                  te ofrece AVIOR
                </Typography>
              </li>
            </ul>
          </Grid>
        </Grid>
      </Grid>
      <ModalPopUp
        open={modalToHome}
        close={() => setModalToHome(false)}
        modalToHome={true}
      />
      <ModalTravelAssist open={openModal} close={() => setOpenModal(false)} />
      <Footer />
    </div>
  );
}
