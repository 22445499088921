import React from "react";
import {
  Grid,
  GridList,
  GridListTile,
  Typography,
  Divider,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { faresColors, termIcon } from "../helpers";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    overflow: "hidden",
  },
  gridList: {
    flexWrap: "nowrap",
    transform: "translateZ(0)",
    marginTop: "10px !important",
  },
  gridListTile: {
    height: "none",
    width: "120px",
    marginRight: "2px",
  },
  fare: {
    color: "#fff",
    //borderRadius: "5px",
    border: "1px solid #E6E6E6",
  },
  termsName: {
    color: "#af0061",
  },
  container: {
    //marginBottom: "5px",
    height: "60px",
    display: "flex",
  },
  containerFare: {
    //marginBottom: "5px",
    height: "60px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  grid: {
    //marginTop:"20px"
  },
}));

export default function FareTerms(props) {
  //console.log(props.terms)
  const classes = useStyles();
  const terms = [];
  const LIGHT = props.terms.find((term) => term.kiuResKey === "LIGHT");
  const FLEX = props.terms.find((term) => term.kiuResKey === "FLEX");
  const MEDIUM = props.terms.find((term) => term.kiuResKey === "MEDIUM");
  const ULTRA = props.terms.find((term) => term.kiuResKey === "ULTRA");
  const BUSINESS = props.terms.find((term) => term.kiuResKey === "BUSINESS");
  if (LIGHT) {
    terms.push(LIGHT);
  }
  if (FLEX) {
    terms.push(FLEX);
  }
  if (MEDIUM) {
    terms.push(MEDIUM);
  }
  if (ULTRA) {
    terms.push(ULTRA);
  }
  if (BUSINESS) {
    terms.push(BUSINESS);
  }
  //console.log(props.terms)
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  function findByFareName(name) {
    const term = props.terms.find((term) => term.name === name);
    if (term) {
      return (
        <GridListTile
          style={{
            height: null,
            width: "320px",
            //paddingLeft: term.name === "MEGA" ? "10px" : "0",
          }}
        >
          <div
            className={classes.fare}
            style={{
              border: `1px solid ${faresColors(term.kiuResKey, false)}`,
            }}
          >
            {term.fareConditions.map((fareCondition, index) => (
              <div
                key={index}
                //className={classes.container}
                style={{
                  color:
                    faresColors(term.kiuResKey, false) === "#FFF"
                      ? "#575756"
                      : faresColors(term.kiuResKey, false),
                  marginBottom: "5px",
                  paddingLeft: "5px",
                  paddingRight: "5px",
                }}
              >
                <Grid
                  style={{ height: "90px" }}
                  container
                  alignItems="center"
                  justify="center"
                  direction="row"
                >
                  <Grid item xs={7}>
                    <Typography
                      variant="h6"
                      style={{
                        fontSize: "0.9em",
                        //padding: "6px 20px"
                      }}
                    >
                      <img
                        src={termIcon(fareCondition.name)}
                        alt=""
                        style={{ width: "15px" }}
                      />
                      <span style={{ paddingLeft: "5px" }}>
                        {fareCondition.name}
                      </span>
                    </Typography>
                    <Typography
                      variant="h6"
                      align="justify"
                      style={{
                        fontSize: "0.9em",
                        //padding: "6px 20px"
                      }}
                    >
                      {fareCondition.description}
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography
                      variant="h6"
                      align="center"
                      style={{
                        fontSize: "0.9em",
                        //padding: "6px 20px"
                      }}
                    >
                      {fareCondition.available ? <CheckIcon /> : <ClearIcon />}
                    </Typography>
                    <Typography
                      variant="h6"
                      align="center"
                      style={{
                        fontSize: "0.7em",
                        //padding: "6px 10px"
                      }}
                    >
                      {fareCondition.aditionalInfo}
                    </Typography>
                  </Grid>
                </Grid>
                <Divider />
              </div>
            ))}
          </div>
        </GridListTile>
      );
    }
    return null;
  }
  return (
    <Grid
      container
      justify="flex-end"
      direction="row"
      spacing={1}
      className={classes.grid}
    >
      <Grid item xs={12} md={9}>
        <div style={{ width: "200px" }}>
          <Typography
            color="textSecondary"
            align="right"
            style={{ fontSize: "0.8rem", cursor: "pointer" }}
            onClick={() => {
              props.setVisible();
            }}
          >
            Elija la tarifa de su preferencia{" "}
            <img
              style={{
                transform: props.openFareTerms ? "rotate(180deg)" : null,
              }}
              src="./img/icono-compararfamilias.png"
              alt=""
            />
          </Typography>
        </div>
        {props.openFareTerms ? (
          <div className={classes.root}>
            <GridList className={classes.gridList}>
              {matches ? (
                <GridListTile
                  style={{
                    width: matches ? "200px" : "180px",
                    paddingRight: "20px",
                    /*marginTop: "20px",*/
                    height: null,
                  }}
                >
                  {terms[0].fareConditions.map((term, index) => (
                    <Grid
                      container
                      justify="flex-start"
                      direction="row"
                      alignItems="center"
                      key={index}
                      className={classes.container}
                      style={{ borderBottom: "1px solid #E6E6E6" }}
                    >
                      <Grid item xs={2}>
                        <img
                          src={termIcon(term.name)}
                          alt=""
                          style={{ width: "15px" }}
                        />
                      </Grid>
                      <Grid item xs={10}>
                        <Typography
                          display="block"
                          align="right"
                          variant="h6"
                          style={{
                            //padding: "5px 15px",
                            color: "rgba(0, 0, 0, 0.54)",
                            fontSize: "0.8em",
                          }}
                        >
                          {term.name}
                        </Typography>
                        <Typography
                          color="secondary"
                          display="block"
                          align="right"
                          style={{ fontSize: "0.7em" }}
                        >
                          {term.description}
                        </Typography>
                      </Grid>
                    </Grid>
                  ))}
                </GridListTile>
              ) : null}
              {matches
                ? terms.map((term, index) => {
                    return (
                      <GridListTile
                        key={index}
                        style={{
                          height: null,
                          width: "120px",
                          marginLeft: term.name === "MEGA" ? "10px" : "0",
                        }}
                      >
                        <div className={classes.fare}>
                          {term.fareConditions.map((fareCondition, index) => (
                            <div
                              key={index}
                              className={classes.containerFare}
                              style={{
                                color:
                                  faresColors(term.kiuResKey, false) === "#FFF"
                                    ? "#575756"
                                    : faresColors(term.kiuResKey, false),
                              }}
                            >
                              <Typography
                                variant="h6"
                                align="center"
                                style={{
                                  fontSize: "0.9em" /*padding: "6px 20px"*/,
                                }}
                              >
                                {fareCondition.available ? (
                                  <CheckIcon />
                                ) : (
                                  <ClearIcon />
                                )}
                              </Typography>
                              <Typography
                                variant="h6"
                                align="center"
                                style={{
                                  fontSize: "0.65em" /*padding: "6px 10px"*/,
                                }}
                              >
                                {fareCondition.aditionalInfo}
                              </Typography>
                            </div>
                          ))}
                        </div>
                      </GridListTile>
                    );
                  })
                : findByFareName(props.fareName)}
            </GridList>
          </div>
        ) : null}
      </Grid>
    </Grid>
  );
}
