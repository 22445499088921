import React,{useState, useEffect} from "react";
import { Grid, MenuItem, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {FormValidator} from '../helpers/'
import {ButtonSpinner,  Toast} from "../components/";
import { paymentServices } from "../services"
import { ExpandMore } from '@material-ui/icons';
import {filterAlpha, filterNumber, onBlurEmail} from "../helpers/filterValues"

let indexDocument=0;
const documentType=[
    {key:indexDocument++, name:"Pasaporte", value:"PP"},
    {key:indexDocument++, name:"CC", value:"CC"},
    {key:indexDocument++, name:"CE", value:"CE"},
    {key:indexDocument++, name:"NIT", value:"NIT"},
    {key:indexDocument++, name:"OTRO", value:"O"},
]

let indexEntity=0;
const entityType=[
    {key:indexEntity++, name:"Persona natural", value:"individual"},
    {key:indexEntity++, name:"Persona juridica", value:"association"},
]

let indexInstitution=0;
const financialInstitution=[
    {key:indexInstitution++, name: 'NEQUI', value: '1507'},
    {key:indexInstitution++, name: 'DaviPlata', value: '1551'},
    {key:indexInstitution++, name: 'Confiar Cooperativa Financiera', value: '1292' },
    {key:indexInstitution++, name: 'Banco AV Villas', value: '1052' },
    {key:indexInstitution++, name: 'Banco Caja Social', value: '1032' },
    {key:indexInstitution++, name: 'Banco Colpatria', value: '1019' },
    {key:indexInstitution++, name: 'Banco Cooperativo Coopcentral', value: '1066' },
    {key:indexInstitution++, name: 'Banco Davivienda', value: '1051' },
    {key:indexInstitution++, name: 'Banco De Bogotá', value: '1001' },
    {key:indexInstitution++, name: 'Banco De Occidente', value: '1023' },
    {key:indexInstitution++, name: 'Banco Falabella', value: '1062' },
    {key:indexInstitution++, name: 'Banco GNB Sudameris', value: '1012' },
    {key:indexInstitution++, name: 'Banco Pichincha S.A.', value: '1060' },
    {key:indexInstitution++, name: 'Banco Popular', value: '1002' },
    {key:indexInstitution++, name: 'Banco Procredit', value: '1058' },
    {key:indexInstitution++, name: 'Bancolombia', value: '1007' },
    {key:indexInstitution++, name: 'Bancoomeva S.A.', value: '1061' },
    {key:indexInstitution++, name: 'Banco BBVA Colombia S.A.', value: '1013' },
    {key:indexInstitution++, name: 'Citibank', value: '1009' },
    {key:indexInstitution++, name: 'Banco Itaú', value: '1006' },
    {key:indexInstitution++, name: 'Banco Agrario', value: '1040' },
]

const useStyles = makeStyles(theme=>({
    inputGrid:{
        marginBottom:"15px",
    },
    Grid:{
        display:'flex',
        justifyContent: 'center'
    },
    floatingLabelFocusStyle: {
        color: "#af0061",
        fontSize: "15pt",
        fontFamily:"Roboto-Medium,  sans-serif",
        width: "max-content",
    },
    inputLabel:{
        fontFamily:"Roboto-Regular,  sans-serif",
        fontSize:"14pt",
        paddingBottom: 0,
        color: "#555555!important",
        height: '20px'
    },
    underline:{
        '&::before':{
            display: "none"
        },
        '&::after':{
            display: "none"
        }
    },
    inputDiv:{
        borderWidth: "1pt",
        borderStyle: "solid",
        borderColor: "#cccccc",
        padding: "10px",
        borderRadius: "5px",
        "& .MuiFormControl-root": {
            width: "100%",
        },
    },
    expandIcon:{
        color:"#FA7268"
    },
    aceptButtonLabel:{
        fontFamily:"Roboto-Bold,  sans-serif",
        color:"#ffffff",
        fontSize:"14pt",
        fontWeight:"bold"
    },
    [theme.breakpoints.down("md")]: {
        floatingLabelFocusStyle:{
          fontSize:"0.9rem"
        },
        inputLabel:{
          fontSize:"0.8rem"
        },
        aceptButtonLabel:{
          fontSize:"0.9rem"
        },
        
      }
}));

export default function PaymentPse(props){
    /*function useQuery() {
        return new URLSearchParams(useLocation().search);
    }
    let query = useQuery();
    const pnr = query.get("pnr")
    let location = useLocation();
    let history = useHistory();
    if (!location.state) {
      history.push(`/`);
    }
    const reservation = location.state.reservation; 
    */
    const classes = useStyles();
    const [loading, setLoading]=useState(false)
    const [toast, setToast]=useState(false)
    const [toastMessage, setToastMessage]=useState('')
    const [fields, setFields]=useState(false)

    const [email, setEmail]=useState('')
    const [firstName, setFirstName]=useState('')
    const [lastName, setLastName]=useState('')
    const [address, setAddress]=useState('')
    const [docNumber, setDocNumer]=useState('')
    const [docType, setDocType]=useState('')
    const [entity, setEntityType]=useState('')
    const [institution, setInstitution]=useState('')
    const [ip, setIp]=useState('')

    const [firstNameError, setFirstNameError]=useState('')
    const [lastNameError, setLastNameError]=useState('')
    const [emailError, setEmailError]=useState('')
    const [addressError, setAddressError]=useState('')
    const [docNumberError, setDocNumberError]=useState('')
    const [docTypeError, setDocTypeError]=useState('')
    const [entityError, setEntityError]=useState('')
    const [institutionError, setInstitutionError]=useState('')
    
    useEffect(() => {
        async function getIp(){
            const publicIp = require('public-ip');
            setIp(await publicIp.v4())
        }
        getIp();
      }, []
    );
    
    const validator = new FormValidator([
        {
          field: "firstName",
          method: "isEmpty",
          validWhen: false,
          message: "Debe ingresar nombre del pasajero"
        },
        {
          field: "lastName",
          method: "isEmpty",
          validWhen: false,
          message: "Debe ingresar apellido del pasajero"
        },
        {
            field: "email",
            method: "isEmpty",
            validWhen: false,
            message: "Debe ingresar email"
        },
        { 
            field: 'email', 
            method: 'isEmail', 
            validWhen: true, 
            message: 'Ingrese un correo electrónico valido' 
        },
        {
            field: "address",
            method: "isEmpty",
            validWhen: false,
            message: "Ingrese su direccion"
        },
        {
            field: "docNumber",
            method: "isEmpty",
            validWhen: false,
            message: "Ingrese numero de documento"
        },
        {
            field: "docNumber",
            method: "isNumeric",
            validWhen: true,
            message: "Ingrese numero de documento"
        },
        {
            field: "docType",
            method: "isEmpty",
            validWhen: false,
            message: "Seleccione tipo de documento"
        },
        {
            field: "entity",
            method: "isEmpty",
            validWhen: false,
            message: "Seleccione tipo de entidad"
        },
        {
            field: "institution",
            method: "isEmpty",
            validWhen: false,
            message: "Seleccione la institucion financiera"
        }
    ]);

    const validateForms= async ()=>{
        const validation = validator.validate({
            firstName,
            lastName,
            email,
            docType,
            docNumber,
            address,
            entity,
            institution
        });
        setFirstNameError(validation.firstName.message)
        setLastNameError(validation.lastName.message)
        setEmailError(validation.email.message)
        setDocTypeError(validation.docType.message)
        setDocNumberError(validation.docNumber.message)
        setAddressError(validation.address.message)
        setEntityError(validation.entity.message)
        setInstitutionError(validation.institution.message)
        
        if(validation.isValid)
        {
            const request={
                "pnr": props.pnr,
                "email": email,
                "description": "pago",
                "firstName": firstName,
                "lastName": lastName,
                "address": {
                    "street_name": address
                },
                "identification": {
                    "number": docNumber,
                    "type": docType
                },
                "ipAddress": ip,
                "entityType": entity,
                "financialInstitution": institution
            }
            setLoading(true);
            const peticion = await paymentServices.payWithPse(request)
            setLoading(false);
            const resp = await peticion.resp;
            const status= await peticion.status;
            if(status===200){
                //  log("url",resp.payment.transactionTicket)
                window.open(resp.payment.transactionTicket, '_blank')
                setFields(true)
            }
            else if(status===400){
                setToast(true)
                setToastMessage(resp.error.message)
            }
        }
    }

    return (
        <div>
            <Grid container justify="center"  direction="row" alignItems="center" spacing={1} >
                <Grid item md={12} xs={12}  className={classes.Grid}>
                    <Grid item md={5} xs={12}  className={classes.inputGrid} >
                        <div className={classes.inputDiv}>
                            <TextField value={email} label="Email" name="email"  id="email"
                                disabled={fields} fullWidth
                                helperText={emailError} error={emailError!==""}
                                InputLabelProps={{ shrink: true, className: classes.floatingLabelFocusStyle, htmlFor:"email" }}
                                InputProps={{ disabled:fields, classes: { input: classes.inputLabel, underline: classes.underline,} }}
                                onChange={e => setEmail(e.target.value)}
                                onBlur={()=>{ setEmailError(onBlurEmail(email)) } }
                            />
                        </div>
                    </Grid>
                </Grid>
                <Grid item md={12} xs={12} className={classes.Grid}>
                    <Grid item md={5} xs={12} className={classes.inputGrid}>
                        <div className={classes.inputDiv}>
                            <TextField value={firstName} label="Nombre" name="firstName" id="firstName"
                                disabled={fields} fullWidth
                                helperText={firstNameError} error={firstNameError!==""}
                                InputLabelProps={{ shrink: true, className: classes.floatingLabelFocusStyle, htmlFor:"email" }}
                                InputProps={{ disabled:fields, classes: { input: classes.inputLabel, underline: classes.underline,} }}
                                onChange={(e) => setFirstName(filterAlpha(e.target.value))}
                            />
                        </div>
                    </Grid>
                </Grid>
                <Grid item md={12} xs={12} className={classes.Grid}>
                    <Grid item md={5} xs={12} className={classes.inputGrid}>
                        <div className={classes.inputDiv}>
                            <TextField value={lastName} label="Apellido" name="lastname" id="lastname"
                                disabled={fields} fullWidth
                                helperText={lastNameError} error={lastNameError!==""}
                                InputLabelProps={{ shrink: true, className: classes.floatingLabelFocusStyle, htmlFor:"email" }}
                                InputProps={{ disabled:fields, classes: { input: classes.inputLabel, underline: classes.underline,} }}
                                onChange={(e) => setLastName(filterAlpha(e.target.value))}
                            />
                        </div>
                    </Grid>
                </Grid>
                <Grid item md={12} xs={12} className={classes.Grid}>
                    <Grid item md={5} xs={11} className={classes.inputGrid}>
                        <div className={classes.inputDiv}>
                            <TextField value={address} label="Direccion" name="address" id="address"
                                disabled={fields} fullWidth helperText={addressError}
                                error={addressError!==""}
                                InputLabelProps={{ shrink: true, className: classes.floatingLabelFocusStyle, htmlFor:"address" }}
                                InputProps={{ disabled:fields, classes: { input: classes.inputLabel, underline: classes.underline,} }}
                                onChange={(e) => setAddress(e.target.value)}
                            />
                        </div>
                    </Grid>
                </Grid>
                <Grid item md={12} xs={12} className={classes.Grid}>
                    <Grid item md={5} xs={12} className={classes.inputGrid}>
                        <div className={classes.inputDiv}>
                            <TextField value={docType} label="Tipo de documento" select id="docType"
                                helperText={docTypeError} error={docTypeError!==""} 
                                InputLabelProps={{ shrink: true, className: classes.floatingLabelFocusStyle, htmlFor:"docType" }}
                                InputProps={{ disabled:fields, classes: { input: classes.inputLabel, underline: classes.underline,} }}
                                SelectProps={{ IconComponent:() => <ExpandMore className={classes.expandIcon}/> }}
                                onChange={e=>setDocType(e.target.value)} 
                            >
                            {
                                documentType.map((document) => (
                                    <MenuItem key={document.key} value={document.value} disabled={fields}>
                                        {document.name}
                                    </MenuItem>
                                ))
                            }
                            </TextField>
                        </div>
                    </Grid>
                </Grid>
                <Grid item md={12} xs={12} className={classes.Grid}>
                    <Grid item md={5} xs={12} className={classes.inputGrid}>
                        <div className={classes.inputDiv}>
                            <TextField value={docNumber} label="Numero de documento" name="number" id="docNumber"
                                disabled={fields} fullWidth     
                                helperText={docNumberError} error={docNumberError!==""}
                                InputLabelProps={{ shrink: true, className: classes.floatingLabelFocusStyle, htmlFor:"docNumber" }}
                                InputProps={{ disabled:fields, classes: { input: classes.inputLabel, underline: classes.underline,} }}
                                onChange={e=>setDocNumer(filterNumber(e.target.value))}
                            />
                        </div>
                    </Grid>
                </Grid>
                <Grid item md={12} xs={12} className={classes.Grid}>
                    <Grid item md={5} xs={12} className={classes.inputGrid}>
                        <div className={classes.inputDiv}>
                            <TextField value={entity} label="Tipo de entidad"  select id="entity"
                                helperText={entityError} error={entityError!==""}
                                InputLabelProps={{ shrink: true, className: classes.floatingLabelFocusStyle, htmlFor:"entity" }}
                                InputProps={{ disabled:fields, classes: { input: classes.inputLabel, underline: classes.underline,} }}
                                SelectProps={{ IconComponent:() => <ExpandMore className={classes.expandIcon}/> }}
                                onChange={e=>setEntityType(e.target.value)} 
                            >
                            {
                                entityType.map((entityType) => (
                                    <MenuItem key={entityType.key} value={entityType.value} disabled={fields}>
                                        {entityType.name}
                                    </MenuItem>
                                ))
                            }
                            </TextField>
                        </div>
                    </Grid>
                </Grid>
                <Grid item md={12} xs={12} className={classes.Grid}>
                    <Grid item md={5} xs={12} className={classes.inputGrid}>
                        <div className={classes.inputDiv}>
                            <TextField value={institution} label="Institucion financiera" select id="institution"
                                helperText={institutionError} error={institutionError!==""}
                                InputLabelProps={{ shrink: true, className: classes.floatingLabelFocusStyle, htmlFor:"institution" }}
                                InputProps={{ disabled:fields, classes: { input: classes.inputLabel, underline: classes.underline,} }}
                                SelectProps={{ IconComponent:() => <ExpandMore className={classes.expandIcon}/> }}
                                onChange={e=>setInstitution(e.target.value)}
                            >
                            {
                                financialInstitution.map((institution) => (
                                    <MenuItem key={institution.key} value={institution.value} disabled={fields}>
                                        {institution.name}
                                    </MenuItem>
                                ))
                            }
                            </TextField>
                        </div>
                    </Grid>
                </Grid>
                <Grid item md={12} xs={12} className={classes.Grid}>
                    <Grid item md={5} xs={12} className={classes.inputGrid}>
                        <Grid container justify="space-between">
                            <div style={{width:"158px", height:"41px"}}>
                                <ButtonSpinner action={()=>{props.goBack()}} fullWidth text="Atras" loading={loading} 
                                    style={{backgroundColor:"#cd0063", height:"100%"}} 
                                    labelClassname={classes.aceptButtonLabel} 
                                />
                            </div>
                            <div style={{width:"158px", height:"41px"}}>
                                <ButtonSpinner action={validateForms} fullWidth text="Continuar" loading={loading} 
                                    style={{backgroundColor:"#cd0063", height:"100%"}} 
                                    labelClassname={classes.aceptButtonLabel} 
                                />
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Toast
                message={toastMessage}
                open={toast}
                close={() => { setToast(false) }}
            />
        </div>
    )
}