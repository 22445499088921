import Modal from "@material-ui/core/Modal";
import React, { useState } from "react";
import { Grid, Typography, Button, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { CloseOutlined } from "@material-ui/icons";
const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 500,
    height: 300,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #FFF",
    boxShadow: theme.shadows[5],
    padding: "20px",
    borderRadius: "20px",
    outline: "none",
  },
  paper2: {
    position: "absolute",
    width: 400,
    height: 200,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #FFF",
    boxShadow: theme.shadows[5],
    padding: "20px",
    borderRadius: "20px",
    outline: "none",
  },
  aceptButtonLabel: {
    color: "#ffffff",
    fontSize: "0.8rem",
  },
  h2: {
    color: "#777777",
    fontWeight: "unset",
    fontSize: "1rem",
  },
  modalText: {
    color: "#575756",
    fontWeight: "bold",
    fontSize: "0.8rem",
  },
  p: {
    fontSize: "1.3rem",
    color: "#848484",
  },
  highlightedP: {
    fontSize: "1.5rem",
    color: "#af0061",
  },
  [theme.breakpoints.down("sm")]: {
    h2: {
      fontSize: "1.1rem",
    },
    p: {
      fontSize: "1rem",
    },
    highlightedP: {
      fontSize: "1.2rem",
    },
    paper: {
      width: 340,
      height: 350,
    },
  },
}));
export default function ModalTravelAssist(props) {
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);

  function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
  }
  return (
    <Modal open={props.open} onClose={props.close} disableBackdropClick={true}>
      <Grid
        container
        justify="center"
        style={modalStyle}
        className={classes.paper}
      >
        <Grid container justify="flex-end" onClick={props.close}>
          <IconButton style={{padding:0}}>
            <CloseOutlined />
          </IconButton>
        </Grid>
        <Grid item md={12} xs={12} style={{ backgroundColor: "#FFF" }}>
          <Grid container alignItems="center" justify="center">
            <Typography
              className={classes.h2}
              align="center"
            >
              <strong>Gracias por tu compra</strong>{" "}
              <br/>
              <strong>¿Ya tienes asistente de viaje?</strong>{" "}
            </Typography>
          </Grid>

          <br />
          <Typography className={classes.modalText}>
            Con Avior Travel Assist, viaja sin preocupaciones. Conoce los beneficios de nuestros planes de cobertura.
          </Typography>
          <br />
          <br />
          <Grid
            container
            justify="center"
            alignItems="center"
            direction="column"
          >
            <div style={{ width: "100%" }}>
              <Button
                variant="contained"
                fullWidth
                color={"primary"}
                href="https://aviorair.com/travelassist"
                target="_blank"
                rel="noreferrer"
                onClick={props.close}
              >
                <span style={{marginRight:5}}>Cotiza</span> aquí
              </Button>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
}
