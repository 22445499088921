import React from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme=>({
    callCenterImg:{
        display:"flex",
        justifyContent:"flex-end",
        alignItems:"center",
        paddingRight:"10px"
    },
    h1:{
        fontFamily: "Roboto-Bold, sans-serif",
        fontStyle: "italic",
        fontSize: "38pt",
        color: "#555",
        lineHeight: "1.5",
        margin: 0,
        fontWeight:"500"
    },
    h2:{
        fontFamily: "Roboto-Regular, sans-serif",
        fontSize: "29pt",
        color: "#555",
        lineHeight: "1.5",
        margin: 0,
        fontWeight:"unset"
    },
    h3:{
        fontFamily: "Roboto, sans-serif",
        fontSize: "23pt",
        color: "#555",
        lineHeight: "1.5",
        margin: 0,
        fontWeight:"unset"
    },
    [theme.breakpoints.down('md')]:{ 
        callCenterImg:{
            justifyContent:"center"
        },
        h1:{
            fontSize: "1.5rem",
        },
        h2:{
            fontSize: "1.2rem",
        },
        h3:{
            fontSize: "1.1rem",
        }
    }
}));
export default function CallCenter(props) {
  const classes = useStyles();
  return (
    <Grid container justify="center" direction="row">
        <Grid item md={2} xs={12} className={classes.callCenterImg}>
            <img src="./img/call-center.png" alt="" />
        </Grid> 
        <Grid item md={6} xs={12} style={{paddingLeft:"10px"}}>
            <Grid container>
                <Grid item md={12} xs={12}>
                    <h1 className={classes.h1}>GCA Airlines en línea</h1>
                </Grid>
                <Grid item md={12} xs={12}>
                    <h2 className={classes.h2}>Atención personalizada</h2>
                </Grid>
                <Grid item md={12} xs={12}>
                    <h3 className={classes.h3}>Horario de atención: Lunes a Sábado</h3>
                </Grid>
                <Grid item md={12} xs={12}>
                    <h3 className={classes.h3}>8:00 a.m. - 12:00 p.m.</h3>
                </Grid>
                <Grid item md={12} xs={12}>
                    <h3 className={classes.h3}>2:00 p.m. - 6:00 p.m.</h3>
                </Grid>
            </Grid>
        </Grid>
    </Grid>
  );
}
