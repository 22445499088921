import urls from "../urls";

const getGateways = async (type) => {
  const peticion = await fetch(type ? `${urls.gateways}?type=${type}` : urls.gateways, {
    headers: {
      "Content-Type": "application/json",
      "Authorization": 'Basic ' + btoa('avior:fazQMUyq'),
    }
  });
  const response = await peticion.json();
  return {
    status: peticion.status,
    response
  };
};

const putGateways = async (gateways) => {
  const token = localStorage.getItem("token");
  const peticion = await fetch(urls.gateways, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify({ gateways })
  });
  const response = await peticion.json();
  return {
    status: peticion.status,
    response
  };
};

const gatewaysServices = Object.freeze({
  getGateways,
  putGateways
});

export default gatewaysServices;