import React from "react";
import { Grid } from "@material-ui/core";
import { getFare } from "../helpers";
import { Title } from ".";
export default function CostResume(props) {
  const baseFare =
    parseFloat(props.originFlight ? props.originFlight.fare.baseFare : 0) +
    parseFloat(props.returnFlight ? props.returnFlight.fare.baseFare : 0);
  const taxesCO =
    parseFloat(
      props.originFlight ? getFare(props.originFlight.fare.taxes, "CO") : 0
    ) +
    parseFloat(
      props.returnFlight ? getFare(props.returnFlight.fare.taxes, "CO") : 0
    );
  const taxesYS =
    parseFloat(
      props.originFlight ? getFare(props.originFlight.fare.taxes, "YS") : 0
    ) +
    parseFloat(
      props.returnFlight ? getFare(props.returnFlight.fare.taxes, "YS") : 0
    );
  const totalAmount =
    parseFloat(props.originFlight ? props.originFlight.fare.totalAmount : 0) +
    parseFloat(props.returnFlight ? props.returnFlight.fare.totalAmount : 0);
  return (
    <Grid
      container
      justify="center"
      direction="row"
      spacing={1}
    >
      <Title center withIcon text1="RESUMEN DE" text2="COSTO" />
      <Grid item md={8} xs={10}>
        <Grid container justify="center" direction="row" spacing={1}>
          <Grid item xs={8}>
            Tarifa base
          </Grid>
          <Grid item xs={4}>
            COP {baseFare}
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={8} xs={10}>
        <Grid container justify="center" direction="row" spacing={1}>
          <Grid item xs={8}>
            COVE
          </Grid>
          <Grid item xs={4}>
            COP {taxesCO}
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={8} xs={10}>
        <Grid container justify="center" direction="row" spacing={1}>
          <Grid item xs={8}>
            IVA (COLOMBIA)(YSVE)
          </Grid>
          <Grid item xs={4}>
            COP {taxesYS}
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={8} xs={10}>
        <Grid container justify="center" direction="row" spacing={1}>
          <Grid item xs={8}>
            TOTAL
          </Grid>
          <Grid item xs={4}>
            COP {totalAmount}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
