import React, { useEffect, useState } from "react";

import { Grid, MenuItem } from "@material-ui/core";
import Select from "./select";
import DatePicker from "./date-picker";
import ButtonSpinner from "./buttonsSpinner";
import PassengersSelect from "./passengers-select";
import FlightType from "./flight-type";
import { makeStyles } from "@material-ui/core/styles";
//import Spinner from "./spinner";
import { getIndexDaysOfTheWeek, filterDate, FormValidator } from "../helpers/";

import { destinationServices, flightAvailabilityServices } from "../services";
import { isAfter } from "date-fns";
const useStyles = makeStyles((theme) => ({
  grid: {
    marginBottom: "15px",
  },
}));

export default function SearchFlightsForm(props) {
  const classes = useStyles();
  const [airports, setAirport] = useState([]);
  const [destinationsDepartures, setDestinationsDepartures] = useState([]);
  const [destinationsArrivals, setDestinationsArrivals] = useState([]);
  const [origin, setOrgin] = useState("");
  const [destination, setDestination] = useState("");
  const [daysNotOperatedDeparture, setDaysNotOperatedDeparture] = useState([]);
  const [daysNotOperatedArrival, setDaysNotOperatedArrival] = useState([]);
  const [isRoundTrip, setIsRoundTrip] = useState(true);
  const [dateDeparture, setDateDeparture] = useState(null);
  const [selectDateDeparture, setSelectDateDeparture] = useState("");
  const [dateArrival, setDateArrival] = useState(null);
  const [selectDateArrival, setSelectDateArrival] = useState("");
  const [countAdults, setCountAdults] = useState(1);
  const [countChildrens, setCountChildrens] = useState(0);
  const [countBabies, setCountBabies] = useState(0);
  const [loading, setLoading] = useState(false);
  const [originError, setOrginError] = useState("");
  const [destinationError, setDestinationError] = useState("");
  const [selectDateDepartureError, setSelectDateDepartureError] = useState("");
  const [selectDateArrivalError, setSelectDateArrivalError] = useState("");
  const [originRoute, setOriginRoute] = useState({});
  const [returnRoute, setReturnRoute] = useState({});
  const validator = new FormValidator([
    {
      field: "origin",
      method: "isEmpty",
      validWhen: false,
      message: "Seleccione el origen",
    },
    {
      field: "destination",
      method: "isEmpty",
      validWhen: false,
      message: "Seleccione el destino",
    },
    {
      field: "selectDateDeparture",
      method: "isEmpty",
      validWhen: false,
      message: "Seleccione la fecha de salida",
    },
    {
      field: "selectDateArrival",
      method: "isEmpty",
      validWhen: !isRoundTrip,
      message: "Seleccione la fecha de retorno",
    },
  ]);
  useEffect(() => {
    getAirports();
  }, []);
  const getAirports = async () => {
    const peticion = await destinationServices.getAirports();
    const resp = peticion.resp;
    if (peticion.status === 200) {
      setAirport(resp.airports);
    }
  };
  const getDestinations = async (locationCode) => {
    const peticion = await destinationServices.getDestinations(locationCode);
    const resp = peticion.resp;
    if (peticion.status === 200) {
      setDestinationsDepartures(resp.routes.departures);
      setDestinationsArrivals(resp.routes.arrivals);
    }
  };
  const changeAirPort = (e) => {
    setOrgin(e.target.value);
    getDestinations(e.target.value);
  };
  const changeDestination = (e) => {
    setDestination(e.target.value);
    const destinationDeparture = destinationsDepartures.find(
      (destination) => destination.route === `${origin}/${e.target.value}`
    );

    if (destinationDeparture) {
      setOriginRoute(destinationDeparture);
      setDaysNotOperatedDeparture(destinationDeparture.daysNotOperated);
    }
    const destinationArrival = destinationsArrivals.find(
      (destination) => destination.route === `${e.target.value}/${origin}`
    );
    if (destinationArrival) {
      setReturnRoute(destinationArrival);
      setDaysNotOperatedArrival(destinationArrival.daysNotOperated);
    }
  };
  const flightAvailability = async () => {
    
    const validation = validator.validate({
      origin,
      destination,
      selectDateDeparture,
      selectDateArrival
    });
    setOrginError(validation.origin.message);
    setDestinationError(validation.destination.message);
    setSelectDateDepartureError(validation.selectDateDeparture.message);
    setSelectDateArrivalError(validation.selectDateArrival.message);
    if (validation.isValid) {
      props.setResultsAvailability(false);
      setLoading(true);
      const peticion = await flightAvailabilityServices.flightAvailability(
        origin,
        destination,
        isRoundTrip,
        selectDateDeparture,
        selectDateArrival,
        countAdults,
        countChildrens,
        countBabies
      );
      setLoading(false);
      const resp = await peticion.resp;
      if (peticion.status === 200) {
        if(resp.departure.length>0){
          props.setSearchParams({
            origin,
            destination,
            isRoundTrip,
            selectDateDeparture,
            selectDateArrival,
            countAdults,
            countChildrens,
            countBabies
          });
          props.setOriginRoute(originRoute);
          props.setReturnRoute(returnRoute);
          props.setResultsFlights(resp);
          props.setResultsAvailability(true);
          props.setNotResult(false);
          if(isRoundTrip && resp.arrival.length===0){
            props.setResultsAvailability(false);
            props.setNotResult(true);
          }
        }else{
          props.setResultsAvailability(false);
          props.setNotResult(true);
        }
      }
    }
  };
  return (
    <Grid container justify="center" direction="row" spacing={1}>
      <Grid item xs={12} style={{ marginBottom: "20px" }}>
        <Grid container justify="center" direction="row" spacing={1}>
          <Grid item md={3} xs={11}>
            <FlightType
              isRoundTrip={isRoundTrip}
              setFlightType={(type) =>
                setIsRoundTrip(type)
              }
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item className={classes.grid} md={2} xs={11}>
        <Select
          helperText={originError}
          error={originError !== ""}
          value={origin}
          label="Origen*"
          onChange={changeAirPort}
        >
          {airports.map((airport, index) => (
            <MenuItem key={index} value={airport.locationCode}>
              {airport.locationCode} {airport.cityName} {airport.airportName}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item className={classes.grid} md={2} xs={11}>
        <Select
          value={destination}
          label="Destino*"
          helperText={destinationError}
          error={destinationError !== ""}
          onChange={changeDestination}
        >
          {destinationsDepartures.map((destination, index) => (
            <MenuItem key={index} value={destination.arrival}>
              {destination.arrival} {destination.arrivalCityName}{" "}
              {destination.arrivalAirportName}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item className={classes.grid} md={3} xs={11}>
        <Grid container justify="center" direction="row">
          <Grid item xs={6} style={{ paddingRight: "0px" }}>
            <DatePicker
              disablePast
              helperText={selectDateDepartureError}
              error={selectDateDepartureError !== ""}
              onChange={(date) => {
                setSelectDateDeparture(filterDate(date));
                setDateDeparture(date);
                if (isAfter(dateArrival, date)) {
                  setSelectDateArrival("");
                  setDateArrival(date);
                }
              }}
              value={dateDeparture}
              label="Fecha de ida"
              disableDays={getIndexDaysOfTheWeek(daysNotOperatedDeparture)}
            />
          </Grid>
          <Grid item xs={6} style={{ paddingLeft: "0px" }}>
            <DatePicker
              disablePast
              helperText={selectDateArrivalError}
              error={selectDateArrivalError !== ""}
              onChange={(date) => {
                setSelectDateArrival(filterDate(date));
                setDateArrival(date);
              }}
              value={dateArrival}
              minDate={dateDeparture}
              disabled={!isRoundTrip}
              label="Fecha de retorno"
              disableDays={getIndexDaysOfTheWeek(daysNotOperatedArrival)}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item className={classes.grid} md={2} xs={11}>
        <PassengersSelect
          countAdults={countAdults}
          countChildrens={countChildrens}
          countBabies={countBabies}
          setCountAdults={(value) => setCountAdults(value)}
          setCountBabies={(value) => setCountBabies(value)}
          setCountChildrens={(value) => setCountChildrens(value)}
        />
      </Grid>
      <Grid item className={classes.grid} md={1} xs={11}>
        <ButtonSpinner
          action={flightAvailability}
          fullWidth
          text="Buscar"
          loading={loading}
        />
      </Grid>
    </Grid>
  );
}
