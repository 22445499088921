import urls from "../urls";

const getInstallmentsPreview = async ({ pnr }) => {
  const peticion = await fetch(`${urls.installmentsPreview}/${pnr}`);
  const response = await peticion.json();
  return {
    status: peticion.status,
    response,
  };
};

const getInstallmentsPrice = async ({ id }) => {
  const peticion = await fetch(`${urls.installmentsPrice}/${id}`);
  const response = await peticion.json();
  return {
    status: peticion.status,
    response,
  };
};

const installmentsServices = Object.freeze({
  getInstallmentsPreview,
  getInstallmentsPrice,
});

export default installmentsServices;
