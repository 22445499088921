import React, { useState } from "react";
import {
  SearchFlightsForm,
  Title,
  SearchResults,
  SelectedFlightsResumen
} from "../components";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  }
}));
export default function SearchFlights() {
  const classes = useStyles();
  const [originRoute, setOriginRoute] = useState({
    origin: "CLO",
    departureAirportName: "ALFONSO B ARAGON",
    departureCityName: "CALI",
    arrival: "BAQ",
    arrivalAirportName: "E CORTISSOZ",
    arrivalCityName: "BARRANQUILLA",
    route: "CLO/BAQ",
    daysNotOperated: ["Martes", "Miercoles", "Jueves", "Sabado", "Domingo"]
  });
  const [returnRoute, setReturnRoute] = useState({
    origin: "CLO",
    departureAirportName: "ALFONSO B ARAGON",
    departureCityName: "CALI",
    arrival: "BAQ",
    arrivalAirportName: "E CORTISSOZ",
    arrivalCityName: "BARRANQUILLA",
    route: "CLO/BAQ",
    daysNotOperated: ["Martes", "Miercoles", "Jueves", "Sabado", "Domingo"]
  });
  const [searchParams, setSearchParams] = useState({
    origin: "CLO",
    destination: "BGA",
    isRoundTrip: false,
    selectDateDeparture: "2020-04-17",
    selectDateArrival: "2020-04-24",
    countAdults: 1,
    countChildrens: 0,
    countBabies: 0
  });
  const [resultsFlights, setResultsFlights] = useState({
    "departure": [
        {
            "segments": [
                {
                    "flightNumber": "1504",
                    "eqp": "734",
                    "stops": 0,
                    "timeFlight": "01:22",
                    "timeConection": "00:00",
                    "departureInfo": {
                        "dateTime": "2020-05-28T07:00",
                        "locationCode": "CLO",
                        "cityName": "Cali",
                        "airPortName": "Aeropuerto Internacional Alfonso Bonilla Aragón"
                    },
                    "arrivalInfo": {
                        "dateTime": "2020-05-28T08:22",
                        "locationCode": "CTG",
                        "cityName": "Cartagena",
                        "airPortName": "Aeropuerto Internacional Rafael Núñez"
                    }
                }
            ],
            "totalTimeFlight": "01:22",
            "fares": [
                {
                    "cabinName": "ECONOMY",
                    "posting": 9,
                    "classCode": "T",
                    "className": "MINI",
                    "currCode": "COP",
                    "baseFare": "145500.00",
                    "totalAmount": "201940.00",
                    "totalFees": "0.00",
                    "totalTax": "56440.00",
                    "faresPerTypePassenger": [
                        {
                            "code": "ADT",
                            "quantity": 1,
                            "baseFare": "145500.00",
                            "totalFare": "201940.00",
                            "taxes": [
                                {
                                    "code": "CO",
                                    "amount": "16500.00",
                                    "totalAmount": "16500.00"
                                },
                                {
                                    "code": "YQ",
                                    "amount": "31100.00",
                                    "totalAmount": "31100.00"
                                },
                                {
                                    "code": "YS",
                                    "amount": "8840.00",
                                    "totalAmount": "8840.00"
                                }
                            ]
                        }
                    ],
                    "taxes": [
                        {
                            "code": "CO",
                            "amount": "16500.00"
                        },
                        {
                            "code": "YQ",
                            "amount": "31100.00"
                        },
                        {
                            "code": "YS",
                            "amount": "8840.00"
                        }
                    ],
                    "terms": {
                        "_id": "5ea345a7285e714d40ee6217",
                        "kiuResKey": "MINI",
                        "name": "MINI",
                        "fareConditions": [
                            {
                                "disabled": false,
                                "available": true,
                                "description": "1 Artículo (Abrigo,bolso,pañalera,coputador portátil, bolsa de compras)",
                                "aditionalInfo": "Sin costo",
                                "name": "Accesorio Personal"
                            },
                            {
                                "disabled": false,
                                "available": true,
                                "description": "Alto (55 cm) x Ancho (45 cm) x Profundidad (25cm)",
                                "aditionalInfo": "1 Pieza 8 kg / 15 Lbs",
                                "name": "Equipaje de Mano"
                            },
                            {
                                "disabled": false,
                                "available": true,
                                "description": "158 cm/62 lineales (Ancho+ Fondo + Alto)",
                                "aditionalInfo": "Cargo adicional 1 Pieza 23 kg / 50.7 Lbs",
                                "name": "Equipaje de Bodega"
                            },
                            {
                                "disabled": false,
                                "available": true,
                                "description": null,
                                "aditionalInfo": "Sin costo",
                                "name": "Selección de asiento"
                            },
                            {
                                "disabled": false,
                                "available": false,
                                "description": null,
                                "aditionalInfo": null,
                                "name": "Abordaje Preferencial"
                            },
                            {
                                "disabled": false,
                                "available": false,
                                "description": null,
                                "aditionalInfo": null,
                                "name": "Cambios"
                            },
                            {
                                "disabled": false,
                                "available": false,
                                "description": null,
                                "aditionalInfo": null,
                                "name": "Devoluciones"
                            }
                        ]
                    }
                },
                {
                    "cabinName": "ECONOMY",
                    "posting": 9,
                    "classCode": "M",
                    "className": "MAXI",
                    "currCode": "COP",
                    "baseFare": "216500.00",
                    "totalAmount": "276490.00",
                    "totalFees": "0.00",
                    "totalTax": "59990.00",
                    "faresPerTypePassenger": [
                        {
                            "code": "ADT",
                            "quantity": 1,
                            "baseFare": "216500.00",
                            "totalFare": "276490.00",
                            "taxes": [
                                {
                                    "code": "CO",
                                    "amount": "16500.00",
                                    "totalAmount": "16500.00"
                                },
                                {
                                    "code": "YQ",
                                    "amount": "31100.00",
                                    "totalAmount": "31100.00"
                                },
                                {
                                    "code": "YS",
                                    "amount": "12390.00",
                                    "totalAmount": "12390.00"
                                }
                            ]
                        }
                    ],
                    "taxes": [
                        {
                            "code": "CO",
                            "amount": "16500.00"
                        },
                        {
                            "code": "YQ",
                            "amount": "31100.00"
                        },
                        {
                            "code": "YS",
                            "amount": "12390.00"
                        }
                    ],
                    "terms": {
                        "_id": "5ea35472285e714d40ee6219",
                        "kiuResKey": "MAXI",
                        "name": "MAXI",
                        "fareConditions": [
                            {
                                "disabled": false,
                                "available": true,
                                "description": "1 Artículo (Abrigo,bolso,pañalera,coputador portátil, bolsa de compras)",
                                "aditionalInfo": "Sin costo",
                                "name": "Accesorio Personal"
                            },
                            {
                                "disabled": false,
                                "available": true,
                                "description": "Alto (55 cm) x Ancho (45 cm) x Profundidad (25cm) ",
                                "aditionalInfo": "1 Pieza 8 kg / 15 Lbs",
                                "name": "Equipaje de Mano"
                            },
                            {
                                "disabled": false,
                                "available": true,
                                "description": "158 cm/62 lineales (Ancho+ Fondo + Alto)",
                                "aditionalInfo": "1 Pieza 23 kg / 50.7 Lbs",
                                "name": "Equipaje de Bodega"
                            },
                            {
                                "disabled": false,
                                "available": true,
                                "description": null,
                                "aditionalInfo": "Sin costo",
                                "name": "Selección de asiento"
                            },
                            {
                                "disabled": false,
                                "available": false,
                                "description": null,
                                "aditionalInfo": null,
                                "name": "Abordaje Preferencial"
                            },
                            {
                                "disabled": false,
                                "available": false,
                                "description": null,
                                "aditionalInfo": "$50.000 (precio Único) por trayecto.",
                                "name": "Cambios"
                            },
                            {
                                "disabled": false,
                                "available": false,
                                "description": null,
                                "aditionalInfo": "70% del valor de la tarifa",
                                "name": "Devoluciones"
                            }
                        ]
                    }
                },
                {
                    "cabinName": "ECONOMY",
                    "posting": 9,
                    "classCode": "S",
                    "className": "SUPER",
                    "currCode": "COP",
                    "baseFare": "265100.00",
                    "totalAmount": "327520.00",
                    "totalFees": "0.00",
                    "totalTax": "62420.00",
                    "faresPerTypePassenger": [
                        {
                            "code": "ADT",
                            "quantity": 1,
                            "baseFare": "265100.00",
                            "totalFare": "327520.00",
                            "taxes": [
                                {
                                    "code": "CO",
                                    "amount": "16500.00",
                                    "totalAmount": "16500.00"
                                },
                                {
                                    "code": "YQ",
                                    "amount": "31100.00",
                                    "totalAmount": "31100.00"
                                },
                                {
                                    "code": "YS",
                                    "amount": "14820.00",
                                    "totalAmount": "14820.00"
                                }
                            ]
                        }
                    ],
                    "taxes": [
                        {
                            "code": "CO",
                            "amount": "16500.00"
                        },
                        {
                            "code": "YQ",
                            "amount": "31100.00"
                        },
                        {
                            "code": "YS",
                            "amount": "14820.00"
                        }
                    ],
                    "terms": {
                        "_id": "5ea3567d285e714d40ee621a",
                        "kiuResKey": "SUPER",
                        "name": "SUPER",
                        "fareConditions": [
                            {
                                "disabled": false,
                                "available": true,
                                "description": "1 Artículo (Abrigo,bolso,pañalera,coputador portátil, bolsa de compras)",
                                "aditionalInfo": "Sin costo",
                                "name": "Accesorio Personal"
                            },
                            {
                                "disabled": false,
                                "available": true,
                                "description": "Alto (55 cm) x Ancho (45 cm) x Profundidad (25cm) ",
                                "aditionalInfo": "1 Pieza 8 kg / 15 Lbs",
                                "name": "Equipaje de Mano"
                            },
                            {
                                "disabled": false,
                                "available": true,
                                "description": "158 cm/62 lineales (Ancho+ Fondo + Alto)",
                                "aditionalInfo": "11 Pieza 32 kg / 70.5 Lbs",
                                "name": "Equipaje de Bodega"
                            },
                            {
                                "disabled": false,
                                "available": true,
                                "description": null,
                                "aditionalInfo": "Sin costo",
                                "name": "Selección de asiento"
                            },
                            {
                                "disabled": false,
                                "available": false,
                                "description": null,
                                "aditionalInfo": null,
                                "name": "Abordaje Preferencial"
                            },
                            {
                                "disabled": false,
                                "available": false,
                                "description": "1er cambio: Sin costo 2do cambio: $100.000 (precio Único) por trayecto.",
                                "aditionalInfo": null,
                                "name": "Cambios"
                            },
                            {
                                "disabled": false,
                                "available": false,
                                "description": null,
                                "aditionalInfo": "100% del valor de la tarifa",
                                "name": "Devoluciones"
                            }
                        ]
                    }
                },
                {
                    "cabinName": "ECONOMY",
                    "posting": 9,
                    "classCode": "W",
                    "className": "MEGA",
                    "currCode": "COP",
                    "baseFare": "372500.00",
                    "totalAmount": "440290.00",
                    "totalFees": "0.00",
                    "totalTax": "67790.00",
                    "faresPerTypePassenger": [
                        {
                            "code": "ADT",
                            "quantity": 1,
                            "baseFare": "372500.00",
                            "totalFare": "440290.00",
                            "taxes": [
                                {
                                    "code": "CO",
                                    "amount": "16500.00",
                                    "totalAmount": "16500.00"
                                },
                                {
                                    "code": "YQ",
                                    "amount": "31100.00",
                                    "totalAmount": "31100.00"
                                },
                                {
                                    "code": "YS",
                                    "amount": "20190.00",
                                    "totalAmount": "20190.00"
                                }
                            ]
                        }
                    ],
                    "taxes": [
                        {
                            "code": "CO",
                            "amount": "16500.00"
                        },
                        {
                            "code": "YQ",
                            "amount": "31100.00"
                        },
                        {
                            "code": "YS",
                            "amount": "20190.00"
                        }
                    ],
                    "terms": {
                        "_id": "5ea357b1285e714d40ee621b",
                        "kiuResKey": "MEGA",
                        "name": "MEGA",
                        "fareConditions": [
                            {
                                "disabled": false,
                                "available": true,
                                "description": "1 Articulo, (Abrigo,bolso,pañalera,coputador portátil, bolsa de compras)",
                                "aditionalInfo": "Sin costo",
                                "name": "Accesorio Personal"
                            },
                            {
                                "disabled": false,
                                "available": true,
                                "description": "Alto (55 cm) x Ancho (45 cm) x Profundidad (25cm) ",
                                "aditionalInfo": "1 Pieza 8 kg / 15 Lbs",
                                "name": "Equipaje de Mano"
                            },
                            {
                                "disabled": false,
                                "available": true,
                                "description": "158 cm/62 lineales (Ancho+ Fondo + Alto)",
                                "aditionalInfo": "2 Piezas 32 kg / 70.5 Lbs",
                                "name": "Equipaje de Bodega"
                            },
                            {
                                "disabled": false,
                                "available": true,
                                "description": null,
                                "aditionalInfo": "Sin costo",
                                "name": "Selección de asiento"
                            },
                            {
                                "disabled": false,
                                "available": true,
                                "description": null,
                                "aditionalInfo": null,
                                "name": "Abordaje Preferencial"
                            },
                            {
                                "disabled": false,
                                "available": false,
                                "description": null,
                                "aditionalInfo": "Cambios ilimitados Sin cargo",
                                "name": "Cambios"
                            },
                            {
                                "disabled": false,
                                "available": false,
                                "description": null,
                                "aditionalInfo": "100% del valor de la tarifa ",
                                "name": "Devoluciones"
                            }
                        ]
                    }
                }
            ]
        }
    ],
    "arrival": []
});
  
  const [resultsAvailability, setResultsAvailability] = useState(true);
  const [notResult, setNotResult] = useState(false);
  const [originFlight, setOriginFlight] = useState(null);
  const [returnFlight, setReturnFlight] = useState(null);
  return (
    <div className={classes.paper}>
      <Title center withIcon text1="ELIGE TU" text2="VUELO" />
      <SearchFlightsForm
        setResultsAvailability={resp => setResultsAvailability(resp)}
        setSearchParams={params => setSearchParams(params)}
        setOriginRoute={route => setOriginRoute(route)}
        setReturnRoute={route => setReturnRoute(route)}
        setResultsFlights={result => {
          setResultsFlights(result);
        }}
        setNotResult={(result) => setNotResult(result)}
      />
      {resultsAvailability ? (
        <div style={{ width: "100%" }}>
          <SearchResults
            route={originRoute}
            title={"SELECCIONA LA IDA"}
            searchParams={searchParams}
            flights={resultsFlights.departure}
            selectFlight={flight => {
              setOriginFlight(flight);
            }}
            typeFlight="origin"
          />
          {searchParams.isRoundTrip ? (
            <SearchResults
              route={returnRoute}
              title={<Title text1="SELECCIONA LA" text2="VUELTA" />}
              searchParams={searchParams}
              flights={resultsFlights.arrival}
              selectFlight={flight => setReturnFlight(flight)}
              typeFlight="destination"
            />
          ) : null}
        </div>
      ) : null}
      {notResult ? (
        <div style = {{marginTop:"20px"}}>
        <Title center text1="NO HAY RESULTADOS PARA ESTA" text2="COMBINACION" />
        </div>
      ) : null}
      {originFlight || returnFlight ?
      <SelectedFlightsResumen
        isRoundTrip={searchParams.isRoundTrip}
        originFlight={originFlight}
        returnFlight={returnFlight}
        searchParams={searchParams}
      />:null
      }
    </div>
  );
}
