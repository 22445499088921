import React from 'react';
import SeatSelection from "./SeatSelection"
import { Grid, Button } from "@material-ui/core";
export default function SeatMap ({ seatMap, updateSeat, activePassenger, seatSsrs, flightRPH, passengers, hiddenMap }) {
  return (
    <Grid item xs={11}>
    <Grid container justify="flex-end" direction="row">
      <Grid item xs={12} style={{ marginTop: "15px", marginBottom: "20px", borderBottom: "1px solid grey", borderTop: "1px solid grey" }}>
        <Grid container justifyContent="center" alignItems="center" spacing={2} style={{ maxWidth: "350px", margin: "0 auto" }}>
        <Grid item>
          <div style={{ borderRadius: "50%", border: "2px solid black", backgroundColor: "#FFFFFF", width: "20px", height: "20px", display: "inline-block", marginRight: "8px"}}></div>
          Disponible
        </Grid>
        <Grid item>
          <div style={{ borderRadius: "50%", border: "2px solid black", backgroundColor: "rgb(96, 96, 96)", width: "20px", height: "20px", display: "inline-block", marginRight: "8px"}}></div>
          Ocupado
        </Grid>
        <Grid item>
          <div style={{ borderRadius: "50%", border: "2px solid black", backgroundColor: "rgb(205, 23, 26)", width: "20px", height: "20px", display: "inline-block", marginRight: "8px"}}></div>
          Seleccionado
        </Grid>
      </Grid>
        <div style={{ margin: "20px 0", maxHeight: "500px", overflowY: "scroll", display: "flex", alignItems: "flex-start", justifyContent: "center" }}>
          <SeatSelection
            seatMap={seatMap}
            passengers={passengers}
            activePassenger={activePassenger}
            seatSsrs={seatSsrs}
            flightRph={flightRPH}
            updateSeat={updateSeat}
          />
        </div>
      </Grid>
      <Button variant="contained" color="primary" onClick={hiddenMap}>
        Guardar
      </Button>
    </Grid>
  </Grid>
  )
}
