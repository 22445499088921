import React, { useState, useEffect } from "react";
import {
  Grid,
  Button,
  GridList,
  GridListTile,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  getShortDateName,
  getDatesBeforeAfter,
  getIndexDaysOfTheWeek,
  FormatCurrency
} from "../helpers";
import { flightAvailabilityServices } from "../services";
import { Spinner } from ".";
const useStyles = makeStyles((theme) => ({
  grid: {
    marginTop: "15px",
    marginBottom: "15px",
  },
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    overflow: "hidden",
  },
  gridList: {
    flexWrap: "nowrap",
    transform: "translateZ(0)",
    margin: "0px !important",
    border: "2px solid #E6E6E6",
    borderRadius: "5px",
  },
  gridListTile: {
    height: "none",
    width: "120px",
    marginRight: "2px",
  },
  button: {
    padding: "2px 3px",
    borderRadius: 0,
    height: "70px",
  },
}));
export default function DatesReBooking(props) {
  const classes = useStyles();
  const dates = getDatesBeforeAfter(
    props.searchParams.selectDateDeparture,
    getIndexDaysOfTheWeek(props.route.daysNotOperated)
  );
  const [lowFares, setLowFares] = useState([])
  const [activeIndex, setActiveIndex] = useState(
    dates.findIndex(
      (date) =>
        date.value === props.searchParams.selectDateDeparture
    )
  );
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    getLowFares();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  async function getLowFares(){
    let origin = props.searchParams.origin;
    let destination = props.searchParams.destination;
    const peticion = await flightAvailabilityServices.lowFares(
      props.searchParams.isRoundTrip,
      origin,
      destination,
      props.searchParams.selectDateDeparture,
      props.searchParams.selectDateArrival,
      props.searchParams.countAdults,
      props.searchParams.countChildrens,
      props.searchParams.countBabies,
      props.searchParams.currency
    );
    const resp = await peticion.resp;
    if (peticion.status === 200) {
      //console.log(resp)
      setLowFares(resp)
    }
  }
  async function changeDate(date) {
    let origin = props.searchParams.origin;
    let destination = props.searchParams.destination;
    setLoading(true);
    const peticion = await flightAvailabilityServices.flightAvailability(
      origin,
      destination,
      props.searchParams.isRoundTrip,
      date,
      props.searchParams.selectDateArrival,
      props.searchParams.countAdults,
      props.searchParams.countChildrens,
      props.searchParams.countBabies,
      props.searchParams.currency
    );
    setLoading(false);
    const resp = await peticion.resp;
    if (peticion.status === 200) {
      props.setFlights(resp);
    }
  }
  return (
    <Grid
      container
      justify="center"
      direction="row"
      className={classes.grid}
    >
      <Grid item xs={12}>
        <div className={classes.root}>
          <GridList className={classes.gridList}>
            {dates.map((date, index) => (
              <GridListTile
                key={index}
                style={{ height: null, width: "150px" }}
              >
                <Button
                  disabled={date.disabled}
                  className={classes.button}
                  onClick={() => {
                    setActiveIndex(index);
                    changeDate(date.value);
                  }}
                  fullWidth
                  variant={activeIndex === index ? "contained" : "text"}
                  style={{
                    backgroundColor: activeIndex === index ? "#CD171A" : "#fff",
                    color: activeIndex === index ? "#F97067" : "#AF0061",
                    borderRight:
                      index !== activeIndex &&
                      index !== dates.length - 1 &&
                      activeIndex - 1 !== index
                        ? "2px solid #E6E6E6"
                        : null,
                  }}
                  
                >
                  <Grid container justify="center" direction="row">
                    <Grid item xs={12}>
                      <Typography
                        variant="h6"
                        display="block"
                        style={{ fontSize: "0.9rem", lineHeight: "1.3", color: activeIndex === index ? "#fff": "#CD171A" }}
                      >
                        {getShortDateName(date.value)}
                      </Typography>
                    </Grid>
                    {!date.disabled&&lowFares[index]&&lowFares[index].flights.length?
                    <div>

                    
                    <Grid item xs={12}>
                      <Typography
                        display="block"
                        style={{ fontSize: "0.7rem", color:activeIndex === index?"#fff":"#85858D" }}
                      >
                        Desde
                      </Typography>
                    </Grid>

                    <Typography style={{fontSize: "0.9rem",color:activeIndex === index?"#fff":"#85858D" }}>{`${props.searchParams.currency}`} {FormatCurrency(`${lowFares[index].flights[0].totalFare}`)}</Typography>
                    </div>
                    : 
                    <div>
                        <Typography style={{fontSize: "0.9rem",color:"#85858D" }}>{date.disabled?"NO DISPONIBLE":null}</Typography>
                    </div>
                    }
                    </Grid>
                </Button>
              </GridListTile>
            ))}
          </GridList>
        </div>
      </Grid>
      <Spinner loading={loading} />
    </Grid>
  );
}
