import React, { useState } from "react";
import {
  StaticDatePicker,
  LocalizationProvider,
  //DateRangePicker,
} from "@material-ui/pickers";
import esLocale from "date-fns/locale/es";
import DateFnsUtils from "@material-ui/pickers/adapter/date-fns";
import { Popover, TextField, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Moment from "moment";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "70%",
  },
  adornment: {
    width: "22px",
  },
  input: {
    "&:before": {
      borderBottom: "1px solid #CD171A",
    },
    "&:after": {
      borderBottom: "2px solid #CD171A",
    },
    "&:focus": {
      borderBottom: "2px solid #CD171A",
    },
    color: "#575756",
    fontSize: "0.8rem",
  },
  floatingLabelFocusStyle: {
    color: "#CD171A",
    fontSize: "15pt",
  },
  inputLabel: {
    fontSize: "14pt",
    paddingBottom: 0,
    color: "#555555!important",
  },
  underline: {
    "&::before": {
      display: "none",
    },
    "&::after": {
      display: "none",
    },
  },
  inputDiv: {
    borderWidth: "1pt",
    borderStyle: "solid",
    borderColor: "#cccccc",
    padding: "5px",
    borderRadius: "5px",
    "& .MuiFormControl-root": {
      width: "100%",
    },
    "& .MuiInputBase-input": {
      padding: "6px 0px 0px 0px",
    },
  },
  label: {
    color: "#575756",
    width: "45%",
    fontSize: "0.8rem",
    fontWeight: "400",
    lineHeight: "1.5",
  },
  container: {
    display: "inline-flex",
    alignItems: "center",
    verticalAlign: "middle",
    width: "100%",
  },
  [theme.breakpoints.down("md")]: {
    floatingLabelFocusStyle: {
      fontSize: "13pt",
    },
  },
}));
export default function DateRangeForm(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElB, setAnchorElB] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickB = (event) => {
    setAnchorElB(event.currentTarget);
  };

  const handleCloseB = () => {
    setAnchorElB(null);
  };

  const open = Boolean(anchorEl);
  const openB = Boolean(anchorElB);

  const id = open ? "simple-popover" : undefined;
  function disableDaysOfTheWeekends(date, days) {
    return days.includes(date.getDay());
  }
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  return (
    <div style={{ width: "100%" }}>
      <LocalizationProvider dateAdapter={DateFnsUtils} locale={esLocale}>
        {props.isRoundTrip ? (
          <div style={{ width: "100%" }}>
            <Grid
              container
              spacing={2}
              justify="center"
              direction="row"
              alignItems="center"
            >
              <Grid item xs={6}>
                <div className={classes.inputDiv}>
                  <TextField
                    label="Fecha Ida"
                    value={
                      props.dateDeparture !== null && props.dateDeparture !== ""
                        ? Moment(props.dateDeparture).format("DD-MM-YYYY")
                        : ""
                    }
                    onClick={(e) => {
                      handleClick(e);
                    }}
                    variant="standard"
                    onChange={() => {}}
                    InputLabelProps={{
                      shrink: true,
                      className: classes.floatingLabelFocusStyle,
                      htmlFor: "dateDeparture",
                    }}
                    //inputProps={classes.input}
                    error={props.dateDepartureError}
                    helperText={props.dateDepartureHelperText}
                    InputProps={{
                      input: classes.inputLabel,
                      classes: { underline: classes.underline },
                    }}
                    placeholder=""
                  />
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className={classes.inputDiv}>
                  <TextField
                    value={
                      props.dateArrival !== null && props.dateArrival !== ""
                        ? Moment(props.dateArrival).format("DD-MM-YYYY")
                        : ""
                    }
                    label="Fecha Regreso"
                    onClick={(e) => {
                      if (!props.disabled) handleClickB(e);
                    }}
                    variant="standard"
                    onChange={() => {}}
                    InputLabelProps={{
                      shrink: true,
                      className: classes.floatingLabelFocusStyle,
                      htmlFor: "dateArrival",
                    }}
                    //inputProps={classes.input}
                    error={props.dateArrivalError}
                    helperText={props.dateArrivalHelperText}
                    disabled={!props.isRoundTrip}
                    InputProps={{
                      input: classes.inputLabel,
                      classes: { underline: classes.underline },
                    }}
                    placeholder=""
                  />
                </div>
              </Grid>
            </Grid>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <div style={{ width: "320px" }}>
                <StaticDatePicker
                  displayStaticWrapperAs="desktop"
                  animateYearScrolling
                  calendars={matches ? 2 : 1}
                  disablePast={props.disablePast}
                  disableFuture={props.disableFuture}
                  minDate={new Date()}
                  disableToolbar={!props.toolbar}
                  autoOk={true}
                  variant="static"
                  format={props.format ? props.format : "dd-MM-yyyy"}
                  shouldDisableDate={(date) =>
                    disableDaysOfTheWeekends(
                      date,
                      props.disableDays ? props.disableDays : []
                    )
                  }
                  value={props.dateDeparture}
                  onChange={
                    props.onChange
                      ? (date) => {
                          props.onChange([date, null]);
                          handleClose();
                        }
                      : () => {
                          handleClose();
                        }
                  }
                />
              </div>
            </Popover>
            <Popover
              id={id}
              open={openB}
              anchorEl={anchorElB}
              onClose={handleCloseB}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <div style={{ width: "320px" }}>
                <StaticDatePicker
                  displayStaticWrapperAs="desktop"
                  animateYearScrolling
                  calendars={matches ? 2 : 1}
                  disablePast={props.disablePast}
                  disableFuture={props.disableFuture}
                  //minDate={new Date()}
                  minDate={
                    props.dateDeparture ? props.dateDeparture : new Date()
                  }
                  disableToolbar={!props.toolbar}
                  autoOk={true}
                  variant="static"
                  format={props.format ? props.format : "dd-MM-yyyy"}
                  shouldDisableDate={(date) =>
                    disableDaysOfTheWeekends(
                      date,
                      props.disableDays ? props.disableDays : []
                    )
                  }
                  value={
                    props.dateArrival ? props.dateArrival : props.dateDeparture
                  }
                  onChange={
                    props.onChange
                      ? (date) => {
                          props.onChange([props.dateDeparture, date]);
                          handleCloseB();
                        }
                      : null
                  }
                />
              </div>
            </Popover>
          </div>
        ) : (
          <div style={{ width: "100%" }}>
            <Grid
              container
              spacing={2}
              justify="center"
              direction="row"
              alignItems="center"
            >
              <Grid item xs={6}>
                <div className={classes.inputDiv}>
                  <TextField
                    label="Fecha Ida"
                    value={
                      props.dateDeparture !== null && props.dateDeparture !== ""
                        ? Moment(props.dateDeparture).format("DD-MM-YYYY")
                        : ""
                    }
                    onClick={(e) => {
                      handleClick(e);
                    }}
                    variant="standard"
                    onChange={() => {}}
                    InputLabelProps={{
                      shrink: true,
                      className: classes.floatingLabelFocusStyle,
                      htmlFor: "dateDeparture",
                    }}
                    //inputProps={classes.input}
                    error={props.dateDepartureError}
                    helperText={props.dateDepartureHelperText}
                    InputProps={{
                      input: classes.inputLabel,
                      classes: { underline: classes.underline },
                    }}
                    placeholder=""
                  />
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className={classes.inputDiv}>
                  <TextField
                    value={
                      props.dateArrival !== null && props.dateArrival !== ""
                        ? Moment(props.dateArrival).format("DD-MM-YYYY")
                        : ""
                    }
                    label="Fecha Regreso"
                    onClick={(e) => {
                      if (!props.disabled) handleClick(e);
                    }}
                    variant="standard"
                    onChange={() => {}}
                    InputLabelProps={{
                      shrink: true,
                      className: classes.floatingLabelFocusStyle,
                      htmlFor: "dateDeparture",
                    }}
                    //inputProps={classes.input}
                    error={props.dateArrivalError}
                    helperText={props.dateArrivalHelperText}
                    disabled={!props.isRoundTrip}
                    InputProps={{
                      input: classes.inputLabel,
                      classes: { underline: classes.underline },
                    }}
                    placeholder=""
                  />
                </div>
              </Grid>
            </Grid>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <div style={{ width: "320px" }}>
                <StaticDatePicker
                  displayStaticWrapperAs="desktop"
                  animateYearScrolling
                  calendars={matches ? 2 : 1}
                  disablePast={props.disablePast}
                  disableFuture={props.disableFuture}
                  minDate={new Date()}
                  disableToolbar={!props.toolbar}
                  autoOk={true}
                  variant="static"
                  format={props.format ? props.format : "dd-MM-yyyy"}
                  shouldDisableDate={(date) =>
                    disableDaysOfTheWeekends(
                      date,
                      props.disableDays ? props.disableDays : []
                    )
                  }
                  value={props.dateDeparture}
                  onChange={
                    props.onChange
                      ? (date) => {
                          props.onChange([date, null]);
                          handleClose();
                        }
                      : () => {
                          handleClose();
                        }
                  }
                />
              </div>
            </Popover>
          </div>
        )}
      </LocalizationProvider>
    </div>
  );
}
