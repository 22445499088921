import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import urls from "../urls";
import { Spinner, ModalPaymentError } from "../components";
//function useQuery() {
//  return new URLSearchParams(useLocation().search);
//}
export default function PaymentCreditCardPaypal(props) {
  const [loading, setLoading] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [openModal, setOpenModal] = useState(false)
  //let query = useQuery();
  //let location = useLocation();
  let history = useHistory();
  //if (!location.state) {
  //  history.push(`/`);
  //}
  //const reservation = location.state.reservation;
  //const pnr = query.get("pnr");
  useEffect(() => {
    window.addEventListener("message", getMessage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  function getMessage(e) {
    const data = e.data;
    //console.log(data)
    if (data.type === "loading") {
      setLoading(data.state.loading);
    } else if (data.type === "response") {
        if(data.status===200)
        { 
          history.push(`/thankyoupage`, data)
        }
        else {
          setToastMessage(data.state.error.message)
          setOpenModal(true)
        }
    } else if(data.type === "back"){
        props.goBack()
    }
    /*setToast(true);
    if (status === 200) {
      if (
        data.payment.status === "approved" ||
        data.payment.status === "in_process"
      ) {
        setToast(false);
        return history.push(`/resumenpago`, {
          params: props.params,
          reservation: props.reservation,
          payment: data.payment,
        });
      }
      setToastMessage(`${data.message}. Puede intentarlo de nuevo.`);
    } else if (status === 400) {
      setToastMessage(data.error.message);
    } else {
      setToastMessage("Error");
    }*/
  }
  return (
    <Grid
      container
      justify="center"
      direction="row"
      alignItems="center"
      spacing={1}
    >
      <Grid item md={5} xs={11}>
      {!openModal?
        <iframe
          style={{ border: 0 }}
          //src={`${urls.paymentPaypalCreditCard}?pnr=${props.pnr}&button=card`}
          src={`${urls.paypal}${props.currency}?pnr=${props.pnr}&button=card`}
          title="Payment Credit Card"
          width="100%"
          height="550px"
        />:null}
      </Grid>
      <Spinner loading={loading} />
      <ModalPaymentError message={toastMessage} open={openModal} close={()=> setOpenModal(false)}/>
    </Grid>
  );
}
