const UNACCOMPANIED_MINOR = [
    "UMNR"
];

const PETS = [
    "AVIL",
    "PETC",
    "AVIH",
    "AVIM"
];

const BAGGAGES = [
    "FBFI",
    "ABAG",
    "PBGA",
    "ARPT",
    "FBAG",
    "OBAG",
    //"CBAG",
    "CBBG",
    "XBAG",
    "BULK",
    "PBGF"
];

const SPORTS_ARTICLES = [
    "BIKE",
    "SPEQ",
    "WESP",
    "ARPT",
    "BGGF",
    "SMSW",
    "SMST",
    "SPAR",
    "SMSB",
    "SPEL"
];

const SEAT_SELECTION = [
    "SEAT",
    "STEX",
    "STPR",
    "STWF",
    "STAF",
    "STXB",
    "STRR",
    "STFA",
    "EXST"
]

export default function getAncillaryGroup(code){
    if(UNACCOMPANIED_MINOR.includes(code)){
        return "UNACCOMPANIED_MINOR"
    }else if(PETS.includes(code)){
        return "PETS"
    }else if(BAGGAGES.includes(code)){
        return "BAGGAGES"
    }else if(SPORTS_ARTICLES.includes(code)){
        return "SPORTS_ARTICLES"
    } else if (SEAT_SELECTION.includes(code)) {
        return "SEAT_SELECTION"
    } else {
        return "OTHERS"
    }
}