import React, { useState, useEffect } from "react";
import {
  PassengersSelectStatic,
  Spinner,
  //Currency,
  DateRangeForm,
  ModalTourCodeError,
} from "../components/index";
import {
  Grid,
  MenuItem,
  TextField,
  Typography,
  Button,
} from "@material-ui/core";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import {
  destinationServices,
  flightAvailabilityServices,
  passengerServices,
} from "../services";
import { ExpandMore } from "@material-ui/icons";
import { styled } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import {
  getIndexDaysOfTheWeek,
  filterDate,
  FormValidator,
  promotionRouters,
} from "../helpers/";
//import { isAfter } from "date-fns";

const MyExpandExpandMore = styled(ExpandMore)({
  color: "#FA7268",
  "&.MuiSelect-iconOpen": {
    transform: "none",
  },
});

export default function IframeBookingForm(props) {
  const classes = useStyles();

  //const [flightType, setFlightType]=useState('');
  const [airports, setAirports] = useState([]);
  const [radioValue, setRadioValue] = useState("roundTrip");
  const [destinationsDepartures, setDestinationsDepartures] = useState([]);
  const [destinationsArrivals, setDestinationsArrivals] = useState([]);
  const [origin, setOrgin] = useState("");
  const [originError, setOrginError] = useState("");
  const [destination, setDestination] = useState("");
  const [destinationError, setDestinationError] = useState("");
  //const [originRoute, setOriginRoute] = useState({});
  const [daysNotOperatedDeparture, setDaysNotOperatedDeparture] = useState([]);
  const [daysNotOperatedArrival, setDaysNotOperatedArrival] = useState([]);
  const [daysNotOperated, setDaysNotOperated] = useState([]);
  //const [returnRoute, setReturnRoute] = useState({});
  const [dateDeparture, setDateDeparture] = useState(null);
  const [selectDateDeparture, setSelectDateDeparture] = useState("");
  const [dateArrival, setDateArrival] = useState(null);
  const [selectDateArrival, setSelectDateArrival] = useState("");
  const [countAdults, setCountAdults] = useState(1);
  const [countChildrens, setCountChildrens] = useState(0);
  const [countBabies, setCountBabies] = useState(0);
  const [selectDateDepartureError, setSelectDateDepartureError] = useState("");
  const [selectDateArrivalError, setSelectDateArrivalError] = useState("");
  //const [fields, setFields]=useState(false)
  const [isRoundTrip, setIsRoundTrip] = useState(true);
  const [loading, setLoading] = useState(false);
  const [originRoute, setOriginRoute] = useState({});
  const [returnRoute, setReturnRoute] = useState({});
  //const [currency, setCurrency]=useState("USD")
  const [code, setCode] = useState("");
  const [disabledPassengers, setDisabledPassengers] = useState(false);
  const [tourCodeError, setTourCodeError] = useState(false);
  const [codeText, setCodeText] = useState("");
  const [codeError, setCodeError] = useState(false);
  const [message, setMessage] = useState("");
  const validator = new FormValidator([
    {
      field: "origin",
      method: "isEmpty",
      validWhen: false,
      message: "Seleccione el origen",
    },
    {
      field: "destination",
      method: "isEmpty",
      validWhen: false,
      message: "Seleccione el destino",
    },
    {
      field: "selectDateDeparture",
      method: "isEmpty",
      validWhen: false,
      message: "Seleccione la fecha de salida",
    },
    {
      field: "selectDateArrival",
      method: "isEmpty",
      validWhen: !isRoundTrip,
      message: "Seleccione la fecha de retorno",
    },
  ]);
  useEffect(() => {
    getAirports();
  }, []);

  const getAirports = async () => {
    const peticion = await destinationServices.getAirports();
    const resp = peticion.resp;
    if (peticion.status === 200) {
      setAirports(resp.airports);
    }
  };

  const handleRadioChange = (event) => {
    setRadioValue(event.target.value);
    setIsRoundTrip(event.target.value === "roundTrip" ? true : false);
    setSelectDateArrival(
      event.target.value === "roundTrip" ? selectDateArrival : ""
    );
    setDateArrival(event.target.value === "roundTrip" ? dateArrival : null);
    if (event.target.value === "roundTrip" && dateDeparture) {
      setDaysNotOperated(daysNotOperatedArrival);
    }
    if (event.target.value !== "roundTrip") {
      setDaysNotOperated(daysNotOperatedDeparture);
    }
  };

  const getDestinations = async (locationCode) => {
    const peticion = await destinationServices.getDestinations(locationCode);
    const resp = peticion.resp;
    //console.log(resp)
    if (peticion.status === 200) {
      setDestinationsDepartures(resp.routes.departures);
      setDestinationsArrivals(resp.routes.arrivals);
    }
  };

  const changeAirPort = (e) => {
    setOrgin(e.target.value);
    getDestinations(e.target.value);
    if (!promotionRouters(e.target.value, destination)) {
      setDisabledPassengers(false);
      setCodeText("");
      setCodeError(false);
    } else {
      if (code !== "") {
        validateCode(code);
      }
    }
  };

  const changeDestination = (e) => {
    setDestination(e.target.value);
    const destinationDeparture = destinationsDepartures.find(
      (destination) => destination.route === `${origin}/${e.target.value}`
    );

    if (destinationDeparture) {
      setOriginRoute(destinationDeparture);
      setDaysNotOperatedDeparture(destinationDeparture.daysNotOperated);
      setDaysNotOperated(destinationDeparture.daysNotOperated);
    } else {
      setDaysNotOperated([
        "Lunes",
        "Martes",
        "Miercoles",
        "Jueves",
        "Viernes",
        "Sabado",
        "Domingo",
      ]);
      setDaysNotOperatedDeparture([
        "Lunes",
        "Martes",
        "Miercoles",
        "Jueves",
        "Viernes",
        "Sabado",
        "Domingo",
      ]);
    }
    const destinationArrival = destinationsArrivals.find(
      (destination) => destination.route === `${e.target.value}/${origin}`
    );
    if (destinationArrival) {
      setReturnRoute(destinationArrival);
      setDaysNotOperatedArrival(destinationArrival.daysNotOperated);
    } else {
      setDaysNotOperatedArrival([
        "Lunes",
        "Martes",
        "Miercoles",
        "Jueves",
        "Viernes",
        "Sabado",
        "Domingo",
      ]);
    }
    if (!promotionRouters(origin, e.target.value)) {
      setDisabledPassengers(false);
      setCodeText("");
      setCodeError(false);
    } else {
      if (code !== "") {
        validateCode(code);
      }
    }
  };

  const flightAvailability = async () => {
    const validation = validator.validate({
      origin,
      destination,
      selectDateDeparture,
      selectDateArrival,
    });
    setOrginError(validation.origin.message);
    setDestinationError(validation.destination.message);
    setSelectDateDepartureError(validation.selectDateDeparture.message);
    setSelectDateArrivalError(validation.selectDateArrival.message);
    if (validation.isValid) {
      setLoading(true);
      const peticion = await flightAvailabilityServices.flightAvailability(
        origin,
        destination,
        isRoundTrip,
        selectDateDeparture,
        selectDateArrival,
        countAdults,
        countChildrens,
        countBabies,
        "USD", //currency,
        code
      );
      setLoading(false);
      // console.log(resp)
      const resp = await peticion.resp;
      const terms = await peticion.terms;
      if (peticion.status === 200) {
        if (
          (isRoundTrip && resp.length) ||
          (!isRoundTrip && resp.departure.length > 0)
        ) {
          props.setOriginRoute(originRoute);
          props.setReturnRoute(returnRoute);
          props.setResultsFlights(resp);
          props.setResultsAvailability(true);
          props.setNotResult(false);
          props.setTerms(terms);
        } else {
          props.setResultsAvailability(false);
          props.setNotResult(true);
        }
        props.setSearchParams({
          origin,
          destination,
          isRoundTrip,
          selectDateDeparture,
          selectDateArrival,
          countAdults,
          countChildrens,
          countBabies,
          currency: "USD",
          tourCode: code,
        });
        props.setStep();
      } else {
        setMessage(resp.error.message);
        setTourCodeError(true);
      }
    }
  };

  const validateCode = async (code) => {
    setLoading(true);
    const { status, resp } = await passengerServices.passengerPromoData(code);
    setLoading(false);
    if (status === 200) {
      setCodeError(false);
      setCodeText("Código promocional aplicado con exito");
      if (!resp.multiPassengers) {
        setDisabledPassengers(true);
        if (resp.passenger.type === "ADT") {
          setCountAdults(1);
          setCountBabies(0);
          setCountChildrens(0);
        } else {
          setCountAdults(0);
          setCountBabies(0);
          setCountChildrens(1);
        }
      } else {
        setDisabledPassengers(false);
      }
    } else {
      setDisabledPassengers(false);
      setCodeError(true);
      setCodeText("Código promocional invalido");
      setCountAdults(1);
    }
  };
  return (
    <Grid container justify="center" style={{ padding: "20px" }}>
      <Grid
        item
        xs={12}
        lg={12}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center" /*border:"1pt solid #000"*/,
        }}
      >
        <FormControl component="fieldset" style={{ marginBottom: "20px" }}>
          <RadioGroup
            row
            aria-label="position"
            name="position"
            value={radioValue}
            onChange={handleRadioChange}
          >
            <FormControlLabel
              value="roundTrip"
              control={<Radio color="primary" />}
              label="Ida y regreso"
              labelPlacement="end"
            />
            <FormControlLabel
              value="oneWay"
              control={<Radio color="primary" />}
              label="Solo ida"
              labelPlacement="end"
            />
          </RadioGroup>
        </FormControl>
        <Grid container>
          <Grid item md={12} xs={12} style={{ marginBottom: "20px" }}>
            <div className={classes.inputDiv}>
              <TextField
                value={origin}
                label="Desde"
                select
                id="origin"
                helperText={originError}
                error={originError !== ""}
                InputLabelProps={{
                  shrink: true,
                  className: classes.floatingLabelFocusStyle,
                  htmlFor: "origin",
                }}
                InputProps={{
                  /*disabled:fields,*/ classes: {
                    input: classes.inputLabel,
                    underline: classes.underline,
                  },
                }}
                SelectProps={{ IconComponent: MyExpandExpandMore }}
                onChange={changeAirPort}
              >
                {airports.map((airport, index) => (
                  <MenuItem key={index} value={airport.locationCode}>
                    {`${airport.cityName} (${airport.locationCode}) ${airport.airPortName}`}
                  </MenuItem>
                ))}
              </TextField>
            </div>
          </Grid>
          <Grid item md={12} xs={12} style={{ marginBottom: "20px" }}>
            <div className={classes.inputDiv}>
              <TextField
                value={destination}
                label="Hacia"
                select
                id="destiny"
                helperText={destinationError}
                error={destinationError !== ""}
                InputLabelProps={{
                  shrink: true,
                  className: classes.floatingLabelFocusStyle,
                  htmlFor: "destiny",
                }}
                InputProps={{
                  /*disabled:fields,*/ classes: {
                    input: classes.inputLabel,
                    underline: classes.underline,
                  },
                }}
                SelectProps={{ IconComponent: MyExpandExpandMore }}
                onChange={changeDestination}
              >
                {destinationsDepartures.map((destination, index) => (
                  <MenuItem key={index} value={destination.arrival}>
                    {`${destination.arrivalCityName} (${destination.arrival}) ${destination.arrivalAirportName}`}
                  </MenuItem>
                ))}
              </TextField>
            </div>
          </Grid>
          <Grid
            container
            justify="space-between"
            style={{ marginBottom: "12px" }}
          >
            <DateRangeForm
              disableDays={getIndexDaysOfTheWeek(daysNotOperated)}
              dateDeparture={dateDeparture}
              dateArrival={dateArrival}
              isRoundTrip={isRoundTrip}
              disabled={!isRoundTrip}
              dateDepartureError={selectDateDepartureError !== ""}
              dateDepartureHelperText={selectDateDepartureError}
              dateArrivalError={selectDateArrivalError !== ""}
              dateArrivalHelperText={selectDateArrivalError}
              selectDaparture={() =>
                setDaysNotOperated(daysNotOperatedDeparture)
              }
              selectArrival={() => setDaysNotOperated(daysNotOperatedArrival)}
              onChange={(dates) => {
                if (dates[0]) {
                  setDateDeparture(dates[0]);
                  setSelectDateDeparture(filterDate(dates[0]));
                  setDateArrival("");
                  setSelectDateArrival("");
                  if (isRoundTrip) {
                    setDaysNotOperated(daysNotOperatedArrival);
                  }
                }
                if (dates[1]) {
                  setDateArrival(dates[1]);
                  setSelectDateArrival(filterDate(dates[1]));
                  setDaysNotOperated(daysNotOperatedDeparture);
                }
              }}
            />
          </Grid>
          <Grid
            container
            justify="space-between"
            style={{ marginBottom: "20px" }}
          >
            <Grid item className={classes.grid} md={12} xs={12}>
              <div className={classes.inputDiv}>
                <PassengersSelectStatic
                  disabled={disabledPassengers}
                  countAdults={countAdults}
                  countChildrens={countChildrens}
                  countBabies={countBabies}
                  setCountAdults={(value) => setCountAdults(value)}
                  setCountBabies={(value) => setCountBabies(value)}
                  setCountChildrens={(value) => setCountChildrens(value)}
                  classes={classes}
                  variant="text"
                />
              </div>
            </Grid>
          </Grid>
          {/*
                    <Grid container justify="center" style={{marginBottom:"20px"}}>
                        <Grid item className={classes.grid} xs={12}>
                            <Currency 
                                setCurrency={(type)=> setCurrency(type)}
                                currency={currency}
                            />
                        </Grid>
                    </Grid>
                    */}
          {promotionRouters(origin, destination) && (
            <Grid item md={12} xs={12} style={{ marginBottom: "20px" }}>
              <div className={classes.inputDiv}>
                <TextField
                  value={code}
                  label="Código promocional"
                  id="code"
                  helperText={codeText}
                  InputLabelProps={{
                    shrink: true,
                    className: classes.floatingLabelFocusStyle,
                    htmlFor: "code",
                  }}
                  InputProps={{
                    /*disabled:fields,*/ classes: {
                      input: classes.inputLabel,
                      underline: classes.underline,
                    },
                  }}
                  onChange={(e) => {
                    setCode(e.target.value.toUpperCase());
                    if (e.target.value !== "") {
                      //setDisabledPassengers(true)
                      //if(e.target.value.length===6){
                      //  validateCode(e.target.value)
                      //}
                    } else {
                      setDisabledPassengers(false);
                      setCodeText("");
                      setCodeError(false);
                    }
                  }}
                  onBlur={(e) => {
                    if (e.target.value !== "") {
                      //setDisabledPassengers(true)
                      validateCode(e.target.value);
                    } else {
                      setDisabledPassengers(false);
                      setCodeText("");
                      setCodeError(false);
                    }
                  }}
                />
              </div>
            </Grid>
          )}
          <Grid item xs={12} md={12}>
            <Button
              variant="contained"
              fullWidth={true}
              color="primary"
              style={{
                boxShadow: "none",
                padding: 0,
                minWidth: "100%",
                height: "50px",
                borderRadius: "5px",
              }}
              onClick={flightAvailability}
              disabled={code !== "" && codeError}
            >
              <Typography style={{ color: "#fff" }}>BUSCAR VUELOS</Typography>
              <img
                src="./img/lupa-02.png"
                alt=""
                style={{ marginLeft: "5px" }}
              />
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Spinner loading={loading} />
      {tourCodeError && (
        <ModalTourCodeError
          message={message}
          close={() => setTourCodeError(false)}
        />
      )}
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  floatingLabelFocusStyle: {
    color: "#CD171A",
    fontSize: "15pt",
  },
  inputLabel: {
    fontSize: "12pt",
    paddingBottom: 0,
    color: "#555555!important",
  },
  underline: {
    "&::before": {
      display: "none",
    },
    "&::after": {
      display: "none",
    },
  },
  selectLabel: {
    color: "#CD171A",
    fontSize: "11.5pt",
  },
  inputDiv: {
    borderWidth: "1pt",
    borderStyle: "solid",
    borderColor: "#cccccc",
    padding: "5px",
    borderRadius: "5px",
    "& .MuiFormControl-root": {
      width: "100%",
    },
  },
  button: {
    padding: "0px",
    color: "#C45E96",
    border: "none",
    borderRadius: 0,
    justifyContent: "flex-start",
  },
  [theme.breakpoints.down("md")]: {
    floatingLabelFocusStyle: {
      fontSize: "13pt",
    },
  },
}));
