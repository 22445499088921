import React from "react";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import urls from "../urls"
const useStyles = makeStyles({
    paper: {
        position: "absolute",
        maxWidth: "500px",
        //minHeight:"550px",
        height: "90vh",
        width: "90%",
        backgroundColor: "#fff",
        border: "1px solid #000",
        //padding: "20px",
        top: "2%",
      },
      modal: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
});
export default function ModalPaymentCreditCard(props) {
  const classes = useStyles();
  return (
    <Modal className={classes.modal} open={props.open} onClose={() => props.close()}>
      <div className={classes.paper}>
           <iframe  src={`${urls.paymentCreditCard}?pnr=${props.pnr}`} title="Payment Credit Card" width="100%" height="100%"/>
      </div>
    </Modal>
  );
}
