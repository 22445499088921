import urls from "../urls";

const createReservation = async (
  segments,
  currency,
  tourCode,
  isPriceZero = false,
  ancillaries,
  contact
) => {
  const body = {
    segments,
    tourCode,
    ancillaries,
    contact,
  };

  if (isPriceZero) {
    body.price = 0;
  }
  const peticion = await fetch(
    `${urls.createReservation}?currency=${currency}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Basic " + btoa("avior:fazQMUyq"),
      },
      body: JSON.stringify(body),
    }
  );
  const resp = await peticion.json();
  return {
    status: peticion.status,
    resp,
  };
};

const getSeatMap = async (pnr, lastName, flightNumber) => {
  const peticion = await fetch(
    `${urls.seatMap}/${pnr}/${lastName.toUpperCase()}/${flightNumber}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Basic " + btoa("avior:fazQMUyq"),
      },
    }
  );
  const resp = await peticion.json();
  return {
    status: peticion.status,
    resp,
  };
};

const getSeatAvailability = async (pnr, lastName, flightNumber) => {
  const peticion = await fetch(
    `${urls.seatAvailability}/${pnr}/${lastName.toUpperCase()}/${flightNumber}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Basic " + btoa("avior:fazQMUyq"),
      },
    }
  );
  const resp = await peticion.json();
  return {
    status: peticion.status,
    resp,
  };
};

const selectSeats = async (pnr, seats) => {
  const body = JSON.stringify({ seats });
  const peticion = await fetch(`${urls.modifyBooking}/${pnr}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Basic " + btoa("avior:fazQMUyq"),
    },
    body: body,
  });
  const response = await peticion.json();
  return {
    status: peticion.status,
    response,
  };
};

const searchPnr = async (pnr, lastName) => {
  const peticion = await fetch(`${urls.booking}/${pnr}/${lastName}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Basic " + btoa("avior:fazQMUyq"),
    },
  });
  const resp = await peticion.json();
  return {
    status: peticion.status,
    resp,
  };
};

const cancelPnr = async (pnr) => {
  await fetch(`${urls.booking}/cancel/${pnr}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Basic " + btoa("avior:fazQMUyq"),
    },
  });
};

const promotion = async (segments) => {
  const peticion = await fetch(`${urls.booking}/promotion`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Basic " + btoa("avior:fazQMUyq"),
    },
    body: JSON.stringify({
      segments,
    }),
  });
  const resp = await peticion.json();
  return {
    status: peticion.status,
    resp,
  };
};

const getReservation = async ({ ...reservationRequest }) => {
  const peticion = await fetch(
    `${urls.getReservation}/${reservationRequest.pnr}/${reservationRequest.lastName}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Basic " + btoa("avior:fazQMUyq"),
      },
    }
  );
  const resp = await peticion.json();
  return {
    status: peticion.status,
    resp,
  };
};

const reservationServices = Object.freeze({
  createReservation,
  getSeatMap,
  getSeatAvailability,
  selectSeats,
  searchPnr,
  cancelPnr,
  promotion,
  getReservation,
});

export default reservationServices;
