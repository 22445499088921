import React from "react";
import { Grid, Typography } from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import {
  getFullDateName,
  getTime,
  FormatCurrency,
  findTaxAmount,
} from "../helpers";
export default function FligthResume(props) {
  //console.log("props.flight",props.flight.fare.totalAmount)
  return (
    <div className={props.classes.container}>
      <Grid container justify="center" direction="row" alignItems="center">
        <Grid item xs={12}>
          <Grid container className={props.classes.padding}>
            <Grid item xs={6} style={{ display: "flex" }}>
              <div
                style={{ width: "10%", display: "flex", alignItems: "center" }}
              >
                {props.direction === "foward" ? (
                  <ArrowForwardIcon className={props.classes.arrow} />
                ) : (
                  <ArrowBackIcon className={props.classes.arrow} />
                )}
              </div>
              <div style={{ width: "90%" }}>
                <Typography display="inline" className={props.classes.p1}>
                  VUELO DE{" "}
                </Typography>
                <Typography
                  display="inline"
                  className={props.classes.span1}
                  color="primary"
                >
                  {props.direction === "foward" ? "IDA" : "REGRESO"}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={5}>
              <Typography className={props.classes.span1} color="primary">
                {`${props.currency}`}{" "}
                {FormatCurrency(
                  `${parseFloat(
                    props.flight.fare.totalAmount -
                      findTaxAmount(props.flight.fare.taxes, "6P") /
                        (props.isRoundTrip ? 2 : 1)
                  ).toFixed(2)}`
                )}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} className={props.classes.box}>
          <Grid container className={props.classes.padding}>
            <Grid item xs={6}>
              <Typography className={props.classes.span1} color="primary">
                Tarifa{" "}
                {props.flight.fare.className === "BUSINESS"
                  ? "EJECUTIVA"
                  : props.flight.fare.className}{" "}
              </Typography>
            </Grid>
            <Grid item xs={6} style={{ display: "flex", alignItems: "center" }}>
              <Typography className={props.classes.p2}>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href={"https://avior.com.ve/tarifas"}
                  style={{ textDecoration: "none" }}
                >
                  Ver condiciones {">>"}
                </a>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={12} xs={12} className={props.classes.padding}>
          <Grid container>
            <Grid item xs={6}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography
                    display="inline"
                    className={props.classes.span1}
                    color="primary"
                  >
                    {props.flight.segments[0].departureInfo.cityName}{" "}
                  </Typography>
                  <Typography display="inline" className={props.classes.span2}>
                    ({props.flight.segments[0].departureInfo.locationCode})
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography className={props.classes.p3}>
                    {props.flight.segments[0].departureInfo.airPortName}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    className={props.classes.p3}
                    style={{ marginTop: "10px" }}
                  >
                    {getFullDateName(
                      props.flight.segments[0].departureInfo.dateTime
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography className={props.classes.p3}>
                    Salida:{" "}
                    {getTime(props.flight.segments[0].departureInfo.dateTime)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography
                    display="inline"
                    className={props.classes.span1}
                    color="primary"
                  >
                    {
                      props.flight.segments[props.flight.segments.length - 1]
                        .arrivalInfo.cityName
                    }{" "}
                  </Typography>
                  <Typography display="inline" className={props.classes.span2}>
                    (
                    {
                      props.flight.segments[props.flight.segments.length - 1]
                        .arrivalInfo.locationCode
                    }
                    )
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography className={props.classes.p3}>
                    {
                      props.flight.segments[props.flight.segments.length - 1]
                        .arrivalInfo.airPortName
                    }
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    className={props.classes.p3}
                    style={{ marginTop: "10px" }}
                  >
                    {getFullDateName(
                      props.flight.segments[props.flight.segments.length - 1]
                        .arrivalInfo.dateTime
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography className={props.classes.p3}>
                    Llegada:{" "}
                    {getTime(
                      props.flight.segments[props.flight.segments.length - 1]
                        .arrivalInfo.dateTime
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
