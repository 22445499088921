/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import {
  CssBaseline,
  TextField,
  Typography,
  Container,
} from "@material-ui/core/";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { ButtonSpinner, Toast } from "../components/";
import { filterAmount } from "../helpers/filterValues";
import { anciliariesServices } from "../services"
const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    width: 100,
    height: 100,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function OpenPaymentLink() {
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [pnr, setPnr] = useState("");
  const [description, setDescription] = useState("");
  const [amount, setAmount] = useState("");
  const [loading, setLoading] = useState(false);
  const [toast, setToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  let history = useHistory();
  const login = async () => {
    if(amount > 3000){
      return;
    }
    setLoading(true);
    const {status, resp } = await anciliariesServices.createOrder({
      email,
      description:`${description} PNR:${pnr}`,
      amount,
      pnr,
      products:[]
    })
    setLoading(false);
    //console.log(resp)
    if (status === 201) {
      setToast(true);
      setToastMessage(resp.message);
      setEmail("");
      setPnr("");
      setDescription("");
      setAmount("")
    } else if(status===401){
      history.push("/loginadminavior")
    } else{
      setToast(true);
      setToastMessage(resp.error.message);
    }
  };
  return (
    <Container component="main" maxWidth="xs">
      <Toast
        message={toastMessage}
        open={toast}
        close={() => {
          setToast(false);
        }}
      />
      <CssBaseline />
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          Generar link de pago
        </Typography>
        <form className={classes.form}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value.toLowerCase())}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="pnr"
            label="Localizador"
            value={pnr}
            onChange={(e) => setPnr(e.target.value.toUpperCase())}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="Descripción"
            name="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="Monto"
            name="amount"
            error={amount>3000}
            helperText={amount>3000?"Monto no debe ser mayor a 3000":""}
            value={amount}
            onChange={(e) => setAmount(filterAmount(e.target.value))}
          />
          <ButtonSpinner
            fullWidth
            action={login}
            loading={loading}
            text="Enviar"
          />
        </form>
      </div>
    </Container>
  );
}
