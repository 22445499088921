export default function termIcon(termName){
    if(termName==="Equipaje de Mano"){
        return `./img/equipaje de mano.png`
    }else if(termName==="Equipaje"){
        return `./img/equipaje.png`
    }else if(termName==="Cambios"){
        return `./img/Cambios.png`
    }else if(termName==="Reembolsos"){
        return `./img/reembolso.png`
    }else if(termName==="Descuento de niños"){
        return `./img/descuento por niños.png`
    }else if(termName==="Millas"){
        return `./img/millas.png`
    }else if(termName==="Selección de Asientos"){
        return `./img/seleccion de asientos.png`
    }
}