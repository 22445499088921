import React,{useState} from "react";
import { Grid, TextField, Typography, } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {FormValidator} from '../../helpers/'
import {ButtonSpinner, Toast, Spinner} from "../../components/";
import { paymentServices } from "../../services"
import { useHistory } from "react-router-dom";
import {FormatCurrency} from "../../helpers"

const useStyles = makeStyles(theme=>({
    inputGrid:{
        marginBottom:"15px",
    },
    Grid:{
        display:'flex',
        justifyContent: 'center'
    },
    floatingLabelFocusStyle: {
        color: "#CD171A",
        fontSize: "15pt",
        width: "max-content",
    },
    inputLabel:{
        fontSize:"14pt",
        paddingBottom: 0,
        color: "#555555!important",
        height: '20px'
    },
    underline:{
        '&::before':{
            display: "none"
        },
        '&::after':{
            display: "none"
        }
    },
    inputDiv:{
        borderWidth: "1pt",
        borderStyle: "solid",
        borderColor: "#cccccc",
        padding: "10px",
        borderRadius: "5px",
        "& .MuiFormControl-root": {
            width: "100%",
        },
    },
    expandIcon:{
        color:"#FA7268"
    },
    [theme.breakpoints.down("md")]: {
        
        floatingLabelFocusStyle:{
          fontSize:"0.9rem"
        },
        inputLabel:{
          fontSize:"0.8rem"
        }
        
      }
}));

export default function PaymentPromoZelle(props){
    const classes = useStyles();
    const [loading, setLoading]=useState(false)
    const [toast, setToast]=useState(false)
    const [toastMessage, setToastMessage]=useState('')

    const [email, setEmail]=useState('')
    const [fullName, setFullName]=useState('')
    const [ref, setRef]=useState('')
    const [firstNameError, setFirstNameError]=useState('')
    const [emailError, setEmailError]=useState('')
    const [refError, setRefError]=useState('')
    const [phone, setPhone] =useState("")
    const [phoneError, setPhoneError]=useState('')
    let history = useHistory();
    const validator = new FormValidator([
        {
          field: "fullName",
          method: "isEmpty",
          validWhen: false,
          message: "Debe ingresar su nombre y apellido"
        },
        {
            field: "email",
            method: "isEmpty",
            validWhen: false,
            message: "Debe ingresar email"
        },
        { 
            field: 'email', 
            method: 'isEmail', 
            validWhen: true, 
            message: 'Ingrese un correo electrónico valido' 
        },
        {
            field: "ref",
            method: "isEmpty",
            validWhen: false,
            message: "Ingrese la referencia bancaria"
        },
        {
            field: "phone",
            method: "isEmpty",
            validWhen: false,
            message: "Ingrese el número de teléfono "
        }
      ]);
      const validateForms=async ()=>{
        const validation = validator.validate({
            fullName,
            email,
            ref,
            phone
        });
        setFirstNameError(validation.fullName.message)
        setEmailError(validation.email.message)
        setRefError(validation.ref.message)
        setPhoneError(validation.phone.message)
        if(validation.isValid)
        {
            const body={
                passenger: props.passenger,
                payment:{
                    email: email,
                    description: "pago cupon air",
                    name: fullName,
                    referenceNumber:ref,
                    phone:phone,
                    amount: "100.00"
                }
            }
            setLoading(true);
            const peticion = await paymentServices.payPromoZelle(body)
            setLoading(false);
            const resp = await peticion.resp;
            const status= await peticion.status;
            if(status===200){
                history.push(`/thankyoupagepromo`, {status:"pending"})
            }
            else if(status===400){
                setToast(true)
                setToastMessage(resp.error.message)
            }
        }
    }
    return (
        <div>
            <Grid container justify="center"  direction="row" alignItems="center" spacing={1}>
                <Grid item md={12} xs={12} className={classes.Grid}>
                    <Grid item md={5} xs={12}  className={classes.inputGrid} >
                        <Typography>
                            <strong>Cuenta Bancaria.</strong>
                        </Typography>
                        <Typography>
                            <strong>Titular:</strong> Avior Airlines CA LLC
                        </Typography>
                        <Typography>
                            <strong>Correo:</strong> zelle@avior.com.ve
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item md={12} xs={12} className={classes.Grid}>
                    <Grid item md={5} xs={12}  className={classes.inputGrid} >
                        <Typography>
                            <strong>Detalles de la transferencia.</strong>
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item md={12} xs={12} className={classes.Grid}>
                    <Grid item md={5} xs={12}  className={classes.inputGrid} >
                        <div className={classes.inputDiv}>
                            <TextField value={email} label="Email de quien envia" name="email"  id="email"
                                fullWidth
                                helperText={emailError} error={emailError!==""}
                                InputLabelProps={{ shrink: true, className: classes.floatingLabelFocusStyle, htmlFor:"email" }}
                                InputProps={{ classes: { input: classes.inputLabel, underline: classes.underline,} }}
                                onChange={e => setEmail(e.target.value)}
                            />
                        </div>
                    </Grid>
                </Grid>
                <Grid item md={12} xs={12}  className={classes.Grid}>
                    <Grid item md={5} xs={12} className={classes.inputGrid}>
                        <div className={classes.inputDiv}>
                            <TextField value={fullName} label="Nombre y apellido de quien envia" name="firstName" id="firstName"
                                fullWidth
                                helperText={firstNameError} error={firstNameError!==""}
                                InputLabelProps={{ shrink: true, className: classes.floatingLabelFocusStyle, htmlFor:"firstName" }}
                                InputProps={{ classes: { input: classes.inputLabel, underline: classes.underline,} }}
                                onChange={(e) => setFullName(e.target.value)}
                            />
                        </div>
                    </Grid>
                </Grid>
                <Grid item md={12} xs={12}  className={classes.Grid}>
                    <Grid item md={5} xs={12}  className={classes.inputGrid}>
                        <div className={classes.inputDiv}>
                            <TextField value={ref} label="Numero de confirmacion" name="ref"
                                fullWidth helperText={refError}
                                error={refError!==""}
                                InputLabelProps={{ shrink: true, className: classes.floatingLabelFocusStyle, htmlFor:"ref" }}
                                InputProps={{ classes: { input: classes.inputLabel, underline: classes.underline,} }}
                                onChange={(e) => setRef(e.target.value)}
                            />
                        </div>
                    </Grid>
                </Grid>
                <Grid item md={12} xs={12}  className={classes.Grid}>
                <Grid item md={5} xs={12} className={classes.inputGrid}>
                    <div className={classes.inputDiv}>
                        <TextField value={phone} label="Número de teléfono " name="phone" id="phone"
                            fullWidth     
                            helperText={phoneError} error={phoneError!==""}
                            InputLabelProps={{ shrink: true, className: classes.floatingLabelFocusStyle, htmlFor:"phone" }}
                            InputProps={{ classes: { input: classes.inputLabel, underline: classes.underline,} }}
                            onChange={e=>setPhone(e.target.value)}
                        />
                    </div>
                </Grid>
            </Grid>
            <Grid item md={12} xs={12} className={classes.Grid}>
          <Typography variant="h5">Total a Pagar USD ${FormatCurrency("100.00")}</Typography>
        </Grid>
                <Grid item md={12} xs={12}  className={classes.Grid}>
                    <Grid item md={5} xs={12}  className={classes.inputGrid}>
                        <Grid container justify="space-between">
                            <div style={{width:"158px", height:"41px"}}>
                                <ButtonSpinner action={()=> {props.goBack()}} fullWidth text="Atras"
                                />
                            </div>
                            <div style={{width:"158px", height:"41px"}}>
                                <ButtonSpinner action={validateForms} fullWidth text="Continuar"
                                />
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Toast
                message={toastMessage}
                open={toast}
                close={() => { setToast(false) }}
            />
            <Spinner loading={loading}/>                
        </div>
    )
}