import React, { useState, useEffect } from "react";
import { 
    Grid, 
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    IconButton,
    Modal,
    TextField,
    Button,
    Switch,
    FormControlLabel
} from "@material-ui/core";
import EditIcon from '@material-ui/icons/Edit';
import { fareConditionsServices } from "../services"
import { Spinner, Toast } from "../components"
import { makeStyles } from "@material-ui/core/styles";
//import { useHistory } from "react-router-dom";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import SaveAltIcon from '@material-ui/icons/SaveAlt'
const useStyles = makeStyles((theme) =>({
    paper: {
        position: 'absolute',
        width: 400,
        height:500,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #FFF',
        boxShadow: theme.shadows[5],
        padding:"20px",
        borderRadius:"20px",
        outline: 'none'
      },
      modal: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
}));

const columns = [
  {
      id: "name",
      label: "Tarifa",
      minWidth: 50,
  },
]


const ModalFareFamily = (props) =>{
  const [modalStyle] = useState(getModalStyle);
  const classes = useStyles();
  function getModalStyle() {
      const top = 50;
      const left = 50;
      
      return {
          top: `${top}%`,
          left: `${left}%`,
          transform: `translate(-${top}%, -${left}%)`,
          overflowY:"scroll"
      };
  }
  
  const Condition = (props) => {
    const [isUpdating, setIsUpdating] = useState(false)
    const [available, setAvailable] = useState(false)
    const [aditionalInfo, setAditionalInfo] = useState("")

    const setData = ()=>{
      setAvailable(props.available)
      setAditionalInfo(props.aditionalInfo)
    }
    useEffect(() => {
      setAvailable(props.available)
      setAditionalInfo(props.aditionalInfo)
    },[props.available, props.aditionalInfo])

    const senData = ()=>{
      props.sendData({
        ...props,
        available,
        aditionalInfo
      })
      setIsUpdating(false)
    }
    return (
      <Grid item xs={12}>
        {isUpdating?
        <Grid container justify="flex-end">
          <SaveAltIcon  color="primary" onClick={senData}/>
          <HighlightOffIcon color="primary" onClick={()=>{setIsUpdating(false); setData()}}/>
        </Grid>
        :
        <Grid container justify="flex-end">
          <EditIcon color="primary" onClick={()=>setIsUpdating(true)}/>
        </Grid>}
        <Typography>Nombre: {props.name}</Typography>
        <Typography>Descripcion: {props.description}</Typography>
        {isUpdating?
          <FormControlLabel
            checked={available}
            control={<Switch color="primary" onChange={(e)=>setAvailable(e.target.checked)}/>}
            label="Disponible"
            labelPlacement="start"
          />:
          <Typography>Disponible: {available?"Si":"No"}</Typography>
        }
        
        {isUpdating?
          <Grid container style={{marginTop:"5px"}}>
            <TextField
                label="Detalle"
                variant="outlined"
                value={aditionalInfo}
                fullWidth
                onChange={(e)=>setAditionalInfo(e.target.value)}
            />
          </Grid>:
          <Typography>Detalle: {aditionalInfo}</Typography>
        }
      </Grid>
    )
  }

  const sendData = (data, index) =>{
    props.family.fareConditions[index]=data
  }

  const saveConditions = ()=>{
    props.saveConditions(props.family._id, props.family)
    props.close()
  }
  return (
      <Modal className={classes.modal} open={props.open} onClose={props.close} disableBackdropClick={true}>
          <Grid container style={modalStyle} className={classes.paper} spacing={2}>
              <Grid item xs={12}>
                  <Grid container justify="flex-end">
                      <HighlightOffIcon color="primary" onClick={props.close}/>
                  </Grid>
              </Grid>
              <Grid item xs={12}>
                <Typography>Tarifa: {props.family.name}</Typography>
                <Typography>Condiciones: </Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid container justify="center" spacing={2}>
                  {props.family.fareConditions?.map((fareCondition,index)=>(
                    <Condition {...fareCondition} sendData={(data)=>sendData(data, index)} key={index}/>
                  ))}
                </Grid>
              </Grid>
              <Grid item xs={12}>
                  <Grid container justify="space-between">
                      <Button color="primary" variant="contained" onClick={props.close}>Cancelar</Button>
                      <Button color="primary" variant="contained" onClick={saveConditions}>Guardar</Button>
                  </Grid>
              </Grid>
          </Grid>
      </Modal>
  )
}

export default function FareFamiliesConditionsConfig(){
  const [conditions, setConditions] = useState([])
  //let history = useHistory();
  const [loading, setLoading] = useState(false)
  const [openToast, setOpenToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [family, setFamily] = useState({})
  const [openModal, setOpenModal] = useState(false)
  const [isInternational, setIsInternational] = useState(false)
  const getFareFamilies = async (isInternational) => {
    setLoading(true)
    const {status, response} = await fareConditionsServices.getFareFamiliesConditions(isInternational);
    setLoading(false)
    if (status === 200) {
      setConditions(response);
    }
  };
  const saveConditions= async (id, body) =>{
    setLoading(true)
    await fareConditionsServices.updateConditions({id, body});
    setLoading(false)
    setOpenToast(true)
    setToastMessage("Condiciones actualizadas")
    getFareFamilies(isInternational);
  }
  useEffect(() => {
    getFareFamilies(isInternational);
  }, [isInternational]);

  return (
    <Grid container justify="center" direction="row" spacing={2}>
      <Grid item xs={12}>
        <FormControlLabel
            checked={!isInternational}
            control={<Switch color="primary" onChange={(e)=>setIsInternational(!e.target.checked)}/>}
            label="Condiciones vuelos nacionales"
            labelPlacement="start"
          />
        </Grid>
      <Grid item xs={12}>
        <FormControlLabel
            checked={isInternational}
            control={<Switch color="primary" onChange={(e)=>setIsInternational(e.target.checked)}/>}
            label="Condiciones vuelos internacionales"
            labelPlacement="start"
          />
      </Grid>
      <Grid item xs={12}>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                  ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {conditions.map((condition,index)=>(
                <TableRow key={index}>
                  <TableCell component="th" scope="row">
                    <Typography color="primary">{condition.name}</Typography>
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <IconButton  onClick={()=>{
                      setFamily(condition)
                      setOpenModal(true)
                    }}>
                      <EditIcon/>
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <ModalFareFamily saveConditions={saveConditions} family={family} open={openModal}  close={()=>{ 
        setOpenModal(false) 
        setFamily({})
      }}/>
      <Spinner loading={loading}/>
      <Toast open={openToast} close={()=>setOpenToast(false)} message={toastMessage}/>
    </Grid>
  )
}