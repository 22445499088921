export default function promotionRouters(departure, arrival){
    return true;
    //if(departure==="CCS"&&arrival==="SDQ"){
    //    return true
    //}else if(departure==="SDQ"&&arrival==="CCS"){
    //    return true
    //}else if(departure==="CCS"&&arrival==="CUN"){
    //    return true
    //}else if(departure==="CUN"&&arrival==="CCS"){
    //    return true
    //}else if(departure==="BLA"&&arrival==="SDQ"){
    //    return true
    //}else if(departure==="BLA"&&arrival==="CUN"){
    //    return true
    //}else if(departure==="CUN"&&arrival==="BLA"){
    //    return true
    //}else if(departure==="SQD"&&arrival==="BLA"){
    //    return true
    //}
    //else {
    //    return false
    //}
}