import React, { useState } from "react";
import {
  Grid,
  GridList,
  GridListTile,
  Typography,
  Button,
  Radio,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  faresColors,
  findTaxAmount,
  FormatCurrency,
  getFareClassName,
} from "../helpers";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    overflow: "hidden",
  },
  gridList: {
    flexWrap: "nowrap",
    transform: "translateZ(0)",
  },
  gridListTile: {
    height: "none",
    width: "120px",
    marginRight: "2px",
  },
  fare: {
    color: "rgb(133, 133, 141)",
    //borderRadius:"5px",
    cursor: "pointer",
  },
  fareContainer: {},
  fareFamilyName: {
    fontWeight: "bold",
    fontSize: "1.1rem",
    textAlign: "center",
    fontStyle: "oblique",
  },
}));
export default function Fares(props) {
  const classes = useStyles();
  const fares = [];
  const LIGHT = props.fares.find((fare) => fare.className === "LIGHT");
  const FLEX = props.fares.find((fare) => fare.className === "FLEX");
  const MEDIUM = props.fares.find((fare) => fare.className === "MEDIUM");
  const ULTRA = props.fares.find((fare) => fare.className === "ULTRA");
  const BUSINESS = props.fares.find((fare) => fare.className === "BUSINESS");
  if (LIGHT) {
    fares.push(LIGHT);
  } else {
    fares.push({
      cabinName: "ECONOMY",
      classCode: "M",
      className: "LIGHT",
      posting: 0,
    });
  }
  if (FLEX) {
    fares.push(FLEX);
  } else {
    fares.push({
      cabinName: "ECONOMY",
      classCode: "E",
      className: "FLEX",
      posting: 0,
    });
  }
  if (MEDIUM) {
    fares.push(MEDIUM);
  } else {
    fares.push({
      cabinName: "ECONOMY",
      classCode: "M",
      className: "MEDIUM",
      posting: 0,
    });
  }
  if (ULTRA) {
    fares.push(ULTRA);
  } else {
    fares.push({
      cabinName: "ECONOMY",
      classCode: "M",
      className: "ULTRA",
      posting: 0,
    });
  }
  if (BUSINESS) {
    fares.push(BUSINESS);
  } else {
    fares.push({
      cabinName: "BUSINESS",
      classCode: "M",
      className: "BUSINESS",
      posting: 0,
    });
  }
  const [seledtedIndex, setSeledtedIndex] = useState(null);
  const [selectRadio, setSelectRadio] = useState(null);
  return (
    <Grid container justify="center" direction="row" className={classes.grid}>
      <Grid item xs={12}>
        <div className={classes.root}>
          <GridList className={classes.gridList}>
            {fares.map((fare, index) => {
              return (
                <GridListTile
                  key={index}
                  style={{
                    height: null,
                    width: "115px",
                    paddingLeft: 0,
                    paddingRight: 0,
                    marginLeft: fare.className === "BUSINESS" ? "5px" : "0",
                  }}
                >
                  <div style={{ height: "20px", color: "#777777" }}>
                    <Typography
                      variant="h6"
                      style={{ fontSize: "0.8em", fontStyle: "oblique" }}
                      align="center"
                    >
                      {`${getFareClassName(fare.className)} ${
                        fare.className === "FLEX" ? `(Recomendada)` : ""
                      }`}
                    </Typography>
                  </div>

                  <div
                    style={{
                      width: "100%",
                      height: "4px",
                      background: faresColors(fare.className),
                      border: `1pt solid ${
                        faresColors(fare.className) === "#FFF"
                          ? "#575756"
                          : null
                      }`,
                      marginBottom: "5px",
                    }}
                  ></div>

                  <Grid container justify="center" direction="row">
                    <Grid
                      item
                      xs={12}
                      style={{ paddingLeft: "2px", paddingRight: "2px" }}
                    >
                      <Button
                        disabled={!props.selectable}
                        className={classes.fare}
                        style={{
                          width: "100%",
                          height: "55px",
                          borderRadius: "0",
                          color:
                            faresColors(fare.className) === "#FFF"
                              ? "#575756"
                              : seledtedIndex === index && props.isSelected
                              ? "#fff"
                              : "rgb(133, 133, 141)",
                          background: fare.posting
                            ? seledtedIndex === index && props.isSelected
                              ? faresColors(fare.className)
                              : "#FAFAFA"
                            : "#6d6e71",
                          border: `${
                            fare.className === "FLEX" ? "3pt" : "1pt"
                          } solid ${
                            faresColors(fare.className) === "#FFF"
                              ? "#575756"
                              : faresColors(fare.className)
                          }`,
                        }}
                        onClick={() => {
                          if (fare.posting) {
                            //props.selectFare(fare);
                            setSelectRadio(index);
                            props.selectFare(fare);
                            setSeledtedIndex(index);
                          }
                        }}
                      >
                        <Grid container justify="center" direction="row">
                          <Grid item xs={12}></Grid>
                          <Grid item xs={12}>
                            {fare.posting ? (
                              <>
                                <Typography
                                  variant="h6"
                                  align="center"
                                  style={{ fontSize: "1em" }}
                                >
                                  {`${props.currency}`}{" "}
                                  {FormatCurrency(
                                    `${parseFloat(
                                      fare.totalAmount -
                                        findTaxAmount(fare.taxes, "6P")
                                    ).toFixed(2)}`
                                  )}
                                </Typography>
                                <Radio
                                  onClick={() => {
                                    setSelectRadio(index);
                                    props.selectFare(fare);
                                    //setSeledtedIndex(index);
                                  }}
                                  checked={
                                    selectRadio === index && props.isSelected
                                  }
                                  size="small"
                                  style={{
                                    padding: "4px",
                                    color:
                                      faresColors(fare.className) === "#FFF"
                                        ? "#575756"
                                        : selectRadio === index &&
                                          props.isSelected
                                        ? "#fff"
                                        : "rgba(0, 0, 0, 0.54)",
                                  }}
                                />
                              </>
                            ) : (
                              <Typography
                                variant="h6"
                                align="center"
                                style={{ fontSize: "1em" }}
                              >
                                Agotado
                              </Typography>
                            )}
                          </Grid>
                        </Grid>
                      </Button>
                    </Grid>
                  </Grid>
                </GridListTile>
              );
            })}
          </GridList>
        </div>
      </Grid>
    </Grid>
  );
}
