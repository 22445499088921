import React, { useState } from "react";
import { Title, PaymentMethods, Toast, ButtonSpinner } from "../components";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { FormatCurrency } from "../helpers";
const useStyles = makeStyles((theme) => ({
  /*Screens styles*/
  container: {
    width: "100%",
    border: "1pt solid #cccccc",
  },
  inputDiv: {
    borderWidth: "2px",
    borderStyle: "solid",
    borderColor: "#FA7268",
    padding: "10px",
    borderRadius: "5px",
    "& .MuiFormControl-root": {
      width: "100%",
    },
  },
  floatingLabelFocusStyle: {
    color: "#af0061",
    fontSize: "2rem",
    width: "100%",
  },
  inputLabel: {
    fontSize: "14pt",
    paddingBottom: 0,
    color: "#555555!important",
    paddingTop: "11px",
  },
  underline: {
    "&::before": {
      display: "none",
    },
    "&::after": {
      display: "none",
    },
  },
  reserveButtonLabel: {
    color: "#891c56",
    fontSize: "14pt",
    lineHeight: "1.5",
    fontWeight: "bold",
  },
  payButtonLabel: {
    color: "#ffffff",
    fontSize: "14pt",
    lineHeight: "1.5",
    fontWeight: "bold",
  },
  aceptButtonLabel: {
    color: "#ffffff",
    fontSize: "14pt",
    fontWeight: "bold",
  },
  p: {
    fontWeight: "bold",
    color: "#ffffff",
    fontSize: "20pt",
  },
  /*Components Shared styles*/
  p1: {
    fontWeight: "bold",
    fontSize: "15pt",
    color: "#848484",
  },
  span1: {
    fontWeight: "bold",
    fontSize: "1.21rem",
    color: "#af0061",
  },
  p2: {
    fontSize: "12pt",
    color: "#848484",
  },
  span2: {
    color: "#848484",
  },
  p3: {
    fontSize: "1.15rem",
    lineHeight: "1.5",
    color: "#848484",
  },
  padding: {
    padding: "10px",
  },
  box: {
    borderBottom: "solid 1px #cccccc",
    backgroundColor: "#EAEAEA",
  },
  banner: {
    display: "none",
  },
  fondoBanner: {
    width: "100%",
  },
  [theme.breakpoints.down("sm")]: {
    floatingLabelFocusStyle: {
      color: "#af0061",
      fontSize: "1.4rem",
      width: "100%",
    },
    inputLabel: {
      fontSize: "1rem",
      paddingBottom: 0,
      color: "#555555!important",
      paddingTop: "11px",
    },
    container: {
      width: "100%",
    },
    fondoBanner: {
      display: "none",
    },
    banner: {
      display: "block",
      width: "100%",
    },
    p: {
      fontSize: "0.9rem",
    },
    payButtonLabel: {
      fontSize: "0.9rem",
    },
    reserveButtonLabel: {
      fontSize: "0.9rem",
    },
    aceptButtonLabel: {
      fontSize: "0.9rem",
    },
    p1: {
      fontSize: "0.9rem",
    },
    p3: {
      fontSize: "0.8rem",
    },
    span1: {
      fontSize: "1rem",
    },
    arrow: {
      fontSize: "0.9rem",
    },
  },
}));

export default function PromotionPaymentMethodSelect(props) {
  const classes = useStyles();
  const [methodSelect, setMethodSelect] = useState("");
  const [toast, setToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const onSubmitPay = () => {
    if (!methodSelect) {
      setToast(true);
      setToastMessage("Debe seleccionar un metodo de pago");
    } else {
        props.sendData(methodSelect);
    }
  };
  return (
    <div>
      <Title
        h2={"RESUMEN DE "}
        strong={"COMPRA"}
        p={"A continuación, encontrarás el detalle de tu selección."}
      />
      <Toast
        message={toastMessage}
        open={toast}
        close={() => {
          setToast(false);
        }}
      />
      <Grid
        container
        style={{ border: "1pt solid #cccccc", borderBottom: "unset" }}
      >
        <PaymentMethods
          selectMethod={(method) => setMethodSelect(method)}
          classes={classes}
        />
      </Grid>
      <Grid
        container
        style={{
          padding: "15px",
          backgroundColor: "#cd171a",
          marginBottom: "20px",
        }}
      >
        <Grid
          item
          md={8}
          xs={8}
          style={{ display: "flex", alignItems: "center" }}
        >
          <p className={classes.p} style={{ margin: 0 }}>
            VALOR TOTAL DE LA PROMOCION: USD{" "}
            {FormatCurrency(`${parseFloat(100).toFixed(2)}`)}
          </p>
        </Grid>
      </Grid>
      <Grid container justify={props.pnr ? "flex-end" : "space-between"}>
          <div style={{ width: "158px", height: "41px" }}>
            <ButtonSpinner
              action={() => {
                props.goBack();
              }}
              fullWidth
              text="Atras"
              style={{ height: "100%" }}
              labelClassname={classes.aceptButtonLabel}
            />
          </div>
          <div style={{ width: "158px", height: "41px" }}>
                <ButtonSpinner
                  action={onSubmitPay}
                  id="reservarButton"
                  fullWidth
                  text="PAGAR"
                  labelClassname={classes.aceptButtonLabel}
                />
              </div>
      </Grid>
    </div>
  );
}
