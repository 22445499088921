import React, { useState } from "react";
import { Spinner, Toast, SearchBar } from "../components";
import { Container, Grid, Typography, Button, TextField } from "@material-ui/core";
import { boardingPassService } from "../services"
export default function BoardingPass(){
    const [segments, setSegments] = useState([])
    const [pnr, setPnr] = useState("")
    const [email, setEmail] = useState("")
    const [loading, setLoading] = useState("")
    const [toast, setToast] = useState(false);
    const [toastMessage, setToastMessage] = useState("");
    const getSegments = async (pnr)=>{
        setLoading(true)
        const { response, status} = await boardingPassService.getSegments(pnr)
        setLoading(false)
        if(status===200){
            setSegments(response.segments)
            setPnr(response.pnr)
        }else{
            setSegments([])
            setPnr("")
            setToastMessage(response.error.message);
            setToast(true);
        }

    }
    const resendEmail = async (segment)=>{
        setLoading(true)
        const { response, status} = await boardingPassService.resendEmail({pnr, email, segment})
        setLoading(false)
        if(status===200){
            setToastMessage(response.message);
            setToast(true);
        }else{
            setToastMessage(response.error.message);
            setToast(true);
        }
    }
    return (
        <Container component="main">
            <Grid container justify="center">
                <SearchBar label="PNR" search={getSegments} clear={()=>{}}/>
            </Grid>
            {pnr!==""&&<Grid container justify="center" spacing={2}>
                <Grid item xs={12} style={{marginTop:"20px"}}>
                    <Grid container justify="center">
                        <Grid item xs={5}>
                            <TextField label="Email" variant="outlined" fullWidth value={email} onChange={(e)=>setEmail(e.target.value)} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Typography>PNR: {pnr}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography>Segmentos chequeados</Typography>
                </Grid>
                {segments.map((segment, index)=>(
                   <Grid item xs={12} key={index}>
                       <Grid container justify="center">
                            <Grid item xs={6}>
                                <Typography><strong>{segment.departureInfo.locationCode}/{segment.arrivalInfo.locationCode}</strong></Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container justify="flex-end">
                                    <Button variant="contained" color="primary" onClick={()=>resendEmail(segment)}>Enviar</Button>
                                </Grid>
                            </Grid>
                       </Grid>
                       <Grid container justify="center" spacing={2}>
                            {segment.passengers.map((passenger, index)=>(
                                <Grid item xs={12} key={index}>
                                    <Typography>{passenger.name} {passenger.lastName} asiento: {passenger.seat.seatNumber}</Typography>
                                </Grid>
                            ))}

                       </Grid>
                    </Grid> 
                ))}
                
            </Grid>}
            <Spinner loading={loading}/>
            <Toast
                message={toastMessage}
                open={toast}
                close={() => {
                setToast(false);
                }}
            />
        </Container>
    )
}