import React, { useState } from "react";
//import { useLocation, useHistory } from "react-router-dom";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {  Title, ButtonSpinner, Toast, Spinner  } from "../components";
import {FormatCurrency} from "../helpers"
import { ref } from "../helpers/links"
import { reservationServices } from "../services";



export default function EarlyBooking(props){
    //let location = useLocation();
    //let history = useHistory();
    /*if (!location.state) {
        history.push(`/`);
    }*/
    const [loading, setLoading] = useState(false);
   // const [termns, setTermns]=useState(false)
   // const [adult, setAdult]=useState(false)
    const classes = useStyles();
    const [toast, setToast] = useState(false);
    const [toastMessage, setToastMessage] = useState("");
    const totalAmount = 
    parseFloat(props.originFlight ? props.originFlight.fare.totalAmount : 0);
    parseFloat(props.returnFlight ? props.returnFlight.fare.totalAmount : 0);
    async function reservation(){
        let passengers=props.passengers;
        for (let passenger of passengers) 
            delete passenger.id;
        let segments = props.segments;
        for (let segment of segments) 
            segment.passengers = passengers;
        setLoading(true);
        const peticion = await reservationServices.promotion(segments)
        setLoading(false);
        const resp = await peticion.resp;
        if (peticion.status === 200) {
            //props.sendData({method:methodSelect, pnr:resp.pnr});
            setToast(true)
            setToastMessage("Reserva promocion creada")
        } 
        else {
            setToast(true);
            setToastMessage(resp.error.message)
        }
    }
    return(
        <div>
            <Title
                h2={"RESERVA DE VUELO"}
                strong={"ANTICIPADA"}
                p={"TU SILLA Y DESTINO, ESPERA POR TI."}
                highlightedP
            />
            <Grid container justify="flex-start" direction="column" >
                <Grid item xs={12} md={12}>
                    <p className={classes.p1}>Toma la desición de viajar, reserva tus tiquetes ahora y paga <span className={classes.span1}>3 DÍAS</span> antes del vuelo.</p>
                    <p className={classes.p}>
                        Sabemos que quieres viajar y GCA está de tu lado para acercarte a los tuyos o para llevarte al destino que quieres, por ese motivo 
                        hemos habilitado el beneficio de que reserves ahora mismo tus sillas a cualquiera de nuestros destinos y en las fechas establecidas 
                        sin ningún costo para que puedas pagar los tiquetes <span className={classes.span2}>3 días antes del vuelo</span>. Si decides no viajar, GCA Airlines no te cobrará ningún 
                        cargo o penalidad.
                    </p>
                </Grid>
            </Grid>
            <Grid container justify="space-between">
                <Grid item md={4} xs={12}  className={classes.containerBoxLeft}>
                    <Grid container className={classes.containerBox}>
                        <Grid item md={12} xs={12} className={classes.boxTitle1} >
                            <p style={{textAlign:"center", margin:0}}>
                                FLEXIBILIDAD NECESARIA PARA <br/>
                                <span className={classes.boxTitle2}>PLANIFICAR TU VIAJE CON TIEMPO</span>
                            </p>
                        </Grid>
                        <Grid item md={12} xs={12} className={classes.parent} style={{height:"90px"}}>
                            <img src="./img/flexibilidad.png" alt="" />
                        </Grid>
                        <Grid item md={12} xs={12} className={classes.boxText} >
                            <p >
                                Tu reserva se mantendrá durante el tiempo hasta que realices tu viaje.
                            </p>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={4} xs={12} className={classes.containerBoxCenter} >
                    <Grid container className={classes.containerBox}>
                        <Grid item md={12} xs={12} className={classes.boxTitle1} >
                            <p  style={{textAlign:"center", margin:0}}>
                                <span className={classes.boxTitle2}>PAGA 72 HORAS ANTES</span> <br/>
                                DEL VUELO
                            </p>
                        </Grid>
                        <Grid item md={12} xs={12} className={classes.parent} style={{height:"90px"}}>
                            <img src="./img/72horas.png" alt="" />
                        </Grid>
                        <Grid item md={12} xs={12} className={classes.boxText} >
                            <p >
                                Selecciona la tarifa y el destino de tu preferencia sin comprometerte con nosotros hasta no estar seguro de viajar.
                            </p>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={4} xs={12}  className={classes.containerBoxRight}>
                    <Grid container className={classes.containerBox}>
                        <Grid item md={12} xs={12} className={classes.boxTitle1} >
                            <p  style={{textAlign:"center", margin:0}}>
                                CANCELACIÓN DE TU RESERVA <br/>
                                <span className={classes.boxTitle2}>SIN PENALIDADES</span>
                            </p>
                        </Grid>
                        <Grid item md={12} xs={12} className={classes.parent} style={{height:"90px"}}>
                            <img src="./img/cancelacion.png" alt="" />
                        </Grid>
                        <Grid item md={12} xs={12} className={classes.boxText}>
                            <p >
                                Si no puedes viajar, puedes cancelar en cualquier momento y no habrá ningún cobro ni penalidad.
                            </p>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container justify="space-between">
                <Grid item md={4} xs={12}  className={classes.containerBoxLeft}>
                    <Grid container className={classes.containerBox}>
                        <Grid item md={12} xs={12} className={classes.boxTitle1} >
                            <p  style={{textAlign:"center", margin:0}}>
                                El MEJOR PRECIO <br/>
                                <span className={classes.boxTitle2}>A TU CONVENIENCIA</span>
                            </p>
                        </Grid>
                        <Grid item md={12} xs={12} className={classes.parent} style={{height:"90px"}}>
                            <img src="./img/mejor-precio.png" alt="" />
                        </Grid>
                        <Grid item md={12} xs={12} className={classes.boxText}>
                            <p >
                                Protegemos tu reserva hasta que hagas el pago, asegurándote el mejor precio que has encontrado.
                            </p>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={4} xs={12} className={classes.containerBoxCenter} >
                    <Grid container className={classes.containerBox}>
                        <Grid item md={12} xs={12} className={classes.boxTitle1}>
                            <p style={{textAlign:"center", margin:0}}>
                                <span className={classes.boxTitle2}>MUY ATENTOS DE TI</span>
                            </p>
                        </Grid>
                        <Grid item md={12} xs={12} className={classes.parent} style={{height:"90px"}}>
                            <img src="./img/atentos.png" alt="" />
                        </Grid>
                        <Grid item md={12} xs={12} className={classes.boxText}>
                            <p >
                                Te enviaremos un recordatorio por correo electrónico veinticuatro (24) horas antes del vencimiento de la reserva.
                            </p>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={4} xs={12}  className={classes.containerBoxRight}>
                    <Grid container className={classes.lastContainerBox}>
                        <Grid item md={12} xs={12} className={classes.parent} style={{height:"100%"}}>
                            <p className={classes.lastBoxText} style={{textAlign:"center"}}>
                                ¡ANÍMATE<br/> Y NO PIERDAS ESTA GRAN <br/>
                                <span className={classes.lastBoxSpan}>OPORTUNIDAD!</span>
                            </p>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container justify="flex-start" direction="column" style={{marginBottom:"20px"}}>
                <Grid item xs={12} md={12}>
                    <p className={classes.p}>
                        Queremos que te programes con tiempo suficiente y encuentres el precio ajustado a tus necesidades, 
                        te garantizamos que la tarifa seleccionada no será modificada hasta que decidas completar la compra durante este periodo.
                        <br/><br/>
                        Para más información, consulta nuestros <a rel='noopener noreferrer' target="_blank" href={ref.termsAndConditions} style={{color:"#555555"}}>
                                <strong className={classes.strong}>{"Términos y condiciones >>"}</strong>
                            </a>
                    </p>
                    <p className={classes.p1} >Reserva ya sin ningún compromiso para que llegues al destino que quieres pronto llegar:</p>
                </Grid>
            </Grid>
            {/*<TermsAndConditions 
                isAdult={(resp)=>setAdult(resp)}
                acceptTermns={(resp)=>setTermns(resp)}
            />*/}
            <Toast
                message={toastMessage}
                open={toast}
                close={() => {
                setToast(false);
                }}
            />
            <Grid container justify="center" direction="row" style={{ backgroundColor:"#af0061", marginBottom:"20px"}} >
                <Grid item md={10} xs={12}>
                    <Grid container justify="center" direction="column" className={classes.spanContainer}>
                        <Grid item md={12} xs={12} >
                            <span className={classes.span3}>
                                VALOR TOTAL SIN PENALIDADES (0COP X PASAJERO)
                            </span>
                        </Grid>
                        <Grid item md={12} xs={12} >
                            <span className={classes.span4}>
                                TOTAL A PAGAR <span className={classes.span6}>
                                    CUANDO CONFIRMES</span> COP ${FormatCurrency(parseFloat(totalAmount).toFixed(2))}
                            </span>
                        </Grid>
                        <Grid item md={12} xs={12} >
                            <span className={classes.span5}>
                                Precio total, incluye tarifa aérea, todas las tasas, impuestos (salvo los que cobren en determinados aeropuertos en el 
                                momento de facturar) y el costo de combustutible
                            </span>
                        </Grid>
                    </Grid>
                </Grid> 
                <Grid item md={2} xs={12} className={classes.buttonContainer}>
                    <div style={{width:"158px", height:"41px"}}>
                        <ButtonSpinner action={reservation} fullWidth text="RESERVAR" style={{backgroundColor:"#fa7268", height:"100%"}} labelClassname={classes.aceptButtonLabel} />
                    </div>
                </Grid>
            </Grid>
            <Spinner loading={loading}/>
            <Grid container justify="flex-start">
                <div style={{ width: "158px", height: "41px" }}>
                <ButtonSpinner
                    action={()=>{props.goBack()}}
                    fullWidth
                    text="Atras"
                    style={{ backgroundColor: "#cd0063", height: "100%" }}
                    labelClassname={classes.aceptButtonLabel}
                />
                </div>
            </Grid>
        </div>
    )
}

const useStyles = makeStyles( theme => ({
    p:{
        fontFamily:"Roboto-Regular, sans-serif",
        fontSize:"16pt", 
        color: "#848484",
        lineHeight:"1.5"
    },
    p1:{
        fontFamily:"Roboto-Bold, sans-serif", 
        fontSize:"25pt",
        color: "#848484",
        margin:0
    },
    span1:{
        fontFamily:"Roboto-Bold, sans-serif", 
        fontSize:"30pt",
        color: "#af0061",
    },
    span2:{
        fontFamily:"Roboto-Bold, sans-serif", 
        color: "#af0061"
    },
    span3:{
        fontFamily:"Roboto-Regular, sans-serif", 
        fontSize:"16pt", color:"#ffffff", 
        lineHeight:"1.5"
    },
    span4:{
        fontFamily:"Roboto-Regular, sans-serif", 
        fontSize:"26pt", 
        color:"#ffffff", 
        lineHeight:"1.5"
    },
    span5:{
        fontFamily:"Roboto-Regular, sans-serif", 
        fontSize:"10pt", color:"#ffffff", 
        lineHeight:"1.5"
    },
    span6:{
        fontFamily:"Roboto-Regular , sans-serif", 
        fontSize:"26pt", 
        color:"#fa7268", 
        lineHeight:"1.5"
    },
    spanContainer:{
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
        padding: "20px",
    },
    
    buttonContainer:{
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        padding: "20px"
    },
    strong:{
        textDecoration:"underline",
    },
    containerBox:{
        borderStyle:"solid",
        borderWidth:"1pt",
        borderColor:"#cccccc",
        width:"95%",
        padding:"15px",
        marginBottom:"15px"
    },
    lastContainerBox:{
        backgroundColor:"#af0061",
        width: "95%",
        padding:"10px",
        marginBottom:"15px"
    },
    parent:{
        display:"flex",
        justifyContent:"center",
        alignItems: "center",
    },
    containerBoxLeft:{
        display:"flex",
        justifyContent:"flex-start"
    },
    containerBoxCenter:{
        display:"flex",
        justifyContent:"center"
    },
    containerBoxRight:{
        display:"flex",
        justifyContent:"flex-end"
    },
    boxTitle1:{
        display: "flex",
        justifyContent:"center",
        alignItems:"flex-start",
        height:"100px",
        '& p':{
        fontFamily:"Roboto-Bold, sans-serif", 
        fontSize:"16pt",
        lineHeight:"1.5"
        }
    },
    boxTitle2:{
        fontFamily:"Roboto-Regular, sans-serif", 
        fontSize:"18pt",
        color: "#af0061",
        lineHeight:"1.5"
    },
    lastBoxText:{
        fontFamily:"Roboto-Bold, sans-serif",
        fontSize:"23pt",
        color:"#ffffff"
    },
    lastBoxSpan:{
        color:"#fa7268"
    },
    boxText:{
        display:"flex",
        justifyContent:"center", 
        alignItems:"center",
        height:"100px",
        '& p':{
            fontFamily:"Roboto-Regular, sans-serif", 
            fontSize:"14pt",
            color: "#848484",
            textAlign:"justify", 
            marginBottom:0, 
            height:"100%",
            lineHeight: "1.5",
        }
    },
    aceptButtonLabel:{
        fontFamily:"Roboto-Bold, sans-serif",
        color:"#ffffff",
        fontSize:"14pt",
        fontWeight:"bold"
    },
    [theme.breakpoints.down('sm')]:{ 
        containerBox:{
            width:"100%"
        },
        lastContainerBox:{
            width:"100%"
        },
        p:{
            fontSize:"0.9rem"
        },
        p1:{
            fontSize:"1rem"
        },
        span1:{
            fontSize:"1.2rem"
        },
        span3:{
            fontSize:"1rem"
        },
        span4:{
            fontSize:"1.2rem"
        },
        span6:{
            fontSize:"1.2rem"
        },
        boxText:{
            height:"unset",
            '& p':{
                fontSize:"1rem",
            }
        },
        boxTitle1:{
            height:"unset",
            '& p':{
            fontSize:"0.9rem",
            }
        },
        boxTitle2:{
            fontSize:"1.1rem",
        },
        aceptButtonLabel:{
            fontSize:"0.9rem"
        }
    }

    })

)