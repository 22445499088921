export default function dateValid(date){
    const dateSplit = date.split('/')
    if (dateSplit.length < 2) {
        return false
    }
    const dateNow = new Date()
    const month = dateNow.getMonth() + 1
    const year = parseInt(dateNow.getFullYear().toString().substr(2,2))

    let monthCard = parseInt(dateSplit[0])
    let yearCard = parseInt(dateSplit[1])
    if (monthCard > 12) {
        return false
    }
    if (yearCard < year) {
        return false
    }
    if (year === yearCard && monthCard < month) {
        return false
    }
    return true
}

function dateValidYYMM(date){
    const dateSplit = date.split('/')
    if (dateSplit.length < 2) {
        return false
    }
    const dateNow = new Date()
    const month = dateNow.getMonth() + 1
    const year = parseInt(dateNow.getFullYear().toString().substr(2,2))

    let monthCard = parseInt(dateSplit[1])
    let yearCard = parseInt(dateSplit[0])
    if (monthCard > 12) {
        return false
    }
    if (yearCard < year) {
        return false
    }
    if (year === yearCard && monthCard < month) {
        return false
    }
    return true
}


export {
    dateValidYYMM
}