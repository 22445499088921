import React, { useState } from "react";
//import { StepsPaymentPromotion } from "../components";
import { makeStyles } from "@material-ui/core/styles";
import { Container } from "@material-ui/core";
import {
  CustomerData
} from ".";
import { invitationServices } from "../services"
import { Toast, Spinner} from "../components"
export default function CCSFCPromotionStep() {
  const [step] = useState(1);
  const [toast, setToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const useStyles = makeStyles((theme) => ({
    paper: {
      width: "100%",
    },
    container: {
      paddingTop: theme.spacing(12),
      paddingBottom: theme.spacing(4),
    },
    bottom: {
      paddingBottom: theme.spacing(4),
    },
    [theme.breakpoints.down("md")]: {
      top: {
        paddingBottom: theme.spacing(4),
      },
    },
    [theme.breakpoints.up("md")]: {
      top: {
        paddingBottom: theme.spacing(4),
      },
    },
  }));
  const classes = useStyles();
  const [passenger, setPassenger] = useState(null);
  const [canRedirect, setCanRedirect] = useState(false);
  const generateInvitationCode = async (data) => {
    setLoading(true)
    const { resp, status } = await invitationServices.generateInvitationCodeCCSFC(data)
    setLoading(false)
    setToast(true);
    if(status===400){
      setToastMessage(resp.error.message)
    }else{
      setToastMessage("Código promocional generado con exito verifique su correo electrónico");
      setCanRedirect(true)
    }
    
  };
  function getStepContent(step) {
    switch (step) {
      case 1:
        return (
          <CustomerData
            passenger={passenger}
            sendData={(data) => {
              //console.log(data)
              generateInvitationCode(data)
              setPassenger(data);
              window.scrollTo(0, 0);
              //setStep(2);
            }}
          />
        );
      default:
        throw new Error("Unknown step");
    }
  }

  //function getActiveStep(step) {
  //  return step;
  //}
  return (
    <div className={classes.paper}>
      {/*<div className={classes.top}>
        <StepsPaymentPromotion active={getActiveStep(step)} />
    </div>*/}
      <Toast
        message={toastMessage}
        open={toast}
        close={() => {
          setToast(false);
          if(canRedirect){
            window.location.replace('https://avior.com.ve/');
          }
        }}
      />
      <Spinner loading={loading} />
      <Container maxWidth="lg" className={classes.bottom}>
        <div style={{ minHeight: "55vh" }}>{getStepContent(step)}</div>
      </Container>
    </div>
  );
}
