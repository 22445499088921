import React, { useState, useEffect } from "react";
import { Box, Grid, MenuItem, TextField, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { FormValidator, FormatCurrency } from "../helpers/";
import { ButtonSpinner, Toast, Spinner, ModalLukaPay } from "../components/";
import {
  paymentServices,
  dolarPriceServices,
  //btpmServices,
  //installmentsServices,
} from "../services";
import { ExpandMore } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import banksBTPM from "../helpers/banks-btpm";

const phoneCodes = [
  { value: "0414", name: "0414" },
  { value: "0424", name: "0424" },
  { value: "0416", name: "0416" },
  { value: "0426", name: "0426" },
  { value: "0412", name: "0412" },
];

const docTypes = [
  { value: "V", name: "VENEZOLANO" },
  { value: "J", name: "JURIDICO" },
  { value: "G", name: "GUBERNAMENTAL" },
  { value: "E", name: "EXTRANGERO" },
];

const useStyles = makeStyles((theme) => ({
  inputGrid: {
    marginBottom: "15px",
  },
  Grid: {
    display: "flex",
    justifyContent: "center",
  },
  floatingLabelFocusStyle: {
    color: "#CD171A",
    fontSize: "15pt",
    width: "max-content",
  },
  inputLabel: {
    fontSize: "14pt",
    paddingBottom: 0,
    color: "#555555!important",
    height: "20px",
  },
  underline: {
    "&::before": {
      display: "none",
    },
    "&::after": {
      display: "none",
    },
  },
  inputDiv: {
    borderWidth: "1pt",
    borderStyle: "solid",
    borderColor: "#cccccc",
    padding: "10px",
    borderRadius: "5px",
    "& .MuiFormControl-root": {
      width: "100%",
    },
  },
  inputCodeDiv: {
    borderWidth: "1pt",
    borderStyle: "solid",
    borderColor: "#cccccc",
    padding: "9px",
    borderRadius: "5px",
    "& .MuiFormControl-root": {
      width: "100%",
    },
  },
  expandIcon: {
    color: "#FA7268",
  },
  [theme.breakpoints.down("md")]: {
    floatingLabelFocusStyle: {
      fontSize: "0.9rem",
    },
    inputLabel: {
      fontSize: "0.8rem",
    },
  },
}));

export default function PaymentBTPM(props) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [toast, setToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [bank, setBank] = useState("");
  const [bankError, setBankError] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [phoneCode, setPhoneCode] = useState("");
  const [tokenError, setTokenError] = useState("");
  const [documentError, setDocumentError] = useState("");
  const [toastLuka, setToastLuka] = useState(true);
  const [banks, setBanks] = useState([]);
  let history = useHistory();
  const [totalAmount, setTotalAmount] = useState("0.00");
  const [document, setDocument] = useState("");
  const [docType, setDocType] = useState("V");
  const [token, setToken] = useState("");
  const getAncillariesAmount = () => {
    return parseFloat(
      props.ancillaries.going.reduce(function (a, b) {
        return parseFloat(a) + parseFloat(b.total);
      }, 0) +
        props.ancillaries.goBack.reduce(function (a, b) {
          return parseFloat(a) + parseFloat(b.total);
        }, 0)
    ).toFixed(2);
  };
  const getAmountSeats = () => {
    return parseFloat(
      props.selectedSeats.going.reduce(function (a, b) {
        return parseFloat(a) + parseFloat(b.total);
      }, 0) +
        props.selectedSeats.goBack.reduce(function (a, b) {
          return parseFloat(a) + parseFloat(b.total);
        }, 0)
    ).toFixed(2);
  };

  const getPrice = async () => {
    setLoading(true);
    const { status, response } = await dolarPriceServices.getPrice();
    setLoading(false);
    if (status === 200) {
      const totalAncillaries = getAncillariesAmount();
      const totalSeats = getAmountSeats();
      const faresAmount =
        parseFloat(
          props.originFlight ? props.originFlight.fare.totalAmount : 0
        ) +
        parseFloat(
          props.returnFlight ? props.returnFlight.fare.totalAmount : 0
        );
      setTotalAmount(
        parseFloat(
          parseFloat(
            parseFloat(faresAmount) +
              parseFloat(totalAncillaries) +
              parseFloat(totalSeats)
          ) * parseFloat(response.price)
        ).toFixed(2)
      );
    }
  };

  /* const getInstallments = async ({ pnr }) => {
    const { response, status } =
      await installmentsServices.getInstallmentsPreview({ pnr });

    setLoading(false);

    if (status !== 200) {
      setToast(true);
      setToastMessage(response.error.message);
    }

    return response;
  };

  const getPriceInstallment = async () => {
    setLoading(true);

    const { status, response } = await dolarPriceServices.getPrice();

    if (status === 200) {
      const installmentsRes = await getInstallments({ pnr: props.pnr });

      if (!installmentsRes?.error) {
        setTotalAmount(
          parseFloat(
            parseFloat(installmentsRes[0].amount) * parseFloat(response.price)
          ).toFixed(2)
        );
      }
    }

    setLoading(false);
  };

  const getPriceAmount = async ({ amount }) => {
    setLoading(true);

    const { status, response } = await dolarPriceServices.getPrice();

    if (status === 200) {
      setTotalAmount(
        parseFloat(parseFloat(amount) * parseFloat(response.price)).toFixed(2)
      );
    }

    setLoading(false);
  }; */

  /* const getBanks = async () => {
    setLoading(true);
    const { status, response } = await btpmServices.bankList();
    setLoading(false);
    if (status === 200) {
      setBanks(response);
    }
  }; */

  useEffect(() => {
    //getBanks();
    setBanks(banksBTPM);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* useEffect(() => {
    if (props.installments) {
      if (props.installmentInfo) {
        getPriceAmount({ amount: props.installmentInfo?.amount });
      } else {
        getPriceInstallment();
      }
    } else {
      getPrice();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); */

  useEffect(() => {
    getPrice();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validator = new FormValidator([
    {
      field: "email",
      method: "isEmpty",
      validWhen: false,
      message: "Debe ingresar su email",
    },
    {
      field: "email",
      method: "isEmail",
      validWhen: true,
      message: "Ingrese un correo electrónico valido",
    },
    {
      field: "bank",
      method: "isEmpty",
      validWhen: false,
      message: "Seleccione la entidad bancaria",
    },
    {
      field: "phone",
      method: "isEmpty",
      validWhen: false,
      message: "Ingrese el número de teléfono ",
    },
    {
      field: "phoneCode",
      method: "isEmpty",
      validWhen: false,
      message: "Ingrese el código",
    },
    {
      field: "token",
      method: "isEmpty",
      validWhen: false,
      message: "Ingrese el código de seguridad",
    },
    {
      field: "document",
      method: "isEmpty",
      validWhen: false,
      message: "Ingrese el número de documento",
    },
  ]);
  const validateForms = async () => {
    const validation = validator.validate({
      email,
      bank,
      phone,
      phoneCode,
      document,
      token,
    });
    setEmailError(validation.email.message);
    setBankError(validation.bank.message);
    setTokenError(validation.token.message);
    setDocumentError(validation.document.message);
    setPhoneError(
      validation.phone.message !== ""
        ? validation.phone.message
        : validation.phoneCode.message
    );
    if (validation.isValid) {
      /* let user_id;

      if (props.userData) {
        user_id = props.userData.user_info
          ? props.userData.user_info?.user_id
          : props.userData?.user_id;
      } */

      const request = {
        pnr: props.pnr,
        email,
        //user: user_id ? user_id : null,
        //installment: props.installmentId ? props.installmentId : null,
        paymentInfo: {
          bank,
          phone: `${phoneCode}${phone}`,
          token,
          document: `${docType}${document}`,
        },
      };

      setLoading(true);

      const { response, status } = await paymentServices.paymentBTPM(request);

      /* const responseData = props.installments
        ? await paymentServices.paymentInstallmentsBTPM(request)
        : await paymentServices.paymentBTPM(request); 
      const { response, status } = responseData;
      */

      setLoading(false);

      if (status === 200) {
        /* if (response.status === "approved") {
          history.push("/thankyoupage", {
            state: {
              ...response,
              reservationDetails: response.reservationdetails,
              originFlight: props.originFlight,
              returnFlight: props.returnFlight,
            },
          });
        } else {
          history.push(`/thankyoupagependding`, {
            ...response,
            reservationDetails: response.reservationdetails,
            originFlight: props.originFlight,
            returnFlight: props.returnFlight,
          });
        } */
        redirectThank({ response, status: response.status });
      } else if (status === 400) {
        setToast(true);
        setToastMessage(response.error.message);
      }
    }
  };

  const redirectThank = ({ response, status }) => {
    let urlThank;

    if (status === "approved") {
      urlThank = "/thankyoupage";
    } else {
      urlThank = "/thankyoupagependding";
    }

    const params = {
      ...response,
      passengers: response.passengers?.length
        ? response.passengers
        : response?.reservationdetails.passengers,
      reservationDetails: response?.reservationdetails,
      installments: props.installments ? true : false,
      originFlight: props.originFlight,
      returnFlight: props.returnFlight,
    };

    history.push(urlThank, {
      ...params,
    });
  };

  return (
    <div>
      <Box px={2}>
        <Grid
          container
          justify="center"
          direction="row"
          alignItems="center"
          spacing={1}
        >
          <Grid item md={12} xs={12} className={classes.Grid}>
            <Grid item md={2} xs={4} className={classes.inputGrid}>
              <img src="./img/btpm.png" alt="" style={{ width: "100%" }} />
            </Grid>
          </Grid>
          <Grid item md={12} xs={12} className={classes.Grid}>
            <Typography variant="h5">
              Total a Pagar VES Bs{" "}
              {FormatCurrency(`${parseFloat(totalAmount).toFixed(2)}`)}
            </Typography>
          </Grid>
          <Grid item md={12} xs={12} className={classes.Grid}>
            <Grid item md={5} xs={12} className={classes.inputGrid}>
              <Typography>
                <strong>Detalles del pago móvil.</strong>
              </Typography>
            </Grid>
          </Grid>
          <Grid item md={12} xs={12} className={classes.Grid}>
            <Grid item md={5} xs={12} className={classes.inputGrid}>
              <div className={classes.inputDiv}>
                <TextField
                  value={email}
                  label="Email"
                  name="email"
                  id="email"
                  fullWidth
                  helperText={emailError}
                  error={emailError !== ""}
                  InputLabelProps={{
                    shrink: true,
                    className: classes.floatingLabelFocusStyle,
                    htmlFor: "email",
                  }}
                  InputProps={{
                    classes: {
                      input: classes.inputLabel,
                      underline: classes.underline,
                    },
                  }}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </Grid>
          </Grid>
          <Grid item md={12} xs={12} className={classes.Grid}>
            <Grid item md={2} xs={5} className={classes.inputGrid}>
              <div className={classes.inputCodeDiv}>
                <TextField
                  value={phoneCode}
                  label="Cod"
                  name="code"
                  id="code"
                  fullWidth
                  select
                  InputLabelProps={{
                    shrink: true,
                    className: classes.floatingLabelFocusStyle,
                    htmlFor: "phone",
                  }}
                  InputProps={{
                    classes: {
                      input: classes.inputLabel,
                      underline: classes.underline,
                    },
                  }}
                  SelectProps={{
                    IconComponent: () => (
                      <ExpandMore className={classes.expandIcon} />
                    ),
                  }}
                  onChange={(e) => setPhoneCode(e.target.value)}
                >
                  {phoneCodes.map((code, index) => (
                    <MenuItem key={index} value={code.value}>
                      {code.name}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
            </Grid>
            <Grid item md={3} xs={7} className={classes.inputGrid}>
              <div className={classes.inputDiv}>
                <TextField
                  value={phone}
                  label="Número de teléfono "
                  name="phone"
                  id="phone"
                  fullWidth
                  helperText={phoneError}
                  error={phoneError !== ""}
                  InputLabelProps={{
                    shrink: true,
                    className: classes.floatingLabelFocusStyle,
                    htmlFor: "phone",
                  }}
                  InputProps={{
                    classes: {
                      input: classes.inputLabel,
                      underline: classes.underline,
                    },
                  }}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </div>
            </Grid>
          </Grid>
          <Grid item md={12} xs={12} className={classes.Grid}>
            <Grid item md={2} xs={5} className={classes.inputGrid}>
              <div className={classes.inputCodeDiv}>
                <TextField
                  value={docType}
                  label="Tipo doc."
                  name="docType"
                  id="docType"
                  fullWidth
                  select
                  InputLabelProps={{
                    shrink: true,
                    className: classes.floatingLabelFocusStyle,
                    htmlFor: "docType",
                  }}
                  InputProps={{
                    classes: {
                      input: classes.inputLabel,
                      underline: classes.underline,
                    },
                  }}
                  SelectProps={{
                    IconComponent: () => (
                      <ExpandMore className={classes.expandIcon} />
                    ),
                  }}
                  onChange={(e) => setDocType(e.target.value)}
                >
                  {docTypes.map((doc, index) => (
                    <MenuItem key={index} value={doc.value}>
                      {doc.name}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
            </Grid>
            <Grid item md={3} xs={7} className={classes.inputGrid}>
              <div className={classes.inputDiv}>
                <TextField
                  value={document}
                  label="Número de documento"
                  name="document"
                  id="document"
                  fullWidth
                  helperText={documentError}
                  error={documentError !== ""}
                  InputLabelProps={{
                    shrink: true,
                    className: classes.floatingLabelFocusStyle,
                    htmlFor: "document",
                  }}
                  InputProps={{
                    classes: {
                      input: classes.inputLabel,
                      underline: classes.underline,
                    },
                  }}
                  onChange={(e) => setDocument(e.target.value)}
                />
              </div>
            </Grid>
          </Grid>
          <Grid item md={12} xs={12} className={classes.Grid}>
            <Grid item md={5} xs={12} className={classes.inputGrid}>
              <div className={classes.inputDiv}>
                <TextField
                  value={bank}
                  label="Entidad bancaria"
                  name="bank"
                  fullWidth
                  helperText={bankError}
                  select
                  error={bankError !== ""}
                  InputLabelProps={{
                    shrink: true,
                    className: classes.floatingLabelFocusStyle,
                    htmlFor: "bank",
                  }}
                  InputProps={{
                    classes: {
                      input: classes.inputLabel,
                      underline: classes.underline,
                    },
                  }}
                  onChange={(e) => setBank(e.target.value)}
                  SelectProps={{
                    IconComponent: () => (
                      <ExpandMore className={classes.expandIcon} />
                    ),
                  }}
                >
                  {banks.map((bank, index) => (
                    <MenuItem key={index} value={bank.codigo}>
                      {bank.nombre}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
            </Grid>
          </Grid>
          <Grid item md={12} xs={12} className={classes.Grid}>
            <Grid item md={5} xs={12} className={classes.inputGrid}>
              <div className={classes.inputDiv}>
                <TextField
                  value={token}
                  label="Clave de Operaciones Especiales (C2P)"
                  name="token"
                  id="token"
                  fullWidth
                  helperText={tokenError}
                  error={tokenError !== ""}
                  InputLabelProps={{
                    shrink: true,
                    className: classes.floatingLabelFocusStyle,
                    htmlFor: "token",
                  }}
                  InputProps={{
                    classes: {
                      input: classes.inputLabel,
                      underline: classes.underline,
                    },
                  }}
                  onChange={(e) => setToken(e.target.value)}
                />
              </div>
            </Grid>
          </Grid>
          <Grid item md={12} xs={12} className={classes.Grid}>
            <Grid item md={5} xs={12} className={classes.inputGrid}>
              <Grid container justify="space-between">
                <div style={{ width: "158px", height: "41px" }}>
                  <ButtonSpinner
                    action={() => {
                      props.goBack();
                    }}
                    fullWidth
                    text="Atras"
                  />
                </div>
                <div style={{ width: "158px", height: "41px" }}>
                  <ButtonSpinner
                    action={validateForms}
                    fullWidth
                    text="Continuar"
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <ModalLukaPay open={toastLuka} close={() => setToastLuka(false)} />
      <Toast
        message={toastMessage}
        open={toast}
        close={() => {
          setToast(false);
        }}
      />
      <Spinner loading={loading} />
    </div>
  );
}
