function getCardType(number) {
  // visa
  var re = new RegExp("^4");
  if (number.match(re) != null) return "visa";

  // Mastercard
  // Updated for Mastercard 2017 BINs expansion
  if (
    /^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/.test(
      number
    )
  )
    return "mastercard";

  // AMEX
  re = new RegExp("^3[47]");
  if (number.match(re) != null) return "amex";

  // Discover
  re = new RegExp(
    "^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)"
  );
  if (number.match(re) != null) return "discover";

  // Diners
  re = new RegExp("^36");
  if (number.match(re) != null) return "dinersclub";
  return "";
}

function clearNumber(value = "") {
  return value.replace(/\D+/g, "");
}

export function formatCreditCardNumber(value) {
  if (!value) {
    return value;
  }

  const issuer = getCardType(value);
  const clearValue = clearNumber(value);
  let nextValue;

  switch (issuer) {
    case "amex":
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        10
      )} ${clearValue.slice(10, 15)}`;
      break;
    case "dinersclub":
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        10
      )} ${clearValue.slice(10, 14)}`;
      break;
    default:
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        8
      )} ${clearValue.slice(8, 12)} ${clearValue.slice(12, 19)}`;
      break;
  }

  return nextValue.trim();
}

export function formatCVC(value, number) {
  const clearValue = clearNumber(value);
  let maxLength = 4;

  if (number) {
    const issuer = getCardType(number);
    maxLength = issuer === "amex" ? 4 : 3;
  }

  return clearValue.slice(0, maxLength);
}

export function formatExpirationDate(value) {
  const clearValue = clearNumber(value);

  if (clearValue.length >= 3) {
    return `${clearValue.slice(0, 2)}/${clearValue.slice(2, 4)}`;
  }

  return clearValue;
}


export function filterFullName(name) {
    name = name.replace(/[^a-zA-ZÑñ ]/g, "")

    if (name.split(' ').length > 1) {
        const nameSplit = name.split(' ')
        const lastName = `${nameSplit[1].charAt(0).toUpperCase()}${nameSplit[1].slice(1)}`
        return `${nameSplit[0]} ${lastName}`
    } else {
        return `${name.charAt(0).toUpperCase()}${name.slice(1)}`
    }

}