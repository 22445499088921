import React from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  AppBar,
  Toolbar,
  Container,
  Typography
} from "@material-ui/core/";
import CssBaseline from "@material-ui/core/CssBaseline";
import clsx from "clsx";
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useLocation } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  banner: {
    minHeight: "120vh",
    backgroundImage: `url(./img/AVIORGO_landing.jpg)`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  appBar: {
    background: "#fff",
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  infoSection: {
    background: "#fff",
    paddingTop: "40px",
    paddingBottom: "20px",
  },
  termSection: {
    background: "#f7f7f7",
    paddingTop: "40px",
  },
  button: {
    border: "1pt solid #CCCCCC",
    fontSize: "1.5em",
    fontWeight: "bold",
    position: "absolute",
    top: "-25%",
    padding: "5px 20px",
    borderRadius: "10px",
  },
  ul: {
    color: "#515151",

    "& li::marker": {
      color: "#cd171a",
      fontWeight: "bold",
      display: "inline-block",
      width: "2em",
      marginLeft: "-1em",
      fontSize: "1.3em",
    },
  },
  footer: {
    background: "#cd171a",
    paddingTop: "10px",
    paddingBottom: "10px",
  },
  [theme.breakpoints.down("md")]: {
    h4:{
      fontSize:"1.5rem"
    },
    font:{
      fontSize:"1rem"
    }
  }
}));
export default function ThankYouPageCuponAir() {
  const classes = useStyles();
  const location = useLocation();
  const params = location.state;
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  function AppBarCustom(props) {
    return (
      <AppBar position="absolute" className={clsx(classes.appBar)}>
        <Container maxWidth="lg">
          <Toolbar>
            <img src="./img/logoWhite.png" alt="" style={{ width: "200px" }} />
          </Toolbar>
        </Container>
      </AppBar>
    );
  }
  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBarCustom />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
         {!matches?<Grid container justify="center">
           <img src="./img/cupon-air-web-thank.png" alt="avior go" style={{width:"100%"}}/> 
           </Grid>:<Grid container justify="center">
           <img src="./img/cupon-air-mobile-thank.png" alt="avior go" style={{width:"100%"}}/> 
           </Grid>}
           <Grid
          container
          maxWidth="lg"
          justify="center"
          className={classes.termSection}
        >
          <Grid item xs={12}>
            <Grid container justify="center">
              <Grid item md={9}>
                <Typography align="center" variant="h4" color="primary"  className={classes.h4}>
                  <strong>Gracias por adquirir tu cupón</strong>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid container style={{ marginTop: 30, marginBottom: 30, backgroundColor: "#fff", }} justify="center">
            <Grid
              item
              xs={8}
              style={{
                padding: "10px",
                display: "flex",
                position: "relative",
              }}
            >
              <Typography style={{ color: "#6f6f6f", fontSize: "1.5em" }}>
                * Recibirás un correo electrónico con tu código y el paso a paso para utilizar tu pase.
              </Typography>
            </Grid>
            {params.status==="pending"&&<Grid
              item
              xs={8}
              style={{
                padding: "10px",
                display: "flex",
                position: "relative",
              }}
            >
              <Typography style={{ color: "#6f6f6f", fontSize: "1.5em" }}>
              Tu pago se encuentra en proceso. Espere a que su pago sea confirmado, te enviaremos un correo electronico.
              </Typography>
            </Grid>}
          </Grid>
        </Grid>
        <Grid
          container
          maxWidth="lg"
          justify="center"
          className={classes.footer}
        >
          <Grid item xs={10}>
            <Typography variant="h6" align="center" style={{ color: "#fff" }} className={classes.font}>
              Más información, te invitamos a consultar la política de equipaje
              en aviorair.com o en el aplicación móvil Avior Air Si tienes
              problemas para realizar tu compra contáctenos llamando al Call
              center en 0501 AVIOR 00
            </Typography>
          </Grid>
        </Grid>
      </main>
    </div>
  );
}