import React from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  AppBar,
  Toolbar,
  Container,
  Typography,
  Button,
} from "@material-ui/core/";
import CssBaseline from "@material-ui/core/CssBaseline";
import clsx from "clsx";
import { useHistory } from "react-router-dom";
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Footer } from "../components"
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  banner: {
    minHeight: "120vh",
    backgroundImage: `url(./img/AVIORGO_landing.jpg)`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  appBar: {
    background: "#fff",
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  infoSection: {
    background: "#fff",
    paddingTop: "40px",
    paddingBottom: "20px",
  },
  termSection: {
    background: "#f7f7f7",
    paddingTop: "40px",
  },
  button: {
    border: "1pt solid #CCCCCC",
    fontSize: "1.5em",
    fontWeight: "bold",
    position: "absolute",
    top: "-25%",
    padding: "5px 20px",
    borderRadius: "10px",
  },
  ul: {
    color: "#515151",

    "& li::marker": {
      color: "#cd171a",
      fontWeight: "bold",
      display: "inline-block",
      width: "2em",
      marginLeft: "-1em",
      fontSize: "1.3em",
    },
  },
  footer: {
    background: "#cd171a",
    paddingTop: "10px",
    paddingBottom: "10px",
  },
  [theme.breakpoints.down("md")]: {
    h4:{
      fontSize:"1.5rem"
    },
    font:{
      fontSize:"1rem"
    }
  }
}));
export default function LandingFVG() {
  const classes = useStyles();
  let history = useHistory();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  function AppBarCustom(props) {
    return (
      <AppBar position="absolute" className={clsx(classes.appBar)}>
        <Container maxWidth="lg">
          <Toolbar>
            <img src="./img/logoWhite.png" alt="" style={{ width: "200px" }} />
          </Toolbar>
        </Container>
      </AppBar>
    );
  }
  function List(props) {
    return (
      <ul className={classes.ul}>
        <li>
          <Typography style={{ color: "#6f6f6f" }}>
          Válido para todas las rutas. Nacionales: Barcelona, Barquisimeto, Caracas, Las Piedras, Maracaibo, Porlamar, Puerto Ayacucho, Puerto Ordaz, Bogota, Curazao, Santo Domingo (República Dominicana) como destino internacional.
          </Typography>
        </li>
        <li>
          <Typography style={{ color: "#6f6f6f" }}>
          Exoneración de un (1) equipo de golf al viajar para vuelos nacionales.
          </Typography>
        </li>
        <li>
          <Typography style={{ color: "#6f6f6f" }}>
          50% de descuento para el traslado de (1) equipo de golf para vuelos internacionales.
          </Typography>
        </li>
        <li>
          <Typography style={{ color: "#6f6f6f" }}>
          Aplica en cualquiera de nuestras tarifas.
          </Typography>
        </li>
        <li>
          <Typography style={{ color: "#6f6f6f" }}>
          Para volar hasta el 31 de diciembre del 2024.
          </Typography>
        </li>
        <li>
          <Typography style={{ color: "#6f6f6f" }}>
          Aplica 20% de descuento sobre la tarifa base, no incluye impuestos o tasas de salida.
          </Typography>
        </li>
        <li>
          <Typography style={{ color: "#6f6f6f" }}>
          Vålido para canjear o comprar ünicamente por la página web www.aviorair.com
          </Typography>
        </li>
        <li>
          <Typography style={{ color: "#6f6f6f" }}>
          Para obtener el código de descuento debes aceptar y completar el formulario. Tienes hasta el 15 de noviembre de 2024.
          </Typography>
        </li>
        <li>
          <Typography style={{ color: "#6f6f6f" }}>
          Beneficios únicamente para afiliados activos de la FVG
          </Typography>
        </li>
      </ul>
    );
  }
  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBarCustom />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
         {!matches?<Grid container justify="center">
           <img src="./img/fvg.jpg" alt="avior go" style={{width:"80%"}}/> 
           </Grid>:<Grid container justify="center">
           <img src="./img/fvg.jpg" alt="avior go" style={{width:"100%"}}/> 
           </Grid>}
        <Grid
          container
          maxWidth="lg"
          justify="center"
          className={classes.termSection}
        >
          <Grid item xs={12}>
            <Grid container justify="center">
              <Grid item md={9}>
                <Typography align="center" variant="h4" color="primary"  className={classes.h4}>
                  <strong>TÉRMINOS Y CONDICIONES</strong>
                </Typography>
              </Grid>
              <Grid item md={9} xs={11}>
                <List />
              </Grid>
            </Grid>
          </Grid>
          <Grid container style={{ marginTop: 60 }} justify="center">
            <Grid
              item
              xs={12}
              style={{
                backgroundColor: "#fff",
                padding: "50px",
                display: "flex",
                justifyContent: "center",
                position: "relative",
              }}
            >
              <Button
                variant="contained"
                color={"primary"}
                className={classes.button}
                onClick={()=>history.push("/fvgavior")}
              >
                Acepto
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Footer />
      </main>
    </div>
  );
}
