import React from "react";
import { Switch, BrowserRouter, Route } from "react-router-dom";
import {
  LayOut,
  IframeBookingForm,
  DashboardAdmin,
  DashboardAdminTransfers,
  AncillariesPassengerList,
  DashboardConfig,
  DashboardGateways,
  DashboardBoardingPass,
} from "./components/";
import DateRangeHeader from "./components/date-range-header";
// Importar componentes en comun como navbars
import {
  PassengersData,
  ResumeReservation,
  PaymentMethodSelect,
  PaymentCash,
  PaymentPse,
  PaymentResume,
  SearchFlightsSteps,
  EarlyBooking,
  PurchaseSummary,
  Availability,
  PaymentCreditCardPaypal,
  Vuelos,
  ThankYouPage,
  PayementLinkGenerator,
  PaymentStepFromPnr,
  Login,
  ThankYouPagePendding,
  Transfers,
  AnciliariesGenerate,
  //PaymentAnciliariesPaypal,
  OpenPaymentLink,
  ThankYouPageOpenLink,
  PaymentsMiscelaneousTable,
  PaymentGBC,
  VerifyGBC,
  CreditCardForm,
  //PaymentAnciliariesCreditCard,
  PaymentAnciliaries,
  LandingAviorGo,
  PaymentPromotionStep,
  TransfersAviorGO,
  ThankYouPageAviorGo,
  ItinerariesConfig,
  AirportsConfig,
  PaymentsGatewaysConfig,
  DolarPriceConfig,
  //NewPaymentAnciliariesCreditCard
  TransfersBss,
  VerifyPaymentBookingBancaAmiga,
  VerifyPaymentAncillariesBancaAmiga,
  FareFamiliesConditionsConfig,
  FitGamesPromotionStep,
  BoardingPass,
  SunsetPromotionStep,
  SendEmailForInvitationCCSFC,
  CCSFCPromotionStep,
  FVGPromotionStep,
  LandingFVG,
  SendEmailForInvitationFVG,
  LandingCuponAir,
  PaymentCuponAirStep,
  LandingBK2,
  SendEmailForInvitationBK2,
  LandingBPlaza,
  SendEmailForInvitationSVCOTMED,
  LandingSVCOTMED,
  SendEmailForInvitationFITGAMES,
  ThankYouPageMiles,
  ThankYouPageInstallments,
  ThankYouPageInstallmentsPending,
  PayInstallments,
} from "./screens/";
import Saldos from "./screens/saldos";
// import las pantallas desde screens

export default function Routes() {
  return (
    <div>
      <BrowserRouter>
        <Switch>
          <Route exact path="/" render={() => <SearchFlightsSteps />} />
          {/*<Route exact path="/" render={()=><LayOut><SearchFlights/></LayOut>}/>*/}
          <Route
            exact
            path="/passengers"
            render={() => (
              <LayOut>
                <PassengersData />
              </LayOut>
            )}
          />
          <Route
            exact
            path="/resumen"
            render={() => (
              <LayOut>
                <ResumeReservation />
              </LayOut>
            )}
          />
          <Route
            exact
            path="/paymentmethod"
            render={() => (
              <LayOut>
                <PaymentMethodSelect />
              </LayOut>
            )}
          />
          <Route
            exact
            path="/paymentcash"
            render={() => (
              <LayOut>
                <PaymentCash />
              </LayOut>
            )}
          />
          <Route
            exact
            path="/paymentpse"
            render={() => (
              <LayOut>
                <PaymentPse />
              </LayOut>
            )}
          />
          <Route
            exact
            path="/resumenpago"
            render={() => (
              <LayOut>
                <PaymentResume />
              </LayOut>
            )}
          />
          <Route
            exact
            path="/reservaanticipada"
            render={() => (
              <LayOut>
                <EarlyBooking />
              </LayOut>
            )}
          />
          <Route
            exact
            path="/resumencompra"
            render={() => (
              <LayOut>
                <PurchaseSummary />
              </LayOut>
            )}
          />
          <Route exact path="/availability" render={() => <Availability />} />
          <Route
            exact
            path="/paymentcreditcard"
            render={() => (
              <LayOut>
                <PaymentCreditCardPaypal />
              </LayOut>
            )}
          />
          <Route
            exact
            path="/vuelos"
            render={() => (
              <LayOut>
                <Vuelos />
              </LayOut>
            )}
          />
          <Route exact path="/booking" render={() => <IframeBookingForm />} />
          <Route exact path="/thankyoupage" render={() => <ThankYouPage />} />
          <Route
            exact
            path="/thankyoupageinstallments"
            render={() => <ThankYouPageInstallments />}
          />
          <Route
            exact
            path="/thankyoupageinstallmentspend"
            render={() => <ThankYouPageInstallmentsPending />}
          />
          <Route
            exact
            path="/thankyoupagemiles"
            render={() => <ThankYouPageMiles />}
          />
          <Route
            exact
            path="/thankyoupagependding"
            render={() => <ThankYouPagePendding />}
          />
          <Route
            exact
            path="/pay-installments"
            render={() => <PayInstallments />}
          />
          <Route
            exact
            path="/linkpago"
            render={() => (
              <LayOut>
                <PayementLinkGenerator />
              </LayOut>
            )}
          />
          <Route
            exact
            path="/openlink"
            render={() => (
              <DashboardAdmin>
                <OpenPaymentLink />
              </DashboardAdmin>
            )}
          />
          <Route
            exact
            path="/pagarbooking"
            render={() => (
              <LayOut>
                <PaymentStepFromPnr />
              </LayOut>
            )}
          />
          <Route
            exact
            path="/rangeheader"
            render={() => (
              <LayOut>
                <DateRangeHeader />
              </LayOut>
            )}
          />
          <Route
            exact
            path="/loginadminavior"
            render={() => (
              <LayOut>
                <Login />
              </LayOut>
            )}
          />
          <Route
            exact
            path="/dHJhbnNmZXJlbmNpYXM=transferencias"
            render={() => (
              <DashboardAdminTransfers>
                <Transfers />
              </DashboardAdminTransfers>
            )}
          />
          <Route
            exact
            path="/saldos"
            render={() => (
              <DashboardAdminTransfers>
                <Saldos />
              </DashboardAdminTransfers>
            )}
          />
          <Route
            exact
            path="/anciliaries"
            render={() => (
              <LayOut>
                <AnciliariesGenerate />
              </LayOut>
            )}
          />
          <Route
            exact
            path="/payment-miscelaneous"
            render={() => (
              <LayOut>
                <PaymentAnciliaries />
              </LayOut>
            )}
          />
          <Route
            exact
            path="/thankyoupageopenlink"
            render={() => <ThankYouPageOpenLink />}
          />
          <Route
            exact
            path="/payments-miscelaneous"
            render={() => (
              <DashboardAdmin>
                <PaymentsMiscelaneousTable />
              </DashboardAdmin>
            )}
          />
          <Route exact path="/gbc" render={() => <PaymentGBC />} />
          <Route
            exact
            path="/verifycreditcard"
            render={() => (
              <LayOut>
                <VerifyGBC />
              </LayOut>
            )}
          />
          <Route
            exact
            path="/creditcard"
            render={() => (
              <LayOut>
                <CreditCardForm />
              </LayOut>
            )}
          />
          <Route
            exact
            path="/AncillariesPassengerList"
            render={() => (
              <LayOut>
                <AncillariesPassengerList />
              </LayOut>
            )}
          />
          <Route
            exact
            path="/avior-go-terminos"
            render={() => <LandingAviorGo />}
          />
          <Route
            exact
            path="/avior-go"
            render={() => (
              <LayOut>
                <PaymentPromotionStep />
              </LayOut>
            )}
          />
          <Route
            exact
            path="/dHJhbnNmZXJlbmNpYXM=transferencias-promo"
            render={() => (
              <DashboardAdminTransfers>
                <TransfersAviorGO />
              </DashboardAdminTransfers>
            )}
          />
          <Route
            exact
            path="/dHJhbnNmZXJlbmNpYXM=transferencias-promo-bss"
            render={() => (
              <DashboardAdminTransfers>
                <TransfersAviorGO isBss={true} />
              </DashboardAdminTransfers>
            )}
          />
          <Route
            exact
            path="/thankyoupagepromo"
            render={() => <ThankYouPageAviorGo />}
          />
          <Route
            exact
            path="/adminitinerario"
            render={() => (
              <DashboardConfig>
                <ItinerariesConfig />
              </DashboardConfig>
            )}
          />
          <Route
            exact
            path="/adminaeropuertos"
            render={() => (
              <DashboardConfig>
                <AirportsConfig />
              </DashboardConfig>
            )}
          />
          <Route
            exact
            path="/paymentsgatewaysconfig"
            render={() => (
              <DashboardGateways>
                <PaymentsGatewaysConfig />
              </DashboardGateways>
            )}
          />
          <Route
            exact
            path="/admindolar"
            render={() => (
              <DashboardAdminTransfers>
                <DolarPriceConfig />
              </DashboardAdminTransfers>
            )}
          />
          <Route
            exact
            path="/dHJhbnNmZXJlbmNpYXM=transferencias-bss"
            render={() => (
              <DashboardAdminTransfers>
                <TransfersBss />
              </DashboardAdminTransfers>
            )}
          />
          <Route
            exact
            path="/verifyorderbancaamiga/:orderRef"
            render={() => (
              <LayOut>
                <VerifyPaymentBookingBancaAmiga />
              </LayOut>
            )}
          />
          <Route
            exact
            path="/verifymiscellaneusorderbancaamiga/:orderRef"
            render={() => (
              <LayOut>
                <VerifyPaymentAncillariesBancaAmiga />
              </LayOut>
            )}
          />
          <Route
            exact
            path="/condiciones-config"
            render={() => (
              <DashboardConfig>
                <FareFamiliesConditionsConfig />
              </DashboardConfig>
            )}
          />
          <Route
            exact
            path="/invitacion-fit-games"
            render={() => <SendEmailForInvitationFITGAMES />}
          />
          <Route
            exact
            path="/fit-games-promo"
            render={() => (
              <LayOut>
                <FitGamesPromotionStep />
              </LayOut>
            )}
          />
          <Route
            exact
            path="/boarding-pass"
            render={() => (
              <DashboardBoardingPass>
                <BoardingPass />
              </DashboardBoardingPass>
            )}
          />
          <Route
            exact
            path="/sunsetavior"
            render={() => (
              <LayOut>
                <SunsetPromotionStep />
              </LayOut>
            )}
          />
          <Route
            exact
            path="/caracasfcavior-invitacion"
            render={() => (
              <LayOut>
                <SendEmailForInvitationCCSFC />
              </LayOut>
            )}
          />
          <Route
            exact
            path="/mail-fvgavior-invitacion"
            render={() => <SendEmailForInvitationFVG />}
          />
          <Route
            exact
            path="/fvgavior-invitacion"
            render={() => <LandingFVG />}
          />
          <Route
            exact
            path="/caracasfcavior"
            render={() => (
              <LayOut>
                <CCSFCPromotionStep />
              </LayOut>
            )}
          />
          <Route
            exact
            path="/fvgavior"
            render={() => (
              <LayOut>
                <FVGPromotionStep />
              </LayOut>
            )}
          />
          <Route
            exact
            path="/cupon-air-promo"
            render={() => <LandingCuponAir />}
          />
          <Route
            exact
            path="/cupon-air"
            render={() => (
              <LayOut>
                <PaymentCuponAirStep />
              </LayOut>
            )}
          />
          <Route
            exact
            path="/mail-bk2-invitacion"
            render={() => <SendEmailForInvitationBK2 />}
          />
          <Route
            exact
            path="/BK2AIR"
            render={() => (
              <LayOut>
                <LandingBK2 />
              </LayOut>
            )}
          />
          <Route
            exact
            path="/BPlaza"
            render={() => (
              <LayOut>
                <LandingBPlaza />
              </LayOut>
            )}
          />
          <Route
            exact
            path="/mail-svcotmed-invitacion"
            render={() => <SendEmailForInvitationSVCOTMED />}
          />
          <Route
            exact
            path="/SVCOTMED"
            render={() => (
              <LayOut>
                <LandingSVCOTMED />
              </LayOut>
            )}
          />
        </Switch>
      </BrowserRouter>
    </div>
  );
}
