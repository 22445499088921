const btBase = "https://tpmovil.bt.gob.ve/RestTesoro_C2P/com/services"

const bankList = async () => {
  const peticion = await fetch(`${btBase}/bancos`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    }
  })
  let response = await peticion.json()
  return {
    status: peticion.status,
    response
  }
}

const btpmServices = Object.freeze({
    bankList
})

export default btpmServices;
