import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  [theme.breakpoints.down("md")]: {
    logo:{
      width:"80%"
    },
    text:{
      color:"#FFF", 
      fontSize:"0.4rem"
    }
  },
  [theme.breakpoints.up("md")]: {
    logo:{
      width:"30%"
    },
    text:{
      color:"#FFF", 
      fontSize:"0.7rem"
    }
  },
}))

export default function Footer(props) {
  const classes=useStyles();
  return (
    <>
      <Grid container justify="space-between" direction="row" style={{paddingTop:"15px", paddingBottom:"15px", backgroundColor:"#CE2827" }}>
        <Grid item xs={4} md={4} style={{display:"flex", justifyContent:"flex-end", alignItems:"center"}}>
          <img src="./img/logo-footer.png" className={classes.logo} alt=""/>
        </Grid>
        <Grid item xs={4} md={4} style={{display:"flex", justifyContent:"flex-start", alignItems:"center"}}>
          <Typography className={classes.text}>{new Date().getFullYear()}. Todos los derechos reservados.</Typography>
        </Grid>
      </Grid>
    </>
  );
}
