import React, { useState, useEffect } from "react";
import { 
    Grid, 
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    IconButton,
    Fab,
    Modal,
    TextField,
    MenuItem,
    ButtonGroup,
    Button
} from "@material-ui/core";
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { destinationServices } from "../services"
import { Spinner, Toast } from "../components"
import { makeStyles } from "@material-ui/core/styles";
import MaskedInput from 'react-text-mask';
import { FormValidator } from "../helpers"
import { useHistory } from "react-router-dom";
const useStyles = makeStyles((theme) =>({
    paper: {
        position: 'absolute',
        width: 400,
        height:500,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #FFF',
        boxShadow: theme.shadows[5],
        padding:"20px",
        borderRadius:"20px",
        outline: 'none'
      },
      modal: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
}));
const columns = [
    {
        id: "number",
        label: "Numero",
        minWidth: 50,
    },
    {
        id: "route",
        label: "Ruta",
        minWidth: 50,
    },
    {
        id: "departure",
        label: "Salida",
        minWidth: 50,
    },
    {
        id: "arrival",
        label: "Llegada",
        minWidth: 50,
    },
    {
        id: "days",
        label: "Frecuencia",
        minWidth: 50,
    },
]
const TimeInput = (props)=>{
    const { inputRef, ...other } = props;
    return (
        <MaskedInput
            {...other}
            mask={[/[0-2]/, /[0-9]/, ':', /[0-5]/, /[0-9]/]}
            placeholder="00:00"
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            showMask
            guide={false}
        />
    )
}
const allDays = ["Lunes", "Martes","Miercoles", "Jueves", "Viernes", "Sabado", "Domingo"]
const Days = (props)=>{
    
    return (
        <div>
            {
                allDays.map(day=>(
                    <Typography key={day} display="inline" color={props.days.includes(day)?"primary":"initial"}><strong>{day.charAt(0)}{" "}</strong></Typography>
                ))
            }
        </div>
    )
}
const DaysButtons = (props)=>{
    return (
        <ButtonGroup color="primary" aria-label="outlined primary button group">
            {allDays.map(day=>(
                <Button onClick={()=>props.changeDays(day)} key={day} variant={props.days.includes(day)?"contained":"outlined"}>{day.charAt(0)}</Button>
            ))}
        </ButtonGroup>
    )
}
const ModalRoutes = (props) =>{
    const [destinations, setDestinations] = useState([])
    const [modalStyle] = useState(getModalStyle);
    const [origin, setOrigin] = useState("")
    const [destination, setDestination] = useState("")
    const [departureTime, setDepartureTime] = useState("")
    const [arrivalTime, setArrivalTime] = useState("")
    const [days, setDays] = useState([])
    const [stops, setStops] = useState("")
    const [duration, setDuration] = useState("")
    const [flightNumber, setFlightNumber] = useState("")
    const [errors, setErrors] = useState({});
    const classes = useStyles();
    const validator = new FormValidator([
        {
            field: "origin",
            method: "isEmpty",
            validWhen: false,
            message: "Debe ingresar el origen"
        },
        {
            field: "destination",
            method: "isEmpty",
            validWhen: false,
            message: "Debe ingresar el destino"
        },
        {
            field: "departureTime",
            method: "isEmpty",
            validWhen: false,
            message: "Debe la hora de salida"
        },
        {
            field: "arrivalTime",
            method: "isEmpty",
            validWhen: false,
            message: "Debe la hora de llegada"
        },
        {
            field: "duration",
            method: "isEmpty",
            validWhen: false,
            message: "Debe la duracion"
        },
        {
            field: "stops",
            method: "isEmpty",
            validWhen: false,
            message: "Debe la cantidad de paradas"
        },
        {
            field: "flightNumber",
            method: "isEmpty",
            validWhen: false,
            message: "Debe el numero de vuelo"
        }
    ])
    function getModalStyle() {
        const top = 50;
        const left = 50;
        
        return {
            top: `${top}%`,
            left: `${left}%`,
            transform: `translate(-${top}%, -${left}%)`,
        };
    }
    const changeDestination = (location)=>{
        setDestinations(props.airPorts.filter(airPort=>airPort.locationCode!==location))
    }
    useEffect(() => {
        if(props.isUpdating){
            setOrigin(props.route.origin)
            setDestinations(props.airPorts.filter(airPort=>airPort.locationCode!==props.route.origin))
            setDestination(props.route.arrival)
            setDepartureTime(props.route.departureTime)
            setArrivalTime(props.route.arrivalTime)
            setDays(props.route.days)
            setStops(props.route.stops)
            setDuration(props.route.duration)
            setFlightNumber(props.route.flightNumber)
        }else{
            setOrigin("")
            setDestination("")
            setDepartureTime("")
            setArrivalTime("")
            setDays([])
            setStops("")
            setDuration("")
            setFlightNumber("")
            setDestinations([])
        }
    }, [props.isUpdating, props.route, props.airPorts]);
    
    const changeDays=(day)=>{
        const daysAux = [...days];
        const index = daysAux.findIndex(elem=>elem===day)
        if(index===-1){
            daysAux.push(day)
        }else{
            daysAux.splice(index,1)
        }
        setDays([...daysAux])
    }
    const addRoute =()=>{
        const validation = validator.validate({
            origin,
            destination,
            departureTime,
            arrivalTime,
            duration,
            stops,
            flightNumber
        });
        setErrors(validation);
        if (validation.isValid) {
            const originRoute = props.airPorts.find(airport=>airport.locationCode===origin)
            const arrivalRoute = props.airPorts.find(airport=>airport.locationCode===destination)
            const route = {
                arrival: destination,
                arrivalAirportName: arrivalRoute.airPortName,
                arrivalCityName: arrivalRoute.cityName,
                arrivalTime,
                days,
                departureAirportName: originRoute.airPortName,
                departureCityName: originRoute.cityName,
                departureTime,
                discontinued: "OPEN",
                duration,
                effectivity: "OPEN",
                flightNumber,
                origin,
                route: `${origin}/${destination}`,
                stops
            }
            if(props.isUpdating){
                props.updateRoute(route)
            }else{
                props.addRoute(route)
            }
            props.close()
        }
    }
    return (
        <Modal className={classes.modal} open={props.open} onClose={props.close} disableBackdropClick={true}>
            <Grid container style={modalStyle} className={classes.paper} spacing={2}>
                <Grid item xs={12}>
                    <Grid container justify="flex-end">
                        <HighlightOffIcon color="primary" onClick={props.close}/>
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        select
                        label="Origin"
                        variant="outlined"
                        value={origin}
                        fullWidth
                        error={errors.origin ? errors.origin.isInvalid : false}
                        helperText={errors.origin ? errors.origin.message : ""}
                        onChange={(e)=>{
                            const value = e.target.value
                            setOrigin(value)
                            changeDestination(value)
                        }}
                    >
                        {props.airPorts.map((airPort) => (
                            <MenuItem key={airPort.locationCode} value={airPort.locationCode}>
                            {airPort.locationCode}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        select
                        label="Destination"
                        variant="outlined"
                        value={destination}
                        fullWidth
                        error={errors.destination ? errors.destination.isInvalid : false}
                        helperText={errors.destination ? errors.destination.message : ""}
                        onChange={(e)=>{setDestination(e.target.value)}}
                    >
                        {destinations.map((airPort) => (
                            <MenuItem key={airPort.locationCode} value={airPort.locationCode}>
                            {airPort.locationCode}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        label="Departure time"
                        value={departureTime}
                        error={errors.departureTime ? errors.departureTime.isInvalid : false}
                        helperText={errors.departureTime ? errors.departureTime.message : ""}
                        onChange={(e)=>setDepartureTime(e.target.value)}
                        InputProps={{
                            inputComponent: TimeInput
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        label="Arrival time"
                        value={arrivalTime}
                        error={errors.arrivalTime ? errors.arrivalTime.isInvalid : false}
                        helperText={errors.arrivalTime ? errors.arrivalTime.message : ""}
                        onChange={(e)=>setArrivalTime(e.target.value)}
                        InputProps={{
                            inputComponent: TimeInput
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        label="Duration"
                        value={duration}
                        error={errors.duration ? errors.duration.isInvalid : false}
                        helperText={errors.duration ? errors.duration.message : ""}
                        onChange={(e)=>setDuration(e.target.value)}
                        InputProps={{
                            inputComponent: TimeInput
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        label="Stops"
                        value={stops}
                        error={errors.stops ? errors.stops.isInvalid : false}
                        helperText={errors.stops ? errors.stops.message : ""}
                        onChange={(e)=>setStops(e.target.value)}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        label="Flight number"
                        value={flightNumber}
                        error={errors.flightNumber ? errors.flightNumber.isInvalid : false}
                        helperText={errors.flightNumber ? errors.flightNumber.message : ""}
                        onChange={(e)=>setFlightNumber(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography>Dias operativos</Typography>
                    <DaysButtons days={days} changeDays={changeDays}/>
                </Grid>
                <Grid item xs={12}>
                    <Grid container justify="center">
                        <Button color="primary" variant="contained" onClick={addRoute}>Aceptar</Button>
                    </Grid>
                </Grid>
            </Grid>
        </Modal>
    )
}
export default function ItinerariesConfig(){
    const [airPorts, setAirPorts] = useState([])
    const [routes, setRoutes] = useState([])
    let history = useHistory();
    const [loading, setLoading] = useState(false)
    const [openModal, setOpenModal] = useState(false)
    const [isUpdating, setIsUpdating] = useState(false)
    const [route, setRoute] = useState({})
    const [index, setIndex] = useState(null)
    const [openToast, setOpenToast] = useState(false);
    const [toastMessage, setToastMessage] = useState("");
    const getAirports = async () => {
        setLoading(true)
        const {status, resp} = await destinationServices.getAirports();
        setLoading(false)
        if (status === 200) {
          setAirPorts(resp.airports);
        }
    };
    const getItineraries = async () => {
        setLoading(true)
        const {status, response} = await destinationServices.getItineraries();
        setLoading(false)
        if (status === 200) {
            setRoutes(response.routes);
        }
    }
    useEffect(() => {
        getAirports();
    }, []);
    useEffect(() => {
        getItineraries();
    }, []);
    
    const addRoute = (route) =>{
        const routesAux = [...routes];
        routesAux.push(route)
        setRoutes([...routesAux])
        setOpenToast(true)
        setToastMessage("Ruta agregada con exito")
    }

    const updateRoute = (route)=>{
        const routesAux = [...routes];
        routesAux[index] = route;
        setRoutes([...routesAux])
        setOpenToast(true)
        setToastMessage("Ruta actualizada con exito")
    }
    const deleteRoute = (index)=>{
        const routesAux = [...routes];
        routesAux.splice(index,1)
        setRoutes([...routesAux])
        setOpenToast(true)
        setToastMessage("Ruta eliminada con exito")
    }
    const saveItineraries = async()=>{
        const body = {
            routes
        }
        setLoading(true)
        const {status} = await destinationServices.putItineraries(body);
        setLoading(false)
        if (status === 200) {
            setOpenToast(true)
            setToastMessage("Itinerario actualizado")
            getItineraries()
        }else if (status === 401) {
            localStorage.clear();
            history.push(`/loginadminavior`);
        }
    }
    return (
        <Grid container justify="center" direction="row" spacing={2}>
            <Spinner loading={loading}/>
            <Grid item xs={12}>
                <Grid container justify="space-between">
                    <Button color="primary" variant="contained" onClick={saveItineraries} style={{height: "35px"}}>Guardar</Button>
                    <Fab color="primary" aria-label="add" onClick={()=>setOpenModal(true)}>
                        <AddIcon />
                    </Fab>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <TableContainer>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                key={column.id}
                                align={column.align}
                                style={{ minWidth: column.minWidth }}
                                >
                                {column.label}
                                </TableCell>
                            ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {routes.map((route, index) => (
                            <TableRow key={index}>
                                <TableCell component="th" scope="row">
                                    <Typography color="primary">{route.flightNumber}</Typography>
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    <Typography color="primary">{route.route}</Typography>
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    <Typography>{route.departureTime}</Typography>
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    <Typography>{route.arrivalTime}</Typography>
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    <Days days={route.days}/>
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    <IconButton  onClick={()=>{
                                            setIndex(index)
                                            setIsUpdating(true);
                                            setRoute(route)
                                            setOpenModal(true)
                                        }}>
                                        <EditIcon/>
                                    </IconButton>
                                    <IconButton onClick={()=>deleteRoute(index)}>
                                        <DeleteIcon/>
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            <ModalRoutes isUpdating={isUpdating} route={route} open={openModal} airPorts={airPorts} addRoute={addRoute} updateRoute={updateRoute} close={()=>{
                setOpenModal(false)
                setIsUpdating(false);
                setRoute({})
                }}/>
            <Toast open={openToast} close={()=>setOpenToast(false)} message={toastMessage}/>
        </Grid>
    )
}