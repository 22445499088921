const daysOftheWeekName=[
    "Domingo",
    "Lunes",
    "Martes",
    "Miercoles",
    "Jueves",
    "Viernes",
    "Sabado",
    
]
export default function getIndexDaysOfTheWeek(daysName){
    const indexs = []
    daysName.forEach(day => {
        const index = daysOftheWeekName.findIndex(value=>value===day);
        if(index!==-1){
            indexs.push(index)
        }
    });
    return indexs;
}