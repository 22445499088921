import React from "react";
import { useLocation, useHistory } from "react-router-dom";
import { CostResume, FligthResume, PaymentMethods } from "../components";
export default function PaymentMethodSelect(props) {
  let location = useLocation();
  let history = useHistory();
  if (!location.state) {
    history.push(`/`);
  }

  const params = location.state.params;
  const reservation = location.state.response
  return (
    <div>
      <FligthResume
        segments={params.originFlight.segments}
        searchParams={params.searchParams}
        totalTimeFlight={params.originFlight.totalTimeFlight}
      />
      {params.returnFlight ? (
        <FligthResume
          segments={params.returnFlight.segments}
          searchParams={params.searchParams}
          totalTimeFlight={params.returnFlight.totalTimeFlight}
        />
      ) : null}
      <div style={{ marginTop: "20px" }}>
        <CostResume
          originFlight={params.originFlight}
          returnFlight={params.returnFlight}
        />
      </div>
      <PaymentMethods params={params} reservation={reservation}/>
    </div>
  );
}
