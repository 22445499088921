import React, { useState } from "react";
import {
  Popover,
  Typography,
  Button,
  Grid,
  IconButton,
} from "@material-ui/core/";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  label: {
    color: "#575756",
    fontSize: "0.7rem",
    fontWeight: "400",
    lineHeight: "2",
  },
  button: {
    padding: "6px 0px",
    color: "#fff",
    border: "none",
    borderRadius: 0,
    borderBottom: "1px solid #CD171A",
    justifyContent: "flex-start",
  },
  flex: {
    display: "flex",
    alignItems: "center",
  },
  buttonFlex: {
    padding: "6px 0px",
    color: "#fff",
    border: "none",
    borderRadius: 0,
    borderBottom: "1px solid #CD171A",
    justifyContent: "flex-start",
    flex: 1,
  },
}));
export default function PassengersSelectStatic(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  function countPassengers() {
    return props.countAdults + props.countChildrens + props.countBabies >= 9;
  }
  return (
    <div
      style={{ width: "100%" }}
      className={props.short ? classes.flex : null}
    >
      <Typography
        className={props.classes ? props.classes.selectLabel : classes.label}
        display={props.short ? "inline" : "block"}
        style={{ marginRight: props.short ? "5px" : "0" }}
      >
        Pasajeros
      </Typography>
      <Button
        className={
          props.classes
            ? props.classes.button
            : props.short
            ? classes.buttonFlex
            : classes.button
        }
        aria-describedby={id}
        variant={props.variant ? props.variant : "outlined"}
        fullWidth={props.short ? false : true}
        onClick={handleClick}
      >
        <Typography
          align="left"
          className={props.classes ? props.classes.inputLabel : classes.label}
        >
          {props.countAdults} {props.short ? "ADT(S)" : "Adulto(s)"}{" "}
          {props.countChildrens} {props.short ? "CHD(s)" : "Niño(s)"}{" "}
          {props.countBabies} {props.short ? "INF(S)" : "Infante(s)"}
        </Typography>
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <div style={{ padding: "12px", width: "280px" }}>
          <Grid container justify="center" direction="row" spacing={1}>
            <Grid item xs={2}>
              <Typography variant="h6">{props.countAdults}</Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography component="h6">
                {props.countAdults === 1 ? "Adulto" : "Adultos"}
              </Typography>
              <Typography variant="caption" color="textSecondary">
                Mayor de 12 años
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <IconButton
                onClick={() => {
                  props.setCountAdults(props.countAdults - 1);
                  if (props.countAdults === props.countBabies)
                    props.setCountBabies(props.countAdults - 1);
                }}
                disabled={props.countAdults <= 1 || props.disabled}
                aria-label="delete"
              >
                <RemoveCircleOutlineIcon />
              </IconButton>
              <IconButton
                onClick={() => props.setCountAdults(props.countAdults + 1)}
                disabled={
                  props.countAdults >= 9 || countPassengers() || props.disabled
                }
                aria-label="delete"
              >
                <AddCircleOutlineIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Grid container justify="center" direction="row" spacing={1}>
            <Grid item xs={2}>
              <Typography variant="h6">{props.countChildrens}</Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography component="h6">
                {props.countChildrens === 1 ? "Niño" : "Niños"}
              </Typography>
              <Typography variant="caption" color="textSecondary">
                Desde 2 hasta 12 años
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <IconButton
                onClick={() =>
                  props.setCountChildrens(props.countChildrens - 1)
                }
                disabled={props.countChildrens <= 0 || props.disabled}
                aria-label="delete"
              >
                <RemoveCircleOutlineIcon />
              </IconButton>
              <IconButton
                onClick={() =>
                  props.setCountChildrens(props.countChildrens + 1)
                }
                disabled={
                  props.countChildrens >= 9 ||
                  countPassengers() ||
                  props.disabled
                }
                aria-label="delete"
              >
                <AddCircleOutlineIcon />
              </IconButton>
            </Grid>
          </Grid>
          {/*<Grid container justify="center" direction="row" spacing={1}>
            <Grid item xs={2}>
              <Typography variant="h6">{props.countBabies}</Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography component="h6">
                {props.countBabies === 1 ? "Infante" : "Infantes"}
              </Typography>
              <Typography variant="caption" color="textSecondary">
                Menores de 2 años
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <IconButton
                onClick={() => props.setCountBabies(props.countBabies - 1)}
                disabled={props.countBabies <= 0 || props.disabled}
                aria-label="delete"
              >
                <RemoveCircleOutlineIcon />
              </IconButton>
              <IconButton
                onClick={() => props.setCountBabies(props.countBabies + 1)}
                disabled={
                  props.countBabies >= props.countAdults ||
                  props.countBabies >= 9 ||
                  countPassengers() ||
                  props.disabled
                }
                aria-label="delete"
              >
                <AddCircleOutlineIcon />
              </IconButton>
            </Grid>
          </Grid>*/}
        </div>
      </Popover>
    </div>
  );
}
