import React, { useState, useEffect } from "react";
import { Grid, MenuItem, TextField, Typography, } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {FormValidator, FormatCurrency} from '../helpers/'
import {ButtonSpinner, Toast, Spinner} from "../components/";
import { paymentServices, dolarPriceServices } from "../services"
import { ExpandMore } from '@material-ui/icons';
import {filterNumber} from "../helpers/filterValues";
import { useHistory } from "react-router-dom";
const documentType=[
    {name:"Pasaporte", value:"P"},
    {name:"Doc de identidad", value:"V"}
]

const banks = [
    "Banco Mercantil",
    "Banco Banesco ",
]

const useStyles = makeStyles(theme=>({
    inputGrid:{
        marginBottom:"15px",
    },
    Grid:{
        display:'flex',
        justifyContent: 'center'
    },
    floatingLabelFocusStyle: {
        color: "#CD171A",
        fontSize: "15pt",
        width: "max-content",
    },
    inputLabel:{
        fontSize:"14pt",
        paddingBottom: 0,
        color: "#555555!important",
        height: '20px'
    },
    underline:{
        '&::before':{
            display: "none"
        },
        '&::after':{
            display: "none"
        }
    },
    inputDiv:{
        borderWidth: "1pt",
        borderStyle: "solid",
        borderColor: "#cccccc",
        padding: "10px",
        borderRadius: "5px",
        "& .MuiFormControl-root": {
            width: "100%",
        },
    },
    expandIcon:{
        color:"#FA7268"
    },
    [theme.breakpoints.down("md")]: {
        
        floatingLabelFocusStyle:{
          fontSize:"0.9rem"
        },
        inputLabel:{
          fontSize:"0.8rem"
        }
        
      }
}));

export default function PaymentBss(props){
    const classes = useStyles();
    const [loading, setLoading]=useState(false)
    const [toast, setToast]=useState(false)
    const [toastMessage, setToastMessage]=useState('')

    const [email, setEmail]=useState('')
    const [firstName, setFirstName]=useState('')
    const [ref, setRef]=useState('')
    const [docNumber, setDocNumer]=useState('')
    const [docType, setDocType]=useState('')
    const [firstNameError, setFirstNameError]=useState('')
    const [emailError, setEmailError]=useState('')
    const [refError, setRefError]=useState('')
    const [docNumberError, setDocNumberError]=useState('')
    const [docTypeError, setDocTypeError]=useState('')
    const [bank, setBank] =useState("")
    const [bankError, setBankError]=useState('')
    const [phone, setPhone] =useState("")
    const [phoneError, setPhoneError]=useState('')
    let history = useHistory();
    const [totalAmount, setTotalAmount]  = useState("0.00")
    const getAncillariesAmount = () => {
        return parseFloat(
            props.ancillaries.going.reduce(function (a, b) {
            return parseFloat(a) + parseFloat(b.total);
        }, 0) +
        props.ancillaries.goBack.reduce(function (a, b) {
            return parseFloat(a) + parseFloat(b.total);
            }, 0)
        ).toFixed(2);
    };
    const getAmountSeats = () =>{
        return parseFloat(
                props.selectedSeats.going.reduce(function (a, b) { return parseFloat(a) + parseFloat(b.total); }, 0) + 
               props.selectedSeats.goBack.reduce(function (a, b) { return parseFloat(a) + parseFloat(b.total); }, 0)
            ).toFixed(2)
      }
    const getPrice = async () => {
        setLoading(true)
        const {status, response} = await dolarPriceServices.getPrice()
        setLoading(false)
        if (status === 200) {
            const totalAncillaries = getAncillariesAmount();
            const totalSeats = getAmountSeats()
            const faresAmount = parseFloat(props.originFlight ? props.originFlight.fare.totalAmount : 0) +
            parseFloat(props.returnFlight ? props.returnFlight.fare.totalAmount : 0);
            setTotalAmount(
                parseFloat(
                    parseFloat(parseFloat(faresAmount)+parseFloat(totalAncillaries)+parseFloat(totalSeats))*parseFloat(response.price)
                ).toFixed(2)
            )

        }
    };
    useEffect(() => {
        getPrice();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    
    const validator = new FormValidator([
        {
          field: "firstName",
          method: "isEmpty",
          validWhen: false,
          message: "Debe ingresar sus nombres y apellidos "
        },
        {
            field: "email",
            method: "isEmpty",
            validWhen: false,
            message: "Debe ingresar su email"
        },
        { 
            field: 'email', 
            method: 'isEmail', 
            validWhen: true, 
            message: 'Ingrese un correo electrónico valido' 
        },
        {
            field: "ref",
            method: "isEmpty",
            validWhen: false,
            message: "Ingrese la referencia bancaria"
        },
        {
            field: "docNumber",
            method: "isEmpty",
            validWhen: false,
            message: "Ingrese número de documento"
        },
        {
            field: "docNumber",
            method: "isNumeric",
            validWhen: true,
            message: "Ingrese número de documento"
        },
        {
            field: "bank",
            method: "isEmpty",
            validWhen: false,
            message: "Seleccione la entidad bancaria"
        },
        {
            field: "docType",
            method: "isEmpty",
            validWhen: false,
            message: "Seleccione el tipo de documento"
        },
        {
            field: "phone",
            method: "isEmpty",
            validWhen: false,
            message: "Ingrese el número de teléfono "
        }
      ]);
      const validateForms=async ()=>{
        const validation = validator.validate({
            firstName,
            email,
            docType,
            docNumber,
            ref,
            bank,
            phone
        });
        setFirstNameError(validation.firstName.message)
        setEmailError(validation.email.message)
        setDocTypeError(validation.docType.message)
        setDocNumberError(validation.docNumber.message)
        setRefError(validation.ref.message)
        setBankError(validation.bank.message)
        setPhoneError(validation.phone.message)
        if(validation.isValid)
        {
            const request={
                pnr: props.pnr,
                email: email,
                description: "pago",
                name: firstName,
                referenceNumber:ref,
                bankName:bank,
                document:`${docType}-${docNumber}`,
                phone:phone,
                amount: parseFloat(totalAmount).toFixed(2)

            }
            setLoading(true);
            const peticion = await paymentServices.payTransferBss(request)
            setLoading(false);
            const resp = await peticion.resp;
            const status= await peticion.status;
            if(status===201){
                history.push(`/thankyoupagependding`, resp)
            }
            else if(status===400){
                setToast(true)
                setToastMessage(resp.error.message)
            }
        }
    }
    return (
        <div>
            <Grid container justify="center"  direction="row" alignItems="center" spacing={1}>
                <Grid item md={12} xs={12} className={classes.Grid}>
                    <Grid item md={5} xs={12}  className={classes.inputGrid} >
                        <Typography>
                            <strong>Cuentas Bancarias.</strong>
                        </Typography>
                        <Typography>
                            <strong>Titular:</strong> Avior Airlines C.A.
                        </Typography>
                        <Typography>
                            <strong>Rif:</strong> J-30209784-3
                        </Typography>
                        <Typography>
                            <strong>Banco Mencantil:</strong> 0105 0046 03  1046501186
                        </Typography>
                        <Typography>
                            <strong>Banco Banesco:</strong> 0134 0401 14 4013046786
                        </Typography>
                        <Typography>
                            <strong>Pago móvil:</strong>
                        </Typography>
                        <Typography>
                            <strong>Banco:</strong> 0105 Mercantil
                        </Typography>
                        <Typography>
                            <strong>Rif:</strong> J-30209784-3
                        </Typography>
                        <Typography>
                            <strong>Teléfono:</strong> 0424-8925387
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item md={12} xs={12} className={classes.Grid}>
                    <Typography variant="h5">Total a Pagar VES Bs {FormatCurrency(`${parseFloat(totalAmount).toFixed(2)}`)}</Typography>
                </Grid>
                <Grid item md={12} xs={12} className={classes.Grid}>
                    <Grid item md={5} xs={12}  className={classes.inputGrid} >
                        <Typography>
                            <strong>Detalles de la transferencia.</strong>
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item md={12} xs={12} className={classes.Grid}>
                    <Grid item md={5} xs={12}  className={classes.inputGrid} >
                        <div className={classes.inputDiv}>
                            <TextField value={email} label="Email" name="email"  id="email"
                                fullWidth
                                helperText={emailError} error={emailError!==""}
                                InputLabelProps={{ shrink: true, className: classes.floatingLabelFocusStyle, htmlFor:"email" }}
                                InputProps={{ classes: { input: classes.inputLabel, underline: classes.underline,} }}
                                onChange={e => setEmail(e.target.value)}
                            />
                        </div>
                    </Grid>
                </Grid>
                <Grid item md={12} xs={12}  className={classes.Grid}>
                    <Grid item md={5} xs={12} className={classes.inputGrid}>
                        <div className={classes.inputDiv}>
                            <TextField value={firstName} label="Nombres y apellidos" name="firstName" id="firstName"
                                fullWidth
                                helperText={firstNameError} error={firstNameError!==""}
                                InputLabelProps={{ shrink: true, className: classes.floatingLabelFocusStyle, htmlFor:"firstName" }}
                                InputProps={{ classes: { input: classes.inputLabel, underline: classes.underline,} }}
                                onChange={(e) => setFirstName(e.target.value)}
                            />
                        </div>
                    </Grid>
                </Grid>
                <Grid item md={12} xs={12}  className={classes.Grid}>
                    <Grid item md={5} xs={12}  className={classes.inputGrid}>
                        <div className={classes.inputDiv}>
                            <TextField value={bank} label="Entidad bancaria" name="bank"
                                fullWidth helperText={bankError}
                                select
                                error={bankError!==""}
                                InputLabelProps={{ shrink: true, className: classes.floatingLabelFocusStyle, htmlFor:"bank" }}
                                InputProps={{  classes: { input: classes.inputLabel, underline: classes.underline,} }}
                                onChange={(e) => setBank(e.target.value)}
                                SelectProps={{ IconComponent:() => <ExpandMore className={classes.expandIcon}/>, }}
                            >
                                {banks.map((bank, index) => (
                                        <MenuItem key={index} value={bank}>
                                          {bank}
                                        </MenuItem>
                                    ))}
                            </TextField>
                        </div>
                    </Grid>
                </Grid>
                <Grid item md={12} xs={12}  className={classes.Grid}>
                    <Grid item md={5} xs={12}  className={classes.inputGrid}>
                        <div className={classes.inputDiv}>
                            <TextField value={ref} label="Referencia Bancaria" name="ref"
                                fullWidth helperText={refError}
                                error={refError!==""}
                                InputLabelProps={{ shrink: true, className: classes.floatingLabelFocusStyle, htmlFor:"ref" }}
                                InputProps={{ classes: { input: classes.inputLabel, underline: classes.underline,} }}
                                onChange={(e) => setRef(e.target.value)}
                            />
                        </div>
                    </Grid>
                </Grid>
                <Grid item md={12} xs={12}  className={classes.Grid}>
                    <Grid item md={5} xs={12} className={classes.inputGrid}>
                        <div className={classes.inputDiv}>
                            <TextField value={docType} label="Tipo de documento" select id="docType"
                                helperText={docTypeError} error={docTypeError!==""} 
                                InputLabelProps={{ shrink: true, className: classes.floatingLabelFocusStyle, htmlFor:"docType" }}
                                InputProps={{ classes: { input: classes.inputLabel, underline: classes.underline,} }}
                                SelectProps={{ IconComponent:() => <ExpandMore className={classes.expandIcon}/>, }}
                                onChange={e=>setDocType(e.target.value)} 
                            >
                            {
                                documentType.map((document, index) => (
                                    <MenuItem key={index} value={document.value}>
                                        {document.name}
                                    </MenuItem>
                                ))
                            }
                            </TextField>
                        </div>
                    </Grid>
                </Grid>
                <Grid item md={12} xs={12}  className={classes.Grid}>
                    <Grid item md={5} xs={12} className={classes.inputGrid}>
                        <div className={classes.inputDiv}>
                            <TextField value={docNumber} label="Número de documento" name="number" id="docNumber"
                                 fullWidth     
                                helperText={docNumberError} error={docNumberError!==""}
                                InputLabelProps={{ shrink: true, className: classes.floatingLabelFocusStyle, htmlFor:"docNumber" }}
                                InputProps={{  classes: { input: classes.inputLabel, underline: classes.underline,} }}
                                onChange={e=>setDocNumer(filterNumber(e.target.value))}
                            />
                        </div>
                    </Grid>
                </Grid>
                <Grid item md={12} xs={12}  className={classes.Grid}>
                <Grid item md={5} xs={12} className={classes.inputGrid}>
                    <div className={classes.inputDiv}>
                        <TextField value={phone} label="Número de teléfono " name="phone" id="phone"
                            fullWidth     
                            helperText={phoneError} error={phoneError!==""}
                            InputLabelProps={{ shrink: true, className: classes.floatingLabelFocusStyle, htmlFor:"phone" }}
                            InputProps={{ classes: { input: classes.inputLabel, underline: classes.underline,} }}
                            onChange={e=>setPhone(e.target.value)}
                        />
                    </div>
                </Grid>
            </Grid>
                <Grid item md={12} xs={12}  className={classes.Grid}>
                    <Grid item md={5} xs={12}  className={classes.inputGrid}>
                        <Grid container justify="space-between">
                            <div style={{width:"158px", height:"41px"}}>
                                <ButtonSpinner action={()=> {props.goBack()}} fullWidth text="Atras"
                                />
                            </div>
                            <div style={{width:"158px", height:"41px"}}>
                                <ButtonSpinner action={validateForms} fullWidth text="Continuar"
                                />
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Toast
                message={toastMessage}
                open={toast}
                close={() => { setToast(false) }}
            />
            <Spinner loading={loading}/>                
        </div>
    )
}