const banks = [
  { value: "0156", name: "100% BANCO" },
  { value: "0196", name: "ABN AMRO BANK" },
  { value: "0172", name: "BANCAMIGA" },
  { value: "0171", name: "BANCO ACTIVO BANCO COMERCIAL" },
  { value: "0166", name: "BANCO AGRICOLA" },
  { value: "0175", name: "BANCO BICENTENARIO" },
  { value: "0128", name: "BANCO CARONI" },
  { value: "0164", name: "BANCO DE DESARROLLO DEL MICROEMPRESARIO" },
  { value: "0102", name: "BANCO DE VENEZUELA" },
  { value: "0114", name: "BANCO DEL CARIBE" },
  { value: "0149", name: "BANCO DEL PUEBLO SOBERANO" },
  { value: "0163", name: "BANCO DEL TESORO" },
  { value: "0176", name: "BANCO ESPIRITO SANTO" },
  { value: "0115", name: "BANCO EXTERIOR" },
  { value: "0003", name: "BANCO INDUSTRIAL DE VENEZUELA" },
  { value: "0173", name: "BANCO INTERNACIONAL DE DESARROLLO" },
  { value: "0105", name: "MERCANTIL" },
  { value: "0191", name: "BANCO NACIONAL DE CREDITO" },
  { value: "0116", name: "BANCO OCCIDENTAL DE DESCUENTO" },
  { value: "0138", name: "BANCO PLAZA" },
  { value: "0108", name: "BANCO PROVINCIAL BBVA" },
  { value: "0104", name: "BANCO VENEZOLANO DE CREDITO" },
  { value: "0168", name: "BANCRECER" },
  { value: "0134", name: "BANESCO" },
  { value: "0177", name: "BANFANB" },
  { value: "0146", name: "BANGENTE" },
  { value: "0174", name: "BANPLUS" },
  { value: "0190", name: "CITIBANK" },
  { value: "0121", name: "CORP BANCA" },
  { value: "0157", name: "DELSUR" },
  { value: "0151", name: "FONDO COMUN" },
  { value: "0601", name: "INSTITUTO MUNICIPAL DE CRÉDITO POPULAR" },
  { value: "0169", name: "MIBANCO" },
  { value: "0137", name: "SOFITASA" },
  { value: "Banco Mercantil", name: "MERCANTIL" },
  { value: "Banco Banesco ", name: "BANESCO" }
]

export default function getBank(bankName){
  const bank = banks.find(elem=>elem.value === bankName)
  if(bank) {
    return bank.name
  } else return bankName
}