import React from "react";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import { OriginDestinationPointToPoint } from ".";
import { Typography } from "@material-ui/core";
const useStyles = makeStyles({
  paper: {
    position: "absolute",
    maxWidth: "400px",
    width: "90%",
    backgroundColor: "#fff",
    border: "1px solid #000",
    padding: "20px",
    top: "20%",
  },
  modal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});
export default function ModalSegmentsInfo(props) {
  const classes = useStyles();
  return (
    <Modal
      className={classes.modal}
      open={props.open}
      onClose={() => props.close()}
    >
      <div className={classes.paper}>
        <Typography>SEGMENTOS</Typography>
        {props.segments.map((segment, index) => (
          <div key={index}>
            <Typography>TRAYECTO {index+1}</Typography>
            <OriginDestinationPointToPoint segment={segment} />
          </div>
        ))}
      </div>
    </Modal>
  );
}
