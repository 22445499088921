import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Grid,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/core/styles";
import { PriorityAssistant } from "./ancillaries-components";
const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));
export default function AncillariesPassengerList(props) {
  const classes = useStyles();
  return (
    <Grid container justify="flex-end" direction="row" spacing={1}>
      <Grid item xs={12} md={8}>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading} color="primary">
              ADICIONALES
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <PriorityAssistant />
          </AccordionDetails>
        </Accordion>
      </Grid>
    </Grid>
  );
}
