import React, { useState, useEffect } from "react";
import FlightRoundTrip from "../components/round-trip/flight-round-trip";
import {
  SelectedFlightsResumen,
  Title,
  DatesReBooking,
  //, AncillariesPassengerList
} from "../components/";
import { Typography, Grid, Divider } from "@material-ui/core";

export default function SearchFlightResultRoundTrip(props) {
  const [originflights, setOriginflights] = useState([]);
  const [returnflights, setReturnflights] = useState([]);
  const [selectFlightRowOrigin, setSelectFlightRowOrigin] = useState(null);
  const [selectFlightRowReturn, setSelectFlightRowReturn] = useState(null);
  const [resultsFlights, setResultsFlights] = useState([]);
  const [openFareTerms, setOpenFareTerms] = useState(false);

  useEffect(() => {
    setResultsFlights(props.resultsFlights);
  }, [props.resultsFlights]);
  useEffect(() => {
    buildFlights(resultsFlights);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resultsFlights]);
  function buildFlights(flights) {
    const originflightsArray = [];
    const returnflightsArray = [];
    if (Array.isArray(flights))
      flights.forEach((flight) => {
        const originSegments = flight.segments.going.map((originSegment) => {
          const segment = {
            ...originSegment,
            departureInfo: {
              ...originSegment.departureInfo,
            },
            arrivalInfo: {
              ...originSegment.arrivalInfo,
            },
          };
          return segment;
        });

        const returnSegments = flight.segments.goBack.map((returnSegment) => {
          const segment = {
            ...returnSegment,
            departureInfo: {
              ...returnSegment.departureInfo,
            },
            arrivalInfo: {
              ...returnSegment.arrivalInfo,
            },
          };
          return segment;
        });
        const faresDepartureExisting = [];
        flight.rdbs.forEach((rdb) => {
          let existDeparture = faresDepartureExisting.find(
            (element) =>
              element.fareInfo.className === rdb.fareInfo[0].className
          );
          if (!existDeparture) {
            if (rdb.fareInfo[0].className === rdb.fareInfo[1].className) {
              let code = "1";
              let fareInfo = rdb.fareInfo.find(
                (element) => element.originDestRPH === code
              );
              let fareConstruction = {
                ...rdb.fareConstruction.find(
                  (element) => element.originDestRPH === code
                ),
                componentAmount: parseFloat(
                  parseFloat(rdb.totalFare) / 2
                ).toFixed(2),
              };
              faresDepartureExisting.push({
                ...rdb,
                fareInfo,
                fareConstruction,
              });
            }
          }
        });
        const fareArrivalExisting = [];
        flight.rdbs.forEach((rdb) => {
          let existArrival = fareArrivalExisting.find(
            (element) =>
              element.fareInfo.className ===
              rdb.fareInfo[rdb.fareInfo.length - 1].className
          );
          if (!existArrival) {
            let code = "1001";
            let fareInfo = rdb.fareInfo.find(
              (element) => element.originDestRPH === code
            );
            let fareConstruction = rdb.fareConstruction.find(
              (element) => element.originDestRPH === code
            );
            fareArrivalExisting.push({ ...rdb, fareInfo, fareConstruction });
          }
        });
        const originflightId = originSegments[0].flightID;
        let existing = false;
        originflightsArray.forEach((originflight) => {
          if (
            originflight.segments.find(
              (segment) => segment.flightID === originflightId
            )
          ) {
            existing = true;
          }
        });
        if (!existing)
          originflightsArray.push({
            flightId: originflightId,
            totalTimeFlight: flight.segments.timeFlightGoing,
            segments: originSegments,
            fares: faresDepartureExisting,
          });
        existing = false;
        const returnflightId = returnSegments[0].flightID;
        returnflightsArray.forEach((returnflight) => {
          if (
            returnflight.segments.find(
              (segment) => segment.flightID === returnflightId
            )
          ) {
            existing = true;
          }
        });
        if (!existing)
          returnflightsArray.push({
            flightId: returnflightId,
            totalTimeFlight: flight.segments.timeFlightGoBack,
            segments: returnSegments,
            fares: fareArrivalExisting,
          });
      });
    setOriginflights(originflightsArray);
    setReturnflights(returnflightsArray);
  }
  function getTaxesPares(flightId, className, fare) {
    const returnflightsArray = [];
    resultsFlights.forEach((flight) => {
      const originSegment = flight.segments.going.map((originSegment) => {
        const segment = {
          ...originSegment,
          departureInfo: {
            ...originSegment.departureInfo,
          },
          arrivalInfo: {
            ...originSegment.arrivalInfo,
          },
        };
        return segment;
      });
      const returnSegments = flight.segments.goBack.map((returnSegment) => {
        const segment = {
          ...returnSegment,
          departureInfo: {
            ...returnSegment.departureInfo,
          },
          arrivalInfo: {
            ...returnSegment.arrivalInfo,
          },
        };
        return segment;
      });
      const originflightId = originSegment[0].flightID;
      if (flightId === originflightId) {
        const fareArrivalExisting = [];
        flight.rdbs.forEach((rdb) => {
          if (rdb.fareInfo[0].className === className) {
            let code = "1001";
            let fareInfo = rdb.fareInfo.find(
              (element) => element.originDestRPH === code
            );
            let fareConstruction = {
              ...rdb.fareConstruction.find(
                (element) => element.originDestRPH === code
              ),
              componentAmount: parseFloat(
                parseFloat(rdb.totalFare) -
                  parseFloat(fare.fareConstruction.componentAmount)
              ).toFixed(2),
            };
            fareArrivalExisting.push({ ...rdb, fareInfo, fareConstruction });
          }
        });
        let existing = false;
        const returnflightId = returnSegments[0].flightID;
        returnflightsArray.forEach((returnflight) => {
          if (
            returnflight.segments.find(
              (segment) => segment.flightID === returnflightId
            )
          ) {
            existing = true;
          }
        });
        if (!existing)
          returnflightsArray.push({
            flightId: returnflightId,
            totalTimeFlight: flight.segments.timeFlightGoBack,
            segments: returnSegments,
            fares: fareArrivalExisting,
          });
      }
    });
    setReturnflights(returnflightsArray);
  }
  function findFarePares() {
    const flightCombination = resultsFlights.find(
      (flight) =>
        flight.segments.going[0].flightID === props.originFlight.flightId &&
        flight.segments.goBack[flight.segments.goBack.length - 1].flightID ===
          props.returnFlight.flightId
    );
    const rdbCombination = flightCombination.rdbs.find(
      (rdb) =>
        rdb.fareInfo[0].className === props.originFlight.fare.className &&
        rdb.fareInfo[rdb.fareInfo.length - 1].className ===
          props.returnFlight.fare.className
    );

    const newOriginFlight = {
      ...props.originFlight,
      fare: {
        ...props.originFlight.fare,
        classCode: rdbCombination.fareInfo[0].classCode,
        fareInfo: {
          ...props.originFlight.fare.fareInfo,
          classCode: rdbCombination.fareInfo[0].classCode,
          cabin: rdbCombination.fareInfo[0].cabin,
          className: rdbCombination.fareInfo[0].className,
          originDestRPH: rdbCombination.fareInfo[0].originDestRPH,
          posting: rdbCombination.fareInfo[0].originDestRPH,
        },
      },
    };

    const newReturnFlight = {
      ...props.returnFlight,
      fare: {
        ...props.returnFlight.fare,
        classCode:
          rdbCombination.fareInfo[rdbCombination.fareInfo.length - 1].classCode,
        fareInfo: {
          ...props.returnFlight.fare.fareInfo,
          classCode:
            rdbCombination.fareInfo[rdbCombination.fareInfo.length - 1]
              .classCode,
          cabin:
            rdbCombination.fareInfo[rdbCombination.fareInfo.length - 1].cabin,
          className:
            rdbCombination.fareInfo[rdbCombination.fareInfo.length - 1]
              .className,
          originDestRPH:
            rdbCombination.fareInfo[rdbCombination.fareInfo.length - 1]
              .originDestRPH,
          posting:
            rdbCombination.fareInfo[rdbCombination.fareInfo.length - 1]
              .originDestRPH,
        },
      },
    };
    props.setOriginFlight(newOriginFlight);
    props.setReturnFlight(newReturnFlight);
  }
  return (
    <>
      {props.resultsAvailability ? (
        <div style={{ width: "100%" }}>
          <Grid container justify="center" direction="row">
            <Grid item xs={12} md={10}>
              <Typography color="textSecondary" style={{ fontSize: "1.25rem" }}>
                <span>
                  SELECCIONA UN VUELO DE{" "}
                  <span style={{ fontWeight: "bold" }}>IDA</span>
                </span>
              </Typography>
            </Grid>
          </Grid>
          <DatesReBooking
            route={props.originRoute}
            searchParams={props.searchParams}
            setFlights={(result) => {
              setReturnflights([]);
              setOriginflights([]);
              setOpenFareTerms(!openFareTerms);
              setResultsFlights(result);
              buildFlights(result);
              props.resetOrigin();
              props.resetReturn();
            }}
          />
          {originflights.map((originflight, index) => (
            <FlightRoundTrip
              key={index}
              isSelected={selectFlightRowOrigin === index}
              searchParams={props.searchParams}
              terms={props.terms}
              typeFlight="origin"
              selectable={true}
              flight={originflight}
              openFareTerms={openFareTerms}
              selectFlight={(flight) => {
                props.setReturnFlight(null);
                setSelectFlightRowOrigin(index);
                getTaxesPares(
                  originflight.segments[0].flightID,
                  flight.fare.fareInfo.className,
                  flight.fare
                );
                props.setOriginFlight({
                  ...flight,
                  fare: {
                    ...flight.fare,
                    ...flight.fare.fareInfo,
                    faresPerTypePassenger: flight.fare.faresPerPassenger,
                    totalAmount: flight.fare.fareConstruction.componentAmount,
                  },
                });
                setSelectFlightRowReturn(null);
              }}
            />
          ))}
          {props.originFlight ? (
            <>
              <Divider
                style={{
                  width: "100%",
                  marginBottom: "20px",
                  marginTop: "20px",
                }}
              />
              <Grid container justify="center" direction="row">
                <Grid item xs={12} md={10}>
                  <Typography
                    color="textSecondary"
                    style={{ fontSize: "1.25rem" }}
                  >
                    <span>
                      <span>
                        SELECCIONA UN VUELO DE{" "}
                        <span style={{ fontWeight: "bold" }}>REGRESO</span>
                      </span>
                    </span>
                  </Typography>
                </Grid>
              </Grid>
              {returnflights.map((returnflightflight, index) => (
                <FlightRoundTrip
                  key={index}
                  typeFlight="destination"
                  searchParams={props.searchParams}
                  selectable={true}
                  terms={props.terms}
                  flight={returnflightflight}
                  openFareTerms={openFareTerms}
                  isSelected={selectFlightRowReturn === index}
                  selectFlight={(flight) => {
                    setSelectFlightRowReturn(index);
                    props.setReturnFlight({
                      ...flight,
                      fare: {
                        ...flight.fare,
                        ...flight.fare.fareInfo,
                        faresPerTypePassenger: flight.fare.faresPerPassenger,
                        totalAmount:
                          flight.fare.fareConstruction.componentAmount,
                      },
                    });
                  }}
                />
              ))}
            </>
          ) : null}
        </div>
      ) : null}
      {props.notResult ? (
        <div style={{ margin: 20 }}>
          <Title h2="NO HAY RESULTADOS PARA ESTA" strong="COMBINACION" />
        </div>
      ) : null}
      {/*props.resultsAvailability&&
        <div style={{ marginTop: "20px" }}>
          <AncillariesPassengerList/>
      </div>*/}
      {props.originFlight || props.returnFlight ? (
        <SelectedFlightsResumen
          isRoundTrip={props.searchParams.isRoundTrip}
          originFlight={props.originFlight}
          returnFlight={props.returnFlight}
          searchParams={props.searchParams}
          open={() => props.open()}
          sendData={(data) => {
            findFarePares();
            props.sendData(data);
          }}
        />
      ) : null}
    </>
  );
}
