import React from "react";
import {
  SearchResults,
  Title,
  SelectedFlightsResumen,
  //, AncillariesPassengerList
} from "../components";
import { Divider } from "@material-ui/core";

export default function SearchFlightResult(props) {
  const [selectable, setSelectable] = React.useState(false);
  return (
    <>
      {props.resultsAvailability ? (
        <div style={{ width: "100%" }}>
          <SearchResults
            selectable={true}
            route={props.originRoute}
            title={
              <span>
                SELECCIONA UN VUELO DE{" "}
                <span style={{ fontWeight: "bold" }}>IDA</span>
              </span>
            }
            searchParams={props.searchParams}
            flights={props.resultsFlights.departure}
            terms={props.terms}
            selectFlight={(flight) => {
              setSelectable(true);
              props.setOriginFlight(flight);
            }}
            reset={() => props.resetOrigin()}
            typeFlight="origin"
          />
          {props.searchParams.isRoundTrip ? (
            <>
              <Divider
                style={{
                  width: "100%",
                  marginBottom: "20px",
                  marginTop: "20px",
                }}
              />
              <SearchResults
                selectable={selectable}
                route={props.returnRoute}
                title={
                  <span>
                    SELECCIONA UN VUELO DE{" "}
                    <span style={{ fontWeight: "bold" }}>REGRESO</span>
                  </span>
                }
                searchParams={props.searchParams}
                flights={props.resultsFlights.arrival}
                terms={props.terms}
                selectFlight={(flight) => props.setReturnFlight(flight)}
                reset={() => props.resetReturn()}
                typeFlight="destination"
              />
            </>
          ) : null}
        </div>
      ) : null}
      {props.notResult ? (
        <div style={{ margin: 20 }}>
          <Title h2="NO HAY RESULTADOS PARA ESTA" strong="COMBINACION" />
        </div>
      ) : null}
      {/*!props.notResult&&
        <div style={{ marginTop: "20px" }}>
          <AncillariesPassengerList/>
      </div>*/}
      {props.originFlight || props.returnFlight ? (
        <SelectedFlightsResumen
          isRoundTrip={props.searchParams.isRoundTrip}
          originFlight={props.originFlight}
          returnFlight={props.returnFlight}
          searchParams={props.searchParams}
          open={() => props.open()}
          sendData={(data) => props.sendData(data)}
        />
      ) : null}
    </>
  );
}
