import React from "react";
import clsx from "clsx";
import { Grid, Typography, Toolbar} from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { AppBar, Container } from "@material-ui/core/";
import { useLocation } from "react-router-dom";
const useStyles = makeStyles(theme => ({
  root: {
    display: "flex"
  },
  appBar: {
    background: "#fff",
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    //overflow: "auto"
  },
  container: {
    backgroundImage: `url(./img/4.jpg)`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    height:"100vh"
  },
  box:{
      borderRadius:"10px",
      background:"#fff",
      padding: "20px 40px"
  },
  greyFont:{
      color:"#575756"
  }
}));

export default function ThankYouPageOpenLink(){
    const classes = useStyles();
    let location = useLocation();
    const [params] = React.useState(location.state ? location.state.state : {});
    function AppBarCustom(props) {
        return (
          <AppBar position="absolute" className={clsx(classes.appBar)}>
            <Container maxWidth="lg">
              <Toolbar>
                <img src="/img/logoWhite.png" alt="" style={{ width: "200px" }} />
              </Toolbar>
            </Container>
          </AppBar>
        );
    }
    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBarCustom />
            <main className={classes.content}>
            <div className={classes.appBarSpacer} />
                <Grid container alignItems="center" justify="center" className={classes.container}>
                    <Grid item sm={12}>
                        <Grid container justify="center">
                            <div className={classes.box}>
                                <Typography color="primary" variant="h4" align="center"><strong>Gracias por su compra.</strong></Typography>
                                <Typography className={classes.greyFont} variant="h5" align="center">PNR: <strong>{params.order.pnr}</strong> </Typography>
                                <Typography className={classes.greyFont} variant="h5" align="center">Monto: <strong>{params.order.amount}$</strong> </Typography>
                                <Typography className={classes.greyFont} variant="h5" align="center">Descripción: <strong>{params.order.description}</strong> </Typography>
                            </div>
                            
                        </Grid>
                        
                    </Grid>
                </Grid>
            </main>
        </div>
    )
}