export default function getCityName(code) {
  const CITY_NAMES = [
    {
      code: "BNS",
      cityName: "BARINAS",
      airPortName: "BARINAS AIRPORT"
    },
    {
      code: "CCS",
      cityName: "CARACAS",
      airPortName: "SIMON BOLIVAR AIRPORT"
    },
    {
      code: "BOG",
      cityName: "Bogotá",
      airPortName: "EL DORADO INTL AIRPORT"
    },
    {
      code: "VIG",
      cityName: "EL VIGIA",
      airPortName: "EL VIGIA AIRPORT"
    },
    {
      code: "VLN",
      cityName: "VALENCIA",
      airPortName: "VALENCIA AIRPORT"
    },
    {
      code: "SKB",
      cityName: "ST KITTS",
      airPortName: "ROBERT L. BRADSHAW INTL AIRPORT"
    },
    {
      code: "SDQ",
      cityName: "SANTO DOMINGO",
      airPortName: "LAS AMERICAS INTL AIRPORT"
    },
    {
      code: "PZO",
      cityName: "PUERTO ORDAZ",
      airPortName: "PUERTO ORDAZ AIRPORT"
    },
    {
      code: "PMV",
      cityName: "PORLAMAR",
      airPortName: "DELCARIBE GEN S MARINO AIRPORT"
    },
    {
      code: "PUJ",
      cityName: "PUNTA CANA",
      airPortName: "PUNTA CANA INTL AIRPORT"
    },
    {
      code: "MAO",
      cityName: "MANAUS",
      airPortName: "EDUARDO GOMES INTERNATIONAL AIRPORT"
    },
    {
      code: "MAR",
      cityName: "MARACAIBO",
      airPortName: "LA CHINITA AIRPORT"
    },
    {
      code: "MDE",
      cityName: "MEDELLIN",
      airPortName: "JOSE MARIA CORDOVA INTL AIRPORT"
    },
    {
      code: "LIM",
      cityName: "LIMA",
      airPortName: "J CHAVEZ INTERNATIONAL AIRPOR"
    },
    {
      code: "CLO",
      cityName: "CALI",
      airPortName: "ALFONSO B. ARAGON INTL AIRPORT"
    },
    {
      code: "BLA",
      cityName: "BARCELONA",
      airPortName: "GEN J A ANZOATEGUI AIRPORT"
    },
    {
      code: "CUN",
      cityName: "CANCUN",
      airPortName: "CANCUN INTL"
    },
    {
      code: "PTY",
      cityName: "PANAMÁ",
      airPortName: "AEROPUERTO INTERNACIONAL DE TOCUMEN"
    },
    {
      code: "BRM",
      cityName: "BARQUISIMETO",
      airPortName: "AEROPUERTO INTERNACIONAL JACINTO LARA"
    },
    {
      code: "LSP",
      cityName: "LAS PIEDRAS",
      airPortName: "AEROPUERTO INTERNACIONAL DE LAS PIEDRAS"
    },
    {
      code: "MUN",
      cityName: "MATURIN",
      airPortName: "AEROPUERTO INTERNACIONAL GENERAL JOSE TADEO MONAGAS"
    },
    {
      code: "CUR",
      cityName: "CURAZAO",
      airPortName: "AEROPUERTO INTERNACIONAL HATO"
    },
    {
      code: "PYH",
      cityName: "PUERTO AYACUCHO",
      airPortName: "AEROPUERTO NACIONAL CACIQUE ARAMARE"
    },
    {
      code: "STB",
      cityName: "SANTA BARBARA DEL ZULIA",
      airPortName: "AEROPUERTO MIGUEL URDANETA FERNÁNDEZ"
    },
    {
      code: "MDE",
      cityName: "MEDELLIN",
      airPortName: "AEROPUERTO INTERNACIONAL JOSE MARIA CORDOVA"
    }
  ];
  return CITY_NAMES.find(city=>city.code===code);
}