import React, { useState, useEffect } from "react";
import { Grid, TextField, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { FormValidator } from "../helpers";
import { ButtonSpinner, Toast, Spinner } from "../components";
import { paymentServices, dolarPriceServices } from "../services";
import { FormatCurrency, dateValidYYMM } from "../helpers";
import Cards from "react-credit-cards";
import {
  formatCreditCardNumber,
  formatExpirationDate,
  formatCVC,
} from "../helpers/credit-card-filters";
import { useHistory } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  inputGrid: {
    marginBottom: "15px",
  },
  Grid: {
    display: "flex",
    justifyContent: "center",
  },
  floatingLabelFocusStyle: {
    color: "#CD171A",
    fontSize: "15pt",
    width: "max-content",
  },
  inputLabel: {
    fontSize: "14pt",
    paddingBottom: 0,
    color: "#555555!important",
    height: "20px",
  },
  underline: {
    "&::before": {
      display: "none",
    },
    "&::after": {
      display: "none",
    },
  },
  inputDiv: {
    borderWidth: "1pt",
    borderStyle: "solid",
    borderColor: "#cccccc",
    padding: "10px",
    borderRadius: "5px",
    "& .MuiFormControl-root": {
      width: "100%",
    },
  },
  expandIcon: {
    color: "#FA7268",
  },
  [theme.breakpoints.down("md")]: {
    floatingLabelFocusStyle: {
      fontSize: "0.9rem",
    },
    inputLabel: {
      fontSize: "0.8rem",
    },
  },
}));

export default function PaymentBookingCreditCardBancaAmigaBss(props) {
  const classes = useStyles();
  const [toast, setToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [document, setDocument] = useState("");
  const [cvc, setCvc] = useState("");
  const [creditCard, setCreditCard] = useState("");
  const [date, setDate] = useState("");
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState("");
  const [loading, setLoading] = useState(false);
  const [focused, setFocused] = useState("");
  const [totalAmount, setTotalAmount] = useState("0.00");
  const [countAttempts, setCountAttempts] = useState(0);
  let history = useHistory();
  const getAmountAmount = () => {
    return parseFloat(
      props.ancillaries.going.reduce(function (a, b) {
        return parseFloat(a) + parseFloat(b.total);
      }, 0) +
        props.ancillaries.goBack.reduce(function (a, b) {
          return parseFloat(a) + parseFloat(b.total);
        }, 0)
    ).toFixed(2);
  };
  const getAmountSeats = () => {
    return parseFloat(
      props.selectedSeats.going.reduce(function (a, b) {
        return parseFloat(a) + parseFloat(b.total);
      }, 0) +
        props.selectedSeats.goBack.reduce(function (a, b) {
          return parseFloat(a) + parseFloat(b.total);
        }, 0)
    ).toFixed(2);
  };
  const getPrice = async () => {
    setLoading(true);
    const { status, response } = await dolarPriceServices.getPrice();
    setLoading(false);
    if (status === 200) {
      const totalAncillaries = getAmountAmount();
      const totalSeats = getAmountSeats();
      const faresAmount =
        parseFloat(
          props.originFlight ? props.originFlight.fare.totalAmount : 0
        ) +
        parseFloat(
          props.returnFlight ? props.returnFlight.fare.totalAmount : 0
        );
      setTotalAmount(
        parseFloat(
          parseFloat(
            parseFloat(faresAmount) +
              parseFloat(totalAncillaries) +
              parseFloat(totalSeats)
          ) * parseFloat(response.price)
        ).toFixed(2)
      );
    }
  };
  useEffect(() => {
    getPrice();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const validator = new FormValidator([
    {
      field: "creditCard",
      method: "isEmpty",
      validWhen: false,
      message: "Debe ingresar su número de tarjeta",
    },
    {
      field: "email",
      method: "isEmpty",
      validWhen: false,
      message: "Debe ingresar email",
    },
    {
      field: "email",
      method: "isEmail",
      validWhen: true,
      message: "Ingrese un correo electrónico valido",
    },
    {
      field: "date",
      method: "isEmpty",
      validWhen: false,
      message: "Ingrese la fecha de vencimiento de su tarjeta de crédito.",
    },
    {
      field: "date",
      method: dateValidYYMM,
      validWhen: true,
      message: "La fecha de vencimiento no es valida",
    },
    {
      field: "cvc",
      method: "isEmpty",
      validWhen: false,
      message: "Ingrese el CVC de su tarjeta de crédito.",
    },
    {
      field: "document",
      method: "isEmpty",
      validWhen: false,
      message: "Ingrese su número de documento",
    },
    {
      field: "cvc",
      method: "isLength",
      validWhen: true,
      args: [{ min: 3, max: 4 }],
      message: "El CVC no es valido",
    },
  ]);
  const paymentCreditCard = async () => {
    const validation = validator.validate({
      email,
      creditCard: creditCard.replace(/\D+/g, ""),
      date,
      cvc,
      document,
    });
    setErrors(validation);
    if (validation.isValid) {
      const exp = date.split("/");
      console.log({
        email,
        pnr: props.pnr,
        creditCard: creditCard.replace(/\D+/g, ""),
        cvc,
        year: exp[0],
        month: exp[1],
        document,
      });
      const body = {
        email,
        pnr: props.pnr,
        creditCard: creditCard.replace(/\D+/g, ""),
        cvc,
        year: exp[0],
        month: exp[1],
        document,
      };
      setLoading(true);
      const { response, status } =
        await paymentServices.paymentBookingBancaAmigaTDCBss({ ...body });
      setLoading(false);
      if (status === 200) {
        history.push(`/thankyoupage`, {
          state: {
            ...response,
            reservationDetails: response.reservationdetails,
            originFlight: props.originFlight,
            returnFlight: props.returnFlight,
          },
        });
      } else {
        setToastMessage(response.error.message);
        setToast(true);
      }
    }
  };
  const onFocus = (e) => {
    setFocused(e.target.name);
  };
  return (
    <div>
      <Grid
        container
        justify="center"
        direction="row"
        alignItems="center"
        spacing={1}
      >
        <Grid item md={12} xs={12} className={classes.Grid}>
          <Grid item md={5} xs={12}>
            <Cards
              cvc={cvc}
              expiry={date}
              focused={focused}
              name={` `}
              number={creditCard}
            />
          </Grid>
        </Grid>
        <Grid item md={12} xs={12} className={classes.Grid}>
          <Grid item md={5} xs={12} className={classes.inputGrid}>
            <div className={classes.inputDiv}>
              <TextField
                value={email}
                label="Correo electrónico"
                name="email"
                id="email"
                fullWidth
                error={errors.email ? errors.email.isInvalid : false}
                helperText={errors.email ? errors.email.message : ""}
                InputLabelProps={{
                  shrink: true,
                  className: classes.floatingLabelFocusStyle,
                  htmlFor: "email",
                }}
                InputProps={{
                  classes: {
                    input: classes.inputLabel,
                    underline: classes.underline,
                  },
                }}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </Grid>
        </Grid>
        <Grid item md={12} xs={12} className={classes.Grid}>
          <Grid item md={5} xs={12} className={classes.inputGrid}>
            <div className={classes.inputDiv}>
              <TextField
                value={creditCard}
                label="Número de tarjeta"
                name="name"
                id="name"
                fullWidth
                error={errors.creditCard ? errors.creditCard.isInvalid : false}
                helperText={errors.creditCard ? errors.creditCard.message : ""}
                InputLabelProps={{
                  shrink: true,
                  className: classes.floatingLabelFocusStyle,
                  htmlFor: "name",
                }}
                InputProps={{
                  classes: {
                    input: classes.inputLabel,
                    underline: classes.underline,
                  },
                }}
                onChange={(e) =>
                  setCreditCard(formatCreditCardNumber(e.target.value))
                }
                onSelect={onFocus}
                onFocus={onFocus}
              />
            </div>
          </Grid>
        </Grid>
        <Grid item md={12} xs={12} className={classes.Grid}>
          <Grid item md={5} xs={12} className={classes.inputGrid}>
            <div className={classes.inputDiv}>
              <TextField
                value={date}
                placeholder="YY/MM"
                label="Fecha de vencimiento"
                name="expiry"
                id="expiry"
                fullWidth
                error={errors.date ? errors.date.isInvalid : false}
                helperText={errors.date ? errors.date.message : ""}
                InputLabelProps={{
                  shrink: true,
                  className: classes.floatingLabelFocusStyle,
                  htmlFor: "expiry",
                }}
                InputProps={{
                  classes: {
                    input: classes.inputLabel,
                    underline: classes.underline,
                  },
                }}
                onChange={(e) => setDate(formatExpirationDate(e.target.value))}
                onSelect={onFocus}
              />
            </div>
          </Grid>
        </Grid>
        <Grid item md={12} xs={12} className={classes.Grid}>
          <Grid item md={5} xs={12} className={classes.inputGrid}>
            <div className={classes.inputDiv}>
              <TextField
                value={cvc}
                label="CVC"
                name="cvc"
                id="cvc"
                fullWidth
                error={errors.cvv ? errors.cvv.isInvalid : false}
                helperText={errors.cvv ? errors.cvv.message : ""}
                InputLabelProps={{
                  shrink: true,
                  className: classes.floatingLabelFocusStyle,
                  htmlFor: "cvc",
                }}
                InputProps={{
                  classes: {
                    input: classes.inputLabel,
                    underline: classes.underline,
                  },
                }}
                onChange={(e) => setCvc(formatCVC(e.target.value, creditCard))}
                onBlur={() => setFocused("")}
                onSelect={onFocus}
              />
            </div>
          </Grid>
        </Grid>
        <Grid item md={12} xs={12} className={classes.Grid}>
          <Grid item md={5} xs={12} className={classes.inputGrid}>
            <div className={classes.inputDiv}>
              <TextField
                value={document}
                label="Número de documento"
                name="document"
                id="document"
                fullWidth
                error={errors.document ? errors.document.isInvalid : false}
                helperText={errors.document ? errors.document.message : ""}
                InputLabelProps={{
                  shrink: true,
                  className: classes.floatingLabelFocusStyle,
                  htmlFor: "document",
                }}
                InputProps={{
                  classes: {
                    input: classes.inputLabel,
                    underline: classes.underline,
                  },
                }}
                onChange={(e) => setDocument(e.target.value)}
              />
            </div>
          </Grid>
        </Grid>
        <Grid item md={12} xs={12} className={classes.Grid}>
          <Typography variant="h5">
            Total a Pagar Bss:
            {FormatCurrency(`${parseFloat(totalAmount).toFixed(2)}`)}
          </Typography>
        </Grid>
        <Grid item md={12} xs={12} className={classes.Grid}>
          <Grid item md={5} xs={12} className={classes.inputGrid}>
            <Grid container justify="space-between">
              <div style={{ width: "158px", height: "41px" }}>
                <ButtonSpinner
                  action={() => {
                    props.goBack();
                  }}
                  fullWidth
                  text="Atras"
                />
              </div>
              <div style={{ width: "158px", height: "41px" }}>
                <ButtonSpinner
                  action={paymentCreditCard}
                  fullWidth
                  text="Procesar Pago"
                />
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Toast
        message={toastMessage}
        open={toast}
        close={() => {
          setCountAttempts(countAttempts + 1);
          setToast(false);
        }}
      />
      <Toast
        message={
          "Reserva cancelada motivo: muchos intentos al pagar la reserva"
        }
        open={countAttempts >= 3}
        close={() => {
          window.location.replace(`https://aviorair.com/`);
        }}
      />
      <Spinner loading={loading} />
    </div>
  );
}
