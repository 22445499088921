import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { anciliariesServices } from "../services";
import { Spinner } from "../components";
import { useHistory } from "react-router-dom";
const columns = [
  {
    id: "pnr",
    label: "PNR",
    minWidth: 50,
  },
  {
    id: "email",
    label: "Email",
    minWidth: 100,
  },
  {
    id: "transferDate",
    label: "Fecha",
    minWidth: 50,
  },
  {
    id: "amount",
    label: "Monto",
    minWidth: 50,
  },
  {
    id: "status",
    label: "Status",
    minWidth: 50,
  },
  {
    id: "ref",
    label: "Referencia",
    minWidth: 100,
  },
];
export default function PaymentsMiscelaneousTable() {
  const [payments, setPayments] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [loading, setLoading] = useState(false);
  let history = useHistory();
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getPayments(newPage + 1, rowsPerPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
    getPayments(1, event.target.value);
  };
  const getPayments = async (page, limit) => {
    setLoading(true);
    const { status, resp } = await anciliariesServices.getOrders({
      page,
      limit,
    });
    setLoading(false);
    if (status === 200) {
      setPage(resp.page ? resp.page - 1 : 0);
      setCount(resp.totalDocs);
      setPayments(resp.docs);
    } else if (status === 401) {
      history.push("/loginadminavior");
    }
  };

  useEffect(() => {
    getPayments(page + 1, rowsPerPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage]);

  return (
    <Grid container alignItems="center" justify="center">
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {payments.map((payment, index) => (
              <TableRow key={index}>
                <TableCell component="th" scope="row">
                  {payment.pnr}
                </TableCell>
                <TableCell component="th" scope="row">
                  {payment.email}
                </TableCell>
                <TableCell component="th" scope="row">
                  {payment.createdAt.split("T")[0]}
                </TableCell>
                <TableCell component="th" scope="row">
                  {payment.amount}
                </TableCell>
                <TableCell component="th" scope="row">
                  {payment.status}
                </TableCell>
                <TableCell component="th" scope="row">
                  {payment.status === "approved" ? payment.payment.ref : ""}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        labelRowsPerPage="Elemetos por pagina"
        rowsPerPageOptions={[10, 25, 50, 100]}
        component="div"
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
      <Spinner loading={loading} />
    </Grid>
  );
}
