export default function getClassName(code){
    if (code === "Y" || code === "Q" || code === "B") {
        return {
          cabinName: "ULTRA",
          familyName: "ECONOMY",
        };
      } else if(code === "E") {
        return {
          cabinName: "FLEX",
          familyName: "ECONOMY",
        }
      } else if (
        code === "H" ||
        code === "O" ||
        code === "M" ||
        code === "T" ||
        code === "G" ||
        code === "L" ||
        code === "K"
      ) {
        return {
          cabinName: "MEDIUM",
          familyName: "ECONOMY",
        };
      } else if (code === "U" || code === "W" || code === "X" || code === "V") {
        return {
          cabinName: "LIGHT",
          familyName: "ECONOMY",
        };
      } else if (
        code === "C" ||
        code === "J" ||
        code === "D" ||
        code === "I" ||
        code === "Z"
      ) {
        return {
          cabinName: "SUPER",
          familyName: "BUSINESS",
        };
      } else {
        return {
          cabinName: "INTERNO 9V",
          familyName: "INTERNO 9V",
        };
      }
}