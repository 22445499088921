import React from "react";
import { getTime, formatHoursMinutes } from "../helpers";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  font: {
    fontSize: "1.8em",
  },
}));
export default function OriginDestinationPointToPoint(props) {
  const classes = useStyles();
  return (
    <div>
      <div>
        <Typography variant="caption" className={classes.font}>
          {getTime(props.segment.departureInfo.dateTime)}
        </Typography>{" "}
        <Typography
          variant="caption"
          color="textSecondary"
          className={classes.font}
        >
          {props.segment.departureInfo.locationCode}{" "}
        </Typography>
        <img
          src="./img/home.png"
          alt=""
          style={{ width: "25px", height: "20px", marginTop: "12px" }}
        />
        <Typography variant="caption" className={classes.font}>
        {" "}{getTime(props.segment.arrivalInfo.dateTime)}
        </Typography>
        <Typography
          variant="caption"
          color="textSecondary"
          className={classes.font}
        >
        {" "}{props.segment.arrivalInfo.locationCode}
        </Typography>
      </div>
      <div>
        <Typography>
          <Typography variant="caption" color="textSecondary">
            TIEMPO DE DURACION
          </Typography>{" "}
          <Typography variant="caption">
            {formatHoursMinutes(props.segment.timeFlight)}
          </Typography>
        </Typography>
      </div>
      {props.segment.timeConection !== "00:00" ? (
        <div>
          <Typography>
            <Typography variant="caption" color="textSecondary">
              TIEMPO DE CONEXION
            </Typography>{" "}
            <Typography variant="caption">
              {formatHoursMinutes(props.segment.timeConection)}
            </Typography>
          </Typography>
        </div>
      ) : null}
    </div>
  );
}
