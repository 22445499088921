import React, { useState, useEffect } from "react";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { aviorPlusServices } from "../services";
import { ButtonSpinner, TermsAviorPlus, Toast, Spinner } from "../components/";
import LoginAviorPlus from "./login-aviorplus";

import { useHistory } from "react-router-dom";

export default function PaymentMiles(props) {
  const classes = useStyles();

  const [terms, setTerms] = useState(false);

  const acceptTermns = async ({ termsA, termsB }) => {
    if (termsA && termsB) setTerms(true);
    else setTerms(false);
  };

  const [totalAmount, setTotalAmount] = useState(0);

  const [loading, setLoading] = useState(false);
  const [toast, setToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  let history = useHistory();

  const getPrice = async () => {
    setLoading(true);
    const { status, response } = await aviorPlusServices.getReservationPrice({
      pnr: props.pnr,
    });
    setLoading(false);

    if (status === 200) {
      setTotalAmount(response.miles);
    }
  };

  const updateAVP = async () => {
    setLoading(true);

    const { response, status } = await aviorPlusServices.updateAVP({
      email: props.userData.user_info.email,
      is_aviorplus: true,
    });

    setLoading(false);

    if (status === 200) {
      props.setUserData({
        ...props.userData,
        user_info: response.data.user_info,
      });
    } else if (status === 400) {
      setToast(true);
      setToastMessage(response.error.message);
    }
  };

  const onSubmit = async () => {
    if (!terms) {
      setToast(true);
      setToastMessage("Debe aceptar los terminos y condiciones");
      return;
    }

    setLoading(true);
    const { response, status } = await aviorPlusServices.addRedemptionAvior({
      token: props.userData.token,
      pnr: props.pnr,
    });
    setLoading(false);

    if (status === 200) {
      if (response.code === 409) {
        setToast(true);
        setToastMessage(response.message);
        return;
      }

      if (response?.error?.code === 409) {
        setToast(true);
        setToastMessage(response.error.message);
        return;
      }

      history.push("/thankyoupagemiles", {
        state: {
          ...response,
          reservation: response.reservation,
          ancillaries: props.ancillaries,
        },
      });
    } else if (status === 400) {
      setToast(true);
      setToastMessage(response.error.message);
    }
  };

  useEffect(() => {
    getPrice();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Grid
        container
        justify="center"
        direction="row"
        alignItems="center"
        spacing={1}
        style={{ marginTop: 20 }}
      >
        <Grid item md={12} xs={12} className={classes.Grid}>
          <Typography className={classes.span1} color="secondary">
            Redención de millas
          </Typography>
        </Grid>

        {!props.userData ? (
          <Grid container justify="center">
            <Grid item md={4} xs={12}>
              <LoginAviorPlus
                setUserData={props.setUserData}
                goBack={() => {
                  props.goBack();
                }}
              />
            </Grid>
          </Grid>
        ) : (
          <Grid item md={6} xs={12}>
            <Grid item xs={12} className={classes.box}>
              <Grid
                container
                justify="space-between"
                className={classes.padding}
              >
                <Grid item xs={4}>
                  <Typography className={classes.span1} color="secondary">
                    Titular:
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Grid container justify="flex-end">
                    <Grid
                      item
                      md={6}
                      xs={4}
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <Typography className={classes.p3}>
                        {props.userData.user_info.first_name}{" "}
                        {props.userData.user_info.last_name}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} className={classes.box}>
              <Grid
                container
                justify="space-between"
                className={classes.padding}
              >
                <Grid item xs={4}>
                  <Typography className={classes.span1} color="secondary">
                    Tu balance:
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Grid container justify="flex-end">
                    <Grid
                      item
                      md={4}
                      xs={4}
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <Typography className={classes.p3}>
                        {props.userData.balance} millas
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} className={classes.box2}>
              <Grid container justify="center" className={classes.padding}>
                <Typography className={classes.span1}>
                  Total a pagar: {totalAmount} millas
                </Typography>
              </Grid>
            </Grid>

            {String(props.userData?.user_info?.is_aviorplus) === "true" ? (
              <>
                <TermsAviorPlus acceptTermns={acceptTermns} />

                <Grid item md={12} xs={12} className={classes.Grid}>
                  <Grid item md={12} xs={12} className={classes.inputGrid}>
                    <Grid container justify="space-between">
                      <div
                        style={{
                          width: "158px",
                          height: "41px",
                        }}
                      >
                        <ButtonSpinner
                          action={() => {
                            props.goBack();
                          }}
                          fullWidth
                          text="Atras"
                        />
                      </div>

                      <div
                        style={{
                          height: "41px",
                        }}
                      >
                        <ButtonSpinner
                          action={() => {
                            onSubmit();
                          }}
                          fullWidth
                          text="Generar reserva"
                        />
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            ) : (
              <>
                <Grid item md={12} xs={12}>
                  <Typography className={classes.p3}>
                    Este usuario no es Avior Plus. Si desea pagar con millas
                    presione la opción "Convertirme en Avior Plus"
                  </Typography>
                </Grid>

                <Grid
                  item
                  md={12}
                  xs={12}
                  className={classes.Grid}
                  style={{ marginTop: 10 }}
                >
                  <Grid item md={12} xs={12} className={classes.inputGrid}>
                    <Grid container justify="space-between">
                      <div
                        style={{
                          width: "158px",
                          height: "41px",
                        }}
                      >
                        <ButtonSpinner
                          action={() => {
                            props.goBack();
                          }}
                          fullWidth
                          text="Atras"
                        />
                      </div>

                      <div
                        style={{
                          height: "41px",
                        }}
                      >
                        <ButtonSpinner
                          action={() => {
                            updateAVP();
                          }}
                          fullWidth
                          text="Convertirme en Avior Plus"
                        />
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}

            {/* <TermsAviorPlus acceptTermns={acceptTermns} />

            <Grid item md={12} xs={12} className={classes.Grid}>
              <Grid item md={12} xs={12} className={classes.inputGrid}>
                <Grid container justify="space-between">
                  <div
                    style={{
                      width: "158px",
                      height: "41px",
                    }}
                  >
                    <ButtonSpinner
                      action={() => {
                        props.goBack();
                      }}
                      fullWidth
                      text="Atras"
                    />
                  </div>

                  <div
                    style={{
                      //width: "158px",
                      height: "41px",
                    }}
                  >
                    <ButtonSpinner
                      action={() => {
                        onSubmit();
                      }}
                      fullWidth
                      text="Generar reserva"
                    />
                  </div>
                </Grid>
              </Grid>
            </Grid> */}
          </Grid>
        )}

        <Toast
          message={toastMessage}
          open={toast}
          close={() => {
            setToast(false);
          }}
        />
        <Spinner loading={loading} />
      </Grid>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  inputGrid: {
    marginBottom: "15px",
  },
  Grid: {
    display: "flex",
    justifyContent: "center",
  },
  floatingLabelFocusStyle: {
    color: "#CD171A",
    fontSize: "15pt",
    width: "max-content",
  },
  inputLabel: {
    fontSize: "14pt",
    paddingBottom: 0,
    color: "#555555!important",
    height: "20px",
  },
  underline: {
    "&::before": {
      display: "none",
    },
    "&::after": {
      display: "none",
    },
  },
  inputDiv: {
    borderWidth: "1pt",
    borderStyle: "solid",
    borderColor: "#cccccc",
    padding: "10px",
    borderRadius: "5px",
    "& .MuiFormControl-root": {
      width: "100%",
    },
  },
  padding: {
    padding: "5px 10px",
  },
  box: {
    borderBottom: "solid 1px #cccccc",
    backgroundColor: "#EAEAEA",
    marginBottom: 5,
  },
  box2: {
    borderBottom: "solid 1px #CD171A",
    backgroundColor: "#CD171A",
    color: "#fff",
    marginBottom: 5,
  },
  span1: {
    fontWeight: "bold",
    fontSize: "1.21rem",
  },
  p3: {
    fontSize: "1.15rem",
    lineHeight: "1.5",
    color: "#848484",
  },
}));
