import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import urls from "../urls";
import { Spinner, Toast } from "../components";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function PaymentAnciliariesCreditCardStripe(props) {
  const [loading, setLoading] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [openToast, setOpenToast] = useState(false)
  const [countAttempts, setCountAttempts] = useState(0)
  const [params, setParams] = useState({});
  const [, setToast] = useState(false);
  //const [toastLuka, setToastLuka] = useState(true);
  let history = useHistory();
  let query = useQuery();

  // obtiene payload del URL
  useEffect(() => {
    const payload = query.get("payload")
    const data = JSON.parse(atob(payload))
    //console.log(data)
    setParams(data)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    window.addEventListener("message", getMessage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (countAttempts >= 3) {
      setToast(true)
    }
  }, [countAttempts]);

  function getMessage(e) {
    const data = e.data;
    if (data.type === "loading") {
      setLoading(data.response.loading);
    } else if (data.type === "response") {
      if (data.status === 200) {
        history.push("/thankyoupageopenlink",
          {
            state: { ...data.response }
          })
      }
      else {
        setToastMessage(data.response.error.message)
        setOpenToast(true)
      }
    } else if (data.type === "back") {
      props.goBack()
    }
  }

  return (
    <Grid
      container
      justify="center"
      direction="row"
      alignItems="center"
      spacing={1}
    >
      <Grid item md={10} xs={12}>
        <iframe
          style={{ border: 0, marginTop: "50px" }}
          src={`${urls.stripeLink}?orderId=${params.orderId}`}
          title="B9 Payment"
          width="100%"
          height="700px"
        />
      </Grid>
      <Spinner loading={loading} />
      <Toast
        message={toastMessage}
        open={openToast}
        close={() => {
          setOpenToast(false)
          setCountAttempts(countAttempts + 1)
          window.location.replace(`https://aviorair.com/`)
        }}
      />
      <Toast
        message={"Orden cancelada motivo: muchos intentos al pagar la orden"}
        open={countAttempts >= 3}
        close={() => {
          setToast(false);
          window.location.replace(`https://aviorair.com/`)
        }}
      />
    </Grid>
  );
}
