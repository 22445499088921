import React, { useState, useEffect } from "react";
import {
  Grid,
  //AppBar,
  Toolbar,
  Typography,
  TextField,
  Box,
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import CssBaseline from "@material-ui/core/CssBaseline";
import {
  ButtonSpinner,
  PassengersSelectStatic as PassengersSelect,
  DateRangeHeader,
  DestinationSelects,
  FlightType,
  //Currency
  ModalTourCodeError,
  //PurchaseDetailsOld,
} from "./";
import {
  destinationServices,
  flightAvailabilityServices,
  passengerServices,
} from "../services";
import {
  getIndexDaysOfTheWeek,
  filterDate,
  FormValidator,
  promotionRouters,
} from "../helpers";
import { useLocation, useHistory } from "react-router-dom";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingLeft: "10px",
    paddingRight: 0,
  },
  appBar: {
    background: "#fff",
    //zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    //paddingTop: "10px",
    //paddingBottom: "10px"
  },
  input: {
    "&:before": {
      borderBottom: "1px solid #FA7268",
    },
    "&:after": {
      borderBottom: "2px solid #FA7268",
    },
    "&:focus": {
      borderBottom: "2px solid #FA7268",
    },
    color: "#fff",
  },
  icon: {
    fill: "#FA7268",
  },
  formControl: {
    width: "90%",
  },
  label: {
    marginRight: "20px",
    color: "#575756",
    fontSize: "0.7rem",
  },
  adornment: {
    width: "22px",
  },
  container: {
    display: "inline-flex",
    alignItems: "center",
    verticalAlign: "middle",
    width: "100%",
  },
  marginButton: {
    marginLeft: "0px",
    justifyContent: "center",
  },
  [theme.breakpoints.down("md")]: {
    currencySelect: {
      marginTop: "10px",
    },
  },
  [theme.breakpoints.up("md")]: {
    border: {
      borderRight: "1px solid #CD171A",
    },
    searchButton: {
      marginLeft: "4%",
    },
    marginButton: {
      marginLeft: "45px",
      justifyContent: "flex-start",
    },
  },
}));

function SearchForm(props) {
  const classes = useStyles();
  const [isRoundTrip, setIsRoundTrip] = useState(true);
  const [airports, setAirport] = useState([]);
  const [destinationsDepartures, setDestinationsDepartures] = useState([]);
  const [destinationsArrivals, setDestinationsArrivals] = useState([]);
  const [origin, setOrgin] = useState("");
  const [destination, setDestination] = useState("");
  const [originError, setOrginError] = useState("");
  const [destinationError, setDestinationError] = useState("");
  const [originRoute, setOriginRoute] = useState({});
  const [returnRoute, setReturnRoute] = useState({});
  const [currency, setCurrency] = useState("");
  const [daysNotOperatedDeparture, setDaysNotOperatedDeparture] = useState([]);
  const [daysNotOperatedArrival, setDaysNotOperatedArrival] = useState([]);
  const [daysNotOperated, setDaysNotOperated] = useState([]);
  const [dateDeparture, setDateDeparture] = useState(null);
  const [dateArrival, setDateArrival] = useState(null);
  const [countAdults, setCountAdults] = useState(1);
  const [countChildrens, setCountChildrens] = useState(0);
  const [countBabies, setCountBabies] = useState(0);
  const [selectDateDepartureError, setSelectDateDepartureError] = useState("");
  const [selectDateArrivalError, setSelectDateArrivalError] = useState("");
  const [selectDateDeparture, setSelectDateDeparture] = useState("");
  const [selectDateArrival, setSelectDateArrival] = useState("");
  const [disabledPassengers, setDisabledPassengers] = useState(false);
  const [code, setCode] = useState("");
  const [tourCodeError, setTourCodeError] = useState(false);
  const [message, setMessage] = useState("");
  let location = useLocation();
  let history = useHistory();

  const validator = new FormValidator([
    {
      field: "origin",
      method: "isEmpty",
      validWhen: false,
      message: "Seleccione el origen",
    },
    {
      field: "destination",
      method: "isEmpty",
      validWhen: false,
      message: "Seleccione el destino",
    },
    {
      field: "selectDateDeparture",
      method: "isEmpty",
      validWhen: false,
      message: "Seleccione la fecha de salida",
    },
    {
      field: "selectDateArrival",
      method: getIsRoundTrip,
      validWhen: false,
      message: "Seleccione la fecha de retorno",
    },
  ]);

  function getIsRoundTrip(e) {
    if (isRoundTrip) {
      if (e === "") {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  useEffect(() => {
    getAirports();
  }, []);

  useEffect(() => {
    getFromProps(props.searchParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getFromProps(searchParams) {
    if (searchParams.origin) {
      const params = searchParams;
      const destinations = await destinationServices.getDestinations(
        params.origin
      );
      const destinationsResponse = destinations.resp;
      if (destinations.status !== 200) {
        return;
      }
      setDestinationsDepartures(destinationsResponse.routes.departures);
      setDestinationsArrivals(destinationsResponse.routes.arrivals);
      const destinationArrival = destinationsResponse.routes.arrivals.find(
        (arrival) => arrival.route === `${params.destination}/${params.origin}`
      );
      const destinationDeparture = destinationsResponse.routes.departures.find(
        (departure) =>
          departure.route === `${params.origin}/${params.destination}`
      );
      //if (!destinationArrival || !destinationDeparture) {
      //  return;
      //}
      if (destinationDeparture) {
        setOriginRoute(destinationDeparture);
        setDaysNotOperated(destinationDeparture.daysNotOperated);
        setDaysNotOperatedDeparture(destinationDeparture.daysNotOperated);
      } else {
        setDaysNotOperated([
          "Lunes",
          "Martes",
          "Miercoles",
          "Jueves",
          "Viernes",
          "Sabado",
          "Domingo",
        ]);
        setDaysNotOperatedDeparture([
          "Lunes",
          "Martes",
          "Miercoles",
          "Jueves",
          "Viernes",
          "Sabado",
          "Domingo",
        ]);
      }
      if (destinationArrival) {
        setReturnRoute(destinationArrival);
        setDaysNotOperatedArrival(destinationArrival.daysNotOperated);
      } else {
        setDaysNotOperatedArrival([
          "Lunes",
          "Martes",
          "Miercoles",
          "Jueves",
          "Viernes",
          "Sabado",
          "Domingo",
        ]);
      }
      //await getDestinations(params.origin);
      setIsRoundTrip(params.isRoundTrip);
      setOrgin(params.origin);
      setDestination(params.destination);
      setDateDeparture(new Date(params.selectDateDeparture.replace(/-/g, "/")));
      setSelectDateDeparture(params.selectDateDeparture);
      setDateArrival(
        params.isRoundTrip
          ? new Date(params.selectDateArrival.replace(/-/g, "/"))
          : null
      );
      setSelectDateArrival(params.isRoundTrip ? params.selectDateArrival : "");
      setCurrency(params.currency);
      setCountAdults(params.countAdults);
      setCountChildrens(params.countChildrens);
      setCountBabies(params.countBabies);
      setCode(params.tourCode);
      if (params.tourCode && params.tourCode !== "") {
        validateCode(params.tourCode);
      }
      if (!promotionRouters(params.origin, params.destination)) {
        setDisabledPassengers(false);
      }
    }
  }

  useEffect(() => {
    getFromLocation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getFromLocation() {
    if (location.state) {
      props.setLoading(true);
      const params = location.state;
      //console.log("params", params)
      //history.replace();
      const destinations = await destinationServices.getDestinations(
        params.origin
      );
      const destinationsResponse = destinations.resp;

      if (destinations.status !== 200) {
        props.setLoading(false);
        return;
      }
      setDestinationsDepartures(destinationsResponse.routes.departures);
      setDestinationsArrivals(destinationsResponse.routes.arrivals);
      const destinationArrival = destinationsResponse.routes.arrivals.find(
        (arrival) => arrival.route === `${params.destination}/${params.origin}`
      );
      const destinationDeparture = destinationsResponse.routes.departures.find(
        (departure) =>
          departure.route === `${params.origin}/${params.destination}`
      );
      //if (!destinationArrival || !destinationDeparture) {
      //  props.setLoading(false);
      //  return;
      //}
      if (destinationDeparture) {
        setOriginRoute(destinationDeparture);
        setDaysNotOperated(destinationDeparture.daysNotOperated);
        setDaysNotOperatedDeparture(destinationDeparture.daysNotOperated);
      } else {
        setDaysNotOperated([
          "Lunes",
          "Martes",
          "Miercoles",
          "Jueves",
          "Viernes",
          "Sabado",
          "Domingo",
        ]);
        setDaysNotOperatedDeparture([
          "Lunes",
          "Martes",
          "Miercoles",
          "Jueves",
          "Viernes",
          "Sabado",
          "Domingo",
        ]);
      }
      if (destinationArrival) {
        setReturnRoute(destinationArrival);
        setDaysNotOperatedArrival(destinationArrival.daysNotOperated);
      } else {
        setDaysNotOperatedArrival([
          "Lunes",
          "Martes",
          "Miercoles",
          "Jueves",
          "Viernes",
          "Sabado",
          "Domingo",
        ]);
      }
      //await getDestinations(params.origin);
      setIsRoundTrip(params.isRoundTrip);
      setOrgin(params.origin);
      setDestination(params.destination);
      setDateDeparture(new Date(params.departure_date.replace(/-/g, "/")));
      setSelectDateDeparture(params.departure_date);
      setDateArrival(
        params.isRoundTrip
          ? new Date(params.return_date.replace(/-/g, "/"))
          : null
      );
      setSelectDateArrival(params.isRoundTrip ? params.return_date : "");
      let ADT = params.passengerType.find(
        (passenger) => passenger.type === "ADT"
      );
      ADT = ADT ? ADT.quantity : 0;
      setCountAdults(ADT);
      let CHD = params.passengerType.find(
        (passenger) => passenger.type === "CHD"
      );
      CHD = CHD ? CHD.quantity : 0;
      setCountChildrens(CHD);
      let INF = params.passengerType.find(
        (passenger) => passenger.type === "INF"
      );
      INF = INF ? INF.quantity : 0;
      setCountBabies(INF);
      setCode(params.tourCode);
      if (params.tourCode && params.tourCode !== "") {
        validateCode(params.tourCode);
      }
      if (!promotionRouters(params.origin, params.destination)) {
        setDisabledPassengers(false);
      }
      const requestflightAvailability =
        await flightAvailabilityServices.flightAvailability(
          params.origin,
          params.destination,
          params.isRoundTrip,
          params.departure_date,
          params.return_date,
          ADT,
          CHD,
          INF,
          "USD", //params.currency,
          params.tourCode
        );

      const flightAvailabilityResponse = await requestflightAvailability.resp;
      const terms = await requestflightAvailability.terms;
      props.setTerms(terms);
      props.setLoading(false);
      props.reset();
      props.setResultsAvailability(false);
      props.setStep();
      props.setSearchParams({
        origin: params.origin,
        destination: params.destination,
        isRoundTrip: params.isRoundTrip,
        selectDateDeparture: params.departure_date,
        selectDateArrival: params.return_date,
        countAdults: ADT,
        countChildrens: CHD,
        countBabies: INF,
        currency: "USD", //,
        user: params.user,
        tourCode: params.tourCode,
      });
      if (requestflightAvailability.status === 200) {
        if (
          (params.isRoundTrip && flightAvailabilityResponse.length) ||
          (!params.isRoundTrip &&
            flightAvailabilityResponse.departure.length > 0)
        ) {
          props.setOriginRoute(destinationDeparture);
          props.setReturnRoute(destinationArrival);
          props.setResultsFlights(flightAvailabilityResponse);
          props.setResultsAvailability(true);
          props.setNotResult(false);
          props.setOpen(false);
        } else {
          props.setResultsAvailability(false);
          props.setNotResult(true);
          props.setOpen(false);
        }
      } else {
        setMessage(flightAvailabilityResponse.error.message);
        setTourCodeError(true);
      }
      history.replace();
    }
  }

  const getAirports = async () => {
    const peticion = await destinationServices.getAirports();
    const resp = peticion.resp;
    if (peticion.status === 200) {
      setAirport(resp.airports);
    }
  };

  const getDestinations = async (locationCode) => {
    const peticion = await destinationServices.getDestinations(locationCode);
    const resp = peticion.resp;
    if (peticion.status === 200) {
      setDestinationsDepartures(resp.routes.departures);
      setDestinationsArrivals(resp.routes.arrivals);
    }
  };

  const changeAirPort = (e) => {
    setOrgin(e);
    setDestination("");
    getDestinations(e);
    if (!promotionRouters(e, destination)) {
      setDisabledPassengers(false);
    }
  };

  const changeDestination = (e) => {
    setDestination(e);
    //console.log(e,"e")
    //console.log(destinationsDepartures)
    const destinationDeparture = destinationsDepartures.find(
      (destination) => destination.route === `${origin}/${e}`
    );
    //console.log({destinationsDepartures})
    if (destinationDeparture) {
      setOriginRoute(destinationDeparture);
      setDaysNotOperatedDeparture(destinationDeparture.daysNotOperated);
      setDaysNotOperated(destinationDeparture.daysNotOperated);
    } else {
      setDaysNotOperated([
        "Lunes",
        "Martes",
        "Miercoles",
        "Jueves",
        "Viernes",
        "Sabado",
        "Domingo",
      ]);
      setDaysNotOperatedDeparture([
        "Lunes",
        "Martes",
        "Miercoles",
        "Jueves",
        "Viernes",
        "Sabado",
        "Domingo",
      ]);
    }
    const destinationArrival = destinationsArrivals.find(
      (destination) => destination.route === `${e}/${origin}`
    );
    if (destinationArrival) {
      setReturnRoute(destinationArrival);
      setDaysNotOperatedArrival(destinationArrival.daysNotOperated);
    } else {
      setDaysNotOperatedArrival([
        "Lunes",
        "Martes",
        "Miercoles",
        "Jueves",
        "Viernes",
        "Sabado",
        "Domingo",
      ]);
    }
    if (!promotionRouters(origin, e)) {
      setDisabledPassengers(false);
    }
  };

  async function getFlightAvailability(
    paramsOrigin,
    paramsDestination,
    paramsIsRoundTrip,
    paramsDateDeparture,
    paramsDateArrival,
    paramsCountAdults,
    paramsCountChildrens,
    paramsCountBabies,
    paramsCurrency
  ) {
    props.reset();
    props.setResultsAvailability(false);
    props.setStep();
    props.setLoading(true);
    const peticion = await flightAvailabilityServices.flightAvailability(
      paramsOrigin,
      paramsDestination,
      paramsIsRoundTrip,
      paramsDateDeparture,
      paramsDateArrival,
      paramsCountAdults,
      paramsCountChildrens,
      paramsCountBabies,
      paramsCurrency,
      code
    );
    props.setLoading(false);
    const resp = await peticion.resp;
    const terms = await peticion.terms;
    props.setTerms(terms);
    //    console.log("resp", resp)
    if (peticion.status === 200) {
      if (
        (paramsIsRoundTrip && resp.length) ||
        (!paramsIsRoundTrip && resp.departure.length > 0)
      ) {
        props.setSearchParams({
          origin: paramsOrigin,
          destination: paramsDestination,
          isRoundTrip: paramsIsRoundTrip,
          selectDateDeparture: paramsDateDeparture,
          selectDateArrival: paramsDateArrival,
          countAdults: paramsCountAdults,
          countChildrens: paramsCountChildrens,
          countBabies: paramsCountBabies,
          currency: "USD", //paramsCurrency
          tourCode: code,
        });
        props.setOriginRoute(originRoute);
        props.setReturnRoute(returnRoute);
        props.setResultsFlights(resp);
        props.setResultsAvailability(true);
        props.setNotResult(false);
        props.setOpen(false);
      } else {
        props.setResultsAvailability(false);
        props.setNotResult(true);
        props.setOpen(false);
      }
    } else {
      setMessage(resp.error.message);
      setTourCodeError(true);
    }
  }

  const flightAvailability = async () => {
    console.log({
      origin,
      destination,
      selectDateDeparture,
      selectDateArrival,
    });
    const validation = validator.validate({
      origin,
      destination,
      selectDateDeparture,
      selectDateArrival,
    });
    setOrginError(validation.origin.message);
    setDestinationError(validation.destination.message);
    setSelectDateDepartureError(validation.selectDateDeparture.message);
    setSelectDateArrivalError(validation.selectDateArrival.message);

    if (validation.isValid) {
      props.clearPurchase();

      getFlightAvailability(
        origin,
        destination,
        isRoundTrip,
        selectDateDeparture,
        selectDateArrival,
        countAdults,
        countChildrens,
        countBabies,
        currency
      );
    }
  };
  const validateCode = async (code) => {
    props.setLoading(true);
    const { status, resp } = await passengerServices.passengerPromoData(code);
    props.setLoading(false);
    if (status === 200) {
      if (!resp.multiPassengers) {
        setDisabledPassengers(true);
        if (resp.passenger.type === "ADT") {
          setCountAdults(1);
          setCountBabies(0);
          setCountChildrens(0);
        } else {
          setCountAdults(0);
          setCountBabies(0);
          setCountChildrens(1);
        }
      } else {
        setDisabledPassengers(false);
      }
    } else {
      setCountAdults(1);
    }
  };
  return (
    <Grid
      container
      justify="center"
      direction="row"
      alignItems="center"
      className={classes.root}
      spacing={1}
      style={{
        marginBottom: "20px",
      }}
    >
      <Grid
        container
        justify="center"
        direction="row"
        alignItems="center"
        className={classes.root}
        style={{
          marginTop: "15px",
          marginBottom: "5px",
        }}
      >
        <Grid item md={4} xs={11}>
          <FlightType
            color="secondary"
            isRoundTrip={isRoundTrip}
            setFlightType={(type) => {
              if (type && dateDeparture) {
                setDaysNotOperated(daysNotOperatedArrival);
              }
              if (!type) {
                setDaysNotOperated(daysNotOperatedDeparture);
              }
              setIsRoundTrip(type);
            }}
          />
        </Grid>
      </Grid>
      {/*<Grid
        container
        justify="center"
        direction="row"
        alignItems="center"
        className={classes.root}
        style={{
          marginTop: "15px",
          marginBottom: "5px",
        }}
      >
        <Grid item md={6} xs={11} >
          <Grid container justify="flex-end">
            <Grid item md={10} xs={12}>
              <FlightType
                color="primary"
                isRoundTrip={isRoundTrip}
                setFlightType={(type) => setIsRoundTrip(type)}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={6} xs={11} className={classes.currencySelect}>
          <Grid container justify="flex-end">
            <Grid item md={7} xs={12} style={{display:"flex", justifyContent:"center"}}>
              <Grid container justify="center">
                <Grid item md={10} xs={12} >
                  <Currency 
                    isHeader={true}
                    setCurrency={(type)=> setCurrency(type)}
                    currency={currency}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>*/}
      <Grid
        item
        md={3}
        xs={12}
        //className={classes.border}
      >
        <DestinationSelects
          airports={airports}
          destinationsDepartures={destinationsDepartures}
          originError={originError}
          origin={origin}
          destination={destination}
          destinationError={destinationError}
          changeAirPort={changeAirPort}
          changeDestination={changeDestination}
        />
      </Grid>
      <Grid
        item
        md={2}
        xs={12}
        //className={classes.border}
      >
        <DateRangeHeader
          disableDays={getIndexDaysOfTheWeek(daysNotOperated)}
          dateDeparture={dateDeparture}
          dateArrival={dateArrival}
          isRoundTrip={isRoundTrip}
          disabled={!isRoundTrip}
          dateDepartureError={selectDateDepartureError !== ""}
          dateDepartureHelperText={selectDateDepartureError}
          dateArrivalError={selectDateArrivalError !== ""}
          dateArrivalHelperText={selectDateArrivalError}
          selectDaparture={() => setDaysNotOperated(daysNotOperatedDeparture)}
          selectArrival={() => setDaysNotOperated(daysNotOperatedArrival)}
          onChange={(dates) => {
            if (dates[0]) {
              setDateDeparture(dates[0]);
              setSelectDateDeparture(filterDate(dates[0]));
              setDateArrival("");
              setSelectDateArrival("");
              if (isRoundTrip) {
                setDaysNotOperated(daysNotOperatedArrival);
              }
            }
            if (dates[1]) {
              setDateArrival(dates[1]);
              setSelectDateArrival(filterDate(dates[1]));
              setDaysNotOperated(daysNotOperatedDeparture);
            }
          }}
        />
      </Grid>
      <Grid
        item
        md={3}
        xs={12}
        //className={classes.border}
      >
        <Grid container justify="center" direction="row" alignItems="center">
          <Grid item xs={11}>
            <PassengersSelect
              short={promotionRouters(origin, destination)}
              countAdults={countAdults}
              countChildrens={countChildrens}
              countBabies={countBabies}
              setCountAdults={(value) => setCountAdults(value)}
              setCountBabies={(value) => setCountBabies(value)}
              setCountChildrens={(value) => setCountChildrens(value)}
              disabled={disabledPassengers}
            />
          </Grid>
        </Grid>
        {promotionRouters(origin, destination) && (
          <Grid container justify="center" direction="row" alignItems="center">
            <Grid item xs={11}>
              <div>
                <div className={classes.container}>
                  <span className={classes.label}>Código</span>
                  <CssTextField
                    variant="standard"
                    style={{ width: "100%" }}
                    onChange={(e) => {
                      setCode(e.target.value.toUpperCase());
                      if (e.target.value !== "") {
                        //setDisabledPassengers(true)
                        //
                        //if(e.target.value.length===6){
                        //  validateCode(e.target.value)
                        //}
                      } else {
                        setDisabledPassengers(false);
                      }
                    }}
                    onBlur={(e) => {
                      if (e.target.value !== "") {
                        //setDisabledPassengers(true)
                        validateCode(e.target.value);
                      } else {
                        setDisabledPassengers(false);
                      }
                    }}
                    value={code}
                    //value={props.origin}
                    //onChange={(e) => {
                    //  props.changeAirPort(e.target.value);
                    //}}
                  />
                </div>
              </div>
            </Grid>
          </Grid>
        )}
      </Grid>
      <Grid item md={4} xs={12}>
        <Grid
          container
          className={classes.marginButton}
          //style={{ marginLeft: "40px" }}
        >
          <Grid item md={6} xs={11}>
            <ButtonSpinner
              action={flightAvailability}
              color="primary"
              text="Buscar"
              fullWidth
            />
          </Grid>
        </Grid>
      </Grid>
      {tourCodeError && (
        <ModalTourCodeError
          message={message}
          close={() => setTourCodeError(false)}
        />
      )}
    </Grid>
  );
}

export default function SearchFlightsHeader(props) {
  /* const purchaseDtailsStyles = makeStyles((theme) => ({
    [theme.breakpoints.down("md")]: {
      background: {
        //background: "#891C56",
        height: "70px",
      },
    },
    [theme.breakpoints.up("md")]: {
      border: {
        borderRight: props.step === 0 ? "unset" : "1px solid #CD171A",
      },
      background: {
        background: props.step === 0 ? "#FFF" : "#CD171A",
        height: "140px",
        display: "flex",
      },
    },
  })); */

  const classes = useStyles();
  //const classesPurchaseDetails = purchaseDtailsStyles();
  const [open, setOpen] = useState(props.step === 1 ? true : false);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  //const [loading, setLoading]=useState(false);
  return (
    <div
      className={classes.root}
      style={{
        justifyContent: "center",
        backgroundColor: "#fff",
        borderBottom: "1px solid #C8C8C8",
      }}
    >
      <CssBaseline />
      <Box className={clsx(classes.appBar)}>
        <Grid
          container
          justify="center"
          direction="row"
          alignItems="center"
          className={classes.root}
        >
          <Grid
            item
            md={props.step === 0 ? 5 : 2}
            xs={5}
            //className={classesPurchaseDetails.border}
          >
            <Toolbar
              className={classes.toolbar}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src="./img/logoWhite.png"
                  alt=""
                  style={{ width: "80%", cursor: "pointer" }}
                  onClick={() => props.setModalToHome(true)}
                />
              </div>
            </Toolbar>
          </Grid>

          {matches ? (
            <Grid item md={10}>
              {props.step === 0 ? null : (
                <SearchForm
                  setResultsAvailability={(resp) => {
                    props.setResultsAvailability(resp);
                  }}
                  setLoading={(load) => props.setLoading(load)}
                  setTerms={(terms) => props.setTerms(terms)}
                  searchParams={props.searchParams}
                  checkFlight={props.checkFlight}
                  step={props.step}
                  setSearchParams={(resp) => props.setSearchParams(resp)}
                  setOriginRoute={(resp) => props.setOriginRoute(resp)}
                  setReturnRoute={(resp) => props.setReturnRoute(resp)}
                  setResultsFlights={(resp) => props.setResultsFlights(resp)}
                  setNotResult={(resp) => props.setNotResult(resp)}
                  setStep={() => props.setStep()}
                  reset={() => props.reset()}
                  setOpen={(isOpen) => setOpen(isOpen)}
                  clearPurchase={() => {
                    props.setSelectedAncillaries({
                      going: [],
                      goBack: [],
                    });
                    props.setSelectedSeats({
                      going: [],
                      goBack: [],
                    });
                  }}
                />
              )}
            </Grid>
          ) : null}

          {/* <Grid item md={1}></Grid> */}

          {/* <Grid
            item
            md={3}
            xs={7}
            className={classesPurchaseDetails.background}
          >
            <Grid
              container
              justify="center"
              direction="row"
              alignItems="center"
            >
              <Grid item xs={12}>
                <PurchaseDetailsOld
                  step={props.step}
                  originFlight={props.originFlight}
                  returnFlight={props.returnFlight}
                  searchParams={props.searchParams}
                  open={props.open}
                  close={() => props.close()}
                  selectedAncillaries={props.selectedAncillaries}
                  selectedSeats={props.selectedSeats}
                />
              </Grid>
            </Grid>
          </Grid> */}

          {!matches ? (
            <>
              {props.step === 0 ? null : (
                <Grid item xs={11} style={{ paddingBottom: "5px" }}>
                  <Typography
                    align="left"
                    style={{
                      fontSize: "0.8rem",
                      //cursor:"pointer",
                      color: "#CE2827",
                    }}
                    onClick={() => {
                      setOpen(!open);
                    }}
                  >
                    Buscar vuelos{" "}
                    <img
                      src="./img/icono-compararfamilias.png"
                      alt=""
                      style={{ transform: open ? "rotate(180deg)" : null }}
                    />
                  </Typography>
                </Grid>
              )}
              {open ? (
                <Grid item xs={12}>
                  <SearchForm
                    setResultsAvailability={(resp) => {
                      props.setResultsAvailability(resp);
                    }}
                    setLoading={(load) => props.setLoading(load)}
                    searchParams={props.searchParams}
                    setTerms={(terms) => props.setTerms(terms)}
                    setSearchParams={(resp) => props.setSearchParams(resp)}
                    setOriginRoute={(resp) => props.setOriginRoute(resp)}
                    setReturnRoute={(resp) => props.setReturnRoute(resp)}
                    setResultsFlights={(resp) => props.setResultsFlights(resp)}
                    setNotResult={(resp) => {
                      props.setNotResult(resp);
                      setOpen(false);
                    }}
                    setStep={() => props.setStep()}
                    reset={() => props.reset()}
                    setOpen={(isOpen) => setOpen(isOpen)}
                    clearPurchase={() => {
                      props.setSelectedAncillaries({
                        going: [],
                        goBack: [],
                      });
                      props.setSelectedSeats({
                        going: [],
                        goBack: [],
                      });
                    }}
                  />
                </Grid>
              ) : null}
            </>
          ) : null}
        </Grid>
      </Box>
    </div>
  );
}
const CssTextField = withStyles({
  root: {
    "& .MuiInput-root": {
      "& input": {
        borderBottom: "1px solid #CD171A",
        color: "#000",
      },
    },
    "& .MuiInput-underline": {
      borderBottom: "1px solid #CD171A",
      "&::before": {
        borderBottom: "1px solid #CD171A",
      },
      "&::after": {
        borderBottom: "1px solid #CD171A",
      },
    },
  },
})(TextField);
