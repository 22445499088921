import React from "react";
import {
  DatePicker as MaterialDatePicker,
  LocalizationProvider
} from "@material-ui/pickers";
import DateFnsUtils from '@material-ui/pickers/adapter/date-fns';
import { makeStyles } from "@material-ui/core/styles";
import { TextField } from "@material-ui/core"
const useStyles = makeStyles(theme => ({
  formControl: {
    width: "100%",
  }
}));
export default function DatePicker(props) {
  const classes = useStyles();
  function disableDaysOfTheWeekends(date, days) {
    return days.includes(date.getDay());
  }
  return (
    <LocalizationProvider dateAdapter={DateFnsUtils}>
      <MaterialDatePicker
        InputLabelProps={props.InputLabelProps}
        InputProps={props.InputProps}
        animateYearScrolling
        disablePast={props.disablePast}
        disableFuture={props.disableFuture}
        disabled={props.disabled}
        minDate={props.minDate}
        className={classes.formControl}
        inputVariant={props.inputVariant? props.inputVariant : "outlined"}
        variant={props.variant ? props.variant : "inline"}
        label={props.label}
        error={props.error}
        autoOk={props.autoOk? props.autoOk: true}
        disableToolbar={!props.toolbar}
        format={props.format?props.format:"dd-MM-yyyy"}
        helperText={props.helperText}
        maxDate={props.maxDate}
        allowKeyboardControl={false}
        shouldDisableDate={date =>
          disableDaysOfTheWeekends(
            date,
            props.disableDays ? props.disableDays : []
          )
        }
        value={props.value}
        onChange={props.onChange ? props.onChange : () => {}}
        renderInput={(props) => <TextField {...props} />}
      />
    </LocalizationProvider>
  );
}
