export default function sortAncillaries(group, ancillaries){
    const ancillariesAux = [...ancillaries]
    const ancillariesSorted = []
    if(group==="BAGGAGES"){
        BAGGAGES_LIST.forEach(elem=>{
            const index = ancillariesAux.findIndex(ancillary=>ancillary.code === elem)
            if(index!==-1){
                ancillariesSorted.push({
                    ...ancillariesAux[index]
                })
                ancillariesAux.splice(index,1)
            }
        })
        ancillariesAux.forEach(elem=>{
            ancillariesSorted.push({
                ...elem
            })
        })
    }else if(group==="PETS"){
        PETS_LIST.forEach(elem=>{
            const index = ancillariesAux.findIndex(ancillary=>ancillary.code === elem)
            if(index!==-1){
                ancillariesSorted.push({
                    ...ancillariesAux[index]
                })
                ancillariesAux.splice(index,1)
            }
        })
        ancillariesAux.forEach(elem=>{
            ancillariesSorted.push({
                ...elem
            })
        })

    }else if(group==="SPORTS_ARTICLES"){
        SPORTS_ARTICLES.forEach(elem=>{
            const index = ancillariesAux.findIndex(ancillary=>ancillary.code === elem)
            if(index!==-1){
                ancillariesSorted.push({
                    ...ancillariesAux[index]
                })
                ancillariesAux.splice(index,1)
            }
        })
        ancillariesAux.forEach(elem=>{
            ancillariesSorted.push({
                ...elem
            })
        })
    }else if(group==="UNACCOMPANIED_MINOR"){
        UNACCOMPANIED_MINOR_LIST.forEach(elem=>{
            const index = ancillariesAux.findIndex(ancillary=>ancillary.code === elem)
            if(index!==-1){
                ancillariesSorted.push({
                    ...ancillariesAux[index]
                })
                ancillariesAux.splice(index,1)
            }
        })
        ancillariesAux.forEach(elem=>{
            ancillariesSorted.push({
                ...elem
            })
        })
    }else{
        OTHERS_LIST.forEach(elem=>{
            const index = ancillariesAux.findIndex(ancillary=>ancillary.code === elem)
            if(index!==-1){
                ancillariesSorted.push({
                    ...ancillariesAux[index]
                })
                ancillariesAux.splice(index,1)
            }
        })
        ancillariesAux.forEach(elem=>{
            ancillariesSorted.push({
                ...elem
            })
        })
    }

    return ancillariesSorted;
}


const BAGGAGES_LIST = [
    "FBFI",
    "ABAG",
    "XBAG"
]

const PETS_LIST = [
    "PETC",
    "AVIM",
    "AVIL"
]

const SPORTS_ARTICLES = [
    "BGGF",
    "BIKE",
    "SMSB",
    "SMSW",
    "SMST",
    "SPAR",
    "SPEQ"
]

const UNACCOMPANIED_MINOR_LIST = [
    "UMNR"
]

const OTHERS_LIST = [
    "BGMI",
    "TVUP",
    "TVOV"
]