import React, { useEffect, useState } from "react";
import PaymentAnciliariesCreditCardLukaPay from "./payment-anciliaries-credit-card-luka-pay";
import {
    PaymentAnciliariesCreditCard,
    PaymentAnciliariesCreditCardTuOfertazo,
    PaymentAnciliariesCreditCardStripe
    // PaymentAnciliariesCreditCardLukaPay,
    //PaymentAncillariesCreditCardBancaAmiga 
} from "./"

//import { paymentMethodsAnciliaries } from "../helpers"
import { gatewaysServices } from "../services";
import { Spinner } from "../components";
export default function PaymentAnciliaries() {
    const [loading, setLoading] = useState(false);
    const [paymentMethodsAnciliaries, setPaymentMethodsAnciliaries] = useState([])
    const getGateways = async () => {
        setLoading(true)
        const { response } = await gatewaysServices.getGateways("link");
        setPaymentMethodsAnciliaries(response)
        setLoading(false)
    }
    useEffect(() => {
        getGateways();
    }, []);

    const getPaymentComponent = () => {
        // 
        switch (paymentMethodsAnciliaries[0]?.value) {
            case "tuOfertazo":
                return <PaymentAnciliariesCreditCardTuOfertazo />
            case "lukaTdc":
                return <PaymentAnciliariesCreditCardLukaPay />
            case "stripe":
                return <PaymentAnciliariesCreditCardStripe />
            default:
                return <PaymentAnciliariesCreditCard />
            // case "bancaAmiga":
            //     return (
            //         <PaymentAncillariesCreditCard />
            //     )
        }
    }


    return (
        <>
            <Spinner loading={loading} />
            {!loading && getPaymentComponent()}
        </>
    );
}