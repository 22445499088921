import React, { useState } from "react"; // Import useState
import {
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  IconButton,
  TableCell,
  TableRow,
  Collapse,
  Box,
  Typography,
  Table,
  TableHead,
  TableBody,
  Button, // Add Button import
} from "@material-ui/core";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { getDateReadable, getFullHour, getStatus, getBank } from "../helpers";

const passengers = [
  {
    id: "full-name",
    label: "Nombre y apellido",
    minWidth: 170,
  },
  {
    id: "email",
    label: "Email",
    minWidth: 100,
  },
];
const segments = [
  {
    id: "route",
    label: "Ruta",
    minWidth: 80,
  },
  {
    id: "departureDate",
    label: "Salida",
    minWidth: 100,
  },
  {
    id: "arrivalDate",
    label: "Llegada",
    minWidth: 100,
  },
];

export default function TransfersRow(props) {
  const [open, setOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogCancel, setOpenDialogCancel] = useState(false);
  //const [pnr, setPnr] = useState("");
  const [transfer, setTransfer] = useState({});

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const getTableCells = (transactionType) => {
    if (["zelle", "stripe"].includes(transactionType)) {
      return (
        <>
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell>{props.transfer.reservation.pnr}</TableCell>
          <TableCell>{props.transfer.payment.ref}</TableCell>
          <TableCell>{props.transfer.createdAt?.split("T")[0]}</TableCell>
          <TableCell>{`${props.transfer.paymentInfo.firstName} ${props.transfer.paymentInfo.lastName}`}</TableCell>
          <TableCell>{props.transfer.paymentInfo.email}</TableCell>
          <TableCell>
            {props.transfer?.reservation?.origin ||
              props.transfer?.reservation?.platform}
          </TableCell>
          <TableCell>{props.transfer.amount}</TableCell>
          <TableCell>{getStatus(props.transfer.status)}</TableCell>
          <TableCell>
            {props.transfer.status !== "pending"
              ? props.transfer.admin
                ? `${props.transfer.admin?.firstName} ${props.transfer.admin?.lastName}`
                : "Automatico"
              : "-"}
          </TableCell>
          <TableCell>
            {props.transfer.status !== "pending"
              ? props.transfer.updatedAt?.split("T")[0]
              : "-"}
          </TableCell>
          <TableCell>
            {props.transfer.status === "pending" && (
              <Grid container justify="center">
                <Grid item xs={6}>
                  <Button
                    onClick={() => {
                      handleClickOpen();
                      setOpen(false);
                      //setPnr(props.transfer.pnr);
                      setTransfer(props.transfer);
                    }}
                    variant="contained"
                    color="primary"
                  >
                    Aprobar
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    onClick={() => {
                      setOpenDialogCancel(true);
                      setOpen(false); //setPnr(props.transfer.pnr);
                      setTransfer(props.transfer);
                    }}
                    variant="contained"
                    color="primary"
                  >
                    Rechazar
                  </Button>
                </Grid>
              </Grid>
            )}
          </TableCell>
        </>
      );
    } else if (transactionType === "bs") {
      const transferType = {
        BAPM: "Banca Amiga",
        BTPM: "Banco del tesoro",
      };
      return (
        <>
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell>{props.transfer.reservation.pnr}</TableCell>
          <TableCell>{props.transfer.payment.ref}</TableCell>
          <TableCell>{props.transfer.createdAt?.split("T")[0]}</TableCell>
          <TableCell>
            <Typography noWrap>
              {getBank(props.transfer.payment.bank)}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography noWrap>
              {transferType[props.transfer.payment.type] || "Banca Amiga"}
            </Typography>
          </TableCell>
          <TableCell>
            {props.transfer.paymentInfo.firstName}{" "}
            {props.transfer.paymentInfo.lastName}{" "}
          </TableCell>
          <TableCell>{props.transfer.paymentInfo.email}</TableCell>
          <TableCell>
            {props.transfer?.reservation?.origin ||
              props.transfer?.reservation?.platform}
          </TableCell>
          <TableCell>{props.transfer.amount}</TableCell>
          <TableCell>{getStatus(props.transfer.status)}</TableCell>
          <TableCell>
            {props.transfer.status !== "pending"
              ? props.transfer.admin
                ? `${props.transfer.admin?.firstName} ${props.transfer.admin?.lastName}`
                : "Automatico"
              : "-"}
          </TableCell>
          <TableCell>
            {props.transfer.status !== "pending"
              ? props.transfer.updatedAt?.split("T")[0]
              : "-"}
          </TableCell>
          <TableCell>
            {props.transfer.status === "pending" && (
              <Grid container justify="center">
                <Grid item xs={6}>
                  <Button
                    onClick={() => {
                      handleClickOpen();
                      setOpen(false);
                      //setPnr(props.transfer.pnr);
                      setTransfer(props.transfer);
                    }}
                    variant="contained"
                    color="primary"
                  >
                    Aprobar
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    onClick={() => {
                      setOpenDialogCancel(true);
                      setOpen(false);
                      //setPnr(props.transfer.pnr);
                      setTransfer(props.transfer);
                    }}
                    variant="contained"
                    color="primary"
                  >
                    Rechazar
                  </Button>
                </Grid>
              </Grid>
            )}
          </TableCell>
        </>
      );
    } else if (["zelleCuponAir", "bsCuponAir"].includes(transactionType)) {
      return (
        <>
          <TableCell>{getDateReadable(props.transfer.createdAt)}</TableCell>
          <TableCell>{props.transfer.referenceNumber}</TableCell>
          <TableCell>{props.transfer.name}</TableCell>
          <TableCell>{props.transfer.email}</TableCell>
          <TableCell>{props.transfer.phone}</TableCell>
          <TableCell>{props.transfer.amount}</TableCell>
          <TableCell>{getStatus(props.transfer.status)}</TableCell>
          <TableCell>
            {props.transfer.status !== "pending"
              ? props.transfer.admin
                ? `${props.transfer.admin?.firstName} ${props.transfer.admin?.lastName}`
                : "Automatico"
              : "-"}
          </TableCell>
          <TableCell>
            {props.transfer.status !== "pending"
              ? getDateReadable(props.transfer.updatedAt?.split("T")[0])
              : "-"}
          </TableCell>
          <TableCell>
            {props.transfer.status === "pending" && (
              <Grid container justify="center">
                <Grid item xs={6}>
                  <Button
                    onClick={() => {
                      handleClickOpen();
                      setTransfer(props.transfer);
                    }}
                    variant="contained"
                    color="primary"
                  >
                    Aprobar
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    onClick={() => {
                      setOpenDialogCancel(true);
                      //setPnr(props.transfer.pnr);
                      setTransfer(props.transfer);
                    }}
                    variant="contained"
                    color="primary"
                  >
                    Rechazar
                  </Button>
                </Grid>
              </Grid>
            )}
          </TableCell>
        </>
      );
    }
  };

  return (
    <React.Fragment>
      <Dialog
        open={openDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Aprobar pago.</DialogTitle>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button
            onClick={() => {
              handleClose();
              props.aproveTranfer(transfer._id);
            }}
            color="primary"
            autoFocus
          >
            Aprobar
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openDialogCancel}
        onClose={() => setOpenDialogCancel(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Rachazar pago.</DialogTitle>
        <DialogActions>
          <Button onClick={() => setOpenDialogCancel(false)} color="primary">
            Cancelar
          </Button>
          <Button
            onClick={() => {
              setOpenDialogCancel(false);
              props.cancelTranfer(transfer._id);
            }}
            color="primary"
            autoFocus
          >
            Rechazar
          </Button>
        </DialogActions>
      </Dialog>
      <TableRow hover role="checkbox" tabIndex={-1}>
        {getTableCells(props.transactionType)}
      </TableRow>
      <TableRow>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
          }}
          colSpan={6}
        >
          {props.transfer.reservation ? (
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={1}>
                <Typography variant="h6" gutterBottom component="div">
                  Pasajeros
                </Typography>
                <Table aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      {passengers.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{
                            minWidth: column.minWidth,
                          }}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {props.transfer.reservation.passengers.map(
                      (pasenger, index) => (
                        <TableRow key={index}>
                          <TableCell component="th" scope="row">
                            {pasenger.firstName} {pasenger.lastName}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {pasenger.email}
                          </TableCell>
                        </TableRow>
                      )
                    )}
                  </TableBody>
                </Table>
              </Box>
              <Box margin={1}>
                <Typography variant="h6" gutterBottom component="div">
                  Itinerario
                </Typography>
                <Table aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      {segments.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{
                            minWidth: column.minWidth,
                          }}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {props.transfer.reservation.segments.going.map(
                      (segment, index) => (
                        <TableRow key={index}>
                          <TableCell component="th" scope="row">
                            {segment.departure}/{segment.arrival}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {getDateReadable(segment.depatureTime, true)}{" "}
                            {getFullHour(segment.depatureTime)}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {getDateReadable(segment.arrivalTime, true)}{" "}
                            {getFullHour(segment.arrivalTime)}
                          </TableCell>
                        </TableRow>
                      )
                    )}
                    {props.transfer.reservation.segments.goBack.map(
                      (segment, index) => (
                        <TableRow key={index}>
                          <TableCell component="th" scope="row">
                            {segment.departure}/{segment.arrival}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {getDateReadable(segment.depatureTime, true)}{" "}
                            {getFullHour(segment.depatureTime)}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {getDateReadable(segment.arrivalTime, true)}{" "}
                            {getFullHour(segment.arrivalTime)}
                          </TableCell>
                        </TableRow>
                      )
                    )}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          ) : null}
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
