import React, { useState } from "react";
import { Grid, TextField, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { FormValidator } from "../helpers";
import { ButtonSpinner, Toast, Spinner } from "../components";
import { paymentServices } from "../services";
import { useHistory } from "react-router-dom";
import {
  formatCreditCardNumber,
  filterFullName,
  formatExpirationDate,
  formatCVC,
} from "../helpers/credit-card-filters";
import Cards from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";
import { dateValidYYMM, FormatCurrency } from "../helpers";
import ReCAPTCHA from "react-google-recaptcha";
const useStyles = makeStyles((theme) => ({
  inputGrid: {
    marginBottom: "15px",
  },
  Grid: {
    display: "flex",
    justifyContent: "center",
  },
  floatingLabelFocusStyle: {
    color: "#CD171A",
    fontSize: "15pt",
    width: "max-content",
  },
  inputLabel: {
    fontSize: "14pt",
    paddingBottom: 0,
    color: "#555555!important",
    height: "20px",
  },
  underline: {
    "&::before": {
      display: "none",
    },
    "&::after": {
      display: "none",
    },
  },
  inputDiv: {
    borderWidth: "1pt",
    borderStyle: "solid",
    borderColor: "#cccccc",
    padding: "10px",
    borderRadius: "5px",
    "& .MuiFormControl-root": {
      width: "100%",
    },
  },
  expandIcon: {
    color: "#FA7268",
  },
  [theme.breakpoints.down("md")]: {
    floatingLabelFocusStyle: {
      fontSize: "0.9rem",
    },
    inputLabel: {
      fontSize: "0.8rem",
    },
  },
}));
export default function PaymentBookingCreditCardTuOfertazo(props) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [toast, setToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [date, setDate] = useState("");
  const [cvv, setCvv] = useState("");
  const [focused, setFocused] = useState("");
  const [errors, setErrors] = useState("");
  const [email, setEmail] = useState("");
  const recaptchaRef = React.createRef();
  const [disabled, setDisabled] = useState(true);
  let history = useHistory();
  const totalAmount =
    parseFloat(props.originFlight ? props.originFlight.fare.totalAmount : 0) +
    parseFloat(props.returnFlight ? props.returnFlight.fare.totalAmount : 0);
  const getAmountAmount = () => {
    return parseFloat(
      props.ancillaries.going.reduce(function (a, b) {
        return parseFloat(a) + parseFloat(b.total);
      }, 0) +
        props.ancillaries.goBack.reduce(function (a, b) {
          return parseFloat(a) + parseFloat(b.total);
        }, 0)
    ).toFixed(2);
  };
  const getAmountSeats = () => {
    return parseFloat(
      props.selectedSeats.going.reduce(function (a, b) {
        return parseFloat(a) + parseFloat(b.total);
      }, 0) +
        props.selectedSeats.goBack.reduce(function (a, b) {
          return parseFloat(a) + parseFloat(b.total);
        }, 0)
    ).toFixed(2);
  };
  const totalSeats = getAmountSeats();
  const totalAncillaries = getAmountAmount();
  const validator = new FormValidator([
    {
      field: "cardNumber",
      method: "isEmpty",
      validWhen: false,
      message: "Debe ingresar su número de tarjeta",
    },
    {
      field: "email",
      method: "isEmpty",
      validWhen: false,
      message: "Debe ingresar email",
    },
    {
      field: "email",
      method: "isEmail",
      validWhen: true,
      message: "Ingrese un correo electrónico valido",
    },
    //{
    //  field: "cardNumber",
    //  method: "isCreditCard",
    //  validWhen: false,
    //  message: "Ingrese un número de tarjeta valido",
    //},
    {
      field: "date",
      method: "isEmpty",
      validWhen: false,
      message: "Ingrese la fecha de vencimiento de su tarjeta de crédito.",
    },
    {
      field: "date",
      method: dateValidYYMM,
      validWhen: true,
      message: "La fecha de vencimiento no es valida",
    },
    {
      field: "cvv",
      method: "isEmpty",
      validWhen: false,
      message: "Ingrese el CVC de su tarjeta de crédito.",
    },
    {
      field: "cvv",
      method: "isLength",
      validWhen: true,
      args: [{ min: 3, max: 4 }],
      message: "El CVC no es valido",
    },
    {
      field: "name",
      method: "isEmpty",
      validWhen: false,
      message: "Ingrese su nombre.",
    },
    {
      field: "lastName",
      method: "isEmpty",
      validWhen: false,
      message: "Ingrese su apellido.",
    },
  ]);

  const onFocus = (e) => {
    setFocused(e.target.name);
  };
  const paymentCreditCard = async () => {
    const validation = validator.validate({
      email,
      cardNumber: cardNumber.replace(/\D+/g, ""),
      date,
      cvv,
      name,
      lastName,
    });
    setErrors(validation);
    if (validation.isValid) {
      const body = {
        email,
        pnr: props.pnr,
        numbercard: cardNumber.replace(/\D+/g, ""),
        exp: date.split("/").join(""),
        cvv,
        descripcion: `Pago ancillaries`,
        name,
        lastname: lastName,
      };
      setLoading(true);
      const { response, status } =
        await paymentServices.paymentBookingCreditCardTuOfertazo(body);
      setLoading(false);
      if (status === 200) {
        if (response.payment.status === "rejected") {
          setToastMessage(response.payment.response.m);
          setToast(true);
        } else {
          history.push(`/thankyoupage`, {
            state: {
              ...response,
              reservationDetails: response.reservationdetails,
              originFlight: props.originFlight,
              returnFlight: props.returnFlight,
            },
          });
        }
      } else {
        setToastMessage(response.error.message);
        setToast(true);
      }
    }
  };
  return (
    <div style={{ marginTop: "20px", marginBottom: "20px" }}>
      <Grid
        container
        justify="center"
        direction="row"
        alignItems="center"
        spacing={1}
      >
        <Grid item md={12} xs={12} className={classes.Grid}>
          <Grid item md={5} xs={12}>
            <Cards
              cvc={cvv}
              expiry={date}
              focused={focused}
              name={`${name} ${lastName}`}
              number={cardNumber}
            />
          </Grid>
        </Grid>
        <Grid item md={12} xs={12} className={classes.Grid}>
          <Grid item md={5} xs={12} className={classes.inputGrid}>
            <div className={classes.inputDiv}>
              <TextField
                value={email}
                label="Correo electrónico"
                name="email"
                id="email"
                fullWidth
                error={errors.email ? errors.email.isInvalid : false}
                helperText={errors.email ? errors.email.message : ""}
                InputLabelProps={{
                  shrink: true,
                  className: classes.floatingLabelFocusStyle,
                  htmlFor: "email",
                }}
                InputProps={{
                  classes: {
                    input: classes.inputLabel,
                    underline: classes.underline,
                  },
                }}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </Grid>
        </Grid>
        <Grid item md={12} xs={12} className={classes.Grid}>
          <Grid item md={5} xs={12} className={classes.inputGrid}>
            <div className={classes.inputDiv}>
              <TextField
                value={cardNumber}
                label="Número de tarjeta"
                name="number"
                id="number"
                fullWidth
                error={errors.cardNumber ? errors.cardNumber.isInvalid : false}
                helperText={errors.cardNumber ? errors.cardNumber.message : ""}
                InputLabelProps={{
                  shrink: true,
                  className: classes.floatingLabelFocusStyle,
                  htmlFor: "number",
                }}
                InputProps={{
                  classes: {
                    input: classes.inputLabel,
                    underline: classes.underline,
                  },
                }}
                onFocus={onFocus}
                onChange={(e) =>
                  setCardNumber(formatCreditCardNumber(e.target.value))
                }
                onSelect={onFocus}
              />
            </div>
          </Grid>
        </Grid>
        <Grid item md={12} xs={12} className={classes.Grid}>
          <Grid item md={5} xs={12} className={classes.inputGrid}>
            <div className={classes.inputDiv}>
              <TextField
                value={name}
                label="Nombre"
                name="name"
                id="name"
                fullWidth
                error={errors.name ? errors.name.isInvalid : false}
                helperText={errors.name ? errors.name.message : ""}
                InputLabelProps={{
                  shrink: true,
                  className: classes.floatingLabelFocusStyle,
                  htmlFor: "name",
                }}
                InputProps={{
                  classes: {
                    input: classes.inputLabel,
                    underline: classes.underline,
                  },
                }}
                onChange={(e) => setName(filterFullName(e.target.value))}
                onSelect={onFocus}
              />
            </div>
          </Grid>
        </Grid>
        <Grid item md={12} xs={12} className={classes.Grid}>
          <Grid item md={5} xs={12} className={classes.inputGrid}>
            <div className={classes.inputDiv}>
              <TextField
                value={lastName}
                label="Apellido"
                name="lastName"
                id="lastName"
                fullWidth
                error={errors.lastName ? errors.lastName.isInvalid : false}
                helperText={errors.lastName ? errors.lastName.message : ""}
                InputLabelProps={{
                  shrink: true,
                  className: classes.floatingLabelFocusStyle,
                  htmlFor: "lastName",
                }}
                InputProps={{
                  classes: {
                    input: classes.inputLabel,
                    underline: classes.underline,
                  },
                }}
                onChange={(e) => setLastName(filterFullName(e.target.value))}
                onSelect={onFocus}
              />
            </div>
          </Grid>
        </Grid>
        <Grid item md={12} xs={12} className={classes.Grid}>
          <Grid item md={5} xs={12} className={classes.inputGrid}>
            <div className={classes.inputDiv}>
              <TextField
                value={date}
                placeholder="YY/MM"
                label="Fecha de vencimiento"
                name="expiry"
                id="expiry"
                fullWidth
                error={errors.date ? errors.date.isInvalid : false}
                helperText={errors.date ? errors.date.message : ""}
                InputLabelProps={{
                  shrink: true,
                  className: classes.floatingLabelFocusStyle,
                  htmlFor: "expiry",
                }}
                InputProps={{
                  classes: {
                    input: classes.inputLabel,
                    underline: classes.underline,
                  },
                }}
                onChange={(e) => setDate(formatExpirationDate(e.target.value))}
                onSelect={onFocus}
              />
            </div>
          </Grid>
        </Grid>
        <Grid item md={12} xs={12} className={classes.Grid}>
          <Grid item md={5} xs={12} className={classes.inputGrid}>
            <div className={classes.inputDiv}>
              <TextField
                value={cvv}
                label="CVC"
                name="cvc"
                id="cvc"
                fullWidth
                error={errors.cvv ? errors.cvv.isInvalid : false}
                helperText={errors.cvv ? errors.cvv.message : ""}
                InputLabelProps={{
                  shrink: true,
                  className: classes.floatingLabelFocusStyle,
                  htmlFor: "cvc",
                }}
                InputProps={{
                  classes: {
                    input: classes.inputLabel,
                    underline: classes.underline,
                  },
                }}
                onChange={(e) => setCvv(formatCVC(e.target.value, cardNumber))}
                onBlur={() => setFocused("")}
                onSelect={onFocus}
              />
            </div>
          </Grid>
        </Grid>
        <Grid item md={12} xs={12} className={classes.Grid}>
          <Grid item md={5} xs={12} className={classes.inputGrid}>
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey="6LesfiMeAAAAAJOBWsiFIvC2zSWMPTyVw5YMP_H2"
              onChange={(value) => setDisabled(value !== "" ? false : true)}
            />
          </Grid>
        </Grid>
        <Grid item md={12} xs={12} className={classes.Grid}>
          <Typography variant="h5">
            Total a Pagar USD $
            {FormatCurrency(
              `${parseFloat(
                parseFloat(totalAmount) +
                  parseFloat(totalAncillaries) +
                  parseFloat(totalSeats)
              ).toFixed(2)}`
            )}
          </Typography>
        </Grid>
        <Grid item md={12} xs={12} className={classes.Grid}>
          <Grid item md={5} xs={12} className={classes.inputGrid}>
            <Grid container justify="space-between">
              <div style={{ width: "158px", height: "41px" }}>
                <ButtonSpinner
                  action={() => {
                    props.goBack();
                  }}
                  fullWidth
                  text="Atras"
                />
              </div>
              <div style={{ width: "158px", height: "41px" }}>
                <ButtonSpinner
                  action={paymentCreditCard}
                  fullWidth
                  disabled={disabled}
                  text="Pagar"
                />
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Toast
        message={toastMessage}
        open={toast}
        close={() => {
          setToast(false);
        }}
      />
      <Spinner loading={loading} />
    </div>
  );
}
