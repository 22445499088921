import React from "react";
import { Grid, Card, CardContent, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  grid: {
    marginTop: "15px",
  },
}));
export default function Pnr(props) {
  const classes = useStyles();
  return (
    <Grid
      container
      justify="center"
      direction="row"
      spacing={1}
      className={classes.grid}
    >
      <Grid item md={6} xs={12}>
        <Card>
          <CardContent>
            <Grid
              container
              justify="center"
              direction="row"
              spacing={1}
              className={classes.grid}
            >
              <Grid item xs={6}>
                <img src="./img/logo.png" alt="" style={{ width: "120px" }} />
              </Grid>
              <Grid item xs={6}>
                <Typography
                  display="block"
                  variant="caption"
                  color="textSecondary"
                  align="center"
                >
                  RESERVA
                </Typography>
                <Typography variant="h6" align="center">
                  {props.pnr}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
