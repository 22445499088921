import React, { useState, useEffect, useRef } from "react";
import {
  Flight,
  DatesReBooking,
  //SearchResume
} from ".";
import { Grid, Typography } from "@material-ui/core";
export default function SearchResults(props) {
  const [flights, setFlights] = useState([]);
  const [selectFlightRowOrigin, setSelectFlightRowOrigin] = useState(null);
  const mountedRef = useRef(true);
  const [openFareTerms, setOpenFareTerms] = useState(false);
  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);
  useEffect(() => {
    if (!mountedRef.current) return null;
    else setFlights(props.flights);
  }, [props.flights]);
  //console.log("flights",props.flights)
  return (
    <div style={{ width: "100%" }}>
      <Grid container justify="center" direction="row">
        <Grid item xs={12} md={10}>
          <Typography color="textSecondary" style={{ fontSize: "1.25rem" }}>
            {props.title}
          </Typography>
        </Grid>
      </Grid>

      {/*<SearchResume
        route={props.route}
        dateDeparture={
          flights[0] ? flights[0].segments[0].departureInfo.dateTime : null
        }
        dateArrival={
          flights[0] ? flights[0].segments[0].arrivalInfo.dateTime : null
        }
        searchParams={props.searchParams}
      />*/}
      <DatesReBooking
        route={props.route}
        searchParams={props.searchParams}
        setFlights={(result) => {
          props.reset();
          setFlights([]);
          setOpenFareTerms(!openFareTerms);
          setFlights(result.departure);
        }}
        typeFlight={props.typeFlight}
      />
      {flights.map((flight, index) => (
        <Flight
          isSelected={selectFlightRowOrigin === index}
          searchParams={props.searchParams}
          selectable={props.selectable}
          typeFlight={props.typeFlight}
          terms={props.terms}
          key={index}
          flight={flight}
          openFareTerms={openFareTerms}
          selectFlight={(result) => {
            setSelectFlightRowOrigin(index);
            props.selectFlight(result);
          }}
        />
      ))}
    </div>
  );
}
