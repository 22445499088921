import React, { useState, useEffect } from "react"
import { StaticDatePicker, PassengersSelectStatic, Currency } from "../components/index";
import { Grid, MenuItem, TextField, Typography, Button } from "@material-ui/core";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { destinationServices } from "../services";
import { ExpandMore } from '@material-ui/icons';
import { styled } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import { getIndexDaysOfTheWeek, filterDate, FormValidator } from "../helpers/";
import { isAfter } from "date-fns";

const MyExpandExpandMore = styled(ExpandMore)({
    color: "#FA7268",
    "&.MuiSelect-iconOpen": {
      transform: "none"
    }
});


export default function IframeBookingForm(props){

    const classes = useStyles();

    //const [flightType, setFlightType]=useState('');
    const [airports, setAirports]=useState([]);
    const [radioValue, setRadioValue]=useState('roundTrip');
    const [destinationsDepartures, setDestinationsDepartures] = useState([]);
    const [destinationsArrivals, setDestinationsArrivals] = useState([]);
    const [origin, setOrgin] = useState("");
    const [originError, setOrginError] = useState("");
    const [destination, setDestination] = useState("");
    const [destinationError, setDestinationError] = useState("");
    const [currency, setCurrency]=useState('VES')
    //const [originRoute, setOriginRoute] = useState({});
    const [daysNotOperatedDeparture, setDaysNotOperatedDeparture] = useState([]);
    const [daysNotOperatedArrival, setDaysNotOperatedArrival] = useState([]);
    //const [returnRoute, setReturnRoute] = useState({});
    const [dateDeparture, setDateDeparture] = useState(null);
    const [selectDateDeparture, setSelectDateDeparture] = useState("");
    const [dateArrival, setDateArrival] = useState(null);
    const [selectDateArrival, setSelectDateArrival] = useState("");
    const [countAdults, setCountAdults] = useState(1);
    const [countChildrens, setCountChildrens] = useState(0);
    const [countBabies, setCountBabies] = useState(0);
    const [selectDateDepartureError, setSelectDateDepartureError] = useState("");
    const [selectDateArrivalError, setSelectDateArrivalError] = useState("");
    //const [fields, setFields]=useState(false)
    const [isRoundTrip, setIsRoundTrip] = useState(true);
    const validator = new FormValidator([
        {
          field: "origin",
          method: "isEmpty",
          validWhen: false,
          message: "Seleccione el origen",
        },
        {
          field: "destination",
          method: "isEmpty",
          validWhen: false,
          message: "Seleccione el destino",
        },
        {
          field: "selectDateDeparture",
          method: "isEmpty",
          validWhen: false,
          message: "Seleccione la fecha de salida",
        },
        {
          field: "selectDateArrival",
          method: "isEmpty",
          validWhen: !isRoundTrip,
          message: "Seleccione la fecha de retorno",
        },
      ]);
    useEffect(() => {
        getAirports();
    }, []);
    
    const getAirports = async () => {
        const peticion = await destinationServices.getAirports();
        const resp = peticion.resp;
        if (peticion.status === 200) {
          setAirports(resp.airports);
        }
    };

    const handleRadioChange = (event) => {
        setRadioValue(event.target.value);
        setIsRoundTrip( (event.target.value==='roundTrip') ? true:false)
        setSelectDateArrival((event.target.value==='roundTrip') ? selectDateArrival: "");
        setDateArrival((event.target.value==='roundTrip') ? dateArrival: null);
    };

    const getDestinations = async (locationCode) => {
        const peticion = await destinationServices.getDestinations(locationCode);
        const resp = peticion.resp;
        if (peticion.status === 200) {
          setDestinationsDepartures(resp.routes.departures);
          setDestinationsArrivals(resp.routes.arrivals);
        }
    };

    const changeAirPort = (e) => {
        setOrgin(e.target.value);
        getDestinations(e.target.value);
    };

    const changeDestination = (e) => {
        setDestination(e.target.value);
        const destinationDeparture = destinationsDepartures.find(
          (destination) => destination.route === `${origin}/${e.target.value}`
        );
    
        if (destinationDeparture) {
          //setOriginRoute(destinationDeparture);
          setDaysNotOperatedDeparture(destinationDeparture.daysNotOperated);
        }
        const destinationArrival = destinationsArrivals.find(
          (destination) => destination.route === `${e.target.value}/${origin}`
        );
        if (destinationArrival) {
          //setReturnRoute(destinationArrival);
          setDaysNotOperatedArrival(destinationArrival.daysNotOperated);
        }
    };

    const flightAvailability = async () => {
    
        const validation = validator.validate({
          origin,
          destination,
          selectDateDeparture,
          selectDateArrival
        });
        setOrginError(validation.origin.message);
        setDestinationError(validation.destination.message);
        setSelectDateDepartureError(validation.selectDateDeparture.message);
        setSelectDateArrivalError(validation.selectDateArrival.message);
        if (validation.isValid) {
            let obj={
                "origin":origin,
                "destination":destination,
                "departure_date":selectDateDeparture,
                "return_date":selectDateArrival,
                "isRoundTrip":isRoundTrip,
                "currency":currency,
                "passengerType":[
                    {
                        "type":"ADT",
                        "quantity":countAdults
                    },
                    {
                        "type":"CHD",
                        "quantity":countChildrens
                    },
                    {
                        "type":"INF",
                        "quantity":countBabies
                    }
                ]
            }
            //console.log(obj)
            let encoded = btoa(JSON.stringify(obj))
            //window.open(`http://localhost:3000/availability?payload=${encoded}`)
            window.open(`https://booking.aviorair.com/availability?payload=${encoded}`)
           
            //console.log(encoded);
        }
    }


    return(
        <Grid container justify="center" style={{padding:"20px"}}>
            <Grid item xs={12} lg={12} style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center", /*border:"1pt solid #000"*/}}>
                <FormControl component="fieldset" style={{marginBottom:"20px"}}>
                    <RadioGroup row aria-label="position" name="position" value={radioValue} onChange={handleRadioChange}>
                        <FormControlLabel
                            value="roundTrip"
                            control={<Radio color="secondary" />}
                            label="Ida y regreso"
                            labelPlacement="end"
                        />
                        <FormControlLabel
                            value="oneWay"
                            control={<Radio color="secondary" />}
                            label="Solo ida"
                            labelPlacement="end"
                        />
                    </RadioGroup>
                </FormControl>
                <Grid container>
                    <Grid item md={12} xs={12} style={{marginBottom:"20px"}}>
                        <div className={classes.inputDiv}>
                            <TextField value={origin} label="Desde" select id="origin"
                                helperText={originError}  error={originError!==""} 
                                InputLabelProps={{ shrink: true, className: classes.floatingLabelFocusStyle, htmlFor:"origin" }}
                                InputProps={{ /*disabled:fields,*/ classes: { input: classes.inputLabel, underline: classes.underline,} }}
                                SelectProps={{ IconComponent: MyExpandExpandMore }}
                                onChange={changeAirPort}
                            >
                            {
                                    airports.map((airport, index) => (
                                        <MenuItem key={index} value={airport.locationCode}>
                                            {airport.locationCode} {airport.cityName} {airport.airportName}
                                        </MenuItem>
                                    ))
                            }
                            </TextField>    
                        </div>
                    </Grid>
                    <Grid item md={12} xs={12} style={{marginBottom:"20px"}}>
                        <div className={classes.inputDiv}>
                            <TextField value={destination} label="Hacia" select id="destiny"
                                helperText={destinationError}  error={destinationError!==""} 
                                InputLabelProps={{ shrink: true, className: classes.floatingLabelFocusStyle, htmlFor:"destiny" }}
                                InputProps={{ /*disabled:fields,*/ classes: { input: classes.inputLabel, underline: classes.underline,} }}
                                SelectProps={{ IconComponent: MyExpandExpandMore }}
                                onChange={changeDestination}
                            >
                            {
                                    destinationsDepartures.map((destination, index) => (
                                        <MenuItem key={index} value={destination.arrival}>
                                          {destination.arrival} {destination.arrivalCityName}{" "}
                                          {destination.arrivalAirportName}
                                        </MenuItem>
                                    ))
                            }
                            </TextField>    
                        </div>
                    </Grid>
                    <Grid container justify="space-between" spacing={2} style={{marginBottom:"12px"}}>
                        <Grid item sm={6} xs={6} >
                            <div className={classes.inputDiv}>
                                <StaticDatePicker  value={dateDeparture} label="Fecha Ida" /*disabled={fields}*/ disablePast id="dateDeparture"
                                    helperText={selectDateDepartureError} error={selectDateDepartureError !== ""} inputVariant={'standard'}
                                    InputLabelProps={{ shrink: true, className: classes.floatingLabelFocusStyle, htmlFor:"dateDeparture"}}
                                    InputProps={{ /*disabled:fields,*/ classes: { input: classes.inputLabel, underline: classes.underline,} }}
                                    disableDays={getIndexDaysOfTheWeek(daysNotOperatedDeparture)}
                                    onChange={(date) => {
                                        setSelectDateDeparture(filterDate(date));
                                        setDateDeparture(date);
                                        if (isAfter(dateArrival, date)) {
                                        setSelectDateArrival("");
                                        setDateArrival(date);
                                        }
                                    }}
                                />
                            </div>
                        </Grid>
                        <Grid item sm={6} xs={6} >
                            <div className={classes.inputDiv}>
                                <StaticDatePicker  value={dateArrival} label="Fecha Regreso" /*disabled={fields}*/ disablePast id="dateDeparture"
                                    helperText={selectDateArrivalError} error={selectDateArrivalError !== ""} inputVariant={'standard'}
                                    InputLabelProps={{ shrink: true, className: classes.floatingLabelFocusStyle, htmlFor:"dateDeparture"}}
                                    InputProps={{ /*disabled:fields,*/ classes: { input: classes.inputLabel, underline: classes.underline,} }}
                                    disableDays={getIndexDaysOfTheWeek(daysNotOperatedArrival)} minDate={dateDeparture}
                                    disabled={!isRoundTrip}
                                    onChange={(date) => {
                                        setSelectDateArrival(filterDate(date));
                                        setDateArrival(date);
                                    }}
                                />
                            </div>
                        </Grid>
                    </Grid>
                    <Grid container justify="space-between" style={{marginBottom:"20px"}}>
                        <Grid item className={classes.grid} md={12} xs={12}>
                            <div className={classes.inputDiv}>
                                <PassengersSelectStatic
                                    countAdults={countAdults}
                                    countChildrens={countChildrens}
                                    countBabies={countBabies}
                                    setCountAdults={(value) => setCountAdults(value)}
                                    setCountBabies={(value) => setCountBabies(value)}
                                    setCountChildrens={(value) => setCountChildrens(value)}
                                    classes={classes}
                                    variant="text"
                                />
                            </div>
                        </Grid>
                    </Grid>
                    <Grid container justify="center" style={{marginBottom:"20px"}}>
                        <Grid item className={classes.grid} md={4} xs={12}>
                            <Currency 
                                setCurrency={(type)=> setCurrency(type)}
                                currency={currency}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Button variant="contained" fullWidth={true}
                            style={{
                                boxShadow: "none",
                                padding:0,
                                minWidth:"100%",
                                height:"50px",
                            }}
                            color={"primary"}
                            onClick={flightAvailability}
                        >
                           <Typography style={{color:"#fff"}}>BUSCAR VUELOS</Typography> 
                           <img src="./img/lupa-02.png" alt="" style={{marginLeft:"5px"}}/>
                        </Button>

                    </Grid>
                </Grid>
            </Grid>

        </Grid>
    )
}

const useStyles = makeStyles((theme) => ({
    floatingLabelFocusStyle: {
        color: "#af0061",
        fontSize: "15pt",
        fontFamily: "Roboto-Medium, sans-serif",
    },
    inputLabel: {
        fontFamily: "Roboto-Regular, sans-serif",
        fontSize: "14pt",
        paddingBottom: 0,
        color: "#555555!important",
    },
    underline: {
        "&::before": {
          display: "none",
        },
        "&::after": {
          display: "none",
        },
    },
    selectLabel:{
        color: "#af0061",
        fontSize: "11.5pt",
        fontFamily: "Roboto-Medium, sans-serif",
    },
    inputDiv: {
        borderWidth: "1pt",
        borderStyle: "solid",
        borderColor: "#cccccc",
        padding: "10px",
        borderRadius: "5px",
        "& .MuiFormControl-root": {
          width: "100%",
        },
    },
    button: {
        padding: "0px",
        color: "#C45E96",
        border: "none",
        borderRadius: 0,
        justifyContent: "flex-start"
    },
}))