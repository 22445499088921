import React, { useState } from "react";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import { ref } from "../helpers/links";

export default function TermsAndConditions(props) {
  const classes = useStyles();

  const [termns, setTermns] = useState(false);
  const [adult, setAdult] = useState(false);
  return (
    <Grid container direction="row" className={classes.container}>
      <Grid
        item
        md={12}
        xs={12}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
        }}
      >
        <div>
          <Checkbox
            checked={termns}
            onChange={(e) => {
              setTermns(e.target.checked);
              props.acceptTermns(e.target.checked);
            }}
            color={"primary"}
          />
        </div>
        <div style={{ width: "95%", paddingTop: "9px" }}>
          <Typography className={classes.checkText}>
            He leído y acepto el{" "}
            <a
              rel="noopener noreferrer"
              target="_blank"
              href={ref.transportContract}
              style={{ color: "#555555" }}
            >
              <strong className={classes.links}>contrato de transporte</strong>
            </a>
            , la política{" "}
            <a
              rel="noopener noreferrer"
              target="_blank"
              href={ref.personalData}
              style={{ color: "#555555" }}
            >
              <strong className={classes.links}>
                tratamiento de privacidad y uso de datos personales
              </strong>
            </a>
            ,{" "}
            <a
              rel="noopener noreferrer"
              target="_blank"
              href={ref.dutiesAndRights}
              style={{ color: "#555555" }}
            >
              <strong className={classes.links}>
                guía de derecho del pasajero
              </strong>
            </a>
            , condiciones de manejo de{" "}
            <a
              rel="noopener noreferrer"
              target="_blank"
              href={ref.dangerousBaggage}
              style={{ color: "#555555" }}
            >
              <strong className={classes.links}>mercancias peligrosas</strong>
            </a>
            , política de{" "}
            <a
              rel="noopener noreferrer"
              target="_blank"
              href={ref.home}
              style={{ color: "#555555" }}
            >
              <strong className={classes.links}>
                condiciones y penalidades por cambio
              </strong>
            </a>
            , el cobro de impuestos y cargos gubernamentales, no incluidos en el
            servicio, así como aquellos incluidos y las condiciones de acceso
            uso{" "}
            <a
              rel="noopener noreferrer"
              target="_blank"
              href={ref.taxes}
              style={{ color: "#555555" }}
            >
              <strong className={classes.links}>de este</strong>
            </a>
            .
          </Typography>
        </div>
      </Grid>
      <Grid
        item
        md={12}
        xs={12}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
        }}
      >
        <div>
          <Checkbox
            checked={adult}
            onChange={(e) => {
              setAdult(e.target.checked);
              props.isAdult(e.target.checked);
            }}
            color={"primary"}
          />
        </div>
        <div style={{ width: "95%", paddingTop: "9px" }}>
          <Typography className={classes.checkText}>
            Soy mayor de edad y acepto el uso de mis datos personales para
            recibir novedades, promociones y ofertas que Avior Airlines tiene
            para mi (OPCIONAL).
          </Typography>
        </div>
      </Grid>
    </Grid>
  );
}
const useStyles = makeStyles((theme) => ({
  checkText: {
    fontSize: "0.8rem",
    color: "#555555",
  },
  container: {
    backgroundColor: "#DBDBDB",
    padding: "20px",
    marginBottom: "20px",
    marginTop: "20px",
  },
  links: {
    textDecoration: "underline",
  },
  [theme.breakpoints.down("md")]: {
    checkText: {
      fontSize: "0.8rem",
    },
  },
}));
