import React from "react"
import { Grid, Typography } from "@material-ui/core";
import { FlightInfo } from "../components/"
import {getCityName, getFullDateName, getTime} from "../helpers/"
export default function TravelInfo(props){

    return (
        <>
            <Grid item md={8} xs={12}>
                <Grid container justify="flex-start">
                    <Grid item md={10} xs={12}>
                        <Grid container justify="flex-start">
                            <Grid item md={6} xs={12} style={{marginBottom:"20px", display:"flex"}}>
                                <Grid container >
                                    <Grid item md={12} style={{marginBottom:"10px", display:"flex", alignItems:"center"}}>
                                        <Typography className={props.classes.text}>
                                            INFORMACIÓN <strong>DEL VIAJE:</strong>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item md={6} xs={12} style={{marginBottom:"20px", display:"flex"}}>
                                <Grid container justify="flex-end">
                                    <Grid item md={10}  xs={12} style={{marginBottom:"10px"}}>
                                        <Grid container style={{backgroundColor:"#CCCCCC", padding:"10px"}} direction="row" alignItems="center" justify="center">
                                            <Typography style={{marginRight:"5px"}} className={props.classes.text}>
                                                De
                                            </Typography>
                                            <div style={{border:"1pt solid #000", padding:"5px"}}>
                                                <Typography style={{fontWeight:"bold"}} className={props.classes.text}>
                                                    {getCityName(props.segment.departure).cityName}
                                                </Typography>
                                            </div>
                                            <Typography style={{marginRight:"5px", marginLeft:"5px"}} className={props.classes.text}>
                                                a
                                            </Typography>
                                            <div style={{border:"1pt solid #000", padding:"5px"}}>
                                                <Typography style={{fontWeight:"bold"}} className={props.classes.text}>
                                                    {getCityName(props.segment.arrival).cityName}
                                                </Typography>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item md={8} xs={12} style={{marginBottom:"20px", border:"1pt solid #000"}}>
                <Grid container justify="flex-start" >
                    <Grid item md={1} xs={3}>
                        <Grid container style={{height:"100%", borderRight:"1pt solid #000"}}>
                            <Grid item md={12} style={{ width:"100%",backgroundColor:"#575756", padding:"5px", display:"flex", justifyContent:"center"}}>
                                <Typography style={{color:"#FFF"}}  className={props.classes.text}>
                                    Trayecto
                                </Typography>
                            </Grid>
                            <Grid item md={12} style={{width:"100%", display:"flex", justifyContent:"center", flexDirection:"column", alignItems:"center"}}>
                                <Typography  className={props.classes.text}>
                                    {props.index}
                                </Typography>
                                <Typography  className={props.classes.text}>
                                    Confirmado
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={3} xs={9} className={props.classes.containerBox}>
                        <Grid container >
                            <Grid item md={12} style={{ width:"100%", padding:"5px", display:"flex", justifyContent:"center"}}>
                                <Typography color={"primary"}  className={props.classes.text}>
                                    Fecha del vuelo
                                </Typography>
                            </Grid>
                            <Grid item md={12} style={{width:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}>
                                <Typography  className={props.classes.text}>
                                   {getFullDateName(props.segment.depatureTime)}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={1} xs={3}>
                        <Grid container style={{height:"100%"}}>
                            <Grid item md={12} style={{ width:"100%", backgroundColor:"#575756", padding:"5px", display:"flex", justifyContent:"center", alignItems:"center"}}>
                                <Typography style={{color:"#FFF"}}  className={props.classes.text}>
                                    Salida
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={3} xs={9} className={props.classes.containerBox} style={{width:"100%",  display:"flex", justifyContent:"center", alignItems:"center"}}>
                        <Typography align="center" className={props.classes.text}>
                            {` ${getTime(props.segment.depatureTime)} ${getCityName(props.segment.departure).cityName} ${getCityName(props.segment.departure).airPortName}`}
                        </Typography>
                    </Grid>
                    <Grid item md={1} xs={3}>
                        <Grid container style={{height:"100%"}}>
                            <Grid item md={12} style={{ width:"100%", backgroundColor:"#575756", padding:"5px", display:"flex", justifyContent:"center", alignItems:"center"}}>
                                <Typography style={{color:"#FFF"}}  className={props.classes.text}>
                                    Llegada
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={3} xs={9} style={{ width:"100%",  display:"flex", justifyContent:"center", alignItems:"center"}}>
                        <Typography align="center"  className={props.classes.text}>
                        {` ${getTime(props.segment.arrivalTime)} ${getCityName(props.segment.arrival).cityName} ${getCityName(props.segment.arrival).airPortName}`}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <FlightInfo classes={props.classes} segment={props.segment}/>
        </>
    )
}