import React from "react";
import { Button, Grid } from "@material-ui/core";
import { useLocation, useHistory } from "react-router-dom";
import { CostResume, FligthResume, Pnr } from "../components";
export default function ResumeReservation(props) {
  let location = useLocation();
  let history = useHistory();
  if (!location.state) {
    history.push(`/`);
  }
  const params = location.state.params;
  const reservation = location.state.response;
  function sendData(){
    history.push(`/paymentmethod`,location.state)
  }
  return (
    <div>
      <Pnr pnr={reservation.pnr} />
      <FligthResume
        segments={params.originFlight.segments}
        searchParams={params.searchParams}
        totalTimeFlight={params.originFlight.totalTimeFlight}
      />
      {params.returnFlight ? (
        <FligthResume
          segments={params.returnFlight.segments}
          searchParams={params.searchParams}
          totalTimeFlight={params.returnFlight.totalTimeFlight}
        />
      ) : null}
      <div style={{ marginTop: "20px" }}>
        <CostResume
          originFlight={params.originFlight}
          returnFlight={params.returnFlight}
        />
      </div>
      <Grid container justify="center" direction="row" spacing={1}>
        <Grid item md={6} xs={12}>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "15px",
            }}
          >
            <Button variant="contained" color="primary" onClick={sendData}>
              Siguiente
            </Button>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
