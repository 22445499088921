import Modal from "@material-ui/core/Modal"
import React, { useState } from "react"
import { Grid, Typography, Button, IconButton } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { CloseOutlined } from "@material-ui/icons"
const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 600,
    height: 500,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #FFF",
    boxShadow: theme.shadows[5],
    padding: "20px",
    borderRadius: "20px",
    outline: "none"
  },
  paper2: {
    position: "absolute",
    width: 400,
    height: 200,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #FFF",
    boxShadow: theme.shadows[5],
    padding: "20px",
    borderRadius: "20px",
    outline: "none"
  },
  aceptButtonLabel: {
    color: "#ffffff",
    fontSize: "0.8rem"
  },
  h2: {
    color: "#777777",
    fontWeight: "unset",
    fontSize: "1rem"
  },
  modalText: {
    color: "#575756",
    fontWeight: "bold",
    fontSize: "0.8rem"
  },
  p: {
    fontSize: "1.3rem",
    color: "#848484"
  },
  highlightedP: {
    fontSize: "1.5rem",
    color: "#af0061"
  },
  [theme.breakpoints.down("sm")]: {
    h2: {
      fontSize: "1.1rem"
    },
    p: {
      fontSize: "1rem"
    },
    highlightedP: {
      fontSize: "1.2rem"
    },
    paper: {
      width: 340,
      height: 600
    }
  }
}))
export default function ModalLukaPay(props) {
  const classes = useStyles()
  const [modalStyle] = useState(getModalStyle)

  function getModalStyle() {
    const top = 50
    const left = 50

    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`
    }
  }
  return (
    <Modal open={props.open} onClose={props.close} disableBackdropClick={true}>
      <Grid container justify="center" style={modalStyle} className={classes.paper}>
        <Grid container justify="flex-end" onClick={props.close}>
          <IconButton style={{ padding: 0 }}>
            <CloseOutlined />
          </IconButton>
        </Grid>
        <Grid item md={12} xs={12} style={{ backgroundColor: "#FFF" }}>
          <Grid container alignItems="center" justify="center">
            <Typography className={classes.h2} align="center">
              <strong>¡ADVERTENCIA!</strong>{" "}
            </Typography>
          </Grid>

          <br />
          {props.method === "btpm" ? (
            <>
              <Typography className={classes.modalText}>
                Para realizar el pago C2P debe ingresar la clave de operaciones proporcionada por su banco.
              </Typography>
              <br />
              <Typography className={classes.modalText}>
                Así mismo, al momento de registrar el pago, los datos a completar deberán coincidir
                con los del pagador.
              </Typography>
            </>
          ) : (
            <>
              <Typography className={classes.modalText}>
                Al momento de realizar el pago de tu reserva, el monto debe coincidir con lo
                cotizado, de lo contrario no se garantiza el cupo.
              </Typography>
              <br />
              <Typography className={classes.modalText}>
                Es de suma importancia incluir los decimales, el monto debe ser exacto al mostrado,
                RECUERDE, no redondear las cifras.
              </Typography>
              <br />
              <Typography className={classes.modalText}>
                Así mismo, al momento de registrar el pago, los datos a completar deberán coincidir
                con los del pagador.
              </Typography>
              <br />
              {props.method === "luka_zelle" && (
                <>
                  <Typography className={classes.modalText}>
                    Si estás realizando tu pago vía Zelle se cargara el 3% por IGTF.
                  </Typography>
                  <br />
                </>
              )}
              <Typography className={classes.modalText}>
                El sistema NO procesará tu boleto, si el pago no coincide con el MONTO EXACTO y
                datos BANCO receptor.
              </Typography>{" "}
            </>
          )}
          <br />
          <Typography className={classes.modalText} align="center">
            ¡Evite inconvenientes!
          </Typography>
          <br />
          <Grid container justify="center" alignItems="center" direction="column">
            <div style={{ width: "100%" }}>
              <Button variant="contained" fullWidth color={"primary"} onClick={props.close}>
                <span style={{ marginRight: 5 }}>Aceptar</span>
              </Button>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  )
}
